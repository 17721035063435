import React from 'react';
import { Form, Select } from 'antd';
import JobRecommendations from '../../Components/IdealCandidateIntel/JobRecommendations/JobRecommendations';
import { onAddRecommendation } from '../../Utils/AdvanceRefineIntelUtils';
import EmploymentContext from './EmploymentContext';

const PreferredIndustry = props => {
  const { initialValue } = props;
  const { form, industries, industryRecommendations } = React.useContext(EmploymentContext);
  return (
    <div>
      <Form.Item>
        {form.getFieldDecorator('PreferredIndustry', {
          initialValue,
        })(
          <Select
            key="PreferredIndustry"
            placeholder="Select Industry"
            mode="multiple"
            style={{ width: '100%' }}
            showArrow
            allowClear
          >
            {industries.map(industry => (
              <Select.Option key={industry} value={industry}>
                {industry}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <JobRecommendations
        key="IndustryRecommendation"
        recommendations={industryRecommendations}
        onAddRecommendation={onAddRecommendation(form, 'PreferredIndustry')}
        recommendationTitle="We recommend these industries to make your search more relevant"
      />
    </div>
  );
};

export default PreferredIndustry;
