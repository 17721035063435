import * as React from 'react';
import { Icon } from 'antd';

const EmailSvgV2 = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.1"
      d="M11.1911 12.5688L3.18481 6.34379L2.54028 5.84222L3.18716 6.34613V18.5618H20.8122V6.34613L12.8012 12.5688C12.571 12.7477 12.2877 12.8448 11.9961 12.8448C11.7046 12.8448 11.4213 12.7477 11.1911 12.5688V12.5688Z"
      fill="#83878C"
    />
    <path
      opacity="0.1"
      d="M20.5376 4.65863L21.4587 5.84221L20.8118 6.34612L21.461 5.84221L20.5399 4.65863H20.5376Z"
      fill="#83878C"
    />
    <path
      d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM19.5352 5.43984L12 11.2969L4.46484 5.43984H19.5352ZM20.8125 6.34687V18.5625H3.1875V6.34687L2.54063 5.84297L3.18516 6.34453L11.1914 12.5695C11.4217 12.7484 11.7049 12.8455 11.9965 12.8455C12.2881 12.8455 12.5713 12.7484 12.8016 12.5695L20.8125 6.34687L21.4594 5.84297L20.5383 4.65938H20.5406L21.4617 5.84297L20.8125 6.34687Z"
      fill="#83878C"
    />
  </svg>
);

const EmailIconV2 = props => <Icon component={customProps => <EmailSvgV2 {...props} {...customProps} />} {...props} />;
export default EmailIconV2;
