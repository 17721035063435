import React from 'react';
import PubSub from 'pubsub-js';
import { Button } from 'antd';
import { RESUME_DOWNLOAD } from '../../PubSub/EventTypes';
import styles from './ResumeDownloadButton.module.scss';
import eventTypes, { modules } from '../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';

function ResumeDownloadButton(props) {
  const {
    isDisable,
    downloadResume,
    allowResumeDownload,
    candidate = {},
    resume,
    portal,
    version,
    jobGuid,
    currentJobDetails,
    resumeDownloadApiStatus,
    resumeUnlockStatus,
    isUnlockable = false,
    isUnlocked,
    unlockCandidateResume,
    isSocialCandidate,
  } = props;
  const resumeDownloadLoadingFlag = resumeDownloadApiStatus === 'INPROGRESS';

  const onClick = () => {
    downloadResume(candidate, resume.OriginalName || resume.Name, candidate.Name, portal, version);
    PubSub.publish(RESUME_DOWNLOAD, {
      candidateId: candidate?.candidateId,
      jobGuid,
      planName: currentJobDetails?.OrderInfo?.ProductVariantName,
      candidateType: candidate?.Source?.Group,
    });
  };
  let resumeView;
  const loadingFlag = resumeUnlockStatus === 'INPROGRESS';

  const onClickResumeDownload = event => {
    event.stopPropagation();
    downloadResume(candidate, undefined, candidate.Name, portal, version);
    logSmartKarrotEvent(getEventNameByCandidateStatus(eventTypes.candidate.candidateResume.download, candidate), {
      Module: modules.candidate.candidateActions,
    });
  };

  if (isUnlockable && !isUnlocked) {
    resumeView = (
      <div className={styles.resumeUnlockButtonWrapper}>
        <Button
          size="default"
          type="primary"
          className={styles.resumeUnlockButton}
          onClick={event => {
            event.stopPropagation();
            unlockCandidateResume(candidate);
          }}
          loading={loadingFlag}
          sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateResume.unlock, candidate)}
        >
          {loadingFlag ? 'Unlocking' : <div>Unlock</div>}
        </Button>
      </div>
    );
  } else
    resumeView = (
      <Button
        disabled={isDisable || !resume}
        onClick={onClick}
        shape="round"
        loading={resumeDownloadLoadingFlag}
        size="default"
        className={styles.resumeDownloadButton}
        sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateResume.download, candidate)}
      >
        Download
      </Button>
    );
  if (allowResumeDownload && resume) return resumeView;
  if (isSocialCandidate)
    return (
      <div className={styles.downloadResume}>
        <Button
          shape="round"
          size="default"
          loading={resumeDownloadLoadingFlag}
          className={styles.resumeDownloadButton}
          onClick={onClickResumeDownload}
        >
          <span>Download</span>
        </Button>
      </div>
    );
  return null;
}

export default ResumeDownloadButton;
