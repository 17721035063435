import _ from 'lodash';
import {
  fetchTitleSuggestions,
  fetchOccupationSuggestions,
  fetchIndustrySuggestions,
  fetchCompanySuggestions,
  fetchSkillSuggestions,
  fetchSkillsSuggestionsForJob as _fetchSkillsSuggestionsForJob,
} from '../Repository/SuggestionsRepository';

const maxSuggestedSkillsCount = 6;

function setSuggestions(suggestions, suggestionsType, candidateId) {
  return {
    type: 'SET_SUGGESTIONS',
    payload: { suggestionsResponse: suggestions, suggestionsType, candidateId },
  };
}

function clearSuggestions(candidateId) {
  return {
    type: 'CLEAR_SUGGESTIONS',
    payload: candidateId,
  };
}

function setSuggestionsFetchApiStatus(apiStatus, suggestionType) {
  return {
    type: 'SET_SUGGESTIONS_FETCH_API_STATUS',
    payload: { [suggestionType]: apiStatus },
  };
}

const setSkillSuggestionApiStatus = (uniqueIndex, status) => ({
  type: 'SET_SKILL_SUGGESTION_API_STATUS',
  payload: {
    uniqueIndex,
    status,
  },
});

const setSkillsSuggestion = (suggestions, uniqueIndex) => ({
  type: 'SET_SKILL_SUGGESTIONS',
  payload: {
    suggestions,
    uniqueIndex,
  },
});

function fetchSuggestions(suggestionsPayload) {
  return async dispatch => {
    let suggestionsResponse;
    const tiocReasons = ['TITLE_MISMATCH', 'JOB_FUNCTION_MISMATCH', 'INDUSTRY_MISMATCH', 'COMPANY_MISMATCH'];
    const { suggestionsType, candidateId } = suggestionsPayload;
    try {
      dispatch(setSuggestionsFetchApiStatus('INPROGRESS', suggestionsType));
      switch (suggestionsType) {
        case 'TITLE_MISMATCH':
          suggestionsResponse = await fetchTitleSuggestions(suggestionsPayload);
          break;
        case 'JOB_FUNCTION_MISMATCH':
          suggestionsResponse = await fetchOccupationSuggestions(suggestionsPayload);
          break;
        case 'INDUSTRY_MISMATCH':
          suggestionsResponse = await fetchIndustrySuggestions(suggestionsPayload);
          break;
        case 'COMPANY_MISMATCH':
          suggestionsResponse = await fetchCompanySuggestions(suggestionsPayload);
          break;
        case 'MISSING_MANDATORY_SKILLS':
          {
            suggestionsResponse = {};
            const negativeSkills = Object.values(suggestionsPayload.skills).join(',');
            const suggestedSkills = await fetchSkillSuggestions({ ...suggestionsPayload, skills: negativeSkills });
            const similarSkills = suggestedSkills?.data?.SimilarSkills;
            Object.entries(suggestionsPayload.skills).forEach(negativeSkill => {
              const [skillKey] = negativeSkill;
              const currentSkill = negativeSkill[1];
              const currentSimilarSkills = similarSkills[currentSkill.toLowerCase()] ?? [];
              const filteredSuggestedSkillsValues = _.uniqBy(
                currentSimilarSkills?.filter(skill => skill.toLowerCase() !== currentSkill.toLowerCase()),
                skill => skill.toLowerCase()
              );
              suggestionsResponse[skillKey] = {
                selectedSkills: [currentSkill],
                suggestedSkills: filteredSuggestedSkillsValues.slice(0, maxSuggestedSkillsCount),
                allSkills: filteredSuggestedSkillsValues,
              };
            });
          }
          break;
        default:
          suggestionsResponse = null;
      }
      const suggestions = tiocReasons.includes(suggestionsType)
        ? _.uniqBy((suggestionsResponse?.data?.Negatives ?? []).concat(suggestionsPayload.negativeValue), suggestion =>
            suggestion.trim().toLowerCase()
          )
        : suggestionsResponse;
      dispatch(setSuggestions(suggestions, suggestionsType, candidateId));
      dispatch(setSuggestionsFetchApiStatus('COMPLETED', suggestionsType));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
        },
      });
      dispatch(setSuggestionsFetchApiStatus('FAILED', suggestionsType));
    }
  };
}

function fetchSkillSuggestionsForJob(jobId, skill, skillIndex) {
  return async dispatch => {
    try {
      dispatch(setSkillSuggestionApiStatus(skillIndex, 'INPROGRESS'));
      const response = await _fetchSkillsSuggestionsForJob(jobId, skill);
      const { SimilarSkills } = response.data;
      dispatch(setSkillsSuggestion(SimilarSkills[skill], skillIndex));
      dispatch(setSkillSuggestionApiStatus(skillIndex, 'COMPLETED'));
    } catch (error) {
      dispatch(setSkillSuggestionApiStatus(skillIndex, 'FAILED'));
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
        },
      });
    }
  };
}

export { fetchSuggestions, clearSuggestions, fetchSkillSuggestionsForJob };
