import React from 'react';
import _ from 'lodash';
import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import './JobShareNotification.scss';
import SegmentShareNotificationIcon from '../../../Icons/SegmentShareNotificationIcon';

function getIcon(notificationType) {
  switch (notificationType) {
    case 'SegmentShare':
    case 'SegmentUnshare':
      return (
        <div className="jobshared-image-wrapper">
          <div className="jobshared-image">
            <SegmentShareNotificationIcon style={{ fontSize: '40px' }} className="segmentShare" />
          </div>
        </div>
      );
    case 'JobShare':
    case 'JobUnshare':
      return (
        <div className="jobshared-image-wrapper">
          <div className="jobshared-image">
            <img src={`${process.env.PUBLIC_URL}/static/Images/notification-jobshared.svg`} alt={notificationType} />
          </div>
        </div>
      );
    default:
      return null;
  }
}

function JobShareNotification(props) {
  const { notification, onClick, userByGuId } = props;
  if (typeof notification.Payload === 'string') {
    notification.Payload = JSON.parse(notification.Payload);
  }
  const { Type, Payload, UpdatedTime, UnreadCount, SenderId } = notification;
  const { JobTitle, JobId } = Payload;
  const isJobSharedOrUnshared = Type === 'JobShare' || Type === 'JobUnshare';
  const isJobOrSegmentShared = Type === 'JobShare' || Type === 'SegmentShare';
  return (
    <div className="notification" role="presentation" onClick={() => onClick(notification)}>
      <div className="icon">{getIcon(Type)}</div>
      <div className="content">
        <div className="notification-title">
          <Tooltip
            overlayClassName="notification-user-name"
            title={`${_.get(userByGuId, [SenderId, 'FirstName'])} ${_.get(userByGuId, [SenderId, 'LastName'])}`}
          >
            <span className="bold">{_.get(userByGuId, [SenderId, 'FirstName'])}</span>
          </Tooltip>
          {' has '}
          <span className="bold">{isJobOrSegmentShared ? 'shared' : 'unshared'}</span>
          {' a '}
          <span className="bold"> {isJobSharedOrUnshared ? 'job' : 'segment'}</span> with you
        </div>
        <div className="message">
          {JobTitle}, {JobId}
        </div>
        <div className="actions" />
        <div className="since">
          {moment
            .utc(UpdatedTime)
            .local()
            .fromNow()}
        </div>
      </div>
      <div className="is-viewed">
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}

export default JobShareNotification;
