import React from 'react';
import { Icon } from 'antd';

export const WarningSvg = props => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <g id="New-User" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Alert" transform="translate(-448.000000, -381.000000)" fill="#FAAD14">
        <g id="Group-41" transform="translate(348.000000, 323.000000)">
          <g id="Shape" transform="translate(100.000000, 58.000000)">
            <path
              d="M12,22.3125 C13.3921875,22.3125 14.7421875,22.040625 16.0125,21.5039062 C17.240625,20.9835938 18.3421875,20.240625 19.2914063,19.29375 C20.2382812,18.346875 20.9835938,17.2429688 21.5015625,16.0148438 C22.040625,14.7421875 22.3125,13.3921875 22.3125,12 C22.3125,10.6078125 22.040625,9.2578125 21.5039062,7.9875 C20.9835938,6.759375 20.240625,5.6578125 19.29375,4.70859375 C18.346875,3.76171875 17.2429688,3.01640625 16.0148438,2.4984375 C14.7421875,1.959375 13.3921875,1.6875 12,1.6875 C10.6078125,1.6875 9.2578125,1.959375 7.9875,2.49609375 C6.759375,3.01640625 5.6578125,3.759375 4.70859375,4.70625 C3.76171875,5.653125 3.01640625,6.75703125 2.4984375,7.98515625 C1.959375,9.2578125 1.6875,10.6078125 1.6875,12 C1.6875,13.3921875 1.959375,14.7421875 2.49609375,16.0125 C3.01640625,17.240625 3.759375,18.3421875 4.70625,19.2914063 C5.653125,20.2382812 6.75703125,20.9835938 7.98515625,21.5015625 C9.2578125,22.040625 10.6078125,22.3125 12,22.3125 L12,22.3125 Z M12,24 C5.371875,24 0,18.628125 0,12 C0,5.371875 5.371875,0 12,0 C18.628125,0 24,5.371875 24,12 C24,18.628125 18.628125,24 12,24 L12,24 L12,24 Z M12,9 C11.4820312,9 11.0625,9.41953125 11.0625,9.9375 L11.0625,18.5859375 C11.0625,19.1039063 11.4820312,19.5234375 12,19.5234375 C12.5179688,19.5234375 12.9375,19.1039063 12.9375,18.5859375 L12.9375,9.9375 C12.9375,9.41953125 12.5179688,9 12,9 L12,9 Z M10.9453125,6.4453125 C10.9453125,7.02780032 11.4175122,7.5 12,7.5 C12.5824878,7.5 13.0546875,7.02780032 13.0546875,6.4453125 C13.0546875,5.86282468 12.5824878,5.390625 12,5.390625 C11.4175122,5.390625 10.9453125,5.86282468 10.9453125,6.4453125 L10.9453125,6.4453125 Z"
              transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const WarningIcon = props => <Icon component={WarningSvg} {...props} />;

export default WarningIcon;
