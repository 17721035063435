const viewJob = {
  goToJobDetails: 'GO_TO_JOB_DETAILS',
};

const shareJob = {};

const createJob = {};

const advertiseJob = {
  goToAdvertiseJob: 'GO_TO_ADVERTISE_JOB',
  cancelAdvertiseJob: 'CANCEL_ADVERTISE_JOB',
  submitAdvertiseJob: 'SUBMIT_ADVERTISE_JOB',
  editAdvertiseJob: 'EDIT_ADVERTISE_JOB',
  updateAdvertiseJob: 'UPDATE_ADVERTISE_JOB',
  cancelEditAdvertiseJob: 'CANCEL_EDIT_ADVERTISE_JOB',
  endAdvertiseJob: 'END_ADVERTISE_JOB',
  advertiseWithCard: 'ADVERTISE_WITH_CARD',
  advertiseWithAdCredit: 'ADVERTISE_WITH_AD_CREDIT',
  cancelAdvertisePayment: 'CANCEL_ADVERTISE_PAYMENT',
  advertiseJobPayment: 'ADVERTISE_JOB_PAYMENT',
  advertiseJobPaymentBack: 'ADVERTISE_JOB_PAYMENT_BACK',
  continueAdvertiseJob: 'CONTINUE_ADVERTISE_JOB',
  confirmEndAdvertiseJob: 'CONFIRM_END_ADVERTISE_JOB',
};

const shareJobSocialMedia = {
  facebook: 'SHARE_JOB_ON_FACEBOOK',
  twitter: 'SHARE_JOB_ON_TWITTER',
  linkedIn: 'SHARE_JOB_ON_LINKEDIN',
};

const publishJob = {
  goToPublishJob: 'GO_TO_PUBLISH_JOB',
  cancelPublishJob: 'CANCEL_PUBLISH_JOB',
  submitPublishJob: 'SUBMIT_PUBLISH_JOB',
  editPublishJob: 'EDIT_PUBLISH_JOB',
  updatePublishJob: 'UPDATE_PUBLISH_JOB',
  cancelEditPublishJob: 'CANCEL_EDIT_PUBLISH_JOB',
  recallPublishJob: 'RECALL_PUBLISH_JOB',
  confirmRecallPublishJob: 'CONFIRM_RECALL_PUBLISH_JOB',
  cancelRecallPublishJob: 'CANCEL_RECALL_PUBLISH_JOB',
};

const aryaBotConfig = {
  goToAryaBotConfig: 'GO_TO_ARYA_BOT_CONFIG',
  saveAryaBotConfig: 'SAVE_ARYA_BOT_CONFIG',
};

const emailAutomation = {};

const settings = {};

const editJob = {};

const copyJob = {};

const quickSearch = {
  goToQuickSearch: 'GO_TO_QUICK_SEARCH',
};

const refineSearch = {
  goToRefineSearch: 'GO_TO_REFINE_SEARCH',
  applyFilter: 'REFINE_SEARCH',
  saveFilter: 'SAVE_REFINE_SEARCH_CRITERIA',
  saveFilterUsingSampleBanner: 'SAVE_REFINE_SEARCH_CRITERIA_SAMPLE_BANNER',
  saveFilterUsingAdditionalBanner: 'SAVE_REFINE_SEARCH_CRITERIA_ADDITIONAL_BANNER',
  reactivateArya: 'REFINE_SEARCH_REACTIVATE_ARYA',
  deactivateArya: 'REFINE_SEARCH_DEACTIVATE_ARYA',
};

const manualSearch = {
  goToManualSearch: 'GO_TO_MANUAL_SEARCH',
  applyManualSearchCriteria: 'MANUAL_SEARCH',
  markProminent: 'MANUAL_SEARCH_MARK_PROMINENT',
  unmarkProminent: 'MANUAL_SEARCH_UMMARK_PROMINENT',
  saveManualSearchCriteria: 'SAVE_MANUAL_SEARCH_CRITERIA',
  resetManualSearchCriteria: 'RESET_MANUAL_SEARCH_CRITERIA',
  clearEducationFilter: 'CLEAR_MANUAL_SEARCH_EDUCATION_FILTER',
  clearEmploymentFilter: 'CLEAR_MANUAL_SEARCH_EMPLOYMENT_FILTER',
  clearTitlesFilter: 'CLEAR_MANUAL_SEARCH_TITLES_FILTER',
  clearSkillsFilter: 'CLEAR_MANUAL_SEARCH_SKILLS_FILTER',
  selectSimilarSkills: 'SELECT_MANUAL_SEARCH_SIMILAR_SKILLS',
  selectAcronyms: 'SELECT_MANUAL_SEARCH_SKILL_ACRONYMS',
  selectSuggestedSkills: 'SELECT_MANUAL_SEARCH_SUGGESTED_SKILLS',
  deselectSuggestedSkills: 'DESELECT_MANUAL_SEARCH_SUGGESTED_SKILLS',
  selectSuggestedTitles: 'SELECT_MANUAL_SEARCH_SUGGESTED_TITLES',
  deselectSuggestedTitles: 'DESELECT_MANUAL_SEARCH_SUGGESTED_TITLES',
  reactivateArya: 'MANUAL_SEARCH_REACTIVATE_ARYA',
  deactivateArya: 'MANUAL_SEARCH_DEACTIVATE_ARYA',
};

const aryaSourcing = {
  reactivateArya: 'JOB_REACTIVATE_ARYA',
  deactivateArya: 'JOB_DEACTIVATE_ARYA',
};

const marketIntel = {
  displayIndustryList: 'DISPLAY_INDUSTRY_LIST',
  hideIndustryList: 'HIDE_INDUSTRY_LIST',
  displayJobRoleList: 'DISPLAY_JOB_ROLE_LIST',
  hideJobRoleList: 'HIDE_JOB_ROLE_LIST',
  displayCompanyList: 'DISPLAY_COMPANY_LIST',
  hideCompanyList: 'HIDE_COMPANY_LIST',
  displaySkillList: 'DISPLAY_SKILL_LIST',
  hideSkillList: 'HIDE_SKILL_LIST',
  displayLocationList: 'DISPLAY_LOCATION_LIST',
  hideLocationList: 'HIDE_LOCATION_LIST',
  displayEducationList: 'DISPLAY_EDUCATION_LIST',
  hideEducationList: 'HIDE_EDUCATION_LIST',
  displayCertificationList: 'DISPLAY_CERTIFICATION_LIST',
  hideCertificationList: 'HIDE_CERTIFICATION_LIST',
};

const jobEventTypes = {
  advertiseJob,
  aryaBotConfig,
  copyJob,
  createJob,
  editJob,
  emailAutomation,
  publishJob,
  quickSearch,
  refineSearch,
  manualSearch,
  settings,
  shareJob,
  shareJobSocialMedia,
  viewJob,
  aryaSourcing,
  marketIntel,
};

export default jobEventTypes;
