import React from 'react';
import { message, Button } from 'antd';
import './SignupVerification.scss';
import { AccountVerification } from '../../Icons/AryaIcons';

export default function SignupVerification(props) {
  const { email, resendVerificationCode, confirmUserSignUp, isStripDownVersion } = props;

  return (
    <div className={isStripDownVersion ? 'minimal-verification-container' : 'signup-verfication-container'}>
      <div className="signup-verification">
        <div>
          {confirmUserSignUp ? null : <p className="verification-title"> Account Verification</p>}
          <AccountVerification />
          <p className="verification-info-title"> Check Your Email!</p>
          <p className="verification-info">
            {confirmUserSignUp
              ? 'We already have sent you verification email, please check it to confirm your email.'
              : `We sent an email to ${email} to verify your account`}
          </p>
          <p className="verification-code-resend">
            Didn't get an email?
            <Button type="link" className="resend-button" onClick={() => resendVerificationCode(email)}>
              Resend
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
