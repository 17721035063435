import React from 'react';
import { Tooltip, Icon } from 'antd';
import styles from './AtsPushComponent.module.scss';
import { getCandidateGuid, getResumeName } from '../../Utils/CandidateResume';
import PushIconV2 from '../../Icons/PushIconV2';

function AtsPushComponent(props) {
  const {
    candidate,
    atsSource = {},
    onPushCandToAts,
    isLoading,
    isPushAllowed,
    onClickAtsPush,
    isCandidateDownloaded,
    showContactInfoButton,
    showPushCandidateButton,
    originalSource,
    showPushError = true,
  } = props;
  if (atsSource && atsSource.CandidateId) {
    return (
      <Tooltip
        placement="left"
        title={<div>Client Candidate ID: {atsSource.CandidateId}</div>}
        overlayClassName="drawer-tooltip"
      >
        <div className={styles.candidateDetailPushedCandidate}>
          <PushIconV2
            onClick={e => {
              e.stopPropagation();
              onClickAtsPush(
                atsSource.CandidateId,
                getCandidateGuid(candidate),
                getResumeName(candidate),
                candidate.LatestResumeDate,
                originalSource ? originalSource.Portal : null
              );
            }}
            style={{ fontSize: '18px' }}
            fillColor="#07101a"
          />
        </div>
      </Tooltip>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loadinIcon}>
        <Icon type="loading" />
      </div>
    );
  }
  const shouldShowPushError = !!(showPushError && candidate?.DownloadedCandidateId && !atsSource?.CandidateId);

  if (shouldShowPushError) {
    return (
      <Tooltip title="Failed to push candidate. Please try manually.">
        <div className={styles.candidateDetailPushCandidateError} role="presentation" onClick={onPushCandToAts}>
          <PushIconV2 className={styles.pushStatusIcon} fillColor="#ffffff" style={{ fontSize: '18px' }} />
        </div>
      </Tooltip>
    );
  }

  const portal = candidate?.Portal?.toLowerCase() ?? '';
  const shouldShowInfoButton =
    showContactInfoButton &&
    isPushAllowed &&
    ((isCandidateDownloaded && candidate.IsDownloaded) || portal === 'social' || portal === 'indeed') &&
    showPushCandidateButton;

  if (shouldShowInfoButton) {
    return (
      <div
        className={styles.candidateDetailPushCandidate}
        role="presentation"
        onClick={e => {
          e.stopPropagation();
          onPushCandToAts();
        }}
      >
        <PushIconV2 className={styles.pushStatusIcon} style={{ fontSize: '18px' }} fillColor="#07101a" />
      </div>
    );
  }

  return null;
}

export default AtsPushComponent;
