import React from 'react';
import * as d3 from 'd3';
import './EducationRelevanceChart.scss';

const groupPlacementShift = { left: 10, top: 10 };

export function prepareEducationalRelevancyData(data) {
  const updatedData = [...data];
  const othersRelevancy = 100 - data.reduce((acc, val) => acc + val.Relevancy, 0);
  updatedData.push({
    Degree: 'Others',
    Relevancy: othersRelevancy,
  });
  return updatedData;
}

function createArc(height) {
  const outerRadius = height / 2;
  const innerRadius = outerRadius - 50;
  return d3
    .arc()
    .outerRadius(outerRadius)
    .innerRadius(innerRadius);
}

function createPie() {
  return d3
    .pie()
    .sort(null)
    .value(d => d.Relevancy);
}

export function getColorsForDegree(data) {
  const { Degree } = data;
  let color;
  switch (Degree) {
    case 'Bachelors':
      color = 'rgba(19,194,107,1)';
      break;
    case 'Masters':
      color = 'rgba(19,194,107,0.7)';
      break;
    case 'Phd':
      color = 'rgba(19,194,107,0.5)';
      break;
    default:
      color = '#83878C';
      break;
  }
  return color;
}

function getEducationLabels(data) {
  return data.map(d => (
    <div className="education-degree-label">
      <div className="education-degree-label-color" style={{ backgroundColor: getColorsForDegree(d) }}></div>
      <div className="education-degree-label-name">{d.Degree}</div>
    </div>
  ));
}

function onMouseOver(event, d) {
  const { Relevancy } = d.data;
  const tooltip = d3.select('.tooltip');
  const html = `
      <div class="tooltip-content">
        <p class="tooltip-heading">
          Education Distribution
        </p>
      <div class="tooltip-edu-details">
        <div class="tooltip-label-color"></div>
        <div class="tooltip-edu-degree"></div>
        <div class="tooltip-edu-relevancy"><div>
      </div>
      </div>
    `;

  tooltip
    .html(html)
    .style('left', `${event.nativeEvent.offsetX}px`)
    .style('top', `${event.nativeEvent.offsetY}px`)
    .transition()
    .duration(200)
    .style('opacity', 1);

  tooltip.select('.tooltip-edu-degree').html(d.data.Degree);
  tooltip.select('.tooltip-edu-relevancy').html(`${Relevancy.toFixed(2)}%`);
  tooltip.select('.tooltip-label-color').style('background-color', getColorsForDegree(d.data));

  d3.select(event.target).attr('filter', "url('#inner-shadow')");
}

function onMouseOut(event) {
  const tooltip = d3.select('.tooltip');
  tooltip
    .transition()
    .duration(300)
    .style('opacity', 0);

  d3.select(event.target).attr('filter', null);
}

class EducationRelevanceChart extends React.Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.state = {
      arc: null,
      pie: null,
    };
  }

  componentDidMount() {
    this.createDonutChart();
  }

  createDonutChart() {
    const { height } = this.props;
    this.setState({
      arc: createArc(height),
      pie: createPie(),
    });
  }

  getTextPosition({ arc, d }) {
    const { width, height } = this.props;
    const radius = Math.min(width, height) / 2 - 25;
    const c = arc.centroid(d);
    const x = c[0];
    const y = c[1];
    const h = Math.sqrt(x * x + y * y);
    return `translate(${(x / h) * radius}, ${(y / h) * radius})`;
  }

  getCandidateAndJobLabels = () => {
    const { EducationalRelevance, candidateName } = this.props;
    const { CandidateEducation, JobRequirement } = EducationalRelevance;
    return (
      <div className="candidate-and-job-labels">
        <div className="education-label-container">
          <div className="label-color label-for-job"></div>
          <div className="label-description">
            <div className="label-heading">Job Requirement</div>
            <div className="label-subheading">{JobRequirement}</div>
          </div>
        </div>
        <div className="education-label-container">
          <div className="label-color label-for-candidate"></div>
          <div className="label-description">
            <div className="label-heading">{candidateName}&apos;s Degree</div>
            <div className="label-subheading">{CandidateEducation}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { width, height, EducationalRelevance } = this.props;
    const { arc, pie } = this.state;
    const { RelevantEducations } = EducationalRelevance;
    const educationChartData = prepareEducationalRelevancyData(RelevantEducations);

    if (!arc || !pie) {
      return <div>Loading...</div>;
    }

    return (
      <div id="education-relevance-chart">
        <div id="donutChart">
          <svg ref={this.svgRef} width={width + 20} height={height + 20} className="pie-chart">
            <defs>
              <filter id="inner-shadow" y="-20" x="-20" height="400" width="500">
                <feGaussianBlur result="offset-blur" stdDeviation="5"></feGaussianBlur>
                <feOffset dx="1" dy="1"></feOffset>
                <feFlood floodColor="black" floodOpacity="1" result="color"></feFlood>
                <feComposite operator="in" in2="offset-blur"></feComposite>
                <feComponentTransfer>
                  <feFuncA slope="0.5"></feFuncA>
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
            </defs>
            <g
              transform={`translate(${width / 2 + groupPlacementShift.left}, ${height / 2 + groupPlacementShift.top})`}
              id="education-group"
            >
              {pie(educationChartData).map(d => {
                const { Relevancy } = d.data;
                return (
                  <React.Fragment key={d.data.Degree}>
                    <path
                      className="donut-arc"
                      fill={getColorsForDegree(d.data)}
                      d={arc(d)}
                      onFocus={() => {}}
                      onMouseOver={event => onMouseOver(event, d)}
                      onBlur={() => {}}
                      onMouseOut={event => onMouseOut(event)}
                    />

                    <text transform={this.getTextPosition({ arc, d })} textAnchor="middle" fontSize="12px">
                      {Relevancy > 5 ? `${Relevancy.toFixed(2)}%` : null}
                    </text>
                  </React.Fragment>
                );
              })}

              <g>
                <text textAnchor="middle" fontSize="16px" x="0" y="0">
                  <tspan x="0" y="-12">
                    Education
                  </tspan>
                  <tspan x="0" y="12">
                    Distribution
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
          <div className="tooltip"></div>
        </div>
        <div className="education-relevance-chart-labels">{getEducationLabels(educationChartData)}</div>
        {this.getCandidateAndJobLabels()}
      </div>
    );
  }
}

export default EducationRelevanceChart;
