export const CareerLevels = [
  'President',
  'ExecutiveLevel',
  'GeneralManager',
  'VicePresident',
  'Director',
  'Head',
  'Manager',
  'Lead',
  'Other',
  'Analyst',
  'Representative',
  'Specialist',
  'Clerk',
  'Coordinator',
  'Assistant',
];

export const SalaryMaxLimit = {
  Hourly: 1032444,
  Yearly: 2147483647,
};

export const MinEducationLevels = [
  'HighSchoolOrEquivalent',
  'Certification',
  'Vocational',
  'AssociateDegree',
  'BachelorsDegree',
  'MastersDegree',
  'Doctorate',
  'Professional',
  'CollegeCourseworkCompleted',
  'HighSchoolCoursework',
];

export const TargetJobTypes = [
  {
    value: 'FullTime',
    displayText: 'Full-Time',
  },
  {
    value: 'PartTime',
    displayText: 'Part-Time',
  },
  {
    value: 'Intern',
    displayText: 'Intern',
  },
  {
    value: 'TempContractSeasonal',
    displayText: 'Temporary/Contract/Seasonal',
  },
];

export const WorkAuthorizationTypes = [
  {
    value: 'ShowAll',
    displayText: 'Show all job seekers',
  },
  {
    value: 'AnyEmployer',
    displayText: 'Limit to job seekers authorized to work in this country for any employer',
  },
];

export const WillingnessesToTravel = [25, 50, 75, 100];

export const CurrencyCodes = [
  'USD',
  'EUR',
  'ARS',
  'AUD',
  'BEF',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DEM',
  'ESP',
  'FJD',
  'FRF',
  'GBP',
  'GRD',
  'HKD',
  'HUF',
  'IDR',
  'IEP',
  'ILS',
  'INR',
  'ITL',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NLG',
  'NZD',
  'PLN',
  'RUR',
  'SEK',
  'SGD',
  'TWD',
  'ZAR',
  'LUF',
  'NOK',
  'DKK',
  'FIM',
  'SKK',
  'ROL',
  'IQD',
  'PHP',
  'SAR',
  'THB',
  'AED',
  'CLP',
  'COP',
  'GTQ',
  'PEN',
  'TND',
  'TRY',
  'TTD',
  'UYU',
  'VEB',
  'PKR',
  'EGP',
  'PGK',
  'BOB',
  'PYG',
  'MTL',
  'BGN',
  'CYP',
  'EEK',
  'ISK',
  'LVL',
  'LTL',
  'SIT',
];

export const SecurityClearance = [
  {
    value: 'ActiveConfidential',
    displayText: 'Active Confidential',
  },
  {
    value: 'ActiveSecret',
    displayText: 'Active Secret',
  },
  {
    value: 'ActiveTopSecret',
    displayText: 'Active Top Secret',
  },
  {
    value: 'ActiveTopSecretSCI',
    displayText: 'Active Top Secret/SCI',
  },
  {
    value: 'ActiveTSSCICIPolygraph',
    displayText: 'Active TS/SCI-CI Polygraph',
  },
  {
    value: 'ActiveTSSCIFSPolygraph',
    displayText: 'Active TS/SCI-FS Polygraph',
  },
  {
    value: 'InactiveClearance',
    displayText: 'Inactive Clearance',
  },
  {
    value: 'OtherActiveClearance',
    displayText: 'Other Active Clearance',
  },
];

export const JobDuration = [1, 2, 5, 10];

export const LanguageProficiencyLevels = [
  {
    value: 'Unknown',
    displayText: 'Unknown',
  },
  {
    value: 'Beginner',
    displayText: 'Beginner',
  },
  {
    value: 'Intermediate',
    displayText: 'Intermediate',
  },
  {
    value: 'Advanced',
    displayText: 'Advanced',
  },
  {
    value: 'Fluent',
    displayText: 'Fluent',
  },
];
