import { setApiStatus } from '../ApiStatusActions';

export const SET_IS_QUOTE_REQUESTED_BY_USER = 'SET_IS_QUOTE_REQUESTED_BY_USER';

export function setRequestQuoteApiStatus(status) {
  const apiName = 'AoDRequestQuoteApiStatus';
  return setApiStatus({ apiName, status });
}

export function setQuoteRequestedByUserApiStatus(status) {
  const apiName = 'QuoteRequestedByUserApiStatus';
  return setApiStatus({ apiName, status });
}

export function setIsQuoteRequestedByUser(payload) {
  return {
    type: SET_IS_QUOTE_REQUESTED_BY_USER,
    payload,
  };
}
