import React from 'react';
import MinMaxExperience from '../MinMaxExperience/MinMaxExperience';
import { FormRadioGroupComponent } from '../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../Utils/MapperUtils';
import './ExperienceMismatchContent.scss';

const experienceMismatchOptions = { 'Less Experienced': 'lessExp', 'Over Experienced': 'moreExp' };
export default function ExperienceMismatchContent(props) {
  const { initalExperienceMismatchReason, initialMinExperience, initialMaxExperience, form, experienceRange } = props;
  const { MinExperience, MaxExperience } = experienceRange;
  return (
    <div className="experience-mismatch-wrapper">
      <div className="experience-mismatch-reason">
        <div className="experience-mismatch-header">Select experience mismatch reasons</div>
        <FormRadioGroupComponent
          className="experience-mismatch-radio-buttons"
          fieldDecoratorValue="EXPERIENCE_MISMATCH"
          form={form}
          initialValue={initalExperienceMismatchReason}
          inputValues={formValuesMapper(experienceMismatchOptions)}
        />
      </div>
      <div className="experience-mismatch-divider" />
      {!(MinExperience || MaxExperience) ? (
        <div className="years-of-experience-range">
          <div className="years-of-experience-range-header">
            Would you like to set a required years of experience range?
          </div>
          <div className="min-max-experience-wrapper">
            <MinMaxExperience
              form={form}
              initialMinExperience={initialMinExperience}
              initialMaxExperience={initialMaxExperience}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
