import { apiV3Client, smartJobV1Client, atlasApiV1Client } from './BaseRepository';

export function getCountries() {
  return apiV3Client.get('countries', {
    params: {
      subscribed: true,
    },
  });
}
export function getSmartjobCountries() {
  return smartJobV1Client.get('Countries');
}

export function getStateSuggestions({ location, country, layers }) {
  return atlasApiV1Client.get(`locations/_autocomplete?location=${location}&country=${country}&layers=${layers}`);
}

export function getCitySuggestions({ country, state, location, layers }) {
  return atlasApiV1Client.get(
    `locations/_autocomplete?layers=${layers}&country=${country}&focus_point=${state.coordinates.latitude},${state.coordinates.longitude}&location=${location}`
  );
}
export function getCityAndStateSuggestions({ location, country }) {
  return atlasApiV1Client.get(`locations/_autocomplete?location=${location}&country=${country}`);
}
export function getLocations(filter) {
  return apiV3Client.post('locations', {
    Country: filter.country || 'US',
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}
export function getSmartjobLocations(filter) {
  return smartJobV1Client.post('locations', {
    Country: filter.country,
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}
