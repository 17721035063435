import React from 'react';
import { useDispatch } from 'react-redux';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { setNavbarRenderStatus } from '../../Actions/ConfigActions';

function BullhornAtsRedirectUrl(props) {
  const { history } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setNavbarRenderStatus(false));
    (async function _connectRedirection() {
      history.replace(`/connect`);
    })();
  }, []);

  return <LoadingScreen active loading />;
}

export default BullhornAtsRedirectUrl;
