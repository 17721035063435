/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import _ from 'lodash';
import { isCandidateIntelV2Data } from '../../Utils/CandidateIntelUtils';
import ScoreDetail from '../../Components/ScoreDetails/ScoreDetails';
import { NetworkErrorComponent } from '../../Components/ErrorComponent/NetworkErrorComponent';
import NoCandidateIntel from '../../Components/CandidateIntel/NoCandidateIntel';
import CandidateIntel from '../../Components/CandidateIntel/CandidateIntel';
import { getCandidateIntelApiStatus, getCandidateDetailApiStatus } from '../../Reducers/ApiStatusReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';

const mapStateToProps = state => ({
  candidateIntelApiStatus: getCandidateIntelApiStatus(state),
  candidateDetailApiStatus: getCandidateDetailApiStatus(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

class CandidateIntelContainer extends React.PureComponent {
  render() {
    const {
      candidateIntel,
      candidateIntelApiStatus,
      candidateName,
      whiteLabelInfo,
      score,
      jobTitle,
      isCandidateViewHeaderVisible,
      setCandidateViewHeaderVisibility,
      candidateDetailApiStatus,
      isCandidateIntelDownloadEnabled,
    } = this.props;
    const scoreDetailProps = {
      candidateName,
      score,
      jobTitle,
      candidateIntel: candidateIntel?.CandidateIntelV2,
      setCandidateViewHeaderVisibility,
      isCandidateViewHeaderVisible,
      isCandidateIntelDownloadEnabled,
      candidateIntelV1: candidateIntel?.CandidateIntelV1,
    };
    const [candidateFirstName] = candidateName?.split(' ') || [''];

    const displayComponent = {
      V1: (
        <CandidateIntel
          candidateIntel={candidateIntel?.CandidateIntelV1}
          candidateName={candidateFirstName}
          AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
        />
      ),
      V2: <ScoreDetail {...scoreDetailProps} />,
      'Not found': <NoCandidateIntel />,
    };
    let candidateIntelVersion;
    if (candidateIntel) {
      const { CandidateIntelV1, CandidateIntelV2 } = candidateIntel;
      if (CandidateIntelV2 && isCandidateIntelV2Data(CandidateIntelV2)) candidateIntelVersion = 'V2';
      // else if (CandidateIntelV1) candidateIntelVersion = 'V1';
      else candidateIntelVersion = 'Not found';
    } else if (candidateIntelApiStatus === 'COMPLETED') {
      candidateIntelVersion = 'Not found';
    }

    if (candidateIntelApiStatus === 'FAILED') return <NetworkErrorComponent />;

    return (
      <Skeleton
        paragraph={{ rows: 12 }}
        loading={candidateIntelApiStatus === 'INPROGRESS' || candidateDetailApiStatus === 'INPROGRESS'}
      >
        {displayComponent[candidateIntelVersion]}
      </Skeleton>
    );
  }
}

export default connect(mapStateToProps, null)(CandidateIntelContainer);
export { CandidateIntelContainer as CandidateIntelContainerWithoutStore };
