import { Icon } from 'antd';
import * as React from 'react';

const CopySvg = props => (
  <svg width={14} height={14} fill="none" {...props}>
    <path
      d="M10.75 2.75h-9.5a.5.5 0 0 0-.5.5v9.5a.5.5 0 0 0 .5.5h9.5a.5.5 0 0 0 .5-.5v-9.5a.5.5 0 0 0-.5-.5Zm-.625 9.375h-8.25v-8.25h8.25v8.25ZM12.75.75H3.125A.125.125 0 0 0 3 .875v.875c0 .069.056.125.125.125h9v9c0 .069.056.125.125.125h.875a.125.125 0 0 0 .125-.125V1.25a.5.5 0 0 0-.5-.5ZM3.687 7.594h4.626v1H3.686v-1Z"
      fill="#83878C"
    />
  </svg>
);

const CopyIcon = props => <Icon component={() => CopySvg(props)} {...props} />;
export default CopyIcon;
