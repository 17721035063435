import React from 'react';
import _ from 'lodash';
import { TreeSelect, Tooltip } from 'antd';
import styles from './TreeSelectDropdown.module.scss';

export function getTreeData(data = []) {
  const parents = _.cloneDeep(data);
  return parents.map(parent => ({
    value: parent?.Id,
    title: <Tooltip title={parent?.Name}>{parent?.Name}</Tooltip>,
    key: parent?.Id,
    children: parent?.children?.map(child => ({
      title: (
        <Tooltip title={child?.Title || child?.Name} placement="topLeft">
          <div className={styles.treeNodeTooltipWrapper}>{child?.Name}</div>
        </Tooltip>
      ),
      value: child?.Value || child?.Id,
      key: child?.Id,
      label: [child?.Name, child?.Title],
    })),
  }));
}
export default function TreeSelectDropdown(props) {
  const { placeholder, optionValues, onSearch, className, onChange, value, disabled } = props;
  return (
    <TreeSelect
      placeholder={placeholder}
      className={className}
      treeCheckable
      dropdownClassName={styles.treeSelectDropdown}
      treeData={getTreeData(optionValues)}
      getPopupContainer={node => node.parentNode}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      dropdownMatchSelectWidth
      searchPlaceholder="Type to search"
      onSearch={onSearch}
      showSearch
      treeDefaultExpandAll
      treeNodeFilterProp="label"
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
}
