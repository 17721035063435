import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Pagination, Tabs } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseTemplateContainer from '../BaseTemplatesContainer';
import CreateTextTemplate from '../../../Components/Connect/BaseTemplateForm/CreateTextTemplate/CreateTextTemplate';
import UserTextTemplateRow from '../../../Components/Connect/TemplateRow/UserTextTemplateRow/UserTextTemplateRow';
import SharedTextTemplateRow from '../../../Components/Connect/TemplateRow/SharedTextTemplateRow/SharedTextTemplateRow';
import TemplateHeader from '../../../Components/Connect/TemplateHeader/TemplateHeader';
import getMergeTags from '../../../Actions/MergeTagsActions';
import * as ConnectActions from '../../../Actions/ConnectActions';
import * as MergeTagsReducer from '../../../Reducers/MergeTagsReducer';

import * as TextTemplatesActions from '../../../Actions/TextTemplates';
import * as UserActions from '../../../Actions/UserActions';
import {
  getCurrentTextTemplateIds,
  getTextTemplatesById,
  getTextTemplatesCount,
} from '../../../Reducers/TextTemplates';
import messages from './messages';
import './TextTemplates.css';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getConnectUsersById,
  getUsersByGuId,
  getUserListForShareTemplate,
  getConnectMaxCount,
} from '../../../Reducers/UserReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags } from '../../../Utils/MergeTagsUtils';
import { connectTemplateNameByType } from '../../../Utils/ConnectUtils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  currentTextTemplateIds: getCurrentTextTemplateIds(state),
  textTemplatesById: getTextTemplatesById(state),
  textTemplatesCount: getTextTemplatesCount(state),
  mergeTags: MergeTagsReducer.getMergeTags(state),
  testTemplateStatus: getApiStatus(state, 'testTextTemplateStatus'),
  connectUsersById: getConnectUsersById(state),
  usersByGuId: getUsersByGuId(state),
  userListForShareTemplate: getUserListForShareTemplate(state),
  maxCount: getConnectMaxCount(state),
  CurrentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  searchTextTemplates: TextTemplatesActions.searchTextTemplates,
  postTextTemplate: TextTemplatesActions.postTextTemplate,
  editTextTemplate: TextTemplatesActions.editTextTemplate,
  deleteTextTemplate: TextTemplatesActions.deleteTextTemplate,
  duplicateTextTemplate: TextTemplatesActions.duplicateTextTemplate,
  testTextTemplate: TextTemplatesActions.testTextTemplate,
  clearTestTextTemplateState: TextTemplatesActions.clearTestTextTemplateState,
  fetchMergeTags: getMergeTags,
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
  shareTextTemplate: TextTemplatesActions.shareTextTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
};

class TextTemplatesContainer extends BaseTemplateContainer {
  constructor(props) {
    super(props);
    this.state = {
      createDrawerVisibility: false,
      editDrawerVisibility: false,
      page: 1,
      pageSize: 10,
      searchQuery: '',
      shared: false,
    };
    this.searchTemplates = this.searchTemplates.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  componentDidMount() {
    this.searchTemplates();
  }

  searchTemplates() {
    const { page, pageSize, searchQuery, shared } = this.state;
    const { searchTextTemplates } = this.props;
    searchTextTemplates({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    });
  }

  createTemplate(template) {
    const { postTextTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    postTextTemplate(template, filter);
    this.closeCreateDrawer();
  }

  closeEditDrawer() {
    const { clearTestTextTemplateState } = this.props;
    clearTestTextTemplateState();
    this.setState({
      editDrawerVisibility: false,
    });
  }

  closeCreateDrawer() {
    const { clearTestTextTemplateState } = this.props;
    clearTestTextTemplateState();
    this.setState({
      createDrawerVisibility: false,
    });
  }

  editTemplate(template) {
    const { editTextTemplate } = this.props;
    const { page, pageSize, searchQuery, shared, editTemplateId } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    editTextTemplate(editTemplateId, template, filter);
    this.closeEditDrawer();
  }

  deleteTemplate(templateId) {
    const { deleteTextTemplate, currentTextTemplateIds } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const shouldGoBack = currentTextTemplateIds.length === 1 && page !== 1;
    const updatedPage = shouldGoBack ? page - 1 : page;
    const filter = {
      From: (updatedPage - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    deleteTextTemplate(templateId, filter);
    this.setState({ page: updatedPage });
  }

  duplicateTemplate(templateId) {
    const { duplicateTextTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    duplicateTextTemplate(templateId, filter);
  }

  render() {
    const {
      intl,
      textTemplatesById,
      currentTextTemplateIds,
      textTemplatesCount,
      mergeTags,
      testTemplateStatus,
      testTextTemplate,
      connectUsersById,
      maxCount,
      CurrentUser,
      usersByGuId,
      userListForShareTemplate,
      shareTextTemplate,
      fetchUsersForShare,
      featureToggleList,
      setInvalidTemplateNotification,
      version,
      jobDetails,
    } = this.props;
    const { createDrawerVisibility, editDrawerVisibility, editTemplateId, page, pageSize, shared } = this.state;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const filteredMergeTags = getFilteredMergeTags(
      mergeTagsForMessage,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      jobDetails
    );
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreation },
    } = featureToggleList;
    let isViewOnly = false;
    if (editTemplateId)
      isViewOnly = shared || (textTemplatesById[editTemplateId].IsConsent && !messageConsentTemplateCreation);

    return (
      <div className="text-templates">
        <TemplateHeader
          onClick={this.showCreateDrawer}
          // onSearch={this.onSearch}
          onChange={event => this.onSearch(event.target.value)}
          heading={<FormattedMessage {...messages.textTemplates} />}
          placeholder={intl.formatMessage({ ...messages.searchTextTemplates })}
          type={connectTemplateNameByType.sms}
        />
        {createDrawerVisibility ? (
          <CreateTextTemplate
            visible={createDrawerVisibility}
            onClose={this.closeCreateDrawer}
            title={intl.formatMessage({ ...messages.textTemplate })}
            textTemplateName={intl.formatMessage({ ...messages.textTemplateName })}
            onSubmit={this.createTemplate}
            initialValues={{}}
            mergeTags={filteredMergeTags}
            testTemplateStatus={testTemplateStatus}
            testTextTemplate={testTextTemplate}
            setInvalidTemplateNotification={setInvalidTemplateNotification}
            featureToggleList={featureToggleList}
          />
        ) : null}
        {editDrawerVisibility ? (
          <CreateTextTemplate
            visible={editDrawerVisibility}
            onClose={this.closeEditDrawer}
            title={intl.formatMessage({ ...messages.textTemplate })}
            textTemplateName={intl.formatMessage({ ...messages.textTemplateName })}
            onSubmit={this.editTemplate}
            initialValues={textTemplatesById[editTemplateId]}
            mergeTags={filteredMergeTags}
            testTemplateStatus={testTemplateStatus}
            testTextTemplate={testTextTemplate}
            viewOnly={isViewOnly}
            setInvalidTemplateNotification={setInvalidTemplateNotification}
            featureToggleList={featureToggleList}
          />
        ) : null}

        <Tabs defaultActiveKey="my-templates" onChange={this.onTabChange}>
          <TabPane tab={<FormattedMessage {...messages.myTemplates} />} key="my-templates">
            <Row className="text template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.template} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.shared} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              {/* <Col span={4}>
                <FormattedMessage {...messages.shared} />
              </Col> */}
            </Row>
            {currentTextTemplateIds.map(templateId => {
              const textTemplate = textTemplatesById[templateId];
              return (
                <UserTextTemplateRow
                  key={textTemplate.Id}
                  title={textTemplate.Name}
                  updatedTime={textTemplate.UpdatedTime}
                  isPublic={textTemplate.IsPublic}
                  isConsent={textTemplate.IsConsent}
                  sharedCount={3}
                  deleteTemplate={this.deleteTemplate}
                  duplicateTemplate={this.duplicateTemplate}
                  editTemplate={this.showEditDrawer}
                  shareTextTemplate={shareTextTemplate}
                  templateId={templateId}
                  usersByGuId={usersByGuId}
                  userListForShareTemplate={userListForShareTemplate}
                  sharedUsers={_.uniq(textTemplatesById[templateId].SharedWith || [])}
                  maxCount={maxCount}
                  CurrentUser={CurrentUser}
                  fetchUsersForShare={fetchUsersForShare}
                  featureToggleList={featureToggleList}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={textTemplatesCount}
              onChange={this.onPageChange}
              onShowSizeChange={this.onShowSizeChange}
              hideOnSinglePage={textTemplatesCount <= 10}
              current={page}
              pageSize={pageSize}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage {...messages.sharedTemplates} />} key="shared-templates">
            <Row className="text template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.template} />
              </Col>
              <Col span={5}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              <Col span={5}>
                <FormattedMessage {...messages.createdBy} />
              </Col>
            </Row>
            {currentTextTemplateIds.map(templateId => {
              const textTemplate = textTemplatesById[templateId];
              const firstName = _.get(connectUsersById, [textTemplate.CreatedBy, 'FirstName'], '');
              const lastName = _.get(connectUsersById, [textTemplate.CreatedBy, 'LastName'], '');
              const isCloneDisable = textTemplate.IsConsent && !messageConsentTemplateCreation;
              return (
                <SharedTextTemplateRow
                  key={textTemplate.Id}
                  id={textTemplate.Id}
                  title={textTemplate.Name}
                  updatedTime={textTemplate.UpdatedTime}
                  createdBy={`${firstName} ${lastName}`}
                  cloneTemplate={this.duplicateTemplate}
                  isConsent={textTemplate.IsConsent}
                  viewTemplate={this.showEditDrawer}
                  isCloneDisable={isCloneDisable}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={textTemplatesCount}
              onChange={this.onPageChange}
              onShowSizeChange={this.onShowSizeChange}
              hideOnSinglePage={textTemplatesCount <= 10}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

TextTemplatesContainer.defaultProps = {
  textTemplatesCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TextTemplatesContainer));
export { TextTemplatesContainer as TextTemplatesContainerWithoutStore };
