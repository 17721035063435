import * as React from 'react';
import { Icon } from 'antd';

const FilterSvg = props => (
  <svg width={20} height={25} fill="none" {...props}>
    <path
      d="M0 2.473V0h19.34v2.473l-6.593 8.241H6.593L0 2.473ZM7.033 21.703v-9.89h5.275V25l-5.275-3.297Z"
      fill="#83878C"
    />
  </svg>
);
const FilterIcon = props => <Icon component={() => FilterSvg(props)} {...props} />;
export default FilterIcon;
