import React from 'react';
import { Upload, message } from 'antd';

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

class JobResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleUpload = this.handleUpload.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleUpload(e) {
    const { uploadJobResume, jobId, removeJobResume } = this.props;
    if (e.file.status !== 'removed') {
      if (e.file.size / 1024 / 1024 > 1) {
        message.error('File must be smaller than 1MB');
      } else if (!['doc', 'docx', 'pdf', 'txt', 'html'].includes(e.file.name.split('.').pop())) {
        message.error('This file type is not supported');
      } else {
        uploadJobResume(jobId, e.file);
      }
    } else {
      removeJobResume(jobId, e.file.uid);
    }
  }

  handleClick(e) {
    const { downloadJobResume, jobId } = this.props;
    downloadJobResume(jobId, e.uid, e.name);
  }

  render() {
    const { resumes, uploadButton } = this.props;
    return (
      <Upload
        accept=".doc,.docx,.pdf,.html,.txt"
        fileList={resumes}
        beforeUpload={() => false}
        onChange={this.handleUpload}
        onDownload={this.handleClick}
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: true,
        }}
      >
        {resumes.length < 4 ? uploadButton : null}
      </Upload>
    );
  }
}

export default JobResume;
