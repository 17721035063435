import * as React from 'react';
import { Icon } from 'antd';

const PushSvg = props => {
  const { fillColor } = props;
  const fill = fillColor ?? '#b4b7ba';
  return (
    <svg width={15} height={14} viewBox="0 0 15 14" {...props}>
      <path
        d="M3.414 3.625a.504.504 0 0 1-.031-.313.52.52 0 0 1 .156-.28L6.613.16a.627.627 0 0 1 .836 0l3.074 2.871a.52.52 0 0 1 .157.281.504.504 0 0 1-.032.313.554.554 0 0 1-.214.25.678.678 0 0 1-.325.094H7.617v4.234a.53.53 0 0 1-.172.387.591.591 0 0 1-.414.16.591.591 0 0 1-.414-.16.53.53 0 0 1-.172-.387V3.97H3.953a.678.678 0 0 1-.324-.094.554.554 0 0 1-.215-.25Zm10.063 4.031a.61.61 0 0 0-.415.16.537.537 0 0 0-.171.387v3.828H1.17V8.203A.537.537 0 0 0 1 7.816a.61.61 0 0 0-.414-.16.61.61 0 0 0-.414.16.537.537 0 0 0-.172.387v3.828c0 .29.125.57.344.774a1.2 1.2 0 0 0 .828.32H12.89a1.2 1.2 0 0 0 .828-.32 1.06 1.06 0 0 0 .344-.774V8.203a.537.537 0 0 0-.172-.387.61.61 0 0 0-.414-.16Zm0 0"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill,
          fillOpacity: 1,
        }}
      />
    </svg>
  );
};

const PushIconV2 = props => <Icon component={customProps => <PushSvg {...props} {...customProps} />} {...props} />;
export default PushIconV2;
