export const filterConfiguration = {
  apply: 'Apply',
  clear: 'Clear',
  filter: 'Filter',
  filterIconToolTipTitle: 'Filters',
  filterValue: 'rejectedBy',
  filterTitle: 'Rejected By',
  filterValueAggregation: 'Reject',
  filterParameter: 'Statuses',
};

export const SR = 'Rejected';
export const CUSTOMER = 'Shortlisted';

export const showCandidateRejectFilter = (activeTab, isPulse, isServiceRep) => {
  return activeTab === 'rejected' && isPulse && !isServiceRep;
};
export const defaultFilter = {
  [filterConfiguration.filterParameter]: [],
};
