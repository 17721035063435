import React from 'react';
import { Icon } from 'antd';

export const JobEditSvg = props => (
  <svg width="15px" height="15px" viewBox="0 0 15 15" {...props}>
    <defs>
      <filter colorInterpolationFilters="auto" id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.721232 0 0 0 0 0.721232 0 0 0 0 0.721232 0 0 0 1.000000 0"
        />
      </filter>
      <filter colorInterpolationFilters="auto" id="filter-2">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.478431 0 0 0 0 0.478431 0 0 0 0 0.478431 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g id="New-User" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="New-User_Search-9" transform="translate(-1167.000000, -535.000000)">
        <g id="Group-7-Copy" transform="translate(147.000000, 388.000000)" filter="url(#filter-1)">
          <g transform="translate(1020.000000, 147.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.000000)">
              <line
                x1={1.00030034}
                y1={13.47879}
                x2={14.0003003}
                y2={13.47879}
                id="Line-5"
                stroke="#7A7A7A"
                strokeWidth={1.5}
                strokeLinecap="round"
              />
              <g id="Fill-1456-+-Fill-1457" transform="translate(0.000000, 0.000000)" filter="url(#filter-2)">
                <g>
                  <path
                    d="M12.7965103,4.2875 L13.3572103,3.7268 C14.2133103,2.8714 14.2133103,1.4777 13.3572103,0.6216 C12.5277103,-0.2072 11.0822103,-0.2072 10.2520103,0.6216 L9.68711034,1.1872 L12.7965103,4.2875 Z"
                    id="Fill-1456"
                    fill="#757575"
                  />
                  <path
                    d="M11.8011803,5.28108 L5.42488034,11.65738 C5.37028034,11.71128 5.31008034,11.75538 5.24288034,11.78898 L1.01278034,13.90438 C0.743280345,14.03948 0.417780345,13.98558 0.204980345,13.77278 C-0.00781965547,13.56068 -0.0610196555,13.23448 0.0740803445,12.96568 L2.18878034,8.73558 C2.22238034,8.66838 2.26648034,8.60748 2.32038034,8.55358 L8.69668034,2.17658 L11.8011803,5.28108 Z"
                    id="Fill-1457"
                    fill="#757575"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const JobEditIcon = props => <Icon component={JobEditSvg} {...props} />;

export default JobEditIcon;
