import React from 'react';
import { Select, Input, Button, Form } from 'antd';
import { getCountryCallingCode } from 'libphonenumber-js/min';
import PhoneNumberItem from '../PhoneNumberItem/PhoneNumberItem';
import './CallSettings.scss';

function validatePhone(rule, value, callback) {
  const regex = /^\+(?:[0-9] ?){4,14}[0-9]$/;
  if (!value.trim().length || regex.test(value)) {
    callback();
    // Valid international phone number
  } else {
    callback('Please input valid E164 Format number');
  }
}

class CallSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CountryCode: 'US',
      CallerId: '',
    };
    this.changeInputOnCountryChange = this.changeInputOnCountryChange.bind(this);
    this.onAddPhoneNumber = this.onAddPhoneNumber.bind(this);
    this.onDeletePhoneNumber = this.onDeletePhoneNumber.bind(this);
  }

  onAddPhoneNumber() {
    const { form, updateCallerIds } = this.props;
    const values = form.getFieldsValue();
    const { CallerId, CountryCode } = values;
    const callerId = {
      CallerId,
      CountryCode,
    };
    form.validateFieldsAndScroll(err => {
      if (!err) {
        updateCallerIds([
          {
            Action: 'Add',
            CallerId: callerId,
          },
        ]);
      }
    });
  }

  onDeletePhoneNumber(index) {
    const { phoneNumbers, updateCallerIds } = this.props;
    const callerId = phoneNumbers[index];
    updateCallerIds([
      {
        Action: 'Remove',
        CallerId: callerId,
      },
    ]);
  }

  changeInputOnCountryChange(value) {
    const { CountryCode } = this.state;
    let { CallerId } = this.state;
    const { form } = this.props;
    const newCountryCallingCode = getCountryCallingCode(value);
    const previousCountryCallingCode = getCountryCallingCode(CountryCode);
    if (CallerId) {
      CallerId = CallerId.substr(previousCountryCallingCode.length + 1);
    }
    CallerId = `+${newCountryCallingCode}${CallerId}`;
    form.setFieldsValue({
      CountryCode: value,
      CallerId,
    });
    this.setState({
      CountryCode: value,
      CallerId,
    });
  }

  render() {
    const { Option } = Select;
    const { countries, phoneNumbers, form } = this.props;
    const countriesMenu = countries
      .sort((c1, c2) => {
        if (c1.Name.toLowerCase() < c2.Name.toLowerCase()) {
          return -1;
        }
        if (c2.Name.toLowerCase() < c1.Name.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map(country => {
        try {
          const countryCallingCode = getCountryCallingCode(country.Iso2Code);
          return (
            <Option
              className="country-calling-option"
              key={country.Iso2Code}
              value={country.Iso2Code}
            >{`${country.Name} (+${countryCallingCode})`}</Option>
          );
        } catch (error) {
          return null;
        }
      });
    return (
      <div>
        <div className="call-settings-header">
          <div className="call-configuration-title">Call Configuration</div>
          <div>Setup your phone calling configuration here</div>
        </div>
        <div className="add-number-fields">
          <div className="add-number">
            <Form.Item label="Phone number" colon={false}>
              {form.getFieldDecorator('CountryCode', { initialValue: 'US' })(
                <Select
                  className="country-select"
                  onSelect={value => this.changeInputOnCountryChange(value)}
                  dropdownClassName="calling-codes-dropdown"
                >
                  {countriesMenu}
                </Select>
              )}
            </Form.Item>
            <Form.Item style={{ height: '33px' }}>
              {form.getFieldDecorator('CallerId', {
                initialValue: '+1',
                rules: [{ required: true, validator: validatePhone }],
              })(<Input className="phone-number-input" type="tel" />)}
              <Button className="submit-number" onClick={this.onAddPhoneNumber}>
                Add Number
              </Button>
            </Form.Item>
          </div>
        </div>
        <div className="phone-number-list">
          <div className="phone-number-list-header">
            <div className="phone-number-heading">Linked Phone number(s)</div>
            <div className="region-heading">Region</div>
          </div>
          {phoneNumbers.map((phoneNumber, index) => (
            <PhoneNumberItem
              phoneNumber={phoneNumber}
              deletePhoneNumber={this.onDeletePhoneNumber}
              index={index}
              key={phoneNumber.CallerId}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Form.create()(CallSettings);

export { CallSettings as CallSettingsWithForm };
