import { connectV1Client } from './BaseRepository';

export function createAryabotTemplate(templateName, isPublic, questions) {
  return connectV1Client.post('/botTemplates', {
    Name: templateName,
    IsPublic: isPublic,
    Questions: questions,
  });
}

export function editAryabotTemplate(templateId, templateName, isPublic, questions) {
  return connectV1Client.put(`/botTemplates/${templateId}`, {
    Name: templateName,
    IsPublic: isPublic,
    Questions: questions,
  });
}

export function shareAryabotTemplate(templateId, shareOptions) {
  return connectV1Client.post(`botTemplates/${templateId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
    IsShareWithAll: shareOptions.isShareWithAll,
    IsUnshareWithAll: shareOptions.isUnshareWithAll,
  });
}

export function searchAryabotTemplates(filter) {
  return connectV1Client.post('/BotTemplates/_search', filter);
}

export function getAryabotTemplate(templateId) {
  return connectV1Client.get(`/botTemplates/${templateId}`);
}

export function duplicateAryabotTemplate(templateId) {
  return connectV1Client.post(`/botTemplates/${templateId}/_duplicate`);
}

export function getQuestions(templateId) {
  return connectV1Client.get(`/botTemplates/${templateId}/questions`);
}

export function postQuestions(templateId, questions) {
  return connectV1Client.post(`/botTemplates/${templateId}/questions`, questions);
}

export function getAnswers(templateId) {
  return connectV1Client.get(`/botTemplates/${templateId}/answers`);
}

export function postAnswers(templateId, answers) {
  return connectV1Client.post(`/botTemplates/${templateId}/answers`, answers);
}

export function putAnswers(templateId, answers) {
  return connectV1Client.put(`/botTemplates/${templateId}/answers`, answers);
}

export function deleteTemplate(templateId) {
  return connectV1Client.delete(`/BotTemplates/${templateId}`);
}
