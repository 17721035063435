import React from 'react';
import { Layout } from 'antd';
import styles from './Footer.module.scss';
import config from '../../Config/Config';

const { Footer } = Layout;

export default function AppFooter({ className }) {
  const { hostname } = window.location;
  if (hostname === config.urls.pulseHostName) {
    return null;
  }
  return (
    <Footer className={`${styles.appFooter} ${className}`}>
      <a href={config.termsOfUseLink} target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <a href={config.privacyLink} target="_blank" rel="noopener noreferrer">
        Data Privacy Policy
      </a>
    </Footer>
  );
}
