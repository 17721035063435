import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Input, Button, Form } from 'antd';
import './PulseLoginForm.scss';
import { generateMagicLink, clearGenerateMagicLinkApiStatus } from '../../../Actions/UserActions';
import { emailValidator } from '../../../Utils/Validators';

import MagicLinkSuccessComponent from './MagicLinkSuccessComponent';

function PulseLoginForm(props) {
  const {
    onHandleChange,
    onSubmit,
    onForgotPassword,
    username,
    password,
    loginFormContent,
    loading,
    onSignUpClick,
    generateMagicLinkApiStatus,
    history,
  } = props;
  const [isPasswordLessFormVisible, setIsPasswordLessFormVisible] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');
  const [emailInputValidationError, setEmailInputValidationError] = React.useState('');
  const [passwordInputValidationError, setPasswordInputValidationError] = React.useState('');
  const isResendLinkButtonDisabled = generateMagicLinkApiStatus === 'SUCCESS';
  const isLoading = generateMagicLinkApiStatus === 'INPROGRESS';
  const validateEmail = email => {
    let errorMessage = '';
    if (!email?.trim()) {
      errorMessage = 'Please enter the email address.';
    } else if (!emailValidator(email)) {
      errorMessage = 'Please enter a valid email address.';
    }
    return errorMessage;
  };

  useEffect(() => {
    if (username) {
      const emailValidationMessage = validateEmail(username);
      setEmailInputValidationError(emailValidationMessage);
      setUserEmail(username);
    }
    if (password) {
      setPasswordInputValidationError('');
      setUserPassword(password);
    }
  }, [username, password]);

  useEffect(() => {
    if (generateMagicLinkApiStatus === 'USER_NOT_FOUND') {
      history.push('/signup');
    }
    if (generateMagicLinkApiStatus === 'SUCCESS') {
      setIsPasswordLessFormVisible(true);
    }
  }, [generateMagicLinkApiStatus]);

  const footer = _.get(loginFormContent, 'footer', null);
  const footerLinkText = _.get(loginFormContent, 'footerLinkText', null);

  const onUserEmailChange = e => {
    const email = e.target.value;
    const emailValidationMessage = validateEmail(email);
    setEmailInputValidationError(emailValidationMessage);
    setUserEmail(email);
  };

  const onUserPasswordChange = e => {
    const { value } = e.target;
    let errorMessage = '';
    if (!value) {
      errorMessage = 'Please enter the password.';
    }
    setPasswordInputValidationError(errorMessage);
    setUserPassword(value);
  };

  const dispatch = useDispatch();
  const magicLinkGeneration = () => {
    if (!userEmail) {
      onUserEmailChange({
        target: {
          value: userEmail,
        },
      });
      return;
    }

    dispatch(
      generateMagicLink({
        Email: userEmail?.trim().toLowerCase(),
        IsTryNow: false,
      })
    );
  };

  const handleBack = () => {
    dispatch(clearGenerateMagicLinkApiStatus());
  };
  const handleResendLink = () => {
    dispatch(
      generateMagicLink({
        Email: userEmail?.trim().toLowerCase(),
        IsTryNow: false,
      })
    );
  };
  const onClickPasswordlessLogin = () => {
    setIsPasswordLessFormVisible(!isPasswordLessFormVisible);
    setUserEmail(username);
  };

  if (generateMagicLinkApiStatus === 'SUCCESS') {
    return (
      <MagicLinkSuccessComponent
        handleBack={handleBack}
        resendLinkProps={{ handleResendLink, isResendLinkButtonDisabled, isLoading }}
      />
    );
  }

  const onHandleUsernameChange = e => {
    onUserEmailChange(e);
    onHandleChange(e);
  };

  const onHandlePasswordChange = e => {
    onUserPasswordChange(e);
    onHandleChange(e);
  };

  const isInputValid = () => {
    let isValid = true;

    onUserEmailChange({
      target: {
        value: userEmail,
      },
    });
    onUserPasswordChange({
      target: {
        value: userPassword,
      },
    });

    if (!userEmail?.trim() || !userPassword?.trim() || passwordInputValidationError || emailInputValidationError) {
      isValid = false;
    }

    return isValid;
  };

  const onClickSubmit = () => {
    if (!isInputValid()) {
      return;
    }
    onSubmit();
  };

  return (
    <div className="login-auth-form">
      <div className="login-auth-email">
        <div className="login-auth-label">Email</div>
        <Form.Item validateStatus={emailInputValidationError ? 'error' : 'success'} help={emailInputValidationError}>
          <Input
            id="username"
            key="username"
            name="username"
            required
            onChange={onHandleUsernameChange}
            onPressEnter={
              isPasswordLessFormVisible || emailInputValidationError || passwordInputValidationError
                ? () => {}
                : onSubmit
            }
            className="login-auth-input"
            placeholder="Enter Email"
            value={userEmail}
          />
        </Form.Item>
      </div>
      {!isPasswordLessFormVisible ? (
        <>
          <div className="login-auth-password">
            <div className="login-auth-label">Password</div>
            <Form.Item
              validateStatus={passwordInputValidationError ? 'error' : 'success'}
              help={passwordInputValidationError}
            >
              <Input.Password
                id="password"
                key="password"
                name="password"
                required
                onChange={onHandlePasswordChange}
                onPressEnter={emailInputValidationError || passwordInputValidationError ? () => {} : onSubmit}
                type="password"
                className="login-auth-input"
                placeholder="Enter Password"
                value={password}
              />
            </Form.Item>
          </div>
          <div className="login-auth-forgot-password">
            <Button className="login-auth-forgot-password-btn" type="link" onClick={onForgotPassword}>
              <div>Forgot Password?</div>
            </Button>
          </div>
        </>
      ) : null}
      <div className="login-auth-login-button-wrapper">
        <Button
          className="login-auth-login-button"
          type="primary"
          shape="round"
          loading={isPasswordLessFormVisible ? generateMagicLinkApiStatus === 'INPROGRESS' : loading}
          onClick={isPasswordLessFormVisible ? magicLinkGeneration : onClickSubmit}
        >
          {isPasswordLessFormVisible ? `Send Exclusive Link` : `Log In`}
        </Button>
        <Button className="login-auth-login-button" shape="round" onClick={onClickPasswordlessLogin}>
          {isPasswordLessFormVisible ? `Log in with Password` : `Passwordless Log In`}
        </Button>
      </div>
      <div className="login-auth-footer-wrapper">
        <div className="login-auth-footer">
          <div className="login-auth-footer-text">{footer}</div>
          <div className="login-auth-footer-link-wrapper">
            <Button className="login-auth-footer-link-btn" type="link" onClick={onSignUpClick}>
              <div className="login-auth-footer-link-text">{footerLinkText}</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PulseLoginForm;
