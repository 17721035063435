import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { getJobsById } from '../../Reducers/JobReducer';
import ExtendedBotConfig from '../../Components/Connect/BotConfig/ExtendedBotConfig';
import * as AryabotTemplateActions from '../../Actions/AryabotTemplates';
import * as AryabotContextActions from '../../Actions/AryabotContextActions';
import { getFrequentQuestions, getContextById, getFrequentQuestionGroups } from '../../Reducers/AryabotContextReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCurrentAryabotTemplateIds, getAryabotTemplatesById } from '../../Reducers/AryabotTemplates';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as JobActions from '../../Actions/JobActions';

const mapStateToProps = state => ({
  frequentQuestions: getFrequentQuestions(state),
  frequentQuestionGroups: getFrequentQuestionGroups(state),
  contextById: getContextById(state),
  currentAryabotTemplateIds: getCurrentAryabotTemplateIds(state),
  aryabotTemplatesById: getAryabotTemplatesById(state),
  jobsById: getJobsById(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  fetchFrequentQuestions: AryabotContextActions.fetchFrequentQuestions,
  getQuestions: AryabotContextActions.getQuestions,
  postQuestions: AryabotContextActions.postQuestions,
  getAnswers: AryabotContextActions.getAnswers,
  postAnswers: AryabotContextActions.postAnswers,
  searchAryabotTemplates: AryabotTemplateActions.searchAryabotTemplates,
  getTemplateQuestions: AryabotTemplateActions.getQuestions,
  createContext: AryabotContextActions.createContext,
  editConversationReferenceDetails: ConnectActions.editConversationReferenceDetails,
  fetchJobDetails: JobActions.fetchJobDetails,
};

class ExtendedBotConfigContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    const { createContext, jobId, getQuestions, getAnswers, jobsById } = this.props;

    const { JobGuid, Description, AryaStatus } = _.get(jobsById, [jobId], {});
    let { BotContextId } = _.get(jobsById, [jobId], {});
    const answers = {
      JobDescription: Description,
    };
    if (!BotContextId || AryaStatus === 'Closed') {
      BotContextId = await createContext('REF', JobGuid, jobId, true, answers);
    }
    getQuestions(BotContextId);
    getAnswers(BotContextId);
  }

  onSave(questions, answers, botStatus) {
    const { onClose, postQuestions, postAnswers, editConversationReferenceDetails, jobId, jobsById } = this.props;
    const { JobGuid, BotContextId, IsBotEnabled } = _.get(jobsById, [jobId], {});

    onClose();
    postQuestions(BotContextId, questions);
    postAnswers(BotContextId, answers);
    if (IsBotEnabled !== botStatus) editConversationReferenceDetails(JobGuid, botStatus, jobId);
  }

  render() {
    const {
      frequentQuestions,
      frequentQuestionGroups,
      contextById,
      currentAryabotTemplateIds,
      aryabotTemplatesById,
      getTemplateQuestions,
      jobId,
      jobsById,
      whiteLabelInfo,
      onCancel,
      cancelButtonText,
      saveButtonText,
      hideSelectTemplate,
      getJobQuestionApiStatus,
      readOnly,
      subTitle,
      isPaidJobServiceEnabled,
    } = this.props;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const { BotContextId, IsBotEnabled } = _.get(jobsById, [jobId], {});
    return (
      <ExtendedBotConfig
        subTitle={subTitle}
        frequentQuestions={frequentQuestions}
        frequentQuestionGroups={frequentQuestionGroups}
        onSave={this.onSave}
        questions={_.get(contextById, [BotContextId, 'Questions'], [])}
        answers={_.get(contextById, [BotContextId, 'Answers'], {})}
        currentAryabotTemplateIds={currentAryabotTemplateIds}
        aryabotTemplatesById={aryabotTemplatesById}
        getTemplateQuestions={getTemplateQuestions}
        isBotEnabled={IsBotEnabled}
        AppName={AppName}
        onCancel={onCancel}
        cancelButtonText={cancelButtonText}
        saveButtonText={saveButtonText}
        hideSelectTemplate={hideSelectTemplate}
        getJobQuestionApiStatus={getJobQuestionApiStatus}
        readOnly={readOnly}
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      />
    );
  }
}

export { ExtendedBotConfigContainer as ExtendedBotConfigContainerWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtendedBotConfigContainer));
