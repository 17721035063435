/* eslint-disable func-names */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { select, line, axisBottom, axisLeft, mouse, scaleLinear, bisectLeft, timeFormat } from 'd3';
import { Menu, Dropdown, Button, Icon, Checkbox } from 'antd';
import styles from './LineChart.module.scss';
import './LineChart.scss';
import { getCountableNounForm } from '../../Utils/TextUtils';

function getLabels(lineLabels, lineColors) {
  return lineLabels?.map((val, index) => {
    return (
      <div className={styles.chartLabelsView}>
        <div className={styles.labelColor} style={{ backgroundColor: lineColors[index] }}></div>
        <div className={styles.labelName}> {lineLabels[index]}</div>
      </div>
    );
  });
}

function makeYGridlines(y, innerHeight, theme) {
  return axisLeft(y).ticks(innerHeight / theme.yAxisTickDensity);
}

export function lineLegendView(values) {
  let lineLegendHtml = '';
  const abscissa = (values ?? [])?.[0]?.abscissa ?? '';
  const abscissaHtml = `<div class="legendHeader">${abscissa}</div>`;
  (values ?? []).forEach((d, index) => {
    if (d.isVisible) {
      lineLegendHtml = lineLegendHtml.concat(
        `<div class='legendListItem' id=legendListItem${index}>
        <div class='legendLabelKey'>
          <div class="labelColor"></div>
          <div class="labelName">${d.lineName}</div>
        </div>
        <div>${d.value}</div>
      </div>`
      );
    }
  });

  return `<div class='legendWrapper'>${abscissaHtml}<div class='legendList'>${lineLegendHtml}</div></div>`;
}

export function lineGenerator(lineGeneratorParams) {
  const { svg, data: lineData, lineColors, myLine } = lineGeneratorParams;
  const data = lineData?.map(value => (value.isVisible ? value.points : {}));

  svg
    .selectAll('.line')
    .data(data)
    .join('path')
    .attr('class', 'line')
    .attr('d', myLine)
    .attr('fill', 'none')
    .attr('stroke', (d, index) => (lineColors[index] ? lineColors[index] : 'black'))
    .attr('stroke-width', '1.5px');
}

export function onMouseMove(data, xScale, yScale, svg, yMinMax, lineColors, id, mouseCoordinates) {
  const mouseData = _.cloneDeep(data);
  const mouseX = mouseCoordinates[0];
  const mouseY = mouseCoordinates[1];

  const invertedMouseX = xScale.invert(mouseX);
  const displayLine = [
    [
      { key: invertedMouseX, value: yMinMax[0] },
      { key: invertedMouseX, value: yMinMax[1] },
    ],
  ];

  const line1 = mouseData?.[0]?.points?.map(d => d.key);
  const bisectionIndex = bisectLeft(line1, invertedMouseX);
  const mouseXKey = mouseData?.[0]?.points?.[bisectionIndex]?.key ?? mouseData?.[0]?.points?.[bisectionIndex - 1]?.key;

  const yPointsToDisplay = mouseData?.map(value => {
    if (value?.isVisible) {
      const yValue = value?.points?.[bisectionIndex]?.value ?? value?.points?.[bisectionIndex - 1]?.value;
      const xValue = value?.points?.[bisectionIndex]?.abscissa ?? value?.points?.[bisectionIndex - 1]?.abscissa;
      return {
        isVisible: value.isVisible,
        lineName: value.lineName,
        value: yValue,
        abscissa: xValue,
      };
    }

    return {
      isVisible: false,
    };
  });

  const isTooltipVisible = yPointsToDisplay?.findIndex(({ isVisible }) => isVisible) !== -1;

  const dline = line()
    .x(() => xScale(mouseXKey))
    .y(d => yScale(d.value));

  if (mouseXKey && isTooltipVisible) {
    const linePaths = svg.selectAll('.displayLine').data(displayLine);
    linePaths
      .enter()
      .append('path')
      .merge(linePaths)
      .attr('class', 'displayLine')
      .attr('d', dline)
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', '0.2px')
      .style('opacity', '1')
      .transition()
      .duration(300);

    const lineCircle = svg.selectAll('.lineCircle').data(yPointsToDisplay);
    lineCircle
      .enter()
      .append('circle')
      .merge(lineCircle)
      .attr('class', 'lineCircle')
      .attr('r', 4)
      .data(yPointsToDisplay)
      .attr('cx', xScale(mouseXKey))
      .attr('cy', d => yScale(d.value))
      .style('stroke', (d, index) => (lineColors[index] ? lineColors[index] : 'black'))
      .style('fill', (d, index) => (lineColors[index] ? lineColors[index] : 'black'))
      .style('stroke-width', '1px')
      .style('opacity', '1')
      .transition()
      .duration(300);

    const html = lineLegendView(yPointsToDisplay);
    const tTip = select(`#tooltip${id}`);
    tTip
      .html(html)
      .style('position', 'absolute')
      .style('top', `${mouseY}px`)
      .style('left', `${xScale(mouseXKey) + 20}px`)
      .style('opacity', 1)
      .transition()
      .duration(300);

    yPointsToDisplay.forEach((d, index) => {
      tTip
        .select(`#legendListItem${index}`)
        .select('.labelColor')
        .style('background-color', lineColors[index]);
    });
  }
}

export function onMouseOut(svg, id) {
  const displayLine = svg.selectAll('.displayLine');
  displayLine
    .transition()
    .duration(300)
    .style('opacity', '0');

  const lineCircle = svg.selectAll('.lineCircle');
  lineCircle
    .transition()
    .duration(300)
    .style('opacity', '0');

  const tTip = select(`#tooltip${id}`);
  tTip
    .transition()
    .duration(300)
    .style('opacity', '0');
}

export default function LineChart(props) {
  const [isMenuVisible, setMenuVisibility] = useState(false);

  const {
    title,
    id,
    xScale,
    yScale,
    data,
    width,
    height,
    margin,
    lineColors,
    filterLines,
    xAxisLabel,
    yAxisLabel,
    selectedLinesCount,
    theme,
    yMinMax,
    xTickFormat,
  } = props;

  const svgRef = useRef();
  const svg = select(svgRef.current);

  const lineLabels = data?.map(lines => lines.lineName);

  function handleCheckBoxChange(e) {
    filterLines(e.target.class, id);
  }

  const menu = (
    <Menu>
      {lineLabels?.map((val, index) => {
        return (
          <Menu.Item key={val}>
            <Checkbox class={val} checked={data?.[index]?.isVisible} defaultChecked onChange={handleCheckBoxChange}>
              {val}
            </Checkbox>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const innerWidth = width - margin?.left - margin?.right;
  const innerHeight = height - margin?.top - margin?.bottom;
  const { axisTimeFormat, tickCount } = xTickFormat;

  const xAxis = axisBottom(xScale)
    .ticks(tickCount)
    .tickFormat(timeFormat(axisTimeFormat));
  const yAxis = axisLeft(yScale)
    .ticks(innerHeight / theme.yAxisTickDensity)
    .tickFormat(value => value);

  const xAxisContainer = svg
    .select('.x-axis')
    .attr('transform', `translate(0, ${innerHeight + margin.top})`)
    .attr('stroke', theme.xAxisTickLineStroke)
    .attr('stroke-width', theme.xAxisThickness)
    .call(xAxis);

  const yAxisContainer = svg
    .select('.y-axis')
    .attr('transform', `translate(${margin.left},0)`)
    .attr('stroke', theme.yAxisTickLineStroke)
    .attr('stroke-width', theme.yAxisThickness)
    .call(yAxis);

  yAxisContainer
    .append('g')
    .attr('class', 'grid')
    .attr('stroke-dasharray', theme.gridLinesDashes)
    .attr('stroke-width', theme.gridLinesThickness)
    .attr('stroke', '#E6EAEE')
    .call(
      makeYGridlines(yScale, innerHeight, theme)
        .tickSize(-innerWidth)
        .tickFormat('')
    );

  xAxisContainer
    .selectAll('.tick text')
    .attr('font-size', theme.xAxisTickFontSize)
    .attr('font-family', 'Roboto, sans-serif')
    .attr('stroke-width', '0.1px')
    .attr('stroke', theme.xAxisTickLineStroke)
    .attr('dy', '1em')
    .attr('transform', 'translate(-10,25) rotate(-30)');

  yAxisContainer
    .selectAll('.tick text')
    .attr('font-size', theme.yAxisTickFontSize)
    .attr('font-family', 'Roboto, sans-serif')
    .attr('stroke-width', '0.1px')
    .attr('stroke', theme.yAxisTickLineStroke);

  svg
    .selectAll('.x-label')
    .append('text')
    .attr('transform', `translate(${innerWidth / 2}, ${innerHeight})`)
    .style('text-anchor', 'middle')
    .attr('font-size', theme.xAxisLabelFontSize)
    .text(xAxisLabel)
    .attr('dy', '5em')
    .attr('dx', '1em')
    .style('font-family', 'Roboto, sans-serif')
    .style('fill', theme.xAxisLabelFill);

  svg
    .selectAll('.y-label')
    .append('text')
    .attr('transform', `translate(0,${innerHeight / 2}) rotate(-90)`)
    .style('text-anchor', 'middle')
    .attr('font-size', theme.yAxisLabelFontSize)
    .attr('dy', '1em')
    .attr('dx', '1em')
    .text(yAxisLabel)
    .style('font-family', 'Roboto, sans-serif')
    .style('fill', theme.yAxisLabelFill);

  const myLine = line()
    .x(value => xScale(value.key))
    .y(value => yScale(value.value));

  svg
    .on('mousemove', function() {
      const mouseCoordinates = mouse(this);
      onMouseMove(data, xScale, yScale, svg, yMinMax, lineColors, id, mouseCoordinates);
    })
    .on('mouseout', () => {
      onMouseOut(svg, id);
    });

  useEffect(() => {
    const lineGeneratorParams = { title, id, svg, data, lineLabels, lineColors, myLine, xScale, yScale };
    lineGenerator(lineGeneratorParams);
  }, [svg, data]);

  return (
    <div className={styles.lineChart}>
        <svg ref={svgRef} width={width} height={height} className={styles.lineChartSvg}>
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="x-label" />
          <g className="y-label" />
          <path className="displayLine" />
          <path className="line" />
          <circle className="lineCircle" />
        </svg>
        <div className="lineChartTooltip" id={`tooltip${id}`} />
        <div className={styles.ChartFilters}>
          <div className={styles.chartLabels}>{getLabels(lineLabels, lineColors)}</div>
          <div className={styles.filterLinedDropdown} id="filterLinedDropdown">
            <Dropdown
              overlay={menu}
              trigger={['click']}
              visible={isMenuVisible}
              placement="topLeft"
              onClick={() => {
                setMenuVisibility(true);
              }}
              onVisibleChange={setMenuVisibility}
            >
              <Button size="small">
                {selectedLinesCount} {getCountableNounForm('Value', 'Values', selectedLinesCount)} Selected{' '}
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
  );
}

LineChart.defaultProps = {
  id: '1',
  width: 300,
  height: 300,
  margin: { top: 20, bottom: 50, left: 50, right: 20 },
  lineColors: [
    '#FF0000',
    '#FF3300',
    '#ff6600',
    '#ff9900',
    '#FFCC00',
    '#FFFF00',
    '#ccff00',
    '#99ff00',
    '#66ff00',
    '#33ff00',
    '#00FF00',
  ],
  xAxisLabel: '',
  yAxisLabel: '',
  data: [],
  theme: {
    xAxisLabelFill: '#07101a',
    yAxisLabelFill: '#07101a',
    xAxisLabelFontSize: '14px',
    yAxisLabelFontSize: '14px',
    xAxisTickFontSize: '14px',
    yAxisTickFontSize: '14px',
    xAxisTickLineStroke: 'black',
    yAxisTickLineStroke: 'black',
    xAxisTickDensity: 100,
    yAxisTickDensity: 100,
    xAxisThickness: '1px',
    yAxisThickness: '1px',
    gridLinesThickness: '0.1px',
    gridLinesDashes: 8,
  },
  yMinMax: [0, 10],
  xScale: scaleLinear(),
  yScale: scaleLinear(),
};
