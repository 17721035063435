import React from 'react';
import { getSourceImage } from '../../Utils/SourceUtils';
import SourcesContext from './SourceContext';
import styles from './SourceItemV2.module.scss';

const SourceItemV2 = () => {
  const { sourceNamesSelected, sourceName, isSourceAuthorized, source, showVaultName } =
    React.useContext(SourcesContext);

  return (
    <div
      className={`${styles.sourceFilter} ${
        sourceNamesSelected.includes(sourceName) && isSourceAuthorized ? styles.selected : styles.unselected
      }`}
      key={sourceName}
    >
      {getSourceImage(source, { fontSize: 24, height: 24, verticalAlign: 'middle' }, showVaultName)}
    </div>
  );
};

export default SourceItemV2;
