import React from 'react';
import { Avatar, Tag, Tooltip, Icon, Popover } from 'antd';
import MoreIcon from '../../Icons/MoreIcon';
import { getConfiguration } from '../Collaborators/Collaborators';
import styles from './NotesCardItem.module.scss';
import NoteActionItems from './NoteActionItems';
import AddSuggestedTag from '../TagsDisplayComponent/AddSuggestedTag';
import TextPreview from '../../Utils/TextPreview/TextPreview';
import { generateNotesDateTime } from './NotesCardItemUtils';

function NotesCardItem(props) {
  const {
    note,
    onDelete,
    onUpdateNote,
    noteDeleteApiStatus,
    totalSuggestedTagsCount,
    onTagChange,
    onTagLoadMore,
    onTagAdd,
    suggestedTags,
    tagCloseApiStatus,
    onTagClose,
    setNotification,
    suggestedTagsApiStatus,
  } = props;

  const [isPopoverVisible, setPopoverVisbility] = React.useState(false);
  const [isDeleteModalVisible, setDeleteModalVisibility] = React.useState(false);
  const [isInvalidTagErrorVisible, setInvalidTagErrorVisiblity] = React.useState(false);

  const name = note.CreatedByName;
  const modifiedByName = note.ModifiedByName;
  const modifiedDate = generateNotesDateTime(note.ModifiedDate);
  const createdDate = generateNotesDateTime(note.CreatedDate);
  const config = getConfiguration(name);
  const onDeleteItem = () => {
    onDelete(note.Id);
  };
  const onDeleteNote = () => {
    setDeleteModalVisibility(true);
    setPopoverVisbility(false);
  };
  const onEditNote = () => {
    onUpdateNote(note);
    setPopoverVisbility(false);
  };

  const handleTagClose = tag => {
    onTagClose({ tag, noteId: note.Id });
  };
  const handleTagAdd = tag => {
    const tagsName = [];
    if (note?.Tags?.length > 0) {
      note.Tags.map(tagElement => tagsName.push(tagElement.Name.toUpperCase()));
    }
    if (tagsName.includes(tag.toUpperCase())) {
      setNotification('ERROR', 'Tag already exists');
    } else {
      onTagAdd({ tag, noteId: note.Id });
    }
  };

  const getTagElem = (tag, isLongTag) => {
    return (
      <Tag key={tag?.values[1]}>
        {isLongTag ? `${tag?.values[0]?.slice(0, 20)}...` : tag?.values[0]}
        <Icon
          type={tagCloseApiStatus?.[[tag?.AssociationId]] === 'INPROGRESS' ? 'loading' : 'close'}
          onClick={() => handleTagClose(tag)}
        />
      </Tag>
    );
  };

  const getToolTip = (tag, isLongTag, tagElem) => {
    return isLongTag ? (
      <Tooltip title={tag?.values[0]} key={tag?.values[1]}>
        {tagElem}
      </Tooltip>
    ) : (
      tagElem
    );
  };

  const handleVisibleChange = newVisible => {
    setPopoverVisbility(newVisible);
  };

  const tagsResult =
    note.Tags &&
    Object.values(
      note.Tags.reduce((acc, { AssociationId, Name, Id }) => {
        acc[AssociationId] = acc[AssociationId] || { AssociationId, values: [] };
        acc[AssociationId].values.push(Name, Id);
        return acc;
      }, {})
    );
  return (
    <div className={styles.cardItem}>
      <div className={styles.noteHeader}>
        <div className={styles.candidateInfo}>
          <div className={styles.userIcon}>
            <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
          </div>
          <div className={styles.name}>
            {note.CreatedDate === note.ModifiedDate || note.notesContext === 'Call' ? (
              <>
                <div className={styles.contentTitle}>{name}</div>
                <div>Created: {createdDate}</div>
              </>
            ) : (
              <>
                <div className={styles.contentTitle}>
                  Modified By: {modifiedByName} | Created By: {name}
                </div>
                <div>
                  Last Modified: {modifiedDate} & Created: {createdDate}
                </div>
              </>
            )}
          </div>
        </div>
        {note.notesContext !== 'Call' ? (
          <div className={styles.moreIcon}>
            <Popover
              trigger={['click']}
              visible={isPopoverVisible}
              onVisibleChange={handleVisibleChange}
              content={
                <div className={styles.contentPop}>
                  <div onClick={onEditNote} onKeyPress={onEditNote} role="button" tabIndex={0}>
                    Edit
                  </div>
                  <div onClick={onDeleteNote} onKeyPress={onDeleteNote} role="button" tabIndex={0}>
                    Delete
                  </div>
                </div>
              }
              placement="left"
              style={{ width: '125px', height: '92px' }}
            >
              <div className={styles.moreIconWrapper}>
                <MoreIcon fillColor="#83878C" />
              </div>
            </Popover>
          </div>
        ) : null}
      </div>
      {note?.Context ? (
        <div>
          <span className={styles.contentTitle}>Context:</span>&nbsp;
          <span className={styles.content}>{note.Context}</span>
        </div>
      ) : null}
      {note?.SubjectLine ? (
        <div className={styles.noteSubject}>
          <span className={styles.contentTitle}>Subject:</span>&nbsp;
          <span className={styles.content}>{note.SubjectLine}</span>
        </div>
      ) : null}
      {note?.Description ? (
        <TextPreview
          text={note.Description}
          showSeeMoreButton
          partialClassName={styles.partialClass}
          context="notes"
          shouldPreserveStyle
        />
      ) : null}
      <div className={styles.noteTagsSection}>
        {tagsResult?.length
          ? tagsResult.map(tag => {
              const isLongTag = tag?.values[0]?.length > 20;
              const tagElem = getTagElem(tag, isLongTag);
              return getToolTip(tag, isLongTag, tagElem);
            })
          : null}
        {note.notesContext !== 'Call' ? (
          <div className={styles.addTag}>
            <AddSuggestedTag
              suggestedTags={suggestedTags}
              onTagAdd={handleTagAdd}
              onTagChange={onTagChange}
              totalSuggestedTagsCount={totalSuggestedTagsCount}
              onLoadMore={onTagLoadMore}
              zIndex={2002}
              suggestedTagsApiStatus={suggestedTagsApiStatus}
              setNotification={setNotification}
              tagsResult={tagsResult}
              isInvalidTagErrorVisible={isInvalidTagErrorVisible}
              setInvalidTagErrorVisiblity={setInvalidTagErrorVisiblity}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.actions}>
        <NoteActionItems
          setDeleteModalVisibility={setDeleteModalVisibility}
          isDeleteModalVisible={isDeleteModalVisible}
          onDelete={onDeleteItem}
          jobNoteDeleteApiStatus={noteDeleteApiStatus}
          noteDetails={note}
        />
      </div>
    </div>
  );
}

export default NotesCardItem;
