import React from 'react';
import { Tag, Icon } from 'antd';
import { SeeMoreArrowIcon, SeeLessArrowIcon } from '../../Icons/AryaIcons';
import { EllipsedText } from '../../Utils/TextUtils';
import './RejectReasonsSuggestions.scss';

export default function RejectReasonsSuggestions(props) {
  const { suggestions, selectedReasons, selectReason } = props;
  const [extraReasonsVisibility, setExtraReasonsVisibility] = React.useState(false);
  const filteredSuggestions = suggestions?.filter(suggestion => !selectedReasons?.includes(suggestion));
  const currentSuggestions = extraReasonsVisibility ? filteredSuggestions : filteredSuggestions?.slice(0, 3);

  const getMoreSuitableReasonsTags = () => {
    if (!currentSuggestions?.length) return null;
    return currentSuggestions.map(reason => (
      <Tag className="more-suitable-reject-reason-tag" key={reason} onClick={() => selectReason(reason)}>
        <Icon type="plus" />
        <EllipsedText text={reason} maxTextLength={20} />
      </Tag>
    ));
  };

  return (
    <div className="reject-reasons-suggestions-wrapper">
      <div className="reject-reasons-suggestions">
        {getMoreSuitableReasonsTags()}{' '}
        {filteredSuggestions?.length > 3 ? (
          <span
            className="see-more-less-suggestions-button"
            role="presentation"
            onClick={() => setExtraReasonsVisibility(!extraReasonsVisibility)}
            onKeyPress={() => setExtraReasonsVisibility(!extraReasonsVisibility)}
          >
            {extraReasonsVisibility ? <SeeLessArrowIcon /> : <SeeMoreArrowIcon />}
          </span>
        ) : null}{' '}
      </div>
    </div>
  );
}
