import React from 'react';
import { Badge, Icon } from 'antd';
import styles from './ErrorSystemNotification.module.scss';

function ErrorSystemNotification(props) {
  const { title, isViewed, publishedTime, content, onClick } = props;
  return (
    <div className={styles.errorSystemNotification} role="presentation" onClick={onClick}>
      <div className={styles.imageSection}>
        <div className={styles.closeIcon}>
          <Icon type="close-circle" theme="filled" />
        </div>
      </div>
      <div className={styles.notificationContent}>
        <div className={styles.notificationTitle}>{title}</div>
        <p>{content}</p>
        <div className={styles.notificationTime}>{publishedTime}</div>
      </div>
      <div className={styles.viewStatus}>
        <Badge dot={isViewed} />
      </div>
    </div>
  );
}

export default ErrorSystemNotification;
