import React from 'react';
import { Modal, Button } from 'antd';
import styles from './SearchSavedCriteriaModal.module.scss';

export default function SearchSavedCriteriaModal({ isModalVisible, handleCancel, handleOk }) {
  return (
    <Modal visible={isModalVisible} width="479px" centered onCancel={handleCancel} footer={null}>
      <div className={styles.searchSavedCriteriaModalContent}>
        <div className={styles.saveCriteriaheader}>Your current search criteria will be replaced!</div>
        <div className={styles.saveCriteriaDescription}>
          Choosing the saved criteria will replace your current criteria.
          <br /> Are you okay with it?
        </div>
        <div className={styles.saveCriteriaFooter}>
          <Button shape="round" onClick={handleCancel} size="large">
            No
          </Button>
          <Button type="primary" shape="round" onClick={handleOk} size="large">
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}
