import React from 'react';
import BarChart from '../../../../../D3Charts/BarChart';
import StatsDetails from '../../StatsDetails/StatsDetails';
import styles from './EmailStats.module.scss';

export default function EmailStats(props) {
  const { emailStats, subtitle } = props;
  const colors = ['#1890FF', '#8E76E4', '#FF855C', '#13C26B'];

  return (
    <StatsDetails title="Email" subtitle={subtitle}>
      <div className={styles.barChartWrapper}>
        <BarChart
          list={emailStats}
          width={450}
          height={300}
          colors={colors}
          rotation={45}
          barWidth={50}
          gapBetweenBars={50}
          margin={30}
          xLabelPadding={20}
          isBarValueVisible={false}
          barLabelKey="Mails"
        />
      </div>
    </StatsDetails>
  );
}
