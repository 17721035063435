import moment from 'moment';

function CalculateWorkExperience(Work) {
  let totalWorkExperience = 0;
  let index = 0;
  if (!Work || Work.length === 0) return totalWorkExperience;
  for (index = 0; index < Work.length; index += 1) {
    totalWorkExperience += moment(Work[index].To).diff(moment(Work[index].From), 'years');
  }
  return totalWorkExperience;
}

export default CalculateWorkExperience;
