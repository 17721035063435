import React from 'react';
import { connect } from 'react-redux';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getSavedReportQueries,
  getSavedQueriesPageSize,
  getReportColumns as _getReportColumns,
} from '../../../Reducers/ReportPortalReducer';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import * as UserActions from '../../../Actions/UserActions';
import SavedReportQueries from '../../../Components/ReportPortal/ReportStatsTab/SavedReportQueries/SavedReportQueries';
import { setSavedQueriesPageSize as _setSavedQueriesPageSize } from '../../../Actions/ActionCreators/ReportPortalActionCreator';

const mapStateToProps = state => {
  return {
    fetchSavedQueriesApiStatus: getApiStatus(state, 'fetchSavedQueriesApiStatus'),
    deleteSavedQueryApiStatus: getApiStatus(state, 'deleteSavedQueryApiStatus'),
    savedReportQueries: getSavedReportQueries(state),
    savedQueryPageSize: getSavedQueriesPageSize(state),
    reportColumns: _getReportColumns(state),
  };
};

const mapDispatchToProps = {
  setSavedQueriesPageSize: _setSavedQueriesPageSize,
  fetchSavedReportQueries: ReportPortalActions.fetchSavedReportQueries,
  deleteSavedQuery: ReportPortalActions.deleteSavedQuery,
  clearDeleteSavedQueryApiStatus: ReportPortalActions.clearDeleteSavedQueryApiStatus,
  fetchClients: ReportPortalActions.fetchClientsForDropdownLists,
  fetchRecruiters: ReportPortalActions.fetchRecruitersForDropdownLists,
  fetchOrganizations: ReportPortalActions.getOrganizations,
  fetchJobs: ReportPortalActions.fetchJobsForDropdownLists,
  getReportColumns: ReportPortalActions.getOrgReportColumns,
  shareReportSavedQuery: ReportPortalActions.shareReportSavedQuery,
  fetchUsersForShare: UserActions.fetchUsersForDropdownLists,
  fetchUsers: UserActions.fetchUsers,
};

export function SavedReportQueriesContainer(props) {
  const {
    fetchSavedQueriesApiStatus,
    fetchSavedReportQueries,
    savedReportQueries,
    savedQueryPageSize,
    setSavedQueriesPageSize,
    deleteSavedQuery,
    deleteSavedQueryApiStatus,
    fetchClients,
    fetchRecruiters,
    fetchOrganizations,
    fetchJobs,
    clearDeleteSavedQueryApiStatus,
    getReportColumns,
    reportColumns,
    shareReportSavedQuery,
    fetchUsersForShare,
    fetchUsers,
  } = props;
  const [savedQueriesPageNumber, setSavedQueriesPageNumber] = React.useState(1);

  const onFetchSavedReportQueries = filter => {
    fetchSavedReportQueries({
      From: filter?.from,
      Size: filter?.size,
      ReportType: null,
      ReportName: filter?.searchTerm,
    });
  };

  return (
    <div>
      <SavedReportQueries
        fetchSavedQueriesApiStatus={fetchSavedQueriesApiStatus}
        fetchSavedReportQueries={onFetchSavedReportQueries}
        savedReportQueries={savedReportQueries}
        setSavedQueriesPageSize={setSavedQueriesPageSize}
        setSavedQueriesPageNumber={setSavedQueriesPageNumber}
        pageSize={savedQueryPageSize}
        pageNumber={savedQueriesPageNumber}
        deleteSavedQuery={deleteSavedQuery}
        deleteSavedQueryApiStatus={deleteSavedQueryApiStatus}
        fetchClients={fetchClients}
        fetchRecruiters={fetchRecruiters}
        fetchOrganizations={fetchOrganizations}
        fetchJobs={fetchJobs}
        clearDeleteSavedQueryApiStatus={clearDeleteSavedQueryApiStatus}
        getReportColumns={getReportColumns}
        reportColumns={reportColumns}
        shareReportSavedQuery={shareReportSavedQuery}
        fetchUsersForShare={fetchUsersForShare}
        fetchUsers={fetchUsers}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedReportQueriesContainer);
