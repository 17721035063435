import { connect } from 'react-redux';
import OrderHistory from '../../Components/OrderHistory/OrderHistory';
import { getOrdersApiStatus, getOrders, getOrdersCount } from '../../Reducers/OrderReducer';
import { getJobsById, getJobGuidToIdMapping } from '../../Reducers/JobReducer';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { fetchOrdersHistory } from '../../Actions/OrderActions';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';

const mapStateToProps = state => {
  return {
    ordersApiStatus: getOrdersApiStatus(state),
    orders: getOrders(state),
    jobs: getJobsById(state),
    ordersCount: getOrdersCount(state),
    jobGuidToIdMapping: getJobGuidToIdMapping(state),
    usersByGuid: getUsersByGuId(state),
    productVariantesById: getProductVariantsById(state),
  };
};

const mapDispatchToProps = {
  fetchOrdersHistory,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
