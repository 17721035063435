import React from 'react';
import { Spin, Empty, Divider } from 'antd';
import _ from 'lodash';
import './SelectDropDownContent.scss';

export default function SelectDropDownContent(props) {
  const { menuItems, isLoading, text, staticHeader, onStaticHeaderClick, filterComponent } = props;
  const getEmptyScreen = () => {
    const menuItemsArray = _.get(menuItems, ['props', 'menuItems'], []);
    return menuItemsArray.length > 0 && menuItemsArray[0].key === 'NOT_FOUND' ? (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-image-in-select-dropdown-content" />
    ) : null;
  };

  return (
    <div>
      {staticHeader ? (
        <div
          style={{ padding: '4px 8px', cursor: 'pointer' }}
          onMouseDown={e => e.preventDefault()}
          onClick={onStaticHeaderClick}
          role="presentation"
        >
          {staticHeader}
        </div>
      ) : null}
      {filterComponent}
      <Divider style={{ margin: '4px 0' }} />
      {menuItems}
      {isLoading ? (
        <div className="spin-loader-container">
          <Spin className="spin-loader" size="small" />
          <span className="spin-loader-text">{text}</span>
        </div>
      ) : (
        getEmptyScreen()
      )}
    </div>
  );
}
