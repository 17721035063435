import React from 'react';
import { Form, Select } from 'antd';

export default function CountrySelect({ form, account, Countries, isAuthenticated, countriesMenu, jobBoard }) {
  return (
    <div className="jobboard-input-container">
      <Form.Item>
        <div className="jobboard-country-code">
          {form.getFieldDecorator(`${jobBoard}countryCode`, {
            initialValue: account.CountryCode || (Countries && Countries[0]),
          })(
            <Select className="jobboard-country-code-select" disabled={isAuthenticated || countriesMenu.length < 2}>
              {countriesMenu}
            </Select>
          )}
        </div>
      </Form.Item>
    </div>
  );
}
