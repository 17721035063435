import * as React from 'react';
import { Icon } from 'antd';

export const MinimizedTransferSvg = props => {
  const { fillColor } = props;
  const fill = fillColor || '#83878c';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={24} viewBox="0 0 34 24" {...props}>
      <defs>
        <clipPath id="a">
          <path d="M19 1h14.586v16H19Zm0 0" />
        </clipPath>
      </defs>
      <path
        d="M19.25 6.75a5.745 5.745 0 0 1-5.75 5.748A5.745 5.745 0 0 1 7.752 6.75 5.745 5.745 0 0 1 13.5 1.002a5.745 5.745 0 0 1 5.75 5.748ZM1 25.313c0-2.637 1.218-4.883 3.369-6.496 2.173-1.63 5.323-2.628 9.131-2.628 3.809 0 6.959.998 9.132 2.628 2.15 1.613 3.37 3.859 3.37 6.495v.686H.998Zm0 0"
        style={{
          fill: fillColor || 'none',
          strokeWidth: 2,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: fillColor || '#83878c',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        transform="scale(.8718 .88889)"
      />
      <g clipPath="url(#a)">
        <path
          d="M26.656 16.066 33.586 9l-6.93-7.066-2.074 2.132L27.953 7.5h-8.226v3h8.226l-3.371 3.434Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill,
            fillOpacity: 1,
          }}
        />
      </g>
    </svg>
  );
};
const CandidateTransferIcon = props => (
  <Icon component={customProps => <MinimizedTransferSvg {...props} {...customProps} />} {...props} />
);
export default CandidateTransferIcon;
