import { Input, Form } from 'antd';
import React from 'react';
import NotesContext from './NotesContext';

export const validateSubject = (rule, value, callback) => {
  if (value && value.trim().length > 1000) callback('Subject cannot exceed 1000 characters');
  else callback();
};

function NotesSubjectField() {
  const { form } = React.useContext(NotesContext);

  return (
    <Form.Item>
      {form.getFieldDecorator('NotesSubject', {
        rules: [{ validator: validateSubject }],
      })(<Input placeholder="Subject Line" />)}
    </Form.Item>
  );
}

export default NotesSubjectField;
