export const getDefaultJobMatchingConfig = candidate => {
  return {
    candidateId: candidate.Id,
    size: 10,
    from: 0,
    searchTerm: '',
    location: [],
    candidateExperience: null,
    industries: [],
    jobStatuses: [],
    excludeJobStatuses: [],
    titles: [],
    candidateTitle: candidate.Title,
    skills: candidate.Skills?.slice(0, 200),
    countryCodes: candidate.Country,
    exactTitleMatch: false,
  };
};
