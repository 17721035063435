import { Button, Icon, Modal, Tooltip, Spin } from 'antd';
import React from 'react';
import {
  GetJobBoardIcon,
  JobStatusChangeActivePortalText,
  hasJobEditAccess,
} from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import { getJobStatusChangeModalHeading, getJobStatusChangeModalDescription } from '../../Utils/JobStatusChangeUtils';
import styles from './JobStatusChange.module.scss';

export default function JobStatusChange(props) {
  const {
    visible,
    onCancel,
    onConfirm,
    recallJob,
    distributedPortals,
    currentUser,
    distributedPortalsInfo,
    isDripCampaignScheduled,
  } = props;
  const [endCampaignLoader, setEndCampaignLoader] = React.useState({});

  const endCampaign = portal => {
    setEndCampaignLoader({ ...endCampaignLoader, [portal]: true });
    recallJob(portal);
  };

  const endCampaignButton = portal => {
    const disabled = !hasJobEditAccess(distributedPortalsInfo, portal, currentUser.Id);
    const endButton = (
      <Button size="small" onClick={() => endCampaign(portal)} disabled={disabled}>
        End
      </Button>
    );
    if (disabled) return endButton;
    return <Tooltip title="Clicking 'End' will end the Intelligent Advertising campaign">{endButton}</Tooltip>;
  };

  const activePortalsList = [...distributedPortals].sort().map(portal => (
    <div className={styles.portalsListItem}>
      <Spin spinning={endCampaignLoader[portal] ?? false}>
        <GetJobBoardIcon portal={portal} style={{ color: '#13c26b', fontSize: '20px', marginRight: '8px' }} />{' '}
        {JobStatusChangeActivePortalText(portal)} {endCampaignButton(portal)}
      </Spin>
    </div>
  ));

  return (
    <div>
      <Modal visible={visible} footer={null} onCancel={onCancel} maskClosable={false} centered width={420}>
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="exclamation-circle" theme="filled" />
          </div>
          <div className={styles.modalContent}>
            <h3>{getJobStatusChangeModalHeading({ distributedPortals, isDripCampaignScheduled })}</h3>
            <p className={styles.message}>
              {getJobStatusChangeModalDescription({ distributedPortals, isDripCampaignScheduled })}
            </p>
            {distributedPortals.length ? <div className={styles.portalsList}>{activePortalsList}</div> : null}
            <div className={styles.actionButton}>
              <Button
                type="default"
                style={{ marginRight: '8px' }}
                shape="round"
                key="cancel-button"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="primary" shape="round" key="ok-button" onClick={onConfirm}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
