import { setApiStatus } from '../ApiStatusActions';

export const UPDATE_USER_API_STATUS = 'UPDATE_USER_API_STATUS';
export const SET_USERS_BY_GUID = 'SET_USERS_BY_GUID';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';

export const LINK_SENT_SUCCESSFULLY_TEXT = 'Link sent successfully';

export const setUpdateUserDetailsApiStatus = status => ({
  type: UPDATE_USER_API_STATUS,
  payload: status,
});

export const setUpdatedUserDetails = data => ({
  type: SET_USERS_BY_GUID,
  payload: data,
});

export function setUserDetailsApiStatus(status) {
  const apiName = 'fetchUserDetailsApiStatus';
  return setApiStatus({ apiName, status });
}

export const clearUserList = () => ({
  type: CLEAR_USER_LIST,
});
