import React from 'react';
import { Button } from 'antd';
import CandidateCardContext from '../../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import styles from './CandidateCardShowDuplicate.module.scss';

function CandidateCardShowDuplicate() {
  const { areDuplicateCandidatesVisible, setDuplicateCandidatesVisibility } = React.useContext(CandidateCardContext);

  const showDuplicateCardButtonText = areDuplicateCandidatesVisible ? 'Hide Duplicates' : 'See Duplicates';

  const onClickShowDuplicatesButton = e => {
    e.stopPropagation();
    setDuplicateCandidatesVisibility(!areDuplicateCandidatesVisible);
  };

  const showDuplicatesCardButton = (
    <Button onClick={onClickShowDuplicatesButton} type="link" className={styles.showDuplicatesCardButton} size="small">
      {showDuplicateCardButtonText}
    </Button>
  );

  return <div className={styles.showDuplicatesCardWrapper}>{showDuplicatesCardButton}</div>;
}

export default CandidateCardShowDuplicate;
