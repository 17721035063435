import React from 'react';
import { Modal, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { getApiStatus } from '../../../../../Reducers/ApiStatusReducer';
import styles from './SaveSearchCriteriaModal.module.scss';

const mapStateToProps = state => ({
  saveManualSearchCriteriaApiStatus: getApiStatus(state, 'saveManualSearchCriteriaApiStatus'),
});
function SaveSearchCriteriaModal({
  isSaveSearchCriteriaModalVisible,
  handleSaveCriteria,
  setIsSaveSearchCriteriaModalVisible,
  saveManualSearchCriteriaApiStatus,
  setCriteriaName,
  isSaveSearchCriteriaFailureModalVisible,
  setSaveSearchCriteriaFailureModalVisible,
  criteriaName,
  handleUpdate,
}) {
  const handleSave = () => {
    setSaveSearchCriteriaFailureModalVisible(false);
    setIsSaveSearchCriteriaModalVisible(true);
  };
  const handleCancelSaveSearchCriteriaFailureModal = () => {
    setSaveSearchCriteriaFailureModalVisible(false);
  };
  const handleCancelSaveSearchCriteriaModal = () => {
    setIsSaveSearchCriteriaModalVisible(false);
  };
  return (
    <>
      <Modal
        title={<div className={styles.saveSearchCriteriaModalTitle}>Save Search Criteria</div>}
        footer={null}
        height="223px"
        width="471px"
        visible={isSaveSearchCriteriaModalVisible}
        onCancel={handleCancelSaveSearchCriteriaModal}
      >
        <div className={styles.saveSearchCriteriaModalContent}>
          <div className={styles.saveSearchCriteriaModalDescription}>
            <Input
              size="large"
              placeholder="Frontend Devloper_Ben"
              onChange={event => setCriteriaName(event.target.value)}
              style={{ width: '409px', marginBottom: '31px' }}
            />
          </div>
          <div className={styles.saveSearchCriteriaModalFooter}>
            <div className={styles.note}>Note: Job Location and experience will not be saved</div>
            <Button
              type="primary"
              shape="round"
              onClick={handleSaveCriteria}
              size="large"
              loading={saveManualSearchCriteriaApiStatus === 'INPROGRESS'}
              disabled={!criteriaName?.length}
            >
              Save Criteria
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isSaveSearchCriteriaFailureModalVisible}
        onCancel={handleCancelSaveSearchCriteriaFailureModal}
        width="479px"
        centered
        footer={null}
      >
        <div className={styles.saveSearchCriteriaFailureModalContent}>
          <div className={styles.saveSearchCriteriaFailureModalHeader}>
            {`"${criteriaName}"`} already exists.
            <br /> Do you want to update it?
          </div>
          <div className={styles.saveSearchCriteriaFailureModalDescription}>
            A set of criteria under the same name is already saved to the
            <br /> system. Replacing it will overwrite the saved search criteria.
          </div>
          <div className={styles.saveSearchCriteriaFailureModalFooter}>
            <Button
              onClick={handleUpdate}
              shape="round"
              size="large"
              loading={saveManualSearchCriteriaApiStatus === 'INPROGRESS'}
            >
              Update
            </Button>
            <Button onClick={handleSave} type="primary" shape="round" size="large">
              Save as
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default connect(mapStateToProps)(SaveSearchCriteriaModal);
export { SaveSearchCriteriaModal as SaveSearchCriteriaModalWithoutStore };
