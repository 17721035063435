import _ from 'lodash';

export function getFormattedClientOptions(clients = []) {
  return clients?.map(client => {
    return {
      Id: client?.Id,
      Value: client?.VaultClientId,
      Name: client?.Name,
    };
  });
}

export function getFormattedRecruiterOptions(recruiters = []) {
  return recruiters?.map(recruiter => {
    return {
      Id: recruiter?.Id,
      Name: recruiter?.FullName,
      Title: recruiter?.Email,
    };
  });
}

export function getFormattedColumnOptions(columns = []) {
  return columns?.map(column => {
    return {
      key: column?.AryaName,
      title: column?.DisplayName,
    };
  });
}

export function getFormattedColumnOptionValues(columns = []) {
  return columns?.map(column => {
    return column?.AryaName;
  });
}

export function getOrgsWithCurrentUserOrg(organizations, currentUserOrg) {
  return _.uniqBy([currentUserOrg].concat(organizations), org => org?.Id)?.filter(
    org => org?.Id
  );
}
