import React from 'react';
import _ from 'lodash';
import { Checkbox, Avatar, Tooltip } from 'antd';
import { getConfiguration } from '../../Components/Collaborators/Collaborators';
import styles from './ShareModalItemUtil.module.scss';

export function ShareModalItemUtil(props) {
  const {
    usersById,
    id,
    isDisabled,
    defaultChecked,
    onChangeUserSelection,
    currentSharedUserIds,
    currentUnsharedUserIds,
    email,
    isShareWithAllChecked,
  } = props;
  const firstName = _.get(usersById, [id, 'FirstName'], '');
  const lastName = _.get(usersById, [id, 'LastName'], '');
  const fullName = `${firstName} ${lastName}`;
  const config = getConfiguration(fullName || email);
  const styleAvatar = { color: '#ffffff', backgroundColor: config.color };
  return (
    <div key={id} className={styles.userItem}>
      <Checkbox
        onChange={onChangeUserSelection}
        checked={
          defaultChecked ||
          currentSharedUserIds.includes(id) ||
          (isShareWithAllChecked && !currentUnsharedUserIds.includes(id))
        }
        value={id}
        disabled={isDisabled}
      />
      <Avatar style={styleAvatar}>{config.text}</Avatar>
      <Tooltip title={email}>
        <span>{fullName || email}</span>
      </Tooltip>
    </div>
  );
}
