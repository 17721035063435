import _ from 'lodash';

export function emailValidator(email) {
  if (!email || email.length > 128) {
    return false;
  }
  const regex = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  const emailParts = email.split('@');
  if (emailParts?.length !== 2) {
    return false;
  }
  const [account] = emailParts;
  if (account?.length > 64) {
    return false;
  }
  return !!regex.test(email.trim());
}
// https://www.npmjs.com/package/email-validator

export function passwordValidator(value) {
  let callbackMessage = '';
  const regexCapital = /^(.*[A-Z].*)$/;
  const regexSmall = /^(.*[a-z].*)$/;
  const regexDigit = /^(.*[0-9].*)$/;
  const regexSpecial = /^(.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*)$/;
  const regexLength = /^(.*).{8,}$/;
  if (value?.length > 20) {
    return 'Length must be 8-20 characters.';
  }
  if (!regexLength.test(value)) {
    callbackMessage = 'Length must be 8-20 characters.';
  } else if (!regexCapital.test(value)) {
    callbackMessage = 'Requires a capital letter.';
  } else if (!regexSmall.test(value)) {
    callbackMessage = 'Requires a small letter.';
  } else if (!regexDigit.test(value)) {
    callbackMessage = 'Requires a digit.';
  } else if (!regexSpecial.test(value)) {
    callbackMessage = 'Requires a special character.';
  }
  return callbackMessage;
}

export const validateInputField = (value, limit, inputField) => {
  const trimmedValue = value?.trim();
  if (!trimmedValue) {
    return `Please enter the ${inputField.toLowerCase()}.`;
  }
  if (limit && trimmedValue?.length > limit) return `Length cannot be greater than ${limit}.`;
  if (inputField === 'Email Address' && !emailValidator(value)) return 'Please enter a valid email address.';
  if (inputField === 'Password') return passwordValidator(value);
  return '';
};

export const emailProviders = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'live.com',
  'rediffmail.com',
  'free.fr',
  'gmx.de',
  'web.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
  'uol.com.br',
  'bol.com.br',
  'mail.ru',
  'cox.net',
  'hotmail.it',
  'sbcglobal.net',
  'sfr.fr',
  'live.fr',
  'verizon.net',
  'live.co.uk',
  'googlemail.com',
  'yahoo.es',
  'ig.com.br',
  'live.nl',
  'bigpond.com',
  'terra.com.br',
  'yahoo.it',
  'neuf.fr',
  'yahoo.de',
  'alice.it',
  'rocketmail.com',
  'att.net',
  'laposte.net',
  'facebook.com',
  'bellsouth.net',
  'yahoo.in',
  'hotmail.es',
  'charter.net',
  'yahoo.ca',
  'yahoo.com.au',
  'rambler.ru',
  'hotmail.de',
  'tiscali.it',
  'shaw.ca',
  'yahoo.co.jp',
  'sky.com',
  'earthlink.net',
  'optonline.net',
  'freenet.de',
  't-online.de',
  'aliceadsl.fr',
  'virgilio.it',
  'home.nl',
  'qq.com',
  'telenet.be',
  'me.com',
  'yahoo.com.ar',
  'tiscali.co.uk',
  'yahoo.com.mx',
  'voila.fr',
  'gmx.net',
  'mail.com',
  'planet.nl',
  'tin.it',
  'live.it',
  'ntlworld.com',
  'arcor.de',
  'yahoo.co.id',
  'frontiernet.net',
  'hetnet.nl',
  'live.com.au',
  'yahoo.com.sg',
  'zonnet.nl',
  'club-internet.fr',
  'juno.com',
  'optusnet.com.au',
  'blueyonder.co.uk',
  'bluewin.ch',
  'skynet.be',
  'sympatico.ca',
  'windstream.net',
  'mac.com',
  'centurytel.net',
  'chello.nl',
  'live.ca',
  'aim.com',
  'bigpond.net.au',
];

export const validateUserEmail = (value, limit, inputField) => {
  const error = validateInputField(value, limit, inputField);
  if (error) return error;
  const trimmedValue = value?.trim()?.toLowerCase();
  const invalidEmail = emailProviders.find(provider => trimmedValue?.toLowerCase()?.endsWith(`@${provider}`));
  if (invalidEmail) return `Please use your work email address.`;
  return '';
};

const getIsParenthesisBalanced = ({ inputString, bracketsToBeValidated = ['(', '{', '['] }) => {
  if (!inputString?.length) {
    return true;
  }
  const bracketPairs = { ')': '(', '}': '{', ']': '[' };
  const filteredBracketKeys = Object.keys(bracketPairs).filter(x => bracketsToBeValidated.includes(bracketPairs[x]));
  const filteredBracketPairs = _.pick(bracketPairs, filteredBracketKeys);
  const stack = [];
  for (let i = 0; i < inputString.length; i += 1) {
    const character = inputString.charAt(i);
    if (character in filteredBracketPairs) {
      if (!stack.length || filteredBracketPairs[character] !== stack.pop()) {
        return false;
      }
    } else if (bracketsToBeValidated.includes(character)) {
      stack.push(character);
    }
  }
  return !stack.length;
};

export const validateTemplateContent = ({ templateContent, mergeTags = [] }) => {
  if (!templateContent?.length) {
    return true;
  }
  const isParenthesisBalanced = getIsParenthesisBalanced({
    inputString: templateContent,
    bracketsToBeValidated: ['{'],
  });
  if (!isParenthesisBalanced) {
    return false;
  }
  const mergeTagKeys = mergeTags.map(mergeTag => mergeTag?.Key?.toLowerCase()).filter(x => x);
  const mergeTagRegex = /{{(\w+[._]\w+)}}/gm;
  const bracketPairRegex = /{{.+?}}/gm;
  const matchedMergeTags = [...templateContent.matchAll(mergeTagRegex)];
  const mergeTagValues = matchedMergeTags.map(mergeTag => mergeTag[1]?.toLowerCase()).filter(x => x);
  const _matchedMergeTags = templateContent.match(bracketPairRegex) ?? [];
  if (mergeTagValues.some(x => ![...mergeTagKeys, 'job.company'].includes(x))) {
    // Job.Company is added to support the templates which are already created with Job.Company mergeTag.
    // We don't have Job.Company in the mergeTag list
    return false;
  }
  return matchedMergeTags?.length === _matchedMergeTags?.length;
};

const validateTemplate = ({ subject, preHeader, body, subjectMergeTags, preHeaderMergeTags, bodyMergeTags }) => {
  const isEmailSubjectValid = validateTemplateContent({ templateContent: subject, mergeTags: subjectMergeTags });
  const isBobyContentValid = validateTemplateContent({ templateContent: body, mergeTags: bodyMergeTags });
  const isPreHeaderContentValid = validateTemplateContent({
    templateContent: preHeader,
    mergeTags: preHeaderMergeTags,
  });
  return isEmailSubjectValid && isBobyContentValid && isPreHeaderContentValid;
};

const isValidNaturalNumber = value => {
  const naturalNumberRegex = /^\d+$/;
  return value && naturalNumberRegex.test(value);
};
const validateMinExperience = (minExp, maxExp, callback) => {
  if (minExp && !Number.isInteger(minExp)) {
    callback(
      'This entry can only contain numbers without decimals. And must not include alphabets and special characters.'
    );
    return false;
  }
  if (maxExp) {
    if (minExp > 50) {
      callback("Min Exp. can't be more than 50 years");
      return false;
    }
    if (minExp > maxExp) {
      callback("Min Exp. can't be greater than Max Exp.");
      return false;
    }
  }
  if (minExp < 0) {
    callback('Negative values are not allowed');
    return false;
  }
  if (minExp > 50) {
    callback("Min Exp. can't be more than 50 years");
    return false;
  }
  callback('');
  return true;
};
const validateMaxExperience = (maxExp, minExp, callback) => {
  if (maxExp && !Number.isInteger(maxExp)) {
    callback(
      'This entry can only contain numbers without decimals. And must not include alphabets and special characters.'
    );
    return false;
  }
  if (minExp) {
    if (maxExp > 50) {
      callback("Max Exp. can't be more than 50 years");
      return false;
    }
    if (maxExp < minExp) {
      callback("Max Exp. can't be less than Min Exp.");
      return false;
    }
  }
  if (maxExp < 0) {
    callback('Negative values are not allowed');
    return false;
  }
  if (maxExp > 50) {
    callback("Max Exp. can't be more than 50 years");
    return false;
  }
  callback('');
  return true;
};

export const docxExtensionRegex = /\.docx$/;

export {
  getIsParenthesisBalanced,
  validateTemplate,
  isValidNaturalNumber,
  validateMinExperience,
  validateMaxExperience,
};
