import { apiV3Client } from './BaseRepository';

export function fetchOrganizations(filter) {
  return apiV3Client.post(`/organizations/_search`, filter);
}

export function fetchAccountTypes() {
  return apiV3Client.get(`/_config/accountTypes`);
}
export function fetchLicenceTypes() {
  return apiV3Client.get(`/_config/licenceTypes`);
}
