import React from 'react';
import { Form, InputNumber } from 'antd';
import { validateMinExperience, validateMaxExperience } from '../../Utils/Validators';

function MinMaxExperienceWithoutForm(props) {
  const {
    handleMatchingJobMinExperienceOptionChange,
    handleMatchingJobMaxExperienceOptionChange,
    inputStyle,
    minExperience,
    setMinExperience,
    maxExperience,
    setMaxExperience,
    isMinExperienceVisible = true,
    isMaxExperienceVisible = true,
    selectedMatchingJobMinExperienceFilterOptions,
  } = props;

  const [minExperienceInputValidationError, setMinExperienceInputValidationError] = React.useState('');
  const [maxExperienceInputValidationError, setMaxExperienceInputValidationError] = React.useState('');

  const onMinExperienceChange = value => {
    if (validateMinExperience(value, maxExperience, setMinExperienceInputValidationError)) {
      setMinExperience(value);
      handleMatchingJobMinExperienceOptionChange(value);
    }
  };

  const onMaxExperienceChange = value => {
    if (validateMaxExperience(value, minExperience, setMaxExperienceInputValidationError)) {
      setMaxExperience(value);
      handleMatchingJobMaxExperienceOptionChange(value);
    }
  };

  const minExperienceInputForm = isMinExperienceVisible ? (
    <Form.Item
      validateStatus={minExperienceInputValidationError ? 'error' : 'success'}
      help={minExperienceInputValidationError}
      initialValue={minExperience ?? selectedMatchingJobMinExperienceFilterOptions}
      className={inputStyle.minExperience}
    >
      <InputNumber
        min={0}
        max={50}
        placeholder="0 years"
        value={minExperience ?? selectedMatchingJobMinExperienceFilterOptions}
        onChange={onMinExperienceChange}
        data-testid="min-experience"
      />
    </Form.Item>
  ) : null;

  const maxExperienceInputForm = isMaxExperienceVisible ? (
    <Form.Item
      validateStatus={maxExperienceInputValidationError ? 'error' : 'success'}
      help={maxExperienceInputValidationError}
      initialValue={maxExperience}
      className={inputStyle.maxExperience}
    >
      <InputNumber min={0} max={50} placeholder="0 years" value={maxExperience} onChange={onMaxExperienceChange} />
    </Form.Item>
  ) : null;

  return (
    <div className={inputStyle.experienceRange}>
      <div className={inputStyle.experienceRangeText}>Experience</div>
      {minExperienceInputForm}
      {maxExperienceInputForm}
    </div>
  );
}

export default MinMaxExperienceWithoutForm;
