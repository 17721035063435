import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { InputNumber, Form, Select, Checkbox } from 'antd';
import styles from './DelayNodeContent.module.scss';
import InfoIconWithTooltip from '../../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import { validateWaitTime } from '../../../Containers/WorkflowCreationContainer/WorkflowUtils/WorkflowElementUtils';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';

export default function DelayNodeContent(props) {
  const { form, initialValue, drawerFooter, isDisabled, isFirstEdge, isFirstNode } = props;
  const [waitUnit, setWaitUnit] = React.useState(form.getFieldsValue().WaitUnit);
  const FormItem = Form.Item;
  const { Option } = Select;
  const weekDaysOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const featureToggleList = useSelector(state => getFeatureToggleList(state));

  const getClassnameForResumeSection = classnames(
    { [styles.resumeSectionShow]: waitUnit === 'days' },
    { [styles.resumeSectionHide]: waitUnit !== 'days' }
  );

  const isResumeDisabled = waitUnit !== 'days' || isDisabled;

  React.useEffect(() => {
    setWaitUnit(form.getFieldsValue().WaitUnit);
  }, [form.getFieldsValue().WaitUnit]);

  return (
    <div className={styles.delayNodeContainer}>
      <div style={{ display: 'flex' }}>
        <span id={styles.waitLabel}>Wait</span>
        &nbsp;&nbsp;
        <FormItem>
          {form.getFieldDecorator('WaitTime', {
            initialValue: _.get(initialValue, 'WaitTime', 1),
            rules: [
              {
                required: true,
                validator: (rule, value, callback) =>
                  validateWaitTime(rule, value, callback, isFirstEdge || isFirstNode ? 0 : 1),
              },
            ],
          })(<InputNumber style={{ width: '70px' }} disabled={isDisabled} />)}
        </FormItem>
        &nbsp;&nbsp;
        <FormItem>
          {form.getFieldDecorator('WaitUnit', { initialValue: _.get(initialValue, 'WaitUnit', 'days') })(
            <Select style={{ width: 90 }} getPopupContainer={trigger => trigger.parentNode} disabled={isDisabled}>
              <Option value="days">Day(s)</Option>
              <Option value="hours">Hour(s)</Option>
              {featureToggleList.MinutesDelayInWorkflow.IsEnabled ? <Option value="minutes">Minute(s)</Option> : null}
            </Select>
          )}
        </FormItem>
        &nbsp;&nbsp;
        <span id={styles.tooltipIcon}>
          <InfoIconWithTooltip tooltipTitle={<div> Minimum wait time is 1 day or hour</div>} />
        </span>
        &nbsp;&nbsp;&nbsp;
        {waitUnit === 'days' ? <span id={styles.waitLabel}>and</span> : null}
      </div>

      <div className={getClassnameForResumeSection}>
        <div className={styles.resumeTimeSection}>
          <span>Resume at</span>
          <FormItem>
            {form.getFieldDecorator('ResumeTime', {
              initialValue: _.get(initialValue, 'ResumeAt.Time', undefined),
            })(
              <Select
                style={{ width: 90 }}
                disabled={isResumeDisabled}
                placeholder="Time"
                dropdownMenuStyle={{ maxHeight: 100 }}
                getPopupContainer={trigger => trigger.parentNode}
                allowClear
              >
                <Option value="8 am">8 am</Option>
                <Option value="10 am">10 am</Option>
                <Option value="12 pm">12 pm</Option>
                <Option value="3 pm">3 pm</Option>
                <Option value="5 pm">5 pm</Option>
                <Option value="7 pm">7 pm</Option>
              </Select>
            )}
          </FormItem>
        </div>
        <div className={styles.checkboxGroup}>
          <span>On</span>
          <FormItem>
            {form.getFieldDecorator('ResumeDays', {
              initialValue: _.get(initialValue, 'ResumeAt.Days', []),
            })(<Checkbox.Group options={weekDaysOptions} disabled={isResumeDisabled} />)}
          </FormItem>
        </div>
      </div>
      <div className={styles.footer}> {drawerFooter}</div>
    </div>
  );
}
