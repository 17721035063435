import React from 'react';
import { Tag, Popover, Icon } from 'antd';
import detectElementOverflow from 'detect-element-overflow';
import { getAggregationCountPairs } from '../QuickSearchForm/QuickSearchFormUtils';
import { aggregationKeysToDisplayNameMapper } from '../../Utils/AggregationFilterUtils';
import styles from './SelectedAggregationFilters.module.scss';

export default function SelectedAggregationFilters(props) {
  const { selectedAggregations, removeAggregation, sourceName } = props;
  const [overflownTags, setOverflownTags] = React.useState([]);
  const aggregationCountPairs = getAggregationCountPairs(selectedAggregations, sourceName);

  React.useEffect(() => {
    const limitedAggregationFiltersDiv = document.getElementById('displayedAggregations-id');
    let currentOverflownTags = [];
    const overflownTagIndex = aggregationCountPairs.findIndex(aggregationCountPair => {
      const aggregationFilterDiv = document.getElementById(aggregationCountPair.key);
      const isElementOverflown = detectElementOverflow(aggregationFilterDiv, limitedAggregationFiltersDiv)
        .collidedBottom;
      return isElementOverflown === true;
    });
    if (overflownTagIndex !== -1) currentOverflownTags = aggregationCountPairs.slice(overflownTagIndex);
    if (currentOverflownTags.length || overflownTags.length) {
      setOverflownTags(currentOverflownTags);
    }
  }, [JSON.stringify(aggregationCountPairs)]);

  return (
    <div className={styles.selectedAggregationsWrapper}>
      <div className={styles.displayedAggregations} id="displayedAggregations-id">
        {aggregationCountPairs.map(aggregationCountPair => {
          const { key: aggregationKey, count: aggregationCount } = aggregationCountPair;
          const aggregationKeyDisplayName =
            aggregationKeysToDisplayNameMapper[sourceName?.toLowerCase()][aggregationKey];
          return (
            <Tag
              className={styles.aggregationTag}
              closable
              key={aggregationKey}
              id={aggregationKey}
              onClose={() => removeAggregation(sourceName, aggregationKey)}
            >
              {aggregationKeyDisplayName} {`(${aggregationCount})`}
            </Tag>
          );
        })}
      </div>
      <Popover
        trigger="click"
        content={
          overflownTags.length ? (
            <div className={styles.overflownAggregationsContent}>
              {overflownTags.map(overflownTag => {
                const { key: aggregationKey, count: aggregationCount } = overflownTag;
                const aggregationKeyDisplayName =
                  aggregationKeysToDisplayNameMapper[sourceName?.toLowerCase()][aggregationKey];
                return (
                  <div className={styles.overflownAggregation} key={aggregationKey}>
                    <span>
                      {aggregationKeyDisplayName} {`(${aggregationCount})`}
                    </span>
                    <Icon type="close" onClick={() => removeAggregation(sourceName, aggregationKey)} />
                  </div>
                );
              })}
            </div>
          ) : null
        }
      >
        {' '}
        {overflownTags?.length ? (
          <div className={styles.overflownAggregationsCount}>+{overflownTags.length}</div>
        ) : null}
      </Popover>
    </div>
  );
}
