export const Languages = [
  {
    value: 'AboriginalDialects',
    displayText: 'Aboriginal Dialects',
  },
  {
    value: 'Afrikaans',
    displayText: 'Afrikaans',
  },
  {
    value: 'AmericanSignLanguage',
    displayText: 'American Sign Language',
  },
  {
    value: 'Amharic',
    displayText: 'Amharic',
  },
  {
    value: 'AncientGreek',
    displayText: 'Ancient Greek',
  },
  {
    value: 'Arabic',
    displayText: 'Arabic',
  },
  {
    value: 'Assamese',
    displayText: 'Assamese',
  },
  {
    value: 'AustralianSignLanguage',
    displayText: 'Australian Sign Language',
  },
  {
    value: 'Azerbaijani',
    displayText: 'Azerbaijani',
  },
  {
    value: 'BahasaIndonesia',
    displayText: 'Bahasa Indonesia',
  },
  {
    value: 'Basque',
    displayText: 'Basque',
  },
  {
    value: 'Belarusian',
    displayText: 'Belarusian',
  },
  {
    value: 'Bengali',
    displayText: 'Bengali',
  },
  {
    value: 'BrazilianPortugese',
    displayText: 'Brazilian Portugese',
  },
  {
    value: 'Breton',
    displayText: 'Breton',
  },
  {
    value: 'BritishSignLanguage',
    displayText: 'British Sign Language',
  },
  {
    value: 'Bulgarian',
    displayText: 'Bulgarian',
  },
  {
    value: 'Burmese',
    displayText: 'Burmese',
  },
  {
    value: 'Catalan',
    displayText: 'Catalan',
  },
  {
    value: 'Chinese',
    displayText: 'Chinese',
  },
  {
    value: 'Cantonese',
    displayText: 'Chinese - Cantonese',
  },
  {
    value: 'Mandarin',
    displayText: 'Chinese - Mandarin',
  },
  {
    value: 'Chinese-ShanghaiDialect',
    displayText: 'Chinese - Shanghai Dialect',
  },
  {
    value: 'Chinese-SouthernFujianDialect',
    displayText: 'Chinese - Southern Fujian Dialect',
  },
  {
    value: 'Taiwanese',
    displayText: 'Chinese - Taiwanese',
  },
  {
    value: 'Corsican',
    displayText: 'Corsican',
  },
  {
    value: 'Croatian',
    displayText: 'Croatian',
  },
  {
    value: 'Czech',
    displayText: 'Czech',
  },
  {
    value: 'Danish',
    displayText: 'Danish',
  },
  {
    value: 'Dutch',
    displayText: 'Dutch',
  },
  {
    value: 'Egyptian',
    displayText: 'Egyptian',
  },
  {
    value: 'English',
    displayText: 'English',
  },
  {
    value: 'Estonian',
    displayText: 'Estonian',
  },
  {
    value: 'Farsi',
    displayText: 'Farsi',
  },
  {
    value: 'Fijian',
    displayText: 'Fijian',
  },
  {
    value: 'Finnish',
    displayText: 'Finnish',
  },
  {
    value: 'French',
    displayText: 'French',
  },
  {
    value: 'Frisian',
    displayText: 'Frisian',
  },
  {
    value: 'Gaelic',
    displayText: 'Gaelic',
  },
  {
    value: 'German',
    displayText: 'German',
  },
  {
    value: 'Greek',
    displayText: 'Greek',
  },
  {
    value: 'Greenlandic',
    displayText: 'Greenlandic',
  },
  {
    value: 'Gujarati',
    displayText: 'Gujarati',
  },
  {
    value: 'HaitianCreole',
    displayText: 'Haitian Creole',
  },
  {
    value: 'Hebrew',
    displayText: 'Hebrew',
  },
  {
    value: 'Hindi',
    displayText: 'Hindi',
  },
  {
    value: 'Hindustan',
    displayText: 'Hindustan',
  },
  {
    value: 'Hmong',
    displayText: 'Hmong',
  },
  {
    value: 'Hungarian',
    displayText: 'Hungarian',
  },
  {
    value: 'Icelandic',
    displayText: 'Icelandic',
  },
  {
    value: 'Irish',
    displayText: 'Irish',
  },
  {
    value: 'IrishGaelic',
    displayText: 'Irish Gaelic',
  },
  {
    value: 'Italian',
    displayText: 'Italian',
  },
  {
    value: 'Japanese',
    displayText: 'Japanese',
  },
  {
    value: 'Kannada',
    displayText: 'Kannada',
  },
  {
    value: 'Kashmiri',
    displayText: 'Kashmiri',
  },
  {
    value: 'Kazakh',
    displayText: 'Kazakh',
  },
  {
    value: 'Korean',
    displayText: 'Korean',
  },
  {
    value: 'Kurdish',
    displayText: 'Kurdish',
  },
  {
    value: 'Kyrgyz',
    displayText: 'Kyrgyz',
  },
  {
    value: 'Laotian',
    displayText: 'Laotian',
  },
  {
    value: 'Latin',
    displayText: 'Latin',
  },
  {
    value: 'Latvian',
    displayText: 'Latvian',
  },
  {
    value: 'Lithuanian',
    displayText: 'Lithuanian',
  },
  {
    value: 'Malay',
    displayText: 'Malay',
  },
  {
    value: 'Malayalam',
    displayText: 'Malayalam',
  },
  {
    value: 'Manipuri',
    displayText: 'Manipuri',
  },
  {
    value: 'ManxGaelic',
    displayText: 'Manx Gaelic',
  },
  {
    value: 'Maori',
    displayText: 'Maori',
  },
  {
    value: 'Marathi',
    displayText: 'Marathi',
  },
  {
    value: 'Nepali',
    displayText: 'Nepali',
  },
  {
    value: 'Norwegian',
    displayText: 'Norwegian',
  },
  {
    value: 'Oriya',
    displayText: 'Oriya',
  },
  {
    value: 'Other',
    displayText: 'Other',
  },
  {
    value: 'Polish',
    displayText: 'Polish',
  },
  {
    value: 'Portuguese',
    displayText: 'Portuguese',
  },
  {
    value: 'Prakrit',
    displayText: 'Prakrit',
  },
  {
    value: 'Punjabi',
    displayText: 'Punjabi',
  },
  {
    value: 'Romanian',
    displayText: 'Romanian',
  },
  {
    value: 'Russian',
    displayText: 'Russian',
  },
  {
    value: 'Samoan',
    displayText: 'Samoan',
  },
  {
    value: 'Sanskrit',
    displayText: 'Sanskrit',
  },
  {
    value: 'Scots',
    displayText: 'Scots',
  },
  {
    value: 'ScotsGaelic',
    displayText: 'Scots Gaelic',
  },
  {
    value: 'Serbian',
    displayText: 'Serbian',
  },
  {
    value: 'Sicilian',
    displayText: 'Sicilian',
  },
  {
    value: 'Slovak',
    displayText: 'Slovak',
  },
  {
    value: 'Slovene',
    displayText: 'Slovene',
  },
  {
    value: 'Somali',
    displayText: 'Somali',
  },
  {
    value: 'Spanish',
    displayText: 'Spanish',
  },
  {
    value: 'Swahili',
    displayText: 'Swahili',
  },
  {
    value: 'Swedish',
    displayText: 'Swedish',
  },
  {
    value: 'Tagalog',
    displayText: 'Tagalog',
  },
  {
    value: 'Tamil',
    displayText: 'Tamil',
  },
  {
    value: 'Telugu',
    displayText: 'Telugu',
  },
  {
    value: 'Thai',
    displayText: 'Thai',
  },
  {
    value: 'Tibetan',
    displayText: 'Tibetan',
  },
  {
    value: 'Tongan',
    displayText: 'Tongan',
  },
  {
    value: 'Turkish',
    displayText: 'Turkish',
  },
  {
    value: 'Ukrainian',
    displayText: 'Ukrainian',
  },
  {
    value: 'Urdu',
    displayText: 'Urdu',
  },
  {
    value: 'Vietnamese',
    displayText: 'Vietnamese',
  },
  {
    value: 'Welsh',
    displayText: 'Welsh',
  },
  {
    value: 'Yiddish',
    displayText: 'Yiddish',
  },
  {
    value: 'Ainu',
    displayText: 'Ainu',
  },
  {
    value: 'Akkadian',
    displayText: 'Akkadian',
  },
  {
    value: 'Alurian',
    displayText: 'Alurian',
  },
  {
    value: 'Arkian',
    displayText: 'Arkian',
  },
  {
    value: 'Assyrian',
    displayText: 'Assyrian',
  },
  {
    value: 'Asturian',
    displayText: 'Asturian',
  },
  {
    value: 'Aymara',
    displayText: 'Aymara',
  },
  {
    value: 'BasqueLanguageEuskara',
    displayText: 'Basque Language - Euskara',
  },
  {
    value: 'Berber',
    displayText: 'Berber',
  },
  {
    value: 'Buhi',
    displayText: 'Buhi',
  },
  {
    value: 'Cherokee',
    displayText: 'Cherokee',
  },
  {
    value: 'Chichewa',
    displayText: 'Chichewa',
  },
  {
    value: 'ChurchSlavonic',
    displayText: 'Church Slavonic',
  },
  {
    value: 'Cornish',
    displayText: 'Cornish',
  },
  {
    value: 'Dakota',
    displayText: 'Dakota',
  },
  {
    value: 'Degaspregos',
    displayText: 'Degaspregos',
  },
  {
    value: 'Dilhok',
    displayText: 'Dilhok',
  },
  {
    value: 'Dongxiang',
    displayText: 'Dongxiang',
  },
  {
    value: 'Esperanto',
    displayText: 'Esperanto',
  },
  {
    value: 'Eurolang',
    displayText: 'Eurolang',
  },
  {
    value: 'Faroese',
    displayText: 'Faroese',
  },
  {
    value: 'Friulian',
    displayText: 'Friulian',
  },
  {
    value: 'Galician',
    displayText: 'Galician',
  },
  {
    value: 'Georgian',
    displayText: 'Georgian',
  },
  {
    value: 'Guarani',
    displayText: 'Guarani',
  },
  {
    value: 'Haponish',
    displayText: 'Haponish',
  },
  {
    value: 'Hausa',
    displayText: 'Hausa',
  },
  {
    value: 'Hawaiian',
    displayText: 'Hawaiian',
  },
  {
    value: 'HawaiianPidginEnglish',
    displayText: 'Hawaiian Pidgin English',
  },
  {
    value: 'Ido',
    displayText: 'Ido',
  },
  {
    value: 'Ingush',
    displayText: 'Ingush',
  },
  {
    value: 'Jameld',
    displayText: 'Jameld',
  },
  {
    value: 'Kankonian',
    displayText: 'Kankonian',
  },
  {
    value: 'Khmer',
    displayText: 'Khmer',
  },
  {
    value: 'Kiswahili',
    displayText: 'Kiswahili',
  },
  {
    value: 'Konkani',
    displayText: 'Konkani',
  },
  {
    value: 'Ladin',
    displayText: 'Ladin',
  },
  {
    value: 'Ladino',
    displayText: 'Ladino',
  },
  {
    value: 'Lakhota',
    displayText: 'Lakhota',
  },
  {
    value: 'Loglan',
    displayText: 'Loglan',
  },
  {
    value: 'LowSaxon',
    displayText: 'Low Saxon',
  },
  {
    value: 'Malat',
    displayText: 'Malat',
  },
  {
    value: 'Mongolian',
    displayText: 'Mongolian',
  },
  {
    value: 'Neelan',
    displayText: 'Neelan',
  },
  {
    value: 'Novial',
    displayText: 'Novial',
  },
  {
    value: 'Occitan',
    displayText: 'Occitan',
  },
  {
    value: 'Ojibwe',
    displayText: 'Ojibwe',
  },
  {
    value: 'Pashto',
    displayText: 'Pashto',
  },
  {
    value: 'Pidgin',
    displayText: 'Pidgin',
  },
  {
    value: 'Quechua',
    displayText: 'Quechua',
  },
  {
    value: 'RhaetoRomance',
    displayText: 'Rhaeto Romance',
  },
  {
    value: 'Romany',
    displayText: 'Romany',
  },
  {
    value: 'Shiyeyi',
    displayText: 'Shiyeyi',
  },
  {
    value: 'Sindhi',
    displayText: 'Sindhi',
  },
  {
    value: 'Sinhalese',
    displayText: 'Sinhalese',
  },
  {
    value: 'Swabian',
    displayText: 'Swabian',
  },
  {
    value: 'Tengwar',
    displayText: 'Tengwar',
  },
  {
    value: 'TokPisin',
    displayText: 'Tok Pisin',
  },
  {
    value: 'Uzbek',
    displayText: 'Uzbek',
  },
  {
    value: 'Vogu',
    displayText: 'Vogu',
  },
  {
    value: 'Xhamagas',
    displayText: 'Xhamagas',
  },
  {
    value: 'Yoruba',
    displayText: 'Yoruba',
  },
  {
    value: 'Albanian',
    displayText: 'Albanian',
  },
  {
    value: 'Luxembourgish',
    displayText: 'Luxembourgish',
  },
  {
    value: 'Sami',
    displayText: 'Sami',
  },
  {
    value: 'Xhosa',
    displayText: 'Xhosa',
  },
  {
    value: 'Zulu',
    displayText: 'Zulu',
  },
  {
    value: 'Bosnian',
    displayText: 'Bosnian',
  },
  {
    value: 'PidginSignedEnglish',
    displayText: 'Pidgin Signed English (PSE) or Signed English',
  },
  {
    value: 'SigningExactEnglish',
    displayText: 'Signing Exact English (SEE)',
  },
  {
    value: 'TactileSignLanguage',
    displayText: 'Tactile Sign Language',
  },
  {
    value: 'Armenian',
    displayText: 'Armenian',
  },
  {
    value: 'Macedonian',
    displayText: 'Macedonian',
  },
];

export const CompanyIndustries = [
  {
    value: 'AccountingAndAuditingServices',
    displayText: 'Accounting and Auditing Services',
  },
  {
    value: 'AdministrativeAndSupportServices',
    displayText: 'Administrative and Support Services',
  },
  {
    value: 'AdvertisingAndPRServices',
    displayText: 'Advertising and PR Services',
  },
  {
    value: 'AerospaceAndDefense',
    displayText: 'Aerospace and Defense',
  },
  {
    value: 'AgricultureForestryFishing',
    displayText: 'Agriculture/Forestry/Fishing',
  },
  {
    value: 'ArchitecturalAndDesignServices',
    displayText: 'Architectural and Design Services',
  },
  {
    value: 'AutomotiveAndParts Mfg',
    displayText: 'Automotive and Parts Mfg',
  },
  {
    value: 'AutomotiveSalesAndRepairServices',
    displayText: 'Automotive Sales and Repair Services',
  },
  {
    value: 'Banking',
    displayText: 'Banking',
  },
  {
    value: 'BiotechnologyPharmaceuticals',
    displayText: 'Biotechnology Pharmaceuticals',
  },
  {
    value: 'BroadcastingMusicAndFilm',
    displayText: 'Broadcasting, Music and Film',
  },
  {
    value: 'BusinessServicesOther',
    displayText: 'Business Services - Other',
  },
  {
    value: 'ChemicalsPetrochemicals',
    displayText: 'Chemicals/Petro-Chemicals',
  },
  {
    value: 'ClothingAndTextileManufacturing',
    displayText: 'Clothing and Textile Manufacturing',
  },
  {
    value: 'ComputerHardware',
    displayText: 'Computer Hardware',
  },
  {
    value: 'ComputerSoftware',
    displayText: 'Computer Software',
  },
  {
    value: 'ComputerITServices',
    displayText: 'Computer/IT Services',
  },
  {
    value: 'ConstructionIndustrialFacilitiesAndInfrastructure',
    displayText: 'Construction - Industrial Facilities and Infrastructure',
  },
  {
    value: 'ConstructionResidentialAndCommercialOffice',
    displayText: 'Construction - Residential & Commercial/Office',
  },
  {
    value: 'ConsumerPackagedGoodsManufacturing',
    displayText: 'Consumer Packaged Goods Manufacturing',
  },
  {
    value: 'Education',
    displayText: 'Education',
  },
  {
    value: 'ElectronicsComponentsAndSemiconductorMfg',
    displayText: 'Electronics, Components and Semiconductor Mfg',
  },
  {
    value: 'EnergyAndUtilities',
    displayText: 'Energy and Utilities',
  },
  {
    value: 'EngineeringServices',
    displayText: 'Engineering Services',
  },
  {
    value: 'EntertainmentVenuesAndTheaters',
    displayText: 'Entertainment Venues and Theaters',
  },
  {
    value: 'FinancialServices',
    displayText: 'Financial Services',
  },
  {
    value: 'FoodAndBeverageProduction',
    displayText: 'Food and Beverage Production',
  },
  {
    value: 'GovernmentAndMilitary',
    displayText: 'Government and Military',
  },
  {
    value: 'GovernmentMilitaryDefense',
    displayText: 'Government - Military/Defense',
  },
  {
    value: 'GovernmentNational',
    displayText: 'Government - National',
  },
  {
    value: 'GroceryConvenienceGas',
    displayText: 'Grocery/Convenience/Gas',
  },
  {
    value: 'HealthcareServices',
    displayText: 'Healthcare Services',
  },
  {
    value: 'HotelsAndLodging',
    displayText: 'Hotels and Lodging',
  },
  {
    value: 'Insurance',
    displayText: 'Insurance',
  },
  {
    value: 'InternetServices',
    displayText: 'Internet Services',
  },
  {
    value: 'LegalServices',
    displayText: 'Legal Services',
  },
  {
    value: 'ManagementAndHoldingCompanies',
    displayText: 'Management and Holding Companies',
  },
  {
    value: 'ManagementConsultingServices',
    displayText: 'Management Consulting Services',
  },
  {
    value: 'OtherManufacturing',
    displayText: 'Manufacturing - Other',
  },
  {
    value: 'MarineMfgAndServices',
    displayText: 'Marine Mfg and Services',
  },
  {
    value: 'MedicalDevicesAndSupplies',
    displayText: 'Medical Devices and Supplies',
  },
  {
    value: 'MetalsAndMinerals',
    displayText: 'Metals and Minerals',
  },
  {
    value: 'Mortgage',
    displayText: 'Mortgage',
  },
  {
    value: 'NonprofitCharitableOrganizations',
    displayText: 'Nonprofit Charitable Organizations',
  },
  {
    value: 'NursingResidentialCareFacilities',
    displayText: 'Nursing / Residential Care Facilities',
  },
  {
    value: 'Other',
    displayText: 'Other / Not Classified',
  },
  {
    value: 'PerformingAndFineArts',
    displayText: 'Performing and Fine Arts',
  },
  {
    value: 'PersonalAndHouseholdServices',
    displayText: 'Personal and Household Services',
  },
  {
    value: 'PrintingAndPublishing',
    displayText: 'Printing and Publishing',
  },
  {
    value: 'RealEstatePropertyManagement',
    displayText: 'Real Estate / Property Management',
  },
  {
    value: 'RentalServices',
    displayText: 'Rental Services',
  },
  {
    value: 'RepairAndMaintenanceServices',
    displayText: 'Repair and Maintenance Services',
  },
  {
    value: 'RestaurantFoodServices',
    displayText: 'Restaurant/Food Services',
  },
  {
    value: 'Retail',
    displayText: 'Retail',
  },
  {
    value: 'SecurityAndSurveillance',
    displayText: 'Security and Surveillance',
  },
  {
    value: 'SocialServices',
    displayText: 'Social Services',
  },
  {
    value: 'SportsAndPhysicalRecreation',
    displayText: 'Sports and Physical Recreation',
  },
  {
    value: 'StaffingEmploymentAgencies',
    displayText: 'Staffing/Employment Agencies',
  },
  {
    value: 'TelecommunicationsServices',
    displayText: 'Telecommunications Services',
  },
  {
    value: 'TradeContractors',
    displayText: 'Trade Contractors',
  },
  {
    value: 'TransportAndStorageMaterials',
    displayText: 'Transport and Storage - Materials',
  },
  {
    value: 'TravelTransportationAndTourism',
    displayText: 'Travel, Transportation and Tourism',
  },
  {
    value: 'WasteManagement',
    displayText: 'Waste Management',
  },
  {
    value: 'WholesaleTradeImportExport',
    displayText: 'Wholesale Trade/Import-Export',
  },
];

export const TargetIndustries = [
  'AgricultureForestryFishing',
  'MetalsAndMinerals',
  'EnergyAndUtilities',
  'ConstructionIndustrialFacilitiesAndInfrastructure',
  'TradeContractors',
  'AerospaceAndDefense',
  'AutomotiveAndParts Mfg',
  'BiotechnologyPharmaceuticals',
  'ChemicalsPetrochemicals',
  'ConsumerPackagedGoodsManufacturing',
  'ElectronicsComponentsAndSemiconductorMfg',
  'OtherManufacturing',
  'PrintingAndPublishing',
  'ClothingAndTextileManufacturing',
  'WholesaleTradeImportExport',
  'GroceryConvenienceGas',
  'Retail',
  'TravelTransportationAndTourism',
  'TransportAndStorageMaterials',
  'InternetServices',
  'BroadcastingMusicAndFilm',
  'TelecommunicationsServices',
  'Banking',
  'Insurance',
  'Mortgage',
  'RealEstatePropertyManagement',
  'RentalServices',
  'AccountingAndAuditingServices',
  'AdvertisingAndPRServices',
  'ArchitecturalAndDesignServices',
  'ManagementConsultingServices',
  'ComputerHardware',
  'ComputerSoftware',
  'LegalServices',
  'ManagementAndHoldingCompanies',
  'AdministrativeAndSupportServices',
  'WasteManagement',
  'Education',
  'HealthcareServices',
  'NursingResidentialCareFacilities',
  'SocialServices',
  'PerformingAndFineArts',
  'SportsAndPhysicalRecreation',
  'HotelsAndLodging',
  'RestaurantFoodServices',
  'StaffingEmploymentAgencies',
  'NonprofitCharitableOrganizations',
  'PersonalAndHouseholdServices',
  'RepairAndMaintenanceServices',
  'GovernmentAndMilitary',
  'GovernmentMilitaryDefense',
  'GovernmentNational',
  'SecurityAndSurveillance',
  'AutomotiveSalesAndRepairServices',
  'BusinessServicesOther',
  'ComputerITServices',
  'ConstructionResidentialAndCommercialOffice',
  'EngineeringServices',
  'EntertainmentVenuesAndTheaters',
  'FinancialServices',
  'FoodAndBeverageProduction',
  'MarineMfgAndServices',
  'MedicalDevicesAndSupplies',
  'Other',
];

export const Currencies = [
  'USDollar',
  'Euro',
  'ArgentinaPeso',
  'AustraliaDollar',
  'BelgiumFranc',
  'BrazilReal',
  'CanadaDollar',
  'SwitzerlandFranc',
  'ChinaYuanRenmimbi',
  'CzechRepublicKoruna',
  'GermanyDeutscheMark',
  'SpainPeseta',
  'FijiDollar',
  'FranceFranc',
  'UnitedKingdomPound',
  'GreeceDrachma',
  'HongKongDollar',
  'HungaryForint',
  'IndonesiaRupiah',
  'IrelandPunt',
  'IsraelNewShekel',
  'IndiaRupee',
  'ItalyLira',
  'JapanYen',
  'SouthKoreaWon',
  'MexicoPeso',
  'MalaysiaRinggit',
  'NetherlandsGuilder',
  'NewZealandDollar',
  'PolandZloty',
  'RussiaRuble',
  'SwedenKrona',
  'SingaporeDollar',
  'TaiwanDollar',
  'SouthAfricaRand',
  'LuxemborgFranc',
  'NorwegianKrone',
  'DanishKrone',
  'FinnishMark',
];

export const CurrencyCodes = [
  'USD',
  'EUR',
  'ARS',
  'AUD',
  'BEF',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DEM',
  'ESP',
  'FJD',
  'FRF',
  'GBP',
  'GRD',
  'HKD',
  'HUF',
  'IDR',
  'IEP',
  'ILS',
  'INR',
  'ITL',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NLG',
  'NZD',
  'PLN',
  'RUR',
  'SEK',
  'SGD',
  'TWD',
  'ZAR',
  'LUF',
  'NOK',
  'DKK',
  'FIM',
];

export const TargetJobTypes = [
  {
    value: 'FullTime',
    displayText: 'Full-Time',
  },
  {
    value: 'PartTime',
    displayText: 'Part-Time',
  },
  {
    value: 'PerDiem',
    displayText: 'Per Diem',
  },
  {
    value: 'Employee',
    displayText: 'Employee',
  },
  {
    value: 'Intern',
    displayText: 'Intern',
  },
  {
    value: 'TempOrContract',
    displayText: 'Temporary/Contract/Project',
  },
  {
    value: 'Seasonal',
    displayText: 'Seasonal',
  },
];

export const CareerLevels = [
  {
    value: 'HighSchool',
    displayText: 'Student (High School)',
  },
  {
    value: 'Undergraduate',
    displayText: 'Student (Undergraduate/Graduate)',
  },
  {
    value: 'EntryLevel',
    displayText: 'Entry Level',
  },
  {
    value: 'Experienced',
    displayText: 'Experienced (Non-Manager)',
  },
  {
    value: 'Manager',
    displayText: 'Manager (Manager/Supervisor of Staff)',
  },
  {
    value: 'Executive',
    displayText: 'Executive (SVP, VP, Department Head, etc.)',
  },
  {
    value: 'SeniorExecutive',
    displayText: 'Senior Executive (President, CFO, etc.)',
  },
];

export const LanguageProficiencyLevels = [
  {
    value: 'Basic',
    displayText: 'Beginner',
  },
  {
    value: 'Conversational',
    displayText: 'Intermediate',
  },
  {
    value: 'FluentWideKnowledge',
    displayText: 'Advanced',
  },
  {
    value: 'FluentFullKnowledge',
    displayText: 'Fluent',
  },
  {
    value: 'Unknown',
    displayText: 'Unknown',
  },
];

export const getFormattedCompanyCategories = companyCategories => {
  const formattedCompanyCategories = [];
  companyCategories.forEach(companyCategory => {
    const occupationsIds = companyCategory.TargetOccupations;
    if (occupationsIds.length === 0) {
      formattedCompanyCategories.push([companyCategory.TargetJobId]);
    } else {
      occupationsIds.forEach(occupationId => {
        formattedCompanyCategories.push([companyCategory.TargetJobId, occupationId]);
      });
    }
  });
  return formattedCompanyCategories;
};
