import React from 'react';
import { Empty } from 'antd';
import { EmptyCandidatesIcon } from '../../../Icons/AryaIcons';
import styles from './ManualSearchFormEmptyScreen.module.scss';

const ManualSearchEmptyTabScreen = () => (
  <div className={styles.manualSearchEmptyScreenWrapper}>
    <Empty
      image={<EmptyCandidatesIcon className={styles.manualSearchEmptyScreen} />}
      description={
        <div>
          <div className={styles.messageHeader}> No Search Performed</div>
          <div className={styles.messageInfo}>Please perform search with recommended search criteria</div>
        </div>
      }
    />
  </div>
);

export { ManualSearchEmptyTabScreen };
