import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import styles from './CandidateJobsHeader.module.scss';
import CandidateJobsFilter from '../CandidateJobsFilter/CandidateJobsFilter';

export default function CandidateJobsHeader(props) {
  const {
    jobs,
    candidateId,
    fetchCandidateJobs,
    onSearchChange,
    onSearch,
    searchTerm,
    jobAggregations,
    selectedCandidateStageFilterOptions,
    setSelectedCandidateStageFilterOptions,
    selectedJobStatusFilterOptions,
    setSelectedJobStatusFilterOptions,
    isCandidateViewHeaderVisible,
    setFilterPopoverVisbility,
    isFilterPopoverVisible,
    isATSViewActive,
  } = props;
  return (
    <div>
      <div
        className={classNames(styles.jobsTabHeader, {
          [styles.jobsHeaderForAtsView]: isATSViewActive,
        })}
      >
        <div className={styles.searchAndFilter}>
          <Input.Search
            size="default"
            placeholder="Search"
            allowClear
            className={styles.jobsInputSearch}
            onChange={onSearchChange}
            onSearch={onSearch}
            value={searchTerm}
          />
          <CandidateJobsFilter
            jobs={jobs}
            jobAggregations={jobAggregations}
            fetchCandidateJobs={fetchCandidateJobs}
            candidateId={candidateId}
            selectedCandidateStageFilterOptions={selectedCandidateStageFilterOptions}
            setSelectedCandidateStageFilterOptions={setSelectedCandidateStageFilterOptions}
            selectedJobStatusFilterOptions={selectedJobStatusFilterOptions}
            setSelectedJobStatusFilterOptions={setSelectedJobStatusFilterOptions}
            searchTerm={searchTerm}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            isFilterPopoverVisible={isFilterPopoverVisible}
            setFilterPopoverVisbility={setFilterPopoverVisbility}
          />
        </div>
      </div>
    </div>
  );
}
