import React from 'react';
import { Form, Select } from 'antd';
import LocationContext from '../LocationContext';
import styles from './Country.module.scss';

const FormItem = Form.Item;
const { Option } = Select;

const Country = props => {
  const { label, initialValue, isDisabled } = props;
  const { form, countryOptions, selectCountryCallback, countryChangeCallback, clearZipCodeError } =
    React.useContext(LocationContext);
  const onSelectCountry = value => {
    form.setFieldsValue({
      Location: undefined,
      Zipcode: undefined,
    });
    clearZipCodeError();
    if (selectCountryCallback) selectCountryCallback(value);
  };
  const onCountryChange = value => {
    if (!value) {
      form.setFieldsValue({
        Location: undefined,
        Zipcode: undefined,
      });
      clearZipCodeError();
    }
    if (countryChangeCallback) countryChangeCallback();
  };
  const countrySelect = (
    <Select
      showSearch
      allowClear
      key="Country"
      placeholder="Country"
      optionFilterProp="children"
      defaultActiveFirstOption={false}
      onSelect={onSelectCountry}
      onChange={onCountryChange}
      className={styles.countrySelect}
      dropdownClassName={styles.countryDropdown}
      disabled={isDisabled}
    >
      {countryOptions?.map(country => (
        <Option value={country.Iso2Code} key={country.Iso2Code}>
          {country.Name}
        </Option>
      ))}
    </Select>
  );
  return (
    <FormItem label={label} colon={false}>
      {form.getFieldDecorator('CountryCode', {
        initialValue,
        rules: [{ required: true, message: 'Please select country' }],
      })(countrySelect)}
    </FormItem>
  );
};

export default Country;
