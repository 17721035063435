import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { fetchJobIdUsingJobCode } from '../../Actions/JobActions';
import { setNavbarRenderStatus } from '../../Actions/ConfigActions';

function BullhornAtsRedirectUrl(props) {
  const { location, history } = props;
  const queryParams = queryString.parse(location.search);
  const { EntityID: jobCode } = queryParams;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let url;
    dispatch(setNavbarRenderStatus(false));
    (async function _featchJobId() {
      const response = await dispatch(fetchJobIdUsingJobCode({ jobCode }));
      const { jobId } = response;
      if (jobId === -1) {
        url = `/jobs/${jobCode}/notfound?ats=bullhorn`;
      } else {
        url = `/jobs/${jobId}`;
      }
      history.replace(url);
    })();
  }, []);

  return <LoadingScreen active loading />;
}

export default BullhornAtsRedirectUrl;
