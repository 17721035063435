import Config from '../Config/Config';

function getUserType() {
  const { location } = window;
  const { hostname } = location;
  return hostname === Config.urls.pulseHostName ? 'pulse' : 'non-pulse';
}

function isPulseUser() {
  return getUserType() === 'pulse';
}

const getCookieValue = name => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';

export { getUserType, isPulseUser, getCookieValue };
