import { Icon } from 'antd';
import * as React from 'react';

const ComposeEmailSvg = props => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5542 12.7182L7.22718 13.1197H11.3762L16.9974 7.6323L16.1274 2.54644L15.8598 1.27498L1.67288 1.07422L0.736013 1.81033L0.267578 9.84063L0.535255 11.7813L2.00748 12.7182H5.5542Z"
      fill="white"
    />
    <path
      d="M16.9987 7.70322C16.5731 7.2843 16.1448 6.8694 15.7272 6.4438C15.679 6.39561 15.6737 6.28988 15.6737 6.21092C15.6696 5.25531 15.671 4.29837 15.671 3.34276V3.12594C15.6 3.17279 15.5519 3.20089 15.5064 3.23569C13.9739 4.38001 12.4428 5.52566 10.909 6.66864C10.1421 7.24013 9.37121 7.80627 8.60699 8.38044C8.51465 8.45003 8.45843 8.4313 8.37813 8.37241C7.53629 7.74471 6.69043 7.12236 5.84859 6.49465C4.37369 5.39316 2.90146 4.289 1.4279 3.18617C1.40649 3.17011 1.3824 3.15539 1.33957 3.12728C1.33422 3.18617 1.32752 3.23167 1.32752 3.27718C1.32752 5.9138 1.32752 8.54907 1.32752 11.1857C1.32752 11.6207 1.5952 11.883 2.05427 11.8857C2.9724 11.891 3.89053 11.8883 4.81 11.887C5.75624 11.887 6.70247 11.887 7.64871 11.887C7.70894 11.887 7.76783 11.887 7.85215 11.887C7.81869 12.0463 7.79058 12.1841 7.7598 12.322C7.7076 12.5589 7.64871 12.7944 7.60187 13.0327C7.57778 13.1571 7.52156 13.2093 7.3904 13.2067C7.04778 13.2 6.70381 13.2067 6.36119 13.2067C4.95053 13.2067 3.53987 13.208 2.12922 13.2067C1.35429 13.2053 0.737296 12.8975 0.310352 12.2403C0.0493671 11.8375 0.00386207 11.3798 0.00252368 10.9194C-0.000153087 8.28274 -0.00282986 5.64746 0.00653883 3.01084C0.00653883 2.70569 0.0533823 2.39117 0.136362 2.09806C0.347827 1.34589 1.21911 0.71819 2.06899 0.778417C2.85462 0.834629 3.64828 0.785109 4.43927 0.786448C7.84278 0.786448 11.2476 0.789124 14.6511 0.790463C14.872 0.790463 15.0968 0.765033 15.3136 0.794478C16.1515 0.909579 16.8073 1.57074 16.9384 2.40322C16.9438 2.43667 16.9786 2.46478 17 2.49556V7.70188L16.9987 7.70322ZM2.10111 2.06996C2.17606 2.12885 2.20952 2.15561 2.24432 2.18238C3.69513 3.26112 5.14459 4.3412 6.5954 5.41726C7.18162 5.85223 7.77452 6.27784 8.35806 6.71415C8.46646 6.79579 8.54008 6.79445 8.64581 6.71013C8.81311 6.57496 8.9978 6.46119 9.17045 6.33271C11.0388 4.94481 12.9059 3.5569 14.7729 2.169C14.7997 2.14892 14.8198 2.11948 14.8653 2.0713H2.10111V2.06996Z"
      fill="black"
    />
    <path
      d="M8.43713 15.459C8.51877 15.0949 8.59372 14.7523 8.67135 14.4097C8.80652 13.8128 8.94705 13.2172 9.07955 12.6203C9.19599 12.0916 9.46501 11.6513 9.83842 11.2672C10.6214 10.4628 11.4016 9.65842 12.1833 8.85272C12.815 8.20226 13.4454 7.55181 14.0744 6.89868C14.14 6.83042 14.1788 6.82506 14.2471 6.89332C15.121 7.76996 15.9963 8.64527 16.873 9.51923C16.9452 9.5915 16.96 9.63166 16.877 9.71597C15.5359 11.0865 14.1895 12.4516 12.8672 13.8409C12.3238 14.4124 11.7014 14.7711 10.9198 14.9089C10.0954 15.0548 9.28165 15.2676 8.43579 15.4576L8.43713 15.459ZM14.1882 8.65597C12.9756 9.89933 11.7737 11.132 10.5893 12.3459C10.9091 12.6711 11.2196 12.987 11.5368 13.3095C11.5797 13.2694 11.6252 13.2292 11.6666 13.1864C12.0106 12.8344 12.3532 12.4824 12.6972 12.1318C13.4869 11.3287 14.2778 10.5257 15.0715 9.724C15.1277 9.66645 15.1518 9.62229 15.0889 9.55938C14.7931 9.26226 14.4973 8.96648 14.1882 8.65597Z"
      fill="black"
    />
    <path
      d="M14.1882 8.65625C14.4974 8.96676 14.7932 9.26388 15.0889 9.55966C15.1518 9.62256 15.1278 9.66673 15.0715 9.72428C14.2792 10.5246 13.4882 11.3277 12.6972 12.132C12.3519 12.4827 12.0107 12.836 11.6667 13.1867C11.6252 13.2295 11.5797 13.2697 11.5369 13.3098C11.2197 12.9873 10.9092 12.6701 10.5893 12.3462C11.7738 11.1323 12.9756 9.89961 14.1882 8.65625Z"
      fill="white"
    />
  </svg>
);

const ComposeEmailIcon = props => <Icon component={() => ComposeEmailSvg(props)} {...props} />;
export default ComposeEmailIcon;
