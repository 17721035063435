/* eslint-disable prefer-destructuring */
import React from 'react';
import styles from './CompanyTable.module.scss';
import {
  getMinAndMaxScoreLocationPopularity,
  updateScoreLocationPopularity,
} from '../../Utils/ScoreDetails/CompanyTableUtils';

const CompanyTable = ({ tableData }) => {
  const ref = React.useRef(null);
  const [dataSource, setDataSource] = React.useState([]);
  const contenderCompanyName = tableData.ContenderCompany['Company Name'];
  const prescribedCompanyName = tableData.PrescribedCompany['Company Name'];

  React.useEffect(() => {
    const attributes = Object.keys(tableData.ContenderCompany).filter(tableItem => tableItem !== 'Company Name');
    const tempTableData = attributes.map((attribute, index) => {
      const tempObj = { key: index };
      tempObj.contender = tableData.ContenderCompany[`${attribute}`];
      tempObj.prescribed = tableData.PrescribedCompany[`${attribute}`];
      tempObj.attribute = attribute;
      return tempObj;
    });
    const newTableData = [];
    const mapping = {
      'Total Locations': 'Operating Locations',
      'Employees Average Tenure': 'Employee Tenure',
      'Arya Popularity': 'Arya Popularity',
      'Company Size': 'Company Size',
      'Annual Revenue': 'Annual Revenue',
    };
    for (const item of tempTableData) {
      if (item.attribute in mapping && item.attribute !== 'Industries' && item.attribute !== 'Top Roles') {
        const contenderValue = item.contender;
        const prescribedValue = item.prescribed;
        if (contenderValue != null && prescribedValue !== null) {
          const { minScore } = getMinAndMaxScoreLocationPopularity(prescribedValue, mapping[item.attribute]);
          const contenderScale = updateScoreLocationPopularity(contenderValue, mapping[item.attribute]);
          newTableData.push({ ...item, attribute: mapping[item.attribute], prescribedScale: minScore, contenderScale });
        }
      }
    }
    setDataSource(newTableData);
  }, []);

  React.useEffect(() => {
    const companyContainer = ref.current;
    if (companyContainer) {
      const tableBody = document.querySelector('.ant-table-tbody');
      const tableRows = tableBody?.childNodes;
      if (tableRows && tableRows.length > 0) {
        tableRows.forEach((tableRow, index) => {
          const tdNodes = tableRow.childNodes;
          tdNodes[1].style.color = '#252525';
          tdNodes[2].style.color = '#252525';
          if (index % 2 !== 0) {
            const list = tableRow.classList;
            list.add(styles.greyColumn);
          }
        });
      }
    }
  }, [dataSource]);

  return (
    <>
      {dataSource.length > 0 && (
        <div className={styles.companyTableContainer} ref={ref}>
          <div className={styles.companyNames}>
            <h3>{contenderCompanyName}</h3>
            <h3>VS</h3>
            <h3>{prescribedCompanyName}</h3>
          </div>
          {dataSource.map(data => (
            <TableInfoGraphics
              key={data.key}
              attribute={data.attribute}
              contenderCompanyText={data.contender}
              prescribedCompanyText={data.prescribed}
              contenderCompanyTextWidth={data.contenderScale}
              prescribedCompanyTextWidth={data.prescribedScale}
            />
          ))}
        </div>
      )}
    </>
  );
};

const TableInfoGraphics = ({
  contenderCompanyText,
  attribute,
  prescribedCompanyText,
  contenderCompanyTextWidth,
  prescribedCompanyTextWidth,
}) => {
  const comapnyInfoRef = React.useRef(null);
  const blueBoxWidth = contenderCompanyTextWidth;
  const greenBoxWidth = prescribedCompanyTextWidth;

  return (
    <div className={styles.companyInfoContainer}>
      <div className={styles.blueBoxWrapper}>
        <div ref={comapnyInfoRef} className={styles.blueBox} style={{ width: `${blueBoxWidth}%` }}>
          {attribute === 'Industries' && <p>{contenderCompanyText}</p>}
        </div>
      </div>
      <div className={styles.centerBox}>{attribute}</div>
      <div className={styles.greenBoxWrapper}>
        <div className={styles.greenBox} style={{ width: `${greenBoxWidth}%` }}>
          {attribute === 'Industries' && <p>{prescribedCompanyText}</p>}
        </div>
      </div>
    </div>
  );
};

export default CompanyTable;
