import { defineMessages } from 'react-intl';

const messages = defineMessages({
  textConsentMessage: {
    id: 'Templates.Text.consentText',
    defaultMessage:
      'Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} job opportunities.',
  },
});

export default messages;
