import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import JobPublishComponent from '../../Components/JobPublish/JobPublishComponent';
import { AdvertisementPortals, JobBoardPortals } from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import {
  distributeJob,
  fetchAvailableMonsterJobInventories,
  fetchJobPublishedPortals,
  fetchJobPortalPublishedInfo,
  recallJob,
  resetJobDistribution,
  fetchPortalTariff,
  fetchAvailableJobAdCredits,
} from '../../Actions/JobDistributionActions';
import {
  fetchZipCodes,
  resetZipcodes,
  searchZipCode,
  clearZipCodeError,
  clearZipValidationError,
} from '../../Actions/JobActions';
import { fetchLocations, clearLocations } from '../../Actions/LocationSearchActions';
import { getJobsById, getJobUtilities } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getAvailableCredits } from '../../Reducers/AryaPayReducer';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import {
  getJobDistributedInfo,
  getJobDistributedPortals,
  getJobDraftedPortals,
  getMonsterJobInventories,
  getPortalTariffInfo,
} from '../../Reducers/JobDistributionReducer';
import { getLocationSearchReducerUtilities } from '../../Reducers/LocationSearchReducer';

export default function JobPublishContainer(props) {
  const { goBack, enabledPortals, portal, jobId, AppName } = props;
  const dispatch = useDispatch();

  const jobsById = useSelector(state => getJobsById(state));
  const utilities = useSelector(state => getLocationSearchReducerUtilities(state));
  const jobUtilities = useSelector(state => getJobUtilities(state));
  const distributedJobInfo = useSelector(state => getJobDistributedInfo(state, portal));
  const distributedPortals = useSelector(state => getJobDistributedPortals(state));
  const draftedPortals = useSelector(state => getJobDraftedPortals(state));
  const monsterInventories = useSelector(state => getMonsterJobInventories(state));
  const locationApiStatus = useSelector(state => getApiStatus(state, 'locationApiStatus'));
  const distributeJobApiStatus = useSelector(state => getApiStatus(state, 'distributeJobStatus'));
  const fetchAvailableJobAdCreditsStatus = useSelector(state =>
    getApiStatus(state, 'fetchAvailableJobAdCreditsStatus')
  );
  const distributeJobPortalApiStatus = useSelector(state => getApiStatus(state, 'distributeJobPortalStatus'));
  const distributeJobPortalInfoApiStatus = useSelector(state => getApiStatus(state, 'distributeJobPortalInfoStatus'));
  const monsterJobInventoriesApiStatus = useSelector(state => getApiStatus(state, 'monsterJobInventoriesStatus'));
  const availableAdCredits = useSelector(state => getAvailableCredits(state));
  const portalTariff = useSelector(state => getPortalTariffInfo(state, portal));
  const productVariantsById = useSelector(state => getProductVariantsById(state));

  React.useEffect(() => {
    dispatch(resetJobDistribution());
    dispatch(clearLocations({ location: true }));
    dispatch(resetZipcodes());
    if (AdvertisementPortals.includes(portal.toLowerCase())) {
      dispatch(fetchAvailableJobAdCredits());
      dispatch(fetchPortalTariff(portal));
    }
  }, []);

  const handleSubmit = (values, publishPortal, successMessage = '') => {
    dispatch(distributeJob(jobId, publishPortal, values, goBack, successMessage));
  };

  const fetchMonsterJobInventories = () => {
    dispatch(fetchAvailableMonsterJobInventories(jobId));
  };

  const fetchDistributedPortals = () => {
    dispatch(fetchJobPublishedPortals({ jobId }));
  };

  const fetchDistributedJobPortalInfo = portalName => {
    dispatch(fetchJobPortalPublishedInfo(jobId, portalName));
  };

  const recallPortalJobDistribution = portalName => {
    dispatch(recallJob(jobId, portalName));
  };

  const currJobDetails = _.get(jobsById, jobId, {});
  const distributionPortals =
    enabledPortals
      ?.filter(
        portalConfig => portalConfig.IsEnabled && JobBoardPortals.includes(portalConfig.Portal?.Name.toLowerCase())
      )
      .map(portalConfig => portalConfig?.Portal?.Name) ?? [];
  const { locations } = utilities;

  return (
    <JobPublishComponent
      portal={portal}
      locationOptions={locations}
      utilities={jobUtilities}
      jobDetails={currJobDetails}
      distributionPortals={distributionPortals}
      distributedJobInfo={distributedJobInfo}
      distributedPortals={distributedPortals}
      draftedPortals={draftedPortals}
      monsterInventories={monsterInventories}
      fetchZipCodes={filter => dispatch(fetchZipCodes(filter))}
      fetchLocations={filter => dispatch(fetchLocations(filter))}
      fetchMonsterJobInventories={fetchMonsterJobInventories}
      fetchDistributedPortals={fetchDistributedPortals}
      fetchDistributedJobPortalInfo={fetchDistributedJobPortalInfo}
      resetZipCodes={() => dispatch(resetZipcodes)}
      searchZipCode={filter => dispatch(searchZipCode(filter))}
      clearZipCodeError={() => dispatch(clearZipCodeError())}
      clearZipValidationError={() => dispatch(clearZipValidationError)}
      recallPortalJobDistribution={recallPortalJobDistribution}
      fetchAvailableJobAdCreditsStatus={fetchAvailableJobAdCreditsStatus}
      locationApiStatus={locationApiStatus}
      distributeJobApiStatus={distributeJobApiStatus}
      distributeJobPortalApiStatus={distributeJobPortalApiStatus}
      distributeJobPortalInfoApiStatus={distributeJobPortalInfoApiStatus}
      monsterJobInventoriesApiStatus={monsterJobInventoriesApiStatus}
      onSubmit={handleSubmit}
      onCancel={goBack}
      availableAdCredits={availableAdCredits ?? 0}
      portalTariff={portalTariff}
      productVariant={productVariantsById?.[portalTariff?.ProductVariantId]}
      productVariantId={portalTariff?.ProductVariantId}
      AppName={AppName}
    />
  );
}
