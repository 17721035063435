import React from 'react';

const paymentData = {
  paymentProcessingContent: {
    header: 'Processing Payment',
    orderStatus: 'Payment Processing',
  },
  paymentSuccessContent: {
    header: 'Payment Successful',
    orderStatus: 'Payment Success',
  },
  paymentFailedContent: {
    header: 'Transaction Failed!',
    orderStatus: 'Payment Failed',
    footer: (
      <>
        <h3>Problem has occured!</h3>
        <p>Proceed your pending transaction again with relevant details</p>
      </>
    ),
    closable: true,
  },
  orderPendingContent: {
    header: 'Confirming your order ...',
    orderStatus: 'Order Pending',
    footer: (
      <p>
        We will be sending an email shortly along with the invoice. Please add our email ID to your email Address Book
        to ensure our updates are always delivered to your inbox.
      </p>
    ),
  },
  orderSuccessContent: {
    header: 'Order Confirmed!',
    orderStatus: 'Order Success',
    orderMessage: 'Your payment was successful!',
    orderNumber: '',
    footer: (
      <p>
        We will be sending an email shortly along with the invoice. Please add our email ID to your email Address Book
        to ensure our updates are always delivered to your inbox.
      </p>
    ),
    linkText: 'Go to job list',
    link: '/jobs',
  },
  orderFailedContent: {
    header: 'Failed to Place Order',
    orderStatus: 'Order Failed',
    closable: true,
  },
};
export default paymentData;
