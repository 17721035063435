import React from 'react';
import { Icon } from 'antd';

function TilesSvg(props) {
  const { fillColor } = props;
  return (
    <svg width={13} height={13} {...props}>
      <g fill={fillColor} fillRule="evenodd">
        <path d="M1.3 1.3v3.25h3.248L4.55 1.3H1.3Zm3.25 4.55H1.3c-.717 0-1.3-.583-1.3-1.3V1.3C0 .584.583 0 1.3 0h3.25c.717 0 1.3.584 1.3 1.3v3.25c0 .717-.583 1.3-1.3 1.3ZM8.45 1.3v3.25h3.248L11.7 1.3H8.45Zm3.25 4.55H8.45c-.717 0-1.3-.583-1.3-1.3V1.3c0-.716.583-1.3 1.3-1.3h3.25c.717 0 1.3.584 1.3 1.3v3.25c0 .717-.583 1.3-1.3 1.3ZM1.3 8.45v3.25h3.248l.002-3.25H1.3ZM4.55 13H1.3C.583 13 0 12.417 0 11.7V8.45c0-.716.583-1.3 1.3-1.3h3.25c.717 0 1.3.584 1.3 1.3v3.25c0 .717-.583 1.3-1.3 1.3ZM8.45 8.45v3.25h3.248l.002-3.25H8.45ZM11.7 13H8.45c-.717 0-1.3-.583-1.3-1.3V8.45c0-.716.583-1.3 1.3-1.3h3.25c.717 0 1.3.584 1.3 1.3v3.25c0 .717-.583 1.3-1.3 1.3Z" />
      </g>
    </svg>
  );
}

const TilesIcon = props => <Icon component={() => TilesSvg(props)} {...props} />;

export default TilesIcon;
