import React from 'react';

export default function DonutInnerRadiusText(props) {
  const { InnerRadiusInfo } = props;
  const { position, fontSize, topText, bottomText } = InnerRadiusInfo;
  return (
    <g>
      <text textAnchor="middle" fontSize={`${fontSize}px`} x="0" y="0">
        <tspan x="0" y={`-${position}`}>
          {topText}
        </tspan>
        <tspan x="0" y={`${position}`}>
          {bottomText}
        </tspan>
      </text>
    </g>
  );
}
