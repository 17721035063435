import React from 'react';
import Config from '../../Config/Config';
import './Auth.scss';

export default function AppLogo() {
  const client = localStorage.getItem('Client');
  let logo = (
    <div className="arya-logo">
      <img
        src={`${process.env.PUBLIC_URL}/static/Images/arya-logo-green.svg`}
        alt="arya logo"
        style={{ height: '50px', width: '50px' }}
      />
      <img
        src={`${process.env.PUBLIC_URL}/static/Images/arya-text-dark.svg`}
        alt="arya logo"
        style={{ height: '42px', width: '100px' }}
      />
    </div>
  );

  if (client) {
    logo = (
      <div className="arya-logo-white-label">
        <img src={`${Config.urls.getWhiteLabelResourceUrl(client)}/logo.png`} alt="whitelabel logo" />
      </div>
    );
  }

  return logo;
}
