import { extent } from 'd3';
import _ from 'lodash';

function mergeLists(listData, path) {
  let mergedList = [];

  listData.forEach(element => {
    const newData = _.get(element, path, []);
    mergedList = [...mergedList, ...newData];
  });

  return mergedList;
}

function fetchMinMaxInList(list) {
  return extent(list);
}

function listToString(list, seperator) {
  return list.join(seperator);
}

function listToStringCustomDefault(list, seperator, defaultValue) {
  const stringValue = list?.join(seperator);
  return stringValue || defaultValue;
}

function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export { mergeLists, fetchMinMaxInList, listToString, listToStringCustomDefault, arraysAreEqual };
