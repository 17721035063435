import React from 'react';
import { Form, InputNumber, Select } from 'antd';
import styles from './DistanceForm.module.scss';

const { Item } = Form;
const { Option } = Select;

export default function DistanceForm(props) {
  const {
    form,
    distanceFieldDecorator,
    distanceInitialValue,
    distanceUnitFieldDecorator,
    distanceUnitInitialValue,
    isDisabled,
  } = props;

  return (
    <div className={styles.distanceForm}>
      <Item colon={false}>
        {form.getFieldDecorator(distanceFieldDecorator, {
          initialValue: distanceInitialValue ?? 50,
        })(
          <InputNumber
            placeholder="Enter Distance"
            min={0}
            max={1000}
            label="Distance"
            className={styles.input}
            disabled={isDisabled}
          />
        )}
      </Item>
      <Item colon={false} className={styles.minExp}>
        {form.getFieldDecorator(distanceUnitFieldDecorator, {
          initialValue: distanceUnitInitialValue ?? 'Miles',
        })(
          <Select
            placeholder="Select UOM"
            getPopupContainer={trigger => trigger.parentNode}
            showArrow
            className={styles.select}
            dropdownClassName={styles.selectDropdown}
            disabled={isDisabled}
          >
            <Option value="Miles">Miles (mi)</Option>
            <Option value="Kilometers">Kilometers (km)</Option>
          </Select>
        )}
      </Item>
    </div>
  );
}
