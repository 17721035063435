import React from 'react';
import _ from 'lodash';
import { Empty, Skeleton, Popover, Checkbox, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './CareerportalConversationsContainerStyles.module.scss';
import ChatWindowContainer from '../ChatWindow/ChatWindow';
import CareerportalCandidateListItem from '../../Components/Connect/CareerportalCandidateListItem/CareerportalCandidateListItem';
import { EmptyConnectIcon } from '../../Icons/AryaIcons';
import { getRelativeTime } from '../../Utils/RelativeTimeCalculator';
import FilterIconWithTooltip from '../../Components/Common/FilterIconWithTooltip/FilterIconWithTooltip';

const CareerportalConversationsContainer = props => {
  const {
    featureToggleList,
    searchConversations,
    orglevelConversationsById,
    orgLevelPersonDetailsById,
    orgLevelConversationsFetchApiStatus,
    currentUserDetails,
    orglevelConversationsCount,
    setOrgConversationFilterValues,
    setOrgConversationFilterStatus,
    orgConversationsFilterValues,
    isOrgConversationsFilterApplied,
  } = props;

  React.useEffect(() => {
    if (currentUserDetails?.OrgGuid) {
      searchConversations({ RefId: currentUserDetails.OrgGuid, BotIneractionStatuses: orgConversationsFilterValues });
    }
  }, []);

  const [selectedConversation, setSelectedConversation] = React.useState({});
  const [filterPopoverVisbility, setFilterPopoverVisbility] = React.useState(false);

  const getConversationTitle = conversation => {
    const personId = conversation?.PersonId;
    const personDetails = personId ? orgLevelPersonDetailsById[personId] : null;
    return personDetails?.Name || `Conversation ${getRelativeTime(_.get(conversation, 'CreatedTime'))}`;
  };

  const selectedChatWindow = selectedConversation?.Id ? (
    <>
      <div className={styles.chatHeader}>{getConversationTitle(selectedConversation)}</div>
      <div className={styles.chatSection}>
        <ChatWindowContainer
          conversationId={selectedConversation.Id}
          personId={selectedConversation?.PersonId}
          featureToggleList={featureToggleList}
          candidate={selectedConversation}
          isOrgLevelCandidate
        />
      </div>
    </>
  ) : (
    <Empty
      description={
        <span>
          <b>No Candidate Conversations found</b>
        </span>
      }
      style={{ marginTop: 100 }}
      image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
    />
  );

  const searchMoreConversations = page => {
    const filter = {
      From: (page - 1) * 10,
      Size: 10,
      BotIneractionStatuses: orgConversationsFilterValues,
      RefId: currentUserDetails.OrgGuid,
    };
    searchConversations(filter, true);
  };

  const onChangeFilter = values => {
    setOrgConversationFilterValues(values);
  };

  const onClearFilter = () => {
    setFilterPopoverVisbility(false);
    setOrgConversationFilterStatus(false);
    setOrgConversationFilterValues([]);
    searchConversations({ RefId: currentUserDetails.OrgGuid });
    setSelectedConversation({});
  };

  const onApplyFilter = () => {
    setFilterPopoverVisbility(false);
    setOrgConversationFilterStatus(true);
    setOrgConversationFilterValues(orgConversationsFilterValues);
    setSelectedConversation({});
    searchConversations({ RefId: currentUserDetails.OrgGuid, BotIneractionStatuses: orgConversationsFilterValues });
  };

  const conversationStatsFilterTitle = (
    <div className={styles.careerportalFilterHeader}>
      <div className={styles.filterTitle}>Filters</div>
      <div className={styles.filterOptions}>
        <Button onClick={onClearFilter}>Clear</Button>
        <Button type="primary" onClick={onApplyFilter}>
          Apply
        </Button>
      </div>
    </div>
  );

  const conversationStatsFilterContent = (
    <Checkbox.Group
      className={styles.filterCheckboxGroup}
      options={['Recruiter Takeover', 'Applied']}
      onChange={onChangeFilter}
      value={orgConversationsFilterValues}
    />
  );

  const handlePopoverVisibility = () => {
    setFilterPopoverVisbility(!filterPopoverVisbility);
  };

  return (
    <div className={styles.careerPortalContainer}>
      <div className={styles.candidateListMenu}>
        <div className={styles.candidateListHeader}>
          Candidates({orglevelConversationsCount})
          <div className={styles.careerPortalFilterDisplay}>
            <Popover
              placement="bottom"
              className="email-stats-filter"
              title={conversationStatsFilterTitle}
              content={conversationStatsFilterContent}
              trigger="click"
              visible={filterPopoverVisbility}
              onVisibleChange={handlePopoverVisibility}
            >
              <FilterIconWithTooltip isFilterApplied={isOrgConversationsFilterApplied} style={{ fontSize: 16 }} />
            </Popover>
          </div>
        </div>
        <Skeleton loading={orgLevelConversationsFetchApiStatus === 'InProgress'}>
          <div className={styles.candidateListContainer}>
            <InfiniteScroll
              useWindow={false}
              pageStart={1}
              key={1}
              initialLoad={false}
              loadMore={searchMoreConversations}
              hasMore={Object.values(orglevelConversationsById).length < orglevelConversationsCount}
              threshold={100}
            >
              {Object.values(orglevelConversationsById).map(conversation => {
                return (
                  <CareerportalCandidateListItem
                    key={conversation.Id}
                    title={getConversationTitle(conversation)}
                    onClick={() => setSelectedConversation(conversation)}
                    isActive={selectedConversation.Id === conversation.Id}
                    isoDateTime={_.get(conversation, 'RecentConversationTime')}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </Skeleton>
      </div>
      <div className={styles.chatInterface}>{selectedChatWindow}</div>
    </div>
  );
};

export default CareerportalConversationsContainer;
