import PropTypes from 'prop-types';

function NotificationReducer(state = {}, action) {
  let currentNotifications;

  switch (action.type) {
    case 'ADD_NOTIFICATIONS':
      currentNotifications = state.notifications || [];
      return {
        ...state,
        notifications: [...currentNotifications, ...action.payload],
      };
    case 'SET_NOTIFICATIONS_COUNT':
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
}

NotificationReducer.PropTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getNotifications(state) {
  return state.NotificationReducer.notifications;
}

function getNotificationsCount(state) {
  return state.NotificationReducer.count;
}

export { NotificationReducer, getNotifications, getNotificationsCount };
