import React from 'react';
import { Button } from 'antd';
import styles from './BrandMessageDisplayCard.module.scss';

export default function BrandMessageDisplayCard(props) {
  const {
    messageTitle,
    messageContent,
    onConfirmClick,
    onGenerateLink,
    confirmButtonContent,
    generateLinkButtonContent,
    isGenerateLinkButtonDisabled,
    isLoading,
  } = props;
  return (
    <div className={styles.displayCardContainer}>
      <div className={styles.displayCardContent}>
        <div className={styles.logoContainer}>
          <img src={`${process.env.PUBLIC_URL}/static/Images/aryalogo-dark.svg`} alt="arya logo" />
        </div>
        <div className={styles.messageContainer}>
          <div className={styles.messageTitle}>{messageTitle}</div>
          <div className={styles.messageContent}>{messageContent}</div>
        </div>
      </div>
      <div className={styles.displayCardFooter}>
        <Button type="default" shape="round" onClick={onConfirmClick}>
          {confirmButtonContent}
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={onGenerateLink}
          disabled={isGenerateLinkButtonDisabled}
          loading={isLoading}
        >
          {generateLinkButtonContent}
        </Button>
      </div>
    </div>
  );
}
