import React from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import { OptedInIcon, OptedOutIcon } from '../../../Icons/AryaIcons';
import './CandidateOptNotification.scss';

function getIcon(consentStatus) {
  if (consentStatus === 'opted-in') {
    return (
      <OptedInIcon
        style={{ fontSize: 20, padding: 10, color: '#13C26B', backgroundColor: '#DCF6E9', borderRadius: '50%' }}
      />
    );
  }
  return (
    <OptedOutIcon
      style={{
        fontSize: 20,
        padding: 10,
        color: '#F5222D',
        backgroundColor: 'rgba(245,34,45,0.15)',
        borderRadius: '50%',
      }}
    />
  );
}

function CandidateOptNotification(props) {
  const { notification, onClick, jobDetails } = props;
  const { UnreadCount, Id, Type, UpdatedTime } = notification;
  let consentStatus;
  const { ConversationId, PersonId, PersonName, PersonNumber, ConsentStatus } = notification.Payload;
  if (ConsentStatus === 0 || ConsentStatus === 'Approved') {
    consentStatus = 'opted-in';
  } else if (ConsentStatus === 0 || ConsentStatus === 'Denied') {
    consentStatus = 'opted-out';
  }
  return (
    <div
      className="connect-notification"
      onClick={() => onClick(ConversationId, PersonId, Id, Type, PersonNumber, jobDetails.JobId)}
      role="presentation"
    >
      <div className="icon">{getIcon(consentStatus)}</div>
      <div className="content">
        <span className="sender-name">{PersonName} </span> has <span className="consent-type "> {consentStatus} </span>
        <Badge dot={UnreadCount > 0}>
          <div>
            {jobDetails.JobTitle} {jobDetails.JobId}
          </div>
        </Badge>
        <div className="since">
          {moment
            .utc(UpdatedTime)
            .local()
            .fromNow()}
        </div>
      </div>
    </div>
  );
}

export default CandidateOptNotification;
