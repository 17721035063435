export const SET_DILATED_INTEL = 'SET_DILATED_INTEL';
export const SET_DILATED_CANDIDATE_COUNT = 'SET_DILATED_CANDIDATE_COUNT';
export const SET_ALL_DILATED_CANDIDATE_COUNT = 'SET_ALL_DILATED_CANDIDATE_COUNT';

export const setDilatedIntel = (jobId, dilatedIntel, statuses) => {
  return {
    type: SET_DILATED_INTEL,
    payload: {
      jobId,
      dilatedIntel,
      statuses,
    },
  };
};

export const setDilatedCandidateCount = (jobId, count) => {
  return {
    type: SET_DILATED_CANDIDATE_COUNT,
    payload: {
      jobId,
      Count: count,
    },
  };
};

export const setAllDilatedCandidateCount = (jobId, count) => {
  return {
    type: SET_ALL_DILATED_CANDIDATE_COUNT,
    payload: {
      jobId,
      Count: count,
    },
  };
};
