import React from 'react';
import { Button } from 'antd';
import './MagicLinkSuccessComponent.scss';

export function getMagicLinkSuccessContent(type) {
  let title = null;
  const description = (
    <>
      An email is on its way with your exclusive link to log in to Arya Pulse. Please check your spam/junk email in case
      you haven&apos;t received our email.{' '}
      <span>
        If you are facing issues feel free to reach out at
        <a href="mailto:support@leoforce.com"> support@leoforce.com</a>
      </span>
    </>
  );
  if (type.toLowerCase() === 'signup') {
    title = `Thank you!`;
  }
  return { title, description };
}

function MagicLinkSuccessComponent({ handleBack, resendLinkProps, type = 'default' }) {
  const content = getMagicLinkSuccessContent(type);
  const { handleResendLink, isResendLinkButtonDisabled, isLoading } = resendLinkProps;
  return (
    <div className="magiclink-component" id="signUpSuccess">
      <div className="pulse-auth-logo">
        <img src={`${process.env.PUBLIC_URL}/static/Images/magiclink-email-sent-Icon.png`} alt="email link sent logo" />
      </div>
      <div className="magiclink-email-sent-title">{content.title} </div>
      <div className="magiclink-email-sent-content">{content.description} </div>
      <div className="magiclink-component-footer">
        <Button type="default" shape="round" onClick={handleBack}>
          Back
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={handleResendLink}
          disabled={isResendLinkButtonDisabled}
          loading={isLoading}
        >
          Resend Link
        </Button>
      </div>
    </div>
  );
}

export default MagicLinkSuccessComponent;
