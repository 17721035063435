import { smartJobV1Client } from './BaseRepository';

export function fetchSkills(title) {
  return smartJobV1Client.post('Jobs/title/skills/_search', { title });
}

export function fetchTitle(filter) {
  return smartJobV1Client.post('Jobs/title/_search', {
    From: filter.from || 0,
    Size: filter.size || 100,
    Title: filter.title,
  });
}
