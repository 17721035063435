import React from 'react';
import {
  prepareDataForRoleSimilarityScatterPlot,
  getXAxisDomain,
  getYAxisDomain,
} from '../../../Utils/CandidateIntelChartUtils';
import renderScatterPlot from '../ScatterPlot';
import './RoleSimilarityScatterPlot.scss';

function onMouseOver(tooltipContent) {
  return (tooltip, offsetX, offsetY) => {
    tooltip
      .style('opacity', 1)
      .html(tooltipContent)
      .style('top', `${offsetY - 20}px`)
      .style('left', `${offsetX + 30}px`);
  };
}

class RoleSimilarityScatterPlot extends React.Component {
  constructor(props) {
    super(props);
    this.createScatterPlot = this.createScatterPlot.bind(this);
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  componentDidUpdate() {
    this.createScatterPlot();
  }

  createScatterPlot() {
    const { RoleRelevance, candidateName } = this.props;

    const { CandidateRole, JobRole } = RoleRelevance;

    const tooltipContent = `
    <div class="tooltip-content">
      <p class="tooltip-heading">
        Roles
      </p>
      <div class="role-tooltip-details">
        <div class="tooltip-candidate-role-color"></div>
        <div class="tooltip-candidate-role">${CandidateRole.Role} (${candidateName}'s)</div>
      </div>
      <div class="role-tooltip-details">
        <div class="tooltip-job-role-color"></div>
        <div class="tooltip-job-role">${JobRole.Role} (Job Role)</div>
      </div>
    </div>
    `;

    const data = prepareDataForRoleSimilarityScatterPlot(RoleRelevance);

    const config = {
      parentId: '#roleSimilarityScatterPlot',
      tooltipClass: 'role-similarity-tooltip',
      onMouseOver: onMouseOver(tooltipContent),
      xAxisDomain: getXAxisDomain(data),
      yAxisDomain: getYAxisDomain(data),
      idPrefix: 'role',
    };
    renderScatterPlot(config, data);
  }

  render() {
    return <div id="roleSimilarityScatterPlot"></div>;
  }
}

export default RoleSimilarityScatterPlot;
