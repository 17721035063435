import React from 'react';
import moment from 'moment';

import {
  WorkflowActionsSvgIcon,
  WorkflowDelayActionSvgIcon,
  WorkflowRuleActionSvgIcon,
  WorkflowEndActionSvgIcon,
} from '../Icons/AryaIcons';

const actionTypes = {
  Start: 'Start',
  SendEmail: 'SendEmail',
  SendSMS: 'SendSMS',
  AddTag: 'AddTag',
  AddCustomField: 'AddCustomField',
  Delay: 'Delay',
  Decision: 'Decision',
  End: 'End',
};

const actionSubTypesKeyValuePairs = [
  { key: actionTypes.SendEmail, value: 'Send Email' },
  // { key: actionTypes.SendSMS, value: 'Send SMS' },
  // { key: actionTypes.AddTag, value: 'Add Tag' },
  // { key: actionTypes.AddCustomField, value: 'Add Custom Field' },
];

const nodeTypesKeyValuePairs = [
  { key: actionTypes.Delay, value: 'Delay', label: 'Wait time' },
  { key: actionTypes.Decision, value: 'Rule', label: 'If-Then criteria' },
  { key: actionTypes.End, value: 'End', label: 'End flow' },
];

// eslint-disable-next-line no-unused-vars
const getWorkflowNodeModalTitle = type => {
  switch (type) {
    case 'SendEmail':
      return (
        <span style={{ color: '#722ed1' }}>
          <WorkflowActionsSvgIcon /> Action - Send Email
        </span>
      );

    case 'SendSMS':
      return <span style={{ color: '#722ed1' }}>Action - Send SMS</span>;

    case 'AddTag':
      return <span style={{ color: '#722ed1' }}>Action - Add Tag</span>;

    case 'AddCustomField':
      return <span style={{ color: '#722ed1' }}>Action - Add Custom Field</span>;

    case 'Delay':
      return (
        <span style={{ color: '#FAAD14' }}>
          <WorkflowDelayActionSvgIcon />
          &nbsp;{type}
        </span>
      );

    case 'Decision':
      return (
        <span style={{ color: '#007bff' }}>
          <WorkflowRuleActionSvgIcon />
          &nbsp;&nbsp;Rule
        </span>
      );

    case 'End':
      return (
        <span style={{ color: '#f5222d' }}>
          <WorkflowEndActionSvgIcon />
          &nbsp;
          {type}
        </span>
      );

    default:
      return null;
  }
};

export const getIsValidWorkflowConfiguration = (startTime, endTime, definitionId) => {
  const dateFrom = startTime?.split('/')?.join('-');
  const dateTo = endTime?.split('/')?.join('-');
  if (!definitionId) return false;
  if (!dateFrom) return false;
  if (!endTime) return true;
  return !moment(dateTo).isSameOrBefore(dateFrom, 'day');
};

export { actionSubTypesKeyValuePairs, nodeTypesKeyValuePairs, actionTypes, getWorkflowNodeModalTitle };
