const initialState = {
  mergeTags: [],
};

export default function MergeTagsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_MERGE_TAGS':
      return {
        ...state,
        mergeTags: action.payload,
      };

    default:
      return state;
  }
}

function getMergeTags(state) {
  return state.MergeTagsReducer.mergeTags;
}

export { getMergeTags };
