import React from 'react';
import { Tooltip } from 'antd';
import './BarChart.scss';

export function prepareBarChatData(list) {
  const updatedData = { Names: [], Values: [] };
  if (list)
    list.forEach(obj => {
      updatedData.Names.push(obj.Name);
      updatedData.Values.push(obj.Count);
    });
  return updatedData;
}

export function calculateTranslation(gapBetweenBars, barWidth, index) {
  return gapBetweenBars + index * (barWidth + gapBetweenBars);
}

export default function BarChart(props) {
  const {
    width,
    height,
    list,
    colors,
    rotation,
    barWidth,
    gapBetweenBars,
    margin,
    isBarValueVisible,
    barLabelKey,
    tooltipCallback,
    xLabelPadding,
    labelTextAnchor,
  } = props;

  const { Names, Values } = prepareBarChatData(list);
  let maxValue = Math.max(...Values);

  maxValue += maxValue === 0 ? 1 : 0.1 * maxValue;

  const innerWidth = width - margin;
  const innerHeight = height - margin;
  return (
    <div id="bar-chart-info" className="bubble">
      <svg className="bubble" id="bar-chart-bubble" width={width} height={height}>
        {Values?.map((dataPoint, index) => (
          <Tooltip
            key={`key${index.toString()}`}
            title={tooltipCallback ? tooltipCallback(dataPoint) : `${dataPoint} ${barLabelKey}`}
          >
            <rect
              className="bar"
              height={innerHeight * (dataPoint / maxValue) || 0}
              width={barWidth}
              x={index * barWidth + (index + 1) * gapBetweenBars}
              y={innerHeight * (1 - dataPoint / maxValue)}
              fill={colors[index] ?? '#13C26B'}
            />
          </Tooltip>
        ))}
        {Names.map((company, index) => (
          <Tooltip key={`key${index.toString()}`} title={company}>
            <text
              fill="rgba(7,16,26,0.7)"
              transform={`translate(${calculateTranslation(gapBetweenBars, barWidth, index) +
                xLabelPadding}, ${innerHeight + xLabelPadding})rotate(${-45 + rotation})`}
              textAnchor={labelTextAnchor}
            >
              {company.length > 10 ? `${company.substr(0, 7)}...` : company}
            </text>
          </Tooltip>
        ))}
        {isBarValueVisible
          ? Values?.map((value, index) => (
              <text
                key={`key_${index}`}
                fill="rgba(7,16,26,0.7)"
                transform={`translate(${calculateTranslation(gapBetweenBars, barWidth, index)},${innerHeight *
                  (1 - value / maxValue)})`}
              >
                {value}
              </text>
            ))
          : null}
        <line x1="0" y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke="#DADBDD" />
      </svg>
    </div>
  );
}

BarChart.defaultProps = {
  colors: [],
  list: [],
  width: 300,
  height: 300,
  rotation: 0,
  barWidth: 24,
  gapBetweenBars: 32,
  margin: 60,
  xLabelPadding: 0,
  isBarValueVisible: false,
  barLabelKey: 'Profiles',
  labelTextAnchor: 'middle',
};
