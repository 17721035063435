import React, { useState, useRef, useCallback } from 'react';
import { Dropdown, Input, Tag, Menu, Skeleton, Icon, Form, Tooltip } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styles from './JobTitleDropdown.module.scss';
import { fetchTitleSynonyms } from '../../Actions/JobActions';
import { getTitleSynonyms } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import useClickOutside from '../../Hooks/useClickOutside';
import { JobCreditIcon } from '../../Icons/AryaIcons';

const FormItem = Form.Item;

function CustomSkeleton({ rows, columns, loading }) {
  return (
    <>
      {Array(...Array(rows)).map(() => (
        <div style={{ display: 'flex' }}>
          {Array(...Array(columns)).map(() => (
            <Skeleton active loading={loading} paragraph={{ rows: 1, width: '100px' }} title={false} />
          ))}
        </div>
      ))}
    </>
  );
}

function getLoadingMenuItem(jobTitleValue, isLoading, isResultEmpty) {
  let message = 'Enter Job Title';
  if (!jobTitleValue) {
    message = 'Enter Job Title';
  }
  if (jobTitleValue && isLoading) {
    message = 'Loading..';
  }
  if (isResultEmpty && !isLoading && jobTitleValue) {
    message = null;
  }

  return message ? (
    <Menu>
      <Menu.Item disabled>{message}</Menu.Item>
    </Menu>
  ) : (
    <div />
  );
}

export default function JobTitleDropdown(props) {
  const { onTitleClick, onTitleChange, jobTitles, onBlur, onChange, value, showSynonymsDropdown = true } = props;
  const [isDropdownVisble, setIsDropdownVisble] = useState(false);
  const [showTitleSynonymsDropdown, setShowTitleSynonymsDropdown] = useState(false);
  const [selectedTitleForSynonyms, setSelectedTitleForSynonyms] = useState('');
  const titleSynonyms = useSelector(state => getTitleSynonyms(state));
  const titleSynonymsApiStatus = useSelector(state => getApiStatus(state, 'titleSynonymStatusUpdate'));
  const fetchTitleApiStatus = useSelector(state => getApiStatus(state, 'fetchTitleApiStatus'));
  const wrapperRef = useRef(null);
  const [titleSynonymsAvailableToSelect, setTitleSynonymsAvailableToSelect] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setShowTitleSynonymsDropdown(value.title);
    setSelectedTitleForSynonyms(value.title);
    onTitleChange(value.title);
  }, []);

  const handleOutsideClick = useCallback(() => {
    setIsDropdownVisble(false);
    if (value.title !== selectedTitleForSynonyms) {
      onChange({
        ...value,
        titleSynonyms: [],
      });
    }
  }, [value.title, selectedTitleForSynonyms]);

  const onDeleteTitleSynonym = titleSynonym => {
    const modifedTitleSynonyms = value.titleSynonyms.filter(title => title !== titleSynonym);
    onChange({
      ...value,
      titleSynonyms: modifedTitleSynonyms,
    });
  };

  React.useEffect(() => {
    if (titleSynonyms?.length || (!titleSynonyms?.length && titleSynonymsApiStatus === 'COMPLETED')) {
      setTitleSynonymsAvailableToSelect(_.difference(_.uniq(titleSynonyms), value.titleSynonyms));
    }
  }, [value.titleSynonyms, titleSynonyms]);

  const onJobTitleClick = jobTitle => {
    onTitleClick(jobTitle);
    setShowTitleSynonymsDropdown(true);
    if (showSynonymsDropdown) {
      dispatch(fetchTitleSynonyms(jobTitle));
    }
    if (jobTitle !== selectedTitleForSynonyms) {
      onChange({
        title: jobTitle,
        titleSynonyms: [],
      });
    } else {
      onChange({
        ...value,
        title: jobTitle,
      });
    }
  };

  useClickOutside(wrapperRef, handleOutsideClick);

  const jobTitlesMenu =
    value.title && jobTitles?.length ? (
      <Menu>
        {jobTitles.map(jobTitle => (
          <Menu.Item
            key={jobTitle}
            onClick={() => {
              onJobTitleClick(jobTitle);
              setSelectedTitleForSynonyms(jobTitle);
            }}
          >
            {jobTitle}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      getLoadingMenuItem(value.title, fetchTitleApiStatus === 'INPROGRESS', !jobTitles?.length)
    );

  const jobTitleSynonymsOverlay = showSynonymsDropdown ? (
    <div>
      <div className={styles.selectedTags}>
        {value.titleSynonyms?.length
          ? value.titleSynonyms.map(title => {
              return (
                <Tooltip key={title} title={title}>
                  <Tag
                    closable
                    className={`${styles.jobTitleTags} ${styles.selectedJobTitles}`}
                    onClose={() => {
                      onDeleteTitleSynonym(title);
                    }}
                  >
                    <span className={styles.tagContent}>
                      <div className={styles.controlOverflow}>{`${title}`}</div>
                    </span>
                  </Tag>
                </Tooltip>
              );
            })
          : null}
      </div>
      <div className={styles.suggestionsContainer}>
        <div className={styles.loaderContainer}>
          {titleSynonymsApiStatus === 'INPROGRESS' ? <Icon type="loading" /> : <JobCreditIcon />}
        </div>
        <div className={styles.suggestionsWrapper}>
          <div className={styles.suggestionsTitle}>
            {titleSynonymsApiStatus === 'INPROGRESS'
              ? `Searching roles similar to ${value.title}`
              : 'Any other similar role you would prefer?'}
          </div>
          {titleSynonymsApiStatus !== 'INPROGRESS' ? (
            <div className={styles.suggestions}>
              {titleSynonymsAvailableToSelect.length
                ? titleSynonymsAvailableToSelect.map(suggestion => {
                    return (
                      <Tooltip title={suggestion} key={suggestion}>
                        <Tag
                          className={styles.jobTitleTags}
                          onClick={e => {
                            e.stopPropagation();
                            const updatedTitlesSelection = _.uniq([...(value.titleSynonyms || []), suggestion]);
                            onChange({
                              ...value,
                              titleSynonyms: updatedTitlesSelection,
                            });
                          }}
                        >
                          <span className={styles.tagContent}>
                            <div className={styles.controlOverflow}>{`+  ${suggestion}`}</div>
                          </span>
                        </Tag>
                      </Tooltip>
                    );
                  })
                : `No synonyms found for ${value.title}`}
            </div>
          ) : null}
          <CustomSkeleton rows={3} columns={3} loading={titleSynonymsApiStatus === 'INPROGRESS'} />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );

  const dropDownContainer = showSynonymsDropdown ? styles.dropDownContainer : styles.hideDropDownContainer;

  return (
    <FormItem label="What is the title for your job opening? *">
      <div className={styles.dropdownWrapper} ref={wrapperRef}>
        <Dropdown
          overlay={showTitleSynonymsDropdown ? jobTitleSynonymsOverlay : jobTitlesMenu}
          placement="bottomCenter"
          visible={isDropdownVisble}
          getPopupContainer={triggerNode => {
            return triggerNode.parentNode;
          }}
          overlayClassName={showTitleSynonymsDropdown ? dropDownContainer : styles.jobTitleDropdown}
        >
          <Input
            onChange={e => {
              onChange({
                ...value,
                title: e.target.value,
              });
              onTitleChange(e.target.value);
            }}
            placeholder="Ex: Software Engineer"
            onFocus={() => {
              setShowTitleSynonymsDropdown(false);
              setIsDropdownVisble(true);
            }}
            onBlur={() => onBlur(value.title)}
            value={value.title}
          />
        </Dropdown>
        {showSynonymsDropdown && (
          <div className={styles.selectedTagsDisplay}>
            {value.titleSynonyms?.length ? (
              <>
                {value.titleSynonyms.map(title => {
                  return (
                    <Tooltip title={title} key={title}>
                      <Tag
                        closable
                        className={`${styles.jobTitleTags} ${styles.selectedJobTitles}`}
                        onClose={() => {
                          if (onDeleteTitleSynonym) {
                            onDeleteTitleSynonym(title);
                          }
                        }}
                      >
                        <span className={styles.tagContent}>
                          <div className={styles.controlOverflow}>{`${title}`}</div>
                        </span>
                      </Tag>
                    </Tooltip>
                  );
                })}
                <Tag
                  className={styles.jobTitleTags}
                  onClick={() => {
                    if (!showTitleSynonymsDropdown) {
                      onJobTitleClick(selectedTitleForSynonyms);
                    }
                    setIsDropdownVisble(true);
                  }}
                >
                  + Add more
                </Tag>
              </>
            ) : null}
          </div>
        )}
      </div>
    </FormItem>
  );
}
