import React from 'react';
import { Row, Col } from 'antd';
import TemplateRow from '../TemplateRow';
import './SystemEmailTemplateRow.scss';

export default class SystemEmailTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.viewTemplate = this.viewTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  viewTemplate() {
    const { id, viewTemplate } = this.props;
    viewTemplate(id);
  }

  render() {
    const { title, subject, updatedBy, updatedTime } = this.props;

    return (
      <Row className="template">
        <Col span={8} onClick={this.viewTemplate} className="system-email-template-row-subject">
          {this.addSubject(this.getTitleColumn(title), this.getSubject('Subject', subject))}
        </Col>
        <Col span={5}>{this.getUpdatedOnColumn(updatedTime)}</Col>
        <Col span={5}>{this.getCreatedByColumn(updatedBy)}</Col>
      </Row>
    );
  }
}
