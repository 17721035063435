import * as locationSearchRepository from '../Repository/LocationSearchRepository';
import * as jobRepository from '../Repository/JobRepository';
import {
  setGlobalCountries,
  setStateSuggestions,
  setGlobalLocations,
  setLocationsApiStatus,
  setStateSuggestionsApiStatus,
  setCitySuggestionsApiStatus,
  setCitySuggestions,
  setCityAndStateSuggestions,
  setLocationValidationError,
} from './ActionCreators/LocationSearchActions';

function fetchCountries(requireAuthentication = true) {
  return async dispatch => {
    let response;
    try {
      if (requireAuthentication) {
        response = await locationSearchRepository.getCountries();
      } else {
        response = await locationSearchRepository.getSmartjobCountries();
      }
      dispatch(setGlobalCountries(response.data));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'COUNTRIES_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function fetchStateSuggestions(filter) {
  const { country, searchTerm: location } = filter;
  const layers = 'region';
  const payload = { country, location, layers };
  return async dispatch => {
    if (!location) return;
    dispatch(setStateSuggestionsApiStatus('INPROGRESS'));
    try {
      const response = await locationSearchRepository.getStateSuggestions(payload);
      const states = response.data.results;
      dispatch(setStateSuggestionsApiStatus('COMPLETED'));
      dispatch(
        setStateSuggestions(
          states.filter((st, ind) => states.findIndex(obj => obj.state.name === st.state.name) === ind)
        )
      );
    } catch {
      dispatch(setStateSuggestionsApiStatus('FAILED'));
    }
  };
}

function fetchCitySuggestions(filter) {
  const { country, state, searchTerm: location } = filter;
  const layers = 'locality';
  const payload = { country, state, location, layers };
  return async dispatch => {
    if (!location) return;
    dispatch(setCitySuggestionsApiStatus('INPROGRESS'));
    try {
      const response = await locationSearchRepository.getCitySuggestions(payload);
      const cities = response.data.results;
      dispatch(setCitySuggestionsApiStatus('COMPLETED'));
      dispatch(
        setCitySuggestions(
          cities.filter(
            (ci, ind) => cities.findIndex(obj => obj.city === ci.city) === ind && ci.state.code === state.state.code
          )
        )
      );
    } catch {
      dispatch(setCitySuggestionsApiStatus('FAILED'));
    }
  };
}

function fetchLocations(filter, requireAuthentication = true) {
  return async dispatch => {
    let response;
    dispatch(setLocationsApiStatus('INPROGRESS'));
    try {
      if (requireAuthentication) {
        response = await locationSearchRepository.getLocations(filter);
      } else {
        response = await locationSearchRepository.getSmartjobLocations(filter);
      }
      dispatch(setGlobalLocations(response.data));
      dispatch(setLocationsApiStatus('COMPLETED'));
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'LOCATIONS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setLocationsApiStatus('FAILED'));
    }
  };
}

function _fetchLocations({ filter, requireAuthentication = true }) {
  // we shouldn't setting locations data in store
  return async () => {
    let response;
    try {
      if (requireAuthentication) {
        response = await locationSearchRepository.getLocations(filter);
      } else {
        response = await locationSearchRepository.getSmartjobLocations(filter);
      }
      return response.data;
    } catch {
      return [];
    }
  };
}

function clearLocations(typeOfLocations) {
  return dispatch => {
    if (typeOfLocations.city) {
      dispatch(setCitySuggestions([]));
    }
    if (typeOfLocations.location) {
      dispatch(setGlobalLocations([]));
    }
    if (typeOfLocations.state) {
      dispatch(setStateSuggestions([]));
    }
  };
}
function fetchZipCodes({ filter, requireAuthentication = true }) {
  // we shouldn't be setting locations data in store
  return async () => {
    let response;
    try {
      if (requireAuthentication) {
        response = await jobRepository.getZipCodes(filter);
      } else {
        response = await jobRepository.getSmartjobZipCodes(filter);
      }
      return response.data;
    } catch {
      return [];
    }
  };
}
function searchZipCode(filter) {
  // we shouldn't be setting locations data in store
  return async () => {
    const response = await jobRepository.searchZipCode(filter);
    return response.data;
  };
}

function fetchCityAndStateSuggestions(filter) {
  const { country, searchTerm: location, layers } = filter;
  const payload = { country, location, layers };
  return async dispatch => {
    if (!location) return;
    let response;
    dispatch(setStateSuggestionsApiStatus('INPROGRESS'));
    try {
      if (layers) response = await locationSearchRepository.getStateSuggestions(payload);
      else response = await locationSearchRepository.getCityAndStateSuggestions(payload);
      const states = response.data.results;
      dispatch(setStateSuggestionsApiStatus('COMPLETED'));
      dispatch(setCityAndStateSuggestions(states));
    } catch {
      dispatch(setStateSuggestionsApiStatus('FAILED'));
    }
  };
}
function _fetchCityAndStateSuggestions(filter) {
  // we shouldn't setting locations data in store
  const { country, searchTerm: location, layers } = filter;
  const payload = { country, location, layers };
  return async () => {
    let response;
    try {
      if (layers) response = await locationSearchRepository.getStateSuggestions(payload);
      else response = await locationSearchRepository.getCityAndStateSuggestions(payload);
      return response.data.results;
    } catch {
      return [];
    }
  };
}
function setValidationError(payload) {
  const { error, errorMessage } = payload;
  return setLocationValidationError({ error, errorMessage });
}
function clearCityAndStateSuggestions() {
  return setCityAndStateSuggestions([]);
}
export {
  fetchLocations,
  _fetchLocations,
  fetchCountries,
  fetchStateSuggestions,
  fetchCitySuggestions,
  clearLocations,
  fetchZipCodes,
  searchZipCode,
  fetchCityAndStateSuggestions,
  _fetchCityAndStateSuggestions,
  clearCityAndStateSuggestions,
  setValidationError,
};
