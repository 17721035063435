import React, { Component } from 'react';
import { Icon, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { VisaCardIcon, MasterCardIcon, DiscoverCardIcon, AmexCardIcon } from '../../Icons/AryaIcons';
import './CreditCardDetails.scss';

export function getCardProviderIcon(cardProviderName = '') {
  switch (cardProviderName.toLowerCase()) {
    case 'visa':
      return <VisaCardIcon className="card-detail-card-icon" />;
    case 'mastercard':
      return <MasterCardIcon className="card-detail-card-icon" />;
    case 'discover':
      return <DiscoverCardIcon className="card-detail-card-icon" />;
    case 'americanexpress':
    case 'american-express':
      return <AmexCardIcon className="card-detail-card-icon" />;
    default:
      return <Icon className="card-detail-card-default-icon" type="credit-card" style={{ fontsize: '24px' }} />;
  }
}

export default class CreditCardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  moreMenu = () => {
    const { ProfileId, showDeletePaymentCardModal } = this.props;

    return (
      <Menu onClick={() => null}>
        <Menu.Item key="delete" onClick={() => showDeletePaymentCardModal(ProfileId)}>
          <div className="credit-card-popover-menu" role="presentation">
            Remove
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  getCardNumberDisplayText = () => {
    const { CardDetail } = this.props;
    let cardNumber = _.get(CardDetail, ['Number'], 'xxxx');
    cardNumber = cardNumber.substring(cardNumber.length - 4);
    cardNumber = `**** **** **** ${cardNumber}`;
    return cardNumber;
  };

  render() {
    const { CardDetail, selectCard, select } = this.props;
    const cardNumber = this.getCardNumberDisplayText();

    const cardProviderName = _.get(CardDetail, ['ProviderName'], '');
    return (
      <div
        className="card-details-body"
        style={select ? { border: '1px solid green' } : {}}
        onClick={selectCard}
        onKeyDown={selectCard}
        role="button"
        tabIndex="0"
      >
        <div className="card-details-primary-content">
          {getCardProviderIcon(cardProviderName)}
          <div className="more-wrapper">
            <Dropdown overlay={this.moreMenu} placement="bottomRight">
              <div className="card-details-more">
                <Icon type="more" />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="card-details-card-number">{cardNumber}</div>
        <div className="card-details-card-expiry">Expires {_.get(CardDetail, 'ExpiryDate')}</div>
      </div>
    );
  }
}
