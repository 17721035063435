import React from 'react';
import { Tooltip } from 'antd';
import { getSourceImage } from '../../../Utils/SourceUtils';

export default function SourceImageIcon(props) {
  const { tooltipVisibility, placement, sourceName, sourcePortal, iconStyle, showVaultName } = props;

  return tooltipVisibility ? (
    <Tooltip placement={placement} title={sourceName}>
      {getSourceImage({ Portal: sourcePortal }, iconStyle, showVaultName)}{' '}
    </Tooltip>
  ) : (
    getSourceImage({ Portal: sourcePortal }, iconStyle, showVaultName)
  );
}
