import React from 'react';
import { Tabs } from 'antd';
import ReportSummaryTabContainer from '../../Containers/ReportPortalContainer/ReportSummaryTabContainer/ReportSummaryTabContainer';
import ReportStatsTabContainer from '../../Containers/ReportPortalContainer/ReportStatsTabContainer/ReportStatsTabContainer';
import styles from './ReportPortalComponent.module.scss';

const { TabPane } = Tabs;

export default function ReportPortalComponent(props) {
  const { featureToggleList } = props;
  const isReportPortalEnabled = featureToggleList.ReportingPortal.IsEnabled;

  return (
    <div className={styles.tabs}>
      <Tabs defaultActiveKey="summary">
        {isReportPortalEnabled ? (
          <TabPane tab="Overview" key="summary">
            <div>
              <ReportSummaryTabContainer />
            </div>
          </TabPane>
        ) : null}
        {isReportPortalEnabled ? (
          <TabPane tab="Reports" key="reports">
            <div>
              <ReportStatsTabContainer />
            </div>
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
}
