import React from 'react';
import ListsHeader from '../ListsHeader/ListsHeader';

function ListFormComponent(props) {
  const { form, createSegment, segmentDetails, onChange, onSearch, searchTerm, onSegmentCreationCallback } = props;
  return (
    <div>
      <ListsHeader
        form={form}
        createSegment={createSegment}
        segmentDetails={segmentDetails}
        onChange={onChange}
        onSearch={onSearch}
        searchTerm={searchTerm}
        onSegmentCreationCallback={onSegmentCreationCallback}
      />
    </div>
  );
}

export default ListFormComponent;
