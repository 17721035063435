export const candidateWorkHistory = { id: 'work-history-ref', name: 'Work History' };
export const candidateSkills = { id: 'keywords-ref', name: 'Keywords/Skills' };
export const candidateAccomplishment = { id: 'accomplishments-ref', name: 'Accomplishments' };
export const candidateResearch = { id: 'research-ref', name: 'Research' };
export const candidateWorkHistoryItems = [
  candidateWorkHistory,
  candidateSkills,
  candidateAccomplishment,
  candidateResearch,
];
export const getAnchorTargetOffset = ({ isCandidateViewHeaderVisible }) => {
  return isCandidateViewHeaderVisible ? 100 : 230;
};
