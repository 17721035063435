import React from 'react';
import { Select, InputNumber, Form } from 'antd';
import styles from './ResumeLastUpdated.module.scss';
import TextWithInfoIcon from '../../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';

const { Option } = Select;
const { Item } = Form;

function ResumeLastUpdated(props) {
  const { form, resumeUpdateDurationType, resumeUpdateDurationQuantity } = props;
  const durationTypeSelect = form.getFieldDecorator('ResumeUpdateDurationType', {
    initialValue: resumeUpdateDurationType,
  })(
    <Select className={styles.durationTypeSelect} placeholder="Select duration">
      <Option value="Days">Days</Option>
      <Option value="Months">Months</Option>
      <Option value="Years">Years</Option>
    </Select>
  );
  return (
    <>
      <TextWithInfoIcon
        text={<div className={styles.resumeLastUpdatedLabelText}>Resume Last Updated</div>}
        tooltipTitle={`On Dice this field will be applied for "Last Active on Dice"`}
      />
      <div className={styles.resumeWrapper}>
        <div className={styles.durationLabel}>Within</div>
        <Item colon={false}>
          {form.getFieldDecorator('ResumeUpdateDurationQuantity', { initialValue: resumeUpdateDurationQuantity })(
            <InputNumber min={1} className={styles.durationTypeQuantity} />
          )}
          {durationTypeSelect}
        </Item>
      </div>
    </>
  );
}

export default ResumeLastUpdated;
