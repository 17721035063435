import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JobStatusChange from '../../Components/JobStatus/JobStatusChange';
import { fetchJobPublishedPortals, recallJob } from '../../Actions/JobDistributionActions';
import { getJobPortalsDistributedInfo, getJobDistributedPortals } from '../../Reducers/JobDistributionReducer';
import { SuccessPublishedStatus } from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { fetchJobDripStats } from '../../Actions/ConnectActions';

export default function JobStatusChangeContainer(props) {
  const {
    visible,
    jobId,
    oldJobStatus,
    nextJobStatus,
    setJobStatusChangeVisible,
    onJobStatusChange,
    form,
    jobGuid,
  } = props;
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [isDripCampaignScheduled, setIsDripCampaignScheduled] = React.useState(false);
  const checkDripCampaignScheduled = jobDripStats => {
    for (let i = 0; i < jobDripStats.length; i += 1) {
      if (jobDripStats[i].ScheduledEmailsCount > 0) return true;
    }
    return false;
  };
  const dispatch = useDispatch();
  async function fetchPublishedPortalsAndDripStats() {
    const response = await dispatch(fetchJobPublishedPortals({ jobId }));
    const jobDripStats = await dispatch(fetchJobDripStats(jobGuid));
    const isDripScheduled = checkDripCampaignScheduled(jobDripStats);
    setIsDripCampaignScheduled(isDripScheduled);
    const activeDistributedPortals = response
      .filter(p => SuccessPublishedStatus.includes(p.DistributionStatus?.toLowerCase()))
      .map(p => p.Portal);
    if (!activeDistributedPortals.length && !isDripScheduled) {
      onJobStatusChange(jobId, nextJobStatus);
      setJobStatusChangeVisible(false);
    } else {
      setPopupVisible(true);
    }
  }

  React.useEffect(() => {
    if (visible) {
      fetchPublishedPortalsAndDripStats();
    } else {
      setPopupVisible(false);
    }
  }, [visible]);

  const currentUser = useSelector(state => getCurrentUserDetails(state));
  const distributedPortals = useSelector(state => getJobDistributedPortals(state));
  const distributedPortalsInfo = useSelector(state => getJobPortalsDistributedInfo(state));

  const recallJobPortal = portal => {
    dispatch(recallJob(jobId, portal));
  };

  const onCancel = () => {
    form.setFieldsValue({ JobStatus: oldJobStatus });
    setJobStatusChangeVisible(false);
  };

  const onConfirm = () => {
    setJobStatusChangeVisible(false);
    onJobStatusChange(jobId, nextJobStatus);
  };

  if (!popupVisible) return null;

  return (
    <JobStatusChange
      visible={popupVisible}
      onCancel={onCancel}
      onConfirm={onConfirm}
      recallJob={recallJobPortal}
      distributedPortals={distributedPortals}
      currentUser={currentUser}
      distributedPortalsInfo={distributedPortalsInfo}
      isDripCampaignScheduled={isDripCampaignScheduled}
    />
  );
}
