import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import {
  DesiredJobTitleIcon,
  DesiredSalaryIcon,
  RelocationIcon,
  WorkAuthorizationIcon,
  LanguagesIcon,
  UnAuthorizedCountriesIcon,
  WorkPermitDocumentsIcon,
  AvailabilityIcon,
  PreferredLocationIcon,
  DesiredJobTypeIcon,
} from '../Icons/AryaIcons';
import MaleIcon from '../Icons/MaleIcon';
import FemaleIcon from '../Icons/FemaleIcon';
import MinorityIcon from '../Icons/MinorityIcon';
import VeteranIcon from '../Icons/VeteranIcon';
import DiverseCandidateIcon from '../Icons/DiverseCandidate';
import { getSalaryString } from './SalaryUtils';

const getMetadataIcon = metadataItem => {
  switch (metadataItem) {
    case 'DesiredJobTitles':
      return <DesiredJobTitleIcon className="candidate-metadata-icon" />;
    case 'JobTypes':
      return <DesiredJobTypeIcon className="candidate-metadata-icon" />;
    case 'PreferredLocation':
      return <PreferredLocationIcon className="candidate-metadata-icon" />;
    case 'Salaries':
      return <DesiredSalaryIcon className="candidate-metadata-icon" />;
    case 'RelocationStatus':
      return <RelocationIcon className="candidate-metadata-icon" />;
    case 'AuthorizedCountries':
      return <WorkAuthorizationIcon className="candidate-metadata-icon" />;
    case 'WorkPermitDocuments':
      return <WorkPermitDocumentsIcon className="candidate-metadata-icon" />;
    case 'UnauthorizedCountries':
      return <UnAuthorizedCountriesIcon className="candidate-metadata-icon" />;
    case 'Languages':
      return <LanguagesIcon className="candidate-metadata-icon" />;
    case 'Availability':
      return <AvailabilityIcon className="candidate-metadata-icon" />;
    case 'Male':
      return <MaleIcon className="candidate-metadata-icon" />;
    case 'Female':
      return <FemaleIcon className="candidate-metadata-icon" />;
    case 'Minority':
      return <MinorityIcon className="candidate-metadata-icon" />;
    case 'Veteran':
      return <VeteranIcon className="candidate-metadata-icon" />;
    case 'Diverse':
      return <DiverseCandidateIcon className="candidate-metadata-icon" />;
    default:
      return null;
  }
};
const getMetadata = (metadataItem, metadataContent) => {
  switch (metadataItem) {
    case 'WorkPermitDocuments':
      return (
        <div key={metadataItem}>
          <div style={{ fontWeight: 'bold' }}>Work Permit Documents:&nbsp;</div>
          <Tooltip title={metadataContent}> {metadataContent} </Tooltip>
        </div>
      );
    case 'RelocationStatus':
      return (
        <div key={metadataItem}>
          <div style={{ fontWeight: 'bold' }}>Willing to relocate:&nbsp;</div>
          <Tooltip title={metadataContent}>{metadataContent}</Tooltip>
        </div>
      );
    case 'Languages':
      return (
        <div key={metadataItem}>
          <div style={{ fontWeight: 'bold' }}>Language&nbsp;</div>
          <Tooltip title={metadataContent}> {metadataContent}</Tooltip>
        </div>
      );
    case 'AuthorizedCountries':
      return (
        <div key={metadataItem}>
          <div style={{ fontWeight: 'bold' }}>Authorized&nbsp;</div>
          <Tooltip title={metadataContent}> {metadataContent}</Tooltip>
        </div>
      );
    default:
      return null;
  }
};
const getMetadataTooltipText = metadataItem => {
  switch (metadataItem) {
    case 'DesiredJobTitles':
      return 'Target Job Title';
    case 'JobTypes':
      return 'Desired Job Type';
    case 'Salaries':
      return 'Desired Salary';
    case 'RelocationStatus':
      return 'Relocation';
    case 'AuthorizedCountries':
      return 'Authorization';
    case 'WorkPermitDocuments':
      return 'Work Permit Documents';
    case 'UnauthorizedCountries':
      return 'Unauthorized-Country';
    case 'Languages':
      return 'Languages';
    case 'Availability':
      return 'Availability';
    case 'Male':
      return 'Gender';
    case 'Female':
      return 'Gender';
    case 'PreferredLocation':
      return 'Preferred Location';
    default:
      return null;
  }
};

const getMetadataContent = (metadataItem, metatadataDetails) => {
  if (
    [
      'JobTypes',
      'DesiredJobTitles',
      'AuthorizedCountries',
      'WorkPermitDocuments',
      'UnauthorizedCountries',
      'Languages',
      'PreferredLocation',
    ].includes(metadataItem)
  )
    return metatadataDetails[metadataItem].join(', ');
  if (
    [
      'Salaries',
      'Availability',
      'RelocationStatus',
      'Male',
      'Female',
      'IsDerivedMinority',
      'IsDerivedVeteran',
      'IsDiverse',
    ].includes(metadataItem)
  )
    return metatadataDetails[metadataItem];

  return metatadataDetails[metadataItem];
};

const getIsMetadataCardVisible = (
  { candidateMetadataDetails, isDisplayShortlistInfo },
  isDiversityAttributesVisible
) => {
  return !!(
    Object.values(candidateMetadataDetails).find(values => values && values.length > 0) ||
    candidateMetadataDetails.RelocationStatus !== undefined ||
    isDisplayShortlistInfo ||
    candidateMetadataDetails.Availability ||
    (candidateMetadataDetails?.Derived?.DerivedGender && isDiversityAttributesVisible)
  );
};

const getModifiedMetadataDetails = (candidateMetadataDetails, isDiversityAttributesVisible) => {
  const metadataDetails = _.cloneDeep(candidateMetadataDetails);
  if (metadataDetails.RelocationStatus !== undefined) {
    metadataDetails.RelocationStatus = metadataDetails.RelocationStatus === true ? 'Relocate' : `Won't Relocate`;
  }
  if (metadataDetails.Salaries) {
    let salaryString;
    metadataDetails.Salaries.forEach(salary => {
      const currentSalaryString = getSalaryString(salary);
      salaryString = salaryString ? `${salaryString}, ${currentSalaryString}` : `${currentSalaryString}`;
    });
    metadataDetails.Salaries = salaryString;
  }
  if (metadataDetails.Languages) {
    metadataDetails.Languages = metadataDetails.Languages.map(language => {
      if (language.Language && language.Proficiency) return `${language.Language}-${language.Proficiency}`;
      if (language.Language) return language.Language;
      return '';
    });
  }
  if (metadataDetails?.Derived) {
    if (isDiversityAttributesVisible) {
      if (['Female', 'Male'].includes(metadataDetails?.Derived?.DerivedGender)) {
        metadataDetails[metadataDetails.Derived.DerivedGender] = metadataDetails.Derived.DerivedGender;
      }
      if (metadataDetails?.Derived?.IsDerivedMinority) {
        metadataDetails.Minority = 'Minority';
      }
      if (metadataDetails?.Derived?.IsDerivedVeteran) {
        metadataDetails.Veteran = 'Veteran';
      }
      if (metadataDetails?.Derived?.IsDiverse) {
        metadataDetails.Diverse = 'Diverse Candidate';
      }
    }
    delete metadataDetails.Derived;
  }
  return metadataDetails;
};

export {
  getMetadata,
  getMetadataIcon,
  getMetadataTooltipText,
  getMetadataContent,
  getModifiedMetadataDetails,
  getIsMetadataCardVisible,
};
