import React from 'react';
import _ from 'lodash';
import { Form, Input, Button, Icon, Select } from 'antd';
import './IndeedLoginForm.scss';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

class IndeedLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.changeInputOnCountryChange = this.changeInputOnCountryChange.bind(this);
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit(isAuthenticated) {
    const { form, authenticate, jobBoard, disconnect, index, clearAccount, addSelectedCountry, removeSelectedCountry } =
      this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const countryCode = values[`${jobBoard}countryCode`];
        if (isAuthenticated) {
          disconnect(jobBoard, countryCode, index, removeSelectedCountry);
        } else {
          authenticate(
            jobBoard,
            { Token: values[`${jobBoard}Token`], CountryCode: values[`${jobBoard}countryCode`] },
            index,
            clearAccount,
            addSelectedCountry
          );
        }
      }
    });
  }

  render() {
    const {
      form,
      jobBoard,
      portalSettings,
      account,
      index,
      Countries,
      countriesMenu,
      credentialsValidator,
      isAuthenticated,
    } = this.props;
    const credentials = account;
    const token = `${jobBoard}Token`;
    const mandatoryFields = [token];
    const tokenError = form.isFieldTouched(token) && form.getFieldError(token);
    const errorDetail = getJobBoardErrorDetails(credentials, jobBoard);
    return (
      <div className="portal-login-form">
        <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
          {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
        </div>
        <div className="error-message-with-form">
          <Form layout="inline">
            <Form.Item>
              <div className="jobboard-country-code">
                {form.getFieldDecorator(`${jobBoard}countryCode`, {
                  initialValue: credentials.CountryCode || (Countries && Countries[0]),
                })(
                  <Select
                    className="jobboard-country-code-select"
                    disabled={isAuthenticated || countriesMenu.length < 2}
                  >
                    {countriesMenu}
                  </Select>
                )}
              </div>
            </Form.Item>
            <Form.Item validateStatus={tokenError ? 'error' : ''} help="">
              <div className="indeed-credentials">
                {form.getFieldDecorator(`${jobBoard}Token`, {
                  initialValue: isAuthenticated ? '********' : undefined,
                  rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                })(
                  <Input
                    placeholder="Token"
                    className="indeed-token"
                    type="password"
                    autoComplete="new-password"
                    readOnly={isAuthenticated}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item>
              <div className="btn-container">
                <Button
                  // type="primary"
                  htmlType="submit"
                  loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
                  onClick={() => this.handleSubmit(isAuthenticated)}
                  className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
                  shape="round"
                  disabled={
                    credentialsValidator.hasError(form.getFieldsError()) ||
                    credentialsValidator.anyFieldEmpty(form, mandatoryFields)
                  }
                  block
                >
                  {isAuthenticated ? 'Disconnect' : 'Authenticate'}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <div className="error-message">{errorDetail.Message}</div>
        </div> 
      </div>
    );
  }
}

export default Form.create()(IndeedLoginForm);
export { IndeedLoginForm as IndeedLoginFormWithoutForm };
