import { Auth, Analytics } from 'aws-amplify';
import langConfig from '../langConfig';
import config from '../Config/Config';

export function configureCognito() {
  const oauth = {
    domain: config.auth.cognito.domain,
    region: config.auth.cognito.region,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: config.auth.cognito.redirectUri,
    redirectSignOut: config.auth.cognito.redirectUri,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  };

  Analytics.configure({ disabled: true });

  Auth.configure({
    oauth,
    userPoolId: config.auth.cognito.poolId,
    userPoolWebClientId: config.auth.cognito.clientId,
  });

  window.appLocale = langConfig.en;
}
