import cleanSet from 'clean-set';
import {
  ADD_GENERATED_AI_CONFIGURATON,
  ADD_RESOLVED_MERGETAG_VALUES,
} from '../Actions/ActionCreators/ChatGptActionCreators';

const emptyObject = {};
function ChatGptReducer(state = {}, action = {}) {
  let newState;
  switch (action.type) {
    case ADD_GENERATED_AI_CONFIGURATON:
      newState = cleanSet(state, 'GeneratedAIConfiguration', action.payload);
      return newState;
    case ADD_RESOLVED_MERGETAG_VALUES:
      newState = cleanSet(state, 'ResolvedMergeTagValues', action.payload);
      return newState;
    default:
      return state;
  }
}

function getChatGptSavedConfiguration(state) {
  return state.ChatGptReducer?.GeneratedAIConfiguration || emptyObject;
}

function getResolvedMergeTagValues(state) {
  return state.ChatGptReducer?.ResolvedMergeTagValues || emptyObject;
}

export { ChatGptReducer, getChatGptSavedConfiguration, getResolvedMergeTagValues };
