import * as careerProjectionRepository from '../Repository/CareerProjectionRepository';
import {
  resetCareerProjection,
  setCareerProjectionApiStatus,
  setCareerAssessmentApiStatus,
  setCareerProjectionData,
  setCareerAssessmentData,
} from './ActionCreators/CareerProjectionActions';

function fetchCareerProjectionList(filter) {
  return async dispatch => {
    dispatch(resetCareerProjection);
    dispatch(setCareerProjectionApiStatus('INPROGRESS'));
    try {
      const response = await careerProjectionRepository.fetchCareerProjection(filter);
      dispatch(setCareerProjectionData(response.data));
      dispatch(setCareerProjectionApiStatus('COMPLETED'));
    } catch (err) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'ERROR',
          Message: 'Oops, something went wrong. Please try again later.',
        },
      });
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'CAREER_PROJECTION_API_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setCareerProjectionApiStatus('FAILED'));
    }
  };
}

function fetchCareerAssessmentList(filter) {
  return async dispatch => {
    dispatch(setCareerAssessmentApiStatus('INPROGRESS'));
    try {
      const response = await careerProjectionRepository.fetchCareerAssessment(filter);
      dispatch(setCareerAssessmentData(response.data));
      dispatch(setCareerAssessmentApiStatus('COMPLETED'));
    } catch (err) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'ERROR',
          Message: 'Oops, something went wrong. Please try again later.',
        },
      });
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'CAREER_ASSESSMENT_API_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setCareerAssessmentApiStatus('FAILED'));
    }
  };
}

function clearCareerProjectionList() {
  return dispatch => {
    dispatch({
      type: resetCareerProjection.type,
    });
  };
}

export { clearCareerProjectionList, resetCareerProjection, fetchCareerProjectionList, fetchCareerAssessmentList };
