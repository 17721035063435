import React from 'react';
import { Modal } from 'antd';
import _ from 'lodash';
import WarningIcon from '../Icons/WarningIcon';

export const getFormattedInsightsList = (insightsList = [], key, total, newKey = '') => {
  const list = [];
  if (total && key !== 'Range') {
    insightsList.forEach(item => {
      const percentage = Math.round(((item?.Count || 0) / total) * 100);
      list.push({
        [key]: item[key],
        percentage,
        count: item?.Count,
      });
    });
  } else if (key === 'Range') {
    insightsList.forEach(item => {
      const percentage = Math.round(((item?.Count || 0) / total) * 100);
      const expArray = item?.Range?.split('-');
      const minExp = expArray[0] === '*' ? 0 : Math.round(expArray[0]);
      const maxExp = expArray[1] === '*' ? 50 : Math.round(expArray[1]);
      list.push({
        [key]: `${minExp}-${maxExp}`,
        percentage,
        count: item?.Count,
      });
    });
  } else {
    const formattedKey = newKey + key;
    insightsList.forEach(item => {
      list.push({
        [formattedKey]: item[key],
      });
    });
  }
  return [list.slice(0, 15), list.slice(15)];
};

export const formatFormValues = (list = [], key) => {
  const insights = list.map(item => item[key]);
  return _.uniq(insights);
};
export const filterMustHaves = (list = []) => {
  return list.filter(item => item.checked).map(item => item.SkillName);
};
export const filterNiceToHaves = (list = []) => {
  return list.filter(item => !item.checked).map(item => item.SkillName);
};

export const getActiveInsightList = (list = [], key) => {
  const newList = [];
  list.forEach(item => {
    if (item) {
      newList.push({ [key]: item });
    }
  });
  return newList;
};

export const getSelectedSkills = (mustHaves = [], niceToHaves = [], key) => {
  const newList = [];
  mustHaves.forEach(item => {
    if (item) {
      newList.push({ [key]: item, checked: true });
    }
  });
  niceToHaves.forEach(item => {
    if (item) {
      newList.push({ [key]: item });
    }
  });
  return newList;
};

export const handleAdvancedJobExitModal = (history, redirectPath) => {
  Modal.confirm({
    title: 'Some of your data might get lost!',
    content: <div>Do you still want to continue creating job without assistance?</div>,
    okText: 'Yes',
    okType: 'primary',
    onOk: () => history.push(redirectPath),
    cancelText: 'No',
    maskClosable: true,
    okButtonProps: { shape: 'round' },
    cancelButtonProps: { shape: 'round' },
    closable: true,
    icon: <WarningIcon />,
    width: 500,
  });
};
