import _ from 'lodash';
import uuid from 'uuid';
import {
  toBooleanQueryStringUsingOR,
  generateBooleanString,
  generateBooleanNiceToHaves,
  generateArrayFromBooleanString,
} from './BooleanStringUtility';
import { getActualSourcingStats, isJobSourcingCompleted } from './JobSourcingStatsUtil';
import AdvanceRefineIntelFields from '../Data/AdvanceRefineIntelFields.json';
import { getActiveSourcingLocations, getSourcingLocations } from './LocationUtils';
import { getStateForPayload, getCityStateForPayload } from '../Components/QuickSearchForm/QuickSearchFormUtils';

export function getSalaryRangeNormalizer(salaryType, currency) {
  if (currency === 'INR') return 1;

  switch (salaryType) {
    case 'Hourly':
      return 5;
    case 'Daily':
      return 40;
    case 'Weekly':
      return 200;
    case 'Monthly':
      return 800;
    default:
      return 10000;
  }
}

export function getLocationDetailsForJob(jobDetails) {
  const distance = jobDetails?.Range;
  return {
    Location: jobDetails?.Location,
    CountryCode: jobDetails?.CountryCode,
    Postalcode: jobDetails?.Zipcode,
    Distance: distance,
    DistanceUnit: distance ? 'Miles' : undefined,
  };
}

export function getSalaryRange(formValues, userGivenIntel) {
  if (formValues.SalaryRange) {
    const { SalaryRange, CompensationType, Currency } = formValues;
    const salaryNormalizer = getSalaryRangeNormalizer(CompensationType, Currency);
    return SalaryRange?.map(value => value * salaryNormalizer);
  }
  return [userGivenIntel?.Salary?.MinSalary, userGivenIntel?.Salary?.MaxSalary];
}

function mapLocation({ payload, formValues, isUserGivenIntelUpdated, userGivenIntel, jobDetails }) {
  const newPayload = _.cloneDeep(payload);
  newPayload.Location = isUserGivenIntelUpdated ? userGivenIntel.Location : getLocationDetailsForJob(jobDetails);
  if ('Location' in formValues) {
    if (formValues.IsStateWideSearch) {
      newPayload.Location.State = getStateForPayload(formValues.Location);
      newPayload.Location.Location = undefined;
    } else {
      newPayload.Location.Location = getCityStateForPayload(formValues.Location);
      newPayload.Location.State = undefined;
    }
  }
  if ('Zipcode' in formValues) {
    _.set(newPayload, ['Location', 'Postalcode'], formValues.Zipcode);
  }
  if ('Distance' in formValues) {
    _.set(newPayload, ['Location', 'Distance'], formValues.Distance);
  }
  newPayload.IsMultiLocationJob = false;
  return newPayload;
}

function mapMultiLocations({ payload, formValues, isUserGivenIntelUpdated, userGivenIntel, jobDetails }) {
  const newPayload = _.cloneDeep(payload);
  newPayload.Locations = isUserGivenIntelUpdated ? userGivenIntel.Locations : jobDetails.SourcingLocations;
  const activeSourcingLocations = getActiveSourcingLocations(
    'SourcingLocations' in formValues ? formValues.SourcingLocations : newPayload.Locations
  );
  const sourcingLocations = getSourcingLocations(activeSourcingLocations);
  if (sourcingLocations?.length) newPayload.Locations = sourcingLocations;
  else newPayload.Locations = [{ CountryCode: jobDetails.CountryCode }];
  newPayload.IsMultiLocationJob = true;
  return newPayload;
}

export function mapAdvanceRefineIntelPayloadToUserGivenintel(
  userGivenIntel,
  form,
  mustHaveSkills,
  jobDetails,
  isMultiLocationEnabled
) {
  let newPayload = _.cloneDeep(userGivenIntel);
  const formValues = form.getFieldsValue();
  const isUserGivenIntelUpdated = !_.isEmpty(userGivenIntel);
  const [minSalary, maxSalary] = getSalaryRange(formValues, userGivenIntel);
  const jobTitle = isUserGivenIntelUpdated ? userGivenIntel?.Titles?.[0] : jobDetails.JobTitle;
  const jobTitleSynonyms = formValues.AlternateTitles ?? userGivenIntel?.Titles?.slice(1);
  const mergedJobTitles = jobTitleSynonyms ? [jobTitle, ...jobTitleSynonyms] : [jobTitle];
  const prefers = formValues.NiceToHaveSkills ?? userGivenIntel.Keywords?.Prefers;
  newPayload.Keywords = {
    Musts: mustHaveSkills
      ? generateBooleanString(Object.values(mustHaveSkills).map(skillGroup => skillGroup.selectedSkills))
      : undefined,
    Excludes: formValues.ExcludedSkills ?? userGivenIntel.Keywords?.Excluded,
    Prefers: generateBooleanNiceToHaves(prefers),
    RawMusts: mustHaveSkills ? Object.values(mustHaveSkills).map(skillGroup => skillGroup.selectedSkills?.[0]) : null,
    RawPrefers: prefers,
  };
  const industries = isUserGivenIntelUpdated
    ? userGivenIntel.Industries
    : { Musts: generateBooleanString([jobDetails?.Industries]) };
  newPayload.Industries = industries;
  newPayload.Titles = mergedJobTitles.filter(x => x);
  const defaultMinExperience = isUserGivenIntelUpdated
    ? userGivenIntel?.Experience?.MinYears
    : jobDetails?.MinExperience;
  const defaultMaxExperience = isUserGivenIntelUpdated
    ? userGivenIntel?.Experience?.MaxYears
    : jobDetails?.MaxExperience;
  newPayload.Experience = {
    MinYears: 'MinExperience' in formValues ? formValues.MinExperience : defaultMinExperience,
    MaxYears: 'MaxExperience' in formValues ? formValues.MaxExperience : defaultMaxExperience,
  };
  newPayload.Salary =
    (minSalary || maxSalary) > 0
      ? {
          MinSalary: minSalary,
          MaxSalary: maxSalary,
          Currency: formValues.Currency ? formValues.Currency : userGivenIntel?.Salary?.Currency ?? 'USD',
          CompensationType: formValues.CompensationType
            ? formValues.CompensationType
            : userGivenIntel.Salary?.CompensationType ?? 'Yearly',
        }
      : undefined;
  if ('AcademicDegree' in formValues) {
    _.set(newPayload, ['Education', 'Degree'], toBooleanQueryStringUsingOR(formValues.AcademicDegree));
  }
  if ('Certification' in formValues) {
    _.set(newPayload, ['Education', 'Certification'], formValues.Certification);
  }
  if ('School' in formValues) {
    _.set(newPayload, ['Education', 'School'], formValues.School);
  }
  if ('Specialization' in formValues) {
    _.set(newPayload, ['Education', 'Specialization'], formValues.Specialization);
  }
  if ('WillingToRelocate' in formValues) {
    newPayload.IsWillingToRelocate = formValues.WillingToRelocate;
  }
  if ('CurrentlyEmployed' in formValues) {
    newPayload.CurrentlyEmployed = formValues.CurrentlyEmployed;
  }
  if ('SecurityClearance' in formValues) {
    newPayload.SecurityClearance = formValues.SecurityClearance;
  }
  if ('WorkStatuses' in formValues) {
    newPayload.VisaStatuses = formValues.WorkStatuses;
  }
  if ('JobTypes' in formValues) {
    newPayload.EmploymentTypes = formValues.JobTypes;
  }
  if ('CareerLevels' in formValues) {
    newPayload.CareerLevels = formValues.CareerLevels;
  }
  if ('NoticePeriod' in formValues) {
    newPayload.NoticePeriod = formValues.NoticePeriod;
  }
  if ('LastActiveInDays' in formValues) {
    newPayload.LastActiveInDays = formValues.LastActiveInDays;
  }
  if ('PreferredCompany' in formValues) {
    _.set(newPayload, ['Companies', 'Musts'], toBooleanQueryStringUsingOR(formValues.PreferredCompany));
    _.set(newPayload, ['Companies', 'RawMusts'], formValues.PreferredCompany ?? []);
  }
  if ('ExcludedCompanies' in formValues) {
    _.set(newPayload, ['Companies', 'Excludes'], formValues.ExcludedCompanies);
  }
  if ('PreferredIndustry' in formValues) {
    _.set(newPayload, ['Industries', 'Musts'], toBooleanQueryStringUsingOR(formValues.PreferredIndustry));
  }
  if ('ExcludedIndustries' in formValues) {
    _.set(newPayload, ['Industries', 'Excludes'], formValues.ExcludedIndustries);
  }
  if ('PreferredOccupation' in formValues) {
    _.set(newPayload, ['Occupations', 'Musts'], toBooleanQueryStringUsingOR(formValues.PreferredOccupation));
  }
  if ('ExcludedOccupations' in formValues) {
    _.set(newPayload, ['Occupations', 'Excludes'], formValues.ExcludedOccupations);
  }
  newPayload.LastUpdatedFrom = newPayload.LastUpdatedFrom ?? 180;
  newPayload.LastUpdatedFromRaw = newPayload.LastUpdatedFromRaw ?? '6m';
  if ('IsRemoteJob' in formValues) newPayload.IsRemoteJob = formValues.IsRemoteJob;
  else newPayload.IsRemoteJob = isUserGivenIntelUpdated ? userGivenIntel.IsRemoteJob : jobDetails.IsRemoteJob;
  if (newPayload.IsRemoteJob) {
    newPayload.Location = { CountryCode: jobDetails?.CountryCode };
    newPayload.Locations = [{ CountryCode: jobDetails?.CountryCode }];
    return newPayload;
  }
  if (isMultiLocationEnabled) {
    newPayload = mapMultiLocations({ payload: newPayload, jobDetails, formValues });
    newPayload.Location = undefined;
  } else {
    newPayload = mapLocation({ payload: newPayload, isUserGivenIntelUpdated, userGivenIntel, formValues, jobDetails });
    newPayload.Locations = undefined;
  }

  return newPayload;
}

export const JobTypeOptions = [
  { label: 'Full Time / Permanent', value: 'FullTime' },
  { label: 'Part Time', value: 'PartTime' },
  { label: 'Per Diem', value: 'PerDiem' },
  { label: 'Employee', value: 'Employee' },
  { label: 'Intern', value: 'Intern' },
  { label: 'Contract-W2 / Temporary', value: 'Temporary' },
  { label: 'Other JobTypes', value: 'AnyOtherJobType' },
];

export const WorkStatusOptions = [
  { label: 'Any Employer', value: 'AnyEmployer' },
  { label: 'US Citizen', value: 'USCitizen' },
  { label: 'H1 Visa', value: 'H1Visa' },
  { label: 'Green Card', value: 'GreenCard' },
  { label: 'Need H1 Sponsor', value: 'NeedH1Sponsor' },
  { label: 'Not Specified', value: 'NotSpecified' },
  { label: 'Other Visa Status', value: 'AnyOtherVisaStatus' },
];

export const GlobalWorkStatusOptions = [
  { label: 'US Citizen', value: 'USCitizen', supportedBy: ['Dice', 'CB Edge/Classic', 'Monster', 'Internal'] },
  { label: 'H1 Visa', value: 'H1Visa', supportedBy: ['Dice', 'CB Edge/Classic', 'Internal'] },
  {
    label: 'Green Card Holder/Permanent Resident',
    value: 'GreenCard',
    supportedBy: ['Dice', 'CB Edge/Classic', 'Monster', 'Internal'],
  },
  { label: 'Need H1 Sponsor', value: 'NeedH1Sponsor', supportedBy: ['Dice', 'CB Edge/Classic', 'Internal'] },
  { label: 'TN Permit Holder', value: 'TNPermitHolder', supportedBy: ['Dice', 'CB Edge/Classic', 'Internal'] },
  { label: 'Not Specified', value: 'NotSpecified', supportedBy: ['CB Edge/Classic', 'Internal'] },
  {
    label: 'Employment Authorization Document',
    value: 'EmploymentAuthDocument',
    supportedBy: ['Dice', 'CB Edge/Classic', 'Internal'],
  },
  { label: 'Canadian Citizen', value: 'CanadianCitizen', supportedBy: ['Dice', 'Internal'] },
  { label: 'Can work for any employer', value: 'AnyEmployer', supportedBy: ['CB Edge/Classic', 'Monster'] },
  { label: 'Show all job seekers', value: 'ShowAll', supportedBy: ['Monster'] },
];

export const CompensationTypeOptions = [
  { label: 'Hourly', value: 'Hourly' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Yearly', value: 'Yearly' },
];

export const EXPERIENCE = 'Experience';
export const EDUCATION = 'Education';
export const LOCATION = 'Location';
export const WORK_STATUS = 'Work Status';
export const EMPLOYMENT = 'Employment';
export const OTHER = 'Other';
export const AdvanceCriteriaTabs = [EXPERIENCE, EDUCATION, LOCATION, WORK_STATUS, EMPLOYMENT, OTHER];

export const keepAllRetainType = { label: 'Keep All', value: 'keepAll' };
export const keepShortlistedRetainType = { label: 'Keep Shortlisted', value: 'keepShortlisted' };
export const deleteAllRetainType = { label: 'Delete All', value: 'deleteAll' };

export const defaultRetainType = keepAllRetainType.value;

export const getIndexedSkillGroups = (skillGroups = []) => {
  const indexedSkillGroups = {};
  skillGroups.forEach(skillGroup => {
    if (skillGroup.length > 0) {
      const newGroupId = uuid.v4();
      indexedSkillGroups[newGroupId] = {};
      indexedSkillGroups[newGroupId].selectedSkills = skillGroup;
    }
  });
  return indexedSkillGroups;
};

export const RadioGroupOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Does not matter', value: undefined },
];

export const isAddCertificationsButtonDisabled = (form, certificationCount) => {
  const certifications = form.getFieldValue('Certifications');
  return !(certifications?.length === certificationCount && certifications[certifications.length - 1]?.length > 0);
};

export function getCurrentPreferredExperienceText(minExp, maxExp) {
  let experienceText;
  if (minExp && maxExp) {
    experienceText = `${minExp} - ${maxExp} Years`;
  } else if (minExp) {
    experienceText = `Minimun ${minExp} Years`;
  } else if (maxExp) {
    experienceText = `Maximum ${maxExp} Years`;
  } else {
    experienceText = null;
  }
  return experienceText;
}

export function getFilteredRecommendations(recommendations, existingValues) {
  return recommendations
    ?.map(({ Name }) => Name)
    ?.filter(searchValue => !existingValues?.find(value => value.toLowerCase() === searchValue.toLowerCase()));
}

export const onAddRecommendation = (form, key) => {
  return newValue => {
    const formValues = form.getFieldsValue();
    const existingValues = formValues[key];
    const foundValue = existingValues?.find(value => value.toLowerCase() === newValue?.toLowerCase());
    if (!foundValue) {
      form.setFieldsValue({
        [key]: existingValues ? [...existingValues, newValue] : [newValue],
      });
    }
  };
};

export const isNoCandidateSourced = (currentJobDetails = {}) => {
  const { sourcingStats, recentSourcedTime, lastChangeTime } = currentJobDetails;
  const { sourcedCount } = getActualSourcingStats(sourcingStats);
  return isJobSourcingCompleted(recentSourcedTime, lastChangeTime) && sourcedCount === 0;
};

export function isMustHaveSkillsEqual(oldSkills, newSkills) {
  return _.isEqual(generateArrayFromBooleanString(oldSkills), generateArrayFromBooleanString(newSkills));
}

export function areArrayEqual(oldValues = [], newValues = []) {
  return _.isEqual(oldValues.sort(), newValues.sort());
}

function isFieldValueChanged(oldValue, newValue, field) {
  switch (field.compareAs) {
    case 'MustHaveSkills':
      return !isMustHaveSkillsEqual(oldValue, newValue);
    case 'NiceToHaveSkills':
      return !areArrayEqual(oldValue, generateArrayFromBooleanString(newValue)?.[0]);
    case 'Array':
      return !areArrayEqual(oldValue, newValue);
    case 'BooleanQuery':
      return !areArrayEqual(
        generateArrayFromBooleanString(oldValue)?.[0],
        generateArrayFromBooleanString(newValue)?.[0]
      );
    default:
      return !_.isEqual(oldValue, newValue);
  }
}

export function isCriteriaUpdated(oldCriteria, newCriteria, currentJobDetails) {
  const { Location, CountryCode, Postalcode, Distance } = getLocationDetailsForJob(currentJobDetails);
  const fields = [
    ...AdvanceRefineIntelFields,
    {
      path: ['Keywords', 'Musts'],
      compareAs: 'MustHaveSkills',
      defaultValue: currentJobDetails.MustHaves,
    },
    {
      path: ['Keywords', 'Prefers'],
      compareAs: 'NiceToHaveSkills',
      defaultValue: generateArrayFromBooleanString(currentJobDetails.NiceToHaves)?.[0],
    },
    {
      path: ['Titles'],
      compareAs: 'Array',
      defaultValue: [currentJobDetails.JobTitle],
    },

    {
      path: ['Location', 'CountryCode'],
      defaultValue: CountryCode,
    },
    {
      path: ['Location', 'Location'],
      defaultValue: Location,
    },
    {
      path: ['Location', 'Postalcode'],
      defaultValue: Postalcode,
    },
    {
      path: ['Location', 'Distance'],
      defaultValue: Distance,
    },
  ];

  return fields.some(field => {
    const oldValue = _.get(oldCriteria, field.path, field.defaultValue);
    const newValue = _.get(newCriteria, field.path, field.defaultValue);
    return isFieldValueChanged(oldValue, newValue, field);
  });
}

export const getLocationDefaultValues = ({
  isIntelUpdated,
  userGivenIntel,
  jobDetails,
  isMultiLocationEnabled,
  orgDefaultDistance,
}) => {
  const userGivenIntelLocation = isMultiLocationEnabled ? userGivenIntel?.Locations?.[0] : userGivenIntel?.Location;
  const defaultCountryCode = isIntelUpdated ? userGivenIntelLocation.CountryCode : jobDetails.CountryCode;
  const defaultRemoteJobChecked = isIntelUpdated ? userGivenIntel.IsRemoteJob : jobDetails.IsRemoteJob;
  const defaultSourcingLocations = isIntelUpdated ? userGivenIntel.Locations : jobDetails?.SourcingLocations;
  if (isMultiLocationEnabled) {
    return {
      defaultCountryCode,
      defaultRemoteJobChecked,
      defaultSourcingLocations,
      defaultDistance: orgDefaultDistance,
    };
  }
  const defaultState = isIntelUpdated ? userGivenIntelLocation?.State : undefined;
  const defaultLocation = isIntelUpdated ? userGivenIntelLocation.Location : jobDetails.Location;
  const defaultZipcode = isIntelUpdated ? userGivenIntelLocation.Postalcode : jobDetails.Zipcode;
  const defaultDistance = isIntelUpdated ? userGivenIntelLocation.Distance : jobDetails.Range;
  return {
    defaultCountryCode,
    defaultRemoteJobChecked,
    defaultState,
    defaultLocation,
    defaultZipcode,
    defaultDistance,
  };
};
