import React from 'react';
import { Icon } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DowntimeAnnouncement.module.scss';
import {
  getDowntimeAnnouncements,
  setDowntimeRenderStatus,
  updateAnnouncementView,
} from '../../Actions/AnnouncementActions';
import { resetDowntimeAnnouncement } from '../../Actions/ActionCreators/AnnouncementActionCreator';
import * as announcementReducer from '../../Reducers/AnnouncementReducer';

const DowntimeAnnouncement = () => {
  const [alertVisibility, setAlertVisibility] = React.useState(false);
  const announcementItems = useSelector((state) => announcementReducer.getDowntimeAnnouncements(state));
  const dispatch = useDispatch();

  const displayAnnouncement = announcementItems?.[0];
  const announcementItemsPayload = displayAnnouncement?.Payload[0];

  React.useEffect(() => {
    if (announcementItemsPayload) {
      setAlertVisibility(true);
      dispatch(setDowntimeRenderStatus(true));
    }
  }, [announcementItemsPayload]);

  React.useEffect(() => {
    dispatch(
      getDowntimeAnnouncements({
        from: 0,
        size: 1,
        type: 'DowntimeAnnouncement',
        isViewed: false,
      })
    );
  }, []);

  const onAlertClose = () => {
    dispatch(setDowntimeRenderStatus(false));
    dispatch(resetDowntimeAnnouncement());
    dispatch(updateAnnouncementView(displayAnnouncement?._id));
    setAlertVisibility(false);
  };
  const displayStyles = classNames(
    {
      [styles.showAlertContainer]: alertVisibility,
    },
    {
      [styles.hideAlertContainer]: !alertVisibility,
    }
  );

  return (
    <>
      {announcementItemsPayload ? (
        <div className={displayStyles}>
          <div className={styles.alertMessage}>
            <div className={styles.messageSection}>
              <Icon className={styles.warningIcon} type="warning" />
              <p>{announcementItemsPayload.Description}</p>
            </div>
            <Icon className={styles.closeIcon} onClick={onAlertClose} type="close" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DowntimeAnnouncement;
