import React from 'react';
import { Button } from 'antd';
import queryString from 'query-string';
import './JobNotFound.scss';

function getDisplayMessage({ id, ats, context, isCandidateCRMEnabled }) {
  if (context === 'segment' && isCandidateCRMEnabled)
    return `We could not find this segment ${id}. Please contact your admin or go back to the segment list page.`;
  if (context === 'segment')
    return 'This feature is not enabled for you.Please contact your admin or go back to the joblist page';
  if (ats === 'bullhorn')
    return `We could not find this job ${id}. Please contact your admin or go back to the job menu.`;
  return `We could not find this job ${id}. Please contact your admin or go back to the joblist page.`;
}

const getDisplayTitle = ({ context, isCandidateCRMEnabled }) => {
  if (context === 'job') return 'Job Not Found';
  if (context === 'segment' && !isCandidateCRMEnabled) return 'Feature not enabled';
  return 'Segment Not Found';
};

function getIsBackButtonVisible(ats) {
  if (ats === 'bullhorn') return false;
  return true;
}

function JobNotFound(props) {
  const { jobId, location, context = 'job', isCandidateCRMEnabled = false, openListOfList } = props;
  const queryParams = queryString.parse(location.search);
  const { ats } = queryParams;
  const isBackButtonVisible = getIsBackButtonVisible(ats);
  const displayMessage = getDisplayMessage({ ats, id: jobId, context, isCandidateCRMEnabled });
  const title = getDisplayTitle({ context, isCandidateCRMEnabled });
  const linkTo = context === 'job' || !isCandidateCRMEnabled ? '/jobs' : '/segments';
  const buttonName = context === 'job' || !isCandidateCRMEnabled ? 'Back to Joblist' : 'Back to Segmentlist';
  return (
    <div className="job-not-found body-content-error">
      <div className="job-not-found-wrapper">
        <div className="job-not-found-title"> {title} </div>
        <div className="job-not-found-description">
          <div>{displayMessage}</div>
        </div>
        {isBackButtonVisible ? (
          <div className="job-not-found-button-wrapper">
            <Button className="job-not-found-button" type="primary" onClick={() => openListOfList(linkTo)}>
              {buttonName}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default JobNotFound;
