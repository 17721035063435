import React from 'react';
import _ from 'lodash';
import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import styles from './CandidateCardMetaData.module.scss';
import { getDisplayedMetadataContent, getMetadataLabel } from '../../../Utils/CandidateCardUtils';
import {
  getIsMetadataCardVisible,
  getMetadataContent,
  getModifiedMetadataDetails,
} from '../../../Utils/CandidateMetadataUtils';

function CandidateCardMetaData(props) {
  const { candidate, isDiversityAttributesVisible, secondaryInfoStyle } = props;

  const candidateMetadata = _.pick(candidate, [
    'Industry',
    'MoversPrediction',
    'RelocationStatus',
    'DerivedGender',
    'WorkPermitDocuments',
    'JobTypes',
    'Salaries',
    'DesiredJobTitles',
    'AuthorizedCountries',
    'UnauthorizedCountries',
    'Languages',
    'Availability',
    ['Derived'],
    'PreferredLocation',
  ]);

  const fieldsToHighlight = ['MoversPrediction'];

  const getMetadataTextClassnames = metadataContent => {
    return classNames(styles.candidateCardMetadataText, {
      [styles.highlightMetdataText]: fieldsToHighlight.includes(metadataContent),
    });
  };

  const showMetaData = getIsMetadataCardVisible(
    { candidateMetadataDetails: candidateMetadata },
    isDiversityAttributesVisible
  );

  const getMetadataContentWithTooltip = (metadataContent, metadataItem) => {
    const displayedMetadataContent = getDisplayedMetadataContent(metadataContent);
    return (
      <Tooltip title={displayedMetadataContent} placement="topLeft" mouseEnterDelay={0.5}>
        <span className={getMetadataTextClassnames(metadataItem)}>{displayedMetadataContent}</span>
      </Tooltip>
    );
  };

  const getMetadataItem = (metadataItem, candidateMetadataDetails) => {
    const metadataContent = getMetadataContent(metadataItem, candidateMetadataDetails);
    if (!metadataContent) return null;
    return (
      <div key={metadataItem}>
        <Tag className={styles.candidateMetadataTag}>
          {getMetadataLabel(metadataItem)}
          {getMetadataContentWithTooltip(metadataContent, metadataItem)}
        </Tag>
      </div>
    );
  };

  const metadataDetails = getModifiedMetadataDetails(candidateMetadata, isDiversityAttributesVisible);

  const candidateMetadataDetails = Object.keys(metadataDetails).map(metadataKey =>
    getMetadataItem(metadataKey, metadataDetails)
  );

  return showMetaData ? (
    <div className={styles.candidateCardMetaData} style={secondaryInfoStyle}>
      {candidateMetadataDetails}
    </div>
  ) : null;
}

export default CandidateCardMetaData;
