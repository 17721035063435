import * as notificationRepository from '../Repository/NotificationRepository';

// Uncomment this line to see debug info
// localStorage.debug = '*';

function fetchNotifications(count, skip, status) {
  return dispatch => {
    notificationRepository
      .fetchNotifications(count, skip, status)
      .then(response => {
        const payload = response.data.Notifications;
        dispatch({
          type: 'ADD_NOTIFICATIONS',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchNotificationsCount(status) {
  return dispatch => {
    notificationRepository.fetchNotificationsCount(status).then(response => {
      dispatch({
        type: 'SET_NOTIFICATIONS_COUNT',
        payload: response.data.Count,
      });
    });
  };
}

function setMessagePopupVisibility(conversationId, personId, isVisible, messageType = 'Chat', personNumber) {
  return dispatch => {
    dispatch({
      type: 'SET_VISIBLE_MESSAGE_POPUPS',
      payload: {
        conversationId,
        personId,
        isVisible,
        messageType,
        personNumber,
      },
    });
  };
}

export { fetchNotifications, fetchNotificationsCount, setMessagePopupVisibility };
