import React from 'react';
import { Button, Icon, Tooltip } from 'antd';
import styles from './SampleCandidatePipeline.module.scss';
import { Pipeline, PipelineItem } from '../Pipeline/Pipeline';
import selectPlanTooltip, { selectPlanCustomTooltip } from '../../Utils/TooltipUtils';
import CandidatesIcon from '../../Icons/CandidatesIcon';
import EngageIcon from '../../Icons/EngageIcon';
import ThumbsUpIcon from '../../Icons/ThumbsUpIcon';

export const sourcedTooltipTitle =
  'Arya intelligently searches the most relevant profiles for this job based on your given criteria and puts it in the sourced list for you.';
export const shortlistedTooltipTitle =
  'You can shortlist candidates you like from the sourced list by giving a thumbs up. Arya takes your feedback and searches for similar candidates you have shortlisted and filters out profiles that you have rejected with a thumbs down.';
export const engagedTooltipTitle = `Engaged list helps you keep a tab on all candidates who have been qualified by Arya's professional recruiters and have shown interest in interviewing for the position. Our recruiters line up only the best candidates for you and you can view there details here.`;
export const rejectedTooltipTitle = 'to avail this feature';

function SampleCandidatePipeline({ activeTab = 'sourced', size, activeByDefault = 'sourced', selectPlan }) {
  const pipelineData = [
    {
      className: `sourced-btn ${activeByDefault ? 'sourced-btn-activated' : ''}`,
      activatedClassName: 'sourced-btn-activated',
      name: 'sourced',
      tooltipContent:
        size === 'small' ? 'Sourced' : selectPlanCustomTooltip(selectPlan, sourcedTooltipTitle, CandidatesIcon),
      content: `Sourced`,
      disabled: false,
      tooltipPlacement: 'bottom',
      overlayClassName: 'custom-tooltip',
    },
    {
      className: 'shortlisted-btn',
      activatedClassName: 'shortlisted-btn-activated',
      name: 'shortlisted',
      tooltipContent: selectPlanCustomTooltip(selectPlan, shortlistedTooltipTitle, ThumbsUpIcon),
      content: `Shortlisted`,
      disabled: false,
      tooltipPlacement: 'bottom',
      overlayClassName: 'custom-tooltip',
    },
    {
      className: 'engaged-btn',
      activatedClassName: 'engaged-btn-activated',
      name: 'engaged',
      tooltipContent: selectPlanCustomTooltip(selectPlan, engagedTooltipTitle, EngageIcon),
      content: 'Engaged (Full-Service Only)',
      disabled: false,
      tooltipPlacement: 'bottom',
      overlayClassName: 'custom-tooltip',
    },
  ];

  const rejectButtonContent = (
    <Tooltip placement="top" title={selectPlanTooltip(selectPlan, rejectedTooltipTitle)}>
      <Button className={styles.rejectButton}>
        <>
          <span className={styles.rejectButtonCount}>
            <span className={styles.rejectedCount}>{0}</span>
          </span>
          <Icon type="dislike" theme="filled" className={styles.icon} style={{ color: '#C7D1DB' }} />
        </>
      </Button>
    </Tooltip>
  );

  return (
    <>
      <div className={styles.pipelineWrapper}>
        <Pipeline activePipelineItem={activeTab} size={size}>
          {pipelineData.map(data => (
            <PipelineItem {...data} key={data.name}>
              {data.content}
            </PipelineItem>
          ))}
        </Pipeline>
      </div>
      {rejectButtonContent}
    </>
  );
}

export default SampleCandidatePipeline;
