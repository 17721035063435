import { apiV3Client } from './BaseRepository';

export function fetchJobScoutingAgents(jobId) {
  return apiV3Client.get(`ScoutingAgent/job/${jobId}`);
}

export function getScoutingAgentConfig() {
  return apiV3Client.get('organizations/_config/scoutingagent/settings');
}

export function fetchScoutingAgent(scoutingAgentId) {
  return apiV3Client.get(`ScoutingAgent/${scoutingAgentId}`);
}

export function createScoutingAgent(jobId, payload) {
  return apiV3Client.post(`ScoutingAgent/job/${jobId}/agent`, payload);
}

export function updateScoutingAgent(payload) {
  return apiV3Client.patch(`ScoutingAgent`, payload);
}

export function startScoutingAgent(scoutingAgentId) {
  return apiV3Client.patch(`ScoutingAgent/job/scoutingagent/${scoutingAgentId}/start`);
}

export function stopScoutingAgent(scoutingAgentId) {
  return apiV3Client.patch(`ScoutingAgent/job/scoutingagent/${scoutingAgentId}/stop`);
}

export function deleteScoutingAgent(scoutingAgentId, queryParams) {
  return apiV3Client.delete(`ScoutingAgent/job/ScoutingAgent/${scoutingAgentId}?${queryParams}`);
}

export function getScoutingAgentByIds(agentIds = []) {
  let urlString = 'ScoutingAgent/job?';
  agentIds.forEach((agentId, index) => {
    if (agentId != null || agentId !== 'undefined') {
      urlString += `jobId=${agentId}`;
    }
    if (index < agentIds.length - 1) urlString += '&';
  });
  return apiV3Client.get(urlString);
}
export function getScoutingAgentCandidate(agentIds = [], jobId) {
  const params = new URLSearchParams();

  if (jobId) {
    params.append('jobId', jobId);
  }

  if (agentIds && agentIds.length > 0) {
    agentIds.forEach(agentId => {
      if (agentId != null && agentId !== 'undefined') {
        params.append('jobId', agentId);
      }
    });
  }

  const urlString = `ScoutingAgent/scoutedinfo?${params.toString()}`;
  return apiV3Client.get(urlString);
}

export const getScoutingAgentEnabledUsers = () => {
  return apiV3Client.get('/organizations/_config/scoutingagent/accessconfig');
};

export const saveSourcingAgentUserList = configs => {
  return apiV3Client.put('/organizations/_config/scoutingagent', {
    Configs: configs,
  });
};
