import _ from 'lodash';

const initialState = {
  ById: {},
  FrequentQuestions: {},
  FrequentQuestionGroups: {
    'Job Type': ['W21099', 'ContractDuration', 'JobScope'],
    'Project Details': [
      'TeamSizeTeammembers',
      'RemoteOption',
      'JobTraining',
      'HiringManager',
      'ProjectDepartmentDurationStartendNeworexisting',
    ],
    'Salary Details': [
      'SalaryDetailsBenefits',
      '_401kPlan',
      'SalaryTerm',
      'SalaryDetails',
      'OvertimePay',
      'SalaryNegotiation',
    ],
    'Work Time': ['WorkTime'],
    'Client Details': ['ClientLocation', 'ClientDetailsIndustry', 'ClientDetails'],
    'Joining Details': ['JoiningDetails'],
    'Company Client Relation': ['ClientCompanyDetails'],
    'Interview Response': ['InterviewResponseTime', 'InterviewProcess'],
    'Company Details': ['CompanyDetails'],
    'Employee Benefits': ['EmploymentBenefits', 'Leaves'],
    'Job Details': ['JobTitle', 'JobExperience', 'JobDescription'],
    'Travel Details': ['Travel', 'BusinessTrips', 'VisaSponsorship'],
    'Education Requirements': ['Education'],
  },
};

export function AryabotContextReducer(state = initialState, action) {
  let newState;
  const frequentQuestions = {};
  switch (action.type) {
    case 'SET_FREQUENT_QUESTIONS':
      action.payload.forEach(question => {
        frequentQuestions[question.QuestionKind] = question;
      });
      return {
        ...state,
        FrequentQuestions: frequentQuestions,
      };

    case 'SET_CONTEXT':
      newState = _.cloneDeep(state);
      newState.ById[action.payload.Id] = action.payload;
      return newState;

    case 'SET_JOB_QUESTIONS':
      newState = _.cloneDeep(state);
      _.set(newState, ['ById', action.payload.contextId, 'Questions'], action.payload.questions);
      return newState;

    case 'SET_JOB_ANSWERS':
      newState = _.cloneDeep(state);
      _.set(newState, ['ById', action.payload.contextId, 'Answers'], action.payload.answers);
      return newState;

    default:
      return state;
  }
}

export function getFrequentQuestions(state) {
  return state.AryabotContextReducer.FrequentQuestions;
}

export function getFrequentQuestionGroups(state) {
  return state.AryabotContextReducer.FrequentQuestionGroups;
}

export function getContextById(state) {
  return state.AryabotContextReducer.ById;
}
