export const SET_CANDIDATES_FOR_DEDUPLICATION_BY_SOURCE = 'SET_CANDIDATES_FOR_DEDUPLICATION_BY_SOURCE';
export const SET_CANDIDATE_GROUPS_BY_SOURCE = 'SET_CANDIDATE_GROUPS_BY_SOURCE';
export const SET_CANDIDATE_GROUPS_INFO_BY_SOURCE = 'SET_CANDIDATE_GROUPS_INFO_BY_SOURCE';

export const setCandidatesForDeDuplicationBySource = ({ jobId, candidates, sourceName }) => ({
  type: SET_CANDIDATES_FOR_DEDUPLICATION_BY_SOURCE,
  payload: { jobId, candidates, sourceName },
});

export const setCandidateGroupsBySource = ({ jobId, sourceName, candidateGroupInfo }) => ({
  type: SET_CANDIDATE_GROUPS_BY_SOURCE,
  payload: { jobId, sourceName, candidateGroupInfo },
});

export const setCandidateGroupsInfoBySource = ({ jobId, sourceName, storedCandidatesGroupInfo }) => ({
  type: SET_CANDIDATE_GROUPS_INFO_BY_SOURCE,
  payload: { jobId, sourceName, storedCandidatesGroupInfo },
});
