import React from 'react';
import { Icon, Form } from 'antd';
import _ from 'lodash';
import InputSearch from '../InputSearch/InputSearch';
import './ManualRejectReasons.scss';

const { Item } = Form;

export default function ManualRejectReasons(props) {
  const { selectedReasons, addRejectReason, mismatchTitleText, rejectReasonKey, form, moreReasonsTitleText } = props;
  return (
    <div className="manual-add-reject-reasons-wrapper">
      <div className="manual-add-reject-reason-text">Please add missing {moreReasonsTitleText} to {_.lowerFirst(mismatchTitleText)}</div>
      <Item>
        {form.getFieldDecorator(`${rejectReasonKey}-Input`)(
          <InputSearch
            className="manual-add-reject-reason-input"
            placeholder={`Add ${mismatchTitleText}`}
            enterButton={<Icon type="plus" />}
            onSearch={addRejectReason}
          />
        )}
      </Item>
      <div className="manual-added-reject-reasons"> {selectedReasons}</div>
    </div>
  );
}
