import { getCandidateBucketStatus } from '../../Utils/CandidateListUtils';
import eventTypes from '../EventTypes';

export function appendCandidateStatus(eventName, status) {
  if (status) {
    return `${eventName}:${status.toUpperCase()}`;
  }
  return eventName;
}

export function getEventNameByCandidateStatus(eventName, candidateDetails) {
  const status = getCandidateBucketStatus(candidateDetails);
  return appendCandidateStatus(eventName, status);
}

export function getCandidateActionEventName(key, candidate) {
  switch (key.toLowerCase()) {
    case 'profile':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateView.viewProfile, candidate);
    case 'message':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToSmsWindow, candidate);
    case 'email':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToMailWindow, candidate);
    case 'call':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToCallWindow, candidate);
    case 'bot':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateConnectBot.botConnectCandidate, candidate);
    default:
      return null;
  }
}

export function getCandidateProfileActionEventName(key, candidate) {
  switch (key.toLowerCase()) {
    case 'profile':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidateProfile, candidate);
    case 'personality':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidatePersonality, candidate);
    case 'candidate-intel':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidateIntel, candidate);
    case 'notes':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidateNotes, candidate);
    default:
      return null;
  }
}

export function getCandidateMetadataActionEventName(key, candidate) {
  switch (key.toLowerCase()) {
    case 'candidate-skills':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateSkills, candidate);
    case 'candidate-education':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateEducation, candidate);
    case 'candidate-metadata':
      return getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateMetadataTags,
        candidate
      );
    case 'candidate-tags':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateTags, candidate);
    default:
      return null;
  }
}

export function getCandidate360ProfileActionEventName(key, candidate) {
  switch (key.toLowerCase()) {
    case 'profile':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateResume, candidate);
    case 'candidate-intel':
      return getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateScoreDetails,
        candidate
      );
    case 'work-history':
      return getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateWorkHistory,
        candidate
      );
    case 'jobs':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateJobs, candidate);
    case 'segments':
      return getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateSegmentsAndCampaigns,
        candidate
      );
    case 'activity-logs':
      return getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateActivityLogs,
        candidate
      );
    case 'contact':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateContact, candidate);
    case 'notes':
      return getEventNameByCandidateStatus(eventTypes.candidate.candidate360Profile.goToCandidateNotes, candidate);
    default:
      return null;
  }
}

export function getCandidateNotesEventName(isCallNotes, candidate) {
  if (isCallNotes) return getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.addCallNotes, candidate);
  return getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.addCandidateNotes, candidate);
}
