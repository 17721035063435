import React from 'react';
import { Button, Modal } from 'antd';
import styles from './EmailConfigurationModal.module.scss';
import EmailConfigurationModalContent from './EmailConfigurationModalContent';

function EmailConfigurationModal(props) {
  const {
    addNewEmailAddressFlag,
    currentEmailConfiguration = {},
    setCurrentEmailConfiguration,
    emailProviders,
    userEmails,
    orgDefaultEmail,
    isAdmin,
    emailConfigurationModalVisiblity,
    form,
    submitEmailConfiguration,
    setEmailConfigurationModalVisiblity,
    emailSmtpConfiguration,
    deleteEmailConfig,
  } = props;
  const isDefaultEmailConfiguration = currentEmailConfiguration.EmailId === orgDefaultEmail.EmailId;
  const handleSubmitEmailConfiguration = () => {
    form.validateFieldsAndScroll({ force: true }, err => {
      if (!err) {
        submitEmailConfiguration(addNewEmailAddressFlag, currentEmailConfiguration, isDefaultEmailConfiguration);
        setEmailConfigurationModalVisiblity(false);
      }
    });
  };
  const editPopupHeader =
    currentEmailConfiguration.EmailId === orgDefaultEmail.EmailId ? 'Edit Default Email' : 'Edit Custom Email';
  return (
    <Modal
        title={addNewEmailAddressFlag ? 'Add New Email' : editPopupHeader}
        width={880}
        visible={emailConfigurationModalVisiblity}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => {
              setEmailConfigurationModalVisiblity(false);
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" shape="round" onClick={handleSubmitEmailConfiguration}>
            Confirm
          </Button>,
        ]}
        onCancel={() => {
          setEmailConfigurationModalVisiblity(false);
        }}
        className={styles.emailConfigurationModal}
        bodyStyle={{ padding: '0px 24px' }}
        maskClosable={false}
        destroyOnClose
      >
        <EmailConfigurationModalContent
          userEmails={userEmails}
          orgDefaultEmail={orgDefaultEmail}
          form={form}
          currentEmailConfiguration={currentEmailConfiguration}
          setCurrentEmailConfiguration={setCurrentEmailConfiguration}
          isAdmin={isAdmin}
          emailProviders={emailProviders}
          emailSmtpConfiguration={emailSmtpConfiguration}
          addNewEmailAddressFlag={addNewEmailAddressFlag}
          deleteEmailConfig={deleteEmailConfig}
          isDefaultEmailConfiguration={isDefaultEmailConfiguration}
        />
      </Modal>
  );
}

export default EmailConfigurationModal;
