import { aryaNotifyV1Client } from './BaseRepository';

export function fetchNotifications(notificationRequest) {
  return aryaNotifyV1Client.post(`notifications/_search`, notificationRequest);
}

export function markNotificationAsRead(payload) {
  return aryaNotifyV1Client.post(`notifications/_read`, payload);
}

export function markNotificationsAsViewed(notificationRequest) {
  return aryaNotifyV1Client.post(`notifications/_view`, notificationRequest);
}

export function markSingleNotificationAsRead(id, payload) {
  return aryaNotifyV1Client.post(`notifications/${id}/_read`, payload);
}
