import { actionTypes } from '../../../Utils/WorkflowUtils';
import * as Node from './Nodes';

export const nodeTypes = {
  Start: Node.Start,
  Action: Node.Action,
  [actionTypes.SendEmail]: Node.Action,
  [actionTypes.SendSMS]: Node.Action,
  [actionTypes.AddTag]: Node.Action,
  [actionTypes.AddCustomField]: Node.Action,
  [actionTypes.Decision]: Node.Condition,
  [actionTypes.Delay]: Node.Action,
  [actionTypes.End]: Node.End,
  Empty: Node.Empty,
  Merge: Node.Empty,
};
