/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

function ChatConversationsReducer(state = {}, action) {
  let newState;
  let messages;
  switch (action.type) {
    case 'ADD_CHAT_MESSAGE':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      messages = _.get(newState, ['ById', action.payload.conversationId], []);
      messages.unshift(action.payload.Message);
      newState.ById[action.payload.conversationId] = messages;
      return newState;
    case 'ADD_CHAT_MESSAGES':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      newState.ById[action.payload.conversationId] = action.payload.Messages;
      return newState;
    default:
      return state;
  }
}

function getMessages(state, conversationId) {
  return _.get(state.ChatConversationsReducer.ById, [conversationId], null);
}

function getBotContextId(state, conversationId) {
  const messages = _.get(state.ChatConversationsReducer.ById, [conversationId], null);
  if (messages && messages.length) return messages[0].BotContextId;
  return null;
}

export { ChatConversationsReducer, getBotContextId, getMessages };
