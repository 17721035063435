import React from 'react';
import { Input } from 'antd';
import styles from './CandidateSegmentsAndCampaignsHeader.module.scss';
import CandidateSegmentsAndCampaignsFilter from '../CandidateSegmentsAndCampaignsFilter/CandidateSegmentsAndCampaignsFilter';

function CandidateSegmentsAndCampaignsHeader(props) {
  const {
    segments,
    candidateId,
    fetchCandidateSegmentsAndCampaigns,
    segmentAggregation,
    onSearchChange,
    onSearch,
    searchTerm,
    selectedCreatedByFilterOptions,
    setSelectedCreatedByFilterOptions,
    isCandidateViewHeaderVisible,
    isFilterPopoverVisible,
    setFilterPopoverVisbility,
  } = props;
  return (
    <div className={styles.listsAndCampaignsTabsHeader}>
      <div className={styles.searchAndFilter}>
        <Input.Search
          size="default"
          placeholder="Search"
          allowClear
          className={styles.listsAndCampaignsInputSearch}
          onSearch={onSearch}
          onChange={onSearchChange}
          value={searchTerm}
        />
        <CandidateSegmentsAndCampaignsFilter
          segments={segments}
          candidateId={candidateId}
          fetchCandidateSegmentsAndCampaigns={fetchCandidateSegmentsAndCampaigns}
          segmentAggregation={segmentAggregation}
          selectedCreatedByFilterOptions={selectedCreatedByFilterOptions}
          setSelectedCreatedByFilterOptions={setSelectedCreatedByFilterOptions}
          searchTerm={searchTerm}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          isFilterPopoverVisible={isFilterPopoverVisible}
          setFilterPopoverVisbility={setFilterPopoverVisbility}
        />
      </div>
    </div>
  );
}

export default CandidateSegmentsAndCampaignsHeader;
