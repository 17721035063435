import React from 'react';
import { Icon, Tooltip } from 'antd';
import { getTooltipContentForDisabledLocation } from '../../../Utils/MultiLocationUtils';
import MultiLocationContext from '../MultiLocationContext';

const AddLocationButton = () => {
  const { isLocationSelectionLimitReached, addLocation, isCountryWideSearchEnabledCountry } =
    React.useContext(MultiLocationContext);
  const isAddLocationDisabled = isLocationSelectionLimitReached || isCountryWideSearchEnabledCountry;
  const onAddLocation = () => {
    if (!isAddLocationDisabled) addLocation();
  };
  const icon = (
    <Icon
      type="plus-circle"
      style={{
        fontSize: '20px',
        color: isAddLocationDisabled ? '#bfbfbf' : '#13c26b',
        cursor: isAddLocationDisabled ? 'not-allowed' : 'pointer',
      }}
      onClick={onAddLocation}
    />
  );
  return isAddLocationDisabled ? (
    <Tooltip
      title={getTooltipContentForDisabledLocation({
        isLocationSelectionLimitReached,
        isCountryWideSearchEnabledCountry,
      })}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};

export default AddLocationButton;
