import React from 'react';
import { AddButton } from '../AddButton/AddButton';
import './EdgeAddButton.scss';
import WorkflowNodePopover from '../../../../Components/WorkflowNodePopover/WorkflowNodePopover';

const EdgeAddButton = props => {
  const { style, data, id, label } = props;
  const { isFirstEdge } = data;

  const onSaveCallback = payload => {
    const { type, properties } = payload;
    data.onAddNodeCallback({ id, type, properties });
  };

  return (
    <div className="EdgeAddButton" style={style}>
      {label}
      <WorkflowNodePopover onSaveCallback={onSaveCallback} isFirstEdge={isFirstEdge}>
        <AddButton {...props} />
      </WorkflowNodePopover>
    </div>
  );
};

export default EdgeAddButton;
