import React from 'react';
import { Select } from 'antd';
import styles from './CandidateAdvanceFilters.module.scss';

const { Option } = Select;

const CandidateAdvanceFilterFields = props => {
  const { filterId, addFilterField, inputList, filterFields, component } = props;

  const handleChange = (value, option) => {
    addFilterField(filterId, option);
  };

  const generateOptions = () => {
    if (component === 'include') {
      if (inputList[0]?.AttributeName) {
        const hasLocation = inputList.some(inputField => inputField?.AttributeId === 5);
        return filterFields.fields.Fields.filter(field => {
          const matchingInputField = inputList.find(inputField => inputField?.AttributeName === field.UIColumnName);
          if (matchingInputField) {
            return field.IsRepetitive;
          }
          return field.ColumnId !== 8 || hasLocation;
        }).filter(field => field?.IsVisible !== false);
      }
      return filterFields?.fields?.Fields.filter(field => field?.ColumnId !== 8).filter(
        field => field.IsVisible !== false
      );
    }
    if (inputList[0]?.AttributeName) {
      const hasLocation = inputList.some(inputField => inputField?.AttributeId === 5);
      return filterFields.fields.Fields.filter(field => {
        const matchingInputField = inputList.find(inputField => inputField?.AttributeName === field.UIColumnName);
        if (matchingInputField) {
          return field.IsRepetitive;
        }
        return (field.ColumnId !== 8 && field.ColumnId !== 29) || hasLocation;
      }).filter(field => field?.IsVisible !== false);
    }
    return filterFields?.fields?.Fields.filter(field => field?.ColumnId !== 8 && field?.ColumnId !== 29).filter(
      field => field.IsVisible !== false
    );
  };

  const options = generateOptions();

  return (
    <Select
      placeholder="FieldName"
      style={{ width: '100%' }}
      showSearch
      dropdownStyle={{ zIndex: 2200 }}
      onChange={handleChange}
      showArrow={false}
      dropdownClassName={styles.filterFieldsDropDown}
      value={inputList[filterId]?.AttributeName}
      getPopupContainer={trigger => trigger.parentNode}
      data-testid="advance-filter-fields"
    >
      {options?.map(option => (
        <Option
          IsRepetitive={option.IsRepetitive}
          QueryOperator={option.QueryOperator}
          AttributeId={option.ColumnId}
          DataType={option.DataType}
          key={option.SelectionType}
          value={option.UIColumnName}
          title={option.UIColumnName}
        >
          {option.UIColumnName}
        </Option>
      ))}
    </Select>
  );
};

export default CandidateAdvanceFilterFields;
