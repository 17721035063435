import React from 'react';
import { Form, Select } from 'antd';
import { purifySelectedOptions } from '../../Utils/SelectComponentUtility';

const { Option } = Select;

export default function SkillsSelection(props) {
  const {
    form,
    skillsList,
    jobtitle,
    initialValue = [],
    formLabel = 'Please add top skills relevant to this role',
  } = props;
  const { getFieldDecorator } = form;

  const getSkillsSelect = skills => {
    const selectAllThatApply = 'Select all that apply';
    const skillsMenuList = skills.map((skill, index) => {
      return (
        <Option value={skill} key={`${skill}${index.toString()}`}>
          {skill}
        </Option>
      );
    });
    return (
      <Select
        mode="tags"
        size="middle"
        key="Skill"
        placeholder="Ex: Java, C++, C#"
        disabled={!jobtitle}
        onSelect={() => purifySelectedOptions(form, 'skills')}
        tokenSeparators={[',']}
      >
        {skills?.length > 0 ? (
          <Option
            disabled
            className="skillsDropdownHeader"
            value={selectAllThatApply}
            key={`${selectAllThatApply}header`}
          >
            {selectAllThatApply}
          </Option>
        ) : null}
        {skillsMenuList}
      </Select>
    );
  };

  const skillsMenu = skillsList?.map(skill => skill.description) ?? [];

  return (
    <div>
      <Form.Item label={formLabel}>
        {getFieldDecorator('skills', {
          initialValue,
        })(getSkillsSelect(skillsMenu))}
      </Form.Item>
    </div>
  );
}
