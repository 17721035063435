import _ from 'lodash';

const emptyObject = {};
const emptyList = [];

function ConnectSettingsReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'SET_EMAIL_CONFIG': {
      newState = _.cloneDeep(state);
      const { providers, isAdmin } = action.payload;
      if (isAdmin) {
        newState.AdminEmailProviders = providers;
      } else newState.UserEmailProviders = providers;
      return newState;
    }

    case 'UPDATE_EMAIL_CONFIG': {
      newState = _.cloneDeep(state);
      const { isAdmin, newConfig } = action.payload;
      const providers = isAdmin ? newState.AdminEmailProviders : newState.UserEmailProviders;
      const { Id } = newConfig;
      const providerIndex = _.findIndex(providers, provider => provider.Id === Id);
      if (providerIndex === -1) {
        providers.push(newConfig);
      } else {
        providers[providerIndex] = { ...providers[providerIndex], ...newConfig };
      }
      return newState;
    }

    case 'SET_EMAIL_SMTP_SETTINGS': {
      newState = _.cloneDeep(state);
      newState.EmailSmtpSettings = action.payload;
      return newState;
    }

    case 'DELETE_EMAIL_CONFIG': {
      newState = _.cloneDeep(state);
      const { providerId } = action.payload;
      if (action.isAdmin) {
        newState.AdminEmailProviders = newState.AdminEmailProviders.filter(provider => provider.Id !== providerId);
      } else {
        newState.UserEmailProviders = newState.UserEmailProviders.filter(provider => provider.Id !== providerId);
      }
      return newState;
    }

    case 'SET_CALLER_IDS':
      newState = _.cloneDeep(state);
      newState.CallerIds = action.payload;
      return newState;
    case 'SET_CONTACT_TYPE':
      newState = _.cloneDeep(state);
      if (!newState.ContactSettings) {
        newState.ContactSettings = {};
      }
      newState.ContactSettings.ContactType = action.payload;
      return newState;
    case 'SET_BOT_DETAILS':
      newState = _.cloneDeep(state);
      newState.BotSettings = action.payload;
      return newState;
    case 'SET_CONTACT_PROVIDERS':
      newState = _.cloneDeep(state);
      if (!newState.ContactSettings) {
        newState.ContactSettings = {};
      }
      newState.ContactSettings.ServiceProviders = action.payload.filter(provider => provider.Type === 'Contact');
      return newState;
    case 'UPDATE_CONTACT_PROVIDERS':
      newState = _.cloneDeep(state);
      newState.ContactSettings.ServiceProviders.forEach(contactProvider => {
        const contactProviderCopy = contactProvider;
        const providerDetails = action.payload.find(provider => provider.ProviderId === contactProvider.Id);
        if (providerDetails) {
          contactProviderCopy.Order = providerDetails.Order;
          contactProviderCopy.IsSubscribed = true;
        } else {
          contactProviderCopy.IsSubscribed = false;
        }
      });
      return newState;

    case 'UPDATE_TESTED_EMAIL_STATUS': {
      let targetEmailProviders;
      newState = _.cloneDeep(state);
      const { providerId, isAdmin, emailResponse, actionType } = action.payload;
      if (isAdmin) targetEmailProviders = newState.AdminEmailProviders;
      else targetEmailProviders = newState.UserEmailProviders;
      const targetEmailConfiguration = targetEmailProviders.find(
        targetEmailProvider => targetEmailProvider.Id === providerId
      );
      let exisitingEmails = targetEmailConfiguration.Emails || [];
      const targetedEmail = exisitingEmails.find(email => email.Email.EmailId === emailResponse.Email.EmailId);
      if (actionType === 'delete') {
        exisitingEmails = exisitingEmails.filter(email => email.Email.EmailId !== emailResponse.Email.EmailId);
        targetEmailConfiguration.Emails = exisitingEmails;
      } else if (targetedEmail) {
        targetedEmail.IsTestEmailSuccessfull = emailResponse.IsTestEmailSuccessfull;
        targetedEmail.Message = emailResponse.Message;
      } else {
        const updatedEmail = {
          Email: { EmailId: emailResponse.Email.EmailId },
          IsTestEmailSuccessfull: emailResponse.IsTestEmailSuccessfull,
          Message: emailResponse.Message,
        };
        if (!exisitingEmails.length) targetEmailConfiguration.Emails = [updatedEmail];
        else {
          exisitingEmails = exisitingEmails.concat(updatedEmail);
          targetEmailConfiguration.Emails = exisitingEmails;
        }
      }
      return newState;
    }

    case 'SET_ORG_DEFAULT_FROM_EMAIL': {
      newState = _.cloneDeep(state);
      [newState.OrgDefaultEmail] = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

function getConnectSettings(state) {
  return state.ConnectSettingsReducer;
}

function getCallerIds(state) {
  return state.ConnectSettingsReducer.CallerIds || emptyList;
}

function getEmailSmtpConfiguration(state) {
  return state.ConnectSettingsReducer.EmailSmtpSettings || emptyObject;
}

function getOrgDefaultEmail(state) {
  return state.ConnectSettingsReducer.OrgDefaultEmail || emptyObject;
}

export { ConnectSettingsReducer, getConnectSettings, getCallerIds, getEmailSmtpConfiguration, getOrgDefaultEmail };
