import React from 'react';
import classNames from 'classnames';
import CandidateSegmentsAndCampaignsHeader from '../CandidateSegmentsAndCampaignsHeader/CandidateSegmentsAndCampaignsHeader';
import CandidateSegmentsAndCampaigns from './CandidateSegmentsAndCampaigns';
import styles from './CandidateSegmentsAndCampaignsWrapper.module.scss';

function CandidateSegmentsAndCampaignsWrapper(props) {
  const {
    segments,
    candidate,
    fetchCandidateSegmentsAndCampaigns,
    onSearchChange,
    onSearch,
    searchTerm,
    segmentAggregation,
    selectedCreatedByFilterOptions,
    setSelectedCreatedByFilterOptions,
    appendCandidateSegmentsAndCampaigns,
    segmentsTotalCount,
    fetchJobAutomationConfiguration,
    associatedTemplateName,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
    openSegmentAtsView,
  } = props;
  const ref = React.useRef(null);
  const [isFilterPopoverVisible, setFilterPopoverVisbility] = React.useState(false);
  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else {
        setFilterPopoverVisbility(false);
        setCandidateViewHeaderVisibility(false);
      }
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      className={classNames(styles.candidateSegmentsAndCampaigns, {
        [styles.extendView]: !isCandidateViewHeaderVisible,
      })}
      ref={ref}
    >
      <div>
        <CandidateSegmentsAndCampaignsHeader
          segments={segments}
          candidateId={candidate.Id}
          fetchCandidateSegmentsAndCampaigns={fetchCandidateSegmentsAndCampaigns}
          onSearchChange={onSearchChange}
          onSearch={onSearch}
          searchTerm={searchTerm}
          segmentAggregation={segmentAggregation}
          selectedCreatedByFilterOptions={selectedCreatedByFilterOptions}
          setSelectedCreatedByFilterOptions={setSelectedCreatedByFilterOptions}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          isFilterPopoverVisible={isFilterPopoverVisible}
          setFilterPopoverVisbility={setFilterPopoverVisbility}
        />
        <CandidateSegmentsAndCampaigns
          candidateId={candidate.Id}
          appendCandidateSegmentsAndCampaigns={appendCandidateSegmentsAndCampaigns}
          segments={segments}
          segmentsTotalCount={segmentsTotalCount}
          fetchJobAutomationConfiguration={fetchJobAutomationConfiguration}
          associatedTemplateName={associatedTemplateName}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          selectedCreatedByFilterOptions={selectedCreatedByFilterOptions}
          version={version}
          openSegmentAtsView={openSegmentAtsView}
        />
      </div>
    </div>
  );
}

export default CandidateSegmentsAndCampaignsWrapper;
