import React from 'react';
import { Icon } from 'antd';

export const AdvanceJobHeaderSvg = props => (
  <svg width="160px" height="106px" viewBox="0 0 160 106" {...props}>
    <defs>
      <polygon id="path-1" points="0 105.903676 160 105.903676 160 0 0 0" />
    </defs>
    <g id="New-User" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="New-User_Search-10-Copy-3" transform="translate(-100.000000, -150.000000)">
        <g id="Group-38-Copy-12" transform="translate(0.000000, 64.000000)">
          <g id="Group-89-Copy" transform="translate(100.000000, 86.000000)">
            <polygon
              id="Fill-1"
              fill="#898989"
              points="0 93.7066494 48.9180279 93.7066494 48.9180279 13.5270878 0 13.5270878"
            />
            <polygon
              id="Fill-2"
              fill="#FFFFFF"
              points="2.56185904 90.6505279 47.3098594 90.6505279 47.3098594 15.1607864 2.56185904 15.1607864"
            />
            <g id="Group-88" transform="translate(0.000000, -0.000000)">
              <path
                d="M20.7656615,31.0169896 C20.734458,30.9232658 19.9510815,26.4265666 19.8573577,24.6779259 C19.7637474,22.9291717 22.6678231,22.6794308 22.6678231,22.6794308 C22.6678231,22.6794308 25.0410999,21.3990686 26.7585372,22.0547947 C28.4760879,22.7106342 29.644912,23.8139878 30.4255653,25.1879603 C31.2062186,26.5619328 29.7387492,30.8439523 29.7387492,30.8439523 L20.7656615,31.0169896 Z"
                id="Fill-3"
                fill="#2E2D3D"
              />
              <path
                d="M15.3815705,45.4646469 C15.3706777,45.3908933 15.5821803,43.6211477 15.5896691,43.5494365 C15.9852152,39.8229517 20.5785883,38.77497 25.1622033,38.77497 C30.5475764,38.77497 34.2009884,39.7605448 34.7839821,43.6327213 C34.8010022,43.7460749 35.111675,45.5202457 35.0962435,45.639613 C25.115001,45.7764543 15.3815705,45.4646469 15.3815705,45.4646469"
                id="Fill-5"
                fill="#D5E1E8"
              />
              <path
                d="M26.0869824,41.6673245 C25.5533469,42.4550128 25.162226,42.4876913 24.5829767,41.6673245 C24.1225274,41.0150025 22.584595,39.5269956 22.584595,38.774936 L23.2156987,31.2760121 C23.2156987,30.5239525 23.8310305,29.9087342 24.5829767,29.9087342 L26.0869824,29.9087342 C26.8389286,29.9087342 27.4542604,30.5239525 27.4542604,31.2760121 L27.7474592,38.774936 C27.7474592,39.5269956 26.5655864,40.9608787 26.0869824,41.6673245"
                id="Fill-7"
                fill="#F09959"
              />
              <path
                d="M26.0869824,29.9086888 L24.5829767,29.9086888 C23.8310305,29.9086888 23.2156987,30.5239072 23.2156987,31.2759668 L22.6678798,37.9285399 C24.3566099,38.4661468 26.6369572,37.3734591 27.6455658,36.1693467 L27.4542604,31.2759668 C27.4542604,30.5239072 26.8389286,29.9086888 26.0869824,29.9086888"
                id="Fill-9"
                fill="#E38540"
              />
              <path
                d="M20.1734996,39.321745 L22.584561,43.7783903 C22.584561,43.7783903 23.8753621,42.7166792 24.0001759,42.9248912 C24.1251031,43.1329898 23.7711994,45.7602058 23.7711994,45.7602058 C23.7711994,45.7602058 26.6440716,45.7992385 26.7689988,45.6743113 C26.893926,45.5494975 26.1861186,42.8207284 26.4776154,42.8207284 C26.7689988,42.8207284 27.6455318,43.6119342 27.6455318,43.6119342 L30.3377645,39.321745 L29.7459203,39.1776419 L25.3350022,41.5509187 L20.6172693,39.2296098 L20.1734996,39.321745 Z"
                id="Fill-11"
                fill="#BDCCD4"
              />
              <polygon
                id="Fill-13"
                fill="#D54A41"
                points="22.9308964 41.3752377 24.7215199 42.5768537 24.1667795 45.7659586 26.5815853 45.6312732 25.9785079 42.7303746 27.4626569 41.3752377 26.7654019 40.71815 23.6281515 40.71815"
              />
              <path
                d="M22.7094541,37.1374476 L20.4946908,39.2449846 C20.4946908,39.2449846 22.7510966,43.2579737 22.8760238,43.2579737 C23.000951,43.2579737 24.7907802,41.0453663 24.7907802,41.0453663 L25.9369109,41.0253961 L27.7473911,42.9248345 L29.8511837,39.2032287 L27.7058622,36.929349 C27.7058622,36.929349 25.8738232,40.4682728 25.540684,40.5515577 C25.2076582,40.6348425 25.2053888,40.6764849 24.9359046,40.4682728 C24.6664203,40.2601743 22.7094541,37.1374476 22.7094541,37.1374476"
                id="Fill-15"
                fill="#D5E1E8"
              />
              <path
                d="M29.8811957,29.9847004 C29.8811957,33.4058454 27.8458238,36.179207 25.3350249,36.179207 C22.8242261,36.179207 20.7887407,33.4058454 20.7887407,29.9847004 C20.7887407,26.5635554 22.8242261,23.7900803 25.3350249,23.7900803 C27.8458238,23.7900803 29.8811957,26.5635554 29.8811957,29.9847004"
                id="Fill-17"
                fill="#F09959"
              />
              <path
                d="M21.2542165,29.8891838 C21.6793776,30.7844388 21.540267,31.7400583 20.9434303,32.023499 C20.3465936,32.3069397 19.5180572,31.8109752 19.0927827,30.9157202 C18.6676217,30.0204652 18.8068458,29.0649592 19.403569,28.7815185 C20.0004056,28.4979643 20.828942,28.9939288 21.2542165,29.8891838"
                id="Fill-19"
                fill="#F09959"
              />
              <path
                d="M29.2106963,29.8891838 C28.7854218,30.7844388 28.9246458,31.7400583 29.5214825,32.023499 C30.1183192,32.3069397 30.9468556,31.8109752 31.3720166,30.9157202 C31.7971776,30.0204652 31.658067,29.0649592 31.0613438,28.7815185 C30.4643937,28.4979643 29.6359708,28.9939288 29.2106963,29.8891838"
                id="Fill-21"
                fill="#F09959"
              />
              <path
                d="M22.6678458,25.7708519 C24.5415272,24.4592862 25.3845873,24.6779373 26.7897633,26.8325631 C28.1949392,28.9871889 29.8811731,29.0252003 29.8811731,29.0252003 L30.0999375,26.7909207 C30.0999375,26.7909207 28.0068109,22.741849 25.1967994,23.2726478 C22.3867879,23.8035602 20.788718,26.3329676 20.788718,26.3329676 C20.788718,26.3329676 20.6519902,31.0738479 21.0440188,31.2355384 C21.436161,31.397229 21.4812074,29.7054353 21.7622653,29.3619705 C22.0433231,29.0185058 21.4812074,27.3946788 21.855989,26.645229 C22.2306572,25.8957791 22.6678458,25.7708519 22.6678458,25.7708519"
                id="Fill-23"
                fill="#2E2D3D"
              />
              <path
                d="M28.6946141,28.2065696 C29.3487516,28.9227736 29.1005992,31.7664848 29.4440639,31.7351679 C29.7875287,31.7039645 29.9124559,29.0184263 29.8188456,28.7373685 C29.7251218,28.456424 28.6946141,28.2065696 28.6946141,28.2065696"
                id="Fill-25"
                fill="#2E2D3D"
              />
              <path
                d="M35.4475148,55.2592133 L14.5652659,55.2592133 C13.8310206,55.2592133 13.2303261,54.6584053 13.2303261,53.92416 C13.2303261,53.1899148 13.8310206,52.5892202 14.5652659,52.5892202 L35.4475148,52.5892202 C36.1816466,52.5892202 36.7824546,53.1899148 36.7824546,53.92416 C36.7824546,54.6584053 36.1816466,55.2592133 35.4475148,55.2592133"
                id="Fill-27"
                fill="#C6C6C6"
              />
              <path
                d="M32.848847,59.3607635 L17.1639563,59.3607635 C16.4297111,59.3607635 15.828903,58.760069 15.828903,58.0257102 C15.828903,57.2916919 16.4297111,56.6908838 17.1639563,56.6908838 L32.848847,56.6908838 C33.5829788,56.6908838 34.1837868,57.2916919 34.1837868,58.0257102 C34.1837868,58.760069 33.5829788,59.3607635 32.848847,59.3607635"
                id="Fill-29"
                fill="#C6C6C6"
              />
              <path
                d="M44.0830267,74.111809 L5.92973127,74.111809 C5.5036625,74.111809 5.15497826,73.7633517 5.15497826,73.3371695 C5.15497826,72.9111007 5.5036625,72.5625299 5.92973127,72.5625299 L44.0830267,72.5625299 C44.5090954,72.5625299 44.8576662,72.9111007 44.8576662,73.3371695 C44.8576662,73.7633517 44.5090954,74.111809 44.0830267,74.111809"
                id="Fill-31"
                fill="#C6C6C6"
              />
              <path
                d="M44.0830267,77.7119483 L5.92973127,77.7119483 C5.5036625,77.7119483 5.15497826,77.363491 5.15497826,76.9374222 C5.15497826,76.5113534 5.5036625,76.1627827 5.92973127,76.1627827 L44.0830267,76.1627827 C44.5090954,76.1627827 44.8576662,76.5113534 44.8576662,76.9374222 C44.8576662,77.363491 44.5090954,77.7119483 44.0830267,77.7119483"
                id="Fill-33"
                fill="#C6C6C6"
              />
              <path
                d="M44.0830267,81.3123145 L5.92973127,81.3123145 C5.5036625,81.3123145 5.15497826,80.9637437 5.15497826,80.5375615 C5.15497826,80.1114927 5.5036625,79.7629219 5.92973127,79.7629219 L44.0830267,79.7629219 C44.5090954,79.7629219 44.8576662,80.1114927 44.8576662,80.5375615 C44.8576662,80.9637437 44.5090954,81.3123145 44.0830267,81.3123145"
                id="Fill-35"
                fill="#C6C6C6"
              />
              <path
                d="M11.2658937,19.2038897 C11.0407751,19.2038897 10.8574123,19.3871389 10.8574123,19.612371 L10.8574123,44.939805 C10.8574123,45.1650371 11.0407751,45.3482864 11.2658937,45.3482864 L38.7468188,45.3482864 C38.9719375,45.3482864 39.1551868,45.1650371 39.1551868,44.939805 L39.1551868,19.612371 C39.1551868,19.3871389 38.9719375,19.2038897 38.7468188,19.2038897 L11.2658937,19.2038897 Z M38.7468188,46.1721706 L11.2658937,46.1721706 C10.586453,46.1721706 10.0336416,45.6193592 10.0336416,44.939805 L10.0336416,19.612371 C10.0336416,18.9328169 10.586453,18.3801189 11.2658937,18.3801189 L38.7468188,18.3801189 C39.4262595,18.3801189 39.979071,18.9328169 39.979071,19.612371 L39.979071,44.939805 C39.979071,45.6193592 39.4262595,46.1721706 38.7468188,46.1721706 L38.7468188,46.1721706 Z"
                id="Fill-37"
                fill="#898989"
              />
              <polygon
                id="Fill-39"
                fill="#C6C6C6"
                points="11.9213815 62.8434417 12.7170125 64.4555816 14.4960623 64.713946 13.2087787 65.9688916 13.5126434 67.7406796 11.9213815 66.9042005 10.3302331 67.7406796 10.6340978 65.9688916 9.34681416 64.713946 11.125864 64.4555816"
              />
              <polygon
                id="Fill-41"
                fill="#C6C6C6"
                points="18.4379757 62.8434417 19.2336067 64.4555816 21.0126565 64.713946 19.7253729 65.9688916 20.0292376 67.7406796 18.4379757 66.9042005 16.8468273 67.7406796 17.150692 65.9688916 15.8634084 64.713946 17.6423448 64.4555816"
              />
              <polygon
                id="Fill-43"
                fill="#C6C6C6"
                points="24.9545813 62.8434417 25.7502122 64.4555816 27.5292621 64.713946 26.2419784 65.9688916 26.5458432 67.7406796 24.9545813 66.9042005 23.3634328 67.7406796 23.6672976 65.9688916 22.3800139 64.713946 24.1589503 64.4555816"
              />
              <polygon
                id="Fill-45"
                fill="#C6C6C6"
                points="31.4711755 62.8434417 32.2668064 64.4555816 34.0458563 64.713946 32.7585726 65.9688916 33.0624374 67.7406796 31.4711755 66.9042005 29.880027 67.7406796 30.1838918 65.9688916 28.8966081 64.713946 30.6755445 64.4555816"
              />
              <polygon
                id="Fill-47"
                fill="#C6C6C6"
                points="38.091331 62.8434417 38.886962 64.4555816 40.6658984 64.713946 39.3786147 65.9688916 39.6824795 67.7406796 38.091331 66.9042005 36.5001826 67.7406796 36.8039339 65.9688916 35.5166502 64.713946 37.2957001 64.4555816"
              />
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-50" />
              <polygon
                id="Fill-49"
                fill="#898989"
                mask="url(#mask-2)"
                points="110.670881 93.8130815 160 93.8130815 160 12.9596391 110.670881 12.9596391"
              />
              <polygon
                id="Fill-51"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                points="113.027251 91.2988786 158.151395 91.2988786 158.151395 15.1745159 113.027251 15.1745159"
              />
              <path
                d="M128.667617,31.6001194 C128.667617,35.2216472 130.848227,41.0647464 135.991915,40.8772989 C141.135604,40.6897378 142.273224,35.1199808 142.472359,33.5481217 C142.671607,31.9762627 128.667617,31.6001194 128.667617,31.6001194"
                id="Fill-52"
                fill="#7A4E34"
                mask="url(#mask-2)"
              />
              <path
                d="M126.52427,45.7004427 C128.222759,42.0466903 131.097219,40.8765046 135.817562,40.8765046 C141.438039,40.8765046 144.356411,42.9125573 145.183586,45.876884 C135.118605,46.0148599 126.52427,45.7004427 126.52427,45.7004427"
                id="Fill-53"
                fill="#D54A41"
                mask="url(#mask-2)"
              />
              <path
                d="M136.750148,43.7931184 C136.212087,44.5875012 135.817562,44.6205201 135.233547,43.7931184 C134.769126,43.1354633 133.854696,41.6409888 133.854696,40.8826885 L133.854696,33.3144363 C133.854696,32.556136 134.475133,31.9358117 135.233547,31.9358117 L136.750148,31.9358117 C137.508561,31.9358117 138.128886,32.556136 138.128886,33.3144363 L138.128886,40.8826885 C138.128886,41.6409888 137.232723,43.0807722 136.750148,43.7931184"
                id="Fill-54"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M136.750148,31.935755 L135.233547,31.935755 C134.475133,31.935755 133.854696,32.5561928 133.854696,33.3144931 L133.854696,40.0670307 C135.557609,40.6091762 137.111881,39.5314662 138.128886,38.3171418 L138.128886,33.3144931 C138.128886,32.5561928 137.508561,31.935755 136.750148,31.935755"
                id="Fill-55"
                fill="#E38540"
                mask="url(#mask-2)"
              />
              <path
                d="M140.576223,32.0123793 C140.576223,35.4622314 138.523717,38.2589673 135.991927,38.2589673 C133.45991,38.2589673 131.407404,35.4622314 131.407404,32.0123793 C131.407404,28.5625271 133.45991,25.7656778 135.991927,25.7656778 C138.523717,25.7656778 140.576223,28.5625271 140.576223,32.0123793"
                id="Fill-56"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M138.511769,27.2643165 C137.9669,29.1854271 136.773113,29.1630741 135.108552,29.7939509 C133.44399,30.4248277 132.648246,31.0595624 132.10349,32.288524 C131.408845,33.855504 132.418929,38.4245951 132.418929,38.4245951 C132.418929,38.4245951 128.203855,35.2705515 128.691309,30.3101125 C129.178764,25.3495601 132.10349,23.4285629 134.856088,23.4285629 C137.608685,23.4285629 138.655305,25.1059463 138.655305,25.1059463 C138.655305,25.1059463 140.12164,25.1120735 141.508207,26.7546225 C143.372017,28.9624644 142.798554,34.3243498 141.508207,36.245347 C140.217973,38.166571 139.357779,38.7686272 139.386486,38.6826192 C139.415194,38.5967246 140.590712,34.1848988 139.959949,31.4570375 C139.329072,28.7290627 138.511769,27.2643165 138.511769,27.2643165"
                id="Fill-57"
                fill="#7A4E34"
                mask="url(#mask-2)"
              />
              <path
                d="M141.449056,55.6099173 L129.8719,55.6099173 C129.131414,55.6099173 128.525613,55.0041167 128.525613,54.2637442 C128.525613,53.5232583 129.131414,52.9174577 129.8719,52.9174577 L141.449056,52.9174577 C142.189429,52.9174577 142.79523,53.5232583 142.79523,54.2637442 C142.79523,55.0041167 142.189429,55.6099173 141.449056,55.6099173"
                id="Fill-58"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M143.568814,59.7459274 L127.752074,59.7459274 C127.011702,59.7459274 126.405901,59.1401268 126.405901,58.3998678 C126.405901,57.6593818 127.011702,57.0535813 127.752074,57.0535813 L143.568814,57.0535813 C144.309186,57.0535813 144.914987,57.6593818 144.914987,58.3998678 C144.914987,59.1401268 144.309186,59.7459274 143.568814,59.7459274"
                id="Fill-59"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M154.897568,74.6209697 L116.423502,74.6209697 C115.993802,74.6209697 115.642281,74.2694488 115.642281,73.8398625 C115.642281,73.4101628 115.993802,73.0586419 116.423502,73.0586419 L154.897568,73.0586419 C155.327155,73.0586419 155.678675,73.4101628 155.678675,73.8398625 C155.678675,74.2694488 155.327155,74.6209697 154.897568,74.6209697"
                id="Fill-60"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M154.897568,78.2514728 L116.423502,78.2514728 C115.993802,78.2514728 115.642281,77.8999518 115.642281,77.4703656 C115.642281,77.0406659 115.993802,76.689145 116.423502,76.689145 L154.897568,76.689145 C155.327155,76.689145 155.678675,77.0406659 155.678675,77.4702521 C155.678675,77.8999518 155.327155,78.2514728 154.897568,78.2514728"
                id="Fill-61"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M154.897568,81.8819758 L116.423502,81.8819758 C115.993802,81.8819758 115.642281,81.5303414 115.642281,81.1007552 C115.642281,80.6711689 115.993802,80.319648 116.423502,80.319648 L154.897568,80.319648 C155.327155,80.319648 155.678675,80.6711689 155.678675,81.1007552 C155.678675,81.5303414 155.327155,81.8819758 154.897568,81.8819758"
                id="Fill-62"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M121.804518,19.4035122 C121.663705,19.4035122 121.544678,19.5225392 121.544678,19.6634652 L121.544678,45.2038769 C121.544678,45.344803 121.663705,45.4638299 121.804518,45.4638299 L149.516462,45.4638299 C149.657388,45.4638299 149.776415,45.344803 149.776415,45.2038769 L149.776415,19.6634652 C149.776415,19.5225392 149.657388,19.4035122 149.516462,19.4035122 L121.804518,19.4035122 Z M149.516462,46.5985004 L121.804518,46.5985004 C121.035665,46.5985004 120.410008,45.9728431 120.410008,45.2038769 L120.410008,19.6634652 C120.410008,18.894499 121.035665,18.2688417 121.804518,18.2688417 L149.516462,18.2688417 C150.285428,18.2688417 150.911085,18.894499 150.911085,19.6634652 L150.911085,45.2038769 C150.911085,45.9728431 150.285428,46.5985004 149.516462,46.5985004 L149.516462,46.5985004 Z"
                id="Fill-63"
                fill="#898989"
                mask="url(#mask-2)"
              />
              <polygon
                id="Fill-64"
                fill="#C6C6C6"
                mask="url(#mask-2)"
                points="125.803459 63.2579709 126.605672 64.8834998 128.399699 65.1442471 127.101523 66.4096317 127.407997 68.1962838 125.803459 67.3527698 124.198808 68.1962838 124.505283 66.4096317 123.207106 65.1442471 125.001134 64.8834998"
              />
              <polygon
                id="Fill-65"
                fill="#C6C6C6"
                mask="url(#mask-2)"
                points="132.374802 63.2579709 133.177014 64.8834998 134.971155 65.1442471 133.672865 66.4096317 133.979453 68.1962838 132.374802 67.3527698 130.770151 68.1962838 131.076625 66.4096317 129.778448 65.1442471 131.572476 64.8834998"
              />
              <polygon
                id="Fill-66"
                fill="#C6C6C6"
                mask="url(#mask-2)"
                points="138.946132 63.2579709 139.748344 64.8834998 141.542485 65.1442471 140.244195 66.4096317 140.550783 68.1962838 138.946132 67.3527698 137.341481 68.1962838 137.648069 66.4096317 136.349779 65.1442471 138.14392 64.8834998"
              />
              <polygon
                id="Fill-67"
                fill="#C6C6C6"
                mask="url(#mask-2)"
                points="145.517474 63.2579709 146.3198 64.8834998 148.113827 65.1442471 146.815651 66.4096317 147.122125 68.1962838 145.517474 67.3527698 143.912823 68.1962838 144.219411 66.4096317 142.921235 65.1442471 144.715262 64.8834998"
              />
              <polygon
                id="Fill-68"
                fill="#13C26B"
                mask="url(#mask-2)"
                points="47.9597986 105.903676 112.572362 105.903676 112.572362 0 47.9597986 0"
              />
              <polygon
                id="Fill-69"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                points="50.8897448 103.228918 109.994277 103.228918 109.994277 3.51952094 50.8897448 3.51952094"
              />
              <path
                d="M68.5683082,43.5031306 C70.7929432,38.7173174 74.5580068,37.1846045 80.7408264,37.1846045 C88.102682,37.184718 91.9252734,39.851534 93.0087703,43.734263 C79.8253742,43.9149025 68.5683082,43.5031306 68.5683082,43.5031306"
                id="Fill-70"
                fill="#B9E59C"
                mask="url(#mask-2)"
              />
              <path
                d="M78.1700148,13.9083938 C75.0535288,14.7832247 75.1901431,16.5343617 74.1518061,18.1596637 C73.1133557,19.7850792 70.8558153,20.8685761 70.584856,21.9521864 C70.3141236,23.0356833 71.2800686,25.2932237 70.1334841,26.5573601 C68.9868995,27.8214965 68.5985018,28.4536214 69.0498737,29.7177578 C69.5014726,30.9818942 70.4946497,32.9684753 70.584856,33.2393212 C70.6752892,33.510167 71.9394256,35.4064283 73.4745213,35.6773876 C75.0095036,35.9482335 77.8990555,36.3998324 79.3438314,37.0318438 C80.7884939,37.6639688 85.4339483,36.2225968 88.0123736,36.3129166 C90.5909123,36.4032364 92.3465879,34.5034576 92.1660619,33.2393212 C91.9855358,31.9751848 94.0674292,29.4467985 94.0623326,27.2796913 C94.0567633,24.9524821 91.3534109,23.2163228 90.6309662,21.6812271 C89.9086349,20.1462448 90.7212859,17.4373325 88.0123736,15.832795 C86.3004962,14.8187399 85.1228217,14.9089462 83.7683655,14.4574608 C82.4139094,14.0059755 80.1564824,13.3507032 78.1700148,13.9083938"
                id="Fill-71"
                fill="#3A384D"
                mask="url(#mask-2)"
              />
              <path
                d="M81.9624126,41.0048585 C81.2576688,42.0453514 80.7408264,42.0884688 79.975945,41.0048585 C79.3676481,40.1434167 78.1700034,38.1858831 78.1700034,37.1927061 L78.1700034,27.2797707 C78.1700034,26.2864802 78.9826544,25.4738292 79.975945,25.4738292 L81.9624126,25.4738292 C82.9557032,25.4738292 83.7683542,26.2864802 83.7683542,27.2797707 L83.7683542,37.1927061 C83.7683542,38.1858831 82.5945376,40.071819 81.9624126,41.0048585"
                id="Fill-72"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M81.9624126,25.4737838 L79.975945,25.4737838 C78.9826544,25.4737838 78.1700034,26.2864348 78.1700034,27.2797254 L78.1700034,36.1243684 C80.4004252,36.8344452 82.436251,35.4228016 83.7683542,33.832334 L83.7683542,27.2797254 C83.7683542,26.2864348 82.9557032,25.4737838 81.9624126,25.4737838"
                id="Fill-73"
                fill="#E38540"
                mask="url(#mask-2)"
              />
              <path
                d="M86.9739118,25.5741114 C86.9739118,30.0929366 84.285537,33.7561068 80.9691221,33.7561068 C77.6529341,33.7561068 74.9643323,30.0929366 74.9643323,25.5741114 C74.9643323,21.0553996 77.6529341,17.3921159 80.9691221,17.3921159 C84.285537,17.3921159 86.9739118,21.0553996 86.9739118,25.5741114"
                id="Fill-74"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M83.7683542,19.0626686 C79.975945,25.4737838 74.3774808,24.8416589 74.3774808,24.8416589 C74.3774808,24.8416589 74.5129604,16.3537562 78.9600745,15.9021574 C83.4071886,15.4507854 86.0258946,17.2355087 86.9288654,20.1807729 C87.8318362,23.1260371 86.9288654,25.0674583 86.9288654,25.0674583 C86.9288654,25.0674583 85.483976,23.9386881 84.7616447,22.1327465 C84.0393135,20.326805 83.7683542,19.0626686 83.7683542,19.0626686"
                id="Fill-75"
                fill="#3A384D"
                mask="url(#mask-2)"
              />
              <path
                d="M75.5791082,25.4480722 C76.1406566,26.6305123 75.9569534,27.8926063 75.1686978,28.2670475 C74.3803288,28.6414888 73.2859391,27.9864435 72.7243907,26.8040034 C72.1628422,25.6214498 72.3465454,24.3592424 73.134801,23.9849146 C73.92317,23.6104733 75.0174463,24.2655186 75.5791082,25.4480722"
                id="Fill-76"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M86.0883355,25.4480722 C85.5267871,26.6305123 85.7104902,27.8926063 86.4987458,28.2670475 C87.2871149,28.6414888 88.3815046,27.9864435 88.943053,26.8040034 C89.5047149,25.6214498 89.3208983,24.3592424 88.5326427,23.9849146 C87.7443871,23.6104733 86.6499974,24.2655186 86.0883355,25.4480722"
                id="Fill-77"
                fill="#F09959"
                mask="url(#mask-2)"
              />
              <path
                d="M88.1172171,56.4827512 L72.9530268,56.4827512 C71.9833374,56.4827512 71.1897488,55.6892762 71.1897488,54.7194733 C71.1897488,53.7496704 71.9833374,52.9561953 72.9530268,52.9561953 L88.1172171,52.9561953 C89.0869065,52.9561953 89.8803816,53.7496704 89.8803816,54.7194733 C89.8803816,55.6892762 89.0869065,56.4827512 88.1172171,56.4827512"
                id="Fill-78"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M90.8937218,61.9002469 L70.1765675,61.9002469 C69.2067646,61.9002469 68.4132895,61.1067718 68.4132895,60.1370824 C68.4132895,59.167166 69.2067646,58.3738044 70.1765675,58.3738044 L90.8937218,58.3738044 C91.8634112,58.3738044 92.6568863,59.167166 92.6568863,60.1370824 C92.6568863,61.1067718 91.8634112,61.9002469 90.8937218,61.9002469"
                id="Fill-79"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M105.732205,81.384003 L55.3379708,81.384003 C54.7752877,81.384003 54.3149519,80.9235537 54.3149519,80.3607571 C54.3149519,79.7979606 54.7752877,79.3376248 55.3379708,79.3376248 L105.732205,79.3376248 C106.295002,79.3376248 106.755451,79.7979606 106.755451,80.3607571 C106.755451,80.9235537 106.295002,81.384003 105.732205,81.384003"
                id="Fill-80"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M105.732205,86.1392028 L55.3379708,86.1392028 C54.7752877,86.1392028 54.3149519,85.6787535 54.3149519,85.1160704 C54.3149519,84.5532738 54.7752877,84.092938 55.3379708,84.092938 L105.732205,84.092938 C106.295002,84.092938 106.755451,84.5532738 106.755451,85.1159569 C106.755451,85.6787535 106.295002,86.1392028 105.732205,86.1392028"
                id="Fill-81"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M105.732205,90.8945161 L55.3379708,90.8945161 C54.7752877,90.8945161 54.3149519,90.4339533 54.3149519,89.8712702 C54.3149519,89.3085871 54.7752877,88.8482513 55.3379708,88.8482513 L105.732205,88.8482513 C106.295002,88.8482513 106.755451,89.3085871 106.755451,89.8712702 C106.755451,90.4339533 106.295002,90.8945161 105.732205,90.8945161"
                id="Fill-82"
                fill="#C6C6C6"
                mask="url(#mask-2)"
              />
              <path
                d="M62.386351,9.05878941 C62.2017402,9.05878941 62.0458364,9.2148066 62.0458364,9.39930402 L62.0458364,42.8526808 C62.0458364,43.0371782 62.2017402,43.1931954 62.386351,43.1931954 L98.6840063,43.1931954 C98.8685037,43.1931954 99.0244075,43.0371782 99.0244075,42.8526808 L99.0244075,9.39930402 C99.0244075,9.2148066 98.8685037,9.05878941 98.6840063,9.05878941 L62.386351,9.05878941 Z M98.6840063,44.6793868 L62.386351,44.6793868 C61.379104,44.6793868 60.559645,43.8599278 60.559645,42.8526808 L60.559645,9.39930402 C60.559645,8.39205702 61.379104,7.57259799 62.386351,7.57259799 L98.6840063,7.57259799 C99.6911399,7.57259799 100.510599,8.39205702 100.510599,9.39930402 L100.510599,42.8526808 C100.510599,43.8599278 99.6911399,44.6793868 98.6840063,44.6793868 L98.6840063,44.6793868 Z"
                id="Fill-83"
                fill="#13C26B"
                mask="url(#mask-2)"
              />
              <polygon
                id="Fill-84"
                fill="#13C26B"
                mask="url(#mask-2)"
                points="67.6241149 66.5003145 68.6750467 68.6296372 71.0248358 68.9710595 69.3244186 70.6285862 69.725865 72.9688441 67.6241149 71.8639019 65.5224782 72.9688441 65.9238111 70.6285862 64.2233939 68.9710595 66.5731831 68.6296372"
              />
              <polygon
                id="Fill-85"
                fill="#13C26B"
                mask="url(#mask-2)"
                points="76.2314302 66.5003145 77.282362 68.6296372 79.6321512 68.9710595 77.931734 70.6285862 78.3330669 72.9688441 76.2314302 71.8639019 74.1296801 72.9688441 74.5311265 70.6285862 72.8307093 68.9710595 75.1806119 68.6296372"
              />
              <polygon
                id="Fill-86"
                fill="#13C26B"
                mask="url(#mask-2)"
                points="84.8387569 66.5003145 85.8896888 68.6296372 88.2394779 68.9710595 86.5390607 70.6285862 86.9405071 72.9688441 84.8387569 71.8639019 82.7371202 72.9688441 83.1384532 70.6285862 81.4381495 68.9710595 83.7879386 68.6296372"
              />
              <polygon
                id="Fill-87"
                fill="#13C26B"
                mask="url(#mask-2)"
                points="93.4460723 66.5003145 94.4970041 68.6296372 96.8469067 68.9710595 95.146376 70.6285862 95.5478225 72.9688441 93.4460723 71.8639019 91.3444356 72.9688441 91.7457686 70.6285862 90.0453514 68.9710595 92.3951405 68.6296372"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const AdvanceJobHeaderIcon = props => <Icon component={AdvanceJobHeaderSvg} {...props} />;

export default AdvanceJobHeaderIcon;
