import _ from 'lodash';

function mapErrorCodes(error) {
  let errorMessage = `Oops, something went wrong`;
  const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
  const statusCode = _.get(error, ['response', 'status']);
  if (statusCode === 401 && appCode.includes('SERVICE_PROVIDER_AUTHORIZATION_FAILED')) {
    errorMessage = 'Authentication Failed';
  }
  return errorMessage;
}

export { mapErrorCodes };
