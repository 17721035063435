import React from 'react';
import { notification as antdNotification } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAlertNotification } from '../../Reducers/AppNotificationReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import markNotificationCompletionAction from '../../Actions/AppNotificationActions';

const mapStateToProps = state => ({
  notification: getAlertNotification(state),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  markNotificationCompletion: markNotificationCompletionAction,
};

class AppAlertNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const { notification, markNotificationCompletion, currentUser } = props;
    const notificationType = 'ALERT';
    const alertNotificationStyle = { top: 90 };
    if (notification) {
      switch (notification.Type) {
        case 'ALERT_LOADING':
          antdNotification.info({
            style: alertNotificationStyle,
            message: notification.Message,
            description: notification.Description,
          });
          markNotificationCompletion(notificationType);
          break;
        case 'ALERT_OPEN':
          antdNotification.open({
            style: alertNotificationStyle,
            message: notification.Message,
            description: notification.Description,
          });
          markNotificationCompletion(notificationType);
          break;
        case 'ALERT_SUCCESS':
          antdNotification.success({
            style: alertNotificationStyle,
            message: notification.Message,
            description: notification.Description,
          });
          markNotificationCompletion(notificationType);
          break;
        case 'ALERT_ERROR':
          antdNotification.error({
            style: alertNotificationStyle,
            message: notification.Message,
            description: notification.Description,
          });
          markNotificationCompletion(notificationType);
          break;
        case 'PAYMENT_REQUIRED_ALERT':
          antdNotification.error({
            style: alertNotificationStyle,
            message: `Hey ${currentUser.given_name}, your credits are very low`,
            description:
              'It looks like you do not have sufficient credits remaining in your account. You no longer can perform actions which require these credits. Please send an email to support@leoforce.com for further help.',
          });
          markNotificationCompletion(notificationType);
          break;
        default:
          break;
      }
    }
    return state;
  }

  render() {
    return null;
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAlertNotificationContainer));
