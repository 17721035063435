import React from 'react';
import { Select } from 'antd';
import MultiLocationContext from '../MultiLocationContext';

const CountrySelect = props => {
  const { isDisabled } = props;
  const { CountryCode, onChangeCountry, countryOptions } = React.useContext(MultiLocationContext);
  return (
    <Select
      placeholder="Country"
      style={{ width: '100%' }}
      dropdownStyle={{ zIndex: '1200' }}
      value={CountryCode}
      onChange={onChangeCountry}
      optionFilterProp="children"
      showSearch
      disabled={isDisabled}
    >
      {countryOptions.map(country => (
        <Select.Option value={country.Iso2Code} key={country.Iso2Code}>
          {country.Name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
