import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
// import { Link } from 'react-router-dom';
import { Icon, Select, Input, Menu, Dropdown, Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import RecruiterSelect from '../RecruiterSelect/RecruiterSelect';
import './JobListHeader.scss';
import messages from './messages';
import OrganizationSelect from '../OrganizationSelect/OrganizationSelect';
import FilterPopUpContainer from '../../Containers/FilterPopUpContainer/FilterPopUpContainer';
import { SELECT_MODE } from '../../PubSub/EventTypes';
import PlanCredits from '../Payment/PlanCredits';
import { isPulseUser } from '../../Utils/ConfigUtils';

const { Search } = Input;
const { Option } = Select;

class JobListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
    };
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
    this.getAddJob = this.getAddJob.bind(this);
    this.handleDropdownVisibility = this.handleDropdownVisibility.bind(this);
    this.onHandleCreateJob = this.onHandleCreateJob.bind(this);
  }

  onSearchFocus() {
    this.setState({
      searchBoxFocused: true,
    });
  }

  onSearchBlur() {
    this.setState({
      searchBoxFocused: false,
    });
  }

  getAddJob() {
    const { history, showPullJobDialog, atsConfig, featureToggleList } = this.props;
    const { showCloseIcon } = this.state;
    const { PaidJobService } = featureToggleList;
    const isPullJobsConfigured = _.get(atsConfig, ['AccessConfiguration', 'IsGetJobAllowed'], false);
    if (isPullJobsConfigured) {
      return (
        <Dropdown
          overlay={
            <Menu className="add-job-menu" onClick={() => null}>
              <Menu.Item
                key="create"
                onClick={() => {
                  this.handleDropdownVisibility(false);
                  if (featureToggleList?.AdvancedJobCreation?.IsEnabled) {
                    history.push('/jobs-v2/select-mode');
                  } else {
                    history.push('/jobs/create');
                  }
                }}
              >
                Create Job
              </Menu.Item>
              <Menu.Divider className="menu-divider" />
              <Menu.Item
                key="pull"
                onClick={() => {
                  this.handleDropdownVisibility(false);
                  showPullJobDialog();
                }}
              >
                Pull Jobs
              </Menu.Item>
            </Menu>
          }
          className="add-job-button"
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={visible => this.handleDropdownVisibility(visible)}
        >
          {showCloseIcon ? (
            <div className="close-circle-icon">
              <Icon type="close-circle" theme="filled" className="add-job-icon" />
            </div>
          ) : (
            <Button className="add-job-button" shape="round" type="primary">
              Add Job
            </Button>
          )}
        </Dropdown>
      );
    }
    return (
      <Button className="add-job-button" shape="round" type="primary" onClick={this.onHandleCreateJob}>
        {PaidJobService.IsEnabled ? 'Create Job' : 'Add Job'}
      </Button>
    );
  }

  onHandleCreateJob() {
    const { history, featureToggleList } = this.props;
    PubSub.publish(SELECT_MODE, {});
    if (featureToggleList?.AdvancedJobCreation?.IsEnabled) {
      history.push('/jobs-v2/select-mode');
    } else {
      history.push('/jobs/create');
    }
  }

  handleDropdownVisibility(visible) {
    if (visible) {
      this.setState({
        showCloseIcon: true,
      });
    } else {
      this.setState({
        showCloseIcon: false,
      });
    }
  }

  getAllJobsOptionDropdownContent = statusTextAllJobs => {
    return isPulseUser() ? 'Available Jobs' : statusTextAllJobs;
  };

  render() {
    const {
      jobStatus,
      onStatusChange,
      currentJobStatusId,
      onSearch,
      onChange,
      intl,
      searchTerm,
      currStatusCount,
      userConfig,
      selectedUser,
      fetchJobsByUser,
      isVaultConfigured,
      fetchJobsOnAppliedFilter,
      featureToggleList,
      planDetailsByProductVariantId,
      productVariantsById,
    } = this.props;
    const { searchBoxFocused } = this.state;
    const { PaidJobService } = featureToggleList;
    let menu;
    let totalCount = 0;
    const menuList = jobStatus.map(status => {
      const statusText = `${status.Name} ${intl.formatMessage({
        ...messages.job,
      })} `;
      let statusCount = '-';
      if (status.Count !== undefined) {
        statusCount = status.Id === currentJobStatusId ? currStatusCount || status.Count : status.Count;
        totalCount += status.Count;
      }
      const statusTextWithCount = `${statusText} (${statusCount})`;
      return (
        <Option key={status.Id} value={status.Id} title={statusTextWithCount}>
          <div className={`job-status-text ${searchBoxFocused ? `job-status-text-limited` : ''}`}>{statusText}</div>
          <span className="job-status-count">{`(${statusCount})`}</span>
        </Option>
      );
    });
    if (jobStatus.length && currentJobStatusId) {
      const statusTextAllJobs = `${`${intl.formatMessage({ ...messages.all })} ${intl.formatMessage({
        ...messages.job,
      })} `}(${currentJobStatusId === -1 ? currStatusCount || totalCount : totalCount})`;
      menu = (
        <Select
          size="large"
          className="job-status-filter"
          key="after-data"
          defaultValue={currentJobStatusId}
          value={currentJobStatusId}
          onChange={value => {
            onStatusChange(value);
          }}
          dropdownClassName="jobstatus-dropdown floating-menu-popup"
        >
          <Option key={-1} value={-1} title={statusTextAllJobs}>
            {this.getAllJobsOptionDropdownContent(statusTextAllJobs)}
          </Option>
          {menuList}
        </Select>
      );
    }

    return (
      <div className="job-list-header">
        <div className="job-status">{menu}</div>
        <div className="right-align">
          {userConfig.CanImpersonate ? (
            <div className="organization-dropdown right-align">
              <span className="recruiter-select-positioner">
                <OrganizationSelect selectedUser={selectedUser} fetchJobsByUser={fetchJobsByUser} />
              </span>
            </div>
          ) : null}
          {['Admin', 'God View'].includes(userConfig.Role) || userConfig.CanImpersonate ? (
            <div className="recruiter-dropdown">
              <span className="recruiter-select-positioner">
                <RecruiterSelect
                  selectedUser={selectedUser}
                  fetchJobsByUser={fetchJobsByUser}
                  vaultAccess={isVaultConfigured}
                />
              </span>
            </div>
          ) : null}
          {!PaidJobService.IsEnabled ? (
            <div className="job-search">
              <Search
                size="default"
                placeholder={
                  searchBoxFocused
                    ? intl.formatMessage({ ...messages.searchExtended })
                    : intl.formatMessage({ ...messages.search })
                }
                allowClear
                className="jobs-input-search"
                onSearch={value => onSearch(value)}
                onChange={onChange}
                onFocus={this.onSearchFocus}
                onBlur={this.onSearchBlur}
                value={searchTerm}
              />
              {featureToggleList.JobTag.IsEnabled ? (
                <FilterPopUpContainer fetchJobsOnAppliedFilter={fetchJobsOnAppliedFilter} />
              ) : null}
            </div>
          ) : null}
          {PaidJobService.IsEnabled ? (
            <div className="plan-credits-wrapper">
              <PlanCredits
                planDetailsByProductVariantId={planDetailsByProductVariantId}
                productVariantsById={productVariantsById}
                isCreateJobPage={false}
              />
            </div>
          ) : null}
        </div>
        <div className="job-create">{this.getAddJob()}</div>
      </div>
    );
  }
}

JobListHeader.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentJobStatusId: PropTypes.number,
  onStatusChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

JobListHeader.defaultProps = {
  currentJobStatusId: undefined,
};

export default injectIntl(JobListHeader);

export { JobListHeader as JobListHeaderWithoutStore };
