import { Button, Divider, Icon, Skeleton } from 'antd';
import _ from 'lodash';
import React from 'react';
import { AuthorizeNetIcon } from '../../Icons/AryaIcons';
import '../Payment/PaymentPlaceOrder.scss';
import PaymentProgressModal from '../Payment/PaymentProgressModal';
import PlaceOrderTermsOfUse from './PlaceOrderTermsOfUse';
import { isUnhandledTaxJarError } from '../../Utils/PaymentUtils';
import eventTypes from '../../Analytics/EventTypes';

function OrderAmountItem({ itemName, price, borderTop, className }) {
  return (
    <div className="place-order-item" style={{ borderTop: borderTop ? '1.5px solid #DADBDD' : 'none' }}>
      <div style={{ paddingLeft: '25px' }} className={className}>
        {itemName}
      </div>
      <div className={`${className} order-price-item`}>{price}</div>
    </div>
  );
}

function getOrderAmount(totalAmount, productVariants, variantId) {
  const planDetails = _.find(productVariants, variant => variant.Id === variantId);
  return {
    orderPrice: totalAmount.Price ?? planDetails?.UnitPrice,
    orderTax: totalAmount.Tax ?? 0,
    orderTotal: totalAmount.Total ?? planDetails?.UnitPrice,
  };
}

function PlaceOrderHeaderContent({ productVariants, variantId, quantity }) {
  const planDetails = _.find(productVariants, variant => variant.Id === variantId);
  return (
    <>
      <h3>{`${quantity} Job Advertisement Credit(s)`}</h3>
      <p>{`(1 Credit = $${planDetails?.UnitPrice})`}</p>
    </>
  );
}

export default function PaymentPlaceOrder({
  quantity,
  placeOrderButtonText,
  placeOrderCollectInfo,
  modalVisbility,
  paymentApiStatus,
  createOrderApiStatus,
  updateBillingInfoApiStatus,
  history,
  invoiceNumber,
  toggleOrderModalVisibility,
  disablePlaceOrder,
  totalAmount,
  getAmountApiStatus,
  isAddressAvaliable,
  onClickDuplicatePaymentOk,
  productVariants,
  variantId,
  createOrderApiErrorMessage,
}) {
  const { orderPrice, orderTax, orderTotal } = getOrderAmount(totalAmount, productVariants, variantId);
  const orderTotalAmount = orderTotal;

  const isAmountApiError = isUnhandledTaxJarError(getAmountApiStatus);
  return (
    <div className="place-order-body">
      <Skeleton active loading={getAmountApiStatus === 'INPROGRESS'}>
        <div className="add-candidates-payment-plan">
          <div className="profiles-add">
            <PlaceOrderHeaderContent productVariants={productVariants} variantId={variantId} quantity={quantity} />
          </div>
          <Divider style={{ margin: '0px' }} />
          <div className="order-details-payment-plan">
            <h3>Order Details</h3>
          </div>
          <div width="100%">
            <div>
              <OrderAmountItem price={`$${Number(orderPrice).toFixed(2)}`} itemName="Price" />
              <OrderAmountItem price={`$${Number(orderTax).toFixed(2)}`} itemName="Tax" className="order-last-item" />
              <OrderAmountItem
                price={<b style={{ fontSize: '16px', color: 'black' }}>{`$${Number(orderTotalAmount).toFixed(2)}`}</b>}
                itemName={<b style={{ fontSize: '12px', color: 'black' }}>ORDER TOTAL</b>}
                borderTop
                className="order-result-amount-item"
              />
            </div>
          </div>
        </div>
      </Skeleton>
      <div className="payment-plan-button">
        {isAmountApiError && isAddressAvaliable && (
          <span className="arya-job-amount-api-error">
            We are facing trouble calculating your order total. Contact{' '}
            <a href="mailto:support@leoforce.com" style={{ textDecoration: 'underline', color: '#b50000' }}>
              support@leoforce.com
            </a>{' '}
            if this issue persists.
          </span>
        )}
        <Button
          className="place-order-btn"
          type="primary"
          shape="round"
          onClick={() => placeOrderCollectInfo(totalAmount.Total, totalAmount.Tax)}
          disabled={disablePlaceOrder || !isAddressAvaliable}
          sk-event-name={eventTypes.job.advertiseJob.advertiseJobPayment}
        >
          {placeOrderButtonText}
        </Button>
        <p style={{ marginTop: '7px' }}>
          <Icon type="lock" theme="filled" style={{ color: '#13C26B' }} />
          &nbsp; Safe and Secure Payments powered by
          <AuthorizeNetIcon style={{ fontSize: '99px', marginLeft: '4px' }} />
        </p>
      </div>

      <PlaceOrderTermsOfUse placeOrderButtonText={placeOrderButtonText} />
      <PaymentProgressModal
        visible={modalVisbility}
        paymentStatus={paymentApiStatus}
        orderStatus={createOrderApiStatus}
        updateBillingInfoApiStatus={updateBillingInfoApiStatus}
        history={history}
        invoiceNumber={invoiceNumber}
        totalAmount={totalAmount}
        toggleOrderModalVisibility={toggleOrderModalVisibility}
        onClickDuplicatePaymentOk={onClickDuplicatePaymentOk}
        isOrderSuccessModalDisabled
        orderErrorMessage={createOrderApiErrorMessage}
      />
    </div>
  );
}
