import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import styles from './SmsNodeContent.module.scss';
import ConnectTemplateSelect from '../../JobSettings/ConnectTemplateSelect';
import { isPulseUser } from '../../../Utils/ConfigUtils';
import RichTextEditor from '../../Editor/RichTextEditor';
import * as SmsReducer from '../../../Reducers/TextTemplates';
import * as SmsActions from '../../../Actions/TextTemplates';

const FormItem = Form.Item;

const mapStateToProps = state => ({
  currentSmsTemplateIds: SmsReducer.getTextTemplateIds(state),
  smsTemplatesById: SmsReducer.getTextTemplatesById(state),
  smsTemplatesCount: SmsReducer.getTextTemplatesCount(state),
});

const mapDispatchToProps = {
  fetchSmsTemplates: SmsActions.searchTextTemplates,
};

function SmsNodeContent(props) {
  const {
    form,
    initialValue,
    currentSmsTemplateIds,
    smsTemplatesById,
    fetchSmsTemplates,
    smsTemplatesCount,
    drawerFooter,
    isDisabled,
  } = props;
  const [smsContentVisibility, setSmsContentVisibility] = React.useState(false);

  const { SmsTemplateId: selectedSmsTemplateId } = form.getFieldsValue();
  form.getFieldDecorator('SmsTemplateName', { initialValue: _.get(initialValue, 'SmsTemplateName') });

  React.useEffect(() => {
    form.setFieldsValue({ SmsTemplateId: _.get(initialValue, 'SmsTemplateId') });
  }, []);

  const toggleSmsContentVisibility = () => {
    setSmsContentVisibility(!smsContentVisibility);
  };

  const onSmsTemplateChange = templateId => {
    form.setFieldsValue({
      SmsTemplateId: templateId,
      SmsTemplateName: _.get(smsTemplatesById, [templateId, 'Name'], null),
    });
  };

  const selectedSmsTemplateName = _.get(smsTemplatesById, [selectedSmsTemplateId, 'Name'], null);
  const selectedSmsSubject = _.get(smsTemplatesById, [selectedSmsTemplateId, 'Subject'], null);
  const selectedSmsBody = _.get(smsTemplatesById, [selectedSmsTemplateId, 'Body'], null);

  const getPreviewTextClassname = classNames(
    {
      [styles.previewText]: !selectedSmsTemplateId,
    },
    {
      [styles.selectedText]: selectedSmsTemplateId,
    }
  );

  return (
    <div className={styles.smsNodeContent}>
      <div className={styles.emailDropdown}>
        <FormItem>
          {form.getFieldDecorator('SmsTemplateId', {
            initialValue: _.get(initialValue, 'SmsTemplateId'),
            rules: [{ required: true, message: 'Please select a Template' }],
          })(
            <ConnectTemplateSelect
              cssClassname="job-settings-template-selector"
              templateIds={currentSmsTemplateIds}
              templatesById={smsTemplatesById}
              templatesCount={smsTemplatesCount}
              fetchTemplates={fetchSmsTemplates}
              isPusle={isPulseUser()}
              selectedTemplateId={selectedSmsTemplateId}
              selectedTemplateName={selectedSmsTemplateName}
              placeholderValue="Choose a template"
              onChange={onSmsTemplateChange}
              isDisabled={isDisabled}
            />
          )}
        </FormItem>
        <Button type="link" onClick={toggleSmsContentVisibility} disabled={!selectedSmsTemplateName}>
          <span className={getPreviewTextClassname}>{smsContentVisibility ? 'Hide Preview' : 'Preview'}</span>
        </Button>
      </div>
      {smsContentVisibility ? (
        <div>
          <div style={{ marginTop: '3px' }}>
            <RichTextEditor
              className="mail-subject-editor connect-template-subject"
              editorContent={{ htmlContent: selectedSmsSubject }}
              showToolbar={false}
              disabled
            />
          </div>
          <div style={{ marginTop: '3px' }}>
            <RichTextEditor
              editorContent={{ htmlContent: selectedSmsBody }}
              showAdditionalOptions
              showToolbar={false}
              disabled
            />
          </div>
        </div>
      ) : null}
      <div className={styles.drawerFooter}>{drawerFooter}</div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsNodeContent);
export { SmsNodeContent as EmailTemplatesContainerWithoutStore };
