import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PasswordResetModal from '../../Components/PasswordResetModal/PasswordResetModal';
import * as UserActions from '../../Actions/UserActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { getAccountType } from '../../Utils/OrganizationUtils';
import { getAccountTypes } from '../../Reducers/OrganizationsReducer';

const mapStateToProps = state => {
  return {
    currentUser: getCurrentUser(state),
    usersByGuid: getUsersByGuId(state),
    userConfig: getConfig(state) || {},
    accountTypes: getAccountTypes(state),
    updatePasswordApiStatus: getApiStatus(state, 'updatePasswordApiStatus'),
  };
};

const mapDispatchToProps = {
  updateUserPassword: UserActions.updateUserPassword,
};

function PasswordResetModalContainer(props) {
  const { usersByGuid, currentUser, userConfig, accountTypes, updatePasswordApiStatus, updateUserPassword } = props;

  const updatePassword = password => {
    const { email } = currentUser;
    updateUserPassword({ Username: email, Password: password });
  };
  const currentUserGuid = currentUser.sub;
  const userInfo = usersByGuid[currentUserGuid];
  const accountTypeId = userConfig?.AccountTypeId;
  const accountType = getAccountType(accountTypeId, accountTypes);
  const isFirstLoginUser = userInfo?.IsFirstLogin;

  return (
    <PasswordResetModal
      visible={accountType?.toLowerCase() === 'aryapulse' && isFirstLoginUser && updatePasswordApiStatus !== 'SUCCESS'}
      updatePassword={updatePassword}
      updatePasswordApiStatus={updatePasswordApiStatus}
    />
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetModalContainer));

export { PasswordResetModalContainer as PasswordResetModalContainerWithoutStore };
