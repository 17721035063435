import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Checkbox, Popover, Tooltip, Input } from 'antd';
import './FilterPopUp.scss';
import FilterIconWithTooltip from '../Common/FilterIconWithTooltip/FilterIconWithTooltip';

class FilterPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedTags: [],
    };
    this.onApplyClick = this.onApplyClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.getTagCheckboxes = this.getTagCheckboxes.bind(this);
    this.getPopOverContent = this.getPopOverContent.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  async onApplyClick() {
    const { updateFilterTags, fetchJobsOnAppliedFilter } = this.props;
    const { currentSelectedTags } = this.state;
    this.setState({ popoverVisibility: false, currentSelectedTags: [] });
    await updateFilterTags(currentSelectedTags);
    fetchJobsOnAppliedFilter();
  }

  onClearClick() {
    this.setState({ currentSelectedTags: [] });
  }

  handleVisibleChange(popoverVisibility) {
    const { tags, selectedTags } = this.props;
    this.setState({
      popoverVisibility,
      currentSelectedTags: _.intersectionBy(tags || [], selectedTags || [], 'Value'),
      suggestedTags: tags,
      searchTerm: '',
    });
  }

  handleCheckboxClick(e) {
    const currentSelectedTag = e.target.value;
    const { currentSelectedTags } = this.state;
    const newCurrentSelectedTags = _.cloneDeep(currentSelectedTags || []);
    if (!_.find(newCurrentSelectedTags, { Value: currentSelectedTag.Value }))
      newCurrentSelectedTags.push(currentSelectedTag);
    else _.remove(newCurrentSelectedTags, { Value: currentSelectedTag.Value });
    this.setState({ currentSelectedTags: newCurrentSelectedTags });
  }

  getTagCheckboxes() {
    const { suggestedTags, currentSelectedTags, searchTerm } = this.state;
    const { tags } = this.props;
    let currentDisplayTags = _.cloneDeep(suggestedTags);
    if (!searchTerm) currentDisplayTags = _.cloneDeep(tags);
    return (currentDisplayTags || []).map(tag => (
      <div key={tag.Key}>
        <Checkbox
          style={{ marginBottom: 8 }}
          value={tag}
          onChange={this.handleCheckboxClick}
          checked={_.find(currentSelectedTags, { Value: tag.Value })}
        >
          {_.get(tag, ['Value', 'length'], 0) > 20 ? (
            <Tooltip title={tag.Value} key={tag}>
              {tag.Value.slice(0, 20)}...
            </Tooltip>
          ) : (
            tag.Value
          )}{' '}
          ({tag.Count})
        </Checkbox>
      </div>
    ));
  }

  onSearchChange(e) {
    const { tags } = this.props;
    const searchTerm = e.target.value;
    let suggestedTags = _.cloneDeep(tags);
    if (searchTerm)
      suggestedTags = tags.filter(tag => (tag.Value || '').toLowerCase().includes(searchTerm.toLowerCase()));
    this.setState({ suggestedTags, searchTerm });
  }

  getPopOverContent() {
    const { Search } = Input;
    const { searchTerm } = this.state;
    const { tags } = this.props;
    const tagCheckboxesContent = tags ? this.getTagCheckboxes() : undefined;
    return (
      <div className="job-filter-popover-form-content">
        <div className="job-filter-popover-content">
          <div className="job-filter-popover-content-header">Tags</div>
          <div className="job-filter-search-box-wrapper">
            <Search
              className="job-filter-search-box"
              placeholder="Search"
              allowClear
              value={searchTerm}
              onChange={this.onSearchChange}
            />
          </div>
          <div className="job-filter-tags">
            <div className="tag-filter-popover-checkboxes">{tagCheckboxesContent}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { popoverVisibility } = this.state;
    const { selectedTags } = this.props;
    const isFilterApplied = (selectedTags || []).length !== 0;
    return (
      <div className="job-filter-icon">
        <Popover
          className="filter-popover"
          overlayClassName="job-filter-popover"
          content={this.getPopOverContent()}
          placement="bottomRight"
          trigger="click"
          autoAdjustOverflow={false}
          visible={popoverVisibility}
          onVisibleChange={this.handleVisibleChange}
          title={
            <div className="job-filter-aggregation">
              <div className="job-filter-aggregation-title">Job Filters</div>
              <div className="job-filter-apply-clear">
                <Button className="job-filter-button job-filter-clear-button" onClick={this.onClearClick}>
                  Clear
                </Button>
                <Button
                  type="primary"
                  className="job-filter-button job-filter-apply-button"
                  onClick={this.onApplyClick}
                >
                  Apply
                </Button>
              </div>
            </div>
          }
        >
          <div className="job-icon-filter-button">
            <FilterIconWithTooltip
              className="job-icon-filter-image"
              onClick={this.handleFilterButtonClick}
              isFilterApplied={isFilterApplied}
              title="Filters"
              style={{ fontSize: 20, marginTop: 5 }}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

export default FilterPopUp;
