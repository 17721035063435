import { apiV3Client, apiV4Client } from './BaseRepository';

function fetchCandidateAdvanceFilterFields() {
  return apiV3Client.get(`/candidate-advance-filters/fields`);
}
function fetchCandidateAdvanceFilterPortals() {
  return apiV3Client.get(`/portal`);
}
function fetchCandidateAdvanceFilterIndustries(payload) {
  return apiV4Client.post(`/Industries`, payload);
}
function fetchCampaignTitleSuggestions(payload) {
  return apiV3Client.post(`jobs/${payload.jobId}/campaign/_search?searchTerm=${payload.searchTerm}`);
}

function runCampaign(jobId, campaignId, payload) {
  return apiV3Client.post(`jobs/${jobId}/campaign/${campaignId}/_run`, payload);
}

function fetchCampaignStats(jobId) {
  return apiV3Client.get(`jobs/${jobId}/campaign/_stats`);
}
function fetchCampaignStatsByCampaignId(jobId, campaignId) {
  return apiV3Client.get(`jobs/${jobId}/campaign/${campaignId}/_stats`, campaignId);
}

function createCampaign(payload, jobId) {
  return apiV3Client.post(`jobs/${jobId}/campaign`, payload);
}
export {
  fetchCandidateAdvanceFilterFields,
  fetchCampaignTitleSuggestions,
  createCampaign,
  fetchCampaignStats,
  runCampaign,
  fetchCampaignStatsByCampaignId,
  fetchCandidateAdvanceFilterPortals,
  fetchCandidateAdvanceFilterIndustries,
};
