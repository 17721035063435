import _ from 'lodash';

const emptyObject = {};
const emptyList = [];

function TextTemplates(state = {}, action) {
  let newState;
  let templateIds;
  switch (action.type) {
    case 'SET_TEXT_TEMPLATES':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};

      newState.CurrentTemplateIds = action.payload.map(template => template.Id);
      newState.templateIds = action.payload.map(template => template.Id);
      action.payload.forEach(template => {
        newState.ById[template.Id] = template;
      });
      return newState;

    case 'SET_TEXT_TEMPLATE_DETAILS':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};
      newState.ById[action.payload.template.Id] = action.payload.template;
      return newState;

    case 'APPEND_TEXT_TEMPLATES':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};
      templateIds = action.payload.templates.map(template => template.Id);
      templateIds = _.uniqBy([...newState.templateIds, ...templateIds]);
      newState.templateIds = templateIds;
      action.payload.templates.forEach(template => {
        newState.ById[template.Id] = template;
      });
      return newState;

    case 'SET_TEXT_TEMPLATES_COUNT':
      return {
        ...state,
        Count: action.payload,
      };
    case 'SET_TEXT_TEMPLATE_SHARED_WITH':
      newState = _.cloneDeep(state);
      if (action.payload.sharedWith || action.payload.unsharedWith) {
        _.set(newState, ['ById', action.templateId, 'SharedWith'], action.payload.sharedWith);
        _.set(newState, ['ById', action.templateId, 'UnsharedWith'], action.payload.unsharedWith);
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }

      if (action.payload.isShareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], true);
      }
      if (action.payload.isUnshareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }
      return newState;
    default:
      return state;
  }
}

function getCurrentTextTemplateIds(state) {
  return state.TextTemplates.CurrentTemplateIds || emptyList;
}

function getTextTemplatesById(state) {
  return state.TextTemplates.ById || emptyObject;
}

function getTextTemplateIds(state) {
  return state.TextTemplates.templateIds || emptyList;
}

function getTextTemplatesCount(state) {
  return state.TextTemplates.Count;
}

export { TextTemplates, getCurrentTextTemplateIds, getTextTemplatesById, getTextTemplateIds, getTextTemplatesCount };
