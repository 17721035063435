import moment from 'moment';

export const compareDates = ({ initialDate, targetDate, dateFormat }) => {
  return moment(
    moment
      .utc(initialDate)
      .local()
      .format(dateFormat)
  ).isSame(
    moment(
      moment
        .utc(targetDate)
        .local()
        .format(dateFormat)
    )
  );
};
