import React from 'react';
import { Tooltip } from 'antd';

const formValuesMapper = options => {
  return Object.keys(options).map(key => {
    return { label: key, value: options[key] };
  });
};

const generateValueCountFromInputs = props => {
  const { inputValues, maxLengthOfValue, maxCount } = props;
  return inputValues?.map(inputValue => {
    return {
      label: (
        <span>
          {maxLengthOfValue && inputValue?.Value?.length > maxLengthOfValue ? (
            <Tooltip title={inputValue.Value} key={inputValue}>
              {inputValue.Value.slice(0, maxLengthOfValue)}...
            </Tooltip>
          ) : (
            inputValue.Value
          )}{' '}
          ({Math.min(inputValue.Count, maxCount)})
        </span>
      ),
      value: inputValue.Value,
    };
  });
};

export { formValuesMapper, generateValueCountFromInputs };
