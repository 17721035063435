import { getChatConnection } from '../Events/SocketConnectionManager';
import * as connectRepository from '../Repository/ConnectRepository';

function sendMessage({ botContextId, message, conversationId }) {
  return dispatch => {
    getChatConnection(dispatch).then(connection =>
      connection.invoke('SendChatMessage', {
        MessageType: 'Chat',
        BotContextId: botContextId,
        Body: message,
        ConversationId: conversationId,
      })
    );
  };
}

function fetchMessages(conversationId) {
  return dispatch => {
    connectRepository
      .fetchMessages({
        conversationId,
        messageType: 'Chat',
      })
      .then(response => {
        dispatch({
          type: 'ADD_CHAT_MESSAGES',
          payload: {
            conversationId,
            Messages: response.data.Messages,
          },
        });
      });
  };
}

export { fetchMessages, sendMessage };
