import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Form, InputNumber, Tooltip, Button, Select, Tag } from 'antd';
import { getSourceDisplayName } from '../../Utils/SourceUtils';
import CareerBuilderEdgeLoginForm from '../../Containers/JobBoardIntegrationLoginForms/CareerBuilderEdgeLoginForm/CareerBuilderEdgeLoginForm';
import IndeedLoginForm from '../JobBoardIntegrationLoginForms/IndeedLoginForm/IndeedLoginForm';
import MonsterLoginForm from '../JobBoardIntegrationLoginForms/MonsterLoginForm/MonsterLoginForm';
import NexxtLoginForm from '../JobBoardIntegrationLoginForms/NexxtLoginForm/NexxtLoginForm';
import NewJobBoardLoginForm from '../JobBoardIntegrationLoginForms/NewJobBoardLoginForm/NewJobBoardLoginForm';
import ResumeLibraryLoginForm from '../JobBoardIntegrationLoginForms/ResumeLibraryLoginForm/ResumeLibraryLoginForm';
import CommonJobBoardForm from '../JobBoardIntegrationLoginForms/CommonJobBoardForm/CommonJobBoardForm';
import { getJobBoardsIcon, singleCredentialJobBoards } from '../../Utils/JobBoardUtils';
import Config from '../../Config/Config';
import './JobBoardSettings.scss';
import store from '../../store';

function getPostingJobboardIntegrationForm(props) {
  const { jobBoard } = props;
  if (jobBoard.toLowerCase() === 'monster') {
    return <CommonJobBoardForm {...props} />;
  }
  return null;
}

export function getJobBoardIntegrationLoginForm(props, isPostingJobboard) {
  const { userConfig } = props;
  const activeTabDisplayName = userConfig?.ActiveChannelAlias || 'Arya Active';
  if (isPostingJobboard) {
    return getPostingJobboardIntegrationForm(props);
  }
  switch (props.jobBoard.toLowerCase()) {
    case 'careerbuilder':
      return <CommonJobBoardForm {...props} />;
    case 'careerbuilderedge':
      return <CareerBuilderEdgeLoginForm {...props} />;
    case 'dice':
      return <CommonJobBoardForm {...props} />;
    case 'efinancial':
      return <CommonJobBoardForm {...props} />;
    case 'indeed':
      return <IndeedLoginForm {...props} />;
    case 'monster':
      return <MonsterLoginForm {...props} />;
    case 'nexxt':
      return <NexxtLoginForm {...props} />;
    case 'resumelibrary':
      return <ResumeLibraryLoginForm {...props} />;
    case 'cvlibrary':
      return <CommonJobBoardForm {...props} />;
    case 'cwjobs':
      return <CommonJobBoardForm {...props} />;
    case 'totaljobs':
      return <CommonJobBoardForm {...props} />;
    case 'clearancejobs':
      return <CommonJobBoardForm {...props} />;
    case 'jobsite':
      return <CommonJobBoardForm {...props} />;
    case 'active':
      return <NewJobBoardLoginForm {...props} portalText={activeTabDisplayName} />;
    case 'passive':
      return <NewJobBoardLoginForm {...props} portalText="Arya Passive" />;
    default:
      return null;
  }
}

function getJobBoardTag(jobBoard) {
  switch (jobBoard.toLowerCase()) {
    case 'careerbuilder':
      return <Tag>Classic</Tag>;
    case 'careerbuilderedge':
      return <Tag>Edge</Tag>;
    default:
      return null;
  }
}

export function getJobBoardName(jobBoard, whiteLabelInfo, userConfig) {
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
  const activeTabDisplayName = userConfig?.ActiveChannelAlias || `${AppName} Active`;
  switch (jobBoard.toLowerCase()) {
    case 'active':
      return activeTabDisplayName;
    case 'passive':
      return `${AppName} Passive`;
    default:
      return null;
  }
}

const credentialsValidator = {
  hasError(inputError) {
    return Object.keys(inputError).some(field => inputError[field]);
  },

  anyFieldEmpty(form, mandatoryFields) {
    return !mandatoryFields.every(form.getFieldValue);
  },

  validateEmptyField(rule, value, callback) {
    if (value) {
      const fieldValue = value.trim();
      if (fieldValue) {
        callback();
      } else {
        callback('');
      }
    }
    return null;
  },
};

class JobBoardSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAccount: undefined,
      selectedCountries: [],
    };

    this.addAccount = this.addAccount.bind(this);
    this.clearAccount = this.clearAccount.bind(this);
    this.addSelectedCountry = this.addSelectedCountry.bind(this);
    this.removeSelectedCountry = this.removeSelectedCountry.bind(this);
    this.getJobBoardIcon = this.getJobBoardIcon.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const countries = [];
    const credentials = _.get(props, ['portalSettings', 'PortalCredentials'], []);
    credentials.map(credential => countries.push(credential.CountryCode || 'All'));

    if (!_.isEqual(countries, state.selectedCountries)) {
      return {
        ...state,
        selectedCountries: countries,
      };
    }

    return {
      ...state,
    };
  }

  addAccount() {
    const { newAccount } = this.state;
    let cloneAccount = _.cloneDeep(newAccount);
    cloneAccount = {};
    this.setState({
      newAccount: cloneAccount,
    });
  }

  addSelectedCountry(country) {
    const { selectedCountries } = this.state;
    const cloneCountries = _.cloneDeep(selectedCountries);
    cloneCountries.push(country);
    this.setState({
      selectedCountries: cloneCountries,
    });
  }

  removeSelectedCountry(country) {
    const { selectedCountries } = this.state;
    const cloneCountries = _.cloneDeep(selectedCountries);
    cloneCountries.filter(cloneCountry => cloneCountry !== country);
    this.setState({
      selectedCountries: cloneCountries,
    });
  }

  clearAccount() {
    this.setState({
      newAccount: undefined,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getJobBoardIcon(jobBoard) {
    const client = localStorage.getItem('Client');
    if (jobBoard.toUpperCase() === 'ACTIVE' && client) {
      return (
        <img
          src={`${Config.urls.getWhiteLabelResourceUrl(client)}/activeicon.png`}
          alt={jobBoard}
          className="careerbuilder-logo"
        />
      );
    }
    if (jobBoard.toUpperCase() === 'PASSIVE' && client) {
      return (
        <img
          src={`${Config.urls.getWhiteLabelResourceUrl(client)}/passiveicon.png`}
          alt={jobBoard}
          className="careerbuilder-logo"
        />
      );
    }

    return <>{getJobBoardsIcon(jobBoard.toUpperCase(), { fontSize: '32px' })}</>;
  }

  render() {
    const {
      form,
      jobBoard,
      portalSettings,
      authenticate,
      disconnect,
      portalCountries,
      integrateSource,
      sourceConfig,
      AppName,
      whiteLabelInfo,
      isPostingJobboard,
    } = this.props;
    const { newAccount, selectedCountries } = this.state;
    const credentials = _.get(this.props, ['portalSettings', 'PortalCredentials'], []);
    const portalCredentials = newAccount !== undefined ? [...credentials, newAccount] : credentials;
    const isSingleCredJobBoard =
      singleCredentialJobBoards.includes(jobBoard.toLowerCase()) && portalCredentials.length > 0;
    const { Option } = Select;
    let allCountries = _.cloneDeep(portalCountries.Countries);
    let Countries = [];
    const countriesMenu = [];

    if (allCountries && allCountries.length === 0) {
      Countries.push('All');
      allCountries.push('All');
      countriesMenu.push(
        <Option className="country-option" key="All" value="All">
          All
        </Option>
      );
    } else if (allCountries && allCountries.length === 1) {
      Countries.push(allCountries[0]);
      countriesMenu.push(
        <Option className="country-option" key={allCountries[0]} value={allCountries[0]}>
          {allCountries[0]}
        </Option>
      );
    } else if (allCountries && allCountries.length > 1) {
      allCountries = ['All', ...allCountries];
      Countries = _.difference(allCountries, selectedCountries);
      countriesMenu.push(
        ...Countries.map(country => {
          return (
            <Option className="country-option" key={country} value={country}>
              {`${country}`}
            </Option>
          );
        })
      );
    }
    let emailLimits;

    if (_.get(portalSettings, emailLimits, false)) {
      emailLimits = (
        <div className="email-limits">
          <div className="email-limits-heading">Set application default email limits:</div>
          <div>
            <div className="max-total-emails">
              <div className="max-total-emails-heading">Max total emails</div>
              {form.getFieldDecorator('MaxTotalEmails')(<InputNumber className="email-limits-input-number" />)}
            </div>
            <div className="max-daily-emails">
              <div className="max-daily-emails-heading">Max daily emails</div>
              {form.getFieldDecorator('MaxDailyEmails')(<InputNumber className="email-limits-input-number" />)}
            </div>
            <div className="email-batch-count">
              <div className="email-batch-count-heading">Email batch count</div>
              {form.getFieldDecorator('EmailBatchCount')(<InputNumber className="email-limits-input-number" />)}
            </div>
            <div className="applicant-count">
              <div className="applicant-count-heading">Applicant count</div>
              {form.getFieldDecorator('ApplicantCount')(<InputNumber className="email-limits-input-number" />)}
            </div>
          </div>
        </div>
      );
    }
    const state = store.getState();
    const userConfig = state?.ConfigReducer?.UserConfig ?? {};
    const _jobBoard = getSourceDisplayName({ Portal: jobBoard }, userConfig, null, {}, whiteLabelInfo);
    return (
      <div className="jobboard-integration">
        <div className="jobboard-header">
          <div className="jobboard-image-wrapper">
            <div className="jobboard-image">
              <Tooltip title={_jobBoard}>{this.getJobBoardIcon(jobBoard)}</Tooltip>
            </div>
            <div className="job-board-tag">{getJobBoardTag(jobBoard)}</div>
            <div className="job-board-name">{getJobBoardName(jobBoard, whiteLabelInfo, userConfig)}</div>
          </div>
          <div className="add-account">
            <Button
              shape="round"
              icon="plus"
              className="add-account-btn"
              onClick={this.addAccount}
              disabled={
                ['resumelibrary', 'active', 'passive', 'indeed'].includes(jobBoard.toLowerCase()) ||
                (selectedCountries && allCountries && selectedCountries.length === allCountries.length) ||
                newAccount !== undefined ||
                isSingleCredJobBoard
              }
            >
              Add account
            </Button>
          </div>
        </div>
        <div className="jobboard-integration-login-form">
          {!['resumelibrary', 'active', 'passive', 'indeed'].includes(jobBoard.toLowerCase()) ? (
            portalCredentials.map((account, index) =>
              getJobBoardIntegrationLoginForm(
                {
                  jobBoard,
                  authenticate,
                  disconnect,
                  portalSettings,
                  Countries,
                  countriesMenu,
                  account,
                  index,
                  clearAccount: this.clearAccount,
                  addSelectedCountry: this.addSelectedCountry,
                  removeSelectedCountry: this.removeSelectedCountry,
                  credentialsValidator,
                  sourceConfig,
                  AppName,
                  isAuthenticated: (sourceConfig.IsAuthorized || isPostingJobboard) && !_.isEmpty(account),
                  userConfig,
                },
                isPostingJobboard
              )
            )
          ) : (
            <NewJobBoardLoginForm
              {...{
                jobBoard,
                authenticate,
                disconnect,
                portalSettings,
                Countries,
                countriesMenu,
                account: _.get(portalCredentials, [0], { IsSystemOwned: false }),
                index: 0,
                clearAccount: this.clearAccount,
                addSelectedCountry: this.addSelectedCountry,
                removeSelectedCountry: this.removeSelectedCountry,
                credentialsValidator,
                portalText: _jobBoard,
                integrateSource,
                sourceConfig,
                AppName,
                showAuthenticateButton: jobBoard.toLowerCase() !== 'indeed',
                userConfig,
              }}
            />
          )}
          {_.get(portalSettings, 'AuthenticationApiStatus', '') === 'FAILED' &&
          _.get(portalSettings, 'PortalCredentials', false) ? (
            <div style={{ color: 'red', fontSize: 12 }}>Invalid Username or Password</div>
          ) : null}
          {emailLimits}
        </div>
      </div>
    );
  }
}

JobBoardSettings.propTypes = {
  authenticate: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired,
  jobBoard: PropTypes.string.isRequired,
};

export default Form.create()(JobBoardSettings);
