import React from 'react';
import { Icon } from 'antd';

const SMSSvg = props => (
  <svg width={14} height={12} fill="none" {...props}>
    <path
      d="M13.5.5H.5A.5.5 0 0 0 0 1v10a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V1a.5.5 0 0 0-.5-.5Zm-.625 1.731v8.144H1.125V2.231l-.431-.336.614-.789.669.52h10.048l.669-.52.614.79-.433.335Zm-.85-.606L7 5.531 1.975 1.625l-.669-.52-.614.789.431.336 5.338 4.15a.875.875 0 0 0 1.073 0l5.341-4.149.431-.336-.614-.789-.667.519Z"
      fill="#83878C"
    />
  </svg>
);

const SMSIcon = props => <Icon component={() => SMSSvg(props)} {...props} />;

export default SMSIcon;
