import { apiV3Client } from './BaseRepository';

export function fetchTitleSuggestions({ jobId, candidateId, negativeValue }) {
  return apiV3Client.get(`jobs/${jobId}/candidates/${candidateId}/suggestions/title?negativeTitle=${negativeValue}`);
}

export function fetchOccupationSuggestions({ jobId, candidateId, negativeValue }) {
  return apiV3Client.get(
    `jobs/${jobId}/candidates/${candidateId}/suggestions/occupation?negativeOccupation=${negativeValue}`
  );
}
export function fetchIndustrySuggestions({ jobId, candidateId, negativeValue }) {
  return apiV3Client.get(
    `jobs/${jobId}/candidates/${candidateId}/suggestions/industry?negativeIndustry=${negativeValue}`
  );
}
export function fetchCompanySuggestions({ jobId, candidateId, negativeValue }) {
  return apiV3Client.get(
    `jobs/${jobId}/candidates/${candidateId}/suggestions/company?negativeCompany=${negativeValue}`
  );
}

export function fetchSkillSuggestions({ jobId, candidateId, skills }) {
  return apiV3Client.get(
    `jobs/${jobId}/candidates/${candidateId}/suggestions/skill?skills=${encodeURIComponent(skills)}`
  );
}

export function fetchSkillsSuggestionsForJob(jobId, skills) {
  return apiV3Client.get(`jobs/${jobId}/suggestions/skill?skills=${encodeURIComponent(skills)}`);
}
