import React from 'react';
import moment from 'moment';
import { FormSelectComponent } from '../../../../../../Utils/FormComponentUtils';

const timeRangeOptionValues = [
  {
    value: 'day',
    label: 'Today',
  },
  {
    value: 'week',
    label: 'Current Week',
  },
  {
    value: 'month',
    label: 'Month to Date',
  },
  {
    value: 'quarter',
    label: 'Quarter to Date',
  },
  {
    value: 'year',
    label: 'Year to Date',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export function getDateRange(rangeKey) {
  switch (rangeKey?.toLowerCase()) {
    case 'day':
      return [moment().startOf('day'), moment()];
    case 'week':
      return [moment().startOf('week'), moment()];
    case 'month':
      return [moment().startOf('month'), moment()];
    case 'quarter':
      return [moment().startOf('quarter'), moment()];
    case 'year':
      return [moment().startOf('year'), moment()];
    case 'custom':
    default:
      return [];
  }
}

export default function TimeRangeSelect(props) {
  const { form, label, className, changeDateRange, fieldDecoratorValue, initialValue, onChange } = props;

  const onSelect = rangePresetKey => {
    const dateRange = getDateRange(rangePresetKey);
    changeDateRange(dateRange);
  };

  return (
    <FormSelectComponent
      fieldDecoratorValue={fieldDecoratorValue}
      placeholder="Select range"
      className={className}
      showArrow
      label={label}
      form={form}
      initialValue={initialValue}
      inputValues={timeRangeOptionValues}
      onSelect={onSelect}
      onChange={onChange}
      rules={[{ required: true, message: 'Please select time range' }]}
    />
  );
}

TimeRangeSelect.defaultProps = {
  fieldDecoratorValue: 'dateRangeKey',
};
