import React from 'react';
import { Icon, Button, Badge, Tooltip } from 'antd';
import RecallJobDistribution from '../RecallJobDistribution/RecallJobDistribution';
import { JobBoardPortals, GetJobBoardIcon } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import styles from './JobBoardPublishComponent.module.scss';
import { isExpiredDateTime } from '../../../Utils/RelativeTimeCalculator';
import eventTypes from '../../../Analytics/EventTypes';

export default function JobBoardPublishComponent(props) {
  const {
    onPublishPortal,
    portals,
    currentUser,
    distributedJobInfo,
    distributedPortals,
    recalledPortals,
    recallPortalJobDistribution,
    aryaJobStatus,
  } = props;

  const [recallVisible, setRecallVisible] = React.useState(false);

  const isPublished = distributedPortals?.find(p => p.toLowerCase() === JobBoardPortals[0]);
  const isRecalled =
    recalledPortals?.find(p => p.toLowerCase() === JobBoardPortals[0]) ||
    isExpiredDateTime(distributedJobInfo.ExpiryDateTime);
  const isPublishClosed = !isPublished && aryaJobStatus === 'Closed';

  const hasEditAccess =
    currentUser?.Id === distributedJobInfo.CreatedBy || currentUser?.Id === distributedJobInfo.UpdatedBy;

  const editButton = () =>
    hasEditAccess ? (
      <div
        className={styles.editButton}
        role="presentation"
        onClick={() => onPublishPortal(portals[0])}
        sk-event-name={eventTypes.job.publishJob.editPublishJob}
      >
        <Icon type="edit" theme="outlined" style={{ color: '#13c26b' }} /> Edit
      </div>
    ) : null;

  const postButton = () => {
    const postBtn = (
      <Button
        type="secondary"
        size="small"
        shape="round"
        onClick={() => onPublishPortal(portals[0])}
        disabled={isPublishClosed || isRecalled}
        sk-event-name={eventTypes.job.publishJob.goToPublishJob}
      >
        Post
      </Button>
    );
    if (isRecalled)
      return (
        <Tooltip title="You can't post this job because it is either expired or has been closed">{postBtn}</Tooltip>
      );
    if (isPublishClosed) return <Tooltip title="You can not publish the closed job">{postBtn}</Tooltip>;
    return postBtn;
  };

  const portalsList = () => {
    const badgeStatus = isPublished ? 'success' : 'error';
    return portals.map(portal => (
      <div className={styles.portalList}>
        <div className={styles.portalLeftSection}>
          <GetJobBoardIcon portal={portal} style={{ fontSize: '25px' }} />
          {isPublished || isRecalled ? (
            <Badge status={badgeStatus} className={styles.activeBadge} />
          ) : (
            <div className={styles.inactiveBadge}></div>
          )}
          <div className={styles.portalName}>{portal}</div>
        </div>
        <div className={styles.portalRightSection}>
          {isPublished ? (
            <>
              {editButton()}
              <Button
                disabled={!hasEditAccess}
                size="small"
                type="secondary"
                onClick={() => setRecallVisible(true)}
                sk-event-name={eventTypes.job.publishJob.recallPublishJob}
              >
                Stop
              </Button>
            </>
          ) : (
            postButton()
          )}
        </div>
      </div>
    ));
  };

  const onRecallConfirm = () => {
    setRecallVisible(false);
    recallPortalJobDistribution();
  };

  return (
    <div className={styles.publishJobWrapper}>
      <RecallJobDistribution
        visible={recallVisible}
        onCancel={() => setRecallVisible(false)}
        onConfirm={onRecallConfirm}
        portal={portals[0]}
      />
      <div className={styles.publishJobTitleWrapper}>Publish To Job Boards</div>
      <div className={styles.publishDescriptionWrapper}>
        You can post this job to below platforms. Candidate will be brought to sourced list.
      </div>
      <div className={styles.publishActionsWrapper}>{portalsList()}</div>
    </div>
  );
}
