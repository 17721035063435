import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Select } from 'antd';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import styles from './CampaignTemplateSelect.module.scss';
import LinkButton from '../Common/LinkButton/LinkButton';

const { Option } = Select;

const mapDispatchToProps = {
  fetchCampaignTitleSuggestions: CandidateAdvanceFilterActions.fetchCampaignTitleSuggestions,
};

const CampaignTemplateSelect = props => {
  const {
    fetchCampaignTitleSuggestions,
    placeholderValue,
    cssClassname,
    onCampaignSelect,
    onClickCreateCampaign,
    onChange,
    handleCampaignTitle,
    createCampaignTitle,
    isCreateCampaign,
    jobDetails,
  } = props;
  const [campaignAutoCompleteApiStatus, setCampaignAutoCompleteApiStatus] = React.useState();
  const [options, setOptions] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSelect = (value, e) => {
    const desiredOption = options?.find(option => option?.Id?.toString() === e.key);
    if (desiredOption) {
      handleCampaignTitle(value);
      onCampaignSelect({ templateId: desiredOption.TemplateId, id: desiredOption.Id });
      onChange(desiredOption?.FromAddress);
    } else {
      onCampaignSelect({ templateId: undefined, id: 0 });
    }
    onClickCreateCampaign(false);
  };

  const handleChange = (value, e) => {
    if (!isCreateCampaign) {
      handleCampaignTitle(value);
      onCampaignSelect({ templateId: undefined, id: 0 });
    } else {
      handleCampaignTitle(value);
    }
  };

  const handleCreateCampaignButtonClick = () => {
    handleCampaignTitle(searchTerm);
    onClickCreateCampaign(true);
  };

  const debounceCampaignResults = React.useCallback(
    debounce(async nextValue => {
      const response = await fetchCampaignTitleSuggestions(nextValue);
      setOptions(response?.Campaigns);
      setCampaignAutoCompleteApiStatus('COMPLETED');
    }, 600),
    []
  );

  const handleCampaignSearch = (value, isCreateCampaignEnabled) => {
    setSearchTerm(value);
    if (!isCreateCampaignEnabled) {
      setCampaignAutoCompleteApiStatus('INPROGRESS');
      debounceCampaignResults({ jobId: jobDetails?.JobId, searchTerm: value });
    } else if (options?.length > 0 && isCreateCampaignEnabled) {
      setOptions([]);
    }
  };

  const getContentNotFound = () => {
    if (isCreateCampaign) {
      return null;
    }
    if (searchTerm)
      return campaignAutoCompleteApiStatus === 'INPROGRESS' ? (
        'Loading...'
      ) : (
        <div className={styles.contentNotFound}>
          <LinkButton buttonName="Create new campaign" onClickButton={handleCreateCampaignButtonClick} />
          <div className={styles.createCampaignButtonContent}>`{searchTerm}`</div>
        </div>
      );
    return 'Type to search';
  };

  const notFoundContent = getContentNotFound();

  return (
    <Select
      allowClear={!isCreateCampaign}
      placeholder={placeholderValue}
      onSearch={value => handleCampaignSearch(value, isCreateCampaign)}
      onSelect={handleSelect}
      showSearch
      value={createCampaignTitle}
      notFoundContent={notFoundContent}
      dropdownStyle={{ zIndex: 2200 }}
      showArrow={false}
      className={cssClassname}
      onChange={handleChange}
      getPopupContainer={trigger => trigger.parentNode}
      mode={isCreateCampaign ? 'combobox' : undefined}
      data-testid="campaign-select"
    >
      {options?.map(option => (
        <Option key={option.Id} value={option.CampaignName} title={option.CampaignName}>
          {option.CampaignName}
        </Option>
      ))}
    </Select>
  );
};

export default connect(null, mapDispatchToProps)(CampaignTemplateSelect);
export { CampaignTemplateSelect as CampaignTemplateSelectWithoutStore };
