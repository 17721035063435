import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { connect } from 'react-redux';
import { getResendCodeStatus } from '../../Reducers/PulseSignupReducer';
import { resendVerificationCode as _resendVerificationCode } from '../../Actions/PulseSignupActions';
import SignupVerification from '../Signup/SignupVerification.tsx';

const mapStateToProps = state => ({
  resendCodeStatus: getResendCodeStatus(state),
});

const mapDispatchToProps = {
  resendVerificationCode: _resendVerificationCode,
};

class ConfirmUserSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);

    this._validAuthStates = ['confirmSignUp'];
  }

  showComponent() {
    const { resendCodeStatus, resendVerificationCode } = this.props;
    const { username } = this.props.authData;
    return (
      <div style={{ background: 'linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%)' }}>
        <SignupVerification
          email={username}
          resendVerificationCode={resendVerificationCode}
          resendCodeStatus={resendCodeStatus}
          confirmUserSignUp
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUserSignUp);
