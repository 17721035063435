import React from 'react';
import { Icon } from 'antd';

export const HamburgerSvg = props => (
  <svg {...props}>
    <g stroke="#979797" strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M1.417 1.538h19.166M1.417 8h19.166M1.417 14.462h19.166" />
    </g>
  </svg>
);

const HamburgerIcon = props => <Icon component={HamburgerSvg} {...props} />;

export default HamburgerIcon;
