import React from 'react';
import _ from 'lodash';
import { Divider } from 'antd';
import styles from './BlsDataDisplay.module.scss';

export default function BlsDataDisplay({ blsData = {} }) {
  const { MetroData: areaData, CountryData: countryData, StateData: stateData } = blsData;
  const projectedGrowth = countryData?.ProjectedGrowth;
  const countryMedianPay = countryData?.MedianPay;
  const stateMedianPay = stateData?.MedianPay;
  const areaMedianPay = areaData?.MedianPay;
  const isBlsDataAvailable = !_.isEmpty(countryData) || !_.isEmpty(stateData) || !_.isEmpty(areaData);
  return isBlsDataAvailable ? (
    <div className={styles.blsIntel}>
      <div className={styles.blsDataHeader}> BLS Data </div>
      <div key="CountryBlsData" className={styles.blsDataContainer}>
        <div className={styles.locationHeader}>Country {countryData.Country ? `(${countryData.Country}) :` : ':'} </div>
        <div className={styles.blsDataGroup}>
          <div className={styles.blsDataValue}>
            {countryMedianPay ? `$${countryMedianPay?.toLocaleString()} yr` : 'NA'}
          </div>
          <div className={styles.blsDataLabel}>Median Pay</div>
        </div>
        <div className={styles.blsDataGroup}>
          <div className={styles.blsDataValue}>
            {countryData?.TotalEmployment ? countryData?.TotalEmployment?.toLocaleString() : 'NA'}
          </div>
          <div className={styles.blsDataLabel}>Number of Jobs</div>
        </div>
        <div className={styles.blsDataGroup}>
          <div className={styles.blsDataValue}>{projectedGrowth ? `${projectedGrowth}` : 'NA'}</div>
          <div className={styles.blsDataLabel}>Projected Growth</div>
        </div>
        <div className={styles.blsDataGroup}>
          <div className={styles.blsDataValue}>{countryData?.EntryEducation ?? 'NA'}</div>
          <div className={styles.blsDataLabel}>Entry Education</div>
        </div>
      </div>
      {!_.isEmpty(stateData) ? (
        <div key="StateBlsData" className={styles.blsDataContainer}>
          <div className={styles.locationHeader}> State {stateData.StateCode ? `(${stateData.StateCode}) :` : ':'}</div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>
              {stateMedianPay ? `$${stateMedianPay?.toLocaleString()} yr` : 'NA'}
            </div>
            <div className={styles.blsDataLabel}>Median Pay</div>
          </div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>{stateData?.JobsCount?.toLocaleString() ?? 'NA'}</div>
            <div className={styles.blsDataLabel}>Number of Jobs</div>
          </div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>{stateData?.LocationQuotient ?? 'NA'}</div>
            <div className={styles.blsDataLabel}>Location Quotient</div>
          </div>
        </div>
      ) : null}
      {!_.isEmpty(areaData) ? (
        <div key="AreaBlsData" className={styles.blsDataContainer}>
          <div className={styles.locationHeader}> {areaData.MetroArea ? `${areaData.MetroArea} ` : ''}Metro Area: </div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>{areaMedianPay ? `$${areaMedianPay.toLocaleString()} yr` : 'NA'}</div>
            <div className={styles.blsDataLabel}>Median Pay</div>
          </div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>{areaData?.JobsCount?.toLocaleString() ?? 'NA'}</div>
            <div className={styles.blsDataLabel}>Number of Jobs</div>
          </div>
          <div className={styles.blsDataGroup}>
            <div className={styles.blsDataValue}>{areaData?.LocationQuotient ?? 'NA'}</div>
            <div className={styles.blsDataLabel}>Location Quotient</div>
          </div>
        </div>
      ) : null}
      <Divider />
    </div>
  ) : null;
}
