import React from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { getNotification } from '../../Reducers/AppNotificationReducer';
import markNotificationCompletionAction from '../../Actions/AppNotificationActions';

const mapStateToProps = state => ({
  notification: getNotification(state),
});

const mapDispatchToProps = {
  markNotificationCompletion: markNotificationCompletionAction,
};

class AppToastNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const { notification, markNotificationCompletion } = props;
    if (notification) {
      switch (notification.Type) {
        case 'LOADING':
          message.loading(notification.Message, notification.Duration || 3);
          markNotificationCompletion();
          break;
        case 'SUCCESS':
          message.success(notification.Message, notification.Duration || 3);
          markNotificationCompletion();
          break;
        case 'ERROR':
          message.error(notification.Message, notification.Duration || 3);
          markNotificationCompletion();
          break;
        default:
          break;
      }
    }
    return state;
  }

  render() {
    return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppToastNotificationContainer);
