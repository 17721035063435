import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import './TemplatesSidebar.scss';
import messages from './messages';

export default function TemplatesSidebar(props) {
  const { templateTypes, onTemplateTypeChange, AppName } = props;
  const sidebar = templateTypes.map(templateType => (
    <Menu.Item className="template-type" key={templateType}>
      <FormattedMessage {...messages[templateType]} values={{ AppName }} />
    </Menu.Item>
  ));

  return (
    <Menu
      className="template-types"
      mode="inline"
      onClick={onTemplateTypeChange}
      defaultSelectedKeys={[templateTypes[0]]}
    >
      {sidebar}
    </Menu>
  );
}

TemplatesSidebar.propTypes = {
  templateTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTemplateTypeChange: PropTypes.func.isRequired,
};
