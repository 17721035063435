import React from 'react';
import { Button } from 'antd';
import PlusIcon from '../../../../Icons/PlusIcon';

export const AddButton = props => {
  const { onClick } = props;
  return (
    <Button
      style={{ border: '0px', padding: '0px', color: '#1F2730', display: 'flex', alignItems: 'center' }}
      size="small"
      onClick={() => onClick(props)}
    >
      <PlusIcon style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }} />
    </Button>
  );
};
