import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logout: {
    id: 'Auth.Logout',
    defaultMessage: 'Logout',
  },
  gender: {
    id: 'Auth.Gender',
    defaultMessage: 'Gender',
  },
  family_name: {
    id: 'Auth.FamilyName',
    defaultMessage: 'Last Name',
  },
  given_name: {
    id: 'Auth.GivenName',
    defaultMessage: 'First Name',
  },
  phone_number: {
    id: 'Auth.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  zoneinfo: {
    id: 'Auth.Zoneinfo',
    defaultMessage: 'Zoneinfo',
  },
  profile: {
    id: 'Auth.Profile',
    defaultMessage: 'Profile',
  },
  locale: {
    id: 'Auth.Locale',
    defaultMessage: 'Locale',
  },
  picture: {
    id: 'Auth.Picture',
    defaultMessage: 'Picture',
  },
  middle_name: {
    id: 'Auth.MiddleName',
    defaultMessage: 'Middle Name',
  },
});

export default messages;
