import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Button } from 'antd';
import { PulseTextWithAryaLogoIcon } from '../../../Icons/AryaIcons';
import PasswordResetSuccessIcon from '../../../Icons/PasswordResetSuccessIcon';
import Config from '../../../Config/Config';
import AppLogo from '../AppLogo';
import styles from './PasswordResetConfirmation.module.scss';

class PasswordResetConfirmation extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['passwordResetConfirmation'];
  }

  handleBackToLogin = () => {
    super.changeState('signIn');
  };

  showComponent() {
    const { location } = window;
    const { hostname } = location;
    const isAppNamePulse = hostname === Config.urls.pulseHostName;

    return (
      <div className={isAppNamePulse ? styles.pulsePasswordResetContainer : styles.aryaPasswordResetContainer}>
        {!isAppNamePulse ? <AppLogo /> : null}
        <div className={styles.passwordResetWrapper}>
          {isAppNamePulse ? (
            <div className={styles.pulseLogo}>
              <PulseTextWithAryaLogoIcon />
            </div>
          ) : null}
          <span className={styles.icon}>
            <PasswordResetSuccessIcon className={styles.iconSize} />
          </span>
          <span className={styles.passwordUpdateText}>We have successfully updated your password!</span>
          <Button className={styles.backToLogin} type="primary" onClick={this.handleBackToLogin}>
            Back to Login
          </Button>
        </div>
      </div>
    );
  }
}
export default PasswordResetConfirmation;
