import * as jobRepository from '../Repository/JobRepository';
import {
  setDilatedIntel,
  setDilatedCandidateCount,
  setAllDilatedCandidateCount,
} from './ActionCreators/DilatedIntelActionCreator';
import { setMustHavesForJob } from './ActionCreators/JobActionCreator';

export function fetchDilatedCandidateCount(jobId, filter) {
  return async dispatch => {
    try {
      const response = await jobRepository.fetchDilatedCandidateCount(jobId, filter);
      const payload = response.data;
      dispatch(setDilatedCandidateCount(jobId, payload));
      if (!filter) {
        dispatch(setAllDilatedCandidateCount(jobId, payload));
      }
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'FETCH_DILATED_COUNT_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function fetchAllDilatedCandidateCount(jobId) {
  return async dispatch => {
    await dispatch(fetchDilatedCandidateCount(jobId));
  };
}

export function fetchJobDilatedIntel(jobId, statuses, fetchCount = false) {
  return async dispatch => {
    try {
      const response = await jobRepository.fetchJobDilatedIntel(jobId, statuses);
      const payload = response.data;
      dispatch(setDilatedIntel(jobId, payload, statuses));
      if (fetchCount) {
        await dispatch(fetchAllDilatedCandidateCount(jobId));
      }
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'FETCH_DILATED_INTEL_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function applyJobDilatedIntel(jobId, dilatedIntel, action) {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_APPLY_INTEL_API_STATUS',
        payload: {
          action,
          status: 'INPROGRESS',
        },
      });
      await jobRepository.applyJobDilatedIntel(jobId, dilatedIntel);
      await dispatch(fetchJobDilatedIntel(jobId, ['AryaSuggested']));
      dispatch({
        type: 'SET_APPLY_INTEL_API_STATUS',
        payload: {
          action,
          status: 'COMPLETED',
        },
      });
      dispatch({
        type: 'SET_ALERT_NOTIFICATION',
        payload: {
          Type: 'ALERT_OPEN',
          Message: 'Thank you.',
          Description:
            'Arya is working on some additional candidates for you. They will be displayed here shortly. Thanks for your patience!',
        },
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'APPLY_DILATED_INTEL_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch({
        type: 'SET_APPLY_INTEL_API_STATUS',
        payload: {
          action,
          status: 'FAILED',
        },
      });
    }
  };
}

export function applyRefineCriteria(jobId, refineCriteria, action, AppName) {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_REFINE_CRITERIA_API_STATUS',
        payload: {
          action,
          status: 'INPROGRESS',
        },
      });
      await jobRepository.updateJobIntelligence(jobId, refineCriteria);
      dispatch({
        type: 'SET_JOB_DETAILS',
        payload: {
          quickSearchParams: refineCriteria,
          JobId: jobId,
        },
      });
      dispatch(setMustHavesForJob(jobId, refineCriteria?.Keywords?.Musts));
      dispatch({
        type: 'SET_REFINE_CRITERIA_API_STATUS',
        payload: {
          action,
          status: 'COMPLETED',
        },
      });
      dispatch({
        type: 'SET_ALERT_NOTIFICATION',
        payload: {
          Type: 'ALERT_OPEN',
          Message: 'Criteria is saved successfully',
          Description: `${AppName} is working on some additional candidates for you. They will be displayed here shortly. Thanks for your patience!`,
        },
      });
    } catch {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'ERROR',
          Message: 'Oops, something just went wrong. Please try again.',
        },
      });
      dispatch({
        type: 'SET_REFINE_CRITERIA_API_STATUS',
        payload: {
          action,
          status: 'FAILED',
        },
      });
    }
  };
}
