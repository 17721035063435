import * as autocompleteRepository from '../Repository/AutocompleteRepository';

function fetchSkillSuggestions({ searchTerm, includeSynonyms }) {
  return async () => {
    try {
      const response = await autocompleteRepository.fetchSkillAutocomplete({ searchTerm, includeSynonyms });
      return response.data;
    } catch (error) {
      return {};
    }
  };
}

function fetchJobTitleAutocomplete({ from, size, title }) {
  return async () => {
    const response = await autocompleteRepository.fetchJobTitleAutocomplete({ from, size, title });
    return response.data;
  };
}

export { fetchJobTitleAutocomplete, fetchSkillSuggestions };
