import { setApiStatus } from '../ApiStatusActions';

export const SET_DISTRIBUTION_PORTALS = 'SET_JOB_DISTRIBUTION_PORTALS';
export const SET_ALL_JOB_DISTRIBUTE_PORTAL_CREDENTIALS = 'SET_ALL_JOB_DISTRIBUTE_PORTAL_CREDENTIALS';
export const SET_JOB_PORTAL_AUTHENTICATE_STATUS = 'SET_JOB_PORTAL_AUTHENTICATE_STATUS';
export const SET_JOB_PORTAL_CREDENTIALS = 'SET_JOB_PORTAL_CREDENTIALS';
export const DELETE_JOB_PORTAL_CREDENTIALS = 'DELETE_JOB_PORTAL_CREDENTIALS';
export const SET_FETCH_NOTIFICATION_CONFIG_API_STATUS = 'SET_FETCH_NOTIFICATION_CONFIG_API_STATUS';
export const SET_ACTIVATE_ALL_NOTIFICATION_TYPE_API_STATUS = 'SET_ACTIVATE_ALL_NOTIFICATION_TYPE_API_STATUS';
export const SET_UPDATE_NOTIFICATION_TYPE_CONFIG_API_STATUS = 'SET_UPDATE_NOTIFICATION_TYPE_CONFIG_API_STATUS';
export const SET_UPDATE_NOTIFICATION_ID_CONFIG_API_STATUS = 'SET_UPDATE_NOTIFICATION_ID_CONFIG_API_STATUS';
export const SET_NOTIFICATION_CONFIG = 'SET_NOTIFICATION_CONFIG';
export const SET_NOTIFICATION_TYPE_CONFIG = 'SET_NOTIFICATION_TYPE_CONFIG';
export const SET_NOTIFICATION_ID_CONFIG = 'SET_NOTIFICATION_ID_CONFIG';
export const SET_ALL_ACTIVE_BY_NOTIFICATION_TYPE = 'SET_ALL_ACTIVE_BY_NOTIFICATION_TYPE';
export const SET_FEATURE_ADDITIONAL_INFO = 'SET_FEATURE_ADDITIONAL_INFO';
export const SET_MASKING_CONFIG = 'SET_MASKING_CONFIG';
export const SET_ORG_MASKING_CONFIG = 'SET_ORG_MASKING_CONFIG';
export const SET_NAVBAR_RENDER_FLAG = 'SET_NAVBAR_RENDER_FLAG';
export const SET_CANDIDATE_FILTER_DOWNLOAD_CONFIG = 'SET_CANDIDATE_FILTER_DOWNLOAD_CONFIG';
export const SET_CANDIDATE_FILTER_COMMUNICATION_CONFIG = 'SET_CANDIDATE_FILTER_COMMUNICATION_CONFIG';

export const setDistributionPortals = payload => ({
  type: SET_DISTRIBUTION_PORTALS,
  payload,
});

export const setAllJobDistributionCredentials = payload => ({
  type: SET_ALL_JOB_DISTRIBUTE_PORTAL_CREDENTIALS,
  payload,
});

export const setCandidateFilterDownloadConfig = payload => ({
  type: SET_CANDIDATE_FILTER_DOWNLOAD_CONFIG,
  payload,
});

export const setCandidateFilterCommunicationConfig = payload => ({
  type: SET_CANDIDATE_FILTER_COMMUNICATION_CONFIG,
  payload,
});

export const setJobPortalAuthenticationStatus = (portal, index, status) => ({
  type: SET_JOB_PORTAL_AUTHENTICATE_STATUS,
  payload: {
    portal,
    status: {
      [index]: status,
    },
  },
});

export const setJobPortalCredentials = (portal, credentials) => ({
  type: SET_JOB_PORTAL_CREDENTIALS,
  payload: {
    portal,
    credentials,
  },
});

export const removeJobPortalCredentials = (portal, countryCode) => ({
  type: DELETE_JOB_PORTAL_CREDENTIALS,
  payload: {
    portal,
    countryCode,
  },
});

export const setNotificationConfig = payload => ({
  type: SET_NOTIFICATION_CONFIG,
  payload,
});

export const setNotificationTypeConfig = (type, config) => ({
  type: SET_NOTIFICATION_TYPE_CONFIG,
  payload: {
    type,
    config,
  },
});

export const setAllActiveByNotificationType = (type, config) => ({
  type: SET_ALL_ACTIVE_BY_NOTIFICATION_TYPE,
  payload: {
    type,
    config,
  },
});

export const setNotificationIdConfig = (id, config) => ({
  type: SET_NOTIFICATION_ID_CONFIG,
  payload: {
    id,
    config,
  },
});

export const setFetchNotificationConfigApiStatus = status => ({
  type: SET_FETCH_NOTIFICATION_CONFIG_API_STATUS,
  payload: {
    status,
  },
});

export const setActivateAllNotificationTypeApiStatus = (type, status) => ({
  type: SET_ACTIVATE_ALL_NOTIFICATION_TYPE_API_STATUS,
  payload: {
    type,
    status,
  },
});

export const setUpdateNotificationTypeConfigApiStatus = (type, status) => ({
  type: SET_UPDATE_NOTIFICATION_TYPE_CONFIG_API_STATUS,
  payload: {
    type,
    status,
  },
});

export const setUpdateNotificationIdConfigApiStatus = (id, status) => ({
  type: SET_UPDATE_NOTIFICATION_ID_CONFIG_API_STATUS,
  payload: {
    id,
    status,
  },
});

export const setFeaturesAdditionalInfo = (featureNames, additionalInfoDictionary) => ({
  type: SET_FEATURE_ADDITIONAL_INFO,
  payload: {
    featureNames,
    additionalInfoDictionary,
  },
});
export const setMaskingConfig = payload => ({
  type: SET_MASKING_CONFIG,
  payload,
});

export const setOrgMaskingConfig = payload => ({
  type: SET_ORG_MASKING_CONFIG,
  payload,
});

export function setOrgMaskingConfigApiStatus(status) {
  const apiName = 'fetchOrgMaskingApiStatus';
  return setApiStatus({ apiName, status });
}

export const setNavbarRenderFlag = payload => ({
  type: SET_NAVBAR_RENDER_FLAG,
  payload,
});
