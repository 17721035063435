import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Badge } from 'antd';
import { getRelativeTimeSpanFromNow } from '../../../Utils/RelativeTimeCalculator';
import { getConfig } from '../../../Reducers/ConfigReducer';
import styles from './CreditsExhaustedNotification.module.scss';

function getNotificationInfo() {
  const userConfig = useSelector(state => getConfig(state));
  return (
    <div className="message">
      {userConfig?.Role !== 'Recruiter' ? (
        <span>
          Please contact <a href="mailto:support@leoforce.com">support@leoforce.com</a> to secure more credits.
        </span>
      ) : (
        <span>Please contact your administrator to add credits to your account.</span>
      )}
    </div>
  );
}

export default function CreditsExhaustedNotification(props) {
  const { notification } = props;
  const { UpdatedTime, UnreadCount } = notification;
  return (
    <div className="notification" role="presentation">
      <div className={styles.creditsExhaustedImageWrapper}>
        <div className={styles.closeIcon}>
          <Icon type="close-circle" theme="filled" />
        </div>
      </div>
      <div className="content">
        <div className="notification-title">
          <span className="bold">Low Credits!</span>
        </div>
        {getNotificationInfo()}
        <div className="actions" />
        <div className="since">{getRelativeTimeSpanFromNow(UpdatedTime)}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}
