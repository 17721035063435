export const JobStatusOptions = ['Full Time', 'Part Time', 'Per Diem'];

export const JobTypeOptions = [
  'Employee',
  'Temporary/Contract',
  'Intern',
  'Seasonal',
  'Volunteer',
  'CCL Sponsored',
  'Temporary',
];

export const EducationLevelOptions = [
  'Unspecified',
  'High School or equivalent',
  'Certification',
  'Vocational',
  'Associate Degree',
  "Bachelor's Degree",
  "Master's Degree",
  'Doctorate',
  'Professional',
  'Some College Coursework Completed',
  'Vocational - HS Diploma',
  'Vocational - Degree',
  'Some High School Coursework',
];

export const CareerLevelOptions = [
  'Student (High School)',
  'Student (Undergraduate/Graduate)',
  'Entry Level',
  'Experienced (Non-Manager)',
  'Manager (Manager/Supervisor of Staff)',
  'Executive (SVPpartment Headc)',
  'Senior Executive (PresidentOc)',
];

export const JobCategotyOptions = [
  'Accounting/Finance/Insurance',
  'Administrative/Clerical',
  'Education/Training',
  'Engineering',
  'Human Resources',
  'Legal',
  'Sales/Retail/Business Development',
  'Other',
  'Food Services/Hospitality',
  'Manufacturing/Production/Operations',
  'Building Construction/Skilled Trades',
  'Customer Support/Client Care',
  'Installation/Maintenance/Repair',
  'Security/Protective Services',
  'Banking/Real Estate/Mortgage Professionals',
  'Biotech/R&D/Science',
  'IT/Software Development',
  'Business/Strategic Management',
  'Medical/Health',
  'Editorial/Writing',
  'Logistics/Transportation',
  'Marketing/Product',
  'Project/Program Management',
  'Creative/Design',
  'Quality Assurance/Safety',
  'AgriculturerestryFishing',
];

export const JobIndustryOptions = [
  'All',
  'Agriculture/Forestry/Fishing',
  'Metals and Minerals',
  'Energy and Utilities',
  'Construction - Industrial Facilities and Infrastructure',
  'Aerospace and Defense',
  'Automotive and Parts Mfg',
  'Biotechnology/Pharmaceuticals',
  'Chemicals/Petro-Chemicals',
  'Consumer Packaged Goods Manufacturing',
  'Electronicsmponentsd Semiconductor Mfg',
  'Manufacturing - Other',
  'Printing and Publishing ',
  'Clothing and Textile Manufacturing',
  'Wholesale Trade/Import-Export',
  'Retail',
  'Travelansportation and Tourism',
  'Transport and Storage - Materials ',
  'Internet Services',
  'Broadcastingsicd Film',
  'Telecommunications Services',
  'Banking',
  'Insurance',
  'Real Estate/Property Management',
  'Rental Services',
  'Accounting and Auditing Services',
  'Advertising and PR Services',
  'Architectural and Design Services',
  'Management Consulting Services',
  'Computer Hardware',
  'Computer Software',
  'Legal Services',
  'Waste Management',
  'Education',
  'Healthcare Services',
  'Performing and Fine Arts',
  'Sports and Physical Recreation',
  'Hotels and Lodging',
  'Restaurant/Food Services',
  'Staffing/Employment Agencies',
  'Nonprofit Charitable Organizations',
  'Personal and Household Services',
  'Government and Military',
  'Security and Surveillance',
  'Automotive Sales and Repair Services',
  'Business Services - Other',
  'Computer/IT Services',
  'Construction - Residential & Commercial/Office',
  'Engineering Services',
  'Entertainment Venues and Theaters',
  'Financial Services',
  'Food and Beverage Production',
  'Marine Mfg & Services',
  'Medical Devices and Supplies',
  'Other/Not Classified',
];

function mapMonsterJobInventoryToDisplayName(inventory) {
  const refreshInfo =
    inventory.InventoryAttributes.AutoRefresh.RefreshDuration > 0
      ? ` AutoRefresh: ${inventory.InventoryAttributes.AutoRefresh.RefreshDuration} days.`
      : '';
  const boldingInfo = !inventory.InventoryAttributes.HasBolding ? '' : ` Bold.`;
  const socialJobAdInfo = !inventory.InventoryAttributes.HasSocialJobAd ? '' : ` Social Job Ads.`;
  const canDurationInfo =
    inventory.InventoryAttributes.CANDuration > 0
      ? ` Career Ad Network: ${inventory.InventoryAttributes.CANDuration} days.`
      : '';
  const availableQuantityInfo = ` Available: ${inventory.Quantity.TotalAvailable}.`;
  const extraParametersInfo = refreshInfo + boldingInfo + socialJobAdInfo + canDurationInfo + availableQuantityInfo;
  return `${inventory.DisplayName}.${extraParametersInfo}`;
}

export { mapMonsterJobInventoryToDisplayName };
