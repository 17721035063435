/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Input, Button, LocaleProvider } from 'antd';
import { RequireNewPassword } from 'aws-amplify-react';
import * as ConfigActions from '../../Actions/ConfigActions';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import messages from './messages';
import AppLogo from './AppLogo';
import AppFooter from '../Footer/Footer';
import config from '../../Config/Config';
import './Auth.scss';

const mapDispatchToProps = {
  fetchWhiteLabelInfo: ConfigActions.fetchWhiteLabelInfo,
};

const mapStateToProps = state => ({
  whiteLabelInfo: getWhiteLabelInformation(state),
});

class RequireNewPasswordForm extends RequireNewPassword {
  constructor(props) {
    const _this = super(props) || this;

    this._validAuthStates = ['requireNewPassword'];
    _this.inputs = _this.inputs || {};
    _this.inputs.gender = 'Not specified';
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    const { whiteLabelInfo } = this.props;
    const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
    this.setState({
      backgroundColor: currentBgColor,
    });
  }

  componentDidUpdate(prevProps) {
    const { whiteLabelInfo } = this.props;
    if (prevProps.whiteLabelInfo !== whiteLabelInfo) {
      const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
      this.setState({
        backgroundColor: currentBgColor,
      });
    }
  }

  change() {
    this.inputs = this.inputs || {};
    this.inputs.gender = 'Not specified';
    super.change();
  }

  showComponent() {
    const { appLocale, location } = window;
    const { backgroundColor } = this.state;
    const { authData: user } = this.props;
    const { requiredAttributes } = user.challengeParam;
    const { hostname } = location;
    const isAppNamePulse = hostname === config.urls.pulseHostName;
    const attributes = requiredAttributes
      .filter(attribute => attribute !== 'gender')
      .map(attribute => (
        <React.Fragment key={attribute}>
            <p className="auth-label">
              <FormattedMessage {...messages[attribute]} />
            </p>
            <FormattedMessage {...messages[attribute]}>
              {msg => (
                <Input
                  id={attribute}
                  key={attribute}
                  name={attribute}
                  onChange={this.handleInputChange}
                  type={attribute}
                  className="auth-input"
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
          </React.Fragment>
      ));
    return (
      <LocaleProvider locale={appLocale.antd}>
        <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
          <div className="auth-container" style={{ backgroundColor }}>
            <AppLogo />
            <div className="auth-form">
              <p className="auth-title">Change Password</p>
              <p className="auth-label">New Password</p>
              <Input
                id="password"
                key="password"
                name="password"
                type="password"
                onChange={this.handleInputChange}
                className="auth-input"
                placeholder="New Password"
              />
              {attributes}
              <Button className="auth-primary-button" type="primary" onClick={this.change}>
                Change
              </Button>
              <Button className="auth-secondary-button" onClick={() => super.changeState('signIn')}>
                <span>Back to Sign In</span>
              </Button>
            </div>
            {!isAppNamePulse ? <AppFooter className="app-footer" /> : null}
          </div>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequireNewPasswordForm);
