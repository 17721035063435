import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Skeleton } from 'antd';
import { connect } from 'react-redux';
import { getGlobalInsightSupplyList } from '../../../Reducers/SalesDashBoardReducer';
import * as SalesDashBoardActions from '../../../Actions/SalesDashBoardActions';
import * as JobActions from '../../../Actions/JobActions';
import * as CandidateActions from '../../../Actions/CandidateActions';
import * as AryaBotContextActions from '../../../Actions/AryabotContextActions';
import * as JobDescriptionActions from '../../../Actions/AryaJobDescriptionActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getUsersById } from '../../../Reducers/UserReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getSampleCandidatesDisplayFlag } from '../../../Reducers/JobStatusReducer';
import { defaultExperienceRanges } from '../../../Utils/GlobalInsightSupplyUtil';
import { getJobDetailsForAnalytics, getIsFreeCandidateStepper } from '../../../Utils/JobUtils';
import { getAdvancedJobFormData, getJobsById } from '../../../Reducers/JobReducer';
import { getCandidateSpecifications } from '../../../Reducers/CandidateReducer';
import { getProductVariantsById, getPlanDetailsByProductVariantId } from '../../../Reducers/AryaPayOrderReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import * as PayOrderActions from '../../../Actions/AryaPayOrderActions';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../../Utils/CreditInfoUtils';
import { generateKeywordsFromBooleanString } from '../../../Utils/BooleanStringUtility';
import { ADVANCED_JOB_INSIGHTS, ADVANCED_JOB_SAVE_DRAFT } from '../../../PubSub/EventTypes';
import AdvancedJobEditHeaderSection from '../../../Components/AdvancedJobCreation/AdvancedJobEditHeaderSection/AdvancedJobEditHeaderSection';
import AdvancedJobDetailsEdit from '../../../Components/AdvancedJobCreation/AdvancedJobDetailsEdit/AdvancedJobDetailsEdit';
import styles from '../../../Components/AdvancedJobCreation/AdvancedJobDetailsEdit/AdvancedJobDetailsEdit.module.scss';

const candidateSpecificationsList = [
  'Employment',
  'WorkAuthorization',
  'PayFrequency',
  'SalaryCurrency',
  'EducationDegree',
];

const mapStateToProps = state => {
  return {
    globalInsightSupplyList: getGlobalInsightSupplyList(state),
    advancedJobFormData: getAdvancedJobFormData(state),
    candidateSpecifications: getCandidateSpecifications(state),
    jobsById: getJobsById(state),
    usersById: getUsersById(state),
    globalSupplyApiStatus: getApiStatus(state, 'globalSupplyApiStatus'),
    globalSupplyLocationApiStatus: getApiStatus(state, 'globalSupplyLocationApiStatus'),
    editJobStatus: getApiStatus(state, 'editJobStatus'),
    showSampleCandidates: getSampleCandidatesDisplayFlag(state),
    currentUser: getCurrentUser(state),
    productVariantsById: getProductVariantsById(state),
    planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
    featureToggleList: getFeatureToggleList(state),
  };
};

const mapDispatchToProps = {
  fetchGlobalInsightSupplyList: SalesDashBoardActions.fetchGlobalInsightSupplyList,
  setAdvancedJobFormData: JobActions.setAdvancedJobFormData,
  fetchJobDetails: JobActions.fetchJobDetails,
  putEditJob: JobActions.putEditJob,
  fetchCandidateSpecifications: CandidateActions.fetchCandidateSpecifications,
  createContext: AryaBotContextActions.createContext,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  suggestJD: JobDescriptionActions.suggestJD,
};

class AdvancedJobEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdvancedJobLoading: true,
    };
  }

  componentDidMount() {
    const { fetchPlanDetailsByProductVariantId } = this.props;
    this.getAdvancedJobDetailsAndGlobalStats();
    this.parseSkillsAndGetJobDescriptionSuggestions();
    fetchPlanDetailsByProductVariantId();
  }

  getAdvancedJobDetailsAndGlobalStats = async () => {
    const { fetchJobDetails, match, fetchCandidateSpecifications, fetchGlobalInsightSupplyList } = this.props;
    fetchCandidateSpecifications(candidateSpecificationsList);
    try {
      const res = await fetchJobDetails(match.params.jobId);
      const { JobTitle, CountryCode, NiceToHaves = '' } = res;
      const niceToHaves = generateKeywordsFromBooleanString(NiceToHaves);
      await fetchGlobalInsightSupplyList({
        CountryCode,
        Experiences: defaultExperienceRanges,
        Title: JobTitle,
        Skills: niceToHaves,
      });
    } finally {
      this.setState({ isAdvancedJobLoading: false });
    }
  };

  getJobDescriptionSuggestions = (jobTitle, skills) => {
    const { suggestJD } = this.props;
    suggestJD({ jobTitle, skills });
  };

  parseSkillsAndGetJobDescriptionSuggestions = async () => {
    const { fetchJobDetails, match } = this.props;
    const res = await fetchJobDetails(match.params.jobId);
    const { JobTitle, NiceToHaves = '', MustHaves = '' } = res;
    const niceToHaves = generateKeywordsFromBooleanString(NiceToHaves);
    const mustHaves = generateKeywordsFromBooleanString(MustHaves);
    const skills = niceToHaves.concat(mustHaves);
    this.getJobDescriptionSuggestions(JobTitle, skills);
  };

  componentDidUpdate(prevProps) {
    const { match, history, jobsById } = this.props;
    const { jobId } = match.params;
    if (!_.isEqual(prevProps.jobsById, jobsById)) {
      if (_.get(this.props, ['jobsById', jobId, 'ErrorCode'], undefined) === 403) {
        history.push(`/jobs/${match.params.jobId}/forbidden`);
      }
      if (_.get(this.props, ['jobsById', jobId, 'ErrorCode'], undefined) === 404) {
        history.push(`/jobs/${match.params.jobId}/notfound`);
      }
    }
  }

  editJob = async (jobDetails, resumeUpdateDetails, submitAction) => {
    const { putEditJob, history, createContext } = this.props;
    const res = await putEditJob(jobDetails?.JobId, jobDetails, null, resumeUpdateDetails);
    const jobId = res.JobId;
    const analyticsJobDetails = getJobDetailsForAnalytics(res);
    const gtagPayload = {
      jobId: res?.JobId,
      jobTitle: jobDetails?.JobTitle,
      isAdvanced: jobDetails?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    };
    const answers = {
      JobDescription: jobDetails?.Description,
    };
    if (submitAction === 'draft') {
      PubSub.publish(ADVANCED_JOB_SAVE_DRAFT, gtagPayload);
      createContext('REF', res?.JobGuid, res?.JobId, true, answers);
      history.push(`/jobs`);
    } else if (submitAction === 'save') {
      PubSub.publish(ADVANCED_JOB_INSIGHTS, gtagPayload);
      createContext('REF', res?.JobGuid, res?.JobId, true, answers);
      history.push(`/jobs-v2/${jobId}/review`);
    }
  };

  render() {
    const {
      jobsById,
      match,
      showSampleCandidates,
      globalInsightSupplyList,
      productVariantsById,
      planDetailsByProductVariantId,
      featureToggleList,
    } = this.props;
    const { isAdvancedJobLoading } = this.state;
    const jobDetails = jobsById[match.params.jobId];
    const distribtionStatsDataCount = globalInsightSupplyList?.Total;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
    const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
    return (
      <Skeleton className={styles.loaderWrapper} active paragraph={{ rows: 12 }} loading={isAdvancedJobLoading}>
        <AdvancedJobEditHeaderSection
          title="Market intelligence based on your information"
          subTitle="Select from our recommendations to target the right candidates"
          activeStep={0}
          isAdvancedJob
          distribtionStatsDataCount={distribtionStatsDataCount}
          isFirstJobFree={isFirstJobFree}
        />
        <AdvancedJobDetailsEdit
          {...this.props}
          handleSubmit={this.editJob}
          isLoading={isAdvancedJobLoading}
          jobDetails={jobDetails || {}}
          getJobDescriptionSuggestions={this.getJobDescriptionSuggestions}
          featureToggleList={featureToggleList}
        />
      </Skeleton>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedJobEditContainer);
export { AdvancedJobEditContainer as AdvancedJobEditContainerWithoutStore };
