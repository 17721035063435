import React from 'react';
import { Tooltip } from 'antd';
import styles from './InsightsCard.module.scss';
import { GroupChatIcon, JobIcon, UserRoleIcon } from '../../../../../../Icons/AryaIcons';
import InfoCircleIcon from '../../../../../../Icons/SuggestedIcons/InfoCircleIcon';

export default function InsightsCard(props) {
  const { statsText, infoText, type } = props;

  const getIndicatorIcon = () => {
    switch (type?.toLowerCase()) {
      case 'jobs':
        return (
          <div className={styles.jobsIconWrapper}>
            <JobIcon className={styles.jobsIcon} />
          </div>
        );
      case 'candidates':
        return (
          <div className={styles.candidatesIconWrapper}>
            <UserRoleIcon className={styles.candidatesIcon} />
          </div>
        );
      case 'connections':
        return (
          <div className={styles.connectionsIconWrapper}>
            <GroupChatIcon className={styles.connectionsIcon} />
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div className={styles.insightsCardWrapper}>
      <div className={styles.insightsCardIndicator}>{getIndicatorIcon()}</div>
      <div className={styles.insightsCard}>
        <div className={styles.insightsCardDetails}>
          <div className={styles.insightsCardStats}>{statsText}</div>
        </div>
        <div className={styles.infoIconTooltipWrapper}>
          <Tooltip title={infoText}>
            <InfoCircleIcon className={styles.infoIcon} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
