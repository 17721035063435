export const SET_CANDIDATE_DOWNLOAD_RESUME_API_STATUS = 'SET_CANDIDATE_DOWNLOAD_RESUME_API_STATUS';
const SET_API_STATUS = 'SET_API_STATUS';

export function setJobDetailsApiStatus(payload) {
  return {
    payload,
    type: 'SET_JOB_DETAILS_API_STATUS',
  };
}

export const setCandidateDownloadResumeApiSatus = payload => ({
  type: SET_CANDIDATE_DOWNLOAD_RESUME_API_STATUS,
  payload,
});

function setApiStatus({ apiName, status }) {
  return {
    payload: { apiName, status },
    type: SET_API_STATUS,
  };
}

export { SET_API_STATUS, setApiStatus };
