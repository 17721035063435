export const LINK_TO_ADVANCED_JOB_CREATION = '/jobs-v2/create';
export const LINK_TO_NORMAL_JOB_CREATION = '/jobs/create';
export const LINK_TO_BUY_CREDITS = '/buy-credit';
export const LINK_TO_JOBS = '/jobs';
export const LINK_TO_SELECT_PLAN = '/select-plan';

const getPlanSelectionLink = (jobId, jobGuid) => {
  return `/select-plan?jobGuid=${jobGuid}&jobId=${jobId}`;
};

const getJobCandidateLink = jobId => {
  return `/jobs/${jobId}/candidates`;
};

const getMarketIntelLink = jobId => {
  return `/jobs/${jobId}/intel`;
};

export { getPlanSelectionLink, getJobCandidateLink, getMarketIntelLink };
