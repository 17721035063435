import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'JobDetailHeader.id',
    defaultMessage: 'ID',
  },
  createdon: {
    id: 'JobDetailHeader.createdon',
    defaultMessage: 'Created On',
  },
});

export default messages;
