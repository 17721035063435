import React from 'react';
import * as d3 from 'd3';
import { getCandidateScorelabel, getColorForScoreType } from '../../../Utils/CandidateIntelChartUtils';
import './CandidateIntelOverviewChart.scss';

const width = 250;
const height = 250;

const config = {
  w: width,
  h: height,
  levels: 4,
  levelScale: 0.85,
  labelScale: 1.0,
  facetPaddingScale: 2.5,
  maxValue: 1,
  radians: 2 * Math.PI,
  polygonAreaOpacity: 0.3,
  polygonStrokeOpacity: 1,
  polygonPointSize: 4,
  legendBoxSize: 10,
  translateX: width / 4,
  translateY: height / 4,
  paddingX: width,
  paddingY: height,
  colors: getColorForScoreType,
  levelStroke: 'gray',
  levelStrokeWidth: '0.5px',
  axesStroke: '#e3e3e3',
  axesStrokeWidth: '1px',
  onMouseOverPolygonFillOpacity: 0.1,
  onMouseOverPolygonStrokeOpacity: 0.1,
  polygonFillOpacity: 0.7,
  transitionDuration: 200,
  onMouseOverStrokeOpacity: 1,
  polygonStrokeWidth: '2px',
  tooltipMargin: { top: 5, left: 10 },
  axesLabelMargin: 10,
};

const vis = {
  svg: null,
  tooltip: null,
  levels: null,
  axis: null,
  vertices: null,
  legend: null,
  allAxis: null,
  total: null,
  radius: null,
  verticesTooltip: null,
};

const intToArray = N => Array.from(Array(N), (_, i) => i + 1);

export function getAxisLabelPosition(index, totalAxes) {
  let textAnchor;
  if (index === 0 || index * 2 === totalAxes) {
    textAnchor = 'middle';
  } else if (index < totalAxes / 2) {
    textAnchor = 'end';
  } else {
    textAnchor = 'start';
  }
  return textAnchor;
}

export function getAxisLabelXPosition(index, configuration, totalAxes) {
  const baseXPosition = (configuration.w / 2) * (1 - Math.sin((index * configuration.radians) / totalAxes));
  let axesLabelXPosition;
  if (index === 0 || index * 2 === totalAxes) {
    axesLabelXPosition = baseXPosition;
  } else if (index < totalAxes / 2) {
    axesLabelXPosition = baseXPosition - configuration.axesLabelMargin;
  } else {
    axesLabelXPosition = baseXPosition + configuration.axesLabelMargin;
  }
  return axesLabelXPosition;
}

export function getAxisLabelYPosition(index, configuration, totalAxes) {
  const baseYPosition = (configuration.h / 2) * (1 - Math.cos((index * configuration.radians) / totalAxes));
  let axesLabelYPosition;
  if (index === 0) {
    axesLabelYPosition = baseYPosition - configuration.axesLabelMargin;
  } else if (index === totalAxes / 2) {
    axesLabelYPosition = baseYPosition + configuration.axesLabelMargin;
  } else {
    axesLabelYPosition = baseYPosition;
  }
  return axesLabelYPosition;
}

function updateConfig() {
  config.w = width * config.levelScale;
  config.h = height * config.levelScale;
  config.paddingX = config.w * config.levelScale;
  config.paddingY = config.h * config.levelScale;
}

function buildVisComponents(data) {
  vis.allAxis = data[0].axes.map(i => i.axis);
  vis.totalAxes = vis.allAxis.length;
  vis.radius = Math.min(config.w / 2, config.h / 2);

  d3.selectAll('#overviewRadarChart svg').remove();
  vis.svg = d3
    .select('#overviewRadarChart')
    .append('svg')
    .classed('svg-vis', true)
    .attr('width', config.w + config.paddingX)
    .attr('height', config.h + config.paddingY)
    .append('svg:g')
    .attr('transform', `translate(${config.translateX}, ${config.translateY})`);

  vis.verticesTooltip = d3.select('#overviewRadarChart').append('div').attr('class', 'overview-tooltip');

  vis.levels = vis.svg.selectAll('.levels').append('svg:g').classed('levels', true);

  vis.axes = vis.svg.selectAll('.axes').append('svg:g').classed('axes', true);

  vis.vertices = vis.svg.selectAll('.vertices');

  vis.legend = vis.svg
    .append('svg:g')
    .classed('legend', true)
    .attr('height', config.h / 2)
    .attr('width', config.w / 2)
    .attr('transform', `translate(0, ${1.1 * config.h})`);
}

function buildCoordinates(data) {
  data.forEach(group => {
    group.axes.forEach((d, i) => {
      // eslint-disable-next-line no-param-reassign
      d.coordinates = {
        x:
          (config.w / 2) *
          (1 - (parseFloat(Math.max(d.value, 0)) / config.maxValue) * Math.sin((i * config.radians) / vis.totalAxes)),
        y:
          (config.h / 2) *
          (1 - (parseFloat(Math.max(d.value, 0)) / config.maxValue) * Math.cos((i * config.radians) / vis.totalAxes)),
      };
    });
  });
}

function buildLevels() {
  const x1 = (index, factor, axesCount) => factor * (1 - Math.sin((index * config.radians) / axesCount));
  const y1 = (index, factor, axesCount) => factor * (1 - Math.cos((index * config.radians) / axesCount));
  const x2 = (index, factor, axesCount) => factor * (1 - Math.sin(((index + 1) * config.radians) / axesCount));
  const y2 = (index, factor, axesCount) => factor * (1 - Math.cos(((index + 1) * config.radians) / axesCount));

  const levels = intToArray(config.levels);

  levels.forEach(level => {
    const randomArray = intToArray(10);
    const levelFactor = vis.radius * (level / config.levels);
    const axesCount = level < config.levels ? 10 : vis.totalAxes;
    const allAxis = level < config.levels ? randomArray : vis.allAxis;
    const translateX = config.w / 2 - levelFactor;
    const translateY = config.h / 2 - levelFactor;
    vis.levels
      .data(allAxis)
      .enter()
      .append('svg:line')
      .classed('level-lines', true)
      .attr('x1', (_, i) => x1(i, levelFactor, axesCount))
      .attr('y1', (_, i) => y1(i, levelFactor, axesCount))
      .attr('x2', (_, i) => x2(i, levelFactor, axesCount))
      .attr('y2', (_, i) => y2(i, levelFactor, axesCount))
      .attr('transform', `translate(${translateX},${translateY})`)
      .attr('stroke', config.levelStroke)
      .attr('stroke-width', config.levelStrokeWidth);
  });
}

function buildAxes() {
  vis.axes
    .data(vis.allAxis)
    .enter()
    .append('svg:line')
    .classed('axis-lines', true)
    .attr('x1', config.w / 2)
    .attr('y1', config.h / 2)
    .attr('x2', (d, i) => (config.w / 2) * (1 - Math.sin((i * config.radians) / vis.totalAxes)))
    .attr('y2', (d, i) => (config.h / 2) * (1 - Math.cos((i * config.radians) / vis.totalAxes)))
    .attr('stroke', config.axesStroke)
    .attr('stroke-width', config.axesStrokeWidth);
}

function buildAxesLabels() {
  vis.axes
    .data(vis.allAxis)
    .enter()
    .append('svg:text')
    .classed('axis-labels', true)
    .text(d => getCandidateScorelabel(d))
    .attr('text-anchor', (_, i) => getAxisLabelPosition(i, vis.totalAxes))
    .attr('x', (_, i) => getAxisLabelXPosition(i, config, vis.totalAxes))
    .attr('y', (_, i) => getAxisLabelYPosition(i, config, vis.totalAxes))
    .attr('font-size', `${11 * config.labelScale}px`);
}

export function verticesTooltipShow(group, d) {
  const { offsetX, offsetY } = d3.event;
  const scoreValue = d.value.toFixed(2);
  const { tooltipMargin } = config;
  const tooltipContent = `
  <div class="tooltip-content">
    <p class="overview-tooltip-score-label"></p>
    <div class="overview-tooltip-details">
      <div class="overview-tooltip-label-color"></div>
      <div class="overview-tooltip-score"></div>
    </div>
  </div>`;
  vis.verticesTooltip
    .style('opacity', 1)
    .html(tooltipContent)
    .style('left', `${offsetX + tooltipMargin.left}px`)
    .style('top', `${offsetY + tooltipMargin.top}px`);
  vis.verticesTooltip.select('.overview-tooltip-score-label').html(getCandidateScorelabel(d.axis));
  vis.verticesTooltip.select('.overview-tooltip-label-color').style('background-color', getColorForScoreType(group));
  vis.verticesTooltip.select('.overview-tooltip-score').html(`${scoreValue}/1`);
}

export function verticesTooltipHide() {
  vis.verticesTooltip.style('opacity', 0);
}

function buildVertices(data) {
  data.forEach(group => {
    vis.vertices
      .data(group.axes)
      .enter()
      .append('svg:circle')
      .classed('polygon-vertices', true)
      .attr('r', config.polygonPointSize)
      .attr('cx', d => d.coordinates.x)
      .attr('cy', d => d.coordinates.y)
      .attr('fill', config.colors(group.group))
      .on('mouseover', d => verticesTooltipShow(group.group, d))
      .on('mouseout', verticesTooltipHide);
  });
}

function buildPolygons(data) {
  vis.vertices
    .data(data)
    .enter()
    .append('svg:polygon')
    .classed('polygon-areas', true)
    .attr('points', group => {
      let verticesString = '';
      group.axes.forEach(d => {
        verticesString += `${d.coordinates.x},${d.coordinates.y} `;
      });
      return verticesString;
    })
    .attr('stroke-width', config.polygonStrokeWidth)
    .attr('stroke', g => config.colors(g.group))
    .attr('fill', g => config.colors(g.group))
    .attr('fill-opacity', config.polygonAreaOpacity)
    .attr('stroke-opacity', config.polygonStrokeOpacity)
    .attr('pointer-events', 'none');
}

function buildChart(data) {
  buildVisComponents(data);
  buildCoordinates(data);
  buildLevels();
  buildAxes();
  buildAxesLabels();
  buildVertices(data);
  buildPolygons(data);
}

export function renderChart(data) {
  d3.select('#overviewRadarChart').selectAll('svg').remove();
  updateConfig();
  buildChart(data);
}

export function formatOverviewDataForRadarChart(overviewData) {
  const data = [];
  Object.keys(overviewData).forEach(key => {
    const group = key;
    const axes = [];
    Object.entries(overviewData[`${key}`]).forEach(scoreData => {
      const [axis, value] = scoreData;
      if (axis !== 'CompanyScore') {
        axes.push({ axis: axis === 'OccupationScore' ? 'ExperienceScore' : axis, value });
      }
    });
    data.push({ group, axes });
  });
  return data;
}
export function formatOverviewDataForRadarChartV2(overviewData) {
  return Object.keys(overviewData).map(key => {
    const group = key;
    const axes = Object.entries(overviewData[key]).map(scoreData => {
      const [axis, value] = scoreData;
      let axisValue = value * 0.95;
      if (axisValue <= 0) axisValue = 0.2;
      return { axis: axis === 'OccupationScore' ? 'ExperienceScore' : axis, value: axisValue };
    });
    return { group, axes };
  });
}

class CandidateIntelOverviewChart extends React.Component {
  componentDidMount() {
    this.createRadarChart();
  }

  componentDidUpdate() {
    this.createRadarChart();
  }

  createRadarChart() {
    const { Overview } = this.props;
    const radarData = formatOverviewDataForRadarChart(Overview);
    renderChart(radarData);
  }

  render() {
    const { scoreDetail } = this.props;
    return <div id="overviewRadarChart" style={scoreDetail ? { marginTop: '1px' } : {}}></div>;
  }
}

export default CandidateIntelOverviewChart;
