import React from 'react';
import { getCandidateScorelabel } from '../../../Utils/CandidateIntelChartUtils';
import './CandidateIntelScore.scss';

export function ScoreProgressBar({ candidateScore }) {
  const filledContainerStyle = {
    width: `${candidateScore * 100}%`,
  };

  return (
    <div className="candidate-score-container">
      <div className="candidate-score-container-filled" style={filledContainerStyle}></div>
    </div>
  );
}

export default function CandidateIntelScore({ score, scoreType }) {
  return (
    <div className="candidate-intel-score">
      <span className="candidate-score">{score.toFixed(2)}</span>
      <span className="candidate-score-secondary-text">/1 {getCandidateScorelabel(scoreType)}</span>
      <ScoreProgressBar candidateScore={score} />
    </div>
  );
}
