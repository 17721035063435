import React from 'react';
import { Skeleton } from 'antd';
import { getCandidateOptOutReason } from '../../Utils/CandidateRejectReasonsUtils';
import EnhancedFetchContactWidget from '../ConnectCard/EnhancedFetchContactWidget/EnhancedFetchContactWidget';
import EnhancedConnectMediaWidget from '../ConnectCard/EnhancedConnectMediaWidget/EnhancedConnectMediaWidget';
import CandidateOptOutWidget from '../CandidateOptOutWidget/CandidateOptOutWidget';

function CandidateCardConnectOptions(props) {
  const {
    featureToggleList,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    candidate,
    indexKey,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,
    candidateContext = 'job',
    isCandidateViewIconVisible,
  } = props;

  const isContactFetchEnabled = featureToggleList?.ContactFetch?.IsEnabled;
  const candidateOptOutReason = getCandidateOptOutReason(candidate);
  const optedOutAdditionDetails = candidateOptOutReason?.AdditionalDetails;

  const getContactPullIcon = isContactFetchEnabled ? (
    <EnhancedFetchContactWidget
      fetchContactStatus={candidate.fetchContactStatus}
      candidate={candidate}
      currentJobDetails={currentJobDetails}
      handleContactPull={handleContactPull}
      contactPullColor="#13c26b"
    />
  ) : null;
  if (showConnectWidget && !candidateOptOutReason) {
    const isEmailReadVisible = featureToggleList.EmailRead.IsEnabled || candidateContext === 'segment';
    const isMessageReadVisible = featureToggleList.MessageRead.IsEnabled || candidateContext === 'segment';
    const isAudioReadVisible = featureToggleList.AudioRead.IsEnabled || candidateContext === 'segment';

    return (
      <Skeleton active loading={!connectStatusLoaded} rows={1} title={false}>
        {candidate.PersonId ? (
          <EnhancedConnectMediaWidget
            candidate={candidate}
            onIconClick={showCandidateDetail}
            setComposeEmailType={setComposeEmailType}
            indexKey={indexKey}
            ConnectStatus={candidate.connectInfo}
            fetchContactStatus={candidate.fetchContactStatus}
            featureToggleList={featureToggleList}
            currentJobDetails={currentJobDetails}
            isEmailReadVisible={isEmailReadVisible}
            isMessageReadVisible={isMessageReadVisible}
            isAudioReadVisible={isAudioReadVisible}
            candidateContext={candidateContext}
            isCandidateViewIconVisible={isCandidateViewIconVisible}
          />
        ) : (
          getContactPullIcon
        )}
      </Skeleton>
    );
  }
  if (candidateOptOutReason) {
    return <CandidateOptOutWidget candidateOptOutType={optedOutAdditionDetails.CandidateOptOutType} />;
  }
  return null;
}

export default CandidateCardConnectOptions;
