import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './CustomAffixMenu.module.scss';

function CustomAffixMenu(props) {
  const [menuItemId, setMenuItemId] = useState(props?.menuItemId);

  const setActiveMenuItemId = e => {
    e.preventDefault();
    const { hash } = e.target;
    if (hash && hash !== menuItemId) {
      setMenuItemId(hash);
      const ele = document.querySelector(hash);
      if (ele) {
        ele.scrollIntoView({ behaviour: 'smooth', block: 'center' });
      }
    }
  };

  const { activeMenuList = [] } = props;

  return (
    <div role="presentation" onClick={e => setActiveMenuItemId(e)} className={styles.customAffixMenu}>
      {activeMenuList.map(item => (
        <a href={`#${item.id}`} key={item.id} className={menuItemId === `#${item.id}` ? styles.active : ''}>
          {item.name}
        </a>
      ))}
    </div>
  );
}

export default withRouter(CustomAffixMenu);

export { CustomAffixMenu as CustomAffixMenuWithoutStore };
