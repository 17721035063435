import cleanSet from 'clean-set';
import _ from 'lodash';
import {
  SET_WORKFLOW_TEMPLATES,
  APPEND_WORKFLOW_TEMPLATES,
  SET_WORKFLOW_TEMPLATES_COUNT,
  SET_WORKFLOW_DRIP_TEMPLATE_SHARED_WITH,
  SET_WORKFLOW_TEMPLATE_DETAILS,
  SET_WORKFLOW_FETCH_API_STATUS,
  SET_WORKFLOW_TEMPLATES_API_FETCH_STATUS,
  SET_WORKFLOW_NODE_CONNECTIONS,
} from '../Actions/ActionCreators/WorkflowActionCreator';

function WorkflowReducer(state = {}, action) {
  let newState;
  let templateIds;
  switch (action.type) {
    case SET_WORKFLOW_TEMPLATES:
      newState = _.cloneDeep(state);
      newState.ById = {};
      newState.CurrentTemplateIds = action.payload.map(template => template.DefinitionId);
      newState.templateIds = action.payload.map(definition => definition.DefinitionId);
      action.payload.forEach(definition => {
        newState.ById[definition.DefinitionId] = definition;
      });

      return newState;

    case SET_WORKFLOW_TEMPLATE_DETAILS: {
      newState = _.cloneDeep(state);
      const { template } = action.payload;
      const definitionId = template.DefinitionId;
      _.setWith(newState, ['ById', definitionId], template, Object);
      return newState;
    }

    case SET_WORKFLOW_FETCH_API_STATUS:
      newState = _.cloneDeep(state);
      _.setWith(newState, ['WorkflowFetchApiStatusById', action.payload.id], action.payload.status, Object);
      return newState;

    case SET_WORKFLOW_TEMPLATES_API_FETCH_STATUS:
      newState = _.cloneDeep(state);
      _.setWith(newState, [action.payload.apiName], action.payload.status, Object);

      return newState;

    case APPEND_WORKFLOW_TEMPLATES:
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};
      templateIds = action.payload.templates.map(template => template.DefinitionId);
      templateIds = _.uniqBy([...newState.templateIds, ...templateIds]);
      newState.templateIds = templateIds;
      action.payload.templates.forEach(template => {
        newState.ById[template.DefinitionId] = template;
      });
      action.payload.templates.forEach(template => {
        newState.CurrentTemplateIds = [...newState.CurrentTemplateIds, template.DefinitionId];
      });
      return newState;

    case SET_WORKFLOW_TEMPLATES_COUNT:
      return {
        ...state,
        Count: action.payload,
      };

    case SET_WORKFLOW_DRIP_TEMPLATE_SHARED_WITH:
      newState = _.cloneDeep(state);
      if (action.payload.isShareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], true);
      }

      if (action.payload.sharedWith || action.payload.unsharedWith) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
        _.set(newState, ['ById', action.templateId, 'UnsharedWith'], action.payload.unsharedWith);
        _.set(newState, ['ById', action.templateId, 'SharedWith'], action.payload.sharedWith);
      }

      if (action.payload.isUnshareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }
      return newState;
    case SET_WORKFLOW_NODE_CONNECTIONS: {
      if (!action.payload.reinitiate) {
        const newConnections = Array.from(
          new Set([...state.NodeConnections.connections, ...action.payload.connections])
        );
        newState = cleanSet(state, 'NodeConnections.connections', newConnections);
        return newState;
      }
      newState = cleanSet(state, 'NodeConnections', { connections: [] });
      return newState;
    }
    default:
      return state;
  }
}

function getCurrentWorkflowDripTemplateIds(state) {
  return state.WorkflowReducer.CurrentTemplateIds || [];
}

function getWorkflowDripTemplates(state) {
  return state.WorkflowReducer?.ById || {};
}

function getWorkflowDripTemplatesById(state, workflowId) {
  return state.WorkflowReducer?.ById?.[workflowId] || {};
}

function getWorkflowDripTemplateIds(state) {
  return state.WorkflowReducer.templateIds || [];
}

function getWorkflowDripTemplatesCount(state) {
  return state.WorkflowReducer.Count;
}

function getWorkflowApiStatusById(state, workflowId) {
  return state?.WorkflowReducer?.WorkflowFetchApiStatusById?.[workflowId];
}

function getWorkflowTemplatesFetchApiStatus(state, apiName) {
  return state?.WorkflowReducer?.[apiName];
}

function getWorkflowNodeConnections(state) {
  return state?.WorkflowReducer?.NodeConnections.connections;
}

export {
  WorkflowReducer,
  getCurrentWorkflowDripTemplateIds,
  getWorkflowDripTemplatesById,
  getWorkflowDripTemplateIds,
  getWorkflowDripTemplatesCount,
  getWorkflowApiStatusById,
  getWorkflowDripTemplates,
  getWorkflowTemplatesFetchApiStatus,
  getWorkflowNodeConnections,
};
