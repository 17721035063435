import React from 'react';
import { Modal, Button, Skeleton, Input } from 'antd';
import styles from './JobDescriptionRecommendationModal.module.scss';
import ElementsListWithCheckbox from './ElementsListWithCheckbox';
import { parseHtmlDescriptionForData } from '../../../Utils/JobDescriptionUtils';

export default function JobDescriptionRecommendationModal(props) {
  const {
    onCancel,
    visibility,
    suggestedJobDescription,
    descriptionSuggestApiStatus,
    initialValues,
    addDescriptionFromTemplateSelectedOptions,
    jobDescription,
  } = props;

  const [selectedRequirements, setSelectedRequirements] = React.useState([]);
  const [selectedResponsibilities, setSelectedResponsibilities] = React.useState([]);
  const [initialRequirements, setInitialRequirements] = React.useState([]);
  const [initialResponsibilities, setInitialResponsibilities] = React.useState([]);

  const requirementsHeading = 'Requirements';
  const responsibilitiesHeading = 'Responsibilities';

  React.useEffect(() => {
    const { requirements, responsibilities } = parseHtmlDescriptionForData(jobDescription);
    setInitialRequirements(requirements);
    setInitialResponsibilities(responsibilities);
  }, [suggestedJobDescription, jobDescription]);

  const onAddSelectedRecommendations = () => {
    addDescriptionFromTemplateSelectedOptions(selectedRequirements, selectedResponsibilities);
    onCancel();
  };

  const footerButtons = (
    <div className={styles.buttonsWrapper}>
      <Button className={styles.cancelButton} onClick={onCancel} shape="round">
        Cancel
      </Button>
      <Button
        className={styles.addButton}
        type="primary"
        onClick={onAddSelectedRecommendations}
        shape="round"
        disabled={selectedRequirements.length === 0 && selectedResponsibilities.length === 0}
      >
        Add to Description
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      style={{ borderRadius: '12px', top: '120px' }}
      bodyStyle={{ height: '512px' }}
      className={styles.recommendationModalWrapper}
      onCancel={onCancel}
      visible={visibility}
      onOk={onCancel}
      width={882}
      footer={null}
      destroyOnClose
    >
      <div className={styles.jdModalWrapper}>
        <div className={styles.jdModalLeft}>
          <div className={styles.jdModalTitle}>Job Description Template</div>
          <div className={styles.jdModalText}>
            A well-written job description is the first step to attracting and engaging the best talent. Click and
            choose from our recommendations here. You can always edit and customize key responsibilities and
            requirements after you add them.
          </div>
          <div className={styles.jobTitleHeader}>Job Title</div>
          <Input
            disabled
            defaultValue={initialValues?.jobTitle}
            className={styles.jobTitleInput}
            placeholder="Job title"
            size="large"
          />
        </div>
        <div className={styles.jdModalRight}>
          <div className={styles.jdModalSuggestions}>
            <Skeleton
              loading={descriptionSuggestApiStatus === 'INPROGRESS'}
              className={styles.suggestionSkeleton}
              active
              paragraph={{ rows: 12 }}
            >
              <ElementsListWithCheckbox
                key="requirements_list"
                heading={requirementsHeading}
                elementsList={suggestedJobDescription.requirements}
                onSelectElements={requirementsList => setSelectedRequirements(requirementsList)}
                isBorderBottomRequired={suggestedJobDescription.responsibilities.length > 0}
                defaultValues={initialRequirements}
              />
              <ElementsListWithCheckbox
                key="responsibilities_list"
                heading={responsibilitiesHeading}
                elementsList={suggestedJobDescription.responsibilities}
                onSelectElements={responsibilitiesList => setSelectedResponsibilities(responsibilitiesList)}
                defaultValues={initialResponsibilities}
              />
            </Skeleton>
          </div>
          {footerButtons}
        </div>
      </div>
    </Modal>
  );
}
