import _ from 'lodash';

export function sanitizeKeywords(keywords) {
  return _.uniqBy(
    keywords && keywords.map(keyword => keyword && keyword.trim()).filter(keyword => keyword !== ''),
    keyword => keyword.toLowerCase()
  );
}

export function purifySelectedOptions(form, fieldValue) {
  const fieldItems = sanitizeKeywords(form.getFieldValue(fieldValue));
  form.setFieldsValue({
    [fieldValue]: fieldItems,
  });
}
