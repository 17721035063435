import React, { useCallback } from 'react';
import classNames from 'classnames';
import ReactFlow, { ReactFlowProvider, Controls, applyNodeChanges, useReactFlow } from 'react-flow-renderer';
import { nodeTypes } from '../Nodes';
import { edgeTypes } from '../Edges';
import { getLayoutedElements } from '../WorkflowUtils/WorkflowLayoutUtils';
import styles from './WorkflowLayout.module.scss';

const Layout = props => {
  const { elements, version, workflowReportApiStatus } = props;
  const [layoutElements, setLayoutElements] = React.useState([]);
  const [layoutDimensions, setLayoutDimensions] = React.useState({});
  const [isInitialLayoutSet, setInitialLayoutFlag] = React.useState(false);
  const { setViewport } = useReactFlow();

  React.useEffect(() => {
    if (elements?.length) {
      const { layoutElements: _layoutElements, height, width } = getLayoutedElements(elements);
      setLayoutElements(_layoutElements);
      setLayoutDimensions({ height, width });
      if (!isInitialLayoutSet) {
        setInitialLayoutFlag(true);
      }
    }
  }, [elements]);

  React.useEffect(() => {
    if (isInitialLayoutSet) {
      const { width } = layoutDimensions;
      setViewport({ x: window.innerWidth / 2 - width / 2, y: 100, zoom: 1 });
    }
  }, [isInitialLayoutSet, workflowReportApiStatus]);

  const onNodesChange = useCallback(changes => {
    setLayoutElements(_layoutElements => {
      const nodes = _layoutElements.filter(x => !x.target);
      const edges = _layoutElements.filter(x => x.target);
      const updatedNodes = applyNodeChanges(changes, nodes);
      return [...updatedNodes, ...edges];
    });
  }, []);

  const layoutNodes = layoutElements.filter(x => x.position);
  const layoutEdges = layoutElements.filter(x => !x.position);
  const { height } = layoutDimensions;

  return (
    <div className={classNames(styles.AutomationCanvas, { [styles.atsAutomationCanvas]: version === 'ats' })}>
      <ReactFlow
        nodes={layoutNodes}
        edges={layoutEdges}
        nodesDraggable
        nodesConnectable={false}
        onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        panOnScroll
        className="react-flow-container"
        panOnDrag
        preventScrolling
        translateExtent={[
          [-Infinity, -150],
          [Infinity, height + 150],
        ]}
      >
        <Controls showInteractive={false} className={styles.Controls} />
      </ReactFlow>
    </div>
  );
};

const WorkflowLayout = props => (
  <ReactFlowProvider>
    <Layout {...props} />
  </ReactFlowProvider>
);

export default WorkflowLayout;
