import React from 'react';
import { Button } from 'antd';
import styles from './BenefitCard.module.scss';

function getBenefitCardContent(type) {
  let title = 'What you see here is just the tip of the iceberg';
  let description = `Continue with a paid plan to unlock the full potential of Arya Pulse with a complete candidate list and interview-ready talent`;
  if (type.toLowerCase() === 'candidatedrawer') {
    title =
      'Go beyond the sample list. Upgrade now to get full access to all candidate profiles and market insights relevant for the job';
    description = ``;
  }
  return { title, description };
}

function BenefitCard({ history, type = 'default', cardTitle, cardDescription, redirectPath = '/select-plan' }) {
  const content = getBenefitCardContent(type);
  return (
    <div className={styles.horizonatlBenefitCardContainer}>
      <div className={styles.benefitCardContent}>
        <div className={styles.benefitTitle}>{cardTitle || content.title}</div>
        <div>{cardDescription || content.description}</div>
      </div>
      <div className={styles.buttonContent}>
        <Button
          className="select-your-plan"
          shape="round"
          onClick={() => {
            history.push(redirectPath);
          }}
        >
          Select a Plan
        </Button>
      </div>
    </div>
  );
}

export { BenefitCard };
