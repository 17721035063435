import React from 'react';
import { Row, Col, Skeleton, Empty } from 'antd';
import TreeGraph from '../../VisxCharts/TreeGraph';
import { FormSelectComponent } from '../../../Utils/FormComponentUtils';
import styles from './CareerAssessmentFilter.module.scss';
import { convertToPascalCase } from '../../../Utils/DashBoardUtils';

export default function CareerAssessmentFilter(props) {
  const { form, futureTitles, fetchCareerAssessment, loading, isValidTitle } = props;
  const { Titles5Years, Titles10Years } = futureTitles;

  const [defaultRangeOption, setDefaultRangeOption] = React.useState(5);
  const [initialNode, setInitialNode] = React.useState(null);
  const [optionsList, setOptionsList] = React.useState([]);

  React.useEffect(() => {
    let defaultOption = 5;
    let options = [];
    let initialNodeKey;

    if (Titles10Years?.length) {
      options = [
        {
          value: 10,
          label: 'In 10 Years',
        },
      ];
      defaultOption = 10;
      initialNodeKey = Titles10Years[0].DisplayKey;
    }
    if (Titles5Years?.length) {
      options = [
        {
          value: 5,
          label: 'In 5 Years',
        },
        ...options,
      ];
      defaultOption = 5;
      initialNodeKey = Titles5Years[0].DisplayKey;
    }
    setDefaultRangeOption(defaultOption);
    setOptionsList(options);
    setInitialNode({ name: initialNodeKey });
  }, [JSON.stringify(futureTitles)]);

  const prescribedTitles = (form.getFieldValue('Range') ?? defaultRangeOption) === 5 ? Titles5Years : Titles10Years;
  const roleTreeData = {
    name: convertToPascalCase(form.getFieldValue('Role')),
    children: prescribedTitles?.map(title => {
      return {
        name: title.DisplayKey,
      };
    }),
  };

  const handleRangeChange = value => {
    if (value === 5) {
      setInitialNode({ name: Titles5Years[0].DisplayKey });
    } else if (value === 10) {
      setInitialNode({ name: Titles10Years[0].DisplayKey });
    }
  };

  const projectionInsightTitle = <div className={styles.headerTitle}>Career Projection</div>;
  const projectionInsightDescription = (
    <div className={styles.headerDescription}>Select projection range and prescribed title to see role comparison.</div>
  );

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerInfo}>{projectionInsightTitle}</div>
        {projectionInsightDescription}
      </div>
      <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
        <Row gutter={16}>
          {optionsList.length > 0 && isValidTitle ? (
            <>
              <Col span={8}>
                <FormSelectComponent
                  form={form}
                  fieldDecoratorValue="Range"
                  initialValue={defaultRangeOption}
                  rules={[{ required: true, message: 'Please select projection range' }]}
                  label="Projection Range"
                  inputValues={optionsList}
                  onChange={handleRangeChange}
                  className={styles.selectComponent}
                />
              </Col>
              <TreeGraph data={roleTreeData} initialNode={initialNode} onNodeClick={fetchCareerAssessment} />
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.empty} />
          )}
        </Row>
      </Skeleton>
    </>
  );
}
