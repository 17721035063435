import React from 'react';
import { Icon } from 'antd';

export const PasswordResetSuccessSvg = props => (
  <svg width={62} height={70} viewBox="0 0 62 70" {...props}>
    <title>Group</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="164.020701%" id="linearGradient-1">
        <stop stopColor="#13C26B" offset="0%" />
        <stop stopColor="#569ED0" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="188.657683%" id="linearGradient-2">
        <stop stopColor="#13C26B" offset="0%" />
        <stop stopColor="#569ED0" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(-0.001260, 0.000315)">
        <path
          d="M6.3504,20.727 C6.69375,29.42415 9.4626,66.15 31.5,66.15 C53.54055,66.15 56.30625,29.42415 56.65275,20.727 L31.5,6.75045 L6.3504,20.727 Z M31.5,72.45 C0.3591,72.45 0,21.0861 0,18.9 C0,17.75655 0.62055,16.70445 1.62225,16.1469 L29.97225,0.3969 C30.92355,-0.1323 32.0796,-0.1323 33.0309,0.3969 L61.3809,16.1469 C62.3826,16.70445 63,17.75655 63,18.9 C63,21.0861 62.64405,72.45 31.5,72.45 L31.5,72.45 Z"
          id="Fill-1439"
          fill="url(#linearGradient-1)"
        />
        <polygon
          id="Fill-1440"
          fill="url(#linearGradient-2)"
          points="32.568795 52.971345 16.538445 40.950945 21.263445 34.650945 30.433095 41.527395 43.911945 19.962495 50.589945 24.136245"
        />
      </g>
    </g>
  </svg>
);

const PasswordResetSuccessIcon = props => <Icon component={PasswordResetSuccessSvg} {...props} />;

export default PasswordResetSuccessIcon;
