import React from 'react';
import { connect } from 'react-redux';
import { getSourceName } from '../../Utils/SourceUtils';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getAvailableCredits } from '../../Reducers/AryaPayReducer';
import CreditInfo from '../../Components/CreditInfo/CreditInfo';

const mapStateToProps = state => ({
  config: getConfig(state),
  currentUser: getCurrentUser(state),
  availableCredits: getAvailableCredits(state),
});

function ActiveChannelCreditInfo(props) {
  const {
    config,
    creditInfoVisibility,
    currentUser,
    onCreditInfoModalCancel,
    setCreditInfoVisibility,
    availableCredits,
  } = props;
  const sourcesConfig = config?.Sources ?? [];
  const activeSourceConfigurations = sourcesConfig.find(s => getSourceName(s.Source).toLowerCase() === 'active');
  const onCreditInfoShowAgainStatusUpdate = showAgain => {
    const { sub } = currentUser;
    const creditInfoShowAgainStatuses = JSON.parse(localStorage.getItem('CreditInfoShowAgainStatuses')) || {};
    if (showAgain) {
      creditInfoShowAgainStatuses[sub] = true;
      localStorage.setItem('CreditInfoShowAgainStatuses', JSON.stringify(creditInfoShowAgainStatuses));
    }
    setCreditInfoVisibility(false);
  };
  return (
    <CreditInfo
      credits={activeSourceConfigurations?.Credits ?? 0}
      visible={creditInfoVisibility}
      creditInfoShowAgainStatusUpdate={onCreditInfoShowAgainStatusUpdate}
      onCreditInfoModalCancel={onCreditInfoModalCancel}
      availableCredits={availableCredits}
      userConfig={config}
    />
  );
}

export default connect(mapStateToProps)(ActiveChannelCreditInfo);
export { ActiveChannelCreditInfo as ActiveChannelCreditInfoWithoutStore };
