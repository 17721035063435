import React from 'react';
import { Modal, Input, Checkbox } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as UserActions from '../../Actions/UserActions';
import { getUsersById, getUserIds, getMaxCount, getTotalUsersCount } from '../../Reducers/UserReducer';
import { ShareModalItemsUtil } from './ShareModalItemsUtil';
import styles from './ShareModalUtil.module.scss';

const mapStateToProps = state => ({
  usersById: getUsersById(state),
  userIds: getUserIds(state),
  maxCount: getMaxCount(state),
  totalUsersCount: getTotalUsersCount(state),
});

const mapDispatchToProps = {
  fetchUsersForShare: UserActions.fetchUsersForDropdownLists,
};

function ShareModalUtil(props) {
  const {
    isVisible,
    sharedUserIds = [],
    unSharedUserIds = [],
    onShare,
    onClose,
    titleText,
    usersById,
    fetchUsersForShare,
    maxCount,
    totalUsersCount,
    userIds,
    isPublic,
    ownerId,
    viewerEmail,
  } = props;

  const [searchValue, setSearchValue] = React.useState('');
  const [currentSharedUserIds, setCurrentSharedUserIds] = React.useState([]);
  const [currentUnsharedUserIds, setCurrentUnsharedUserIds] = React.useState([]);
  const [topSharedUserIds, setTopSharedUserIds] = React.useState([]);
  const [isShareWithAllChecked, setShareWithAllCheckedFlag] = React.useState(isPublic);
  React.useEffect(() => {
    if (sharedUserIds.length) {
      setCurrentSharedUserIds(sharedUserIds);
      setTopSharedUserIds(sharedUserIds);
    }
  }, [sharedUserIds]);

  React.useEffect(() => {
    if (unSharedUserIds.length) {
      setCurrentUnsharedUserIds(unSharedUserIds);
    }
  }, [unSharedUserIds]);

  const getShareDataPayload = () => {
    if (isShareWithAllChecked) return { isShareWithAll: true, unshareWith: currentUnsharedUserIds };
    if (isPublic && !currentSharedUserIds?.length) {
      return { isUnshareWithAll: true };
    }
    return { shareWith: currentSharedUserIds, unshareWith: currentUnsharedUserIds };
  };

  const onShareData = () => {
    setSearchValue('');
    const shareDataPayload = getShareDataPayload();
    onShare({ ...shareDataPayload });
  };

  const handleClose = () => {
    setSearchValue('');
    onClose();
  };

  const onSearchClick = value => {
    const cleanValue = value.trim();
    const currentTopSharedUsers = currentSharedUserIds.filter(id => {
      if (!usersById[id]) {
        return false;
      }
      return (
        usersById[id].FullName.toLowerCase().search(cleanValue) >= 0 ||
        usersById[id].Email.toLowerCase().search(cleanValue) >= 0
      );
    });
    if (cleanValue) {
      fetchUsersForShare({ page: 1, searchTerm: cleanValue.toLowerCase() });
    } else {
      fetchUsersForShare({ page: 1 });
    }
    setSearchValue(cleanValue.toLowerCase());
    setTopSharedUserIds(currentTopSharedUsers);
  };

  const onSearchChange = e => {
    const cleanValue = e.target.value;
    // handle if value of search box is cleared
    if (!cleanValue) {
      fetchUsersForShare({ page: 1, isActive: true });
      setSearchValue(cleanValue.toLowerCase());
      setTopSharedUserIds(currentSharedUserIds);
    }
  };

  const handleLoadMore = page => {
    if ((page - 1) * 10 > maxCount) {
      return;
    }
    if (searchValue) {
      fetchUsersForShare({ page, searchTerm: searchValue, isActive: true });
    } else {
      fetchUsersForShare({ page, isActive: true });
    }
  };

  const onChangeUserSelection = e => {
    const checkedUserId = e.target.value;
    const isChecked = e.target.checked;
    const clonedCurrentSharedUserIds = _.cloneDeep(currentSharedUserIds);
    const clonedCurrentUnsharedUserIds = _.cloneDeep(currentUnsharedUserIds);
    if (isChecked) {
      _.remove(clonedCurrentUnsharedUserIds, id => id === checkedUserId);
      clonedCurrentSharedUserIds.push(checkedUserId);
    } else {
      _.remove(clonedCurrentSharedUserIds, id => id === checkedUserId);
      clonedCurrentUnsharedUserIds.push(checkedUserId);
    }
    if (
      (userIds.length === totalUsersCount && _.isEqual([...clonedCurrentSharedUserIds].sort(), [...userIds].sort())) ||
      (isShareWithAllChecked && !clonedCurrentUnsharedUserIds?.length)
    ) {
      setShareWithAllCheckedFlag(true);
      setCurrentSharedUserIds([]);
      setCurrentUnsharedUserIds([]);
    } else if (
      userIds.length === totalUsersCount &&
      _.isEqual([...clonedCurrentUnsharedUserIds].sort(), [...userIds].sort())
    ) {
      setShareWithAllCheckedFlag(false);
      setCurrentSharedUserIds([]);
      setCurrentUnsharedUserIds([]);
    } else {
      setCurrentSharedUserIds(clonedCurrentSharedUserIds);
      setCurrentUnsharedUserIds(clonedCurrentUnsharedUserIds);
    }
  };

  const onChangeSelectAll = () => {
    setCurrentSharedUserIds([]);
    setCurrentUnsharedUserIds([]);
    setShareWithAllCheckedFlag(!isShareWithAllChecked);
  };

  const shareDisableFlag =
    (!currentUnsharedUserIds?.length > 0 &&
      _.isEqual(sharedUserIds.sort(), currentSharedUserIds.sort()) &&
      isPublic === isShareWithAllChecked) ||
    (currentUnsharedUserIds?.length > 0 && _.intersection(currentUnsharedUserIds, sharedUserIds).length === 0);

  const isIndeterminateState =
    (isShareWithAllChecked && currentUnsharedUserIds?.length >= 1) || currentSharedUserIds?.length >= 1;

  return (
    <div>
      <Modal
        visible={isVisible}
        okText="Share"
        cancelText="Cancel"
        onOk={onShareData}
        onCancel={handleClose}
        title={titleText}
        okButtonProps={{ disabled: shareDisableFlag, shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
      >
        <div>
          <div className={styles.shareSearchBox}>
            <Input.Search
              placeholder="Search Recruiters"
              shape="round"
              onSearch={onSearchClick}
              onChange={onSearchChange}
            />
          </div>
          <div className={styles.selectAllCheckbox}>
            <Checkbox onChange={onChangeSelectAll} indeterminate={isIndeterminateState} checked={isShareWithAllChecked}>
              Share with all
            </Checkbox>
          </div>
          <ShareModalItemsUtil
            searchValue={searchValue}
            handleLoadMore={handleLoadMore}
            userIds={userIds}
            maxCount={maxCount}
            topSharedUserIds={topSharedUserIds}
            ownerId={ownerId}
            usersById={usersById}
            onChangeUserSelection={onChangeUserSelection}
            currentSharedUserIds={currentSharedUserIds}
            currentUnsharedUserIds={currentUnsharedUserIds}
            isShareWithAllChecked={isShareWithAllChecked}
            viewerEmail={viewerEmail}
          />
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModalUtil);
export { ShareModalUtil as ShareModalWithoutConnect };
