import _ from 'lodash';
import * as actionCreators from './ActionCreators/WorkflowActionCreator';
import * as workflowDripTemplatesRepo from '../Repository/WorkflowRepository';
import { fetchConnectUsers } from './UserActions';
import { setNotification } from './ActionCreators/ConnectActions';

function searchWorkflowTemplates(filter, isFetchingTemplatesForAutomation = false, dispatchApiStatus = true) {
  return async dispatch => {
    if (dispatchApiStatus) {
      dispatch(actionCreators.setWorkflowTemplatesFetchApiStatus('InProgress'));
    }
    try {
      const response = await workflowDripTemplatesRepo.fetchWorkflowTemplates({ ...filter, LanguageCode: 'en-US' });
      const { Items, TotalCount } = response.data;
      if (!isFetchingTemplatesForAutomation || filter.From === 0) {
        dispatch(actionCreators.setWorkflowDripTemplates(Items));
      } else {
        dispatch(actionCreators.appendWorkflowDripTemplates(Items));
      }
      // !! TODO
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(Items.map(workflow => workflow?.CreatedBy)),
        })
      );
      dispatch(actionCreators.setWorkflowTemplatesCount(TotalCount));
      if (dispatchApiStatus) {
        dispatch(actionCreators.setWorkflowTemplatesFetchApiStatus('Completed'));
      }
    } catch (errorResponse) {
      const isRequestCancelled = errorResponse.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        dispatch(setNotification('ERROR', 'Oops, something just went wrong'));
      }
    }
  };
}

function editWorkflowDripTemplate(workflowId, workflow, filter) {
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage('Updating Workflow'));
    workflowDripTemplatesRepo.putWorkflowDripTemplate(workflowId, workflow).then(() => {
      dispatch(searchWorkflowTemplates(filter, false, false));
      dispatch(actionCreators.setSuccessNoificationMessage('Workflow updated'));
    });
  };
}

function saveWorkflowTemplate(workflow) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setLoadingNotificationMessage('Creating Workflow'));
      await workflowDripTemplatesRepo.saveWorkflowTemplate(workflow);
      dispatch(actionCreators.setSuccessNoificationMessage('Workflow created'));
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(setNotification('ERROR', `workflow with name ${workflow.Name} already exists.`));
      }
      throw error;
    }
  };
}
function fetchWorkflowTemplateById(workflowId) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'InProgress'));
      const response = await workflowDripTemplatesRepo.fetchWorkflowTemplateById(workflowId);
      dispatch(actionCreators.setWorkflowTemplateDetails(response.data));
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'Completed'));
    } catch {
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'Failed'));
    }
  };
}

function setWorkflowTemplateFetchApiStatus({ workflowId, status }) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, status));
  };
}

function deleteWorkflowDripTemplate(workflowId, filter) {
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage('Deleting Workflow'));
    workflowDripTemplatesRepo.deleteWorkflowDripTemplate(workflowId).then(() => {
      dispatch(searchWorkflowTemplates(filter, false, false));
      dispatch(actionCreators.setSuccessNoificationMessage('Workflow deleted'));
    });
  };
}

function duplicateWorkflowTemplate(workflowId, filter) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setLoadingNotificationMessage('Duplicating Workflow'));
      await workflowDripTemplatesRepo.duplicateWorkflowTemplate(workflowId);
      dispatch(actionCreators.setSuccessNoificationMessage('Workflow Duplicated'));
      dispatch(searchWorkflowTemplates(filter, false, false));
    } catch (error) {
      throw error;
    }
  };
}

function shareWorkflowDripTemplate(workflowId, shareOptions) {
  let successMessage = 'Workflow Shared Successfully';
  let loadingMessage = 'Sharing Workflow';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'Sharing Workflow With All';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'Workflow Un-shared Successfully';
    loadingMessage = 'Un-sharing Workflow With All';
  }
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage(loadingMessage));
    workflowDripTemplatesRepo
      .shareWorkflowDripTemplate(workflowId, shareOptions)
      .then(() => {
        dispatch(actionCreators.setWorkflowDripTemplateSharedWith(workflowId, shareOptions));
        dispatch(actionCreators.setSuccessNoificationMessage(successMessage));
      })
      .catch(() => {
        dispatch(actionCreators.setErrorForShareTemplatePostFailed());
        dispatch(actionCreators.setTemplateSharingFailureNotification());
      });
  };
}

function setWorkflowNodeConnections(data) {
  return actionCreators.setWorkflowNodeConnections(data);
}

export {
  searchWorkflowTemplates,
  saveWorkflowTemplate,
  fetchWorkflowTemplateById,
  editWorkflowDripTemplate,
  deleteWorkflowDripTemplate,
  duplicateWorkflowTemplate,
  shareWorkflowDripTemplate,
  setWorkflowTemplateFetchApiStatus,
  setWorkflowNodeConnections,
};
