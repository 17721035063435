/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { getOutGoingEdges } from './GraphUtils';

const isValidNode = node => {
  return node.type !== 'Empty' && node.type !== 'Merge';
};

const getValidNodeIdConnectedToEdgeId = ({ nodes, edges, edgeId }) => {
  const targetNodeId = edges.find(x => x.id === edgeId).target;
  const targetNode = nodes.find(x => x.id === targetNodeId);
  if (isValidNode(targetNode)) {
    return targetNodeId;
  }
  const outGoingEdge = getOutGoingEdges({ edges, targetNodeId })[0];
  return getValidNodeIdConnectedToEdgeId({
    nodes,
    edges,
    edgeId: outGoingEdge.id,
  });
};

const removeEdgesFromInvalidNodes = ({ nodes, edges }) => {
  const invalidNodeIds = nodes.filter(x => !isValidNode(x)).map(x => x.id);
  _.remove(edges, x => invalidNodeIds.includes(x.source));
};

const getFilteredNodes = ({ nodes }) => {
  return nodes.filter(x => isValidNode(x));
};

const getFilteredEdges = ({ edges, nodes }) => {
  const updatedEdges = _.cloneDeep(edges);
  updatedEdges.forEach(edge => {
    const { target } = edge;
    const targetNode = nodes.find(x => x.id === target);
    if (!isValidNode(targetNode)) {
      const validNodeId = getValidNodeIdConnectedToEdgeId({
        nodes,
        edges,
        edgeId: edge.id,
      });
      edge.target = validNodeId;
    }
  });
  return updatedEdges;
};

const generatePayload = ({ elements }) => {
  const clonedElements = _.cloneDeep(elements);
  const nodes = clonedElements.filter(x => x.position);
  const edges = clonedElements.filter(x => !x.position);
  const filteredNodes = getFilteredNodes({ nodes });
  const filteredEdges = getFilteredEdges({ edges, nodes });
  removeEdgesFromInvalidNodes({ nodes, edges: filteredEdges });
  return { nodes: filteredNodes, edges: filteredEdges };
};

export { generatePayload };
