import React from 'react';
import { Button } from 'antd';
import './AdvancedOptionsFooter.scss';

export default function AdvancedOptionsFooter(props) {
  const { onClear, onApply } = props;

  return (
    <div className="advance-options-footer">
      <Button key="clear" onClick={onClear}>
        Clear
      </Button>
      <Button key="apply" type="primary" onClick={onApply}>
        Apply
      </Button>
    </div>
  );
}
