import React from 'react';
import { validateStatus } from './ContactUtils';

function isValidContact(contact) {
  if (!contact) {
    return false;
  }
  return (contact.Emails && contact.Emails.length > 0) || (contact.Phones && contact.Phones.length > 0);
}

function hasAtleastOneValidContact(contact) {
  if (!isValidContact(contact)) {
    return false;
  }
  return !(
    !contact.Emails?.some(email => !validateStatus(email.ValidityStatus)) &&
    !contact.Phones?.some(phone => !validateStatus(phone.ValidityStatus))
  );
}

function candidateToContact(candidate) {
  if (!candidate) return null;
  return {
    Emails: (candidate.Emails || []).map(email => {
      return {
        EmailAddress: email,
      };
    }),
    Phones: (candidate.Phones || []).map(phone => {
      return {
        Number: phone,
      };
    }),
  };
}

function getIsCandidateCommunicationDisabled(personId, candidatesConnectInfo) {
  const candidateConnectInfo = candidatesConnectInfo?.ConnectStatuses?.[personId];
  const candidateConsentConfiguration = candidateConnectInfo?.Contact?.ConsentConfiguration;

  const isCandidateConsentRequired = candidateConsentConfiguration?.IsConsentRequired;
  const isConsentManagerEnabled = candidateConsentConfiguration?.IsEnabled;

  return isCandidateConsentRequired && !isConsentManagerEnabled;
}

function getCommunicationDisabledContent() {
  return {
    header: 'Communication Disabled',
    body: (
      <span>
        This feature shall be disabled if you intend to source candidates whose personal data is not governed by the
        General Data Protection Regulation (GDPR). More information on data privacy and GDPR is available at{' '}
        <a href="https://leoforce.com/data-privacy-policy/" target="_blank" rel="noreferrer">
          https://leoforce.com/data-privacy-policy/
        </a>
        .
      </span>
    ),
  };
}

function getConsentComplianceInfo(jobDetails, userConnectConfig) {
  const consentEnabledCountries = userConnectConfig?.ConsentEnabledCountries;
  const isConsentManagerEnabled = userConnectConfig?.IsEnabled;
  const jobCountryCode = jobDetails?.CountryCode;
  if (!isConsentManagerEnabled && consentEnabledCountries?.find(countryCode => countryCode === jobCountryCode)) {
    return getCommunicationDisabledContent();
  }
  return null;
}

export const connectTemplateNameByType = {
  email: 'email',
  sms: 'sms',
  drip: 'drip',
  bot: 'bot',
};

export const contactPullStatusText = {
  All: 'All contacts available',
  None: 'No contacts available',
  Email: 'Only Email address available',
  InValidEmail: 'Only Invalid Email address available',
  Phone: 'Only Phone number available',
  NotPulled: 'No contacts pulled',
};

export const consentStatusText = {
  Pending: 'Consent requested',
  Denied: 'Consent rejected',
  Failed: 'Consent request failed',
  Approved: 'Consent approved',
};

export const supportedReactQuillFormats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
  'image',
  'video',
];

export const getSelectedEmail = (currentEmails, nonRefundedEmails) => {
  const concatenatedEmails = (currentEmails || []).concat(nonRefundedEmails);
  const selectedValidEmail = concatenatedEmails.filter(email => email.ValidityStatus === 'Valid');
  return selectedValidEmail.length ? selectedValidEmail : concatenatedEmails;
};

export {
  isValidContact,
  candidateToContact,
  getIsCandidateCommunicationDisabled,
  getCommunicationDisabledContent,
  getConsentComplianceInfo,
  hasAtleastOneValidContact,
};
