export const Categories = [
  {
    value: 'Accounting',
    displayText: 'Accounting',
  },
  {
    value: 'Admin',
    displayText: 'Admin - Clerical',
  },
  {
    value: 'Automotive',
    displayText: 'Automotive',
  },
  {
    value: 'Banking',
    displayText: 'Banking',
  },
  {
    value: 'Biotech',
    displayText: 'Biotech',
  },
  {
    value: 'BusinessDevelopment',
    displayText: 'Business Development',
  },
  {
    value: 'BusinessOpportunity',
    displayText: 'Business Opportunity',
  },
  {
    value: 'Construction',
    displayText: 'Construction',
  },
  {
    value: 'Consultant',
    displayText: 'Consultant',
  },
  {
    value: 'CustomerService',
    displayText: 'Customer Service',
  },
  {
    value: 'Design',
    displayText: 'Design',
  },
  {
    value: 'DistributionShipping',
    displayText: 'Distribution - Shipping',
  },
  {
    value: 'Education',
    displayText: 'Education',
  },
  {
    value: 'Engineering',
    displayText: 'Engineering',
  },
  {
    value: 'Fresher',
    displayText: 'Entry Level',
  },
  {
    value: 'Executive',
    displayText: 'Executive',
  },
  {
    value: 'Facilities',
    displayText: 'Facilities',
  },
  {
    value: 'Finance',
    displayText: 'Finance',
  },
  {
    value: 'Franchise',
    displayText: 'Franchise',
  },
  {
    value: 'GeneralBusiness',
    displayText: 'General Business',
  },
  {
    value: 'GeneralLabour',
    displayText: 'General Labour',
  },
  {
    value: 'Government',
    displayText: 'Government',
  },
  {
    value: 'GovernmentFederal',
    displayText: 'Government - Federal',
  },
  {
    value: 'Grocery',
    displayText: 'Grocery',
  },
  {
    value: 'HealthCare',
    displayText: 'Health Care',
  },
  {
    value: 'HospitalityHotel',
    displayText: 'Hospitality - Hotel',
  },
  {
    value: 'HumanResources',
    displayText: 'Human Resources',
  },
  {
    value: 'InformationTechnology',
    displayText: 'Information Technology',
  },
  {
    value: 'InstallationMaintRepair',
    displayText: 'Installation - Maint - Repair',
  },
  {
    value: 'Insurance',
    displayText: 'Insurance',
  },
  {
    value: 'Inventory',
    displayText: 'Inventory',
  },
  {
    value: 'Legal',
    displayText: 'Legal',
  },
  {
    value: 'LegalAdmin',
    displayText: 'Legal Admin',
  },
  {
    value: 'Management',
    displayText: 'Management',
  },
  {
    value: 'Manufacturing',
    displayText: 'Manufacturing',
  },
  {
    value: 'Marketing',
    displayText: 'Marketing',
  },
  {
    value: 'MediaJournalismNewspaper',
    displayText: 'Media - Journalism - Newspaper',
  },
  {
    value: 'NonprofitSocialServices',
    displayText: 'Nonprofit - SocialServices',
  },
  {
    value: 'Nurse',
    displayText: 'Nurse',
  },
  {
    value: 'Other',
    displayText: 'Other',
  },
  {
    value: 'Pharmaceutical',
    displayText: 'Pharmaceutical',
  },
  {
    value: 'ProfessionalServices',
    displayText: 'Professional Services',
  },
  {
    value: 'PurchasingProcurement',
    displayText: 'Purchasing - Procurement',
  },
  {
    value: 'QA',
    displayText: 'QA - Quality Control',
  },
  {
    value: 'RealEstate',
    displayText: 'Real Estate',
  },
  {
    value: 'Research',
    displayText: 'Research',
  },
  {
    value: 'Restaurant',
    displayText: 'Restaurant',
  },
  {
    value: 'Retail',
    displayText: 'Retail',
  },
  {
    value: 'Sales',
    displayText: 'Sales',
  },
  {
    value: 'Science',
    displayText: 'Science',
  },
  {
    value: 'SkilledLabourTrades',
    displayText: 'Skilled Labour - Trades',
  },
  {
    value: 'StrategyPlanning',
    displayText: 'Strategy - Planning',
  },
  {
    value: 'SupplyChain',
    displayText: 'Supply Chain',
  },
  {
    value: 'Telecommunications',
    displayText: 'Telecommunications',
  },
  {
    value: 'Training',
    displayText: 'Training',
  },
  {
    value: 'Transportation',
    displayText: 'Transportation',
  },
  {
    value: 'VeterinaryServices',
    displayText: 'Veterinary Services',
  },
  {
    value: 'Warehouse',
    displayText: 'Warehouse',
  },
];

export const EmploymentTypes = [
  {
    value: 'FullTime',
    displayText: 'Full-Time',
  },
  {
    value: 'PartTime',
    displayText: 'Part-Time',
  },
  {
    value: 'Contractor',
    displayText: 'Contractor',
  },
  {
    value: 'Intern',
    displayText: 'Intern',
  },
  {
    value: 'Seasonal',
    displayText: 'Seasonal/Temp',
  },
];

export const CurrentlyEmployedOptions = [
  {
    value: 'true',
    displayText: 'Yes',
  },
  {
    value: 'false',
    displayText: 'No',
  },
  {
    value: undefined,
    displayText: 'Does not matter',
  },
];

export const SecurityClearanceOptions = [
  { value: 'true', displayText: 'Yes' },
  { value: 'false', displayText: 'No' },
  { value: 'Either', displayText: 'Either' },
];

export const ManagementExperiences = [
  {
    value: 'Yes',
    displayText: 'Yes',
  },
  {
    value: 'No',
    displayText: 'No',
  },
  {
    value: 'Either',
    displayText: 'Does not matter',
  },
];

export const SearchWithOptions = [
  {
    value: true,
    displayText: 'Last Activity',
  },
  {
    value: false,
    displayText: 'Last Modified',
  },
];

export const OrderByOptions = [
  {
    value: 'Relevancy',
    displayText: 'Relevancy',
    disabledFor: [],
  },
  {
    value: 'ModifiedDate',
    displayText: 'Modified Date',
    disabledFor: ['ActivityDate'],
  },
  {
    value: 'ActivityDate',
    displayText: 'Activity Date',
    disabledFor: ['ModifiedDate'],
  },
];

export const Languages = [
  {
    value: 'English',
    displayText: 'English',
  },
  {
    value: 'ChineseMandarin',
    displayText: 'Chinese-Mandarin',
  },
  {
    value: 'Czech',
    displayText: 'Czech',
  },
  {
    value: 'Danish',
    displayText: 'Danish',
  },
  {
    value: 'Dutch',
    displayText: 'Dutch',
  },
  {
    value: 'Estonian',
    displayText: 'Estonian',
  },
  {
    value: 'Finnish',
    displayText: 'Finnish',
  },
  {
    value: 'French',
    displayText: 'French',
  },
  {
    value: 'German',
    displayText: 'German',
  },
  {
    value: 'Greek',
    displayText: 'Greek',
  },
  {
    value: 'Hebrew',
    displayText: 'Hebrew',
  },
  {
    value: 'Hungarian',
    displayText: 'Hungarian',
  },
  {
    value: 'Icelandic',
    displayText: 'Icelandic',
  },
  {
    value: 'Italian',
    displayText: 'Italian',
  },
  {
    value: 'Japanese',
    displayText: 'Japanese',
  },
  {
    value: 'Korean',
    displayText: 'Korean',
  },
  {
    value: 'Latvian',
    displayText: 'Latvian',
  },
  {
    value: 'Lithuanian',
    displayText: 'Lithuanian',
  },
  {
    value: 'Norwegian',
    displayText: 'Norwegian',
  },
  {
    value: 'Polish',
    displayText: 'Polish',
  },
  {
    value: 'Portuguese',
    displayText: 'Portuguese',
  },
  {
    value: 'Romanian',
    displayText: 'Romanian',
  },
  {
    value: 'Russian',
    displayText: 'Russian',
  },
  {
    value: 'Spanish',
    displayText: 'Spanish',
  },
  {
    value: 'Swedish',
    displayText: 'Swedish',
  },
  {
    value: 'Arabic',
    displayText: 'Arabic',
  },
  {
    value: 'Bengali',
    displayText: 'Bengali',
  },
  {
    value: 'ChineseCantonese',
    displayText: 'Chinese-Cantonese',
  },
  {
    value: 'ChineseTaiwanese',
    displayText: 'Chinese-Taiwanese',
  },
  {
    value: 'Hindi',
    displayText: 'Hindi',
  },
  {
    value: 'Urdu',
    displayText: 'Urdu',
  },
  {
    value: 'Armenian',
    displayText: 'Armenian',
  },
  {
    value: 'Assamese',
    displayText: 'Assamese',
  },
  {
    value: 'Farsi',
    displayText: 'Farsi',
  },
  {
    value: 'Gujarati',
    displayText: 'Gujarati',
  },
  {
    value: 'Kannada',
    displayText: 'Kannada',
  },
  {
    value: 'Kashmiri',
    displayText: 'Kashmiri',
  },
  {
    value: 'Malayalam',
    displayText: 'Malayalam',
  },
  {
    value: 'Oriya',
    displayText: 'Oriya',
  },
  {
    value: 'Pashto',
    displayText: 'Pashto',
  },
  {
    value: 'Punjabi',
    displayText: 'Punjabi',
  },
  {
    value: 'Sanskrit',
    displayText: 'Sanskrit',
  },
  {
    value: 'Sindhi',
    displayText: 'Sindhi',
  },
  {
    value: 'Tamil',
    displayText: 'Tamil',
  },
  {
    value: 'Telugu',
    displayText: 'Telugu',
  },
  {
    value: 'Turkish',
    displayText: 'Turkish',
  },
  {
    value: 'Uzbek',
    displayText: 'Uzbek',
  },
  {
    value: 'Indonesian',
    displayText: 'Indonesian',
  },
  {
    value: 'Catalán',
    displayText: 'Catalán',
  },
  {
    value: 'Euskera',
    displayText: 'Euskera',
  },
  {
    value: 'Vasco',
    displayText: 'Vasco',
  },
  {
    value: 'Bulgarian',
    displayText: 'Bulgarian',
  },
  {
    value: 'Croatian',
    displayText: 'Croatian',
  },
  {
    value: 'Macedonian',
    displayText: 'Macedonian',
  },
  {
    value: 'Serbian',
    displayText: 'Serbian',
  },
  {
    value: 'Albanian',
    displayText: 'Albanian',
  },
  {
    value: 'Cambodian',
    displayText: 'Cambodian',
  },
  {
    value: 'ChineseChinois',
    displayText: 'Chinese-Chinois',
  },
  {
    value: 'Ukranian',
    displayText: 'Ukranian',
  },
  {
    value: 'Vietnamese',
    displayText: 'Vietnamese',
  },
  {
    value: 'Tagalog',
    displayText: 'Tagalog',
  },
  {
    value: 'Thai',
    displayText: 'Thai',
  },
  {
    value: 'Malay',
    displayText: 'Malay',
  },
  {
    value: 'Others',
    displayText: 'Others',
  },
];

export const WorkAuthorizations = [
  {
    value: 'US',
    displayText: 'US Citizen',
  },
  {
    value: 'H1',
    displayText: 'Have H1 Visa',
  },
  {
    value: 'GreenCard',
    displayText: 'Green Card Holder',
  },
  {
    value: 'NeedH1Sponsor',
    displayText: 'Need H1 Visa Sponsor',
  },
  {
    value: 'TNPermitHolder',
    displayText: 'TN Permit Holder',
  },
  {
    value: 'Employment Auth Document',
    displayText: 'Employment Authorization Document',
  },
  {
    value: 'NotSpecified',
    displayText: 'Not Specified',
  },
  {
    value: 'CanWork',
    displayText: 'Can work for any employer',
  },
];

export const MilitaryExperiences = [
  {
    value: 'ActiveDuty',
    displayText: 'Active Duty',
  },
  {
    value: 'ReservestDrilling',
    displayText: 'Reservist - Drilling',
  },
  {
    value: 'NationalGuardDrilling',
    displayText: 'National Guard - Drilling',
  },
  {
    value: 'InactiveReserve',
    displayText: 'Inactive Reserve',
  },
  {
    value: 'InactiveNationalGuard',
    displayText: 'Inactive National Guard',
  },
  {
    value: 'RetiredMilitary',
    displayText: 'Retired Military',
  },
  {
    value: 'Veteran',
    displayText: 'Veteran',
  },
];

export const TravelOptions = [
  {
    value: 'None',
    displayText: 'None',
  },
  {
    value: 'Negligible',
    displayText: 'Negligible',
  },
  {
    value: 'Max30',
    displayText: 'Up to 25%',
  },
  {
    value: 'Max50',
    displayText: 'Up to 50%',
  },
  {
    value: 'Unlimited',
    displayText: 'Road Warrior',
  },
];
