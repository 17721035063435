import React from 'react';
import { Select, Popover, Tooltip } from 'antd';
import styles from './RegionUtils.module.scss';

const { Option } = Select;

function getRegionOptions({ regions = [] }) {
  return regions.map(region => (
    <Option key={region.RegionId} value={region.RegionId} style={{ marginRight: '0px' }}>
      <Popover
        content={
          <div>
            {region.Locations?.map(location => (
              <div>{location.Location}</div>
            ))}
          </div>
        }
        trigger="hover"
        placement="rightTop"
        overlayClassName={styles.regionPopover}
        key={region.RegionId}
      >
        <div className={styles.regionName}>{region.RegionName}</div>
      </Popover>
    </Option>
  ));
}

function getRegionTooltip({ regions = [], regionId }) {
  const region = regions.filter(region => region.RegionId === regionId);
  return (
    <Tooltip
      title={
        <div>
          {region?.[0]?.Locations?.map(location => (
            <div>{location.Location}</div>
          ))}
        </div>
      }
      placement="top"
    >
      {' '}
      {region?.[0]?.RegionName}
    </Tooltip>
  );
}

export { getRegionOptions, getRegionTooltip };
