import React from 'react';
import EducationSection from './EducationSection/EducationSection';
import EmploymentSection from './EmploymentSection/EmploymentSection';
import styles from './AdvancedCriteria.module.scss';
import PersonalInformationSection from './PersonalSection/PersonalInformationSection';

function AdvancedCriteria(props) {
  const { form, defaultFormValues, industries, candidateContext } = props;
  return (
    <div className={styles.advancedCriteriaWrapper}>
      <EducationSection form={form} defaultFormValues={defaultFormValues} />
      <EmploymentSection
        form={form}
        defaultFormValues={defaultFormValues}
        industries={industries}
        candidateContext={candidateContext}
      />
      <PersonalInformationSection form={form} defaultFormValues={defaultFormValues} />
    </div>
  );
}

export default React.memo(AdvancedCriteria);
