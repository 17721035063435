import React from 'react';
import * as ReactRedux from 'react-redux';
import _ from 'lodash';
import { Tag, Skeleton } from 'antd';
import { EllipsedText } from '../../Utils/TextUtils';
import { fetchSuggestions } from '../../Actions/SuggestionsActions';
import RejectReasonsSuggestions from '../RejectReasonsSuggestions/RejectReasonsSuggestions';
import ManualRejectReasons from '../ManualRejectReasons/ManualRejectReasons';

import './TiocMismatchContent.scss';

const TiocMismatchContent = props => {
  const {
    suggestions = [],
    value: selectedReasons = [],
    jobId,
    candidateId,
    negativeValue,
    onChange,
    mismatchTitleText,
    moreReasonsTitleText,
    rejectReasonKey,
    suggestionsFetchApiStatus,
    form,
  } = props;

  const dispatch = ReactRedux.useDispatch();
  React.useEffect(() => {
    if (negativeValue)
      dispatch(fetchSuggestions({ jobId, candidateId, negativeValue, suggestionsType: rejectReasonKey }));
  }, []);

  const deselectReason = reason => {
    const currentSelectedValues = selectedReasons.filter(selectedReason => selectedReason !== reason);
    onChange(currentSelectedValues);
  };

  const selectReason = reason => {
    const currentSelectedValues = _.uniqBy(selectedReasons.concat(reason), selectedReason =>
      selectedReason.toLowerCase()
    );
    onChange(currentSelectedValues);
  };

  const addManualReasons = reasons => {
    selectReason(reasons.split(','));
    form.setFieldsValue({ [`${rejectReasonKey}-Input`]: '' });
  };

  const getSelectedReasons = () => {
    if (!selectedReasons?.length) return null;
    return (
      <div className="selected-reject-reasons">
        {selectedReasons.map(reason => (
          <Tag
            className="selected-reject-reason-tag"
            key={reason}
            closable
            onClose={e => {
              e.preventDefault();
              deselectReason(reason);
            }}
          >
            <EllipsedText text={reason} maxTextLength={20} />
          </Tag>
        ))}
      </div>
    );
  };

  if (!negativeValue)
    return (
      <ManualRejectReasons
        selectedReasons={getSelectedReasons()}
        addRejectReason={addManualReasons}
        mismatchTitleText={mismatchTitleText}
        form={form}
        rejectReasonKey={rejectReasonKey}
        moreReasonsTitleText={moreReasonsTitleText}
      />
    );
  const filteredSuggestions = suggestions.filter(
    suggestion =>
      !selectedReasons.find(selectedReason => selectedReason.trim().toLowerCase() === suggestion.trim().toLowerCase())
  );
  return (
    <div className="candidate-reject-reason-popover-content">
      <div className="selected-reject-reasons-wrapper">
        {selectedReasons?.length > 0 ? (
          <>
            <div className="selected-reasons-title">Selected mismatched {moreReasonsTitleText} will help Arya {_.lowerFirst(mismatchTitleText)}</div>
            {getSelectedReasons()}
          </>
        ) : (
          <div className="selected-reasons-empty-state">Selected mismatched {moreReasonsTitleText} will appear here</div>
        )}
      </div>
      <div className="more-suitable-reasons-wrapper">
        <div className="more-suitable-reasons-title">
          {suggestionsFetchApiStatus === 'INPROGRESS' || filteredSuggestions.length
            ? `Would you like to select some more unsuitable ${moreReasonsTitleText}?`
            : `No other mismatched ${moreReasonsTitleText} found`}
        </div>
        {suggestionsFetchApiStatus === 'INPROGRESS' ? (
          <div className="reject-reasons-suggestions-loading-state-wrapper">
            <Skeleton active paragraph={{ rows: 2 }} />
          </div>
        ) : (
          <RejectReasonsSuggestions
            suggestions={filteredSuggestions}
            selectedReasons={selectedReasons}
            selectReason={selectReason}
          />
        )}
      </div>
    </div>
  );
};

export default TiocMismatchContent;
