import React from 'react';
import { Button, Alert } from 'antd';
import { InfoIcon } from '../../../Icons/AryaIcons';
import styles from './AutoRejectAlertMessage.module.scss';

export default function AutoRejectAlertMessage(props) {
  const {
    appliedCandidateCount,
    autoRejectedCandidateCount,
    undoButtonLoadingStatus,
    onUndoClick,
    onReviewClick,
  } = props;
  const messageText = `Arya has auto-rejected ${autoRejectedCandidateCount} candidates out of ${appliedCandidateCount} applied as they were not matching the job criteria.`;
  const message = (
    <div className={styles.autoRejectAlertMessageWrapper}>
      <div className={styles.autoRejectAlertMessageText}>{messageText}</div>
      <div className={styles.autoRejectAlertMessageActions}>
        <Button
          className={styles.autoRejectAlertActionButton}
          type="link"
          size="small"
          onClick={onUndoClick}
          loading={undoButtonLoadingStatus}
        >
          Undo Rejection
        </Button>
        <Button
          className={styles.autoRejectAlertActionButton}
          type="link"
          size="small"
          onClick={() => onReviewClick('rejected')}
        >
          Review
        </Button>
      </div>
    </div>
  );
  return (
    <Alert message={message} type="info" showIcon icon={<InfoIcon className={styles.autoRejectAlertInfoIcon} />} />
  );
}
