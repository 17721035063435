import React from 'react';
import WorkflowNodeModalContentMapper from '../WorkflowNodeModalContentMapper/WorkflowNodeModalContentMapper';

export default function WorkflowNodeModalContent(props) {
  const {
    type,
    form,
    initialValue,
    drawerFooter,
    onCancel,
    onSave,
    mergeTags,
    isDisabled,
    isFirstEdge,
    isFirstNode,
  } = props;
  return (
    <WorkflowNodeModalContentMapper
      initialValue={initialValue}
      isFirstEdge={isFirstEdge}
      isFirstNode={isFirstNode}
      isDisabled={isDisabled}
      type={type}
      form={form}
      onCancel={onCancel}
      onSave={onSave}
      drawerFooter={drawerFooter}
      mergeTags={mergeTags}
    />
  );
}
