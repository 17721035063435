import React from 'react';
import { Row, Col } from 'antd';
import LocationWrapper from '../../../../../CompoundComponents/LocationWrapper/LocationWrapper';
import Country from '../../../../../CompoundComponents/LocationWrapper/Country/Country';
import Location from '../../../../../CompoundComponents/LocationWrapper/Location/Location';
import Zipcode from '../../../../../CompoundComponents/LocationWrapper/Zipcode/Zipcode';
import RemoteJob from '../../../../../CompoundComponents/LocationWrapper/RemoteJob/RemoteJob';

function ManualSearchLocationInput(props) {
  const {
    form,
    isPaidJobServiceEnabled,
    isCountryWideSearchEnabled,
    defaultIsStateWideSearchChecked,
    defaultIsRemoteLocation,
    defaultLocationObject = {},
    AppName,
    candidateContext,
  } = props;
  const [processedDefaultLocationObject] = Array.isArray(defaultLocationObject)
    ? defaultLocationObject
    : [defaultLocationObject];
  const {
    CountryCode: defaultCountryCode,
    Location: defaultLocation,
    Zipcode: defaultZipcode,
  } = processedDefaultLocationObject;
  const isRemoteJobChecked = form.getFieldValue('IsRemoteJob');
  const isDisabled = candidateContext === 'job' || isCountryWideSearchEnabled || isRemoteJobChecked;
  return (
    <LocationWrapper {...props}>
      {form.getFieldDecorator('IsStateWideSearch', { initialValue: defaultIsStateWideSearchChecked })}
      <Row gutter="16">
        <Col span={4}>
          <Country size="large" label="Country" initialValue={defaultCountryCode} isDisabled={isDisabled} />
        </Col>

        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px auto', display: 'block', justifyContent: 'left' }}>
            <Col span={7}>
              <Location
                size="large"
                label="Location"
                initialValue={defaultLocation}
                required={isPaidJobServiceEnabled}
                disabled={isCountryWideSearchEnabled || isRemoteJobChecked}
                placeholder="City, State or State"
              />
            </Col>
          </div>

          <div style={{ marginLeft: '5px auto', display: 'block', justifyContent: 'right' }}>
            <Col span={15}>
              <Zipcode
                size="large"
                label="Postal Code"
                initialValue={defaultZipcode}
                required={isPaidJobServiceEnabled}
                disabled={isCountryWideSearchEnabled || isRemoteJobChecked}
              />
            </Col>
          </div>
        </div>
      </Row>
      {!isPaidJobServiceEnabled ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <RemoteJob initialValue={defaultIsRemoteLocation} />
          <span>Remote Job</span>
          <span style={{ opacity: '0.49' }}>({AppName} will search throughout the country)</span>
        </div>
      ) : null}
    </LocationWrapper>
  );
}

export default ManualSearchLocationInput;
