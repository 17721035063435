import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import LocationContext from '../LocationContext';
import { getDisplayedLocations } from '../../../Utils/LocationUtils';
import styles from './Location.module.scss';

const FormItem = Form.Item;
const { Option } = Select;

const Location = props => {
  const { label, initialValue, required, disabled, locationTooltipContent, placeholder, formItemStyle } = props;
  const {
    handleLocationSelect,
    locationSuggestions,
    isCountrySelected,
    locationDropDownStatus,
    form,
    clearZipCodeError,
    setLocationInput,
    onSearchLocation,
  } = React.useContext(LocationContext);

  const currentLocation = form.getFieldValue('Location');

  const handleLocationSearch = debounce(value => {
    setLocationInput(value);
    onSearchLocation(value);
  }, 600);

  const handleLocationChange = value => {
    if (!value?.length) {
      form.setFieldsValue({
        Location: undefined,
        Zipcode: undefined,
      });
      clearZipCodeError();
      form.setFieldsValue({ IsStateWideSearch: false });
    }
  };

  const displayedLocations = getDisplayedLocations({ currentLocation, locationSuggestions });
  const locationSelect = (
    <Select
      allowClear
      key="Location"
      showSearch
      placeholder={placeholder}
      className={styles.locationSelect}
      dropdownClassName={styles.locationDropdown}
      notFoundContent={locationDropDownStatus}
      onSearch={handleLocationSearch}
      onSelect={handleLocationSelect}
      onChange={handleLocationChange}
      disabled={disabled || !isCountrySelected}
      showArrow={false}
      filterOption={false}
    >
      {displayedLocations?.map(locationOption => (
        <Option key={locationOption.value ?? locationOption} value={locationOption.value ?? locationOption}>
          {locationOption.label ?? locationOption}
        </Option>
      ))}
    </Select>
  );
  return (
    <Tooltip title={locationTooltipContent}>
      <FormItem label={label} colon={false} style={formItemStyle}>
        {form.getFieldDecorator('Location', {
          rules: [{ required, message: 'Please input location' }],
          initialValue,
        })(locationSelect)}
      </FormItem>
    </Tooltip>
  );
};

export default Location;
