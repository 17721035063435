import React from 'react';
import { Button } from 'antd';
import { VerificationLinkExpiredIcon } from '../../Icons/AryaIcons';
import style from './UserVerification.module.scss';

export default function UserVerificationLinkExpired({ username, resendCode }) {
  return (
    <>
      <VerificationLinkExpiredIcon />
      <h1 className={style.linkExpiredHeading}>Link Expired</h1>
      <p>
        The verification link is valid for 24 hours and has expired. Request for a new link by clicking on the button
        below
      </p>
      <Button type="primary" shape="round" className={style.linkExpiredButton} onClick={() => resendCode(username)}>
        Request Verification Link
      </Button>
    </>
  );
}
