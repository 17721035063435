import { Button, Modal } from 'antd';
import React from 'react';
import {
  DeclinedTransactionIcon,
  EmailConfirmedIcon,
  EmailPendingIcon,
  OrderConfirmationIcon,
  TransactionFailedIcon,
} from '../../Icons/AryaIcons';
import PaymentErrorModal from './PaymentErrorModal';
import paymentData from './PaymentModalContent';
import './PaymentProgressModal.scss';
import { isInvalidDataError, isDuplicationError, isGatewayError } from '../../Utils/PaymentUtils';
import { getErrorMessage } from '../../Utils/TextUtils';

const paymentModalIcon = status => {
  switch (status) {
    case 'Order Success':
      return <EmailConfirmedIcon className="order-status-icon" />;
    case 'Order Pending':
      return <EmailPendingIcon className="order-status-icon" />;
    case 'Order Failed':
      return <DeclinedTransactionIcon className="order-failed-status-icon" />;
    case 'Payment Processing':
      return <h2>Payment Processing</h2>;
    case 'Payment Success':
      return <h2>Payment Success</h2>;
    case 'Payment Failed':
      return <TransactionFailedIcon className="order-status-icon" />;
    case 'Success':
      return <OrderConfirmationIcon className="order-status-icon" />;
    default:
      return <p>Something went wrong, Try Refreshing Page!</p>;
  }
};

function getModalContent(paymentStatus, orderStatus, updateBillingInfoStatus, invoiceNumber) {
  const {
    orderFailedContent,
    paymentProcessingContent,
    paymentSuccessContent,
    paymentFailedContent,
    orderPendingContent,
    orderSuccessContent,
  } = paymentData;
  if (!paymentStatus) {
    if (updateBillingInfoStatus === 'FAILED') {
      return orderFailedContent;
    }
  }
  if (!orderStatus) {
    if (paymentStatus === 'PENDING') {
      return paymentProcessingContent;
    }
    if (paymentStatus === 'SUCCESS') {
      return paymentSuccessContent;
    }
    if (paymentStatus === 'FAILED') {
      return paymentFailedContent;
    }
  }
  if (orderStatus === 'PENDING') {
    return orderPendingContent;
  }
  if (orderStatus === 'SUCCESS') {
    orderSuccessContent.orderNumber = invoiceNumber;
    return orderSuccessContent;
  }
  if (orderStatus === 'FAILED') {
    return orderFailedContent;
  }

  return paymentProcessingContent;
}
export default function PaymentProgressModal({
  visible,
  paymentStatus,
  orderStatus,
  orderErrorMessage,
  updateBillingInfoStatus,
  history,
  invoiceNumber,
  toggleOrderModalVisibility,
  onClickDuplicatePaymentOk,
  isOrderSuccessModalDisabled,
}) {
  if (isInvalidDataError(orderStatus)) {
    const { errorTitle, errorDescription } = getErrorMessage(orderStatus);
    return (
      <PaymentErrorModal
        creditCardFormDisplay={visible}
        handleCancel={toggleOrderModalVisibility}
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        orderErrorMessage={orderErrorMessage}
      />
    );
  }
  if (isDuplicationError(orderStatus)) {
    const { errorTitle, errorDescription } = getErrorMessage(orderStatus);
    return (
      <PaymentErrorModal
        creditCardFormDisplay={visible}
        handleCancel={onClickDuplicatePaymentOk}
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        orderErrorMessage={orderErrorMessage}
        type="duplicateTransaction"
      />
    );
  }
  if (isGatewayError(orderStatus)) {
    const { errorTitle, errorDescription } = getErrorMessage(orderStatus);
    return (
      <PaymentErrorModal
        creditCardFormDisplay={visible}
        handleCancel={onClickDuplicatePaymentOk}
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        orderErrorMessage={orderErrorMessage}
        type="declineTransaction"
      />
    );
  }

  if (orderStatus === 'PENDING' && visible) {
    return (
      <div id="overlay">
        <div>
          <img src="/static/Images/arya-logo-green.svg" alt="arya logo" className="loading-logo" />
          Do not close this browser tab or go back until processing is complete
        </div>
      </div>
    );
  }
  const modalContent = getModalContent(paymentStatus, orderStatus, updateBillingInfoStatus, invoiceNumber);
  return (
    <>
      {modalContent.orderStatus === 'Order Success' ? (
        !isOrderSuccessModalDisabled && (
          <Modal
            visible={visible}
            footer={null}
            style={{ textAlign: 'center' }}
            maskClosable={false}
            closable={!!modalContent.closable}
            onCancel={toggleOrderModalVisibility}
            zIndex={2001}
            centered
            keyboard={false}
          >
            <h2>{modalContent.header}</h2>
            <div>{paymentModalIcon(modalContent.orderStatus)}</div>
            <h2>{modalContent.orderMessage}</h2>
            <h3 id="orderSuccess">{`#Order Number: ${modalContent.orderNumber}`}</h3>
            {modalContent.footer && <>{modalContent.footer}</>}
            {modalContent.link && (
              <Button onClick={() => history.push(modalContent.link)}>{modalContent.linkText}</Button>
            )}
          </Modal>
        )
      ) : (
        <Modal
          visible={visible}
          footer={null}
          maskClosable={false}
          closable={!!modalContent.closable}
          onCancel={toggleOrderModalVisibility}
          zIndex={2001}
          keyboard={false}
          width="646px"
        >
          <div className="order-error-modal">
            {paymentModalIcon(modalContent.orderStatus)}
            <div className="order-error-modal-title">{modalContent.header}</div>
            <div className="order-error-modal-description">
              Please reach out to <a href="mailto:support@leoforce.com">support@leoforce.com</a> if this issue persists
            </div>
            <Button
              type="primary"
              shape="round"
              onClick={toggleOrderModalVisibility}
              className="order-error-modal-try-again-button"
            >
              Try Again
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
