import React from 'react';
import { Skeleton } from 'antd';
import CandidateActivityLogs from './CandidateActivityLogs';

function CandidateActivityWrapper(props) {
  const {
    clearCandidateActivityLogs,
    fetchCandidateActivityLogs,
    candidateActivityLogsCount,
    candidateActivityLogs,
    initialFetchActivityLogsApiStatus,
    candidate,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
  } = props;

  React.useEffect(() => {
    fetchCandidateActivityLogs({ from: 0, size: 10, candidateId: candidate?.Id });
    return () => {
      clearCandidateActivityLogs({ candidateId: candidate?.Id });
    };
  }, []);

  const onLoadMoreActivity = page => {
    fetchCandidateActivityLogs({ from: page * 10, size: 10, candidateId: candidate?.Id });
  };

  return (
    <Skeleton loading={initialFetchActivityLogsApiStatus === 'INPROGRESS'} paragraph={{ rows: 8 }}>
      <CandidateActivityLogs
        fetchCandidateActivityLogs={fetchCandidateActivityLogs}
        candidate={candidate}
        clearCandidateActivityLogs={clearCandidateActivityLogs}
        activityLogs={candidateActivityLogs}
        candidateActivityLogsCount={candidateActivityLogsCount}
        onLoadMore={onLoadMoreActivity}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        version={version}
      />
    </Skeleton>
  );
}

export default CandidateActivityWrapper;
