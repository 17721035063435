import { defineMessages } from 'react-intl';

const messages = defineMessages({
  workflowDripTemplates: {
    id: 'Workflows.workflow.workflowDripTemplates',
    defaultMessage: 'Workflow Drip Templates',
  },
  fetchWorkflowTemplates: {
    id: 'Workflows.workflow.fetchWorkflowTemplates',
    defaultMessage: 'Search Workflow Templates',
  },
  template: {
    id: 'Workflows.workflow.Heading.template',
    defaultMessage: 'TEMPLATE',
  },
  shared: {
    id: 'Workflows.workflow.Heading.shared',
    defaultMessage: 'SHARED',
  },
  created: {
    id: 'Workflows.workflow.Heading.created',
    defaultMessage: 'CREATED',
  },
  updatedOn: {
    id: 'Workflows.workflow.Heading.updatedOn',
    defaultMessage: 'UPDATED ON',
  },
  myWorkflow: {
    id: 'Workflows.workflow.Tab.myWorkflow',
    defaultMessage: 'My Workflow',
  },
  shareWorkflow: {
    id: 'Workflows.workflow.Tab.shareWorkflow',
    defaultMessage: 'Shared Workflow',
  },
  createdBy: {
    id: 'Workflows.workflow',
    defaultMessage: 'CREATED BY',
  },
  workflowName: {
    id: 'Workflows.workflow',
    defaultMessage: 'WORKFLOW NAME',
  },
  sharedWith: {
    id: 'Workflows.workflow',
    defaultMessage: 'SHARED WITH',
  },
});

export default messages;
