import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import {
  emailStatsTooltipTexts,
  emailStatsTitleForSegments,
  emailStatusValueForSegmemnts,
} from '../../Utils/EmailStatsUtils';

function SegmentEmailStatsFilter(props) {
  const { emailStats = [] } = props;
  const allowedSentStatuses = ['Success', 'Opened', 'Clicked'];
  const allowedOpenedStauses = ['Opened', 'Clicked'];
  const sentEmailsTypeArray = emailStats.filter(status => allowedSentStatuses.includes(status.Value));
  const openedEmailsTypeArray = emailStats.filter(status => allowedOpenedStauses.includes(status.Value));
  const filteredEmailStats = emailStats.filter(status => status.Value !== 'Opened');

  const getEmailStatusWithCount = (emailStatusArray, text) => {
    const count = emailStatusArray.reduce((emailStatusCount, emailStatus) => emailStatusCount + emailStatus.Count, 0);
    return [{ Value: text, Count: count }];
  };

  const sentEmails = getEmailStatusWithCount(sentEmailsTypeArray, 'Sent');
  const openedEmails = getEmailStatusWithCount(openedEmailsTypeArray, 'Opened');
  const sortedEmailStats = [...sentEmails, ...filteredEmailStats, ...openedEmails].sort((a, b) => a.Value - b.Value);

  return (
    <>
      {sortedEmailStats.map(emailStatus => {
        const emailStatsTitle = emailStatsTitleForSegments[emailStatus.Value];
        const emailStatsValue = emailStatusValueForSegmemnts[emailStatus.Value];
        const emailStatsCount = emailStatus?.Count;
        return emailStatus?.Count > 0 ? (
          <div key={emailStatus.Value}>
            <Tooltip title={emailStatsTooltipTexts[emailStatus?.Value]}>
              <Checkbox style={{ marginBottom: 16 }} value={emailStatsValue}>
                {emailStatsTitle} ({emailStatsCount})
              </Checkbox>
            </Tooltip>
          </div>
        ) : null;
      })}
    </>
  );
}

export default SegmentEmailStatsFilter;
