import _ from 'lodash';
import * as dripTemplatesApi from '../Repository/DripTemplates';
import { fetchConnectUsers } from './UserActions';
import { setDripTemplateDetails, setDripTemplateFetchApiStatus } from './ActionCreators/DripTemplatesActionCreator';

function getDripTemplates(from, size) {
  return dispatch => {
    dripTemplatesApi.getDripTemplates(from, size).then(response => {
      dispatch({
        type: 'SET_DRIP_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch({
        type: 'SET_DRIP_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function clearDripTemplates() {
  return dispatch => {
    dispatch({ type: 'CLEAR_DRIP_TEMPLATES' });
  };
}

function searchDripTemplates(filter) {
  return dispatch => {
    dripTemplatesApi.searchDripTemplates(filter).then(response => {
      dispatch({
        type: 'SET_DRIP_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
        })
      );
      dispatch({
        type: 'SET_DRIP_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function deleteDripTemplate(templateId, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Deleting drip template',
      },
    });
    dripTemplatesApi.deleteDripTemplate(templateId).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Drip template deleted',
        },
      });
    });
  };
}

function editDripTemplate(templateId, template, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Updating drip template',
      },
    });
    dripTemplatesApi.putDripTemplate(templateId, template).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Drip template updated',
        },
      });
    });
  };
}

function duplicateDripTemplate(templateId, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Duplicating drip template',
      },
    });
    dripTemplatesApi.duplicateDripTemplate(templateId).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Drip template duplicated',
        },
      });
    });
  };
}

function postDripTemplate(template, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Creating drip template',
      },
    });
    dripTemplatesApi.postDripTemplate(template).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Drip template created',
        },
      });
    });
  };
}

function shareDripTemplate(templateId, shareOptions) {
  let successMessage = 'Template Shared Successfully';
  let loadingMessage = 'Sharing Template';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'Sharing Template With All';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'Template Un-shared Successfully';
    loadingMessage = 'Un-sharing Template With All';
  }
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: loadingMessage,
      },
    });
    dripTemplatesApi
      .shareDripTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_DRIP_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: successMessage,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'FAILURE',
            Message: 'Template sharing Failed',
          },
        });
      });
  };
}

function getDripTemplate(templateId) {
  return async dispatch => {
    dispatch(setDripTemplateFetchApiStatus('INPROGRESS'));
    try {
      const response = await dripTemplatesApi.getDripTemplate(templateId);
      dispatch(setDripTemplateDetails({ templateId, template: response.data }));
      dispatch(setDripTemplateFetchApiStatus('COMPLETED'));
    } catch {
      dispatch(setDripTemplateFetchApiStatus('FAILED'));
    }
  };
}

export {
  getDripTemplates,
  searchDripTemplates,
  postDripTemplate,
  editDripTemplate,
  deleteDripTemplate,
  duplicateDripTemplate,
  getDripTemplate,
  shareDripTemplate,
  clearDripTemplates,
};
