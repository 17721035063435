export const LikelyToSwitchOptions = [
  {
    displayText: 'Any',
    value: null,
  },
  {
    displayText: 'Most Likely To Switch',
    value: 'MostLikely',
  },
  {
    displayText: 'Possibly Willing To Switch',
    value: 'PossiblyWilling',
  },
  {
    displayText: 'Unlikely To Switch',
    value: 'Unlikely',
  },
  {
    displayText: 'Willing To Switch Unknown',
    value: 'Unknown',
  },
];

export const TravelPreferenceOptions = [
  {
    displayText: 'Any',
    value: null,
  },
  {
    displayText: 'Up To 25%',
    value: '25',
  },
  {
    displayText: 'Up To 50%',
    value: '50',
  },
  {
    displayText: 'Up To 75%',
    value: '75',
  },
  {
    displayText: 'Up To 100%',
    value: '100',
  },
  {
    displayText: 'Prefers No Travel',
    value: '0',
  },
];

export const Languages = [
  'English',
  'Spanish',
  'French',
  'German',
  'Russian',
  'Chinese',
  'Italian',
  'Hindi',
  'Portuguese',
  'Japanese',
  'Arabic',
];

export const SocialProfiles = ['Github', 'Stackoverflow', 'Twitter', 'Facebook', 'LinkedIn', 'Meetup'];

export const WorkAuthorizations = [
  {
    displayText: 'US Citizen',
    value: 'US Citizenship',
  },
  {
    displayText: 'Employment Auth. Document',
    value: 'Employment Auth Document',
  },
  {
    displayText: 'Green Card Holder',
    value: 'Green Card',
  },
  {
    displayText: 'Have H1 Visa',
    value: 'Have H1',
  },
  {
    displayText: 'Need H1 Visa',
    value: 'Need H1',
  },
  {
    displayText: 'Canadian Citizen',
    value: 'Canadian Citizen',
  },
  {
    displayText: 'TN Permit Holder',
    value: 'Tn Permit Holder',
  },
];

export const AuthorizedCountries = [
  {
    displayText: 'USA',
    value: 'US',
  },
  {
    displayText: 'Canada',
    value: 'CA',
  },
];

export const JobTypes = [
  'Full-Time',
  'Contract To Hire - W2',
  'Contract - W2',
  'Contract - Independent',
  'Contract To Hire - Independent',
  'Contract - Corp-To-Corp',
  'Contract To Hire - Corp-To-Corp',
  'Part-Time',
  'Internship',
];

export const Currencies = ['USD', 'GBP', 'CAD', 'EUR'];
