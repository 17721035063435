import React from 'react';
import { Icon } from 'antd';

export const SkillSvg = props => (
  <svg width={44} height={44} {...props}>
    <defs>
      <path id="a" d="M0 26h26V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={21} stroke="#979797" strokeWidth={2} />
      <path fill="#757575" d="M18.75 31.75v2.167h2.167V35h2.166v-1.083h2.167V31.75z" />
      <g transform="translate(9 9)">
        <path
          d="M14.805 18.101a1.084 1.084 0 0 0-.722 1.02v.379h-2.166v-.378c0-.458-.29-.868-.722-1.02A5.425 5.425 0 0 1 7.583 13 5.423 5.423 0 0 1 13 7.583 5.423 5.423 0 0 1 18.417 13a5.425 5.425 0 0 1-3.612 5.101M13 5.417c-4.182 0-7.583 3.401-7.583 7.583 0 2.94 1.712 5.6 4.333 6.846v.737c0 .598.485 1.084 1.083 1.084h4.334c.598 0 1.083-.486 1.083-1.084v-.737A7.596 7.596 0 0 0 20.583 13c0-4.182-3.401-7.583-7.583-7.583"
          fill="#757575"
        />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#757575"
          mask="url(#b)"
          d="M11.917 4.333h2.166V0h-2.166zM0 14.083h4.333v-2.166H0zm21.667 0H26v-2.166h-4.333zm1.849-10.067-1.532-1.532-3.25 3.25 1.532 1.532zM7.266 5.734l-3.25-3.25-1.532 1.532 3.25 3.25z"
        />
      </g>
    </g>
  </svg>
);

const SkillsIcon = props => <Icon component={SkillSvg} {...props} />;

export default SkillsIcon;
