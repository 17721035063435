import React from 'react';
import { Skeleton } from 'antd';

function LoadingScreen(props) {
  const { loading, active, children } = props;
  return (
    <div id="body-content-loading">
      <Skeleton loading={loading} active={active} paragraph={{ rows: 16, width: '80' }}>
        {children}
      </Skeleton>
    </div>
  );
}

export default LoadingScreen;
