import _ from 'lodash';
import * as orderRepository from '../Repository/OrderRepository';
// eslint-disable-next-line import/no-cycle
import * as jobRepository from '../Repository/JobRepository';
import * as aryaPayRepository from '../Repository/AryaPayRepository';
import { fetchUsers } from './UserActions';
import { setCancelOrderApiStatus, setUpdateOrderApiStatus } from './ActionCreators/OrderActionCreator';

const setFetchOrdersApiStatus = status => ({
  type: 'SET_FETCH_ORDERS_API_STATUS',
  payload: status,
});

export function fetchOrders(filter) {
  return async dispatch => {
    const response = await aryaPayRepository.getOrders(filter);
    dispatch({
      type: 'SET_ORDERS',
      payload: response.data,
    });
    return response.data;
  };
}

export function fetchOrderDetails(jobId, orderId) {
  return async dispatch => {
    const response = await aryaPayRepository.getOrderDetails(orderId);
    dispatch({
      type: 'SET_ORDER_DETAILS',
      payload: {
        orderDetails: response.data,
        jobId,
      },
    });
    return response.data;
  };
}

export function fetchOrdersHistory(filter) {
  return async dispatch => {
    try {
      dispatch(setFetchOrdersApiStatus('PENDING'));
      const { Items: orders } = await dispatch(fetchOrders(filter));
      const jobGuids = [...new Set(orders.map(order => _.get(order, ['Items', 0, 'RefIds', 0])))];
      const count = jobGuids.length;
      const userIds = orders.map(order => order.CreatedBy);
      if (userIds.length) {
        const uniqueUserIds = [...new Set(userIds)];
        dispatch(
          fetchUsers({
            userGuids: uniqueUserIds,
          })
        );
      }
      jobRepository
        .fetchJobList({
          jobGuids,
          count,
        })
        .then(response => {
          const jobGuidsToJobIdMapping = {};
          const jobs = _.get(response, ['data', 'Jobs'], []);
          jobs.forEach(job => {
            jobGuidsToJobIdMapping[job.JobGuid] = job.JobId;
          });
          dispatch({
            type: 'SET_JOB_LIST',
            payload: {
              jobs: response.data.Jobs,
            },
          });
          dispatch({
            type: 'SET_JOB_GUID_TO_ID_MAPPING',
            payload: jobGuidsToJobIdMapping,
          });
          dispatch(setFetchOrdersApiStatus('SUCCESS'));
        });
    } catch (ex) {
      dispatch(setFetchOrdersApiStatus('FAILED'));
      throw ex;
    }
  };
}

export function cancelOrder(jobId, orderId, orderDetails) {
  return dispatch => {
    dispatch(setCancelOrderApiStatus(jobId, { status: 'INPROGRESS' }));
    aryaPayRepository
      .cancelOrder(orderId, orderDetails)
      .then(() => {
        dispatch(setCancelOrderApiStatus(jobId, { status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = { status: 'FAILED', error: _.get(err, 'response') };
        dispatch(setCancelOrderApiStatus(jobId, status));
      });
  };
}

export function clearCancelOrderApiStatus(jobId) {
  return dispatch => {
    dispatch(setCancelOrderApiStatus(jobId, {}));
  };
}

export function updateOrderStatus(jobId, orderId, orderDetails) {
  return dispatch => {
    dispatch(setUpdateOrderApiStatus(jobId, { status: 'INPROGRESS' }));
    orderRepository
      .updateOrder(orderId, orderDetails)
      .then(() => {
        dispatch({
          type: 'SET_JOB_ORDER_STATUS',
          payload: { jobId, status: orderDetails.Status },
        });
        dispatch(setUpdateOrderApiStatus(jobId, { status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = { status: 'FAILED', error: _.get(err, 'response') };
        const statusCode = _.get(err, ['response', 'status'], null);
        if (statusCode === 406) {
          dispatch({
            type: 'SET_JOB_ORDER_STATUS',
            payload: { jobId, status: 'Cancelled' },
          });
        } else {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Oops, something just went wrong. Please try again.',
            },
          });
        }
        dispatch(setUpdateOrderApiStatus(jobId, status));
      });
  };
}

export function fetchJobsOrderDetails(jobIds) {
  return async dispatch => {
    const response = await orderRepository.fetchOrders(jobIds);
    dispatch({
      type: 'SET_JOB_ORDER_DETAILS',
      payload: response.data,
    });
    return response.data;
  };
}
