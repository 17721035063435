import React from 'react';
import { Icon } from 'antd';

function ChatBubbleBlock(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <g fill="currentColor">
        <path d="M18.906 13.996c.054.329.092.662.092 1.004 0 3.86-3.589 7-8 7H3.291l1.328-1.43a1 1 0 00.021-1.338C3.566 18 2.998 16.537 2.998 15c0-3.563 3.06-6.506 7-6.939V6.046c-5.046.452-9 4.296-9 8.954 0 1.735.541 3.392 1.573 4.836L.265 22.32A.999.999 0 00.998 24h10c5.514 0 10-4.037 10-9 0-.34-.025-.674-.066-1.005l-2.026.001z"></path>
        <path d="M17.998 0c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 2c.739 0 1.424.215 2.019.566l-5.452 5.452A3.944 3.944 0 0113.998 6c0-2.205 1.794-4 4-4zm0 8a3.959 3.959 0 01-2.019-.566l5.452-5.453c.352.596.567 1.28.567 2.02a4.004 4.004 0 01-4 3.999z"></path>
      </g>
    </svg>
  );
}

const ChatBubbleBlockIcon = props => <Icon component={ChatBubbleBlock} {...props} />;

export default ChatBubbleBlockIcon;
