import React from 'react';
import { Icon } from 'antd';

export const StarSvg = props => {
  const { fillColor, strokeColor } = props;
  return (
    <svg width="1em" height="1em" {...props}>
      <path
        d="M2.902 10.966c.005-.041.012-.097.021-.152.167-.97.333-1.94.504-2.91.02-.113 0-.188-.085-.271-.715-.69-1.425-1.383-2.136-2.076-.218-.213-.263-.442-.135-.677.09-.165.239-.238.42-.264.996-.142 1.99-.289 2.987-.43.1-.013.157-.052.202-.145.442-.904.887-1.805 1.333-2.707.12-.244.312-.357.546-.33.21.025.34.148.43.331.398.81.815 1.61 1.189 2.431.14.31.32.434.654.473.904.104 1.803.25 2.704.383.353.052.552.373.426.695a.788.788 0 0 1-.188.258c-.697.683-1.395 1.365-2.097 2.041-.094.09-.124.173-.101.303.174.978.341 1.956.508 2.935.059.345-.123.623-.445.648a.752.752 0 0 1-.38-.092c-.881-.456-1.759-.921-2.635-1.388-.09-.048-.158-.047-.248.001-.88.469-1.762.933-2.645 1.397-.26.137-.468.12-.66-.047-.12-.105-.16-.24-.17-.407Z"
        stroke={strokeColor}
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

const StarIcon = props => <Icon component={() => StarSvg(props)} {...props} />;

export default StarIcon;
