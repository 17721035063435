import { closeSocketConnection } from '../Events/SocketConnectionManager';

export default function signOut() {
  return dispatch => {
    dispatch({
      type: 'USER_LOGOUT',
    });
    closeSocketConnection();
  };
}
