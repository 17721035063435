import React from 'react';
import { Input, Card, Checkbox, List, Tooltip, Icon, ConfigProvider } from 'antd';
import styles from './GlobalInsightPanel.module.scss';

const { Search } = Input;
export default function GlobalInsightPanel(props) {
  const {
    form,
    placeHolder,
    title,
    searchKeyWord,
    onHandleAddButtonClick,
    initialValue,
    dataSource,
    isSearchAllowed,
    isAddAllowed,
    filterName,
    keyParameter,
    value: inputValue = '',
    emptyDescription,
    disabled,
  } = props;
  const isEmptyData = !(dataSource && dataSource.length > 0);
  const emptyDescriptionData = () => <div className={styles.emptyPanel}>{emptyDescription}</div>;
  return (
    <ConfigProvider renderEmpty={isEmptyData && emptyDescriptionData}>
      {isSearchAllowed && isAddAllowed ? (
        <div className={styles.supplySearchInput}>
          {form.getFieldDecorator(searchKeyWord)(
            <Search
              className={`${styles.searchButtonFilter} ${!form.getFieldValue(searchKeyWord) ? styles.disabled : null}`}
              placeholder={placeHolder}
              enterButton={<Icon type="plus" />}
              value={inputValue}
              onSearch={() => onHandleAddButtonClick(keyParameter, searchKeyWord, filterName)}
              disabled={disabled}
            />
          )}
        </div>
      ) : null}
      <Card
        size="small"
        title={title}
        extra="Supply"
        className={`${styles.supplyCardList} ${disabled ? styles.disabled : null}`}
      >
        {form.getFieldDecorator(filterName, {
          initialValue,
        })(
          <Checkbox.Group className={styles.supplyFilterList} disabled={disabled}>
            <List
              className={styles.listBordered}
              bordered
              dataSource={dataSource}
              renderItem={item => (
                <List.Item>
                  <Checkbox value={item.Name}>
                    <Tooltip title={item.DisplayName}>
                      <span className={styles.checkBoxName}>{item.DisplayName}</span>
                    </Tooltip>
                  </Checkbox>
                  <div className={styles.displaySupplyPercentageView}>
                    <span className={`${styles.displaySupplyPercentage} ${disabled ? styles.disabled : null}`}>
                      {item.SupplyPercentage}%
                    </span>
                  </div>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        )}
      </Card>
    </ConfigProvider>
  );
}
