import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { ContactPullIcon } from '../../../Icons/AryaIcons';
import { onFetchContact } from '../../../Actions/ContactFetchActions';
import './FetchContactWidget.scss';
import { logSmartKarrotEvent } from '../../../Analytics/EventUtils';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes, { modules } from '../../../Analytics/EventTypes';

// pass handleContactPull only when contact pull needs to be handled separately
function FetchContactWidget(props) {
  const {
    fetchContactStatus,
    currentJobDetails,
    candidate,
    handleContactPull,
    isDisable,
    contactPullColor = '#83878C',
    onCandidate360TabChange,
  } = props;
  const dispatch = useDispatch();
  let disableCssClass = '';
  const isLoading = fetchContactStatus === 'InProgress';
  if (isLoading || isDisable) {
    disableCssClass = 'disabled';
  }
  const onContactPull = () => {
    if (isDisable) return;
    if (handleContactPull) {
      handleContactPull({ candidate });
    } else {
      dispatch(onFetchContact({ currentJobDetails, candidate }));
    }
    if (onCandidate360TabChange) onCandidate360TabChange('contact');
    logSmartKarrotEvent(
      getEventNameByCandidateStatus(eventTypes.candidate.candidateContactPull.pullContact, candidate),
      { Module: modules.candidate.candidateActions }
    );
  };

  return (
    <div className={`connect-get-contact ${disableCssClass}`} role="presentation" onClick={onContactPull}>
      <span>
        {isLoading ? (
          <Icon type="loading" style={{ fontSize: '20px', marginRight: '10px' }} />
        ) : (
          <ContactPullIcon
            style={{
              fontSize: '20px',
              color: contactPullColor,
              marginRight: '10px',
              opacity: isDisable ? '0.25' : '1',
            }}
          />
        )}
      </span>
      <span>Get Contacts</span>
    </div>
  );
}

export default FetchContactWidget;
