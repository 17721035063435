import React from 'react';
import { FormSelectComponent, FormDropdownInputComponent } from '../../Utils/FormComponentUtils';
import { getDisplayedLocations } from '../../Utils/LocationUtils';
import useZipcodeLocation from '../../Hooks/useZipcodeLocation';
import './ZipcodeLocation.scss';

export default function ZipcodeLocation(props) {
  const {
    locations,
    zipcodes,
    isZipcodeValid,
    locationChange,
    callForZipCodes,
    filterLocations,
    onZipcodeChange,
  } = useZipcodeLocation(props);
  const {
    form,
    zipcodeFieldDecorator,
    locationFieldDecorator,
    locationInitialValue,
    zipcodeInitialValue,
    isZipcodeDisabled,
    isLocationDisabled,
    locationLabel,
    zipcodeLabel,
  } = props;
  const currentLocation = form.getFieldValue(locationFieldDecorator);
  const displayedLocations = getDisplayedLocations({ currentLocation, locationSuggestions: locations });

  return (
    <div className="zipcode-location">
      <FormSelectComponent
        label={locationLabel}
        placeholder="City, State or State"
        fieldDecoratorValue={locationFieldDecorator}
        notFoundContent="Enter a location"
        className="location-select"
        showSearch
        showArrow={false}
        allowClear
        inputValues={displayedLocations}
        initialValue={locationInitialValue}
        onSearch={value => {
          if (value) filterLocations(value);
        }}
        onSelect={value => callForZipCodes(value)}
        onChange={value => locationChange(value)}
        form={form}
        isDisabled={isLocationDisabled}
      />
      <FormDropdownInputComponent
        label={zipcodeLabel}
        placeholder="Postal Code"
        fieldDecoratorValue={zipcodeFieldDecorator}
        errorMessage="Invalid Postal code"
        autoComplete="off"
        dropdownOverlayClassName="zipcode-dropdown"
        className="zipcode-input"
        showSearch
        showArrow={false}
        allowClear
        inputValues={zipcodes}
        initialValue={zipcodeInitialValue}
        validityStatus={isZipcodeValid}
        onChange={value => {
          onZipcodeChange(value);
        }}
        onClick={value => {
          form.setFieldsValue({ [zipcodeFieldDecorator]: value });
          onZipcodeChange(value);
        }}
        form={form}
        isDisabled={isZipcodeDisabled}
      />
    </div>
  );
}
