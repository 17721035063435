import React from 'react';
import SourcesContext from './SourceContext';
import styles from './SourceItemName.module.scss';

const SourceItemName = () => {
  const { sourceDisplayName } = React.useContext(SourcesContext);
  const primarySourceDisplayName = sourceDisplayName.split('_')[0];
  const secondarySourceDisplayName = sourceDisplayName.split('_')[1];
  return (
    <div className={styles.sourceNameWrapper}>
      <span className={styles.sourceNamePrimary}>{primarySourceDisplayName}</span>
      {secondarySourceDisplayName ? (
        <span className={styles.sourceNameSecondary}>{secondarySourceDisplayName}</span>
      ) : null}
    </div>
  );
};

export default SourceItemName;
