import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Icon, Modal, Skeleton, Pagination, Empty, Tooltip } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { EmptyJobsIcon, JobCreditIcon } from '../../Icons/AryaIcons';
import styles from './OrderHistory.module.scss';
import { getProductVariantsByName } from '../../Utils/PlanSelectionUtils';

export const OrderDetailsModal = ({ order, isVisible, setVisibility, userDetails }) => {
  if (!order) {
    return null;
  }
  const time = moment
    .utc(order.createdTime)
    .local()
    .format('MMMM DD, YYYY | h:mm A');
  const debitPayment = _.get(order, 'payments', []).find(
    payment => _.get(payment, 'Type', '').toLowerCase() === 'debit'
  );
  let paymentMethod = 'Arya Credits';
  if (debitPayment) {
    paymentMethod = 'Credit Card';
  }

  const { FullName: fullName, Email: email } = userDetails;
  const { invoiceNumber: orderId } = order;
  const amount = order?.amount || {};
  const { Price = 0, Discount = 0, Tax = 0, Total = 0 } = amount;

  return (
    <Modal
      title={<div className={styles.orderDetailsTitle}>Order Details</div>}
      visible={isVisible}
      footer={null}
      onCancel={() => setVisibility(false)}
    >
      <div className={styles.orderDetails}>
        <div className={styles.orderCreatedTime}>{time}</div>
        <div className={styles.orderDetailsWrapper}>
          <div className={styles.orderDetailsHeadings}>
            <p>Order ID</p>
            <p>Customer</p>
            <p>Amount</p>
            <p>Discount</p>
            <p>Tax</p>
            <p>Order Total</p>
            <p>Email</p>
            <p className={styles.paymentMethod}>Payment Method</p>
          </div>
          <div className={styles.orderDetailsValues}>
            <p className={styles.invoiceNumber}>
              <Tooltip key={orderId} title={orderId}>
                #{orderId}
              </Tooltip>
            </p>
            <p className={styles.textEllipsis}>
              <Tooltip key={fullName} title={fullName}>
                {fullName}
              </Tooltip>
            </p>
            <p>${Price}</p>
            <p>-${Discount}</p>
            <p>${Tax}</p>
            <p>${Total}</p>
            <p className={styles.textEllipsis}>
              <Tooltip key={email} title={email}>
                {email}
              </Tooltip>
            </p>
            <p>{paymentMethod}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default function OrderHistory({
  orders = [],
  jobs = {},
  ordersCount,
  fetchOrdersHistory,
  ordersApiStatus,
  history,
  jobGuidToIdMapping = {},
  usersByGuid = {},
  productVariantesById = {},
}) {
  const [isOrderModalVisbile, setOrderModalVisbilty] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const selectedOrder = orders.find(order => order.id === selectedOrderId);
  const product = getProductVariantsByName(productVariantesById);

  useEffect(() => {
    fetchOrdersHistory({ from: 0, size: 10 });
  }, []);

  function onViewOrderDetails(orderId) {
    setOrderModalVisbilty(!isOrderModalVisbile);
    setSelectedOrderId(orderId);
  }

  function onPageChange(_page, _pageSize) {
    setPageNumber(_page);
    setPageSize(_pageSize);
    fetchOrdersHistory({ from: (_page - 1) * _pageSize, size: _pageSize });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  function onShowSizeChange(_page, _pageSize) {
    setPageNumber(_page);
    setPageSize(_pageSize);
    fetchOrdersHistory({ from: (_page - 1) * _pageSize, size: _pageSize });
  }

  function getJob(orderMetaData) {
    const refId = _.get(orderMetaData, ['items', 0, 'RefIds', 0]);
    if (!refId) return {};
    const jobId = jobGuidToIdMapping[refId];
    return jobs[jobId];
  }

  return (
    <Row>
      <Col span={16} offset={4}>
        <div className={styles.ordersContainer}>
          <h4>Order History</h4>
          <Skeleton active paragraph={{ rows: 10 }} loading={ordersApiStatus === 'PENDING'}>
            {orders.length ? (
              orders.map(orderMetaData => {
                return (
                  <OrderItem
                    orderMetaData={orderMetaData}
                    jobMetaData={getJob(orderMetaData)}
                    key={orderMetaData.id}
                    onViewOrderDetails={() => onViewOrderDetails(orderMetaData.id)}
                    product={product}
                    productVariantesById={productVariantesById}
                  />
                );
              })
            ) : (
              <Empty
                image={<EmptyJobsIcon className={styles.orderHistoryEmpty} />}
                description={
                  <span>
                    <span className={styles.orderHistoryNoOrderFound}> No orders found</span>
                  </span>
                }
              />
            )}
          </Skeleton>
          <div className={styles.paginationContainer}>
            <Pagination
              current={page}
              pageSize={pageSize}
              showSizeChanger
              size="small"
              total={ordersCount || 0}
              onChange={onPageChange}
              onShowSizeChange={onShowSizeChange}
              hideOnSinglePage={ordersCount <= 10}
              disabled={ordersApiStatus === 'PENDING'}
            />
          </div>
          <div className={styles.orderItemContainer}>
            <Row>
              <Col span={18}>
                <div>
                  <Button shape="round" onClick={history.goBack}>
                    Back
                  </Button>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.orderSideContainer}>
                  <Button type="primary" shape="round" onClick={() => history.push('/jobs')}>
                    Job List
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <OrderDetailsModal
            order={selectedOrder}
            userDetails={_.get(usersByGuid, [selectedOrder && selectedOrder.createdBy], {})}
            isVisible={isOrderModalVisbile}
            setVisibility={setOrderModalVisbilty}
          />
        </div>
      </Col>
    </Row>
  );
}

function Location({ children }) {
  return (
    <span style={{ marginLeft: '5px' }}>
      <Icon type="environment" /> {children}
    </span>
  );
}

function GetProductVariantTags(productVariant) {
  let className = '';
  const text = productVariant?.DisplayName;
  switch (productVariant?.Name?.toLowerCase()) {
    case 'basic':
      className = styles.basicOrderType;
      break;
    case 'freetrial':
      className = styles.freeOrderType;
      break;
    default:
      className = styles.premiumOrderType;
  }
  return <div className={className}>{text}</div>;
}

const OrderHeader = ({ title, client, location, isBundleOrder }) => (
  <Row>
    <div className={styles.orderHeader}>
      <div className={styles.jobTitle}>{title}</div>
      {!isBundleOrder ? <div className={styles.verticalLine}>&nbsp;</div> : null}
      <div className={styles.jobClient}>{client}</div>
      {!isBundleOrder ? (
        <div className={styles.jobLocation}>
          <Location>{location}</Location>{' '}
        </div>
      ) : null}
    </div>
  </Row>
);

const OrderInfo = ({ jobId, productVariant, isBundleOrder, basicCredits, premiumCredits, isBasicPlanHidden }) => (
  <div className={styles.orderInfo}>
    {!isBundleOrder ? (
      <>
        <div> {jobId} </div>
        <div className={styles.orderTypeWrapper}> {GetProductVariantTags(productVariant)}</div>
      </>
    ) : (
      <>
        {isBasicPlanHidden && basicCredits > 0 && (
          <div>
            <JobCreditIcon /> {basicCredits} Basic Credits
          </div>
        )}

        <div className={styles.fullServiceCreditWrapper}>
          <JobCreditIcon /> {premiumCredits} Full-Service Credits
        </div>
      </>
    )}
  </div>
);

function GetCreditsCounts(product, items) {
  const basicItem = items.filter(item => item.ProductVariantId === product.Basic.Id);
  const premiumItem = items.filter(item => item.ProductVariantId === product.Premium.Id);

  const basicCreditCount = _.get(basicItem, [0, 'Quantity'], 0);
  const premiumCreditCount = _.get(premiumItem, [0, 'Quantity'], 0);

  return { basicCreditCount, premiumCreditCount };
}

function OrderItem({ orderMetaData, jobMetaData, onViewOrderDetails, product, productVariantesById }) {
  if (!orderMetaData && !jobMetaData) {
    return null;
  }

  const jobTitle = _.get(jobMetaData, ['JobTitle'], 'Multiple Jobs');
  const client = _.get(jobMetaData, ['Client']);
  const jobLocation = _.get(jobMetaData, ['Location']);
  const jobId = _.get(jobMetaData, ['JobId']);
  const items = _.get(orderMetaData, ['items'], []);
  const productVariantId = _.get(items, [0, 'ProductVariantId']);
  const productVariant = _.get(productVariantesById, [productVariantId]);
  const orderType = _.get(orderMetaData, ['type']);
  const creditObject = GetCreditsCounts(product, items);

  const orderInfo = _.get(jobMetaData, 'OrderInfo', {});
  const orderStatus = _.get(orderMetaData, ['status']);

  const isBasicPlanHidden = product?.Basic?.IsHidden;
  let orderStatusText = _.get(orderInfo, 'Status', '');
  if (orderType?.toLowerCase() === 'future' && orderStatus?.toLowerCase() === 'placed') {
    orderStatusText = 'Success';
  }
  let isBundleOrder = false;

  if (_.isEmpty(jobMetaData)) {
    isBundleOrder = true;
  }

  return (
    <div className={styles.orderItemContainer}>
      <Row className={styles.orderItemWrapper}>
        <Col span={18}>
          <div className={styles.orderItem}>
            <OrderHeader title={jobTitle} client={client} location={jobLocation} isBundleOrder={isBundleOrder} />
            <OrderInfo
              jobId={jobId}
              productVariant={productVariant}
              isBundleOrder={isBundleOrder}
              basicCredits={creditObject.basicCreditCount}
              premiumCredits={creditObject.premiumCreditCount}
              isBasicPlanHidden={isBasicPlanHidden}
            />
          </div>
        </Col>
        <Col span={6}>
          <div className={styles.orderSideContainer}>
            <Button type="primary" shape="round" onClick={onViewOrderDetails}>
              View Order Details
            </Button>
            <p>{orderStatusText}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
