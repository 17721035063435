import React from 'react';
import _ from 'lodash';
import { Form, Input, Button, Radio, Icon, Select } from 'antd';
import './MonsterLoginForm.scss';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

const { Group } = Radio;

class MonsterLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.RadioGroupKey = 0;
    this.state = { selectedSearchType: 'Classic' };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
  }

  onRadioButtonChange(event) {
    this.setState({
      selectedSearchType: event.target.value,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { account } = nextProps;
    if (!!account.SearchType && account.SearchType !== prevState.selectedSearchType) {
      return {
        selectedSearchType: account.SearchType,
      };
    }
    return null;
  }

  getButtonGroup(isAuthenticated) {
    this.RadioGroupKey += 1; // generate new RadioGroup Key to update defaultValue
    const { selectedSearchType } = this.state;
    return (
      <div className="button-group">
        <Group
          key={this.RadioGroupKey}
          defaultValue={selectedSearchType}
          onChange={this.onRadioButtonChange}
          disabled={isAuthenticated}
        >
          <Radio value="Classic">Classic</Radio>
          <Radio value="Power">Power</Radio>
        </Group>
      </div>
    );
  }

  handleSubmit(isAuthenticated) {
    const { selectedSearchType } = this.state;
    const { form, authenticate, jobBoard, disconnect, index, clearAccount, addSelectedCountry, removeSelectedCountry } =
      this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const countryCode = values[`${jobBoard}countryCode`];
        if (isAuthenticated) {
          disconnect(jobBoard, countryCode, index, removeSelectedCountry, selectedSearchType);
        } else {
          authenticate(
            jobBoard,
            {
              Username: values[`${jobBoard}userName`],
              Cat: values[`${jobBoard}Cat`],
              SearchType: selectedSearchType,
              CountryCode: countryCode,
            },
            index,
            clearAccount,
            addSelectedCountry
          );
        }
      }
    });
  }

  render() {
    const {
      form,
      jobBoard,
      portalSettings,
      account,
      index,
      Countries,
      countriesMenu,
      credentialsValidator,
      isAuthenticated,
    } = this.props;
    const credentials = account;
    const username = `${jobBoard}userName`;
    const cat = `${jobBoard}Cat`;
    const mandatoryFields = [username, cat];
    const usernameError = form.isFieldTouched(username) && form.getFieldError(username);
    const catError = form.isFieldTouched(cat) && form.getFieldError(cat);
    const errorDetail = getJobBoardErrorDetails(credentials, jobBoard);
    return (
      <div className="portal-login-form">
        <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
          {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
        </div>
        <div className="error-message-with-form">
          <Form>
            <div className="jobboard-credentials-container">
              <div className="jobboard-input-container">
                <Form.Item>
                  <div className="jobboard-country-code">
                    {form.getFieldDecorator(`${jobBoard}countryCode`, {
                      initialValue: credentials.CountryCode || (Countries && Countries[0]),
                    })(
                      <Select
                        className="jobboard-country-code-select"
                        disabled={isAuthenticated || countriesMenu.length < 2}
                      >
                        {countriesMenu}
                      </Select>
                    )}
                  </div>
                </Form.Item>
              </div>
              <div className="jobboard-input-container">
                <Form.Item validateStatus={usernameError ? 'error' : ''} help="">
                  <div className="monster-credentials-container">
                    {form.getFieldDecorator(`${jobBoard}userName`, {
                      initialValue: credentials.Username,
                      rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                    })(
                      <Input
                        placeholder="Username"
                        className="monster-username"
                        autoComplete="false"
                        readOnly={isAuthenticated}
                      />
                    )}
                  </div>
                </Form.Item>
                <Form.Item validateStatus={catError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}Cat`, {
                    initialValue: isAuthenticated ? '********' : undefined,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(
                    <Input
                      placeholder="Cat"
                      className="monster-cat"
                      type="password"
                      autoComplete="new-password"
                      readOnly={isAuthenticated}
                    />
                  )}
                  {this.getButtonGroup(isAuthenticated)}
                </Form.Item>
              </div>
              <Form.Item>
                <div className="btn-container">
                  <Button
                    // type="primary"
                    htmlType="submit"
                    loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
                    onClick={() => this.handleSubmit(isAuthenticated)}
                    className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
                    disabled={
                      credentialsValidator.hasError(form.getFieldsError()) ||
                      credentialsValidator.anyFieldEmpty(form, mandatoryFields)
                    }
                    shape="round"
                    block
                  >
                    {isAuthenticated ? 'Disconnect' : 'Authenticate'}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
          <div className="error-message">{errorDetail.Message}</div>
        </div>
      </div>
    );
  }
}

export default Form.create()(MonsterLoginForm);
