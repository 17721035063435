import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserAlerts from '../../Components/UserAlerts/UserAlerts';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getFeatureLockedNotification } from '../../Reducers/AppNotificationReducer';
import * as appNotificationActions from '../../Actions/AppNotificationActions';
import { appFeatureNotificationContentMapper } from './AppFeatureNotificationContentMapper';
import './AppFeatureLockedNotification.scss';

const mapStateToProps = state => ({
  featureLockedNotification: getFeatureLockedNotification(state),
  userConfig: getConfig(state),
});

const mapDispatchToProps = {
  setFeatureLockedNotificationAlert: appNotificationActions.setFeatureLockedNotificationAlert,
};

class AppFeatureLockedNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setFeatureLockedAlertVisibility() {
    this.setState(
      {
        featureLockedAlertVisibility: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            featureLockedAlertVisibility: false,
          });
        }, 5000);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { featureLockedAlertVisibility: prevFeatureLockedAlertVisibility } = prevState;
    const { featureLockedAlertVisibility: currentFeatureLockedAlertVisibility } = this.state;
    const { featureLockedNotification: prevFeatureLockedNotification } = prevProps;
    const {
      setFeatureLockedNotificationAlert,
      featureLockedNotification: currentFeatureLockedNotification,
    } = this.props;
    const autoClose = currentFeatureLockedNotification?.autoClose;
    if (prevFeatureLockedAlertVisibility && !currentFeatureLockedAlertVisibility)
      setFeatureLockedNotificationAlert(undefined);
    if (autoClose && !prevFeatureLockedNotification && currentFeatureLockedNotification)
      this.setFeatureLockedAlertVisibility();
  }

  render() {
    const { featureLockedNotification } = this.props;
    if (!featureLockedNotification) return null;
    const { type } = featureLockedNotification;
    const { content, header } = appFeatureNotificationContentMapper({ type });
    return (
      <div className="userAlertWrapper">
        <UserAlerts header={header} content={content} />
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppFeatureLockedNotificationContainer));
export { AppFeatureLockedNotificationContainer as AppFeatureLockedNotificationAlertWithoutStore };
