import _ from 'lodash';
import { getConfig } from '../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import {
  getPlanDetails,
  getProductVariants,
  getTotalAmountForProductvariant,
} from '../Repository/AryaPayOrderRepository';
import * as AryaPayOrderActionCreators from './ActionCreators/AryaPayOrderActionCreator';

export function fetchProductVariantsForProduct(productName = 'Job') {
  return async dispatch => {
    try {
      dispatch(AryaPayOrderActionCreators.setFetchProductVariantsApiStatus('INPROGRESS'));
      const response = await getProductVariants(productName);
      dispatch(AryaPayOrderActionCreators.setProductVariants(response.data));
      dispatch(AryaPayOrderActionCreators.setFetchProductVariantsApiStatus('COMPLETED'));
      return response.data;
    } catch (ex) {
      dispatch(AryaPayOrderActionCreators.setFetchProductVariantsApiStatus('FAILED'));
    }
    return null;
  };
}

export function fetchProductVariants() {
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const userConfig = getConfig(store);
    if (isPaidJobServiceEnabled || userConfig.Role === 'Service Rep') {
      await dispatch(fetchProductVariantsForProduct());
    }
  };
}

export function fetchPlanDetailsForProductVariantIds(productvariantIds) {
  return async dispatch => {
    try {
      dispatch(AryaPayOrderActionCreators.setFetchPlanDetailsByProductVariantIdApiStatus('INPROGRESS'));
      const response = await getPlanDetails({
        ProductVariantIds: productvariantIds,
      });
      dispatch(AryaPayOrderActionCreators.setPlanDetailsByProductVariantId(response.data));
      dispatch(AryaPayOrderActionCreators.setFetchPlanDetailsByProductVariantIdApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(AryaPayOrderActionCreators.setFetchPlanDetailsByProductVariantIdApiStatus('FAILED'));
    }
  };
}

export function fetchPlanDetailsByProductVariantId(productvariantIds) {
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    if (isPaidJobServiceEnabled) {
      await dispatch(fetchPlanDetailsForProductVariantIds(productvariantIds));
    }
  };
}

export function fetchTotalAmount(filter) {
  return async dispatch => {
    try {
      if (filter.IsTaxCalcuationRequired) {
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithTaxApiStatus('INPROGRESS'));
      } else {
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithoutTaxApiStatus('INPROGRESS'));
      }
      const response = await getTotalAmountForProductvariant(filter);
      if (filter.IsTaxCalcuationRequired) {
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantsWithTax(response.data));
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithTaxApiStatus('COMPLETED'));
      } else {
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantsWithoutTax(response.data));
        dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithoutTaxApiStatus('COMPLETED'));
      }
      return response.data.Amount;
    } catch (error) {
      const statusType = _.get(error, ['response', 'data', 'ErrorCode'], 'FAILED');
      dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithTaxApiStatus(statusType));
    }
    return null;
  };
}

export function fetchTotalAmountForProductvariants(filter) {
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    if (isPaidJobServiceEnabled) {
      await dispatch(fetchTotalAmount(filter));
    }
    return null;
  };
}

export function resetGetOrderAmountApiStatus() {
  return dispatch => {
    dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithoutTaxApiStatus(undefined));
    dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantWithTaxApiStatus(undefined));
  };
}
export function resetTotalAmountForProductvariant() {
  return dispatch => dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariant(undefined));
}

export function resetOrderDetails() {
  return dispatch => dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantsWithTax(undefined));
}
export function resetOrderDetailsWithoutTax() {
  return dispatch => dispatch(AryaPayOrderActionCreators.setTotalAmountForProductvariantsWithoutTax(undefined));
}
