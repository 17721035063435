import React from 'react';
import { Icon } from 'antd';

const ParsingFailedSvg = props => {
  return (
    <svg width={22} height={18} viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter colorInterpolationFilters="auto" id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.980392 0 0 0 0 0.678431 0 0 0 0 0.078431 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-158 -504)" filter="url(#prefix__a)" fill="none" fillRule="evenodd">
        <g fill="#757575">
          <path d="M166.387 506.805c-1.157 0-2.097.94-2.097 2.096 0 1.157.94 2.097 2.097 2.097a2.098 2.098 0 000-4.193m0 6.29a4.197 4.197 0 01-4.194-4.194 4.197 4.197 0 014.194-4.193 4.197 4.197 0 014.193 4.193 4.197 4.197 0 01-4.193 4.194M160.263 519.384h12.247c-.626-1.895-2.97-3.144-6.124-3.144-3.153 0-5.496 1.25-6.123 3.144zm14.51 2.097H158v-1.048c0-3.704 3.449-6.29 8.386-6.29 4.939 0 8.387 2.586 8.387 6.29v1.048zM179.707 505.482L178.225 504l-2.404 2.404-2.403-2.404-1.483 1.482 2.404 2.404-2.404 2.404 1.483 1.482 2.403-2.404 2.404 2.404 1.482-1.482-2.403-2.404z" />
        </g>
      </g>
    </svg>
  );
};

const ParsingFailedIcon = props => <Icon component={ParsingFailedSvg} {...props} />;

export default ParsingFailedIcon;
