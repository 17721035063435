import React from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { Form, Button, InputNumber } from 'antd';
import _ from 'lodash';
import {
  getProductVariantsByName,
  BASIC,
  PREMIUM,
  INPUT_VALIDATION_ERROR,
  TOTAL,
  AMOUNT,
  DISCOUNT,
  PRICE,
  QUANTITY_INPUT_LABEL,
} from '../../Utils/PlanSelectionUtils';
import { roundOffNumber } from '../../Utils/MathUtils';
import styles from './SelectJobCredit.module.scss';
import { isValidNaturalNumber } from '../../Utils/Validators';

export const getFormattedAmount = value => {
  return value ? `$${value}` : '';
};

const MAXIMUM_CREDIT_PURCHASE_COUNT = 100;
const ProductPurchaseCountInput = ({ count, productDetails, onChange, validateStatus, total, maxUnits }) => {
  const discountPercentage = (productDetails.discount / productDetails.price) * 100;

  const offerTagStyle = discountPercentage === 20 ? styles.offerTag20Percent : styles.offerTag10Percent;
  const currencySignedAmount = getFormattedAmount(total);
  return (
    <div className={styles.bodyContent}>
      <div className={styles.innerBody}>
        <div className={styles.bundleInput}>
          <Form.Item
            validateStatus={validateStatus ? 'error' : 'success'}
            help={validateStatus}
            className={styles.jobsCount}
          >
            <InputNumber
              className={styles.inputCount}
              min={0}
              defaultValue={count}
              value={count}
              max={maxUnits}
              onChange={onChange}
            />
          </Form.Item>
        </div>
        {discountPercentage ? (
          <div className={styles.discountContent}>
            <div className={`${styles.offerTag} ${offerTagStyle}`}>
              <p>{discountPercentage}% off</p>
            </div>
          </div>
        ) : (
          <div className={styles.offerText}>{QUANTITY_INPUT_LABEL}</div>
        )}
      </div>
      {discountPercentage ? (
        <div className={styles.amount}>
          <p className={styles.actualAmount}>${total}</p>
        </div>
      ) : (
        <div className={styles.nonDiscountContent}>
          <p>{currencySignedAmount}</p>
        </div>
      )}
    </div>
  );
};

export const getPlanAmountDetails = (productVariantName, productAmountDetails, type, basicCount, premiumCount) => {
  const planQuantityMapping = {
    Premium: premiumCount,
    Basic: basicCount,
  };
  const totalQuantity = basicCount + premiumCount;
  return planQuantityMapping[productVariantName] && totalQuantity <= MAXIMUM_CREDIT_PURCHASE_COUNT
    ? productAmountDetails[type]
    : 0;
};

const mapProductDetails = (productVariantsByName, productItemsDetailsById, basicCount, premiumCount) => {
  const productDetails = {};
  Object.keys(productVariantsByName).forEach(productVariantName => {
    const productVariantId = productVariantsByName?.[productVariantName]?.Id;
    const productAmountDetails = _.get(productItemsDetailsById, [productVariantId, AMOUNT], {});
    productDetails[productVariantName] = {
      id: productVariantId,
      price: productAmountDetails[PRICE] ?? 0,
      discount: getPlanAmountDetails(productVariantName, productAmountDetails, DISCOUNT, basicCount, premiumCount),
      totalAmount: getPlanAmountDetails(productVariantName, productAmountDetails, TOTAL, basicCount, premiumCount),
    };
  });
  return productDetails;
};

export const getTotalForProductsAmount = (basicProductDetails, premiumProductDetails, basicCount, premiumCount) => {
  let totalAmount = 0;
  if (basicCount) {
    totalAmount += basicProductDetails?.totalAmount;
  }
  if (premiumCount) {
    totalAmount += premiumProductDetails?.totalAmount;
  }
  return roundOffNumber(totalAmount);
};

function SelectJobCredit(props) {
  const {
    productVariantsById = {},
    orderDetails: productDetails,
    history,
    resetOrderDetailsWithoutTax,
    fetchTotalAmountForProductvariants,
    basicQuantity,
    premiumQuantity,
    isBasicPlanHidden,
  } = props;
  const [basicCount, setBasicCount] = React.useState(0);
  const [premiumCount, setPremiumCount] = React.useState(0);
  const [basicPlanInputValidationError, setBasicPlanInputValidationError] = React.useState('');
  const [premiumPlanInputValidationError, setPremiumPlanInputValidationError] = React.useState('');
  const productVariant = getProductVariantsByName(productVariantsById);
  const fetchTotalAmountForProductvariantsWithDelay = debounce(fetchTotalAmountForProductvariants, 500);
  const fetchOrderDetails = () => {
    const items = [];
    if (basicCount) {
      items.push({
        productvariantId: productVariant.Basic.Id,
        quantity: basicCount,
      });
    }
    if (premiumCount) {
      items.push({
        productvariantId: productVariant.Premium.Id,
        quantity: premiumCount,
      });
    }
    if (items.length) {
      fetchTotalAmountForProductvariantsWithDelay({
        items,
        IsTaxCalcuationRequired: false,
      });
    } else {
      resetOrderDetailsWithoutTax();
    }
  };

  React.useEffect(() => {
    if (basicQuantity || premiumQuantity) {
      setBasicCount(basicQuantity);
      setPremiumCount(premiumQuantity);
    }
  }, []);

  React.useEffect(() => {
    fetchOrderDetails();
  }, [basicCount, premiumCount]);

  const validateFieldValue = (selectedPlanCount, unselectedPlancount, type) => {
    if (
      selectedPlanCount + unselectedPlancount > MAXIMUM_CREDIT_PURCHASE_COUNT ||
      !isValidNaturalNumber(selectedPlanCount)
    ) {
      if (type === BASIC) {
        setBasicCount(selectedPlanCount);
        setBasicPlanInputValidationError(INPUT_VALIDATION_ERROR);
        resetOrderDetailsWithoutTax();
      } else {
        setPremiumCount(selectedPlanCount);
        setPremiumPlanInputValidationError(INPUT_VALIDATION_ERROR);
        resetOrderDetailsWithoutTax();
      }
      return false;
    }
    return true;
  };
  const onBasicCountChange = newBasicCreditsCount => {
    if (validateFieldValue(newBasicCreditsCount, premiumCount, BASIC)) {
      setBasicCount(newBasicCreditsCount);
      setBasicPlanInputValidationError('');
    }
  };
  const onPremiumCountChange = newPremiumCreditsCount => {
    if (validateFieldValue(newPremiumCreditsCount, basicCount, PREMIUM)) {
      setPremiumCount(newPremiumCreditsCount);
      setPremiumPlanInputValidationError('');
    }
  };

  const productVariantsByName = getProductVariantsByName(productVariantsById);
  const productItemsById = productDetails?.orderItemById;

  const { Basic: basicProductDetails = {}, Premium: premiumProductDetails = {} } = mapProductDetails(
    productVariantsByName,
    productItemsById,
    basicCount,
    premiumCount
  );
  const grandTotalAmount = getTotalForProductsAmount(
    basicProductDetails,
    premiumProductDetails,
    basicCount,
    premiumCount
  );
  const currencySignedGrandTotalAmount = getFormattedAmount(grandTotalAmount);

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.containerWithoutBasicPlan]: isBasicPlanHidden,
        })}
      >
        {!isBasicPlanHidden ? (
          <>
            <ProductPurchaseCountInput
              productDetails={basicProductDetails}
              onChange={onBasicCountChange}
              validateStatus={basicPlanInputValidationError}
              count={basicCount}
              total={basicProductDetails.totalAmount}
              maxUnits={MAXIMUM_CREDIT_PURCHASE_COUNT - premiumCount}
            />
            <div className={styles.border}></div>
          </>
        ) : null}
        <ProductPurchaseCountInput
          productDetails={premiumProductDetails}
          onChange={onPremiumCountChange}
          validateStatus={premiumPlanInputValidationError}
          count={premiumCount}
          total={premiumProductDetails.totalAmount}
          maxUnits={MAXIMUM_CREDIT_PURCHASE_COUNT - basicCount}
        />
      </div>
      <div
        className={classNames(styles.selectJobCreditTotalOption, {
          [styles.selectJobCreditTotalOptionWithoutBasicPlan]: isBasicPlanHidden,
        })}
      >
        <div className={styles.grandTotal}>GRAND TOTAL: {currencySignedGrandTotalAmount}</div>
        <div className={styles.selectCreditButtons}>
          <Button type="primary" shape="round" onClick={() => history.push(`/jobs`)} className={styles.cancelButton}>
            {' '}
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() =>
              history.push({
                pathname: '/payment',
                search: `?premium=${premiumCount}&basic=${basicCount}&isBuyCredit=${true}`,
              })
            }
            disabled={!(basicCount || premiumCount) || premiumPlanInputValidationError || basicPlanInputValidationError}
            className={styles.nextButton}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
export default SelectJobCredit;
