import React, { Component } from 'react';
import { Modal, Form } from 'antd';
import _ from 'lodash';
import CreditCardForm from './CreditCardForm';
import { getStringWithoutWhiteSpaces } from '../../Utils/TextUtils';
import PaymentErrorModal from './PaymentErrorModal';
import './ModalCardForm.scss';

class ModalCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOk = () => {
    const { form, savePaymentDetails, handleCancel, fetchPaymentProfile, showNotification = false } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        let cardNumber = _.get(values, 'Number', '');
        cardNumber = getStringWithoutWhiteSpaces(cardNumber);
        const cardDetails = {
          Number: cardNumber,
          ExpiryMonth: Number(values.Date.format('M')),
          ExpiryYear: values.Date.year(),
          CVV: values.Cvv,
        };
        await savePaymentDetails(cardDetails, null, showNotification);
        fetchPaymentProfile();
        handleCancel();
      }
    });
  };

  render() {
    const { creditCardFormDisplay, handleCancel, form, savePaymentProfileApiStatus } = this.props;
    if (savePaymentProfileApiStatus === 'CONFLICTING_CARDDETAIL') {
      return (
        <PaymentErrorModal
          creditCardFormDisplay={creditCardFormDisplay}
          handleCancel={handleCancel}
          icon="duplicateCard"
          errorDescription="The card has already been added. Please try another card."
        />
      );
    }

    return (
      <Modal
        visible={creditCardFormDisplay}
        onOk={this.handleOk}
        okText="Add your card"
        onCancel={handleCancel}
        destroyOnClose
        className="modal-payment-card-form"
        confirmLoading={savePaymentProfileApiStatus === 'PENDING'}
        okButtonProps={{ shape: 'round', className: 'modal-payment-card-ok-btn' }}
        cancelButtonProps={{ shape: 'round' }}
        width={656}
        maskClosable={false}
      >
        <div className="payment-creditcard-modal-form">
          <div className="payment-card-form-header">Add a new card</div>
          <CreditCardForm form={form} creditCardFormDisplay={creditCardFormDisplay} />
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ModalCardForm);
export { ModalCardForm as ModalCardWithoutForm };
