import React from 'react';
import BooleanDisplay from '../../Components/BooleanDisplay/BooleanDisplay';
import MustHavesContext from './MustHavesContext';

export default function MustHavesDisplay() {
  const {
    addMustHave,
    inputVisibilityKey,
    mustHaves,
    setInputVisibilityKey,
    inputValue,
    setInputValue,
    deSelectMustHave,
  } = React.useContext(MustHavesContext);
  return (
    <div>
      {Object.entries(mustHaves).map(mustHave => {
        const [key, value] = mustHave;
        return (
          <BooleanDisplay
            key={key}
            selectedTags={value.selectedSkills}
            currentIndex={key}
            inputVisibility={inputVisibilityKey === key}
            setInputVisibilityKey={setInputVisibilityKey}
            inputValue={inputValue}
            setInputValue={setInputValue}
            selectSkill={addMustHave}
            deSelectSkill={deSelectMustHave}
            isMustHaveSection
          />
        );
      })}
    </div>
  );
}
