import React from 'react';
import { EmptyCandidateIntelIcon } from '../../Icons/AryaIcons';
import './CandidateIntel.scss';

export default function NoCandidateIntel() {
  return (
    <div className="candidate-intel-not-found">
      <div className="empty-candidate-intel-icon">
        <EmptyCandidateIntelIcon />
      </div>
      <h2>No Score Details found</h2>
    </div>
  );
}
