import React from 'react';
import { Tooltip, Tag } from 'antd';

function TagsDisplayComponent(props) {
  const { className, tag } = props;
  const isLongTag = tag.length > 20;
  return (
    <Tag className={className}>
      {isLongTag ? (
        <Tooltip title={tag} key={tag}>
          {`${tag.slice(0, 20)}...`}
        </Tooltip>
      ) : (
        tag
      )}
    </Tag>
  );
}
export default TagsDisplayComponent;
