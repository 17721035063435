import { validateTemplate } from './Validators';

function getEmailTemplateDetails(templateId, emailTemplatesById, dripTemplatesById) {
  const selectedEmailTemplate = emailTemplatesById?.[templateId] ?? {};
  if (selectedEmailTemplate.IsDrip) {
    const selectedDripTemplate = dripTemplatesById?.[templateId];
    if (selectedDripTemplate) {
      const dripTemplateDrops = selectedDripTemplate?.Drops ?? [];
      const firstDripDrop = dripTemplateDrops.find(drop => drop.Order === 0) ?? {};
      const dripTemplateName = selectedDripTemplate.Name;
      return { ...firstDripDrop, Name: dripTemplateName, IsDrip: true };
    }
  }
  return selectedEmailTemplate;
}

function getJoinedDripEmailTemplate({ dripTemplate }) {
  const dripTemplateDrops = dripTemplate?.Drops ?? [];
  const subject = dripTemplateDrops
    ?.map(drop => drop.Subject)
    .filter(x => !!x)
    .join(',');
  const body = dripTemplateDrops
    ?.map(drop => drop.Body)
    .filter(x => !!x)
    .join(',');
  return { Subject: subject, Body: body };
}

function getEmailCompleteTemplateDetails({ templateId, emailTemplatesById, dripTemplatesById }) {
  const selectedEmailTemplate = emailTemplatesById?.[templateId] ?? {};
  if (selectedEmailTemplate.IsDrip) {
    const selectedDripTemplate = dripTemplatesById?.[templateId];
    if (selectedDripTemplate) {
      return getJoinedDripEmailTemplate({ dripTemplate: selectedDripTemplate });
    }
  }
  return selectedEmailTemplate;
}

function getTemplateIdsWithSupportedMergeTags({ templateIds = [], emailTemplatesById, dripTemplatesById, mergeTags }) {
  return templateIds.filter(templateId => {
    const selectedEmailTemplate = getEmailCompleteTemplateDetails({
      templateId,
      emailTemplatesById,
      dripTemplatesById,
    });
    const { Subject, Body } = selectedEmailTemplate;
    const { emailSubjectMergeTags, emailBodyMergeTags } = mergeTags;
    return validateTemplate({
      subject: Subject,
      body: Body,
      subjectMergeTags: emailSubjectMergeTags,
      bodyMergeTags: emailBodyMergeTags,
    });
  });
}

export { getEmailTemplateDetails, getTemplateIdsWithSupportedMergeTags };
