import _ from 'lodash';
import cleanSet from 'clean-set';
import {
  SET_CANDIDATES_FOR_DEDUPLICATION_BY_SOURCE,
  SET_CANDIDATE_GROUPS_BY_SOURCE,
  SET_CANDIDATE_GROUPS_INFO_BY_SOURCE,
} from '../Actions/ActionCreators/CandidateDeDuplicationActionCreators';

const initialState = {
  ByJobId: {},
};

const emptyList = [];

function CandidateDeDuplicationReducer(state = initialState, action) {
  let payload;
  let newState;
  switch (action.type) {
    case SET_CANDIDATES_FOR_DEDUPLICATION_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, candidates, sourceName } = payload;
      const existingCandidatesById = _.get(state, ['ByJobId', jobId, 'BySource', sourceName], emptyList);
      const allCandidates = _.uniqBy([...existingCandidatesById, ...candidates], 'Id');
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', sourceName], allCandidates);
      return newState;
    }
    case SET_CANDIDATE_GROUPS_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, sourceName, candidateGroupInfo } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'CandidateGroups', sourceName], candidateGroupInfo);
      return newState;
    }
    case SET_CANDIDATE_GROUPS_INFO_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, sourceName, storedCandidatesGroupInfo = [] } = payload;
      newState = { ...state };
      storedCandidatesGroupInfo.forEach(candidate => {
        const { candidateId, CandidateGroup } = candidate;
        if (_.get(state, ['ByJobId', jobId, 'BySource', sourceName, candidateId])) {
          const newSelectedCandidate = {
            ...state.ByJobId[jobId].BySource[sourceName][candidateId],
            CandidateGroup,
          };
          newState = cleanSet(newState, ['ByJobId', jobId, 'BySource', sourceName, candidateId], newSelectedCandidate);
        }
      });
      return newState;
    }
    default:
      return state;
  }
}

export const getCandidatesToDeDuplicateBySource = (state, jobId, source) => {
  return state.ByJobId[jobId]?.BySource?.[source] || {};
};
export const getDuplicateCandidatesBySource = (state, jobId, source) => {
  const candidateGroupBySource = state?.ByJobId?.[jobId]?.CandidateGroups ?? {};
  return candidateGroupBySource[source] || {};
};

export { CandidateDeDuplicationReducer };
