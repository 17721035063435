import React from 'react';
import { Radio, Select, Input, Button, Form, Skeleton } from 'antd';
import './ContactSettings.scss';
import ContactProvidersContainer from '../../Containers/ContactProviders/ContactProvidersContainer';
import eventTypes from '../../Analytics/EventTypes';

const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class ContactSettings extends React.Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
    this.isContactTypeChanged = this.isContactTypeChanged.bind(this);
    this.isContactProviderInfoChanged = this.isContactProviderInfoChanged.bind(this);
    this.handleSendRequest = this.handleSendRequest.bind(this);
    this.shouldHideAddCreditsForm = this.shouldHideAddCreditsForm.bind(this);
    this.isErrorInSave = this.isErrorInSave.bind(this);
  }

  onSave() {
    const { form, updateContactType, bulkSubscribeContactProviders } = this.props;
    const contactType = form.getFieldValue('ContactType');
    const checkedProviders = form.getFieldValue('checkedProviders').map((provider, index) => ({
      ProviderId: provider.ProviderId,
      Order: index + 1,
      CredentialType: provider.CredentialType,
    }));

    if (this.isContactTypeChanged()) {
      updateContactType(contactType);
    }

    if (this.isContactProviderInfoChanged()) {
      bulkSubscribeContactProviders(checkedProviders);
    }
  }

  isContactTypeChanged() {
    const { form, contactSettings } = this.props;
    const contactType = form.getFieldValue('ContactType');
    return contactSettings.ContactType !== contactType;
  }

  isContactProviderInfoChanged() {
    const { form, contactSettings } = this.props;
    let isInfoChanged = false;

    const checkedProviders = (form.getFieldValue('checkedProviders') || []).map((provider, index) => ({
      ProviderId: provider.ProviderId,
      Order: index + 1,
      CredentialType: provider.CredentialType,
    }));
    const subscribedProviders = (contactSettings.ServiceProviders || [])
      .filter(provider => provider.IsSubscribed)
      .sort((p1, p2) => p1.Order - p2.Order);

    if (checkedProviders.length === subscribedProviders.length) {
      isInfoChanged = !checkedProviders.every(
        (provider, index) =>
          provider.ProviderId === subscribedProviders[index].Id &&
          provider.CredentialType === subscribedProviders[index].CredentialType
      );
    } else {
      isInfoChanged = true;
    }
    return isInfoChanged;
  }

  isErrorInSave() {
    const { form } = this.props;

    const checkedProviders = (form.getFieldValue('checkedProviders') || []).map((provider, index) => ({
      ProviderId: provider.ProviderId,
      Order: index + 1,
      CredentialType: provider.CredentialType,
      IsAuthenticate: provider.IsAuthenticate,
    }));

    if (checkedProviders.length) {
      const errorData = checkedProviders
        .filter(provider => provider.CredentialType === 'Client')
        .filter(provider => !provider.IsAuthenticate);
      return errorData.length;
    }

    return false;
  }

  isSaveDisabled() {
    if (this.isErrorInSave()) return true;
    return !this.isContactTypeChanged() && !this.isContactProviderInfoChanged();
  }

  handleSendRequest(event) {
    const { requestForCredits, form, AppName } = this.props;
    event.preventDefault();
    form.validateFields(err => {
      if (!err) {
        const requestedCredits = this.getRequestedCredits();
        requestForCredits(requestedCredits, AppName);
      }
    });
  }

  getRequestedCredits = () => {
    const { form } = this.props;
    return form.getFieldValue('Credits') === 'Customize' ? form.getFieldValue('OtherAmount') : form.getFieldValue('Credits');
  };

  isSendRequestButtonDisabled = () => {
    const { form } = this.props;
    const requestedCredits = this.getRequestedCredits();
    return hasErrors(form.getFieldsError()) || !requestedCredits;
  };

  shouldHideAddCreditsForm() {
    const { availableCredits } = this.props;
    if (availableCredits === null || availableCredits === undefined) return true;
    if (typeof availableCredits === 'string') {
      return availableCredits.toLowerCase() === 'unlimited';
    }
    return false;
  }

  validateCredits = (rule, value, callback) => {
    if (!value?.trim()?.length) {
      callback('Please enter credits');
    }
    const regex = /^[1-9]\d*$/;
    if (!regex.test(value)) {
      callback('Please enter an amount greater than zero');
    } else {
      callback();
    }
  };

  render() {
    const {
      form,
      contactSettings,
      availableCredits,
      requestForCreditsApiStatus,
      AppName,
      fetchAvailableCreditsApiStatus,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    let displayAmount = availableCredits;
    if (typeof availableCredits === 'string' && availableCredits.toLowerCase() === 'unlimited') {
      displayAmount = 'Inclusive';
    }
    const subscribedProviders = (contactSettings.ServiceProviders || [])
      .filter(provider => provider.IsSubscribed)
      .sort((p1, p2) => p1.Order - p2.Order)
      .map(provider => ({
        ProviderId: provider.Id,
        Order: provider.Order,
        CredentialType: provider.CredentialType,
        IsAuthenticate: provider?.Configuration?.Others?.user_id || provider?.Configuration?.ApiKey,
      }));
    /* if (aryaVersion === 'Lite') {
      return (
        <>
          <div className="credits-settings">
            <div className="credits-display">
              <div className="credits-display-title">Credits Remaining</div>
              <div className="credits-display-amount">{availableCredits}</div>
            </div>
            <div className={`${this.shouldHideAddCreditsForm() ? 'hide-credits-form' : ''}`}>
              <div className="credits-request">
                <div className="credits-request-title">Request more credits</div>
                {form.getFieldDecorator('Credits')(
                  <Select className="credits-request-amount">
                    <Option value={500}>500</Option>
                    <Option value={1000}>1000</Option>
                    <Option value={2000}>2000</Option>
                    <Option value="Customize">Customize..</Option>
                  </Select>
                )}
              </div>
              {form.getFieldValue('Credits') === 'Customize' ? (
                <div className="other-amount-request">
                  <div className="other-amount-title">Enter other amount</div>
                  {form.getFieldDecorator('OtherAmount')(
                    <Input className="other-amount-input" placeholder="Enter credit amount" />
                  )}
                </div>
              ) : null}
              <Button
                className="send-request-button"
                onClick={() => this.handleSendRequest()}
                loading={requestForCreditsApiStatus === 'INPROGRESS'}
              >
                Send Request
              </Button>
            </div>
            <div className="credits-submit-disclaimer">
              Disclaimer: Access to contact information provider data is available as a subscription for an additional
              fee
            </div>
          </div>
        </>
      );
    }
    if (aryaVersion) {
      */
    return (
      <div>
        <div className="contact-settings-admin">
          <div className="contact-settings-header">
            <div className="contact-settings-title">Candidate Contact Information Provider Settings</div>
            <div className="contact-settings-description">
              {`If mutiple providers are selected, ${AppName} will search each one in the order they are listed below until
              contact information is found.`}
            </div>
          </div>
          <Button
            disabled={this.isSaveDisabled()}
            className="app-save"
            onClick={this.onSave}
            sk-event-name={eventTypes.settings.candidateInfoProviderConfig.saveChanges}
          >
            Save Changes
          </Button>
        </div>
        <div className="contact-settings-container">
          <div className="contact-type-credits-wrapper">
            <div className="contact-type-options">
              <div className="contact-options-heading">Contact information</div>
              {form.getFieldDecorator('ContactType', { initialValue: contactSettings.ContactType })(
                <Radio.Group className="radio-contact-group">
                  <Radio className="radio-contact-option" value="Phone">
                    Phone
                  </Radio>
                  <Radio className="radio-contact-option" value="Email">
                    Email
                  </Radio>
                  <Radio className="radio-contact-option" value="All">
                    Both
                  </Radio>
                </Radio.Group>
              )}
            </div>
            <div className="contact-info-provider-settings">
              <div className="contact-info-provider-settings-title">
                Which contact information look-up partner(s) would you like to use?
              </div>
              <div className="contact-info-provider-settings-title">
                {`If multiple providers are selected,
              ${AppName} will search each one in the order they are listed below until contact information is found`}
              </div>
              <div>
                {form.getFieldDecorator('checkedProviders', {
                  initialValue: subscribedProviders,
                })(<ContactProvidersContainer data={contactSettings.ServiceProviders || []} />)}
              </div>
            </div>
            <div className="credits-settings">
              <div className="credits-display">
                <div className="credits-display-title">Credits Remaining</div>
                <div className="credits-display-amount">
                  <Skeleton
                    active
                    title={false}
                    paragraph={{ rows: 1, width: '64px' }}
                    loading={fetchAvailableCreditsApiStatus !== 'COMPLETED'}
                  >
                    {displayAmount}
                  </Skeleton>
                </div>
              </div>
              <div className={`${this.shouldHideAddCreditsForm() ? 'hide-credits-form' : ''}`}>
                <div className="credits-request">
                  <Form.Item label="Request more credits" colon={false} required={false}>
                    {form.getFieldDecorator('Credits', {
                      rules: [{ required: true, message: 'Please choose credits' }],
                    })(
                      <Select className="credits-request-amount">
                        <Option value={500}>500</Option>
                        <Option value={1000}>1000</Option>
                        <Option value={2000}>2000</Option>
                        <Option value="Customize">Customize..</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>

                {form.getFieldValue('Credits') === 'Customize' ? (
                  <div className="other-amount-request">
                    <Form.Item label="Enter other amount" colon={false}>
                      {form.getFieldDecorator('OtherAmount', {
                        rules: [{ validator: this.validateCredits }],
                      })(<Input className="other-amount-input" placeholder="Enter credit amounts" />)}
                    </Form.Item>
                  </div>
                ) : null}
                <Button
                  className="send-request-button"
                  onClick={this.handleSendRequest}
                  loading={requestForCreditsApiStatus === 'INPROGRESS'}
                  disabled={this.isSendRequestButtonDisabled()}
                >
                  Send Request
                </Button>
              </div>
              <div className="credits-submit-disclaimer">
                Disclaimer: Access to contact information provider data is available as a subscription for an additional
                fee
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // }
    //  return null;
  }
}

export default Form.create()(ContactSettings);
export { ContactSettings as ContactSettingsWithoutForm };
