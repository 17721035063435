import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styles from './TooltipUtils.module.scss';

export default function selectPlanTooltip(selectPlan = '/select-plan', description = 'To Enjoy All Features') {
  return (
    <>
      <Link to={selectPlan}>Select A Plan </Link>
      {description}
    </>
  );
}

export const selectPlanCustomTooltip = (
  selectPlan = '/select-plan',
  description = 'To Enjoy All Features',
  SVGIcon,
  buttonText = 'Select a plan'
) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.icon}>
        <SVGIcon />
      </span>
      <div className={styles.textWrapper}>
        <span>{description}</span>
        <Link to={selectPlan}>
          <Button type="primary" shape="round" className={styles.selectPlanBtn}>
            {buttonText}
          </Button>
        </Link>
      </div>
    </div>
  );
};
