import React from 'react';
import { connect } from 'react-redux';
import ResearchPortalComponent from '../../Components/ResearchPortal/ResearchPortalComponent';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import styles from './ResearchPortalContainer.module.scss';

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};

export function ResearchPortalContainer(props) {
  const { featureToggleList } = props;
  return (
    <div className={styles.researchPortalContainer}>
      <ResearchPortalComponent featureToggleList={featureToggleList} />
    </div>
  );
}

export default connect(mapStateToProps)(ResearchPortalContainer);
