function linkify(inputText) {
  let replacedText;
  const modifiedInputText = inputText.split('\n');

  replacedText = modifiedInputText.map(input => {
    const httpUrlPattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = input.replace(httpUrlPattern, '<a target="_blank" href="$1">$1</a>');

    const wwwUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(wwwUrlPattern, '$1<a target="_blank" href="http://$2">$2</a>');

    const emailPattern = /([\w+-]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[a-zA-Z]{2,}))/gim;
    replacedText = replacedText.replace(emailPattern, '<a href="mailto:$1">$1</a>');

    return replacedText;
  });
  return replacedText.join('<br/>');
}

export default linkify;
