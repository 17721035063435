import React from 'react';
import { Checkbox } from 'antd';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';

function CandidateCheckbox() {
  const {
    candidate,
    isCandidateChecked,
    indexKey,
    showCandidateCheckboxSelection,
    onCandidateCheckBoxClick,
    isCheckboxDisabled,
  } = React.useContext(CandidateCardContext);
  
  return (
    <div>
      {showCandidateCheckboxSelection ? (
        <Checkbox
          onChange={event => onCandidateCheckBoxClick(event, indexKey)}
          checked={isCandidateChecked}
          value={candidate}
          disabled={isCheckboxDisabled}
          onClick={e => e.stopPropagation()}
        />
      ) : null}
    </div>
  );
}

export default CandidateCheckbox;
