function convertToDays(resumeUpdateDurationQuantity, resumeUpdateDurationType) {
  if (resumeUpdateDurationQuantity && resumeUpdateDurationType === 'Days') {
    return resumeUpdateDurationQuantity;
  }
  let resumeUpdateDuration = 0;
  if (resumeUpdateDurationQuantity && resumeUpdateDurationType === 'Months') {
    resumeUpdateDuration = resumeUpdateDurationQuantity * 30;
  } else if (resumeUpdateDurationQuantity && resumeUpdateDurationType === 'Years') {
    resumeUpdateDuration = resumeUpdateDurationQuantity * 365;
  }
  return resumeUpdateDuration;
}

function convertFromDays(resumeUpdateDuration) {
  if (resumeUpdateDuration % 365 === 0) {
    return { resumeUpdateDurationQuantity: resumeUpdateDuration / 365, resumeUpdateDurationType: 'Years' };
  }
  if (resumeUpdateDuration % 30 === 0) {
    return { resumeUpdateDurationQuantity: resumeUpdateDuration / 30, resumeUpdateDurationType: 'Months' };
  }
  return { resumeUpdateDurationQuantity: resumeUpdateDuration, resumeUpdateDurationType: 'Days' };
}

function getTimePeriodWithUnit(resumeUpdateDurationQuantity = 0, resumeUpdateDurationType = 'days') {
  if (resumeUpdateDurationQuantity) {
    switch (resumeUpdateDurationType?.toLowerCase()) {
      case 'days':
        return `${resumeUpdateDurationQuantity}d`;
      case 'months':
        return `${resumeUpdateDurationQuantity}m`;
      case 'years':
        return `${resumeUpdateDurationQuantity}y`;
      default:
        return `${resumeUpdateDurationQuantity}${resumeUpdateDurationType}`;
    }
  }
  return '6m';
}

export { convertToDays, convertFromDays, getTimePeriodWithUnit };
