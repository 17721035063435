import cleanSet from 'clean-set';

const emptyList = [];
const emptyObject = {};

const initialState = {
  ById: {},
  CurrentTemplateIds: [],
};

function DripTemplates(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'SET_DRIP_TEMPLATES':
      newState = { ...state };
      newState.CurrentTemplateIds = action.payload.map(template => template.Id);
      action.payload.forEach(template => {
        newState = cleanSet(newState, ['ById', template.Id], template);
      });
      return newState;

    case 'CLEAR_DRIP_TEMPLATES':
      return initialState;

    case 'SET_DRIP_TEMPLATES_COUNT':
      return {
        ...state,
        Count: action.payload,
      };

    case 'SET_DRIP_TEMPLATE_DETAILS':
      return cleanSet(state, ['ById', action.payload.templateId], action.payload.template);

    case 'SET_DRIP_TEMPLATE_SHARED_WITH': {
      newState = { ...state };
      let dripTemplate = { ...newState.ById[action.templateId] };
      if (action.payload.sharedWith || action.payload.unsharedWith) {
        dripTemplate = {
          ...dripTemplate,
          SharedWith: action.payload.sharedWith,
          UnsharedWith: action.payload.unsharedWith,
          IsPublic: false,
        };
      }

      if (action.payload.isShareWithAll) {
        dripTemplate.IsPublic = true;
      }
      if (action.payload.isUnshareWithAll) {
        dripTemplate.IsPublic = false;
      }
      newState = cleanSet(state, ['ById', action.templateId], dripTemplate);
      return newState;
    }
    default:
      return state;
  }
}

function getCurrentDripTemplateIds(state) {
  return state.DripTemplates.CurrentTemplateIds || emptyList;
}

function getDripTemplatesById(state) {
  return state.DripTemplates.ById || emptyObject;
}

function getDripTemplatesCount(state) {
  return state.DripTemplates.Count;
}

export { DripTemplates, getCurrentDripTemplateIds, getDripTemplatesById, getDripTemplatesCount };
