import * as React from 'react';
import { Icon } from 'antd';

const ChatGPTInputSendEnabledSvg = props => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.9711 1.9544C21.0073 1.82611 21.0086 1.6905 20.9749 1.56153C20.9412 1.43256 20.8738 1.3149 20.7796 1.22065C20.6853 1.1264 20.5676 1.05897 20.4387 1.0253C20.3097 0.991629 20.1741 0.992939 20.0458 1.02909L2.0458 6.48722C1.89885 6.52869 1.76805 6.61403 1.6709 6.73183C1.57375 6.84964 1.51487 6.99429 1.50213 7.14645C1.48939 7.29861 1.5234 7.45104 1.59961 7.58335C1.67582 7.71567 1.7906 7.82157 1.92861 7.8869L9.95549 11.6875C10.1118 11.7616 10.2376 11.8875 10.3117 12.0438L14.1133 20.0697C14.1786 20.2077 14.2845 20.3225 14.4169 20.3987C14.5492 20.4749 14.7016 20.5089 14.8538 20.4962C15.0059 20.4835 15.1506 20.4246 15.2684 20.3274C15.3862 20.2303 15.4715 20.0995 15.513 19.9525L20.9711 1.9544Z"
      stroke="#13C26B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1646 11.8356L15.0002 7"
      stroke="#13C26B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ChatGPTInputSendEnabledIcon = props => (
  <Icon component={customProps => <ChatGPTInputSendEnabledSvg {...props} {...customProps} />} {...props} />
);
export default ChatGPTInputSendEnabledIcon;
