import React from 'react';
import { Form, Slider, Checkbox } from 'antd';
import styles from './PulseMilesInput.module.scss';

const FormItem = Form.Item;

export default function PulseMilesInput(props) {
  const { form, label, initialRange, isSliderDisabled, isAutoExpandDisabled, isTooltipVisible } = props;
  const { getFieldDecorator } = form;

  const handleCheckChange = event => {
    form.setFieldsValue({ Range: event.target.checked ? 1000 : 100 });
  };

  return (
    <FormItem label={label} colon={false} className={styles.milesInput}>
      {getFieldDecorator('Range', {
        initialValue: initialRange,
      })(
        <Slider
          style={{ width: '175px' }}
          disabled={isSliderDisabled}
          min={5}
          tooltipVisible={isTooltipVisible}
          tooltipPlacement="bottom"
        />
      )}
      <span style={{ fontSize: '16px' }}>Maximum Miles</span>
      {getFieldDecorator('AutoExpand', {
        valuePropName: 'checked',
        initialValue: initialRange > 100,
      })(
        <Checkbox onChange={handleCheckChange} disabled={isAutoExpandDisabled}>
          <span style={{ fontSize: '16px' }}>Search the entire country</span>
        </Checkbox>
      )}
    </FormItem>
  );
}
