import React from 'react';
import { Icon } from 'antd';

const ChoosePlanSvg = props => {
  return (
    <svg viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path id="prefix__ac" d="M0 .012h26.927v17.692H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={18.5} cy={18.5} r={18} stroke="#979797" />
        <g transform="translate(5 11.069)">
          <mask id="prefix__bc" fill="#fff">
            <use xlinkHref="#prefix__ac" />
          </mask>
          <path
            d="M7.7.941l1.412 1.415c0-.302.004-.664-.002-1.027-.003-.224-.134-.371-.35-.382C8.38.927 8 .94 7.7.94zm18.297 1.49c0-.344.004-.684-.002-1.025-.004-.246-.106-.446-.375-.46-.377-.02-.755-.005-1.115-.005l1.492 1.49zm-9.713 1.285L18.14 5.57c0-.463.017-.962-.008-1.459-.012-.23-.191-.384-.43-.391-.481-.014-.964-.004-1.418-.004zm2.769.167H26c-.045-.071-.063-.117-.096-.15-.898-.9-1.797-1.799-2.702-2.693a.374.374 0 00-.237-.098A838.186 838.186 0 0018.237.94c-.263 0-.417.141-.421.41-.007.424-.007.847.001 1.27.001.066.052.176.097.186.6.139.956.53 1.139 1.077zm-10.7-.829c-.66-.658-1.326-1.33-2.006-1.991a.541.541 0 00-.346-.12C4.497.935 2.993.937 1.49.938c-.427 0-.554.128-.558.562-.003.373 0 .746 0 1.119V3.89h6.895l.527-.837zm9.845 4.054c-.078-.116-.108-.179-.155-.225-1.02-1.02-2.038-2.04-3.066-3.052a.498.498 0 00-.318-.12 877.275 877.275 0 00-5.24-.004c-.485.001-.643.167-.644.663-.003.826-.002 1.652 0 2.478 0 .087.014.173.021.26h9.402zm7.8-2.276H19.11v7.432H25.37c.526 0 .629-.104.629-.642v-6.79zM7.816 12.264V4.832H.951c-.008.056-.019.094-.02.133 0 2.268-.003 4.537.002 6.805 0 .366.15.493.533.493 1.889.002 3.778 0 5.667 0h.683zm1.161 5.44c-.852-.302-1.18-.92-1.144-1.801.032-.785.006-1.573.006-2.359v-.361H7.45c-1.97 0-3.94-.025-5.909.01-1.064.018-1.547-.75-1.541-1.531C.024 8.285.023 4.907 0 1.53-.005.757.488-.001 1.523.013c2.343.032 4.687.009 7.03.01.968.001 1.494.528 1.498 1.497v1.232h6.826c0-.402.016-.803-.004-1.201-.044-.893.564-1.552 1.53-1.539 2.333.033 4.667.033 7 0 1.044-.014 1.53.752 1.524 1.517-.022 3.387-.01 6.775-.01 10.162 0 .964-.528 1.49-1.5 1.49-1.98.003-3.96.001-5.94.001h-.389v.906c0 .605-.023 1.21.006 1.814.042.88-.29 1.5-1.144 1.801h-3.577c-.22-.142-.375-.325-.279-.602.096-.276.33-.31.593-.308.91.009 1.819.004 2.728.003.578 0 .738-.158.739-.732V8.087H8.79c-.007.073-.015.122-.015.171 0 2.642-.002 5.285 0 7.927.001.418.196.608.616.61.95.005 1.9.006 2.849-.002.262-.002.496.032.593.308.097.277-.059.46-.279.602H8.977z"
            fill="#39B66A"
            mask="url(#prefix__bc)"
          />
        </g>
        <path
          d="M17.996 21.391c-.332.195-.501.428-.5.753.002.324.16.564.5.74V21.391zm.948 4.144c.347-.192.505-.445.485-.78-.018-.304-.165-.543-.485-.682v1.462zm.003-4.133v1.62c.254.142.52.25.736.42 1.064.847.82 2.442-.449 2.98-.17.072-.302.135-.379.338-.045.118-.262.24-.4.237a.537.537 0 01-.392-.253.56.56 0 00-.33-.304c-.695-.271-1.155-.92-1.15-1.592.002-.296.153-.48.412-.497.276-.019.464.144.494.45.03.313.163.55.507.745 0-.369-.02-.694.005-1.016.03-.405-.032-.667-.481-.868-.684-.304-.998-.923-.93-1.683.065-.722.49-1.195 1.138-1.476.152-.066.252-.131.334-.296.064-.129.27-.253.417-.257.132-.003.33.131.387.256.077.168.18.23.333.297.67.293 1.091.783 1.141 1.536.023.336-.139.539-.41.558-.279.02-.454-.151-.494-.488-.035-.3-.175-.526-.49-.707z"
          fill="#39B66A"
        />
      </g>
    </svg>
  );
};

const ChoosePlanIcon = props => <Icon component={ChoosePlanSvg} {...props} />;
export default ChoosePlanIcon;
