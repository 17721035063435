import React from 'react';
import classNames from 'classnames';
import { Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import styles from './CandidateJobsItem.module.scss';

function CandidateJobsItem(props) {
  const { jobTitle, jobId, clientJobId, stage, lastUpdated, status, version, openJobViewInNewTabCallBack } = props;
  const handleOpenJob = () => {
    if (version !== 'ats') {
      window.open(`/jobs/${jobId}`);
    } else if (version === 'ats' && clientJobId?.trim().length > 0) {
      openJobViewInNewTabCallBack(clientJobId);
    }
  };

  return (
    <div className={styles.jobCardDetails} role="presentation">
      <Row className={styles.jobCard} type="flex" justify="space-between">
        <Col xs={24} sm={5}>
          <Tooltip title={jobTitle}>
            <div
              className={styles.jobTitle}
              onClick={handleOpenJob}
              onKeyPress={handleOpenJob}
              role="button"
              tabIndex={0}
            >
              {jobTitle}
            </div>
          </Tooltip>
        </Col>
        <Col xs={24} sm={3}>
          <div className={styles.jobId}>{jobId}</div>
        </Col>
        <Col xs={24} sm={3}>
          <div
            className={classNames(
              {
                [styles.jobId]: clientJobId?.length,
              },
              { [styles.noneText]: !clientJobId?.length }
            )}
          >
            {clientJobId || 'none'}
          </div>
        </Col>
        <Col xs={24} sm={3}>
          <div className={styles.candidateStage}>{stage}</div>
        </Col>

        <Col xs={24} sm={2}>
          <div className={styles.jobStatus}>{status}</div>
        </Col>
        <Col xs={24} sm={3}>
          <div className={styles.updatedTime}>{moment.utc(lastUpdated).local().format(' MMM DD, YYYY')}</div>
        </Col>
      </Row>
    </div>
  );
}

export default CandidateJobsItem;
