export const getMatchingJobsTitleDisplayOptions = matchingJobTitleFilterOptions => {
  const matchingJobTitleDisplayOptions = [];
  if (!matchingJobTitleFilterOptions?.length) return [];
  matchingJobTitleFilterOptions.forEach(title => {
    const idx = matchingJobTitleDisplayOptions.findIndex(
      matchingJobTitleDisplayOption =>
        matchingJobTitleDisplayOption.name
          .replace(/\s+/g, ' ')
          .trim()
          .toLowerCase() ===
        title.Value.replace(/\s+/g, ' ')
          .trim()
          .toLowerCase()
    );
    if (idx >= 0) {
      matchingJobTitleDisplayOptions[idx].similarTitles.push(title.Value);
      matchingJobTitleDisplayOptions[idx].count += title.Count;
    } else {
      matchingJobTitleDisplayOptions.push({
        name: title.Value.toLowerCase(),
        count: title.Count,
        similarTitles: [title.Value],
      });
    }
  });
  return matchingJobTitleDisplayOptions;
};
export const getMatchingJobSimilarTitles = ({ selectedJobTitleFilterOption, matchingJobTitleFilterOptions }) => {
  if (!selectedJobTitleFilterOption || !selectedJobTitleFilterOption.trim().length) return [];
  const matchingJobTitle = matchingJobTitleFilterOptions.find(
    matchingJobTitleFilterOption =>
      matchingJobTitleFilterOption.name.toLowerCase() === selectedJobTitleFilterOption.toLowerCase()
  );
  if (matchingJobTitle) {
    return matchingJobTitle.similarTitles;
  }

  return [];
};
