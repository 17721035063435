import React from 'react';
import { Tag } from 'antd';
import styles from './JobRecommendations.module.scss';

export default function JobRecommendations({ recommendations, onAddRecommendation, recommendationTitle }) {
  return recommendations?.length ? (
    <div className={styles.recommendationContainer}>
      <p className={styles.recommendationHeader}>{recommendationTitle}</p>
      <div className={styles.recommedationTagGroup}>
        {recommendations.map(keyword => (
          <Tag.CheckableTag
            key={keyword}
            className={styles.recommendationTags}
            checked={false}
            onChange={() => onAddRecommendation(keyword)}
          >
            + {keyword}
          </Tag.CheckableTag>
        ))}
      </div>
    </div>
  ) : null;
}
