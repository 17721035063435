import { Button, Collapse, Form } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as PaymentActions from '../../Actions/AryaPayActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import * as JobActions from '../../Actions/JobActions';
import * as UserActions from '../../Actions/UserActions';
import eventTypes from '../../Analytics/EventTypes';
import PaymentBillingInfo from '../../Components/Payment/PaymentBillingInfo';
import PaymentCompanyInfoPanel from '../../Components/Payment/PaymentCompanyInfoPanel';
import PaymentInformation from '../../Components/PaymentJobAd/PaymentInformation';
import PaymentPlaceOrder from '../../Components/PaymentJobAd/PaymentPlaceOrder';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getTotalAmountForProductvariant } from '../../Reducers/AryaPayOrderReducer';
import {
  getCreateOrderApiStatus,
  getOrderInvoiceNumber,
  getPaymentApiStatus,
  getPaymentProfile,
  getUpdateBillingInfoApiStatus,
  getCreateOrderApiErrorMessage,
} from '../../Reducers/AryaPayReducer';
import { getJobUtilities } from '../../Reducers/JobReducer';
import { getUserLocationUtilities } from '../../Reducers/UserReducer';
import { getCurrentUser, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import '../Payment/Payment.scss';

const { Panel } = Collapse;
const mapStateToProps = state => ({
  currentUserDetails: getCurrentUserDetails(state),
  createOrderApiStatus: getCreateOrderApiStatus(state),
  paymentApiStatus: getPaymentApiStatus(state),
  updateBillingInfoApiStatus: getUpdateBillingInfoApiStatus(state),
  currentUser: getCurrentUser(state),
  getAmountApiStatus: getApiStatus(state, 'fetchTotalAmountForProductvariantWithTaxApiStatus'),
  invoiceNumber: getOrderInvoiceNumber(state),
  jobUtilities: getJobUtilities(state),
  locationApiStatus: getApiStatus(state, 'locationApiStatus'),
  paymentProfile: getPaymentProfile(state),
  totalAmount: getTotalAmountForProductvariant(state),
  userUtilities: getUserLocationUtilities(state),
  createOrderApiErrorMessage: getCreateOrderApiErrorMessage(state),
});

const mapDispatchToProps = {
  createOrder: PaymentActions.createOrder,
  fetchPaymentProfile: PaymentActions.fetchPaymentProfile,
  savePaymentDetails: PaymentActions.savePaymentDetails,
  resetZipcodes: JobActions.resetZipcodes,
  resetTotalAmountForProductvariant: PayOrderActions.resetTotalAmountForProductvariant,
  clearZipValidationError: JobActions.clearZipValidationError,
  fetchLocations: JobActions.fetchLocations,
  searchZipCode: JobActions.searchZipCode,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
  clearZipCodeError: JobActions.clearZipCodeError,
  fetchTotalAmountForProductvariants: PayOrderActions.fetchTotalAmount,
  setCreateOrderApiStatus: PaymentActions.setCreateOrderApiStatus,
  updateBillingInfo: PaymentActions.updateBillingInfo,
  resetAryaPayApiStatuses: PaymentActions.resetAryaPayApiStatuses,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsForProductVariantIds,
  setCreateOrderApiStatusPending: PaymentActions.setCreateOrderApiStatusPending,
  fetchUsers: UserActions.fetchUsers,
  updateUserDetails: UserActions.updateUserDetails,
  fetchUserZipCodes: UserActions.fetchZipCodes,
  fetchUserLocations: UserActions.fetchLocations,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
};

class PaymentJobAd extends React.Component {
  constructor(props) {
    super(props);
    const isAddressAvaliable = this.getIsUserFullAddressAvaliable(props?.currentUserDetails);
    this.state = {
      selectedPaymentProfileId: null,
      orderModalVsibility: false,
      paymentMode: 'CreditCard',
      isPaymentAlreadyCompleted: false,
      activePanelKey: isAddressAvaliable ? '2' : '1',
    };
    this.callback = this.callback.bind(this);
  }

  async componentDidMount() {
    const {
      fetchPaymentProfile,
      productVariantId,
      fetchPlanDetailsByProductVariantId,
      fetchUsers,
      quantity,
      currentUser,
      fetchTotalAmountForProductvariants,
    } = this.props;
    window.addEventListener('beforeunload', this.callback);
    fetchPaymentProfile();
    fetchPlanDetailsByProductVariantId([productVariantId]);
    fetchUsers({
      userGuids: [currentUser.sub],
    });
    await fetchTotalAmountForProductvariants({
      items: [{ ProductVariantId: productVariantId, Quantity: quantity }],
      IsTaxCalcuationRequired: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.callback);
    const { resetAryaPayApiStatuses, resetTotalAmountForProductvariant } = this.props;
    resetAryaPayApiStatuses();
    resetTotalAmountForProductvariant();
  }

  callback = e => {
    e.preventDefault();
    e.returnValue = 'Are you sure you want to leave?';
  };

  updateAddress = async companyInfo => {
    const {
      productVariantId,
      updateUserDetails,
      quantity,
      fetchTotalAmountForProductvariants,
      fetchPlanDetailsByProductVariantId,
    } = this.props;
    await updateUserDetails(companyInfo);
    this.handlePanelChange('2');
    await fetchTotalAmountForProductvariants({
      Items: [{ productvariantId: productVariantId, quantity }],
      IsTaxCalcuationRequired: true,
    });
    fetchPlanDetailsByProductVariantId([productVariantId]);
  };

  toggleOrderModalVisibility = () => {
    const { orderModalVsibility } = this.state;
    this.setState({ orderModalVsibility: !orderModalVsibility });
  };

  onClickDuplicatePaymentOk = () => {
    const { orderModalVsibility } = this.state;
    this.setState({ orderModalVsibility: !orderModalVsibility });
    const { history } = this.props;
    history.push({
      pathname: `/jobs`,
    });
  };

  placeOrder = async values => {
    const {
      createOrder,
      quantity,
      paymentProfile,
      updateBillingInfo,
      setCreateOrderApiStatusPending,
      resetOrderDetails,
      resetOrderDetailsWithoutTax,
      productVariantId,
      advertiseCallback,
    } = this.props;
    const { selectedPaymentProfileId } = this.state;
    const paymentData = {
      cardDetails: null,
      paymentProfileId: null,
      saveCardDetails: values.savePaymentDetails,
      address: {},
    };

    const address = {};
    if (values.Location) {
      const locationParts = values.Location.trim().split(',');
      const City = locationParts[0] ? locationParts[0].trim() : '';
      const State = locationParts[1] ? locationParts[1].trim() : '';
      paymentData.address = { ...paymentData.address, City, State };
      address.State = values.Location.trim();
    }
    if (values.Address) {
      address.State = values.Address.trim();
      paymentData.address = { ...paymentData.address, Street: values.Address.trim() };
    }
    if (values.Country) {
      address.Country = values.Country.trim();
      paymentData.address = { ...paymentData.address, Country: values.Country.trim() };
    }
    if (values.Zipcode) {
      address.Zip = values.Zipcode.trim();
      paymentData.address = { ...paymentData.address, Zip: values.Zipcode.trim() };
    }

    const selectedPaymentProfile = paymentProfile
      ? paymentProfile.find(profile => profile.ProfileId === selectedPaymentProfileId)
      : null;
    const selectedBillingAddress = selectedPaymentProfile?.Address;
    let billInfoUpdated = false;
    if (!_.isEqual(paymentData.address, selectedBillingAddress)) {
      billInfoUpdated = true;
    }
    if (selectedPaymentProfileId) {
      paymentData.paymentProfileId = selectedPaymentProfileId;
    } else {
      const cardNumber = values?.Number ?? '';
      paymentData.cardDetails = {
        Number: cardNumber,
        ExpiryMonth: Number(values.Date.format('M')),
        ExpiryYear: values.Date.year(),
        CVV: values.Cvv,
      };
    }
    this.toggleOrderModalVisibility();
    setCreateOrderApiStatusPending();
    if (billInfoUpdated && selectedPaymentProfile) {
      await updateBillingInfo(selectedPaymentProfile.ProfileId, paymentData.address);
    }
    const items = [
      {
        ProductVariantId: productVariantId,
        Quantity: quantity,
        CreditsToUse: paymentData.creditsToUse,
        RefIds: [],
        IsGroupPlanRequired: false,
      },
    ];
    await createOrder(items, paymentData, 'Future', advertiseCallback);
    resetOrderDetails();
    resetOrderDetailsWithoutTax();
  };

  placeOrderCollectInfo = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.placeOrder(values);
      }
    });
  };

  handleBackToJobCreate = () => {
    const { backNavigation } = this.props;
    if (backNavigation) backNavigation();
  };

  getPlaceOrderDisabilityStatus = () => {
    const { selectedPaymentProfileId } = this.state;
    const { paymentProfile, form, getAmountApiStatus = 'PENDING' } = this.props;
    const { getFieldValue } = form;
    const isAmountAvaliable = getAmountApiStatus === 'COMPLETED';
    let disablePlaceOrder = true;
    const currentAddressLocation = getFieldValue('Location');
    const currentAddress = getFieldValue('Address');
    const currentCountry = getFieldValue('Country');
    const currentZipCode = getFieldValue('Zipcode');
    const currentCvv = getFieldValue('Cvv');
    const currentDate = getFieldValue('Date');
    const currentName = getFieldValue('Name');
    const currentNumber = getFieldValue('Number');
    const isPaymentInfoAvaliable =
      (paymentProfile.length && selectedPaymentProfileId) ||
      (currentName && currentNumber && currentDate && currentCvv);
    const isbillingAddressAvaliable = currentAddress && currentCountry && currentAddressLocation && currentZipCode;
    if (isAmountAvaliable && isbillingAddressAvaliable && isPaymentInfoAvaliable) {
      disablePlaceOrder = false;
    }
    return disablePlaceOrder;
  };

  getIsUserFullAddressAvaliable = (userDetails = {}) => {
    const { Country, ZipCode, State, City, Street } = userDetails;
    return Country && ZipCode && State && City && Street;
  };

  handlePanelChange = key => {
    this.setState({ activePanelKey: key });
  };

  render() {
    const countries = [{ Name: 'USA', Iso2Code: 'US', Iso3Code: 'USA' }];
    const {
      selectedPaymentProfileId,
      orderModalVsibility,
      paymentMode,
      isPaymentAlreadyCompleted,
      activePanelKey,
    } = this.state;

    const {
      form,
      quantity,
      placeOrderButtonText,
      productVariantId,
      productVariant,
      createOrderApiStatus,
      paymentApiStatus,
      history,
      paymentProfile,
      invoiceNumber,
      jobUtilities,
      locationApiStatus,
      fetchLocations,
      fetchZipCodes,
      resetZipcodes,
      clearZipValidationError,
      searchZipCode,
      clearZipCodeError,
      updateBillingInfoApiStatus,
      getAmountApiStatus = 'PENDING',
      totalAmount,
      userUtilities,
      fetchUserLocations,
      fetchUserZipCodes,
      currentUserDetails,
      createOrderApiErrorMessage,
    } = this.props;
    const disablePlaceOrder = this.getPlaceOrderDisabilityStatus();
    const selectedPaymentProfile = paymentProfile?.find(profile => profile.ProfileId === selectedPaymentProfileId);
    const selectedBillingAddress = selectedPaymentProfile?.Address;
    const isAddressAvaliable = this.getIsUserFullAddressAvaliable(currentUserDetails);
    return (
      <Form onSubmit={this.placeOrderCollectInfo} layout="inline" className="billing-info-card">
        <div>
          <div className="job-creation-back">
            <Button
              type="link"
              onClick={this.handleBackToJobCreate}
              sk-event-name={eventTypes.job.advertiseJob.advertiseJobPaymentBack}
            >
              {'< Back'}
            </Button>
          </div>
        </div>
        <span className="place-order-title">Place Order</span>
        <div className="payment-plan-container">
          <div className="payment-billing-panel">
            <PaymentCompanyInfoPanel
              userDetails={currentUserDetails}
              updateAddress={this.updateAddress}
              activePanelKey={activePanelKey}
              handlePanelChange={this.handlePanelChange}
              paymentMode={paymentMode}
              userUtilities={userUtilities}
              fetchUserLocations={fetchUserLocations}
              fetchUserZipCodes={fetchUserZipCodes}
              isAddressAvaliable={isAddressAvaliable}
              getAmountApiStatus={getAmountApiStatus}
            />
            <Collapse
              expandIconPosition="right"
              onChange={this.handlePanelChange}
              defaultActiveKey={[activePanelKey]}
              activeKey={[activePanelKey]}
              accordion
              ghost
            >
              <Panel header={<div className="payment-method">Payment Information</div>} key="2">
                <div className="payment-billing-information-container">
                  <PaymentInformation hideHeader form={form} />
                  <PaymentBillingInfo
                    userDetails={currentUserDetails}
                    isAddressAvaliable={isAddressAvaliable}
                    countries={countries}
                    fetchLocations={fetchLocations}
                    resetZipcodes={resetZipcodes}
                    fetchZipCodes={fetchZipCodes}
                    clearZipValidationError={clearZipValidationError}
                    searchZipCode={searchZipCode}
                    clearZipCodeError={clearZipCodeError}
                    paymentMode={paymentMode}
                    form={form}
                    selectedAddress={selectedBillingAddress}
                    utilities={jobUtilities}
                    locationApiStatus={locationApiStatus}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="payment-agreement-container">
            <PaymentPlaceOrder
              quantity={quantity}
              productVariants={[productVariant]}
              variantId={productVariantId}
              createOrderApiStatus={createOrderApiStatus}
              paymentApiStatus={paymentApiStatus}
              history={history}
              placeOrderCollectInfo={this.placeOrderCollectInfo}
              updateBillingInfoApiStatus={updateBillingInfoApiStatus}
              isAddressAvaliable={isAddressAvaliable}
              modalVisbility={orderModalVsibility}
              invoiceNumber={invoiceNumber}
              toggleOrderModalVisibility={this.toggleOrderModalVisibility}
              onClickDuplicatePaymentOk={
                isPaymentAlreadyCompleted ? this.onClickDuplicatePaymentOk : this.toggleOrderModalVisibility
              }
              getAmountApiStatus={getAmountApiStatus}
              totalAmount={totalAmount}
              disablePlaceOrder={disablePlaceOrder}
              createOrderApiErrorMessage={createOrderApiErrorMessage}
              placeOrderButtonText={placeOrderButtonText}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentJobAd));

export { PaymentJobAd as PaymentJobAdWithoutStore };
