import React from 'react';
import classNames from 'classnames';
import CandidateJobsHeader from '../CandidateJobsHeader/CandidateJobsHeader';
import CandidateJobs from './CandidateJobs';
import JobMatchingModalWrapper from '../CandidateJobsHeader/JobMatchingModalWrapper';
import styles from './CandidateJobsWrapper.module.scss';

function CandidateJobsWrapper(props) {
  const {
    jobs,
    jobAggregations,
    candidate,
    fetchCandidateJobs,
    onSearchChange,
    onSearch,
    searchTerm,
    selectedCandidateStageFilterOptions,
    setSelectedCandidateStageFilterOptions,
    selectedJobStatusFilterOptions,
    setSelectedJobStatusFilterOptions,
    jobsTotalCount,
    appendCandidateJobs,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    fetchCandidateMatchingJobs,
    clearAllFilter,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    version,
    candidateJobMatchingInitialAggregation,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isATSViewActive,
    setIsFindMathingJobClicked,
  } = props;
  const ref = React.useRef(null);
  const [isFilterPopoverVisible, setFilterPopoverVisbility] = React.useState(false);
  const [isJobMatchingFilterPopoverVisible, setJobMatchingFilterPopoverVisbility] = React.useState(false);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) {
        setCandidateViewHeaderVisibility(true);
      } else {
        setFilterPopoverVisbility(false);
        setCandidateViewHeaderVisibility(false);
        setJobMatchingFilterPopoverVisbility(false);
      }
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      className={
        !isATSViewActive
          ? classNames(styles.candidateJobs, { [styles.extendView]: !isCandidateViewHeaderVisible })
          : null
      }
      ref={ref}
    >
      <div>
        {isCandidateMatchingJobModalVisible ? (
          <JobMatchingModalWrapper
            isMatchingVisible={isCandidateMatchingJobModalVisible}
            setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
            fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
            candidate={candidate}
            version={version}
            isFilterPopoverVisible={isJobMatchingFilterPopoverVisible}
            setIsFilterPopoverVisbility={setJobMatchingFilterPopoverVisbility}
            candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
            candidateJobsTabRef={ref}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            onClickAddJobCallBack={onClickAddJobCallBack}
            isATSViewActive={isATSViewActive}
            setIsFindMathingJobClicked={setIsFindMathingJobClicked}
          />
        ) : (
          <>
            <div>
              <CandidateJobsHeader
                jobs={jobs}
                jobAggregations={jobAggregations}
                fetchCandidateJobs={fetchCandidateJobs}
                candidateId={candidate.Id}
                onSearchChange={onSearchChange}
                onSearch={onSearch}
                searchTerm={searchTerm}
                selectedCandidateStageFilterOptions={selectedCandidateStageFilterOptions}
                setSelectedCandidateStageFilterOptions={setSelectedCandidateStageFilterOptions}
                selectedJobStatusFilterOptions={selectedJobStatusFilterOptions}
                setSelectedJobStatusFilterOptions={setSelectedJobStatusFilterOptions}
                isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
                isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                isFilterPopoverVisible={isFilterPopoverVisible}
                setFilterPopoverVisbility={setFilterPopoverVisbility}
                isATSViewActive={isATSViewActive}
              />
            </div>
            <CandidateJobs
              jobs={jobs}
              jobsTotalCount={jobsTotalCount}
              candidateId={candidate.Id}
              fetchCandidateJobs={fetchCandidateJobs}
              appendCandidateJobs={appendCandidateJobs}
              selectedJobStatusFilterOptions={selectedJobStatusFilterOptions}
              selectedCandidateStageFilterOptions={selectedCandidateStageFilterOptions}
              searchTerm={searchTerm}
              clearAllFilter={clearAllFilter}
              version={version}
              isATSViewActive={isATSViewActive}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            />
          </>
        )}
      </div>
    </div>
  );
}
export default CandidateJobsWrapper;
