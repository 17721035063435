import * as candidateRepo from '../Repository/CandidateRepository';
import { updateUnlockCandidate, setCandidateDownloadedDetails } from './ActionCreators/CandidateActions';

function unlockCandidateResume(filter) {
  return dispatch => {
    dispatch({
      type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
      payload: 'INPROGRESS',
    });
    candidateRepo
      .unlockCandidateResume(filter)
      .then(response => {
        dispatch(updateUnlockCandidate(response.data));
        dispatch(
          setCandidateDownloadedDetails(
            response.data.Id,
            response.data.IsDownloaded,
            response.data.IsUnlocked,
            filter.jobId
          )
        );
        dispatch({
          type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

export { unlockCandidateResume };
