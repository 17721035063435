import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import * as SalesDashBoardActions from '../../Actions/SalesDashBoardActions';
import {
  getProductVariants,
  getOrderDetails,
  getProductVariantsById,
  getOrderDetailswithTax,
} from '../../Reducers/AryaPayOrderReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getJobsById } from '../../Reducers/JobReducer';
import { getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import SelectJobCredit from '../../Components/SelectJobCredit/SelectJobCredit';
import { RefineCriteriaIcon } from '../../Icons/AryaIcons';
import { getProductVariantsByName } from '../../Utils/PlanSelectionUtils';
import { PlanSelectionHeader } from './PlanSelectionModules';
import PlanSelectionFooter from '../../Components/Payment/PlanSelectionFooter';
import { PlanCards } from './CreditSelectionModules';
import styles from './PlanSelection.module.scss';

const mapStateToProps = state => ({
  productVariants: getProductVariants(state),
  jobsById: getJobsById(state),
  orderDetails: getOrderDetails(state),
  productVariantsById: getProductVariantsById(state),
  getAmountWithoutTaxApiStatus: getApiStatus(state, 'fetchTotalAmountForProductvariantWithoutTaxApiStatus'),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  orderDetailsWithTax: getOrderDetailswithTax(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  fetchTotalAmountForProductvariants: PayOrderActions.fetchTotalAmountForProductvariants,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
  clearGlobalInsightList: SalesDashBoardActions.clearGlobalInsightList,
};

function CreditSelection(props) {
  const {
    resetOrderDetails,
    resetOrderDetailsWithoutTax,
    productVariants,
    clearGlobalInsightList,
    history,
    productVariantsById,
    orderDetails,
    fetchTotalAmountForProductvariants,
    orderDetailsWithTax,
    featureToggleList,
  } = props;
  const visibleProductVariants = productVariants.filter(productVariant => productVariant.IsHidden !== true);
  const isPulsePromotionalOfferEnabled = featureToggleList.PulsePromotionalOffer.IsEnabled;
  let { orderItemById = {} } = orderDetailsWithTax;

  if (_.isEmpty(orderItemById)) {
    orderItemById = orderDetails?.orderItemById || {};
  }
  const product = getProductVariantsByName(productVariantsById);
  const basicPlanVariantId = product?.Basic?.Id;
  const premiumPlanVariantId = product?.Premium?.Id;
  const isBasicPlanHidden = product?.Basic?.IsHidden;
  const basicQuantity = isBasicPlanHidden ? 0 : orderItemById[basicPlanVariantId]?.Quantity ?? 1;
  const premiumQuantity = orderItemById[premiumPlanVariantId]?.Quantity ?? 1;

  // ComponentDidMount
  React.useEffect(() => {
    resetOrderDetails();
    resetOrderDetailsWithoutTax();
    clearGlobalInsightList();
  }, []);

  return (
    <div className={styles.selectPlanContainer}>
        <div className={styles.selectCreditHeaderContainer}>
          <RefineCriteriaIcon className={styles.refineCriteriaIconCreditSelection} />
          <PlanSelectionHeader isUpgrade={false} />
        </div>
        <div className={styles.selectPlanCardsContainer}>
          <PlanCards
            {...props}
            productVariants={visibleProductVariants}
            isPulsePromotionalOfferEnabled={isPulsePromotionalOfferEnabled}
          />
          <SelectJobCredit
            history={history}
            productVariantsById={productVariantsById}
            fetchTotalAmountForProductvariants={fetchTotalAmountForProductvariants}
            orderDetails={orderDetails}
            resetOrderDetailsWithoutTax={resetOrderDetailsWithoutTax}
            basicQuantity={Number.parseInt(basicQuantity, 10)}
            premiumQuantity={Number.parseInt(premiumQuantity, 10)}
            isBasicPlanHidden={isBasicPlanHidden}
            isPulsePromotionalOfferEnabled={isPulsePromotionalOfferEnabled}
          />
        </div>
        <div className={styles.moreDiscount}>
          Get 24 X 7 support via chat, email and phone.
          <br />
          To get your queries resolved regarding discounts and services, reach to us at <span>sales@leoforce.com</span>
        </div>
        <div className={styles.selectPlanFooterContainer}>
          <PlanSelectionFooter />
        </div>
      </div>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditSelection));

export { CreditSelection as CreditSelectionWithoutStore };
