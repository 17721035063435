import _ from 'lodash';
import appConfig from '../Config/Config';
import { getCandidateListPageSize } from './LocalStorageUtils';

export function getSupportedPageSizes(config, candidateListType, candidateContext) {
  let { defaultPageSizes } = appConfig;
  if (candidateContext === 'segment' && candidateListType !== 'ManualSearchCandidateList') {
    defaultPageSizes =
      config.SegmentCandidateListPageSizes.length > 0
        ? _.uniq(config.SegmentCandidateListPageSizes.concat([appConfig.defaultSelectedPageSize])).sort((a, b) => a - b)
        : appConfig.defaultPageSizes;
  }
  return defaultPageSizes.sort((a, b) => a - b);
}

export function getCurrentPageDefaultSize(config, candidateListType, candidateContext, userEmail) {
  const paginationId =
    candidateContext === 'segment' && candidateListType !== 'ManualSearchCandidateList' ? 'segment' : 'job';
  const defaultPageSizes = getSupportedPageSizes(config, candidateListType, candidateContext);
  const localStoragePageSize = getCandidateListPageSize({
    currentUserEmail: userEmail,
    paginationId,
  });
  const smallestAvailablePageSize = defaultPageSizes[0];
  if (!defaultPageSizes.includes(localStoragePageSize)) {
    return smallestAvailablePageSize;
  }
  return localStoragePageSize;
}
