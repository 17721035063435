import React from 'react';
import { Menu, Dropdown, Button, Checkbox, Tooltip, Empty, Input } from 'antd';
import SegregateComponent from '../../Components/SegregateComponent/SegregateComponent';
import styles from './WorkflowReport.module.scss';

const { Search } = Input;

function sortString(a, b) {
  const stringA = a.Title.toLowerCase();
  const stringB = b.Title.toLowerCase();
  if (stringA > stringB) return 1;
  if (stringA < stringB) return -1;
  return 0;
}

const WorkflowReportFilter = props => {
  const {
    workflowId,
    workflowReportFilterData,
    setWorkflowReportData,
    setWorkflowReportEndNodesData,
    isCandidateCRMFeatureEnabled,
    version,
  } = props;
  const [activeJobs, setActiveJobs] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState('Overview');
  const [filterContent, setFilterContent] = React.useState({ Jobs: [], Segments: [] });
  const [searchValue, setSearchValue] = React.useState({ Jobs: '', Segments: '' });
  const { Jobs: filterData } = workflowReportFilterData;
  const overviewClicked = () => {
    if (activeTab !== 'Overview') {
      setActiveTab('Overview');
      setActiveJobs([]);
      const data = {
        id: workflowId,
        aggregationIds: [workflowId],
      };
      setWorkflowReportData(data);
      setWorkflowReportEndNodesData({ reinitiate: true });
    }
  };

  React.useEffect(() => {
    if (Array.isArray(filterData)) {
      const jobsFilterData = [];
      const segmentFilterData = [];
      filterData.forEach(report => {
        if (report.EntityType === 1) {
          jobsFilterData.push({ ...report, display: true });
        } else if (report.EntityType === 2) {
          segmentFilterData.push({ ...report, display: true });
        }
      });
      jobsFilterData.sort(sortString);
      segmentFilterData.sort(sortString);
      setFilterContent({ Jobs: jobsFilterData, Segments: segmentFilterData });
    }
  }, [workflowId, filterData]);

  function setWorkflowReport(aggregationIds) {
    const data = {
      id: workflowId,
      aggregationIds,
    };
    setWorkflowReportData(data);
  }

  const onChangeMenu = (val, title) => {
    if (val.includes(`all-${title}`)) {
      if (!activeJobs.includes(`all-${title}`))
        filterContent[title].forEach(({ JobGuid }) => !val.includes(JobGuid) && val.push(JobGuid));
      else val.splice(val.indexOf(`all-${title}`), 1);
    } else if (activeJobs.includes(`all-${title}`)) {
      val.splice(0, val.length);
    } else if (val.length === filterContent[title].length) val.push(`all-${title}`);
    setActiveJobs(val);
  };

  function idSearch(title, val) {
    return filterContent[title].map(job => {
      const tempJob = { ...job };
      const { Id } = tempJob;
      if (!String(Id).includes(String(val))) tempJob.display = false;
      else tempJob.display = true;
      return tempJob;
    });
  }

  function nameSearch(title, val) {
    return filterContent[title].map(job => {
      const tempJob = { ...job };
      const { Title } = tempJob;
      if (!Title.toLowerCase().includes(val.toLowerCase())) tempJob.display = false;
      else tempJob.display = true;
      return tempJob;
    });
  }

  const onChangeSearch = (val, title) => {
    searchValue[title] = val;
    setSearchValue({ ...searchValue });
    let filterJobs;
    if (Number.isInteger(parseInt(val, 10))) filterJobs = idSearch(title, val);
    else filterJobs = nameSearch(title, val);
    filterContent[title] = filterJobs;
    setFilterContent(filterContent);
  };
  function resetSearch(title) {
    setSearchValue({ Jobs: '', Segments: '' });
    const filterJobs = filterContent[title].map(job => {
      const tempJob = { ...job };
      tempJob.display = true;
      return tempJob;
    });
    filterContent[title] = filterJobs;
    setFilterContent(filterContent);
  }

  const handleSubmit = title => {
    const aggregationIds = [...activeJobs];
    if (aggregationIds.includes(`all-${title}`)) aggregationIds.splice(aggregationIds.indexOf(`all-${title}`), 1);
    if (aggregationIds.length > 0) {
      setWorkflowReport(aggregationIds);
      if (activeTab !== title) setActiveTab(title);
      setWorkflowReportEndNodesData({ reinitiate: true });
      resetSearch(title);
    }
  };
  const Overview = <OverviewComponent overviewClicked={overviewClicked} />;
  const JobsComponent = (
    <TabComponent
      job={activeJobs}
      filterList={filterContent.Jobs}
      title="Active Jobs"
      tab={2}
      count={filterContent.Jobs.length < 10 ? `0${filterContent.Jobs.length}` : filterContent.Jobs.length}
      onChangeMenu={onChangeMenu}
      searchValue={searchValue.Jobs}
      onChangeSearch={onChangeSearch}
      handleSubmit={handleSubmit}
      version={version}
    />
  );
  const SegmentComponent = (
    <TabComponent
      job={activeJobs}
      filterList={filterContent.Segments}
      title="Active Segments"
      tab={1}
      count={filterContent.Segments.length < 10 ? `0${filterContent.Segments.length}` : filterContent.Segments.length}
      onChangeMenu={onChangeMenu}
      searchValue={searchValue.Segments}
      onChangeSearch={onChangeSearch}
      handleSubmit={handleSubmit}
      version={version}
    />
  );
  const filterComponents = isCandidateCRMFeatureEnabled
    ? [
        { component: Overview, name: 'Overview' },
        { component: SegmentComponent, name: 'Segments' },
        { component: JobsComponent, name: 'Jobs' },
      ]
    : [
        { component: Overview, name: 'Overview' },
        { component: JobsComponent, name: 'Jobs' },
      ];
  return (
    <div className={styles.filterContent}>
      <div className={styles.filterNote}>
        <h4>Select any one category</h4>
      </div>
      <SegregateComponent
        parentStyle={styles.parentStyle}
        isActive={activeTab}
        options={filterComponents}
        defaultOption="Overview"
        contentStyle={styles.contentStyle}
        activeSegmentClassName={styles.activeSegment}
      />
    </div>
  );
};

const Menus = ({
  count,
  job,
  onChangeMenu,
  title,
  searchValue,
  onChangeSearch,
  handleSubmit,
  filterList = [],
  version,
}) => {
  const buttonType = 'primary';
  function menuTitle(text, jobId) {
    const jobText = title === 'Segments' ? 'Seg.' : 'Job';
    if (text.length > 40) {
      return (
        <Tooltip title={`${text} (${jobText} Id: ${jobId})`} placement="right">
          {text.slice(0, 40)}
          <span>...</span> {` (${jobText} Id: ${jobId})`}
        </Tooltip>
      );
    }
    return `${text} (${jobText} Id: ${jobId})`;
  }
  if (count > 0)
    return (
      <Menu>
        <Menu.Item>
          <Search
            placeholder={`Search ${title}`}
            className={styles.filterSearchInput}
            onChange={e => onChangeSearch(e.target.value, title)}
            value={searchValue}
            allowClear
          />
        </Menu.Item>
        <Menu.Item className={styles.filterReportMenu}>
          <Checkbox.Group onChange={val => onChangeMenu(val, title)} value={job}>
            <Checkbox style={{ display: 'none' }} value={0}></Checkbox>
            <Checkbox value={`all-${title}`}>All</Checkbox>
            {filterList.length > 0 &&
              filterList.map(
                list =>
                  list.display && (
                    <Checkbox value={list.JobGuid}>
                      {menuTitle(list.Title, version !== 'ats' || title === 'Segments' ? list.Id : list.JobCode)}
                    </Checkbox>
                  )
              )}
          </Checkbox.Group>
        </Menu.Item>
        <Menu.Item>
          <Button htmlType="submit" block onClick={() => handleSubmit(title)} type={buttonType}>
            Submit
          </Button>
        </Menu.Item>
      </Menu>
    );
  return (
    <Menu>
      <Menu.Item>
        <Empty
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
          imageStyle={{
            height: 60,
          }}
          description={<span>No {title} found</span>}
        />
      </Menu.Item>
    </Menu>
  );
};

const TabComponent = props => {
  const { count, title, version } = props;
  return (
    <Dropdown
      getPopupContainer={node => node.parentNode}
      overlay={<Menus {...props} title={title.split(' ')[1]} version={version} />}
      placement="bottomLeft"
      trigger={['hover', 'click']}
      overlayClassName={styles.filterReportDropdown}
    >
      <Button className={styles.filterButton}>
        <div className={styles.dropDownBody}>
          <div className={styles.leftContent}>
            <div>{title}</div>
            <small>
              View <TriangleIcon />
            </small>
          </div>
          <div className={styles.rightContent}>{count}</div>
        </div>
      </Button>
    </Dropdown>
  );
};

const OverviewComponent = ({ overviewClicked }) => (
  <Button className={styles.filterButton} onClick={overviewClicked}>
    <div className={styles.overview}>Overview</div>
    <small>(All)</small>
  </Button>
);

const TriangleIcon = () => (
  <div className={styles.triangle}>
    <div></div>
  </div>
);
export default WorkflowReportFilter;
export { WorkflowReportFilter as WorkflowReportFilterTest, Menus as MenusComponent, TabComponent as TabComponentTest };
