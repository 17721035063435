import React from 'react';
import { getDatafromPath } from '../../../../../../Utils/LodashUtils';
import StatsDetails from '../../StatsDetails/StatsDetails';
import StatsView from '../../StatsDetails/StatsView/StatsView';
import GenericDonut from '../../../../../D3Charts/GenericDonut';
import styles from './SmsStats.module.scss';

const colors = ['#13C26B', '#DADBDD'];
export default function SmsStats(props) {
  const { textStats, subtitle } = props;

  const total = getDatafromPath(textStats, ['total'], 0);
  const repliedTexts = getDatafromPath(textStats, ['data', 0, 'Value'], 0);

  return (
    <StatsDetails title="Text/SMS" subtitle={subtitle}>
      <div className={styles.smsStats}>
        <div className={styles.smsStatsView}>
          <StatsView title="Total texts sent" count={total} />
          <StatsView title="Replies" count={repliedTexts} type="success" />
        </div>
        <div>
          <GenericDonut
            data={textStats?.data}
            width={208}
            height={208}
            title={total}
            subtitle="Total"
            colors={colors}
            showStatsText={false}
            chartTitle="Text"
            thickness={35}
          />
        </div>
      </div>
    </StatsDetails>
  );
}
