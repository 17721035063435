export const getJobStatusChangeModalHeading = ({ distributedPortals, isDripCampaignScheduled }) => {
  if (distributedPortals.length) return 'Job Advertising Campigns are active';
  if (isDripCampaignScheduled) return 'A drip campaign is currently scheduled for this job';
  return 'All active campaigns have been ended';
};

export const getJobStatusChangeModalDescription = ({ distributedPortals, isDripCampaignScheduled }) => {
  if (distributedPortals.length)
    return 'Do you want to stop the active Intelligent Job Advertising campaign? or continue';
  if (isDripCampaignScheduled) return 'Are you sure you want to end the campaign?';
  return 'Please press continue to close the job';
};
