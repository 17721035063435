import React from 'react';
import { Popover } from 'antd';
import SourcesContext from './SourceContext';
import AdvancedOptions from '../../Components/QuickSearchForm/AdvancedOptions/AdvancedOptions';
import AdvancedOptionsFooter from '../../Components/QuickSearchForm/AdvancedOptions/AdvancedOptionsFooter';
import styles from './AdvancedSettingsPopover.module.scss';

const AdvancedSettingsPopover = props => {
  const { children } = props;
  const {
    sourceName,
    advanceSettingsVisiblePopover,
    isAdvanceSettingsButtonDisabled,
    handleAdvanceSettingsPopoverVisibility,
    advancedOptionTitle,
    defaultAdvancedSettings,
    source,
    form,
    searchType,
    currentCompanies,
    monsterClassicCompanyCatagories,
    defaultValues,
    isCountryWideSearchEnabled,
    isAlternateLocationEnabled,
    isStateWideSearchEnabled,
    applyAdvancedOptions,
    clearAdvancedOptions,
  } = React.useContext(SourcesContext);
  return (
    <Popover
      key={sourceName}
      overlayClassName={styles.advanceOptionsPopover}
      overlayStyle={{ top: 70 }}
      placement="right"
      trigger={['click']}
      visible={advanceSettingsVisiblePopover === sourceName}
      onVisibleChange={visible =>
        handleAdvanceSettingsPopoverVisibility(visible, sourceName, isAdvanceSettingsButtonDisabled)
      }
      title={advancedOptionTitle}
      content={
        <>
          <AdvancedOptions
            source={source}
            defaultAdvancedSettings={defaultAdvancedSettings}
            form={form}
            searchType={searchType}
            currentCompanies={currentCompanies}
            monsterClassicCompanyCatagories={monsterClassicCompanyCatagories}
            countryCode={defaultValues.CountryCode}
            isCountryWideSearchEnabled={isCountryWideSearchEnabled}
            isAlternateLocationEnabled={isAlternateLocationEnabled}
            isStateWideSearchEnabled={isStateWideSearchEnabled}
          />
          <AdvancedOptionsFooter onApply={applyAdvancedOptions} onClear={clearAdvancedOptions} />
        </>
      }
    >
      {children}
    </Popover>
  );
};

export default AdvancedSettingsPopover;
