import React from 'react';
import { Icon } from 'antd';

const StartHiringSvg = props => {
  return (
    <svg width={37} height={37} viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#979797" cx={18.5} cy={18.5} r={18} />
        <g fill="#39B66A">
          <path d="M5 19.008c.337-.352.67-.708 1.012-1.055.48-.487.966-.97 1.454-1.451.323-.319.637-.325.953-.013.757.75 1.511 1.502 2.262 2.258.274.276.286.637.045.88-.249.25-.593.242-.879-.034-.364-.354-.72-.715-1.08-1.072-.057-.057-.118-.11-.205-.19-.041.74.007 1.436.158 2.117.739 3.332 2.678 5.684 5.848 6.97 4.016 1.627 8.79.134 11.213-3.465.071-.105.138-.213.212-.316.212-.294.572-.375.842-.193.278.187.345.538.148.851a10.45 10.45 0 01-2.218 2.52 10.555 10.555 0 01-5.537 2.368c-5.414.655-10.504-2.975-11.626-8.31-.155-.738-.174-1.504-.255-2.258-.009-.08-.008-.161-.014-.305-.448.449-.854.86-1.266 1.267-.257.254-.525.306-.775.137-.114-.076-.196-.199-.292-.3v-.406zM31 18.197c-.102.133-.192.278-.31.396-.701.709-1.409 1.412-2.116 2.116-.39.388-.673.387-1.063-.002-.737-.734-1.472-1.47-2.21-2.203-.185-.185-.25-.404-.174-.648.068-.218.233-.349.458-.394.242-.048.427.062.587.227l1.2 1.235.067-.032c0-.247.015-.495-.002-.74-.23-3.21-1.667-5.74-4.377-7.486-4.308-2.775-10.111-1.52-12.927 2.753-.047.07-.09.143-.14.211-.212.286-.552.364-.825.19a.585.585 0 01-.162-.844c.334-.477.662-.964 1.049-1.396 1.797-2.006 4.045-3.186 6.721-3.505 5.498-.655 10.593 3.057 11.659 8.493.148.758.179 1.538.266 2.33l.337-.333.808-.806c.455-.453.774-.43 1.154.083v.355z" />
          <path d="M21.44 22.142c.006-.025.013-.042.012-.058a.308.308 0 00-.011-.075c-.319-1.068-.998-1.82-2.085-2.07-.63-.146-1.307-.107-1.964-.118a2.79 2.79 0 00-1.787.61c-.56.433-.922.993-1.074 1.711h6.91zm-5.793-5.91a2.32 2.32 0 002.287 2.38c1.328.025 2.386-.976 2.41-2.281.024-1.339-.98-2.384-2.31-2.407a2.325 2.325 0 00-2.387 2.308zm.06 2.756a3.588 3.588 0 01-.127-5.344c1.388-1.27 3.543-1.25 4.895.062a3.564 3.564 0 01-.152 5.243c.372.273.78.516 1.119.833.825.774 1.248 1.747 1.28 2.88.013.463-.228.698-.698.698l-6.015-.002h-2.03c-.495-.002-.726-.228-.704-.713.073-1.59.826-2.762 2.21-3.54.072-.04.146-.077.222-.117z" />
        </g>
      </g>
    </svg>
  );
};

const StartHiringIcon = props => <Icon component={StartHiringSvg} {...props} />;

export default StartHiringIcon;
