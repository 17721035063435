import React from 'react';
import styles from './CandidateCardAssignedStatus.module.scss';

export default function CandidateCardAssignedStatus(props) {
  const { assignedStatus, candidateOrigin = '' } = props;
  let statusText = assignedStatus;
  if (statusText?.toLowerCase() === 'applied' && candidateOrigin) {
    statusText = statusText?.concat(' through ', candidateOrigin);
  }

  return (
    <div className={styles.candidateAssignedStatusWrapper}>
      <div className={styles.candidateAssignedStatusText}>{statusText}</div>
    </div>
  );
}
