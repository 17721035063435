import _ from 'lodash';
import { createSelector } from 'reselect';
import { SET_REGIONS, DELETE_REGION, SET_REGION } from '../Actions/ActionCreators/RegionActionCreator';

const initialState = {
  RegionsById: {},
};

function RegionReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_REGIONS: {
      newState = _.cloneDeep(state);
      const { regions } = action.payload;
      const regionsById = {};
      regions.forEach(region => {
        regionsById[region.RegionId] = region;
      });
      newState.RegionsById = regionsById;
      return newState;
    }
    case SET_REGION: {
      newState = _.cloneDeep(state);
      const { id, regionDetails } = action.payload;
      newState.RegionsById[id] = regionDetails;
      return newState;
    }
    case DELETE_REGION: {
      newState = _.cloneDeep(state);
      const { id } = action.payload;
      delete newState.RegionsById[id];
      return newState;
    }
    default:
      return state;
  }
}

const getRegions = createSelector(
  state => state.RegionReducer.RegionsById ?? {},
  regionsById => Object.values(regionsById)
);

export { RegionReducer, getRegions };
