import React from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import RoutesContainer from './routes';
import LoginForm from './Components/Auth/LoginForm';
import ForgotPasswordForm from './Components/Auth/ForgotPasswordForm';
import RequireNewPasswordForm from './Components/Auth/RequireNewPasswordForm';
import ConfirmUserSignUp from './Components/Auth/ConfirmUserSignUp';
import PasswordResetConfirmation from './Components/Auth/PasswordResetConfirmation';

function App() {
  return <RoutesContainer />;
}

export default withAuthenticator(App, false, [
  <LoginForm />,
  <RequireNewPasswordForm />,
  <ForgotPasswordForm />,
  <ConfirmUserSignUp />,
  <PasswordResetConfirmation />,
]);
