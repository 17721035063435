import React from 'react';
import { Modal, Button } from 'antd';
import {
  InValidDataIcon,
  DuplicateCardErrorIcon,
  DuplicateTransactionIcon,
  DeclinedTransactionIcon,
} from '../../Icons/AryaIcons';
import './PaymentErrorModal.scss';

function getIcon(iconType) {
  switch (iconType) {
    case 'duplicateCard':
      return <DuplicateCardErrorIcon style={{ fontSize: '160px' }} />;
    case 'duplicateTransaction':
      return <DuplicateTransactionIcon style={{ fontSize: '160px' }} />;
    case 'declineTransaction':
      return <DeclinedTransactionIcon style={{ fontSize: '161px' }} />;
    default:
      return <InValidDataIcon style={{ fontSize: '85px' }} />;
  }
}

function PaymentErrorModal(props) {
  const {
    creditCardFormDisplay,
    handleCancel,
    errorDescription,
    errorTitle,
    type,
    buttonText,
    orderErrorMessage,
  } = props;
  return (
    <Modal
      visible={creditCardFormDisplay}
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={false}
      footer={null}
      width="646px"
    >
      <div className="payment-error-modal">
        {getIcon(type)}
        <div className="payment-error-modal-title">{errorTitle}</div>
        <div className="payment-error-modal-description">{orderErrorMessage || errorDescription}</div>
        <Button type="primary" onClick={handleCancel} className="payment-error-modal-ok-button">
          {buttonText || (type === 'declineTransaction' ? 'Try Again' : 'OK')}
        </Button>
      </div>
    </Modal>
  );
}

export default PaymentErrorModal;
