import React from 'react';
import NotesContext from './NotesContext';

function NotesWrapper(props) {
  const {
    children,
    form,
    onEditorContentChange,
    editorBody,
    onSubmit,
    addButtonApiStatus,
    invalidDescriptionErrorMessage,
    onCancel,
    candidateListStatus,
    candidateDetailsStatus,
  } = props;

  return (
    <div>
      <NotesContext.Provider
        value={{
          form,
          onEditorContentChange,
          editorBody,
          onSubmit,
          addButtonApiStatus,
          invalidDescriptionErrorMessage,
          onCancel,
          candidateListStatus,
          candidateDetailsStatus,
        }}
      >
        {children}
      </NotesContext.Provider>
    </div>
  );
}

export default NotesWrapper;
