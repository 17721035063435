import React from 'react';
import AlertMessage from '../AlertMessage/AlertMessage';

export default function SmtpNotConfiguredWarning(props) {
  const { selectedEmailFrom, version } = props;
  const descriptionText =
    'Please go to Application Settings and link your email address to a SMTP configuration before sending emails from this account.';
  const messageText = `${selectedEmailFrom} is not currently linked to SMTP setting`;
  return (
    <AlertMessage
      message={messageText}
      description={descriptionText}
      showSettingsLink
      type="warning"
      isClosable={false}
      version={version}
    />
  );
}
