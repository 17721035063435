import React from 'react';
import { withRouter } from 'react-router-dom';
import BullhornAtsRedirectUrl from './BullhornAtsRedirectUrl';

function AtsRedirectUrlMapper(props) {
  const { history, location, match } = props;
  const { ats } = match.params;

  switch (ats.toLowerCase()) {
    case 'bullhorn':
      return <BullhornAtsRedirectUrl history={history} location={location} />;
    default:
  }
}

export default withRouter(AtsRedirectUrlMapper);
export { AtsRedirectUrlMapper as AtsRedirectUrlMapperWithoutRouter };
