import React from 'react';
import { Button } from 'antd';
import { UserVerificationIcon } from '../../Icons/AryaIcons';
import config from '../../Config/Config';
import style from './UserVerification.module.scss';

export default function UserVerificationSuccess() {
  return (
    <>
      <h1>Confirmation</h1>
      <UserVerificationIcon className={style.userVerificationIcon} />
      <h2>Congratulations!</h2>
      <p>Your account has been activated.</p>
      <Button
        id="lets-start-button"
        type="primary"
        onClick={() => {
          localStorage.clear();
          window.location.href = config.urls.verifiedRedirectUri;
        }}
      >
        Let&apos;s Start
      </Button>
    </>
  );
}
