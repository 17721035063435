import React from 'react';
import { Form, Radio } from 'antd';
import styles from './AdvancedJobDetailsSelection.module.scss';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export default function SecurityClearance(props) {
  const { form, securityClearance } = props;

  const handleRadioChange = event => {
    form.setFieldsValue({
      SecurityClearance: event?.target?.value,
    });
  };

  return (
    <div className={styles.securityClearanceWrapper}>
      <FormItem label="Security Clearance" colon={false}>
        {form.getFieldDecorator('SecurityClearance', {
          initialValue: securityClearance ?? null,
        })(
          <RadioGroup onChange={handleRadioChange}>
            <Radio value className="radio-select">
              Yes
            </Radio>
            <Radio value={false} className="radio-select">
              No
            </Radio>
            <Radio value={null} className="radio-select">
              Doesn&apos;t matter
            </Radio>
          </RadioGroup>
        )}
      </FormItem>
    </div>
  );
}
