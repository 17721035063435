import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { validateEmptyField } from '../../../Utils/FormValidators';
import './BaseTemplateForm.css';

/* eslint-disable class-methods-use-this */
export default class BaseTemplateForm extends Component {
  getTemplateNameItem(placeholder, errorMessage, initialValue, readOnly = false) {
    const { form, viewOnly } = this.props;
    return (
      <Form.Item>
        {form.getFieldDecorator('template-name', {
          rules: [
            {
              validator: (rule, value, callback) => validateEmptyField(value, callback, errorMessage),
              required: true,
            },
          ],
          initialValue,
        })(
          <Input className="create-template template-name" placeholder={placeholder} disabled={viewOnly || readOnly} />
        )}
      </Form.Item>
    );
  }

  getTestTemplateItem(buttonText) {
    return <Button className="create-template test">{buttonText}</Button>;
  }

  getSubjectItem(placeholder, errorMessage, initialValue, decoratorName, required) {
    const { form, viewOnly } = this.props;
    return (
      <Form.Item label={placeholder} colon={false}>
        {form.getFieldDecorator(decoratorName || 'template-subject', {
          rules: [
            {
              required: required !== false,
              message: errorMessage,
            },
          ],
          initialValue,
        })(<Input className="create-template subject-line" placeholder={placeholder} disabled={viewOnly} />)}
      </Form.Item>
    );
  }

  getBodyItem(errorMessage, initialValue, decoratorName, required) {
    const { form, viewOnly } = this.props;
    return (
      <Form.Item>
        {form.getFieldDecorator(decoratorName || 'template-body', {
          rules: [
            {
              required: required !== false,
              message: errorMessage,
            },
          ],
          initialValue,
        })(<Input.TextArea className="create-template body" rows={10} disabled={viewOnly} />)}
      </Form.Item>
    );
  }

  getCancelButton(buttonText, onClose, skEventName) {
    const { form } = this.props;
    const cancelButton = (
      <Button className="create-template cancel" onClick={onClose} sk-event-name={skEventName}>
        {buttonText}
      </Button>
    );

    return <Form.Item>{form.getFieldDecorator('template-cancel')(cancelButton)}</Form.Item>;
  }

  getSaveButton(buttonText, isDisable, skEventName) {
    const { form } = this.props;
    const saveButton = (
      <Button
        className="create-template save"
        htmlType="submit"
        type="primary"
        disabled={this.hasErrors(form.getFieldsError()) || isDisable}
        sk-event-name={skEventName}
      >
        {buttonText}
      </Button>
    );

    return <Form.Item>{form.getFieldDecorator('template-save')(saveButton)}</Form.Item>;
  }

  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
}

BaseTemplateForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsError: PropTypes.func,
  }).isRequired,
};
