import React, { /* useState, */ useEffect } from 'react';
import { /* Switch, */ Row, Col, Input, Select, Button, Form, Divider, Tag, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import styles from './MultilocationSettings.module.scss';
import SelectDropDownContent from '../../../../Components/Utils/SelectDropDownContent/SelectDropDownContent';
import { locationMenuList } from '../../../../Utils/LocationUtils';
import { validateEmptyField } from '../../../../Utils/FormValidators';
import { getLocationSectionConfig, getLocationSectionTooltipContent } from '../../../../Utils/MultiLocationUtils';

export const validateLocation = (rule, value, callback) => {
  if (!value?.length) callback('Location is required');
  else if (value?.length > 5) {
    callback('At max only 5 locations can be selected for a region');
  } else callback();
};
export const validateRegion = (rule, value, callback) => {
  validateEmptyField(value, callback, 'Region is required');
};

function MultiLocationSettings(props) {
  const {
    regions,
    form,
    fetchRegions,
    addRegion,
    fetchLocations,
    locations,
    deleteRegion,
    locationApiStatus,
    countryOptions,
    clearLocations,
  } = props;
  const [isCountryWideSearchCountry, setCountryWideSearchEnabledCountry] = React.useState(false);
  useEffect(() => {
    fetchRegions();
  }, []);

  const { Location: locationTooltipContent } = getLocationSectionTooltipContent({
    isCountryWideSearchEnabledCountry: isCountryWideSearchCountry,
  });
  const createRegion = newRegion => {
    const countryCode = newRegion.country;
    const currentLocations = newRegion.location?.map(x => ({
      Location: x,
      CountryCode: countryCode,
    }));
    const regionCreationPayload = { Regions: [{ RegionName: newRegion.name, Locations: currentLocations }] };
    addRegion({ regionCreationPayload });
  };
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        createRegion(values);
        form.resetFields();
      }
    });
  };
  const onChangeCountry = value => {
    if (!value) {
      form.setFieldsValue({
        states: undefined,
        cities: undefined,
      });
    } else {
      clearLocations();
      form.setFieldsValue({
        location: undefined,
      });
      const countryOption = [
        {
          CountryCode: value,
        },
      ];
      const { isCountryWideSearchEnabledCountry } = getLocationSectionConfig(countryOption, countryOptions);
      setCountryWideSearchEnabledCountry(isCountryWideSearchEnabledCountry);
    }
  };
  const debouncedSearchLocation = debounce(
    (searchTerm, country) => fetchLocations({ country, search: searchTerm }),
    600
  );

  const onSearchLocation = searchTerm => {
    const country = form.getFieldValue('country');
    debouncedSearchLocation(searchTerm, country);
  };

  const onDeleteRegion = id => {
    deleteRegion({ id });
  };
  const locationOptions = locations ? locationMenuList(locations) : [];

  return (
    <>
      <div className={styles.multiLocationSettingsTitle}>Multilocation</div>
      <div className={styles.multiLocationSettingsSubtitle}>Setup your sourcing criteria.</div>
      {/* <div className={styles.multiLocationSettingsDescription}>
        <span>Enable multilocation option for all jobs</span>
        <Switch checked={isMultilocationEnabled} onChange={() => setMultiLocationStatus(!isMultilocationEnabled)} />
      </div> */}
      <div /* className={!isMultilocationEnabled ? styles.disabledMultilocation : ''} */>
        <Form hideRequiredMark onSubmit={handleSubmit}>
          <Row gutter={12}>
            <Col span={5}>
              <Form.Item label="Region *" colon={false}>
                {form.getFieldDecorator('name', {
                  rules: [{ validator: validateRegion }],
                })(<Input placeholder="Name" size="large" />)}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Country *" colon={false}>
                {form.getFieldDecorator('country', {
                  initialValue: 'US',
                  rules: [{ required: true, messsage: 'Country is required' }],
                })(
                  <Select
                    className={styles.selectDropdown}
                    key="Country"
                    placeholder="Country"
                    optionFilterProp="children"
                    defaultActiveFirstOption={false}
                    onChange={onChangeCountry}
                    showSearch
                    size="large"
                  >
                    {countryOptions?.map(country => (
                      <Select.Option value={country.Iso2Code} key={country.Id}>
                        {country.Name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Tooltip title={locationTooltipContent}>
                <Form.Item label="Location *" colon={false}>
                  {form.getFieldDecorator('location', {
                    rules: [{ validator: validateLocation }],
                  })(
                    <Select
                      mode="multiple"
                      className={styles.selectDropdown}
                      placeholder="City, State"
                      notFoundContent=" "
                      onSearch={onSearchLocation}
                      disabled={isCountryWideSearchCountry}
                      dropdownClassName={styles.selectDropdown}
                      size="large"
                      dropdownRender={menuItems => {
                        return (
                          <SelectDropDownContent
                            menuItems={menuItems}
                            isLoading={locationApiStatus === 'INPROGRESS'}
                            text="loading auto suggestions..."
                          />
                        );
                      }}
                    >
                      {locationOptions?.map(optionValue => (
                        <Select.Option key={optionValue} value={optionValue}>
                          {optionValue}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title={locationTooltipContent}>
                <Button
                  type="primary"
                  shape="round"
                  className={styles.createButton}
                  htmlType="submit"
                  disabled={isCountryWideSearchCountry}
                >
                  Add Region
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
          {regions.map(region => (
            <>
              <Divider />
              <Row>
                <Col span={5} style={{ paddingLeft: '6px', paddingRight: '6px', fontSize: '16px' }}>
                  {region.RegionName}
                </Col>
                <Col span={5} style={{ paddingLeft: '6px', paddingRight: '6px', fontSize: '16px' }}>
                  {region.Locations?.[0]?.CountryCode}
                </Col>
                <Col span={10} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                  <div className={styles.locationTagsWrapper}>
                    {region.Locations?.map(location => (
                      <Tag className={styles.locationTags}>{location.Location}</Tag>
                    ))}
                  </div>
                </Col>
                <Col span={4} style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => onDeleteRegion(region.RegionId)}
                    onKeyPress={() => onDeleteRegion(region.RegionId)}
                    className={styles.deleteButton}
                  >
                    Delete
                  </div>
                </Col>
              </Row>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default Form.create()(MultiLocationSettings);
export { MultiLocationSettings as MultiLocationSettingsWithoutForm };
