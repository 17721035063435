import React from 'react';
import { Collapse } from 'antd';
import CandidateLocationIcon from '../../../Icons/CandidateLocationIcon';
import AdvancedJobLocationInsight from './AdvancedJobLocationInsight';
import styles from './AdvancedJobLocationInsight.module.scss';

const { Panel } = Collapse;

export default function AdvancedJobLocationPanel(props) {
  return (
    <div className={styles.locationInsightWrapper}>
      <Collapse expandIconPosition="right" accordion ghost>
        <Panel
          header={
            <div className={styles.infoWrapper}>
              <span>
                <CandidateLocationIcon className={styles.infoIcon} />
              </span>
              <span className={styles.infoText}>
                See the <span>&nbsp;5 top regions&nbsp;</span>that contain the most job candidates.
              </span>
            </div>
          }
          key="1"
        >
          <AdvancedJobLocationInsight {...props} />
        </Panel>
      </Collapse>
    </div>
  );
}
