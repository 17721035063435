import _ from 'lodash';
import {
  RESET_CAREER_PROJECTION,
  SET_CAREER_PROJECTION_DATA,
  SET_CAREER_ASSESSMENT_DATA,
} from '../Actions/ActionCreators/CareerProjectionActions';

const initialState = {
  CareerProjection: {
    CareerDistributionStats: {
      SimilarTitleStats: [],
    },
    Skills: [],
    Predictions: {
      Title: {
        FutureTitles: {
          Titles10Years: [],
          Titles5Years: [],
        },
      },
    },
  },
  CareerAssessment: {
    Skills: {
      Comparable: [],
      Missing: [],
    },
  },
};

function CareerProjectionReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case SET_CAREER_PROJECTION_DATA:
      newState = _.cloneDeep(initialState);
      newState.CareerProjection = action.payload;
      newState.CareerProjection.Skills = action.payload.Skills ?? [];
      return newState;
    case SET_CAREER_ASSESSMENT_DATA:
      newState = _.cloneDeep(state);
      newState.CareerAssessment = action.payload;
      return newState;
    case RESET_CAREER_PROJECTION:
      return initialState;
    default:
      return state;
  }
}

function getCareerProjectionList(state) {
  return state.CareerProjectionReducer.CareerProjection;
}

function getCareerAssessmentList(state) {
  return state.CareerProjectionReducer.CareerAssessment;
}

export { CareerProjectionReducer, getCareerProjectionList, getCareerAssessmentList };
