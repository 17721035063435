import React from 'react';
import { Icon } from 'antd';

export const RolesSvg = props => (
  <svg width={44} height={44} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={21} stroke="#979797" strokeWidth={2} />
      <path
        d="M20 27c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm12.707 4.293-5.683-5.682A8.943 8.943 0 0 0 29 20c0-4.962-4.038-9-9-9s-9 4.038-9 9 4.038 9 9 9c2.122 0 4.07-.742 5.61-1.975l5.683 5.682 1.414-1.414z"
        fill="#757575"
      />
    </g>
  </svg>
);

const RolesIcon = props => <Icon component={RolesSvg} {...props} />;

export default RolesIcon;
