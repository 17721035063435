import * as quickSearchFormUtils from '../Components/QuickSearchForm/QuickSearchFormUtils';

const getPortalQueries = (source, values) => {
  const portal = source.Portal.toLowerCase();
  let searchType;
  switch (portal) {
    case 'monster':
      searchType = source.SearchType;
      if (searchType?.toLowerCase() === 'classic') {
        return quickSearchFormUtils.getClassicMonsterPortalQueries(values);
      }
      if (searchType?.toLowerCase() === 'power') {
        return quickSearchFormUtils.getPowerMonsterPortalQueries(values);
      }
      break;
    case 'careerbuilder':
      return quickSearchFormUtils.getCareerBuilderPortalQueries(values);
    case 'careerbuilderedge':
      return quickSearchFormUtils.getCareerBuilderEdgePortalQueries(values);
    case 'dice':
      return quickSearchFormUtils.getDicePortalQueries(values);
    case 'clearancejobs':
      return quickSearchFormUtils.getClearanceJobsPortalQueries(values);
    case 'naukri':
      return quickSearchFormUtils.getNaukriPortalQueries(values);
    case 'social':
      return quickSearchFormUtils.getSocialPortalQueries(values);
    default:
      return {};
  }
  return {};
};

function resetPortalAdvanceOptionsFields(form, fields, seperator, sourceName) {
  form.resetFields(fields.filter(field => field.split(seperator)[0] === sourceName));
}

const resetAdvancedOptionFields = ({ source, form }) => {
  const values = form.getFieldsValue();
  const fields = Object.keys(values);
  const sourceName = source.Portal.toLowerCase();
  switch (sourceName) {
    case 'careerbuilder':
      resetPortalAdvanceOptionsFields(form, fields, '-', 'careerbuilder');
      break;
    case 'monster':
      resetPortalAdvanceOptionsFields(form, fields, '-', 'monster');
      break;
    case 'dice':
      resetPortalAdvanceOptionsFields(form, fields, '-', 'dice');
      break;
    case 'clearancejobs':
      resetPortalAdvanceOptionsFields(form, fields, '_', 'cj');
      break;
    case 'careerbuilderedge':
      resetPortalAdvanceOptionsFields(form, fields, '_', 'cbedge');
      break;
    case 'social':
      resetPortalAdvanceOptionsFields(form, fields, '_', 'social');
      break;
    default:
      break;
  }
};

function getPortalsDefaultValues(formFeilds, sourceName, seperator, callback) {
  const values = {};
  formFeilds
    .filter(field => field.split(seperator)[0] === sourceName)
    .forEach(option => {
      values[option] = callback(option);
    });
  return values;
}

const clearAdvancedOptionFields = ({ source, form }) => {
  const formValues = form.getFieldsValue();
  const fields = Object.keys(formValues);
  let values = {};
  const sourceName = source.Portal.toLowerCase();
  switch (sourceName) {
    case 'careerbuilder':
      values = getPortalsDefaultValues(
        fields,
        'careerbuilder',
        '-',
        quickSearchFormUtils.getCareerBuilderClassicDefaultValue
      );
      break;
    case 'careerbuilderedge':
      values = getPortalsDefaultValues(fields, 'cbedge', '_', quickSearchFormUtils.getCareerBuilderEdgeDefaultValue);
      break;
    case 'monster':
      values = getPortalsDefaultValues(fields, 'monster', '-', quickSearchFormUtils.getMonsterDefaultValue);
      break;
    case 'dice':
      fields
        .filter(field => field.split('-')[0] === 'dice')
        .forEach(option => {
          if (option.split('-')[1] === 'distance') values[option] = 50;
          else if (option.split('-')[1] === 'distanceunit') values[option] = 'Miles';
          else values[option] = quickSearchFormUtils.getDiceDefaultValue(option);
        });
      break;
    case 'clearancejobs':
      values = getPortalsDefaultValues(fields, 'cj', '_', () => {});
      break;
    case 'naukri':
      values = getPortalsDefaultValues(fields, 'Naukri', '_', option =>
        quickSearchFormUtils.getNaukriDefaultValue(option)
      );
      break;
    case 'social':
      values = getPortalsDefaultValues(fields, 'social', '_', option =>
        quickSearchFormUtils.getSocialDefaultValues(option)
      );
      break;
    default:
      break;
  }

  form.setFieldsValue(values);
};

export { getPortalQueries, resetAdvancedOptionFields, clearAdvancedOptionFields };
