import _ from 'lodash';
import * as connectRepository from '../Repository/ConnectRepository';

function fetchConnectStatus(conversationId, personId) {
  return dispatch => {
    if (!personId) {
      return connectRepository
        .fetchConversations(conversationId)
        .then(response => {
          dispatch({
            type: 'ADD_CONVERSATION_DETAILS',
            payload: [response.data],
          });
          return response.data.PersonId;
        })
        .then(_personId => {
          connectRepository.fetchConnectStatus(conversationId).then(connectStatusResponse => {
            dispatch({
              type: 'SET_CANDIDATE_CONNECT_INFO',
              payload: {
                connectInfo: {
                  [_personId]: connectStatusResponse.data,
                },
              },
            });
          });
        });
    }
    return connectRepository.fetchConnectStatus(conversationId).then(connectStatusResponse => {
      if (!_.isEmpty(connectStatusResponse.data))
        dispatch({
          type: 'SET_CANDIDATE_CONNECT_INFO',
          payload: {
            connectInfo: {
              [personId]: connectStatusResponse.data,
            },
          },
        });
    });
  };
}

export { fetchConnectStatus };
