function isInvalidDataError(orderStatus) {
  const invalidErrorCodeList = [
    'INVALID_NUMBER',
    'INVALID_CREDITS',
    'INVALID_DATE',
    'INVALID_BILLINGADDRESS',
    'INVALID_CVV',
  ];
  return invalidErrorCodeList.includes(orderStatus);
}

function isDuplicationError(orderStatus) {
  const duplicationErrorCodeList = ['CONFLICTING_CREATEPAYMENTCOMMAND', 'DUPLICATE_TRANSACTION'];
  return duplicationErrorCodeList.includes(orderStatus);
}

function isGatewayError(orderStatus) {
  const gatewayErrorCodeList = [
    'AUTHORIZENET_GATEWAY_ERROR',
    'INVALID_ITEMS',
    'INVALID_PROFILEID',
    'INVALID_PAYMENTCOMMAND',
  ];
  return gatewayErrorCodeList.includes(orderStatus);
}
function isUnhandledTaxJarError(status) {
  const taxJarApiStatusList = [
    'INPROGRESS',
    'PENDING',
    'COMPLETED',
    'DESTINATIONADDRESS_NOT_FOUND',
    'TAXJAR_GATEWAY_ERROR',
    'INVALID_STATE',
  ];
  return !taxJarApiStatusList.includes(status);
}

export { isGatewayError, isDuplicationError, isInvalidDataError, isUnhandledTaxJarError };
