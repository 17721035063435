import { setApiStatus } from '../ApiStatusActions';

export function setEmailSmtpSettingsApiStatus(status) {
  const apiName = 'emailSettingsApiStatus';
  return setApiStatus({ apiName, status });
}

export function setEmailConfigurationApiStatus(status) {
  const apiName = 'emailConfigurationApiStatus';
  return setApiStatus({ apiName, status });
}
