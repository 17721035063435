import React from 'react';
import _ from 'lodash';
import { Badge, Icon } from 'antd';

function getIcon(notificationType) {
  switch (notificationType) {
    case 'VaultJobFetched':
      return <Icon style={{ fontSize: 40, color: '#13C26B' }} type="check-circle" />;
    case 'VaultJobNotFound':
      return <Icon style={{ fontSize: 40, color: '#f5222d' }} type="close-circle" />;
    default:
      return null;
  }
}

function getMessage(notification) {
  switch (notification.Type) {
    case 'VaultJobFetched':
      return `Job ${_.get(notification, 'Payload.VaultJobId', '')} has been pulled successfully from ats`;
    case 'VaultJobNotFound':
      return `Job ${_.get(notification, 'Payload.VaultJobId', '')} is not existing in your ats`;
    default:
      return null;
  }
}

export default function JobPullNotification(props) {
  const { notification } = props;
  const { Type } = notification;
  const VaultJobId = _.get(notification, 'Payload.VaultJobId', '');
  return (
    <div className="notification" role="presentation">
      <div className="icon">{getIcon(Type)}</div>
      <div className="content">
        <div className="notification-title">{VaultJobId}</div>
        <div className="message">{getMessage(notification)}</div>
        <div className="actions" />
      </div>
      <div className="is-viewed">
        <Badge dot={notification.UnreadCount > 0} />
      </div>
    </div>
  );
}
