import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import config from '../../Config/Config';
import './CalendarContainer.scss';

const mapStateToProps = state => {
  return {
    whiteLabelInfo: getWhiteLabelInformation(state),
    currentUser: getCurrentUser(state),
  };
};

const mapDispatchToProps = {};

const calendarUrl = `${config.urls.aryaCalendar}`;

function CalendarContainer(props) {
  const { currentUser, whiteLabelInfo } = props;
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
  return (
    <div id="calendar-component">
      <iframe
        title="Calendar"
        className="calendar-iframe"
        src={`${calendarUrl}/?username=${
          currentUser ? currentUser.email : ''
        }&authToken=envvnfnkkmfmefkfe&app=arya&appname=${AppName}&refererHost=${window.location.hostname}`}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
