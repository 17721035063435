import _ from 'lodash';
import {
  RESET_GLOBAL_INSIGHT,
  SET_GLOBAL_SUPPLY_LOCATION_DATA,
  SET_GLOBAL_SUPPLY_LIST,
} from '../Actions/ActionCreators/SalesDashBoardActions';

const initialState = {
  CompaniesStats: [],
  CertificationsStats: [],
  EducationsStats: [],
  ExperienceRangeStats: [],
  IndustriesStats: [],
  SimilarTitlesStats: [],
  SkillsStats: [],
  States: [],
  Cities: [],
};

function SalesDashBoardReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case SET_GLOBAL_SUPPLY_LIST:
      newState = action.payload;
      newState.States = action.payload.States ?? [];
      newState.Cities = action.payload.Cities ?? [];
      return newState;
    case RESET_GLOBAL_INSIGHT:
      return initialState;
    case SET_GLOBAL_SUPPLY_LOCATION_DATA: {
      const { States, Cities, Total } = action.payload;
      newState = _.cloneDeep(state);
      newState.States = States ?? [];
      newState.Cities = Cities ?? [];
      newState.LocationSpecificProfileCount = Total;
      return newState;
    }
    default:
      return state;
  }
}

function getGlobalInsightSupplyList(state) {
  return state.SalesDashBoardReducer;
}

function getApiStatus(state, apiName) {
  return state.SalesDashBoardReducer[apiName];
}

export { SalesDashBoardReducer, getGlobalInsightSupplyList, getApiStatus };
