import TooltipText from './TooltipText';

function getPercentage(value, total) {
  if (total > 0) return (value / total) * 100;
  return 0;
}

function getNodePercentage({ activityStats, sourceNodes, completedInstances, targetNodeLabel }) {
  if (sourceNodes[0] === 'start node') {
    return 100;
  }
  let totalInstances = 0;
  sourceNodes.forEach(sourceNode => {
    const activityStat = activityStats.find(activity => activity.ActivityId === sourceNode);
    if (activityStat) {
      const { OutcomeStats, TotalInstances } = activityStat;
      if (targetNodeLabel.toLowerCase() === 'yes' || targetNodeLabel.toLowerCase() === 'no') {
        totalInstances = TotalInstances;
      } else {
        const totalDoneInstances = OutcomeStats.find(
          ({ ActivityOutcome }) => ActivityOutcome.toLowerCase() === targetNodeLabel.toLowerCase()
        );
        totalInstances += totalDoneInstances?.Count;
      }
    }
  });
  return getPercentage(completedInstances, totalInstances);
}

const TooltipNodeReportData = props => {
  const { completedInstances, activityStats, sourceNodes, targetNodeLabel } = props;
  if (activityStats?.length > 0) {
    const percentage = getNodePercentage({ activityStats, sourceNodes, completedInstances, targetNodeLabel });
    return TooltipText({ completedInstances, percentage });
  }
  return TooltipText({ completedInstances: 0, percentage: 0 });
};

export default TooltipNodeReportData;
