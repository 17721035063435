import React from 'react';
import { Slider } from 'antd';

export default class SourceLimitSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { jobId, fetchJobConfig } = this.props;
    if (jobId) {
      fetchJobConfig(jobId);
    }
  }

  render() {
    const { jobSourceLimit, defaultSourceLimit, maxLimit, changeSourceLimit, minSourceValue } = this.props;
    return (
      <Slider
        min={minSourceValue}
        max={maxLimit}
        value={jobSourceLimit || defaultSourceLimit}
        tooltipVisible
        className="job-form-source-limit-slider"
        onChange={changeSourceLimit}
        tooltipPrefixCls="source-limit-slider ant-tooltip"
      />
    );
  }
}
