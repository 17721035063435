import React from 'react';
import { Radio } from 'antd';
import MultiLocationContext from '../MultiLocationContext';

const PrimaryLocationRadio = () => {
  const { currentKey, changePrimaryLocation, isPrimaryLocation, isPrimaryLocationRadioDisabled } = React.useContext(
    MultiLocationContext
  );
  return (
    <Radio
      value={currentKey}
      onChange={event => changePrimaryLocation(event.target.value)}
      checked={isPrimaryLocation}
      disabled={isPrimaryLocationRadioDisabled}
    />
  );
};

export default PrimaryLocationRadio;
