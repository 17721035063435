import moment from 'moment';

const generateNotesDateTime = date => {
  return moment
    .utc(date)
    .local()
    .format('hh:mm A | MMM DD, YYYY');
};

export { generateNotesDateTime };
