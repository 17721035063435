import { SET_IS_QUOTE_REQUESTED_BY_USER } from '../Actions/ActionCreators/AoDActionCreator';

const initialState = {
  IsUserRequested: false,
};

export const AoDReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_QUOTE_REQUESTED_BY_USER:
      return { ...state, IsUserRequested: action.payload };
    default:
      return state;
  }
};

export function getQuoteRequestedByUser(state) {
  return state.AoDReducer.IsUserRequested;
}
