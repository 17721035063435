export const isCandidateIntelV2Data = data => {
  try {
    if (data) {
      const V2Keys = Object.keys(data);
      return V2Keys.some(item => data[item].Facts.length > 0);
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const formatExperience = experience => {
  if (experience < 1) {
    const experienceInMonths = Math.floor(experience * 12);
    return experienceInMonths === 1 ? `${experienceInMonths} month` : `${experienceInMonths} months`;
  }
  if (experience === 1) {
    return '1 year';
  }
  return `${experience} years`;
};
