import React from 'react';
import ChoosePlanIcon from '../../../Icons/ChoosePlanIcon';
import JobDetailIcon from '../../../Icons/JobDetailIcon';
import StartHiringIcon from '../../../Icons/StartHiringIcon';
import styles from './JobCreationFlowStepper.module.scss';

export default function JobCreationFlowStepper() {
  return (
    <div className={styles.jobLandigPageStepsWrapper}>
      <div className={styles.enterJobDetailStep}>
        <JobDetailIcon className={styles.labelIcon} />
        <div className={styles.labelText}>Create Job</div>
      </div>

      <div className={styles.choosePlanStep}>
        <ChoosePlanIcon className={styles.labelIcon} />
        <div className={styles.labelText}>Select a Plan</div>
      </div>

      <div className={styles.startHiringStep}>
        <StartHiringIcon className={styles.labelIcon} />
        <div className={styles.labelText}>Order</div>
      </div>
    </div>
  );
}
