import React from 'react';
import { Modal, InputNumber, Form } from 'antd';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { getProductVariantsByName } from '../../Utils/PlanSelectionUtils';
import { getJobDetailsForAnalytics } from '../../Utils/JobUtils';
import { SELECT_PLAN } from '../../PubSub/EventTypes';
import styles from './BundlePlanModal.module.scss';

const planTypeToValidationErrorKeyMapper = {
  Basic: 'basicPlanInputValidationError',
  Premium: 'premiumPlanInputValidationError',
};
const inputNumberMessage = 'Select the number of jobs for Full-Service:';
const footerMessage =
  'If you proceed with your selection, the job you currently created will be saved as a draft. You can go to the dashboard to access the draft job or continue with the plan.';

const DiscountContent = ({
  plan,
  unitPrice,
  count,
  maxUnits,
  discountPercentage,
  total,
  price,
  onChange,
  validateStatus,
  isPulsePromotionalOffer,
}) => {
  return (
    <div className={styles.bodyContent}>
      <div className={styles.innerBody}>
        <div className={styles.planContent}>
          <p className={styles.planText}> {plan}</p>
          <p className={styles.unitPrice}>(${unitPrice} per job)</p>
        </div>
        <div className={styles.bundleInput}>
          <Form.Item validateStatus={validateStatus ? 'error' : 'success'} help={validateStatus}>
            <InputNumber min={0} defaultValue={count} value={count} max={maxUnits} onChange={onChange} />
          </Form.Item>
        </div>
      </div>
      {discountPercentage ? (
        <div className={styles.discountContent}>
          <p>Discount Applied</p>{' '}
          <div
            className={`${styles.offerTag} ${
              discountPercentage === 20 ? styles.offerTag20Percent : styles.offerTag10Percent
            }`}
          >
            <p>{discountPercentage}% off</p>
          </div>
          <div className={styles.amount}>
            <p className={styles.discountedAmount}>${price}</p>
            <p className={styles.actualAmount}>${total}</p>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            { [styles.nonDiscountPromotionalOfferContent]: isPulsePromotionalOffer },
            { [styles.nonDiscountContent]: !isPulsePromotionalOffer }
          )}
        >
          <p>{total ? `$${total}` : ''}</p>
        </div>
      )}
    </div>
  );
};

const OfferBanner = ({ discountPercentage, jobsQuantityContent }) => {
  return (
    <div
      className={`${styles.offerBanner} ${
        discountPercentage === 20 ? styles.offerBanner20PercentColor : styles.offerBanner10PercentColor
      }`}
    >
      <p>{discountPercentage}% OFF</p>
      <p style={{ fontSize: '18px' }}>{jobsQuantityContent}</p>
    </div>
  );
};

class BundlePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicCount: 0,
      premiumCount: 0,
      basicPlanInputValidationError: '',
      premiumPlanInputValidationError: '',
    };
    this.fetchOrderDeatils = debounce(this.fetchOrderDeatils, 600);
  }

  componentDidMount() {
    const { basicQuantity, premiumQuantity } = this.props;
    if (basicQuantity || premiumQuantity) {
      this.setState({ basicCount: basicQuantity, premiumCount: premiumQuantity });
    }
  }

  componentDidUpdate(previousProps) {
    const { basicQuantity: prevBasicQuantity, premiumQuantity: prevPremiumQuantity } = previousProps;
    const { basicQuantity: currBasicQuantity, premiumQuantity: currPremiumQuantity } = this.props;

    if (
      (currBasicQuantity || currPremiumQuantity) &&
      (prevBasicQuantity !== currBasicQuantity || prevPremiumQuantity !== currPremiumQuantity)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          basicCount: currBasicQuantity,
          premiumCount: currPremiumQuantity,
        },
        this.fetchOrderDeatils
      );
    }
  }

  handleOk = () => {
    const { history, toggleBundlePlanModalVisibility, jobDetails } = this.props;
    const { premiumCount, basicCount } = this.state;
    const totalJobCount = premiumCount + basicCount;
    const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
    toggleBundlePlanModalVisibility();

    PubSub.publish(SELECT_PLAN, {
      jobId: jobDetails?.JobId,
      jobTitle: jobDetails?.JobTitle,
      isAdvanced: jobDetails?.IsAdvancedJob,
      quantity: totalJobCount,
      planName: 'Multiple Jobs',
      jobDetails: analyticsJobDetails,
    });
    history.push({
      pathname: '/payment',
      search: `?premium=${premiumCount}&basic=${basicCount}`,
    });
  };

  handleCancel = () => {
    const { toggleBundlePlanModalVisibility } = this.props;
    toggleBundlePlanModalVisibility();
  };

  onPremiumCountChange = value => {
    const { basicCount } = this.state;

    this.validateFieldValue(value, basicCount, 'Premium');
    if ((Number.isInteger(value) && value >= 0 && value + basicCount <= 100) || value === '') {
      this.setState(
        {
          premiumCount: value || 0,
          premiumPlanInputValidationError: '',
        },
        this.fetchOrderDeatils
      );
    }
  };

  fetchOrderDeatils = () => {
    const { premiumCount, basicCount } = this.state;
    const { fetchTotalAmountForProductvariants, productVariantsById, resetOrderDetailsWithoutTax } = this.props;
    const product = getProductVariantsByName(productVariantsById);
    const items = [];
    if (basicCount) {
      items.push({
        productvariantId: product.Basic.Id,
        quantity: basicCount,
      });
    }
    if (premiumCount) {
      items.push({
        productvariantId: product.Premium.Id,
        quantity: premiumCount,
      });
    }
    if (items.length) {
      fetchTotalAmountForProductvariants({
        items,
        IsTaxCalcuationRequired: false,
      });
    } else {
      resetOrderDetailsWithoutTax();
    }
  };

  validateFieldValue = (value, count, type) => {
    const isInteger = Number.isInteger(value);
    if ((isInteger && (value < 0 || value > 100)) || (value && !isInteger)) {
      this.setState({ [planTypeToValidationErrorKeyMapper[type]]: 'Jobs must be number between 0 and 100' });
    } else if (isInteger && value + count > 100) {
      this.setState({ [planTypeToValidationErrorKeyMapper[type]]: 'Maximum of 100 jobs total allowed' });
    }
  };

  onBasicCountChange = value => {
    const { premiumCount } = this.state;
    this.validateFieldValue(value, premiumCount, 'Basic');
    if ((Number.isInteger(value) && value >= 0 && value + premiumCount <= 100) || value === '') {
      this.setState(
        {
          basicCount: value || 0,
          basicPlanInputValidationError: '',
        },
        this.fetchOrderDeatils
      );
    }
  };

  render() {
    const { basicCount, premiumCount, basicPlanInputValidationError, premiumPlanInputValidationError } = this.state;
    const {
      modalVisibility,
      productVariantsById = {},
      orderDetails,
      fetchTotalAmountForProductvariantApiStatus,
      isPulsePromotionalOffer,
    } = this.props;
    const product = getProductVariantsByName(productVariantsById);
    const isBasicPlanHidden = product?.Basic?.IsHidden;

    const basicUnitPrice = _.get(product, ['Basic', 'UnitPrice']);
    const basicProductVarientId = _.get(product, ['Basic', 'Id']);
    const premiumProductVarientId = _.get(product, ['Premium', 'Id']);
    const premiumUnitPrice = _.get(product, ['Premium', 'UnitPrice']);
    const orderItemById = _.get(orderDetails, ['orderItemById'], {});

    const basicTotal = _.get(orderItemById, [basicProductVarientId, 'Amount', 'Total'], 0);
    const basicPrice = _.get(orderItemById, [basicProductVarientId, 'Amount', 'Price'], 0);
    const basicDiscount = _.get(orderItemById, [basicProductVarientId, 'Amount', 'Discount'], 0);
    const premiumTotal = _.get(orderItemById, [premiumProductVarientId, 'Amount', 'Total'], 0);
    const premiumPrice = _.get(orderItemById, [premiumProductVarientId, 'Amount', 'Price'], 0);
    const premiumDiscount = _.get(orderItemById, [premiumProductVarientId, 'Amount', 'Discount'], 0);
    const basicDiscountPercentage = (basicDiscount / basicPrice) * 100;
    const premiumDiscountPercentage = (premiumDiscount / premiumPrice) * 100;

    return (
      <Modal
        title="How many Jobs do you want to buy?"
        visible={modalVisibility}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{
          shape: 'round',
          disabled: !(basicCount || premiumCount) || premiumPlanInputValidationError || basicPlanInputValidationError,
        }}
        cancelButtonProps={{ shape: 'round' }}
        okText="Next"
        confirmLoading={fetchTotalAmountForProductvariantApiStatus === 'INPROGRESS'}
        width={580}
        maskClosable={false}
        zIndex={2100}
        destroyOnClose
        centered
      >
        <div className={styles.container}>
          {!isPulsePromotionalOffer ? (
            <div className={styles.primaryHeaderContent}>
              <OfferBanner discountPercentage={10} jobsQuantityContent="2 to 10 jobs" />
              <OfferBanner discountPercentage={20} jobsQuantityContent="More than 10 jobs" />
            </div>
          ) : null}
          <div className={styles.container}>
            <div className={styles.secondaryHeaderContent}>
              <p>{inputNumberMessage}</p>
            </div>
            {!isBasicPlanHidden ? (
              <>
                <DiscountContent
                  plan="Basic"
                  unitPrice={basicUnitPrice}
                  count={basicCount}
                  maxUnits={100 - premiumCount}
                  discountPercentage={basicDiscountPercentage}
                  total={basicTotal}
                  price={basicPrice}
                  onChange={this.onBasicCountChange}
                  validateStatus={basicPlanInputValidationError}
                />
                <div className={styles.border}></div>
              </>
            ) : null}
            <DiscountContent
              plan="Full-Service"
              unitPrice={premiumUnitPrice}
              count={premiumCount}
              maxUnits={100 - basicCount}
              discountPercentage={premiumDiscountPercentage}
              total={premiumTotal}
              price={premiumPrice}
              onChange={this.onPremiumCountChange}
              validateStatus={premiumPlanInputValidationError}
              isPulsePromotionalOffer={isPulsePromotionalOffer}
            />
            {isPulsePromotionalOffer ? (
              <div className={styles.promotionalOfferContent}>
                <span style={{ fontWeight: 'bolder' }}> Special Offer: </span>
                Purchase 1 job credit & get 1 job credit for FREE. Offer valid from Dec 01 - 15, 2022. Click &nbsp;
                <a target="blank" href="https://leoforce.com/offer-terms-conditions">
                  here
                </a>
                &nbsp; for more info.
              </div>
            ) : null}
            <div className={styles.contactUsContent}>
              <a href="https://goarya.com/arya-pulse-bundling/" target="_blank" rel="noopener noreferrer">
                Contact Us for More Offers
              </a>
            </div>
          </div>
          <div className={styles.bundlePlanModalFooterContent}>{footerMessage}</div>
        </div>
      </Modal>
    );
  }
}

export default BundlePlanModal;
