import React from 'react';
import _ from 'lodash';
import { Skeleton } from 'antd';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import * as JobActions from '../../../Actions/JobActions';
import * as CandidateActions from '../../../Actions/CandidateActions';
import * as PaymentActions from '../../../Actions/AryaPayActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getUsersById } from '../../../Reducers/UserReducer';
import { getCurrentUser, getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import { getAdvancedJobFormData, getJobsById } from '../../../Reducers/JobReducer';
import { getSampleCandidatesDisplayFlag } from '../../../Reducers/JobStatusReducer';
import {
  getProductVariants,
  getProductVariantsById,
  getPlanDetailsByProductVariantId,
} from '../../../Reducers/AryaPayOrderReducer';
import { getCreateOrderApiStatus } from '../../../Reducers/AryaPayReducer';
import { getGtagPayload, getIsFreeCandidateStepper } from '../../../Utils/JobUtils';
import { ADVANCED_JOB_SAVE_DRAFT, SAVE_JOB, FREE_JOB_SUBMITTED } from '../../../PubSub/EventTypes';
import { FREE_TRIAL } from '../../../Utils/PlanSelectionUtils';
import * as PayOrderActions from '../../../Actions/AryaPayOrderActions';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../../Utils/CreditInfoUtils';
import { LINK_TO_JOBS, getPlanSelectionLink, getJobCandidateLink } from '../../../Utils/LinkUtils/LinkUtils';
import { setCandidatesViewedStats } from '../../../Utils/PubSubUtils';
import AdvancedJobEditHeaderSection from '../../../Components/AdvancedJobCreation/AdvancedJobEditHeaderSection/AdvancedJobEditHeaderSection';
import AdvancedJobDetailsReview from '../../../Components/AdvancedJobCreation/AdvancedJobDetailsReview/AdvancedJobDetailsReview';
import ConfirmationDialogueBox from '../../../Components/AdvancedJobCreation/ConfirmationDialogueBox/ConfirmationDialogueBox';
import styles from '../../../Components/AdvancedJobCreation/AdvancedJobDetailsEdit/AdvancedJobDetailsEdit.module.scss';

const mapStateToProps = state => {
  return {
    advancedJobFormData: getAdvancedJobFormData(state),
    jobsById: getJobsById(state),
    usersById: getUsersById(state),
    editJobStatus: getApiStatus(state, 'editJobStatus'),
    jobDetailsStatus: getApiStatus(state, 'jobDetailsStatus'),
    showSampleCandidates: getSampleCandidatesDisplayFlag(state),
    currentUser: getCurrentUser(state),
    productVariants: getProductVariants(state),
    createOrderApiStatus: getCreateOrderApiStatus(state),
    productVariantsById: getProductVariantsById(state),
    planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
    currentUserDetails: getCurrentUserDetails(state),
    featureToggleList: getFeatureToggleList(state),
  };
};

const mapDispatchToProps = {
  setAdvancedJobFormData: JobActions.setAdvancedJobFormData,
  fetchJobDetails: JobActions.fetchJobDetails,
  downloadJobResume: JobActions.downloadJobResume,
  putEditJob: JobActions.putEditJob,
  fetchTryNowCandidates: CandidateActions.fetchTryNowCandidates,
  setTrynowFormData: JobActions.setTrynowFormData,
  createOrder: PaymentActions.createOrder,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
};

class AdvancedJobDetailsReviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogueBoxVisibility: false,
      currentJobData: {},
    };
    this.placeJob = this.placeJob.bind(this);
  }

  componentDidMount() {
    const { fetchJobDetails, match, fetchPlanDetailsByProductVariantId } = this.props;
    fetchJobDetails(match.params.jobId);
    fetchPlanDetailsByProductVariantId();
  }

  componentDidUpdate(prevProps) {
    const { match, history, jobsById } = this.props;
    const { jobId } = match.params;
    if (!_.isEqual(prevProps.jobsById, jobsById)) {
      const errorCode = jobsById?.[jobId]?.ErrorCode;
      if (errorCode === 403) {
        history.push(`/jobs/${match.params.jobId}/forbidden`);
      }
      if (errorCode === 404) {
        history.push(`/jobs/${match.params.jobId}/notfound`);
      }
    }
  }

  toggleConfirmationDialogueBoxVisibility = () => {
    const { confirmationDialogueBoxVisibility } = this.state;
    this.setState({
      confirmationDialogueBoxVisibility: !confirmationDialogueBoxVisibility,
    });
  };

  raiseGtmEvents = (jobDetails, orderResponse) => {
    const gtagPayload = getGtagPayload(jobDetails);
    const gtagPayloadForCheckout = { ...gtagPayload, quantity: 1, planName: FREE_TRIAL, price: 0 };
    const gtagPayloadForPurchase = {
      ...gtagPayloadForCheckout,
      invoiceNumber: orderResponse?.InvoiceNumber,
      tax: orderResponse?.Amount?.Tax,
    };
    PubSub.publish(SAVE_JOB, gtagPayload);
    PubSub.publish(FREE_JOB_SUBMITTED, gtagPayloadForPurchase);
  };

  async placeJob(jobDetails, resumeUpdateDetails) {
    const { putEditJob, createOrder, history, productVariants, currentUserDetails } = this.props;
    const freeTrialVariant = productVariants.filter(productVariant => productVariant.Name === FREE_TRIAL);
    const jobData = await putEditJob(jobDetails?.JobId, jobDetails, null, resumeUpdateDetails);
    const jobGuid = jobData.JobGuid;
    const jobId = jobData.JobId;
    const paymentData = {
      cardDetails: null,
      paymentProfileId: null,
      address: null,
    };
    const items = [
      {
        ProductVariantId: freeTrialVariant[0].Id,
        Quantity: 1,
        CreditsToUse: 1,
        RefIds: [jobGuid],
      },
    ];
    const orderResponse = await createOrder(items, paymentData, 'immediate');
    const orderResponseData = orderResponse.data;
    this.raiseGtmEvents(jobData, orderResponseData);
    const jobCandidateLink = getJobCandidateLink(jobId);
    history.push(jobCandidateLink);
    setCandidatesViewedStats([], currentUserDetails?.UserGuid);
  }

  showConfirmationDialogueBox = jobDetails => {
    const { confirmationDialogueBoxVisibility } = this.state;
    if (!confirmationDialogueBoxVisibility) {
      this.setState({ currentJobData: jobDetails });
      this.toggleConfirmationDialogueBoxVisibility();
    }
  };

  editJob = async (jobDetails, resumeUpdateDetails, submitAction) => {
    const {
      putEditJob,
      history,
      showSampleCandidates,
      planDetailsByProductVariantId,
      productVariantsById,
    } = this.props;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
    const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
    if (submitAction === 'draft') {
      const jobData = await putEditJob(jobDetails?.JobId, jobDetails, null, resumeUpdateDetails);
      const gtagPayload = getGtagPayload(jobData);
      PubSub.publish(ADVANCED_JOB_SAVE_DRAFT, gtagPayload);
      history.push(LINK_TO_JOBS);
    } else if (isFirstJobFree) {
      this.showConfirmationDialogueBox(jobDetails);
    } else {
      const jobData = await putEditJob(jobDetails?.JobId, jobDetails, null, resumeUpdateDetails);
      const jobId = jobData.JobId;
      const jobGuid = jobData.JobGuid;
      const gtagPayload = getGtagPayload(jobData);
      PubSub.publish(SAVE_JOB, gtagPayload);
      const planSelectionLink = getPlanSelectionLink(jobId, jobGuid);
      history.push(planSelectionLink);
    }
  };

  render() {
    const { confirmationDialogueBoxVisibility, currentJobData } = this.state;
    const {
      jobsById,
      match,
      showSampleCandidates,
      createOrderApiStatus,
      editJobStatus,
      planDetailsByProductVariantId,
      productVariantsById,
      featureToggleList,
    } = this.props;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
    const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
    const jobDetails = jobsById[match.params.jobId];
    const isLoading = _.isEmpty(jobDetails);
    const jobPlaceLoading = createOrderApiStatus === 'PENDING' || editJobStatus === 'INPROGRESS';
    return (
      <Skeleton className={styles.loaderWrapper} active paragraph={{ rows: 12 }} loading={isLoading}>
        <AdvancedJobEditHeaderSection
          title="Job summary"
          subTitle="Review or edit the job summary below before you proceed"
          activeStep={0}
          isAdvancedJob
          isFirstJobFree={isFirstJobFree}
        />
        <AdvancedJobDetailsReview
          {...this.props}
          handleSubmit={this.editJob}
          jobDetails={jobDetails || {}}
          featureToggleList={featureToggleList}
        />
        <ConfirmationDialogueBox
          confirmationDialogueBoxVisibility={confirmationDialogueBoxVisibility}
          onCancel={this.toggleConfirmationDialogueBoxVisibility}
          onPlaceJob={this.placeJob}
          isLoading={jobPlaceLoading}
          jobDetails={currentJobData}
        />
      </Skeleton>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedJobDetailsReviewContainer);
export { AdvancedJobDetailsReviewContainer as AdvancedJobDetailsReviewContainerWithoutStore };
