import React, { Component } from 'react';
import _ from 'lodash';
import { Tag, Popover, Tooltip } from 'antd';
import { connect } from 'react-redux';
import './JobTags.scss';
import { getSelectedTags } from '../../Reducers/JobReducer';
import TagsDisplayComponent from '../../Components/TagsDisplayComponent/TagsDisplayComponent';

const mapStateToProps = state => ({
  selectedTags: getSelectedTags(state),
});

class JobTags extends Component {
  getExtraTags = tags => {
    return (
      <div className="hidden-tags">
        {tags.map(tag => {
          const isLongTag = tag.length > 20;
          return <p>{isLongTag ? <Tooltip title={tag} key={tag}>{`${tag.slice(0, 20)}...`}</Tooltip> : tag}</p>;
        })}
      </div>
    );
  };

  render() {
    const { tags, selectedTags } = this.props;
    const currentSelectedTags = (selectedTags || []).map(tag => tag.Value);
    const highLightedTags = _.intersection(currentSelectedTags, tags);
    let tagsToBeShown = tags;
    const hiddenTags = (tagsToBeShown || []).slice(6);
    if (tagsToBeShown && tagsToBeShown.length > 6 && !currentSelectedTags.length)
      tagsToBeShown = tagsToBeShown.slice(0, 6);

    return (
      <div className="job-tags">
        {(tagsToBeShown || []).map(tag => {
          return <TagsDisplayComponent
              className={`${_.find(selectedTags, { Value: tag }) ? `selected-job-tag` : ``} `}
              tag={tag}
            />;
        })}

        {hiddenTags.length && !highLightedTags.length ? (
          <Popover
            content={this.getExtraTags(hiddenTags)}
            overlayClassName="job-tag-popover"
            placement="bottomRight"
            // trigger="click"
          >
            <Tag type="primary" className="job-card-dropdown-tag">
              +
              {hiddenTags.length}
            </Tag>
          </Popover>
        ) : null}
      </div>
    );
  }
}

export { JobTags as JobTagsContainerWithoutStore };
export default connect(mapStateToProps)(JobTags);
