import React from 'react';
import { Modal } from 'antd';

export default function DeleteReportQueryModal(props) {
  const { queryDetails, isVisible, onCancel, onDelete, deleteQueryApiStatus } = props;
  const queryName = queryDetails?.ReportName ?? '';

  const onDeleteClick = () => {
    onDelete(queryDetails?.SavedReportId);
  };

  return (
    <Modal
      title="Delete Query"
      okText="Delete"
      visible={isVisible}
      onOk={onDeleteClick}
      onCancel={onCancel}
      okButtonProps={{
        loading: deleteQueryApiStatus?.status?.toUpperCase() === 'INPROGRESS',
        shape: 'round',
        type: 'danger',
        ghost: true,
      }}
      cancelButtonProps={{ shape: 'round' }}
      centered
      maskClosable={false}
    >
      {`Are you sure you want to delete ${queryName} query?`}
    </Modal>
  );
}
