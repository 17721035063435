import React from 'react';
import { Popover } from 'antd';
import _ from 'lodash';
import { FormCheckboxGroupComponent } from '../../Utils/FormComponentUtils';
import { generateValueCountFromInputs } from '../../Utils/MapperUtils';
import { RightArrowIcon } from '../../Icons/AryaIcons';
import './FormPanelItem.scss';

export default function FormPanelItem(props) {
  const { form, label, inputValues, fieldDecoratorValue, initialValue } = props;
  React.useEffect(() => {
    const currentFormFieldValue = form.getFieldValue(fieldDecoratorValue);
    if (initialValue !== undefined && !_.isEqual(initialValue, currentFormFieldValue)) {
      form.setFieldsValue({ [fieldDecoratorValue]: initialValue });
    }
  }, []);
  const currentSelectedFiltersCount = form.getFieldValue(fieldDecoratorValue)?.length;
  return (
    <Popover
      trigger={['click']}
      overlayClassName="form-panel-item-popover"
      content={
        <FormCheckboxGroupComponent
          fieldDecoratorValue={fieldDecoratorValue}
          label={label}
          form={form}
          inputValues={generateValueCountFromInputs({ inputValues, maxLengthOfValue: 20, maxCount: 1000 })}
          initialValue={initialValue}
        />
      }
      placement="leftBottom"
    >
      <div className={`form-panel-item ${currentSelectedFiltersCount ? 'selected-form-panel-item' : null}`}>
        <span>
          {label} {currentSelectedFiltersCount ? `(${currentSelectedFiltersCount})` : null}
        </span>
        <RightArrowIcon />
      </div>
    </Popover>
  );
}
