import React from 'react';
import { Collapse } from 'antd';
import styles from './PaymentCompanyInfoPanel.module.scss';

const { Panel } = Collapse;

export default function PlaceOrderTermsOfUse() {
  return (
    <div className={styles.termsOfUseWrapper}>
      <Collapse expandIconPosition="right" accordion ghost>
        <Panel
          header={
            <div className={styles.termsOfUseHeader}>
              Please read the
              <a href="https://login.leoforce.com/leo/signup/usageagreement" target="_blank" rel="noopener noreferrer">
                &nbsp;Terms of Use
              </a>{' '}
              Carefully.
            </div>
          }
          key="1"
        >
          <div className={styles.paymentAgreement}>
            <p>
              By clicking ‘Place Order’ you agree that Leoforce may publish and/or distribute your job posting on its
              sites and through its distribution partners.
            </p>
            <p>
              Leoforce reserves the right to reject job postings that are not bonafide recruitment ads, or for any other
              reason, we consider not in the best interest of Leoforce.
            </p>
            <p>
              Please read the
              <a href="https://login.leoforce.com/leo/signup/usageagreement" target="_blank" rel="noopener noreferrer">
                &nbsp;Terms of Use
              </a>
              {` carefully. Note that once you place your order, the cost is non-refundable and
          you agree that you have read and reviewed, in its entirety, the Leoforce Terms of Use and agree to be bound by
          its terms and conditions. If you do not agree to be bound by the Leoforce Terms of Use, please DO NOT click
          the Place Order button. Leoforce may revise these Terms at any time by posting an updated version to this URL.
          You should visit this URL periodically to review the most current Terms because they are binding on You.`}
              <br />
              Please click Place order only once to avoid being charged multiple times.
            </p>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
