import React from 'react';
import { Modal, Button } from 'antd';
import styles from './ScoutingAgent.module.scss';

const contentStyle = {
  margin: 0,
  color: 'black',
};

const WarningModal = props => {
  const { showWarningModal, setWarningModal, setScoutingAgentDrawerVisibility } = props;
  const closeModal = () => setWarningModal(false);
  const handleScoutingAgentList = () => {
    setWarningModal(false);
    setScoutingAgentDrawerVisibility(true);
  };
  return (
    <Modal
      title={
        <div className={styles.titleContainer}>
          <h3>Scouting Agents</h3>
        </div>
      }
      footer={
        <div className={styles.footerContainer}>
          <Button shape="round" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleScoutingAgentList}>
            Go to Scouting Agent List
          </Button>
        </div>
      }
      onCancel={closeModal}
      onOk={closeModal}
      visible={showWarningModal}
    >
      <p style={contentStyle}>
        This job already contains 5 scouting agents. Please stop or delete existing scouting agents to create new{' '}
      </p>
    </Modal>
  );
};

export default WarningModal;
