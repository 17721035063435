import React from 'react';
import { Modal, Button } from 'antd';
import { QuestionIcon } from '../../../Icons/AryaIcons';
import './BotPrequalPopup.scss';

function BotPrequalPopup(props) {
  const { botPrequalPopupVisibility, onClickSkip, onClickSave } = props;
  return (
    <Modal
      title={null}
      visible={botPrequalPopupVisibility}
      footer={null}
      zIndex={2050}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <div className="bot-prequal-wrapper">
        <QuestionIcon style={{ fontSize: '200px' }} />
        <div className="bot-prequal-text">Which questions would you like our AI to ask candidates about this job?</div>
        <div className="button-wapper">
          <Button className="skip-button" onClick={onClickSkip}>
            Skip
          </Button>
          <Button className="continue-button" type="primary" onClick={onClickSave}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
}
export default BotPrequalPopup;
