import { setApiStatus } from '../ApiStatusActions';

export const SET_GLOBAL_COUNTRIES = 'SET_GLOBAL_COUNTRIES';
export const SET_STATE_SUGGESTIONS = 'SET_STATE_SUGGESTIONS';
export const SET_CITY_SUGGESTIONS = 'SET_CITY_SUGGESTIONS';
export const SET_GLOBAL_LOCATIONS = 'SET_GLOBAL_LOCATIONS';
export const FETCH_LOCATION_API_STATUS = 'FETCH_LOCATION_API_STATUS';
export const SET_CITY_AND_STATE_SUGGESTIONS = 'SET_CITY_AND_STATE_SUGGESTIONS';
export const SET_LOCATION_VALIDATION_ERROR = 'SET_LOCATION_VALIDATION_ERROR';
export const setGlobalCountries = payload => ({
  type: SET_GLOBAL_COUNTRIES,
  payload,
});

export const setStateSuggestionsApiStatus = status => {
  const apiName = 'stateSuggestionsApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCitySuggestionsApiStatus = status => {
  const apiName = 'citySuggestionsApiStatus';
  return setApiStatus({ apiName, status });
};

export const setStateSuggestions = payload => ({
  type: SET_STATE_SUGGESTIONS,
  payload,
});

export const setCitySuggestions = payload => ({
  type: SET_CITY_SUGGESTIONS,
  payload,
});

export const setGlobalLocations = payload => ({
  type: SET_GLOBAL_LOCATIONS,
  payload,
});

export const setLocationsApiStatus = status => ({
  type: FETCH_LOCATION_API_STATUS,
  payload: status,
});
export const setCityAndStateSuggestions = payload => ({
  type: SET_CITY_AND_STATE_SUGGESTIONS,
  payload,
});
export const setLocationValidationError = payload => ({
  type: SET_LOCATION_VALIDATION_ERROR,
  payload,
});
