/* eslint-disable no-console */
import { Auth } from 'aws-amplify';
import io from 'socket.io-client';
import * as signalR from '@microsoft/signalr';

import Config from '../Config/Config';
import EventHandlers from './SocketEventHandler';

/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-04-30 03:10:52
 * @modify date 2019-04-30 03:10:52
 * @desc Connection manager for websockets - Chat, SMS, Notifications
 */

// eslint-disable-next-line import/no-mutable-exports
let chatConnection = null;
// eslint-disable-next-line import/no-mutable-exports
let notificationConnection = null;

function setupNotificationConnection(dispatch, token) {
  const socket = io(Config.urls.aryaNotifySocketEndpoint, {
    query: {
      token,
      AppId: Config.appId,
    },
    transports: ['websocket'],
  });
  socket.connect();
  socket.on('announcement', newAnnouncement => {
    EventHandlers.handleAnnouncementEvent(dispatch, newAnnouncement);
  });
  socket.on('notification', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'notification', newNotification);
  });
  socket.on('GetNotificationsResponse', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'GetNotificationsResponse', newNotification);
  });
  socket.on('NotificationRead', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'NotificationRead', newNotification);
  });
  socket.on('NotificationsRead', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'NotificationsRead', newNotification);
  });
  socket.on('error', error => {
    console.error('Socket connection failed', error);
  });
  socket.on('reconnect', attempt => {
    console.error('Successfully reconnected', attempt);
  });
  socket.on('reconnect_error', error => {
    console.error('Error occured while trying to reconnect', error);
  });
  socket.on('reconnect_failed', error => {
    console.error('Failed to reconnect', error);
  });
  return socket;
}

function setupChatConnection(dispatch, token) {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${Config.urls.aryaConnectChat}?access_token=${token}`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();
  connection.on('ReceiveChatMessage', payload => EventHandlers.handleChatEvent(connection, dispatch, 'chat', payload));
  connection.on('ReceiveSMSMessage', payload => EventHandlers.handleChatEvent(connection, dispatch, 'sms', payload));
  // connection.on('ReceiveEmail', payload => EventHandlers.handleEmailEventFromConnect(connection, dispatch, payload));
  connection
    .start()
    .then(() => {
      console.log('ChatHub Connected');
    })
    .catch(err => {
      console.error('ChatHub Connection failed due to', err, '. Retrying... in 5sec');
      chatConnection = null;
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(setupSocketConnections()), 5000);
    });
  connection.onclose(res => {
    const { message } = res;
    chatConnection = null;
    if (message === 'WebSocket closed with status code: 1006 ().') {
      console.log('Chathub disconnected due to ', res, '. Reconnecting... immediately');
      // eslint-disable-next-line no-use-before-define
      dispatch(setupSocketConnections());
      // eslint-disable-next-line no-use-before-define
    } else {
      console.log('Chathub disconnected due to ', res, '. Reconnecting... in 5sec');
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(setupSocketConnections()), 5000);
    }
  });
  return connection;
}

function setupSocketConnections() {
  return dispatch => {
    if (chatConnection && notificationConnection) {
      return;
    }
    Auth.currentSession().then(session => {
      const { jwtToken: token } = session.getIdToken();
      if (!token) {
        return;
      }
      if (!chatConnection) {
        chatConnection = setupChatConnection(dispatch, token);
      }
      if (!notificationConnection) {
        notificationConnection = setupNotificationConnection(dispatch, token);
      }
    });
  };
}

function getChatConnection(dispatch) {
  if (chatConnection) {
    return Promise.resolve(chatConnection);
  }
  return Auth.currentSession().then(session => {
    const { jwtToken: token } = session.getIdToken();
    if (!token) {
      return Promise.reject();
    }
    chatConnection = setupChatConnection(dispatch, token);
    return Promise.resolve(chatConnection);
  });
}

function closeSocketConnection() {
  if (chatConnection) {
    chatConnection.stop();
    chatConnection = null;
  }
  if (notificationConnection) {
    notificationConnection.close();
    notificationConnection = null;
  }
}

export { setupSocketConnections, chatConnection, notificationConnection, getChatConnection, closeSocketConnection };
