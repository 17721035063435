import { defineMessages } from 'react-intl';

const messages = defineMessages({
  candidateHighKeyWordsMatch: {
    id: 'Candidate.highkeywordsmatch',
    defaultMessage: 'KeyWords',
  },
  candidatePast: {
    id: 'Candidate.past',
    defaultMessage: 'Past',
  },
  candidateEdu: {
    id: 'Candidate.edu',
    defaultMessage: 'Edu.',
  },
  candidateCall: {
    id: 'Candidate.call',
    defaultMessage: 'Call',
  },
  candidateEmail: {
    id: 'Candidate.email',
    defaultMessage: 'Email',
  },
  candidateSms: {
    id: 'Candidate.sms',
    defaultMessage: 'SMS',
  },
  occupation: {
    id: 'Candidate.occupation',
    defaultMessage: 'Occupation',
  },
  industry: {
    id: 'Candidate.industry',
    defaultMessage: 'Industry',
  },
});

export default messages;
