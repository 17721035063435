import { connectV1Client } from './BaseRepository';

export function getDripTemplates(from, size) {
  return connectV1Client.get('dripTemplates', {
    params: {
      from,
      size,
    },
  });
}

export function searchDripTemplates(filter) {
  return connectV1Client.post('dripTemplates/_search', filter);
}

export function postDripTemplate(template) {
  return connectV1Client.post('dripTemplates', template);
}

export function getDripTemplate(templateId) {
  return connectV1Client.get(`dripTemplates/${templateId}`);
}

export function deleteDripTemplate(templateId) {
  return connectV1Client.delete(`dripTemplates/${templateId}`);
}

export function putDripTemplate(templateId, template) {
  return connectV1Client.put(`dripTemplates/${templateId}`, template);
}

export function duplicateDripTemplate(templateId) {
  return connectV1Client.post(`dripTemplates/${templateId}/_duplicate`);
}

export function shareDripTemplate(templateId, shareOptions) {
  return connectV1Client.post(`dripTemplates/${templateId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
    IsShareWithAll: shareOptions.isShareWithAll,
    IsUnshareWithAll: shareOptions.isUnshareWithAll,
  });
}
