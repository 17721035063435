const getValueToBeInserted = (value, queryString, cursorPosition) => {
  const prevCursorPosition = queryString[cursorPosition - 1];
  const multiWordValue = value.split(' ').length > 1;

  if ([')'].includes(value)) return value;
  if (((['('].includes(value) && [' ', '('].includes(prevCursorPosition)) || !queryString) && !multiWordValue)
    return value.length > 1 ? `${value} ` : value;
  if (['('].includes(value)) return ` ${value}`;
  if (['OR', 'AND'].includes(value) && prevCursorPosition !== ' ') return ` ${value} `;
  if (['OR', 'AND'].includes(value) && prevCursorPosition === ' ') return `${value} `;
  if (multiWordValue && (prevCursorPosition === '(' || prevCursorPosition === ' ')) return `"${value}" `;
  if (multiWordValue && !queryString) return `"${value}" `;
  if (multiWordValue) return ` "${value}" `;
  if (prevCursorPosition === '(' || prevCursorPosition === ' ') return `${value} `;
  return ` ${value} `;
};

const suggestionTypes = {
  keywords: 'KEYWORDS',
  static: 'STATIC',
  boolean: 'BOOLEAN',
  complexBoolean: 'COMPLEX_BOOLEAN',
};

const booleanOperators = ['OR', 'AND', '(', ')'];

const isWrappedByQuotes = text => {
  const quotes = ["'", '"'];
  if (!text || text.length < 2) {
    return false;
  }
  return quotes.includes(text[0]) && quotes.includes(text[text.length - 1]);
};

const stripQuotes = text => (isWrappedByQuotes(text) ? text.substring(1, text.length - 1) : text);

const tryWrapBooleanDoubleQuotes = operand => {
  const trimmed = operand?.trim();
  if (trimmed?.includes(' ') && !isWrappedByQuotes(trimmed)) {
    return `"${operand}"`;
  }
  return operand;
};

const getBooleanString = (skill, netSynonyms) => {
  if (!netSynonyms?.length) {
    return null;
  }
  const combinedSkills = skill ? [skill].concat(netSynonyms) : netSynonyms;
  const doubleQuotedSkills = combinedSkills.map(x => tryWrapBooleanDoubleQuotes(x));
  return doubleQuotedSkills.join(' OR ');
};

const isStaticOption = selectedItem => {
  return booleanOperators.includes(selectedItem);
};

export {
  getValueToBeInserted,
  suggestionTypes,
  booleanOperators,
  stripQuotes,
  getBooleanString,
  isStaticOption,
  isWrappedByQuotes,
};
