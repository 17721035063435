import React from 'react';
import _ from 'lodash';
import { Button, Icon, Divider, Skeleton } from 'antd';
import qs from 'query-string';
import PaymentProgressModal from './PaymentProgressModal';
import PlaceOrderTermsOfUse from './PlaceOrderTermsOfUse';
import BundlePlanModal from '../BundlePlanModal/BundlePlanModal';
import { getProductVariantsByName } from '../../Utils/PlanSelectionUtils';
import { isUnhandledTaxJarError } from '../../Utils/PaymentUtils';
import { AuthorizeNetIcon } from '../../Icons/AryaIcons';

import './PaymentPlaceOrder.scss';

function OrderAmountItem({ itemName, price, borderTop, className }) {
  return (
    <div className="place-order-item" style={{ borderTop: borderTop ? '1.5px solid #DADBDD' : 'none' }}>
      <div style={{ paddingLeft: '25px' }} className={className}>
        {itemName}
      </div>
      <div className={`${className} order-price-item`}>{price}</div>
    </div>
  );
}

function getOrderAmount(totalAmount, paymentMode, productVariants, variantId) {
  const planDetails = _.find(productVariants, variant => variant.Id === variantId);
  if (paymentMode !== 'AryaJobCredit') {
    return {
      orderPrice: totalAmount.Price ?? planDetails?.UnitPrice,
      orderTax: totalAmount.Tax ?? 0,
      orderTotal: totalAmount.Total ?? planDetails?.UnitPrice,
    };
  }
  return { orderPrice: 0, orderTax: 0, orderTotal: 0 };
}

function BundleOrderReview({ productVariantesById, orderItemById, productVariantId }) {
  const discount = Number(_.get(orderItemById, [productVariantId, 'Amount', 'Discount'], 0));
  const totalPlanPrice = Number(_.get(orderItemById, [productVariantId, 'Amount', 'Price'], 0));
  const quantity = Number(_.get(orderItemById, [productVariantId, 'Quantity'], 0));
  const productVariantName = _.get(productVariantesById, [productVariantId, 'DisplayName']);
  const pricePerJob = `$${Number(totalPlanPrice / (quantity || 1)).toFixed(2)}`;
  const totalTaxAmount = Number(_.get(orderItemById, [productVariantId, 'Amount', 'Tax'], 0)).toFixed(2);
  const planTotalAmount = Number(_.get(orderItemById, [productVariantId, 'Amount', 'Total'], 0)).toFixed(2);

  return (
    <>
      <div className="order-sub-item-title">{productVariantName} Plan</div>
      <OrderAmountItem price={pricePerJob} itemName="Price per job" />
      <OrderAmountItem price={quantity} itemName="Number of jobs" />
      <OrderAmountItem price={`$${totalPlanPrice}`} itemName="SubTotal" />
      {discount ? (
        <OrderAmountItem
          price={`-$${Number(discount).toFixed(2)}`}
          itemName={`${(discount / totalPlanPrice) * 100}% Discount per ${quantity} jobs`}
        />
      ) : null}
      <OrderAmountItem price={`$${totalTaxAmount}`} itemName="Tax" />
      {Object.keys(orderItemById).length > 1 ? (
        <OrderAmountItem price={`$${planTotalAmount}`} itemName="NetTotal" className="order-last-item" />
      ) : null}
    </>
  );
}

function PlaceOrderHeaderContent({
  basicCount,
  premiumCount,
  orderType,
  planName,
  planDisplayName,
  toggleBundlePlanModalVisibility,
  isBuyCredit,
}) {
  if (orderType === 'future') {
    let planNameFuture = '';
    if (basicCount && premiumCount) planNameFuture = 'Basic and Full-Service';
    else if (basicCount) planNameFuture = 'Basic';
    else planNameFuture = 'Full-Service';

    return (
      <>
        <h3>Order Summary</h3>
        <p>{`You are ordering the ${planNameFuture} Plan`}</p>
        {isBuyCredit === 'true' ? null : (
          <div
            style={{ color: '#13C26B', cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onClick={toggleBundlePlanModalVisibility}
            onKeyPress={toggleBundlePlanModalVisibility}
          >
            Modify Order
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <h3>
        {planName === 'Premium' || planName === 'Premium Upgrade'
          ? 'Up to 100 sourced and engaged candidates'
          : 'Up to 100 sourced candidates'}
      </h3>
      <p>{`You are ordering the ${planDisplayName} Plan`}</p>
    </>
  );
}

export default function PaymentPlaceOrder({
  planName,
  planDisplayName,
  placeOrderCollectInfo,
  modalVisbility,
  paymentApiStatus,
  createOrderApiStatus,
  createOrderApiErrorMessage,
  updateBillingInfoApiStatus,
  history,
  invoiceNumber,
  toggleOrderModalVisibility,
  disablePlaceOrder,
  totalAmount,
  getAmountApiStatus,
  isAddressAvaliable,
  paymentMode,
  onClickDuplicatePaymentOk,
  productVariants,
  variantId,
  orderType,
  bundlePlanModalVisibility,
  toggleBundlePlanModalVisibility,
  orderDetails,
  fetchTotalAmountForProductvariants,
  productVariantesById,
  orderDetailsWithTax,
  getAmountWithoutTaxApiStatus,
  resetOrderDetailsWithoutTax,
  isPulsePromotionalOffer,
}) {
  const { isUpgrade, isBuyCredit } = qs.parse(history.location.search);

  const { orderPrice, orderTax, orderTotal } = getOrderAmount(totalAmount, paymentMode, productVariants, variantId);
  const product = getProductVariantsByName(productVariantesById);
  const productVariantIds = [product.Basic.Id, product.Premium.Id];
  let { orderItemById = {}, TotalAmount } = orderDetailsWithTax;

  if (_.isEmpty(orderItemById)) {
    orderItemById = orderDetails?.orderItemById || {};
    TotalAmount = orderDetails?.TotalAmount;
  }

  const basicQuantity = _.get(orderItemById, [product.Basic.Id, 'Quantity'], 0);
  const premiumQuantity = _.get(orderItemById, [product.Premium.Id, 'Quantity'], 0);

  const bundleOrderItems = productVariantIds.filter(productVariantId => productVariantId in orderItemById);
  let orderTotalAmount = 0;
  if (orderType !== 'future') {
    orderTotalAmount = orderTotal;
  } else {
    orderTotalAmount = _.get(TotalAmount, ['Total'], 0);
  }

  const isAmountApiError = isUnhandledTaxJarError(getAmountApiStatus);
  return (
    <div className="place-order-body">
      <Skeleton active loading={getAmountApiStatus === 'INPROGRESS'}>
        <div className="add-candidates-payment-plan">
          <div className="profiles-add">
            <PlaceOrderHeaderContent
              basicCount={Number.parseInt(basicQuantity, 10)}
              premiumCount={Number.parseInt(premiumQuantity, 10)}
              orderType={orderType}
              planName={planName}
              planDisplayName={planDisplayName}
              toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
              isUpgrade={isUpgrade}
              isBuyCredit={isBuyCredit}
            />
          </div>
          <Divider style={{ margin: '0px' }} />
          {orderType !== 'future' ? (
            <div className="order-details-payment-plan">
              <h3>Order Details</h3>
            </div>
          ) : null}
          <div width="100%">
            <div>
              {orderType !== 'future' ? (
                <>
                  <OrderAmountItem price={`$${Number(orderPrice).toFixed(2)}`} itemName="Price" />
                  <OrderAmountItem
                    price={`$${Number(orderTax).toFixed(2)}`}
                    itemName="Tax"
                    className="order-last-item"
                  />
                </>
              ) : (
                <>
                  {bundleOrderItems.map((productVariantId, index) => {
                    const isLastBundleItem = index !== bundleOrderItems.length - 1;
                    return (
                      <>
                        <BundleOrderReview
                          productVariantesById={productVariantesById}
                          orderItemById={orderItemById}
                          productVariantId={productVariantId}
                        />
                        {isLastBundleItem ? <OrderAmountItem borderTop /> : null}
                      </>
                    );
                  })}
                </>
              )}
              <OrderAmountItem
                price={<b style={{ fontSize: '16px', color: 'black' }}>{`$${Number(orderTotalAmount).toFixed(2)}`}</b>}
                itemName={<b style={{ fontSize: '12px', color: 'black' }}>ORDER TOTAL</b>}
                borderTop
                className="order-result-amount-item"
              />
            </div>
          </div>
        </div>
      </Skeleton>
      <div className="payment-plan-button">
        {paymentMode === 'AryaJobCredit' && (
          <div className="arya-job-credit-info">The account will be charged with 1 credit once you place the order</div>
        )}
        {isAmountApiError && paymentMode !== 'AryaJobCredit' && isAddressAvaliable && (
          <span className="arya-job-amount-api-error">
            We are facing trouble calculating your order total. Try placing order with Arya Credits, if available, or
            contact{' '}
            <a href="mailto:support@leoforce.com" style={{ textDecoration: 'underline', color: '#b50000' }}>
              support@leoforce.com
            </a>{' '}
            if this issue persists.
          </span>
        )}
        <Button
          className="place-order-btn"
          type="primary"
          shape="round"
          onClick={() => placeOrderCollectInfo(totalAmount.Total, totalAmount.Tax)}
          disabled={disablePlaceOrder || !isAddressAvaliable}
        >
          Place Order
        </Button>
        <p style={{ marginTop: '7px' }}>
          <Icon type="lock" theme="filled" style={{ color: '#13C26B' }} />
          &nbsp; Safe and Secure Payments powered by
          <AuthorizeNetIcon style={{ fontSize: '99px', marginLeft: '4px' }} />
        </p>
      </div>

      <PlaceOrderTermsOfUse />
      <PaymentProgressModal
        visible={modalVisbility}
        paymentStatus={paymentApiStatus}
        orderStatus={createOrderApiStatus}
        orderErrorMessage={createOrderApiErrorMessage}
        updateBillingInfoApiStatus={updateBillingInfoApiStatus}
        history={history}
        invoiceNumber={invoiceNumber}
        totalAmount={totalAmount}
        toggleOrderModalVisibility={toggleOrderModalVisibility}
        onClickDuplicatePaymentOk={onClickDuplicatePaymentOk}
      />
      <BundlePlanModal
        modalVisibility={bundlePlanModalVisibility}
        fetchTotalAmountForProductvariants={fetchTotalAmountForProductvariants}
        orderDetails={orderDetails}
        productVariantsById={productVariantesById}
        basicQuantity={Number.parseInt(basicQuantity, 10)}
        premiumQuantity={Number.parseInt(premiumQuantity, 10)}
        history={history}
        toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
        fetchTotalAmountForProductvariantApiStatus={getAmountWithoutTaxApiStatus}
        resetOrderDetailsWithoutTax={resetOrderDetailsWithoutTax}
        isPulsePromotionalOffer={isPulsePromotionalOffer}
      />
    </div>
  );
}
