import React, { useState, createContext, useContext, useEffect, useRef } from 'react';

const ScrollContext = createContext();

export function ScrollTo({ id, children }) {
  const { setActivePane } = useContext(ScrollContext);
  return (
    <div role="presentation" onClick={() => setActivePane(id)}>
      {children}
    </div>
  );
}
export function ScrollItem({ children, id }) {
  const { activePane, setActivePane } = useContext(ScrollContext);
  const myRef = useRef(null);
  useEffect(() => {
    if (id === activePane) {
      window.scrollTo({
        top: myRef.current.offsetTop,
        behavior: 'smooth',
      });
      setActivePane(null);
    }
  });
  return <div ref={myRef}>{children}</div>;
}

export function Scroll({ children }) {
  const [activePane, setActivePane] = useState();
  const providerValue = { activePane, setActivePane };
  return <ScrollContext.Provider value={providerValue}>{children}</ScrollContext.Provider>;
}
