import React, { useCallback } from 'react';
import { Button, Icon, Divider } from 'antd';
import ZipcodeLocation from '../../../ZipcodeLocation/ZipcodeLocation';
import './AdvanceSearchLocation.scss';
import DistanceForm from '../../../Common/DistanceForm/DistanceForm';
import styles from './AdvanceSearchLocation.module.scss';

const range = N => Array.from({ length: N }, (x, y) => y);

export default function AdvanceSearchLocation(props) {
  const {
    defaultAlternateLocations,
    form,
    jobBoardFormDecorator,
    maxAlternateLocationsAllowed,
    countryCode,
    isCountryWideSearchEnabled,
    config,
  } = props;

  const [locationsCount, setLocationsCount] = React.useState(1);
  const [locationsKeys, setLocationsKeys] = React.useState([0]);

  const alternateLocations = defaultAlternateLocations && JSON.parse(defaultAlternateLocations);
  const alternateLocationsLength = alternateLocations?.length;

  React.useEffect(() => {
    if (alternateLocationsLength) {
      setLocationsCount(alternateLocationsLength);
      setLocationsKeys(range(alternateLocationsLength));
    }
  }, [alternateLocationsLength]);

  const addLocationOption = useCallback(() => {
    const newLocationsCount = locationsCount + 1;
    const newLocationsKeys = locationsKeys.concat(locationsCount);
    setLocationsCount(newLocationsCount);
    setLocationsKeys(newLocationsKeys);
  }, [locationsCount, locationsKeys]);

  const deleteLocationOption = useCallback(
    currentKey => {
      const updatedLocationsKeys = locationsKeys.filter(key => key !== currentKey);
      setLocationsKeys(updatedLocationsKeys);
      form.setFieldsValue({
        [`${jobBoardFormDecorator}-alternatelocation-${currentKey}`]: undefined,
        [`${jobBoardFormDecorator}-alternatezipcode-${currentKey}`]: undefined,
      });
    },
    [locationsKeys]
  );
  return (
    <div className="advanced-options-alternate-locations">
      <span className="location-label">Locations</span>
      {locationsKeys.map((key, index) => (
        <div key={`advanced-options-alternatelocation-${key}`}>
          <div className="adv-options-alternate-locations-wrapper">
            {form.getFieldDecorator(`${jobBoardFormDecorator}-IsStateWideSearch-${key}`, {
              initialValue: !!alternateLocations?.[key]?.State,
            })(
              <ZipcodeLocation
                form={form}
                locationInitialValue={alternateLocations?.[key]?.State || alternateLocations?.[key]?.Location}
                zipcodeInitialValue={alternateLocations?.[key]?.PostalCode}
                locationFieldDecorator={`${jobBoardFormDecorator}-alternatelocation-${key}`}
                zipcodeFieldDecorator={`${jobBoardFormDecorator}-alternatezipcode-${key}`}
                countryCode={countryCode}
                isZipcodeDisabled={
                  isCountryWideSearchEnabled || form.getFieldValue(`${jobBoardFormDecorator}-IsStateWideSearch-${key}`)
                }
                isLocationDisabled={isCountryWideSearchEnabled}
                isStateWideSearchChecked={form.getFieldValue(`${jobBoardFormDecorator}-IsStateWideSearch-${key}`)}
                stateWideSearchFieldDecorator={`${jobBoardFormDecorator}-IsStateWideSearch-${key}`}
              />
            )}
            {locationsKeys.length > 1 ? (
              <Icon
                className="alternate-location-delete-button"
                type="minus-circle-o"
                onClick={() => deleteLocationOption(key)}
              />
            ) : null}
          </div>
          {config?.supportDistance ? (
            <div>
              <DistanceForm
                form={form}
                distanceFieldDecorator={`${jobBoardFormDecorator}-distance-${key}`}
                distanceInitialValue={alternateLocations?.[key]?.Distance}
                distanceUnitFieldDecorator={`${jobBoardFormDecorator}-distanceunit-${key}`}
                distanceUnitInitialValue={alternateLocations?.[key]?.DistanceUnit}
                isDisabled={form.getFieldValue(`${jobBoardFormDecorator}-IsStateWideSearch-${key}`)}
              />
              {index !== locationsKeys.length - 1 ? <Divider className={styles.divider} /> : null}
            </div>
          ) : null}
        </div>
      ))}
      <Button
        className="add-more-locations-button"
        onClick={addLocationOption}
        disabled={isCountryWideSearchEnabled || locationsKeys.length >= maxAlternateLocationsAllowed}
      >
        <Icon type="plus" /> Add more location
      </Button>
    </div>
  );
}
