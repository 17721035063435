import { S3 } from 'aws-sdk';

function getS3Object(credentials) {
  return new S3({
    accessKeyId: credentials.AccessKeyId,
    secretAccessKey: credentials.SecretKey,
    sessionToken: credentials.SessionToken,
  });
}

export { getS3Object };
