import { apiV3Client } from './BaseRepository';

export function fetchSuggestedTagsForCandidate(filter) {
  return apiV3Client.post(`tags/_search?type=${filter.type}`, {
    From: filter.from,
    Size: filter.size,
    SearchTerm: filter.searchTerm,
  });
}

export function createCandidateTags(filter) {
  return apiV3Client.post(`tags?candidateId=${filter.candidateId}`, {
    Tags: filter.tags,
  });
}

export function createNoteTags(filter) {
  return apiV3Client.post(`tags?noteId=${filter.noteId}`, {
    Tags: filter.tags,
  });
}

export function deleteTag(filter) {
  return apiV3Client.delete(`tags?tagAssociationId=${filter.tagAssociationId}`);
}
