import { getCandidateSource } from './SourceUtils';
import { hasAtleastOneValidContact } from './ConnectUtils';

function isResumeUnlockable(candidate) {
  const resumeLockablePortals = ['resumelibrary', 'cvlibrary'];
  const candidateSource = getCandidateSource(candidate);
  const candidatePortal = candidateSource?.Portal ?? '';
  return !!candidatePortal && resumeLockablePortals.includes(candidatePortal.toLowerCase());
}

function isSocialResumeGenerationEnabled(candidate, featureToggleList) {
  const candidateSource = getCandidateSource(candidate);
  const isSocialCandidate =
    candidateSource?.Portal?.toLowerCase() === 'social' || candidateSource?.Group?.toLowerCase() === 'passive';
  const isCountryAllowed = !!(
    featureToggleList?.CandidateResumeGeneration.IsPublic ||
    (candidate?.Country &&
      featureToggleList?.CandidateResumeGeneration.AllowedCountryCodes?.includes(candidate.Country))
  );
  return featureToggleList?.CandidateResumeGeneration.IsEnabled && isSocialCandidate && isCountryAllowed;
}

function isSocialResumeGenerationAllowed(candidate, featureToggleList, connectInfo) {
  const isValidContactPresent = hasAtleastOneValidContact(connectInfo?.Contact);
  return isSocialResumeGenerationEnabled(candidate, featureToggleList) && isValidContactPresent;
}

export { isResumeUnlockable, isSocialResumeGenerationEnabled, isSocialResumeGenerationAllowed };
