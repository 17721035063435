import * as userActivityRepository from '../Repository/UserActivityRepository';
import {
  setMonitorUserEmailActivityApiStatus,
  setAdvanceSearchActivityApiStatus,
  SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS,
  SET_ADVANCE_SEARCH_ACTIVITY_API_STATUS,
} from './ActionCreators/UserActivityActionCreator';

function monitorUserEmailActivity(payload) {
  return async dispatch => {
    try {
      dispatch(setMonitorUserEmailActivityApiStatus('INPROGRESS'));
      await userActivityRepository.monitorUserEmailActivity(payload);
      dispatch(setMonitorUserEmailActivityApiStatus('COMPLETED'));
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS,
          timeStamp: new Date(),
        },
      });
      dispatch(setMonitorUserEmailActivityApiStatus('FAILED'));
    }
  };
}

function trackAdvanceSearchUserActivity(payload) {
  return async dispatch => {
    try {
      dispatch(setAdvanceSearchActivityApiStatus('INPROGRESS'));
      await userActivityRepository.saveAdvanceSearchActivity(payload);
      dispatch(setAdvanceSearchActivityApiStatus('COMPLETED'));
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: SET_ADVANCE_SEARCH_ACTIVITY_API_STATUS,
          timeStamp: new Date(),
        },
      });
      dispatch(setAdvanceSearchActivityApiStatus('FAILED'));
    }
  };
}

export { monitorUserEmailActivity, trackAdvanceSearchUserActivity };
