const currencyToSymbol = {
  USD: '$',
  INR: 'Rs',
  GBP: '£',
};

const getCurrencySymbol = currency => {
  let currencySymbol;
  if (currency) {
    currencySymbol = currencyToSymbol[currency] ? currencyToSymbol[currency] : currency;
  } else currencySymbol = '';
  return currencySymbol;
};

const getSalaryString = salary => {
  const { MinSalary, MaxSalary, Currency, SalaryType } = salary;
  if (!MinSalary && !MaxSalary) return ''
  const currencySymbol = getCurrencySymbol(Currency);
  return `${currencySymbol} ${MinSalary ?? 0}${Number(MaxSalary) ? `-${MaxSalary}` : '+'}${
    SalaryType ? `/${SalaryType}` : ''
  }`;
};

export { getSalaryString, getCurrencySymbol };
