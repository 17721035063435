import React from 'react';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';

function CandidateCardWrapper(props) {
  const { children } = props;

  const contextValues = React.useMemo(() => ({ ...props }), [props]);

  return <CandidateCardContext.Provider value={contextValues}>{children}</CandidateCardContext.Provider>;
}

export default CandidateCardWrapper;
