import _ from 'lodash';
import { createSelector } from 'reselect';

function UserSessionReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'LOAD_CURRENT_USER':
      newState = _.cloneDeep(state);
      if (_.get(newState, ['user'], false)) {
        newState = {};
      }
      newState.user = action.payload;
      return newState;
    case 'SET_LOAD_CURRENT_USER_API_STATUS':
      return {
        ...state,
        loadUserApiStatus: action.payload,
      };
    case 'SET_USER_VERIFICATION_STATUS':
      return {
        ...state,
        userVerificationStatus: action.payload,
      };
    case 'GET_KNOWLEDGEBASE_AUTHORIZATION_TOKEN_API_STATUS':
      return {
        ...state,
        getKnowledgeBaseAuthorizationTokenStatus: action.payload,
      };
    default:
      return state;
  }
}

function getCurrentUser(state) {
  let attributes = _.get(state.UserSessionReducer.user, ['attributes'], null);
  if (!attributes) {
    attributes = _.get(state.UserSessionReducer.user, ['signInUserSession', 'idToken', 'payload'], {});
  }
  return attributes;
}

function getLoadCurrentUserApiStatus(state) {
  return _.get(state, ['UserSessionReducer', 'loadUserApiStatus']);
}

function getUserVerificationStatus(state) {
  return _.get(state, ['UserSessionReducer', 'userVerificationStatus']);
}

function getImpersonatedUserDetailsJson(state) {
  return state?.UserSessionReducer?.user?.storage?.ImpersonatedUser;
}

const getImpersonatedUserDetails = createSelector(getImpersonatedUserDetailsJson, impersonatedUserDetails => {
  if (impersonatedUserDetails) return JSON.parse(impersonatedUserDetails);
  return null;
});

function getCurrentUserDetails(state) {
  const currentUser = getCurrentUser(state) ?? {};
  const impersonatedUserDetails = getImpersonatedUserDetails(state);
  const usersByGuid = state.UserReducer.ByGuId || {};
  const currentUserDetails = usersByGuid[currentUser.sub] ?? {};
  return impersonatedUserDetails ?? currentUserDetails;
}

function getCurrentUserOrgGuid(state) {
  const currentUserDetails = getCurrentUserDetails(state);
  return currentUserDetails?.OrgGuid || currentUserDetails?.OrgId;
}

function getKnowledgeBaseAuthorizationTokenApiStatus(state) {
  return _.get(state, ['UserSessionReducer', 'getKnowledgeBaseAuthorizationTokenStatus']);
}

export {
  UserSessionReducer,
  getCurrentUser,
  getCurrentUserDetails,
  getLoadCurrentUserApiStatus,
  getUserVerificationStatus,
  getImpersonatedUserDetails,
  getCurrentUserOrgGuid,
  getKnowledgeBaseAuthorizationTokenApiStatus,
};
