import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { formatExperience } from '../../Utils/CandidateIntelUtils';

const BellCurveGraph = ({ meanExperience, candidateExperience, requiredExperience }) => {
  const normalDistribution = (x, mean, stddev) => {
    return (1 / (stddev * Math.sqrt(2 * Math.PI))) * Math.exp(-((x - mean) ** 2) / (2 * stddev ** 2));
  };

  const getNearestElement = (x, arr) => {
    if (arr.includes(x)) return x;
    let diff = Infinity;
    let element;
    arr.forEach(item => {
      if (Math.abs(item - x) < diff) {
        diff = Math.abs(item - x);
        element = item;
      }
    });
    return element;
  };

  const getElementPosition = (x, arr, middleRange) => {
    let position = 'normal';
    if (arr.length === 0) return position;
    const nearestElement = getNearestElement(x, arr);
    if (Math.abs(nearestElement - x) < 26) {
      if (x > nearestElement && x < middleRange) position = 'top';
      else if (x === nearestElement) position = 'same';
      else position = 'bottom';
    }
    return position;
  };

  const findXCoordinate = (y, mean, stddev, precision = 0.0001, maxIterations = 1000) => {
    let minX = mean - 3 * stddev;
    let maxX = mean + 3 * stddev;
    let midX;

    let iteration = 0;
    while (iteration < maxIterations) {
      midX = (minX + maxX) / 2;
      const currentY = normalDistribution(midX, mean, stddev);

      if (Math.abs(currentY - y) < precision) {
        return midX; // Found approximate x-coordinate
      }
      if (currentY < y) {
        minX = midX;
      } else {
        maxX = midX;
      }

      iteration += 1;
    }

    return null; // Unable to find x-coordinate within the given iterations
  };

  useEffect(() => {
    const minExperience = Math.min(meanExperience, candidateExperience, requiredExperience);
    const maxExperience = Math.max(meanExperience, candidateExperience, requiredExperience);
    const rangeExperience = maxExperience - minExperience;
    let stddev;
    if (rangeExperience === 0) {
      stddev = 1; // Default standard deviation when all input values are the same
    } else {
      stddev = rangeExperience; // Dynamic standard deviation based on input range
    }

    const markers = [
      { x: requiredExperience, label: 'Required Experience', color: '#722ED1' },
      { x: candidateExperience, label: 'Candidate Experience', color: '#237804' },
      { x: meanExperience, label: 'Average Experience', color: '#FA541C' },
    ];

    const dataPoints = [];
    for (let x = minExperience - 3 * stddev; x <= maxExperience + 3 * stddev; x += 0.1) {
      dataPoints.push({ x, y: normalDistribution(x, meanExperience, stddev) });
    } // Define SVG area dimensions
    const svgWidth = 800;
    const svgHeight = 280; // Define the chart's margins as an object
    const margin = { top: 30, right: 130, bottom: 30, left: 130 }; // Define dimensions of the chart area
    const chartWidth = svgWidth - margin.left - margin.right - 120;
    const chartHeight = svgHeight - margin.top - margin.bottom - 80; // Select svg with id "chart" and set its dimensions
    const svg = d3.select('#chart').attr('width', svgWidth).attr('height', svgHeight); // Append a group area, then set its margins
    const chartGroup = svg.append('g').attr('transform', `translate(${margin.left + 55}, ${margin.top + 84})`); // Configure a time scale with a range between 0 and the chartWidth
    const xScale = d3
      .scaleLinear()
      .range([0, chartWidth])
      .domain(d3.extent(dataPoints, data => data.x)); // Configure a linear scale with a range between the chartHeight and 0
    const yScale = d3
      .scaleLinear()
      .range([chartHeight, 0])
      .domain([0, d3.max(dataPoints, data => data.y)]);
    const bottomAxis = d3.axisBottom(xScale).tickSize(0).tickFormat('');
    const leftAxis = d3.axisLeft(yScale); // Configure a drawLine function which will use our scales to plot the line's points
    const drawLine = d3
      .line()
      .x(data => xScale(data.x))
      .y(data => yScale(data.y)); // Append an SVG path and plot its points using the line function
    chartGroup
      .append('path')
      .attr('d', drawLine(dataPoints))
      .classed('line', true)
      .style('fill', 'none')
      .style('stroke', 'black')
      .attr('stroke-width', 2);
    // Append an SVG group element to the SVG area, create the left axis inside of it
    chartGroup.append('g').classed('axis', true).call(leftAxis).style('display', 'none'); // Append an SVG group element to the SVG area, create the bottom axis inside of it// Translate the bottom axis to the bottom of the page
    chartGroup.append('g').classed('axis', true).attr('transform', `translate(0, ${chartHeight})`).call(bottomAxis); // Add tooltip div
    const textDisplacement = 14;

    const maxY = Math.max(
      ...dataPoints.map(marker => marker.y) // Get an array of all x-values from the markers
    );

    const xCordinate = findXCoordinate(maxY, meanExperience, stddev);
    const xRangeMiddle = xScale(xCordinate);

    const markerPositionArray = markers.map(item => xScale(item.x));
    const middleRangeMarkers = markerPositionArray
      .map((item, index) => item === xRangeMiddle && index)
      .filter(item => item);
    const anchorArray = [];
    const markerDetailsX = [];
    let middlePosition;
    markers.forEach((item, index) => {
      const { x, label, color } = item;
      const cx = xScale(x);
      const cy = yScale(normalDistribution(x, meanExperience, stddev));
      const textOffset = 30;
      const extraOffset = 20;
      let textX;
      let textY;
      let anchor;
      if (cx < xRangeMiddle) {
        textX = cx - textOffset - extraOffset;
        textY = cy;
        anchor = 'end';
      } else if (cx > xRangeMiddle) {
        textX = cx + textOffset + extraOffset;
        textY = cy - 10;
        anchor = 'start';
      } else {
        textX = cx;
        textY = cy - textOffset - 30;
        anchor = 'middle';
      }
      if (middleRangeMarkers.includes(index) && middleRangeMarkers.length > 1) {
        if (!middlePosition) {
          textX = cx + 60 + extraOffset;
          textY = cy - 8;
          anchor = 'start';
          middlePosition = 'right';
        } else if (middlePosition === 'right') {
          textX = cx - 10 - extraOffset;
          textY = cy - 7;
          anchor = 'end';
          middlePosition = 'middle';
        } else {
          textX = cx;
          textY = cy - textOffset - 60;
          anchor = 'middle';
        }
      }
      const position = getElementPosition(Math.round(cx), markerDetailsX, xRangeMiddle);
      markerDetailsX.push(Math.round(cx));

      if (position === 'top') textY -= textDisplacement - 10;
      else if (position === 'bottom') textY += textDisplacement + 10;
      else if (position === 'same') {
        const extraSpace = 7;
        textY -= textDisplacement + extraSpace;
      }

      let middleLineSpace = cx === xRangeMiddle && anchor === 'middle' ? 5 : 0;

      anchorArray.push(anchor);
      let displayLabel = `<tspan x="${textX}" y="${textY}">Candidate experience = ${formatExperience(x)}</tspan>`;
      if (label === 'Average Experience') {
        displayLabel = `<tspan x="${textX}" y="${textY}">Average experience for given role = ${formatExperience(
          x
        )} </tspan>`;
      }
      if (label === 'Required Experience') {
        displayLabel = `<tspan x="${textX}" y="${textY}">Arya extracted experience</tspan>
        <tspan x="${textX}" y="${textY + 16}" dy="0.2em">from JD = ${formatExperience(x)}</tspan>`;
        if (cx === xRangeMiddle) middleLineSpace = 20;
      }
      chartGroup.append('circle').attr('cx', cx).attr('cy', cy).attr('r', 10).attr('fill', color);

      chartGroup
        .append('line')
        .attr('x1', cx)
        .attr('y1', cy)
        .attr('x2', textX)
        .attr('y2', textY + middleLineSpace)
        .attr('stroke', color)
        .attr('stroke-width', 1);

      chartGroup
        .append('text')
        .attr('x', textX)
        .attr('y', textY)
        .attr('font-family', 'sans-serif')
        .attr('font-size', '16px')
        .attr('fill', color)
        .attr('text-anchor', anchor)
        .attr('alignment-baseline', 'middle')
        .html(displayLabel);
    });
  }, [meanExperience, candidateExperience, requiredExperience]);

  return (
    <div style={{ textAlign: 'center' }}>
      <svg id="chart"></svg>
    </div>
  );
};

export default React.memo(BellCurveGraph);
