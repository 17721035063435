import React from 'react';
import MoreIcon from '../../../Icons/MoreIcon';
import SourceItem from '../../../CompoundComponents/Sources/SourceItem';
import SourceItemName from '../../../CompoundComponents/Sources/SourceItemName';
import AdvancedSettingsPopover from '../../../CompoundComponents/Sources/AdvancedSettingsPopover';
import { getIsAdvancedSettingsEnabled } from '../../../Components/QuickSearchForm/QuickSearchFormUtils';
import styles from './ManualSearchSources.module.scss';

export default function ManualSearchSourceItem(props) {
  const {
    isFilterApplied,
    isAdvanceSettingsButtonDisabled,
    sourcesSectionVisibility,
    source,
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
    isSocialAdvanceSettingsEnabled,
  } = props;
  const isAdvanceSettingsEnabled = getIsAdvancedSettingsEnabled(source, isSocialAdvanceSettingsEnabled);
  return (
    <div
      className={`${styles.sourceWrapper} ${
        isFilterApplied && !isAdvanceSettingsButtonDisabled ? styles.advanceOptionsApplied : ''
      }`}
    >
      <div className={styles.sourceItemWrapper}>
        <SourceItem
          candidateContext={candidateContext}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        />
        {sourcesSectionVisibility ? <SourceItemName /> : null}
      </div>
      {sourcesSectionVisibility && isAdvanceSettingsEnabled ? (
        <AdvancedSettingsPopover>
          <MoreIcon
            className={`${styles.advanceSettingsButton} ${isAdvanceSettingsButtonDisabled ? styles.disabled : ''} ${
              isFilterApplied && !isAdvanceSettingsButtonDisabled ? styles.active : ''
            }`}
            fillColor={isFilterApplied && !isAdvanceSettingsButtonDisabled ? '#13c26b' : '#DADBDD'}
          />
        </AdvancedSettingsPopover>
      ) : null}
    </div>
  );
}
