import { apiV3Client } from './BaseRepository';

export function pullJobs(jobIds, vaultName) {
  return apiV3Client.post(`Jobs/_bulkfetch?vaultName=${vaultName}`, {
    JobCodes: jobIds,
  });
}

export function pushCandidate(jobId, candidateId, portal, vaultName) {
  return apiV3Client.post(`jobs/${jobId}/candidates/${candidateId}/_push?vaultName=${vaultName}&portal=${portal}`);
}

export function fetchAtsConfig(vaultName) {
  return apiV3Client.get(`_config/clientvault?vaultName=${vaultName}`);
}
