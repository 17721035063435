import React from 'react';
import { Button } from 'antd';
import './JobForbidden.scss';

function JobForbidden(props) {
  const { context = 'job', openListOfList } = props;
  const title = context === 'job' ? 'Job Not Shared' : 'Segment Not Shared';
  const linkTo = context === 'job' ? '/jobs' : '/segments';
  return (
    <div className="job-not-share body-content-error">
      <div className="job-not-share-wrapper">
        <div className="job-not-share-title"> {title}</div>
        <div className="job-not-share-description">
          <div>
            You don&#39;t have access to this {context}. To access the {context},
          </div>
          <div>please ask the owner of the {context} to share it with you.</div>
        </div>
        <div className="job-not-share-button-wrapper">
          <Button className="job-not-share-button" type="primary" onClick={() => openListOfList(linkTo)}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
}

export default JobForbidden;
