import React from 'react';
import { Modal } from 'antd';

export default function PulseAccountSetup() {
  return (
    <Modal>
      <div>Your Account is being Settingup Please Wait</div>
    </Modal>
  );
}
