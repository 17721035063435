export const chatGptDefaultEmailConfiguration = {
  Intent: 'Introduction',
  MergeTags: [
    {
      Description: '',
      DisplayName: 'Candidate First Name',
      IsUrl: false,
      Key: 'Candidate.FirstName',
      scopes: ['EmailBody', 'Sms', 'EmailSubject', 'EmailPreHeader'],
    },
    {
      Description: '',
      DisplayName: 'Organization Name',
      IsUrl: false,
      Key: 'Organization.Name',
      scopes: ['EmailBody', 'Sms', 'EmailSubject', 'EmailPreHeader'],
    },
    {
      Description: '',
      DisplayName: 'Recruiter Full Name',
      IsUrl: false,
      Key: 'Recruiter.Name',
      scopes: ['EmailBody', 'Sms', 'EmailSubject', 'EmailPreHeader'],
    },
  ],
  UserPrompts: [],
  Stream: true,
};
