import React from 'react';
import { Form, Input, Icon, Radio } from 'antd';
import * as DragAndDropHook from '../../../Hooks/useDragAndDrop';
import './ScreeningQuestionItem.scss';

const { Group } = Radio;
const { TextArea } = Input;

export default function ScreeningQuestionItem(props) {
  const { removeQuestion, form, index, question, expectedAnswer, readOnly, onDragQuestion, dragType, questionId } =
    props;

  const [isTextFocused, setTextFocused] = React.useState(false);
  const ref = React.useRef(null);
  const [handlerId, drop, drag] = DragAndDropHook.useDragAndDrop({
    itemType: dragType,
    index,
    itemId: questionId,
    moveCard: onDragQuestion,
    ref,
    isDragAllowed: !isTextFocused,
  });
  drag(drop(ref));

  return (
    <div className="bot-question" ref={ref} data-handler-id={handlerId}>
      <div className="question">
        <Form.Item>
          {form?.getFieldDecorator(`bot-question_${index}`, {
            initialValue: question,
            rules: [{ required: true, message: 'Question is required' }],
          })(
            <TextArea
              autosize={{ minRows: 1, maxRows: 3 }}
              size="small"
              placeholder="Type a question"
              disabled={readOnly}
              onMouseEnter={() => setTextFocused(true)}
              onMouseLeave={() => setTextFocused(false)}
            />
          )}
        </Form.Item>
        {!readOnly ? <Icon type="minus-circle" onClick={removeQuestion} style={{ fontSize: 18 }} /> : null}
      </div>
      <div className="answer">
        Expected Answer is &nbsp;
        <Form.Item>
          {form?.getFieldDecorator(`bot-answer_${index}`, { initialValue: expectedAnswer })(
            <Group>
              <Radio value="Yes" disabled={readOnly}>
                Yes
              </Radio>
              <Radio value="No" disabled={readOnly}>
                No
              </Radio>
            </Group>
          )}
        </Form.Item>
      </div>
    </div>
  );
}
