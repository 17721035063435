import cleanSet from 'clean-set';
import {
  SET_CANDIDATE_ADVANCE_FILTER_FIELDS,
  SET_CAMPAIGN_CREATION_API_STATUS,
  SET_CAMPAIGN_STATS,
  SET_CANDIDATE_ADVANCE_FILTER_PORTALS,
} from '../Actions/ActionCreators/CandidateAdvanceActionCreators';

const emptyObject = {};

function CandidateAdvanceFiltersReducer(state = {}, action = {}) {
  let newState;
  switch (action.type) {
    case SET_CANDIDATE_ADVANCE_FILTER_FIELDS:
      newState = cleanSet(state, 'AdvanceFilterFeilds', action.payload);
      return newState;
    case SET_CAMPAIGN_CREATION_API_STATUS:
      newState = cleanSet(state, 'CreateCampaignApiStatus', action.payload);
      return newState;
    case SET_CAMPAIGN_STATS:
      newState = cleanSet(state, 'CampaignStats', action.payload);
      return newState;
    case SET_CANDIDATE_ADVANCE_FILTER_PORTALS:
      newState = cleanSet(state, 'Portals', action.payload);
      return newState;
    default:
      return state;
  }
}

function getCandidateAdvanceFilterFields(state) {
  return state.CandidateAdvanceFiltersReducer?.AdvanceFilterFeilds || emptyObject;
}
function getCandidateAdvanceFilterPortals(state) {
  return state.CandidateAdvanceFiltersReducer?.Portals || emptyObject;
}

function getCreateCampaignApiStatus(state) {
  return state.CandidateAdvanceFiltersReducer?.CreateCampaignApiStatus || emptyObject;
}

function getCampaignStats(state) {
  return state.CandidateAdvanceFiltersReducer?.CampaignStats || [];
}

export {
  getCandidateAdvanceFilterFields,
  CandidateAdvanceFiltersReducer,
  getCreateCampaignApiStatus,
  getCampaignStats,
  getCandidateAdvanceFilterPortals,
};
