import calendar from './Calendar/CalendarEventTypes';
import candidate, { candidateModules } from './Candidate/CandidateEventTypes';
import connect from './Connect/ConnectEventTypes';
import job from './Job/JobEventTypes';
import report from './Report/ReportEventTypes';
import research from './Research/ResearchEventTypes';
import settings from './Settings/SettingsEventTypes';
import home, { homeModules } from './Home/HomeEventTypes';

const eventTypes = {
  calendar,
  candidate,
  connect,
  job,
  report,
  research,
  settings,
  home,
};

export const modules = {
  candidate: candidateModules,
  home: homeModules,
};

export default eventTypes;
