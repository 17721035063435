import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Icon } from 'antd';
import * as OrderActions from '../../Actions/OrderActions';
import { getJobsById } from '../../Reducers/JobReducer';
import { getOrdersByJobId } from '../../Reducers/OrderReducer';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import OrderCancellationComponent from '../../Components/OrderCancellation/OrderCancellationComponent';
import CancelOrderModal from '../../Components/CustomModal/CustomModal';
import OrderCancelled from '../../Components/OrderCancellation/OrderCancelled';
import './OrderCancellation.scss';
import { InValidDataIcon } from '../../Icons/AryaIcons';

const mapStateToProps = state => ({
  jobsById: getJobsById(state),
  productVariantsById: getProductVariantsById(state),
  orderDetailsByJobId: getOrdersByJobId(state),
});

const mapDispatchToProps = {
  cancelOrder: OrderActions.cancelOrder,
  updateOrderStatus: OrderActions.updateOrderStatus,
  clearCancelOrderApiStatus: OrderActions.clearCancelOrderApiStatus,
  fetchOrderDetails: OrderActions.fetchOrderDetails,
};

class OrderCancellationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibility: false,
    };
  }

  componentDidMount() {
    const { jobId, jobsById, fetchOrderDetails } = this.props;
    const orderId = _.get(jobsById, [jobId, 'OrderInfo', 'Id']);
    fetchOrderDetails(jobId, orderId);
  }

  componentDidUpdate(prevProps) {
    const { jobId, jobsById } = this.props;
    const prevOrderDetails = _.get(prevProps, ['jobsById', jobId, 'OrderInfo']);
    const nextOrderDetails = _.get(jobsById, [jobId, 'OrderInfo']);
    const prevCancelOrderApiStatus = _.get(prevOrderDetails, ['cancelOrderApiStatus', 'status'], null);
    const nextCancelOrderApiStatus = _.get(nextOrderDetails, ['cancelOrderApiStatus', 'status'], null);
    if (prevCancelOrderApiStatus !== 'COMPLETED' && nextCancelOrderApiStatus === 'COMPLETED') {
      this.onCancellationSuccess();
    }
  }

  onCancellationSuccess = () => {
    const { history } = this.props;
    const secondsToGo = 3;
    setTimeout(() => {
      this.toggleCancelOrderModalVisibility();
      history.push('/jobs');
    }, secondsToGo * 1000);
  };

  onCancellationFailed = () => {
    const { history } = this.props;
    this.toggleCancelOrderModalVisibility();
    history.push('/jobs');
  };

  toggleCancelOrderModalVisibility = () => {
    const { modalVisibility } = this.state;
    const { jobsById, jobId, clearCancelOrderApiStatus } = this.props;
    const cancelOrderApiStatus = _.get(jobsById, [jobId, 'OrderInfo', 'cancelOrderApiStatus'], null);
    this.setState({
      modalVisibility: !modalVisibility,
    });
    if (_.get(cancelOrderApiStatus, ['status']) === 'FAILED') {
      clearCancelOrderApiStatus(jobId);
    }
  };

  onCancelOrder = () => {
    const { cancelOrder, jobId, jobsById, orderDetailsByJobId } = this.props;
    const orderId = _.get(jobsById, [jobId, 'OrderInfo', 'Id'], null);
    const orderDetails = _.get(orderDetailsByJobId, [jobId], {});
    const items = _.get(orderDetails, ['Items'], []);
    const debitPayment = _.get(orderDetails, ['Payments'], []).find(
      payment => _.get(payment, 'Type', '').toLowerCase() === 'debit'
    );
    let paymentMethod = 'Credit';
    if (debitPayment) {
      paymentMethod = 'Original';
    }
    const refundDetails = {
      RefundType: paymentMethod,
      Items: items,
    };
    cancelOrder(jobId, orderId, refundDetails);
  };

  onViewOrder = () => {
    const { updateOrderStatus, jobId, jobsById } = this.props;
    const orderId = _.get(jobsById, [jobId, 'OrderInfo', 'Id'], null);
    const orderDetails = {
      Status: 'Completed',
      jobId,
    };
    updateOrderStatus(jobId, orderId, orderDetails);
  };

  getErrorMessage = () => {
    const { jobId, jobsById } = this.props;
    const cancelOrderApiStatus = _.get(jobsById, [jobId, 'OrderInfo', 'cancelOrderApiStatus'], {});
    let errorMessage = null;

    if (_.get(cancelOrderApiStatus, 'status', null) === 'FAILED') {
      if (_.get(cancelOrderApiStatus, ['error', 'status'], null) === 404) {
        errorMessage = "You don't have permission to access this job.";
      } else {
        errorMessage = 'Oops, something just went wrong. Please try again.';
      }
    }
    return errorMessage;
  };

  getCancellationPendingContent = () => {
    const { jobId, jobsById } = this.props;
    const cancelOrderApiStatus = _.get(jobsById, [jobId, 'OrderInfo', 'cancelOrderApiStatus'], {});
    const errorMessage = this.getErrorMessage(cancelOrderApiStatus);
    return {
      header: 'Are you sure you want to cancel order?',
      message: (
        <div>
          {errorMessage ? (
            <div style={{ marginBottom: '4px' }}>
              <Alert type="error" message={errorMessage} />
            </div>
          ) : null}
          Cancelling this order will refund the full amount paid, but you will not have access to your candidates.
        </div>
      ),
      footer: (
        <div className="custom-modal-footer-content">
          <div className="offset"></div>
          <div className="custom-modal-buttons">
            <Button className="custom-modal-no-button" shape="round" onClick={this.toggleCancelOrderModalVisibility}>
              <div className="no-button-text">No</div>
            </Button>
            <Button
              className="custom-modal-yes-button"
              shape="round"
              onClick={this.onCancelOrder}
              loading={_.get(cancelOrderApiStatus, 'status') === 'INPROGRESS'}
            >
              <div className="yes-button-text">Yes</div>
            </Button>
          </div>
        </div>
      ),
      icon: <Icon type="close-circle" theme="filled" className="custom-modal-pending-icon" />,
    };
  };

  getCancellationSuccessContent = () => {
    return {
      header: 'Cancelled successfully!',
      message: 'Your order has been successfully cancelled and the hold on the amount will be released.',
      icon: <Icon type="check-circle" theme="outlined" className="custom-modal-success-icon" />,
    };
  };

  getCancellationFailedContent = failedMessage => {
    return {
      message: (
        <div className="order-cancel-failed">
          <InValidDataIcon style={{ fontSize: '85px' }} />
          <div className="order-cancel-failed-text">{failedMessage}</div>
          <Button type="primary" onClick={this.onCancellationFailed} className="order-cancel-modal-ok-button">
            OK
          </Button>
        </div>
      ),
      customModalWrapperClassName: 'order-cancel-failed-content-wrapper',
    };
  };

  getModalContent = () => {
    const { jobId, jobsById } = this.props;
    const cancelOrderApiStatus = _.get(jobsById, [jobId, 'OrderInfo', 'cancelOrderApiStatus'], {});
    const statusCode = _.get(cancelOrderApiStatus, ['error', 'status'], null);
    let failedMessage = 'You have already viewed the candidates, and you cannot cancel it.';
    const errorMessage = _.get(cancelOrderApiStatus, ['error', 'data', 'ErrorMessage']);
    if (errorMessage?.includes('Cancelled')) {
      failedMessage = 'You have already cancelled this order.';
    }
    if (_.get(cancelOrderApiStatus, 'status') === 'COMPLETED') {
      return this.getCancellationSuccessContent();
    }
    if (statusCode === 400) {
      return this.getCancellationFailedContent(failedMessage);
    }
    return this.getCancellationPendingContent();
  };

  render() {
    const { jobId, jobsById, productVariantsById } = this.props;
    const { modalVisibility } = this.state;
    const sourcedCandidateCount = _.get(jobsById, [jobId, 'sourcingStats', 'PublishedStats', 'Sourced'], 0);
    const engagedCandidatesCount = _.get(jobsById, [jobId, 'sourcingStats', 'PublishedStats', 'Engaged'], 0);
    const updateOrderApiStatus = _.get(jobsById, [jobId, 'OrderInfo', 'updateOrderApiStatus'], {});
    const orderInfo = _.get(jobsById, [jobId, 'OrderInfo'], {});
    const orderStatus = _.get(orderInfo, ['Status'], '');

    const productVariantId = _.get(orderInfo, 'ProductVariantId', '');
    const productVariant = productVariantsById[productVariantId];
    const productVariantName = _.get(productVariant, 'Name', null);

    return (
      <>
        {orderStatus.toLowerCase() === 'cancelled' ? (
          <OrderCancelled />
        ) : (
          <OrderCancellationComponent
            invoiceNumber={_.get(orderInfo, ['InvoiceNumber'])}
            candidateCount={
              productVariantName === 'Basic' ? sourcedCandidateCount : engagedCandidatesCount + sourcedCandidateCount
            }
            viewCandidates={this.onViewOrder}
            showCancelConfirmation={this.toggleCancelOrderModalVisibility}
            updateOrderApiStatus={updateOrderApiStatus}
          />
        )}
        <CancelOrderModal
          visible={modalVisibility}
          closeModal={this.toggleCancelOrderModalVisibility}
          modalContent={this.getModalContent()}
        />
      </>
    );
  }
}

export { OrderCancellationContainer as OrderCancellationContainerWithoutStore };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderCancellationContainer));
