import React from 'react';
import { Icon } from 'antd';

function LinkedInGreySVG(props) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="New-Users" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M14.4773339,0.979166667 L0.847684191,0.979166667 C0.474269131,0.979166667 0.194207836,1.25933948 0.194207836,1.63290323 L0.194207836,15.361371 C0.194207836,15.6415439 0.474269131,15.9217167 0.847684191,15.9217167 L14.5706876,15.9217167 C14.9441027,15.9217167 15.224164,15.6415439 15.224164,15.2679801 L15.224164,1.63290323 C15.1308102,1.25933948 14.8507489,0.979166667 14.4773339,0.979166667 L14.4773339,0.979166667 Z M4.58183479,13.6803342 L2.43469819,13.6803342 L2.43469819,6.58262292 L4.67518855,6.58262292 L4.67518855,13.6803342 L4.58183479,13.6803342 Z M3.55494337,5.64871354 C2.80811325,5.64871354 2.24799066,4.99497698 2.24799066,4.34124042 C2.24799066,3.59411292 2.80811325,3.03376729 3.55494337,3.03376729 C4.30177349,3.03376729 4.86189608,3.59411292 4.86189608,4.34124042 C4.76854232,4.99497698 4.20841973,5.64871354 3.55494337,5.64871354 L3.55494337,5.64871354 Z M12.8903199,13.6803342 L10.6498295,13.6803342 L10.6498295,10.2248695 C10.6498295,9.38435104 10.6498295,8.35705073 9.52958433,8.35705073 C8.40933915,8.35705073 8.22263162,9.2909601 8.22263162,10.2248695 L8.22263162,13.7737251 L5.98214126,13.7737251 L5.98214126,6.58262292 L8.12927785,6.58262292 L8.12927785,7.51653229 L8.12927785,7.51653229 C8.40933915,6.95618667 9.15616927,6.39584104 10.1830607,6.39584104 C12.423551,6.39584104 12.7969661,7.89009604 12.7969661,9.75791479 L12.7969661,13.6803342 L12.8903199,13.6803342 Z"
          id="Shape"
          fill="#808489"
        ></path>
      </g>
    </svg>
  );
}

const LinkedInGreyIcon = props => <Icon component={LinkedInGreySVG} {...props} />;

export default LinkedInGreyIcon;
