import React from 'react';
import { Icon } from 'antd';
import ReviewCommentDetails from './ReviewCommentDetails';
import './ReviewCommentsHeader.scss';

function ReviewCommentsHeader(props) {
  const { onClickSeeDetails, isExpanded, jobId, AppName } = props;
  return (
    <div className="review-comment">
      <div className="review-comment-title">
        <Icon type="exclamation-circle" className="review-comment-exclamation" />
        Review comments
      </div>
      <div className="review-comment-description">
        <span>There seems to be a problem in finding candidates for this job.</span>
        <div onClick={onClickSeeDetails} role="presentation" className="see-details-wrapper">
          <span className="see-details">See details</span>
          <span className="arrow-icon">
            <Icon type={isExpanded ? 'down' : 'right'} />
          </span>
        </div>
      </div>
      {isExpanded ? <ReviewCommentDetails onClose={onClickSeeDetails} jobId={jobId} AppName={AppName} /> : null}
    </div>
  );
}

export default ReviewCommentsHeader;
