import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Select, Tooltip } from 'antd';
import _ from 'lodash';
import './RecruiterSelect.scss';
import * as UserActions from '../../Actions/UserActions';
import {
  getUserListForRecruiterSelect,
  getMaxCount,
  getUsersById,
  getRecruiterDropdownSelectedOrg,
} from '../../Reducers/UserReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getConfig } from '../../Reducers/ConfigReducer';

const { Option } = Select;

const mapStateToProps = state => ({
  userIds: getUserListForRecruiterSelect(state),
  selectedOrgId: getRecruiterDropdownSelectedOrg(state),
  usersById: getUsersById(state),
  userTotal: getMaxCount(state),
  userConfig: getConfig(state) || {},
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  fetchUsersForRecruiterSelect: UserActions.fetchUsersForDropdownLists,
};

class RecruiterSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSearch = this.onSearch.bind(this);
    this.fetchUsers = debounce(this.fetchUsers, 150);
    this.handleScroll = this.handleScroll.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { fetchUsersForRecruiterSelect, userConfig, selectedOrgId } = this.props;
    if (!userConfig.IsManagementUser) {
      fetchUsersForRecruiterSelect({ page: 1, searchTerm: null, listFor: 'RecruiterSelect' });
    } else {
      fetchUsersForRecruiterSelect({ page: 1, searchTerm: null, listFor: 'RecruiterSelect' }, selectedOrgId);
    }
  }

  handleScroll(e) {
    const { SearchTerm } = this.state;
    const { userIds, userTotal } = this.props;
    e.persist();
    // if (e.target.scrollTop + e.target.offsetHeight >= 0.5 * e.target.scrollHeight && clients.length < clientTotal) {
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 && userIds.length < userTotal) {
      // scrollToEnd, do something!!!
      this.fetchUsers(userIds.length / 10 + 1, SearchTerm);
    }
  }

  fetchUsers(page, newSearchTerm) {
    const { fetchUsersForRecruiterSelect, userConfig, selectedOrgId } = this.props;
    const { LastFetchedPage, SearchTerm } = this.state;
    if (LastFetchedPage !== page || newSearchTerm !== SearchTerm) {
      this.setState({
        SearchTerm: newSearchTerm,
        LastFetchedPage: page,
      });
      if (!userConfig.IsManagementUser) {
        fetchUsersForRecruiterSelect({ page, searchTerm: newSearchTerm, listFor: 'RecruiterSelect' });
      } else {
        fetchUsersForRecruiterSelect({ page, searchTerm: newSearchTerm, listFor: 'RecruiterSelect' }, selectedOrgId);
      }
    }
  }

  onChange(value) {
    const { fetchJobsByUser, userConfig, currentUser, usersById } = this.props;
    if (!userConfig.IsManagementUser) {
      if (value === undefined) {
        fetchJobsByUser(undefined);
      }
    } else if (value) {
      localStorage.setItem('ManagementUser', currentUser.sub);
      localStorage.setItem('Impersonate', usersById[value].UserGuid);
      const impersonatedUser = usersById[value];
      localStorage.setItem('ImpersonatedUser', JSON.stringify(impersonatedUser));
      window.location.reload();
    } else {
      localStorage.removeItem('ManagementUser');
      localStorage.removeItem('Impersonate');
      localStorage.setItem('ImpersonatedUser');
      window.location.reload();
    }
  }

  onSearch(value) {
    const { SearchTerm } = this.state;
    if (value.trim() !== SearchTerm) {
      this.setState({
        LastFetchedPage: 1,
      });
      this.fetchUsers(1, value);
    }
  }

  resetList() {
    this.setState({ LastFetchedPage: 1 });
    this.fetchUsers(1);
  }

  render() {
    const { userIds, currentUser, usersById, fetchJobsByUser, selectedUser, userConfig, vaultAccess } = this.props;
    const _userIds =
      selectedUser && !['unassigned-jobs', 'all-users'].includes(selectedUser) ? [...userIds, selectedUser] : userIds;
    const options = _.uniq(_userIds)
      .filter(id => usersById[id].Email !== currentUser.email)
      .map(id => {
        const user = usersById[id];
        const { FullName, IsActive, IsAdmin, Email } = user;
        return (
          <Option value={id} key={id} className={!IsActive ? 'inactive' : ''}>
            <Tooltip
              title={`
              ${Email}
              ${IsAdmin ? ' (Admin)' : ''}
              ${!IsActive ? ' (Inactive)' : ''}
            `}
            >
              {FullName}
              {IsAdmin ? ' (Admin)' : ''}
            </Tooltip>
          </Option>
        );
      });

    const isUserInactive = usersById[selectedUser] && !usersById[selectedUser].IsActive;
    return (
      <Select
        showSearch
        placeholder="Select Recruiter"
        onChange={this.onChange}
        onSearch={this.onSearch}
        filterOption={false}
        allowClear // ={selectedUser !== undefined}
        onPopupScroll={this.handleScroll}
        onSelect={fetchJobsByUser}
        className={`recruiter-select${isUserInactive ? ' inactive' : ''}`}
        dropdownClassName="recruiter-select-dropdown"
        onDropdownVisibleChange={visible => {
          if (visible === false) {
            this.resetList();
          }
        }}
        value={selectedUser}
      >
        {!userConfig.IsManagementUser && vaultAccess ? <Option value="unassigned-jobs">Unassigned Jobs</Option> : null}
        {!userConfig.IsManagementUser ? <Option value="all-users">All Users</Option> : null}
        {options}
      </Select>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSelect);

export { RecruiterSelect };
