import * as regionRepository from '../Repository/RegionRepository';
import { setNotification } from './ActionCreators/ConnectActions';
import { setRegions, setRegion, removeRegion } from './ActionCreators/RegionActionCreator';

function fetchRegionsByCountryCode({ countryCode }) {
  return async dispatch => {
    try {
      const regions = await regionRepository.fetchRegionsByCountryCode({ countryCode });
      dispatch(setRegions({ regions: regions.data }));
    } catch (error) {
      dispatch(setRegions({ regions: [] }));
    }
  };
}

function fetchRegions() {
  return async dispatch => {
    try {
      const regions = await regionRepository.fetchRegions();
      dispatch(setRegions({ regions: regions.data }));
    } catch (error) {
      dispatch(setRegions({ regions: [] }));
    }
  };
}

function addRegion({ regionCreationPayload }) {
  return async dispatch => {
    try {
      const response = await regionRepository.addRegion({ regionCreationPayload });
      const id = response.data[0];
      dispatch(setRegion({ id, regionDetails: { RegionId: id, ...regionCreationPayload.Regions[0] } }));
      dispatch(setNotification('SUCCESS', 'Region created successfully!'));
    } catch (error) {
      dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
    }
  };
}

function deleteRegion({ id }) {
  return async dispatch => {
    try {
      await regionRepository.deleteRegion({ id });
      dispatch(removeRegion({ id }));
      dispatch(setNotification('SUCCESS', 'Region deleted successfully!'));
    } catch (error) {
      dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
    }
  };
}

export { fetchRegionsByCountryCode, fetchRegions, addRegion, deleteRegion };
