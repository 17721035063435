import _ from 'lodash';

function SuggestionsReducer(state = {}, action = {}) {
  let newState;
  if (action.type === 'SET_SUGGESTIONS') {
    newState = _.cloneDeep(state);
    const { suggestionsResponse, suggestionsType, candidateId } = action.payload;
    const mergedSuggestions = { ...newState[candidateId], [suggestionsType]: suggestionsResponse };
    _.setWith(newState, [candidateId], mergedSuggestions, Object);
    return newState;
  }
  if (action.type === 'CLEAR_SUGGESTIONS') {
    newState = _.cloneDeep(state);
    const candidateId = action.payload;
    delete newState[candidateId];
    return newState;
  }
  if (action.type === 'SET_SKILL_SUGGESTIONS') {
    newState = _.cloneDeep(state);
    const { suggestions, uniqueIndex } = action.payload;
    _.setWith(newState, ['SkillSuggestions', uniqueIndex, 'suggestions'], suggestions, Object);
    return newState;
  }
  if (action.type === 'SET_SKILL_SUGGESTION_API_STATUS') {
    newState = _.cloneDeep(state);
    const { uniqueIndex, status } = action.payload;
    _.setWith(newState, ['SkillSuggestions', uniqueIndex, 'apiStatus'], status, Object);
    return newState;
  }
  return state;
}

function getSuggestions(state, candidateId) {
  return state.SuggestionsReducer[candidateId];
}

const getSkillsSuggestions = state => {
  return state.SuggestionsReducer.SkillSuggestions ?? {};
};

export { SuggestionsReducer, getSuggestions, getSkillsSuggestions };
