import React from 'react';
import _ from 'lodash';
import styles from './CandidateCardFooter.module.scss';
import CandidateCardMetaData from './CandidateCardMetaData/CandidateCardMetaData';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import { getIsMetadataCardVisible } from '../../Utils/CandidateMetadataUtils';

function CandidateCardFooter() {
  const { candidate, isDiversityAttributesVisible } = React.useContext(CandidateCardContext);

  const candidateMetadata = _.pick(candidate, [
    'Industry',
    'MoversPrediction',
    'RelocationStatus',
    'DerivedGender',
    'WorkPermitDocuments',
    'JobTypes',
    'Salaries',
    'DesiredJobTitles',
    'AuthorizedCountries',
    'UnauthorizedCountries',
    'Languages',
    'Availability',
    ['Derived'],
    'PreferredLocation',
  ]);

  const showMetaData = getIsMetadataCardVisible(
    { candidateMetadataDetails: candidateMetadata },
    isDiversityAttributesVisible
  );
  return showMetaData ? (
    <div className={styles.candidateCardFooter}>
      <CandidateCardMetaData candidate={candidate} isDiversityAttributesVisible={isDiversityAttributesVisible} />
    </div>
  ) : null;
}

export default CandidateCardFooter;
