import React from 'react';
import styles from './SettingsLayoutItem.module.scss';

export default function SettingsLayoutItem(props) {
  const { title, description, button, children } = props;
  return (
    <div className={styles.settingsLayoutItem}>
      <div className={styles.settingsLayoutItemHeader}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        {button}
      </div>
      {children}
    </div>
  );
}
