import { defineMessages } from 'react-intl';

const messages = defineMessages({
  prev: {
    id: 'CandidateDetail.prev',
    defaultMessage: 'Prev',
  },
  next: {
    id: 'CandidateDetail.next',
    defaultMessage: 'Next',
  },
  profile: {
    id: 'CandidateDetail.profile',
    defaultMessage: 'Profile',
  },
  personality: {
    id: 'CandidateDetail.personality',
    defaultMessage: 'Personality',
  },
  notes: {
    id: 'CandidateDetail.notes',
    defaultMessage: 'Notes',
  },
  candidateIntel: {
    id: 'CandidateDetail.candidateIntel',
    defaultMessage: 'Candidate Intel',
  },
});

export default messages;
