import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { EmptyCandidatesIcon } from '../../../Icons/AryaIcons';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getCandidateListError } from '../../../Reducers/CandidateReducer';

const QuickSearchEmptyScreen = props => {
  const { version, filterQueries, clearAggregationFilter, source } = props;
  const sourceName = getSourceName(source);
  const error = useSelector(state => getCandidateListError(state));
  const { Message: message, ErrorDescription: errorDescrption } = error || {};
  const defaultErrorMessage = 'No candidates found';
  const defaultErrorDescription = 'Please try changing options and search again';
  return (
    <Empty
      key="quick-search-empty-screen"
      image={<EmptyCandidatesIcon className="quick-serach-empty-list" />}
      description={
        <div>
          <div className="quick-search-no-candidates-found">{message ?? defaultErrorMessage}</div>
          <div className="quick-search-no-candidates-found-message">{errorDescrption ?? defaultErrorDescription}</div>
          {clearAggregationFilter && !_.isEmpty(filterQueries?.[sourceName]) ? (
            <div
              className="clear-aggregation-filter-button"
              role="button"
              tabIndex={0}
              onClick={() => clearAggregationFilter(sourceName)}
              onKeyPress={() => clearAggregationFilter(sourceName)}
            >
              Clear Filter
            </div>
          ) : null}
          {error && (error.code === 400 || error.code === 401) && version !== 'ats' ? (
            <Link to="/settings/app">
              <div style={{ cursor: 'pointer', marginTop: '10px' }}>Go to settings</div>
            </Link>
          ) : null}
        </div>
      }
    />
  );
};

const QuickSearchEmptyTabsScreen = () => (
  <Empty
    image={<EmptyCandidatesIcon className="quick-serach-empty-list" />}
    description={
      <div>
        <div className="quick-search-no-option-selected"> No candidates found</div>
        <div className="quick-search-no-candidates-found-message">Choose options and search to see candidates</div>
      </div>
    }
  />
);

export { QuickSearchEmptyScreen, QuickSearchEmptyTabsScreen };
