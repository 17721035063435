/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Input, Menu, Dropdown, Icon } from 'antd';
import classNames from 'classnames';
import getCaretCoordinates from 'textarea-caret';
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation';
import styles from './BooleanTextAreaStatic.module.scss';

const { TextArea } = Input;

const BooleanTextAreaStatic = ({
  uniqueIdentifier,
  onInputChange,
  options,
  value,
  style,
  onSelect,
  dropdownItemClassName,
  className,
  placeholder,
  onFocus,
  onClick,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const dropdownTriggerTarget = useRef(null);
  const dropdownRef = useRef(null);

  const keyBoardData = useKeyboardListNavigation({
    list: options,
    onEnter: () => {},
  });
  const { interactive, selected } = keyBoardData;

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (dropdownOpen && !dropdownRef?.current?.contains(e.target)) {
        setDropdownOpen(false);
      }
    };

    const handleEscapeClick = e => {
      if (e.keyCode === 27) {
        setDropdownOpen(false);
        keyBoardData.reset();
        setSelectedOption(null);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    document.addEventListener('keydown', handleEscapeClick);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
      document.removeEventListener('keydown', handleEscapeClick);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    keyBoardData.reset();
    setSelectedOption(null);
    setDropdownOpen(options.length > 0);
  }, [options]);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  function setDropdownCoordinates() {
    const textAreaRef = document.getElementById(`booleanTextArea-${uniqueIdentifier}`);
    const { top, left } = getCaretCoordinates(textAreaRef, textAreaRef.selectionEnd);
    setPositionX(left);
    setPositionY(top - (textAreaRef.scrollTop || 0));
  }

  useEffect(() => {
    setDropdownCoordinates();
  }, []);

  function handleInputChange(e) {
    setDropdownCoordinates();
    onInputChange(e);
  }

  function handleKeydownEvent(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const dropdownValue = selected;
      if (!interactive && options.length === 0) {
        return;
      }
      if (dropdownValue !== null || dropdownValue !== '') {
        e.preventDefault();
      }
      if (onSelect && dropdownOpen) {
        onSelect(selectedOption ?? options[0]);
      }
      setDropdownOpen(false);
      keyBoardData.reset();
      setSelectedOption(null);
    }
  }

  function onMenuItemClick(e) {
    if (onSelect) {
      onSelect(e.key);
    }
    setDropdownOpen(false);
    keyBoardData.reset();
  }

  function DropdownFooter() {
    return (
      <div
        style={{
          display: options.length > 1 ? 'flex' : 'none',
        }}
        className={styles.suggestionsDropdownFooter}
      >
        <div>
          <span>Also use</span> <Icon type="arrows-alt" rotate={-45} /> <span>arrows to select and</span>{' '}
          <Icon type="enter" /> to apply
        </div>
      </div>
    );
  }

  function handleMenuItemMouseOver(isMouseOver, option) {
    if (isMouseOver) {
      setSelectedOption(option);
      const index = options.findIndex(x => x === option);
      if (index >= 0) {
        keyBoardData.set({ cursor: index });
      }
    }
  }

  const menu = (
    <Menu onClick={onMenuItemClick}>
      {options.map(option => (
        <Menu.Item
          onMouseEnter={() => handleMenuItemMouseOver(true, option)}
          onMouseLeave={() => handleMenuItemMouseOver(false, option)}
          className={classNames(
            styles.suggestionDropdownItem,
            {
              [styles.liSelected]: selectedOption ? selectedOption === option : option === options[0],
            },
            {
              [dropdownItemClassName]: dropdownItemClassName,
            }
          )}
          key={option}
        >
          {option}
        </Menu.Item>
      ))}
      <DropdownFooter />
    </Menu>
  );

  return (
    <div className="boolean-static-text-area-container" style={{ position: 'relative' }}>
      <TextArea
        id={`booleanTextArea-${uniqueIdentifier}`}
        onFocus={onFocus}
        onChange={handleInputChange}
        onClick={onClick}
        style={style}
        value={value}
        onKeyDown={handleKeydownEvent}
        className={className}
        placeholder={placeholder}
      />
      <div ref={dropdownRef}>
        <Dropdown
          overlay={menu}
          visible={dropdownOpen && options.length > 0}
          overlayClassName={styles.suggestionsDropdown}
          getPopupContainer={node => node.parentNode}
        >
          <a
            ref={dropdownTriggerTarget}
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
            style={{
              position: 'absolute',
              left: `${positionX}px`,
              top: `${positionY - 20}px`,
              visibility: 'hidden',
            }}
          >
            Hover me
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default BooleanTextAreaStatic;
