import { Icon } from 'antd';
import * as React from 'react';

const OpenAiSvg = props => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_696_16618)">
      <path
        d="M18.7208 10.4856C18.6147 9.47713 18.1912 8.52832 17.5113 7.77611C17.7242 7.13644 17.7981 6.45877 17.7279 5.78836C17.6577 5.11795 17.4453 4.47011 17.1045 3.8885C16.5994 3.00869 15.8278 2.31216 14.9012 1.8993C13.9745 1.48658 12.9406 1.37878 11.9486 1.59174C11.5012 1.08758 10.9514 0.684621 10.3356 0.410176C9.71983 0.135732 9.05258 -0.0041294 8.37848 9.2826e-05C7.36435 -0.00228217 6.37569 0.317287 5.55487 0.913017C4.73404 1.50875 4.1238 2.34976 3.81175 3.31467C3.1511 3.44991 2.52687 3.72462 1.98102 4.12072C1.43516 4.51668 0.98022 5.0248 0.646665 5.61077C0.137623 6.4882 -0.0796896 7.50443 0.0261299 8.51341C0.131949 9.52213 0.555359 10.4713 1.23553 11.2239C1.02257 11.8636 0.948686 12.5413 1.01875 13.2117C1.08907 13.8821 1.30151 14.5298 1.64232 15.1116C2.1474 15.9914 2.91901 16.6879 3.84579 17.1006C4.7723 17.5135 5.80622 17.6211 6.79818 17.4083C7.2456 17.9125 7.79541 18.3153 8.4112 18.5899C9.02685 18.8642 9.69396 19.0042 10.3681 18.9998C11.3827 19.0025 12.372 18.6826 13.193 18.0865C14.0141 17.4905 14.6246 16.649 14.9362 15.6834C15.5969 15.548 16.221 15.2735 16.7668 14.8774C17.3127 14.4814 17.7676 13.9733 18.1013 13.3872C18.6098 12.5099 18.8266 11.4938 18.7205 10.4853L18.7208 10.4856Z"
        fill="black"
      />
      <path
        d="M13.0966 2.73337C13.7723 2.76239 14.4257 2.98327 14.9805 3.37C15.5352 3.75686 15.9684 4.29361 16.2294 4.91758C16.4902 5.54154 16.5679 6.22699 16.4535 6.89344C16.4256 6.87616 16.3765 6.8466 16.3411 6.82642L12.558 4.64115C12.4638 4.586 12.3565 4.5571 12.2474 4.5571C12.1383 4.5571 12.031 4.58613 11.9367 4.64115L7.31796 7.30801V5.46158C7.31744 5.45208 7.31915 5.44271 7.32324 5.43414C7.3272 5.42556 7.33314 5.41804 7.34079 5.41237L11.1651 3.20612C11.7508 2.86835 12.4209 2.70434 13.0965 2.73323L13.0966 2.73337Z"
        fill="white"
      />
      <path
        d="M9.37391 7.12402L11.4309 8.31139V10.6868L9.37391 11.8742L7.31689 10.6868V8.31205L9.37391 7.12402Z"
        fill="white"
      />
      <path
        d="M4.81787 4.80082C4.81827 4.12434 5.01143 3.46198 5.37468 2.89132C5.73792 2.32053 6.25607 1.86519 6.86882 1.57821C7.48157 1.29136 8.16319 1.18488 8.83413 1.27144C9.5052 1.35786 10.1376 1.63376 10.6575 2.06667C10.6286 2.08237 10.5784 2.11008 10.5451 2.13027L6.76194 4.31553C6.66707 4.3695 6.5883 4.44774 6.53355 4.54234C6.47905 4.63695 6.45042 4.74422 6.45108 4.85347L6.44844 10.1851L4.84914 9.26173C4.8407 9.25764 4.83344 9.25131 4.82803 9.24379C4.82262 9.23613 4.81906 9.22716 4.818 9.21793V4.80109L4.81787 4.80082Z"
        fill="white"
      />
      <path
        d="M1.371 8.93208C1.12677 8.02061 1.25384 7.04937 1.72448 6.23158C2.13984 5.50971 2.796 4.95713 3.57791 4.67041C3.57791 4.703 3.57606 4.76066 3.57606 4.80064V9.17104C3.5754 9.28029 3.60377 9.38756 3.65839 9.48216C3.71289 9.57676 3.79179 9.65501 3.88652 9.70897L8.50524 12.3756L6.9062 13.2989C6.89829 13.3041 6.88932 13.3072 6.87995 13.3082C6.87045 13.3091 6.86108 13.3075 6.85224 13.3039L3.02757 11.0939C2.21096 10.621 1.61523 9.84356 1.371 8.93222V8.93208Z"
        fill="white"
      />
      <path
        d="M4.88182 16.1477C3.96996 15.9035 3.19228 15.3073 2.71952 14.4905C2.30231 13.7696 2.15189 12.9251 2.29479 12.1044C2.32289 12.1213 2.37198 12.1512 2.40721 12.1715L6.19032 14.3568C6.28453 14.412 6.3918 14.441 6.50105 14.441C6.6103 14.441 6.71744 14.412 6.81164 14.3568L11.4304 11.6899V13.5365C11.4309 13.5459 11.429 13.5554 11.4251 13.5639C11.421 13.5725 11.4151 13.58 11.4075 13.5856L7.58326 15.7937C6.76533 16.2647 5.79369 16.3922 4.88196 16.1479L4.88182 16.1477Z"
        fill="white"
      />
      <path
        d="M13.9269 14.1972C13.9256 15.1406 13.5506 16.0451 12.8839 16.7125C12.217 17.38 11.3129 17.7559 10.3695 17.7579C9.53671 17.759 8.73 17.4675 8.09033 16.9339C8.11923 16.9183 8.16976 16.8905 8.20275 16.8703L11.9859 14.685C12.0809 14.6309 12.1596 14.5527 12.2143 14.4579C12.2689 14.3633 12.2974 14.2559 12.2967 14.1468V8.81348L13.8958 9.73669C13.9042 9.74091 13.9113 9.74712 13.9169 9.75477C13.9223 9.76242 13.9256 9.77126 13.9269 9.78063V14.1975V14.1972Z"
        fill="white"
      />
      <path
        d="M17.4877 11.2898C17.43 11.9641 17.1813 12.6078 16.771 13.1458C16.3605 13.6838 15.8054 14.0938 15.1705 14.3277V9.82656C15.1714 9.71758 15.1433 9.61031 15.089 9.51584C15.0347 9.42123 14.9564 9.34286 14.8619 9.28876L10.2432 6.6219L11.8422 5.69895C11.8501 5.69367 11.8591 5.6905 11.8686 5.68971C11.878 5.68879 11.8875 5.69037 11.8962 5.69406L15.7208 7.90202C16.3068 8.24059 16.7843 8.73881 17.0972 9.3389C17.4101 9.93885 17.5456 10.6155 17.488 11.2897L17.4877 11.2898Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_696_16618">
        <rect width="18.7471" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const OpenAiContrastIcon = props => <Icon component={() => OpenAiSvg(props)} {...props} />;
export default OpenAiContrastIcon;
