import { Icon } from 'antd';
import * as React from 'react';

const ChatGptSvg = props => (
  <svg width="17" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="20" height="20" rx="2" fill="#48A47F" />
    <g clipPath="url(#clip0_64_22219)">
      <path
        d="M17.123 10.8467C17.0422 10.0783 16.7194 9.35523 16.2013 8.78203C16.3636 8.29459 16.4199 7.77819 16.3664 7.26732C16.3129 6.75645 16.151 6.26277 15.8913 5.81957C15.5064 5.14914 14.9185 4.61836 14.2123 4.30376C13.5062 3.98925 12.7183 3.90711 11.9624 4.06939C11.6215 3.68521 11.2025 3.37814 10.7333 3.16901C10.264 2.95988 9.75558 2.8533 9.2419 2.85652C8.46911 2.85471 7.71573 3.09823 7.09024 3.55219C6.46475 4.00615 5.99973 4.64702 5.76194 5.3823C5.25852 5.48536 4.78284 5.6947 4.36688 5.99653C3.95093 6.29827 3.60425 6.68547 3.35007 7.13199C2.96217 7.80061 2.79657 8.575 2.87721 9.34387C2.95785 10.1125 3.2805 10.8359 3.79881 11.4094C3.63653 11.8968 3.58022 12.4132 3.63361 12.9241C3.6872 13.4349 3.84908 13.9285 4.10879 14.3718C4.49367 15.0423 5.08166 15.573 5.78788 15.8875C6.49391 16.2021 7.28178 16.2842 8.03767 16.122C8.37862 16.5062 8.79759 16.8131 9.26684 17.0224C9.73598 17.2314 10.2443 17.3381 10.758 17.3348C11.5312 17.3368 12.2851 17.0931 12.9107 16.6388C13.5364 16.1846 14.0016 15.5434 14.2391 14.8076C14.7425 14.7044 15.2181 14.4952 15.634 14.1934C16.05 13.8916 16.3967 13.5044 16.6509 13.0578C17.0384 12.3893 17.2036 11.615 17.1228 10.8465L17.123 10.8467Z"
        fill="white"
      />
      <path
        d="M12.8371 4.9391C13.352 4.96122 13.8499 5.12954 14.2727 5.42423C14.6954 5.71903 15.0255 6.12805 15.2244 6.60352C15.4231 7.079 15.4824 7.60133 15.3952 8.10918C15.3739 8.09601 15.3365 8.07349 15.3095 8.05811L12.4267 6.39288C12.3549 6.35085 12.2732 6.32884 12.19 6.32884C12.1069 6.32884 12.0251 6.35095 11.9532 6.39288L8.43366 8.4251V7.01807C8.43326 7.01083 8.43457 7.00369 8.43769 6.99716C8.4407 6.99062 8.44523 6.98489 8.45106 6.98057L11.3653 5.29936C11.8116 5.04196 12.3222 4.91698 12.837 4.939L12.8371 4.9391Z"
        fill="#48A47F"
      />
      <path
        d="M10.0006 8.28516L11.5681 9.18996V11.0001L10.0006 11.9049L8.43311 11.0001V9.19046L10.0006 8.28516Z"
        fill="#48A47F"
      />
      <path
        d="M6.52869 6.5145C6.52899 5.99901 6.67619 5.49427 6.95299 5.05941C7.22979 4.62446 7.62463 4.27748 8.09156 4.05879C8.55849 3.84021 9.0779 3.75907 9.58917 3.82503C10.1005 3.89088 10.5825 4.10112 10.9786 4.43101C10.9566 4.44297 10.9184 4.46409 10.8929 4.47947L8.01011 6.1447C7.93782 6.18582 7.8778 6.24544 7.83607 6.31753C7.79455 6.38962 7.77273 6.47137 7.77323 6.55462L7.77122 10.6174L6.55252 9.91382C6.54608 9.9107 6.54055 9.90588 6.53643 9.90015C6.53231 9.89431 6.52959 9.88748 6.52879 9.88044V6.5147L6.52869 6.5145Z"
        fill="#48A47F"
      />
      <path
        d="M3.90191 9.66351C3.7158 8.96895 3.81262 8.22884 4.17127 7.60566C4.48778 7.05558 4.98779 6.6345 5.58362 6.41602C5.58362 6.44085 5.58221 6.48479 5.58221 6.51525V9.8456C5.58171 9.92885 5.60333 10.0106 5.64495 10.0827C5.68648 10.1548 5.7466 10.2144 5.81879 10.2555L9.33837 12.2875L8.11986 12.9911C8.11383 12.9951 8.10699 12.9975 8.09985 12.9982C8.09262 12.9989 8.08548 12.9977 8.07874 12.995L5.16425 11.3108C4.54197 10.9505 4.08801 10.3581 3.90191 9.66361V9.66351Z"
        fill="#48A47F"
      />
      <path
        d="M6.57751 15.1616C5.88264 14.9754 5.29003 14.5212 4.92978 13.8987C4.61185 13.3494 4.49723 12.7058 4.60612 12.0805C4.62754 12.0933 4.66494 12.1162 4.69179 12.1316L7.57461 13.7969C7.6464 13.8389 7.72814 13.861 7.81139 13.861C7.89464 13.861 7.97629 13.8389 8.04808 13.7969L11.5676 11.7646V13.1718C11.568 13.1789 11.5666 13.1862 11.5636 13.1927C11.5605 13.1992 11.556 13.205 11.5503 13.2092L8.63606 14.8918C8.01278 15.2507 7.27237 15.3479 6.57761 15.1618L6.57751 15.1616Z"
        fill="#48A47F"
      />
      <path
        d="M13.4701 13.6748C13.4691 14.3937 13.1833 15.0829 12.6753 15.5915C12.1671 16.1001 11.4782 16.3866 10.7593 16.3881C10.1246 16.389 9.5099 16.1668 9.02246 15.7602C9.04448 15.7483 9.08299 15.7271 9.10813 15.7117L11.9909 14.0465C12.0633 14.0053 12.1234 13.9457 12.165 13.8735C12.2066 13.8014 12.2283 13.7195 12.2278 13.6364V9.57227L13.4463 10.2758C13.4528 10.279 13.4582 10.2837 13.4624 10.2896C13.4665 10.2954 13.4691 10.3021 13.4701 10.3093V13.675V13.6748Z"
        fill="#48A47F"
      />
      <path
        d="M16.1834 11.46C16.1394 11.9738 15.9499 12.4643 15.6372 12.8743C15.3244 13.2843 14.9014 13.5967 14.4176 13.775V10.345C14.4183 10.2619 14.3969 10.1802 14.3555 10.1082C14.3141 10.0361 14.2544 9.97636 14.1824 9.93514L10.6628 7.90293L11.8813 7.19962C11.8874 7.1956 11.8942 7.19318 11.9015 7.19258C11.9086 7.19188 11.9158 7.19308 11.9225 7.1959L14.837 8.87842C15.2835 9.13641 15.6474 9.51607 15.8857 9.97335C16.1242 10.4305 16.2275 10.9461 16.1836 11.4599L16.1834 11.46Z"
        fill="#48A47F"
      />
    </g>
    <defs>
      <clipPath id="clip0_64_22219">
        <rect width="14.2857" height="14.4785" fill="white" transform="translate(2.8573 2.85645)" />
      </clipPath>
    </defs>
  </svg>
);

const ChatGptIcon = props => <Icon component={() => ChatGptSvg(props)} {...props} />;
export default ChatGptIcon;
