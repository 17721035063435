import React from 'react';
import debounce from 'lodash/debounce';
import { searchZipCode, getZipCodes } from '../Repository/JobRepository';
import { getCityAndStateSuggestions } from '../Repository/LocationSearchRepository';
import { getOptionValueFromValue, locationMenuList } from '../Utils/LocationUtils';
import { validateZipCode } from '../Utils/FormValidators';

export default function useZipcodeLocation(props) {
  const [locations, setLocations] = React.useState([]);
  const [zipcodes, setZipcodes] = React.useState([]);
  const [isZipcodeValid, setZipcodeValidityStatus] = React.useState(true);
  const { form, zipcodeFieldDecorator, locationFieldDecorator, countryCode, stateWideSearchFieldDecorator } = props;

  const clearZipValidationError = () => {
    setZipcodeValidityStatus(true);
  };

  const filterLocations = debounce(async value => {
    let currentLocations;
    try {
      const response = await getCityAndStateSuggestions({
        location: value,
        country: countryCode,
      });
      currentLocations = response.data.results;
    } catch {
      currentLocations = [];
    }
    setLocations(currentLocations);
  }, 600);
  const parseValue = value => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };
  const callForZipCodes = debounce(async value => {
    let currentZipcodes;
    const parsedValue = parseValue(value);
    if (parsedValue?.state && !parsedValue?.city) {
      form.setFieldsValue({ [stateWideSearchFieldDecorator]: true, IsStateWideSearch: true });
    } else if (parsedValue?.city) {
      form.setFieldsValue({ [stateWideSearchFieldDecorator]: false, IsStateWideSearch: false });
    }
    const isStateWideSearchChecked = form.getFieldValue(stateWideSearchFieldDecorator);
    if (isStateWideSearchChecked) return;
    try {
      const response = await getZipCodes({
        country: countryCode,
        location: getOptionValueFromValue(parsedValue),
      });
      currentZipcodes = response.data;
    } catch {
      currentZipcodes = [];
    }
    setZipcodes(currentZipcodes);
  }, 600);

  const locationChange = value => {
    form.setFieldsValue({
      [zipcodeFieldDecorator]: undefined,
    });
    if (!value) {
      form.setFieldsValue({
        [locationFieldDecorator]: undefined,
        [stateWideSearchFieldDecorator]: undefined,
        IsStateWideSearch: undefined,
      });
      clearZipValidationError();
      setZipcodes([]);
      setLocations([]);
    }
  };

  const searchLocationFromZipcode = async params => {
    const { postalCode } = params;
    let currentLocations;
    try {
      const response = await searchZipCode({
        postalCode,
        countryCode,
      });
      currentLocations = response.data;
      const updatedLocation = { city: currentLocations[0]?.City, state: { name: currentLocations[0]?.State } };
      setLocations([updatedLocation]);
    } catch {
      currentLocations = [];
      setLocations(currentLocations);
    }
    if (currentLocations.length)
      form.setFieldsValue({ [locationFieldDecorator]: locationMenuList(currentLocations)[0] });
    setZipcodeValidityStatus(!!currentLocations.length);
  };

  const onZipcodeChange = debounce(value => {
    validateZipCode(searchLocationFromZipcode, clearZipValidationError, zipcodes, countryCode, value, !isZipcodeValid);
  }, 600);

  return {
    locations,
    zipcodes,
    isZipcodeValid,
    locationChange,
    callForZipCodes,
    filterLocations,
    onZipcodeChange,
    setLocations,
    setZipcodes,
  };
}
