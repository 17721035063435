function MoverTitle(props) {
  switch (props.type) {
    case 'LowMover':
      return 'Low mover';
    case 'MediumMover':
      return 'Medium mover';
    case 'HighMover':
      return 'High mover';
    case 'LowNonMover':
      return 'Low non mover';
    case 'MediumNonMover':
      return 'Medium non mover';
    case 'HighNonMover':
      return 'High non mover';
    default:
      return null;
  }
}

export default MoverTitle;
