import React from 'react';
import { Button } from 'antd';
import { getEventNameByReportType } from '../../../../../../Analytics/Report/ReportEventUtils';

export default function SaveQueryButton(props) {
  const { onClick, disabled, loading, buttonText = 'Save Query', type } = props;
  return (
    <Button
      shape="round"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      sk-event-name={getEventNameByReportType(type, 'saveQuery')}
    >
      {buttonText}
    </Button>
  );
}
