import React from 'react';
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';
import { prepareDataForIndustryRelevanceSankeyDiagram } from '../../../Utils/CandidateIntelChartUtils';
import './IndustryRelevancySankeyChart.scss';

const color = d3.scaleOrdinal(d3.schemeCategory10);

export function showTooltip(d) {
  const { relevancy, source, target } = d;
  const industryRelevancyPercentage = relevancy.toFixed(2);
  const tooltipContent = `
    <div class="industry-relevance-tooltip-content">
      <p class="industry-relevance-percentage">${industryRelevancyPercentage}%</p>
      <div class="industry-relevancy-description">
       <div class="industry-relevancy-color"></div>
       <div class="industry-relevancy-text">${source.name} -> ${target.name}</div>
      </div>
    </div>
  `;

  const offsetX = (source.x0 + target.x1) / 2;
  const offsetY = Math.min(source.y0, target.y1);
  const tooltip = d3.select('.industry-relevance-tooltip').html(tooltipContent);
  tooltip
    .style('opacity', 1)
    .style('top', `${offsetY}px`)
    .style('left', `${offsetX}px`);

  tooltip.select('.industry-relevancy-color').style('background-color', color(source.id));
}

export function hideTooltip() {
  d3.select('.industry-relevance-tooltip').style('opacity', 0);
}

export function createSankeyChart(nodeId, IndustryRelevance, width) {
  const height = 350;
  const linkStorkeOpacity = 0.5;
  const labelFontSize = 10;
  const labelMargin = '0.35em';

  const preparedData = prepareDataForIndustryRelevanceSankeyDiagram(IndustryRelevance);

  const sankey = d3Sankey
    .sankey()
    .nodeId(d => d.id)
    .nodeWidth(15)
    .nodePadding(10)
    .extent([
      [1, 5],
      [width - 1, height - 5],
    ]);

  const sankeyData = data => {
    const nodeList = data.nodes;
    const linkList = data.links;
    return sankey({
      nodes: nodeList.map(d => ({ ...d})),
      links: linkList.map(d => ({ ...d})),
    });
  };

  const { nodes, links } = sankeyData(preparedData);
  d3.selectAll(`${nodeId} svg`).remove();
  const svg = d3
    .select(nodeId)
    .append('svg')
    .attr('width', width)
    .attr('height', height);

  d3.select(nodeId)
    .append('div')
    .classed('industry-relevance-tooltip', true);

  svg
    .append('g')
    .selectAll('rect')
    .data(nodes)
    .enter()
    .append('rect')
    .attr('x', d => d.x0)
    .attr('y', d => d.y0)
    .attr('height', d => d.y1 - d.y0)
    .attr('width', d => d.x1 - d.x0)
    .attr('fill', d => color(d.id));

  const link = svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke-opacity', linkStorkeOpacity)
    .selectAll('g')
    .data(links)
    .enter()
    .append('g')
    .style('mix-blend-mode', 'multiply');

  const gradient = link
    .append('linearGradient')
    .attr('id', d => d.id)
    .attr('gradientUnits', 'userSpaceOnUse')
    .attr('x1', d => d.source.x1)
    .attr('x2', d => d.target.x0);

  gradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', d => color(d.source.id));

  gradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', d => color(d.target.id));

  link
    .append('path')
    .attr('d', d3Sankey.sankeyLinkHorizontal())
    .attr('stroke', d => `url(#${d.id})`)
    .attr('stroke-width', d => Math.max(1, d.width))
    .on('mouseover', showTooltip)
    .on('mouseout', hideTooltip);

  svg
    .append('g')
    .attr('font-size', labelFontSize)
    .selectAll('text')
    .data(nodes)
    .enter()
    .append('text')
    .attr('x', d => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
    .attr('y', d => (d.y1 + d.y0) / 2)
    .attr('dy', labelMargin)
    .attr('text-anchor', d => (d.x0 < width / 2 ? 'start' : 'end'))
    .text(d => d.name);
}

class IndustryRelevancySankeyChart extends React.Component {
  componentDidMount() {
    this.createIndustryRelevanceChart();
  }

  componentDidUpdate() {
    this.createIndustryRelevanceChart();
  }

  createIndustryRelevanceChart() {
    const { IndustryRelevance } = this.props;
    const { clientWidth } = this.node;
    createSankeyChart('#industryRelevanceSankeyChart', IndustryRelevance, clientWidth);
  }

  render() {
    return (
      <div
        ref={node => {
          this.node = node;
        }}
        id="industryRelevanceSankeyChart"
      ></div>
    );
  }
}

export default IndustryRelevancySankeyChart;
