import React from 'react';
import { Button } from 'antd';

export default function MoreMenuButton(props) {
  const { size } = props;
  return <Button shape="circle" size={size} icon="more" />;
}

MoreMenuButton.defaultProps = {
  size: 'default',
};
