import React from 'react';
import { connect } from 'react-redux';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as candidateActions from '../../Actions/CandidateActions';
import * as candidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import { getCandidateActivityLogs, getCandidateActivityLogsCount } from '../../Reducers/CandidateReducer';
import CandidateActivityWrapper from '../../Components/CandidateActivityLogs/CandidateActivityWrapper';

const mapStateToProps = (state, props) => ({
  initialFetchActivityLogsApiStatus: getApiStatus(state, 'initialFetchActivityLogsApiStatus'),
  candidateActivityLogs: getCandidateActivityLogs(state, props?.candidate?.Id),
  candidateActivityLogsCount: getCandidateActivityLogsCount(state, props?.candidate?.Id),
});

const mapDispatchToProps = {
  fetchCandidateActivityLogs: candidateActions.fetchCandidateActivityLogs,
  clearCandidateActivityLogs: candidateActionCreators.clearCandidateActivityLogs,
};

function CandiateActivityContainer(props) {
  const {
    clearCandidateActivityLogs,
    fetchCandidateActivityLogs,
    candidateActivityLogsCount,
    candidateActivityLogs,
    initialFetchActivityLogsApiStatus,
    candidate,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
  } = props;
  return (
    <CandidateActivityWrapper
      fetchCandidateActivityLogs={fetchCandidateActivityLogs}
      candidate={candidate}
      clearCandidateActivityLogs={clearCandidateActivityLogs}
      candidateActivityLogs={candidateActivityLogs}
      candidateActivityLogsCount={candidateActivityLogsCount}
      initialFetchActivityLogsApiStatus={initialFetchActivityLogsApiStatus}
      setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      version={version}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandiateActivityContainer);
