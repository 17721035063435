import React from 'react';
import { Select } from 'antd';
import filterSelectSearch from '../../Utils/FilterSearchUtils';
import './DropdownWithLabel.scss';

const { Option } = Select;

class DropdownWithLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.renderOnlySelect = this.renderOnlySelect.bind(this);
  }

  handleChange = (value, option) => {
    const newSelectedValue = option && option.key ? option.key : undefined;

    const { onChange } = this.props;
    if (onChange) {
      onChange(newSelectedValue);
    }
  };

  renderOnlySelect() {
    const { placeholderValue, optionValues, cssClassname, initialValue, disabled, showArrow = true } = this.props;
    return (
      <Select
        placeholder={placeholderValue}
        className={cssClassname}
        dropdownClassName="select-with-label-dropdown"
        value={initialValue}
        getPopupContainer={node => node.parentNode}
        onChange={this.handleChange}
        showSearch
        optionFilterProp="value"
        filterOption={(input, option) => filterSelectSearch(input, option.props.value)}
        disabled={disabled}
        showArrow={showArrow}
      >
        {optionValues.sort().map(value => (
          <Option key={value} value={value}>
            <span className="select-with-label-value">{value}</span>
          </Option>
        ))}
      </Select>
    );
  }

  render() {
    const { label } = this.props;
    if (label) {
      return (
        <div className="dropdown-with-label-wrapper">
          <div className="dropdown-with-label">{label}</div>
          {this.renderOnlySelect()}
        </div>
      );
    }
    return <div>{this.renderOnlySelect()}</div>;
  }
}

export default DropdownWithLabel;
