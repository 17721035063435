import React from 'react';
import { Row, Col, Form, Select, Divider, Button, InputNumber } from 'antd';
import { getSalaryRangeNormalizer } from '../../../Utils/AdvanceRefineIntelUtils';
import styles from './SalarySelection.module.scss';
import selectionStyles from './AdvancedJobDetailsSelection.module.scss';

export const validateMinSalary = (form, value, callback) => {
  const maxSalary = form.getFieldValue('MaxSalary');
  if (maxSalary !== undefined && maxSalary !== '' && maxSalary !== null) {
    if (maxSalary && (value === undefined || value === '' || value === null)) {
      callback('Min Salary is required');
    } else if (Number(value) > Number(maxSalary)) {
      callback("Min Salary can't be greater than Max Salary");
    } else {
      callback();
    }
  } else {
    callback();
  }
  form.validateFields(['MaxSalary']);
};

export const validateMaxSalary = (form, value, callback) => {
  const minSalary = form.getFieldValue('MinSalary');
  if (minSalary !== undefined && minSalary !== '' && minSalary !== null) {
    if (minSalary && (value === undefined || value === '' || value === null)) {
      callback('Max Salary is required');
    } else if (Number(value) < Number(minSalary)) {
      callback("Max Salary can't be less than Min Salary");
    } else {
      callback();
    }
  } else {
    callback();
  }
  form.validateFields(['MinSalary']);
};

export default function SalarySelection(props) {
  const {
    form,
    candidateSpecifications,
    jobDetails: { JobAdvanceInfo = {} },
    salaryId,
  } = props;
  const CompensationTypeOptions = candidateSpecifications?.PayFrequencies || [];
  const compensationType = form.getFieldValue('CompensationType');
  const salaryNormalizer = getSalaryRangeNormalizer(compensationType);
  const initialCompensationType = JobAdvanceInfo?.Salary?.CompensationType;
  const initialCurrency = JobAdvanceInfo?.Salary?.Currency || 'USD';
  const minSalary = JobAdvanceInfo?.Salary?.MinSalary;
  const maxSalary = JobAdvanceInfo?.Salary?.MaxSalary;

  const resetSalaryDetails = () => {
    form.setFieldsValue({
      CompensationType: undefined,
      MinSalary: undefined,
      MaxSalary: undefined,
    });
  };

  return (
    <div id={salaryId} className={selectionStyles.sectionWrapper}>
      <div className={selectionStyles.title}>Salary</div>
      <span className={selectionStyles.subTitle}>Select salary range (USD) for this job</span>
      <Row className={styles.salaryWrapper}>
        <Col span={3}>
          <Form.Item>
            {form.getFieldDecorator('Currency', {
              initialValue: initialCurrency,
            })(
              <Select placeholder="Select Currency">
                <Select.Option key="USD" value="USD">
                  USD
                </Select.Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            {form.getFieldDecorator('CompensationType', {
              initialValue: initialCompensationType || undefined,
            })(
              <Select placeholder="Pay Scale" key="CompensationType">
                {CompensationTypeOptions.map(item => (
                  <Select.Option value={item?.Name} key={item?.Code}>
                    {item?.Name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <div className={styles.minMaxSalary}>
            <Form.Item colon={false}>
              {form.getFieldDecorator('MinSalary', {
                initialValue: minSalary,
                rules: [{ validator: (rule, value, callback) => validateMinSalary(form, value, callback) }],
              })(<InputNumber placeholder="Min" style={{ width: '85%' }} min={1} step={salaryNormalizer} />)}
            </Form.Item>
            <Form.Item colon={false}>
              {form.getFieldDecorator('MaxSalary', {
                initialValue: maxSalary,
                rules: [{ validator: (rule, value, callback) => validateMaxSalary(form, value, callback) }],
              })(<InputNumber placeholder="Max" style={{ width: '85%' }} min={1} step={salaryNormalizer} />)}
            </Form.Item>
          </div>
        </Col>
        <Col span={5}>
          <Button type="secondary" className={styles.resetSalary} onClick={resetSalaryDetails}>
            Clear
          </Button>
        </Col>
      </Row>
      <Divider />
    </div>
  );
}
