import { parseJobDescription, suggestJobDescription } from '../Repository/AryaJobDescriptionRepository';
import * as ActionCreators from './ActionCreators/AryaJobDescriptionActionCreator';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { parseHtmlDescriptionForData, mergeLocalAndApiParserData } from '../Utils/JobDescriptionUtils';

export function parseJD(jobDescription) {
  return async dispatch => {
    try {
      dispatch(ActionCreators.setJobParserApiStatus('INPROGRESS'));
      const localParsingData = parseHtmlDescriptionForData(jobDescription);
      const parsingApiResponse = await parseJobDescription(jobDescription);
      const apiParsingData = parsingApiResponse?.data;
      const parsingDataModel = mergeLocalAndApiParserData(localParsingData, apiParsingData);
      await dispatch(ActionCreators.setParsedJobData(parsingDataModel));
      dispatch(ActionCreators.setJobParserApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(ActionCreators.setJobParserApiStatus('FAILED'));
    }
  };
}

export const resetJDParsingInformation = {
  type: ActionCreators.RESET_PARSED_JOB_DATA,
};

export function suggestJD(suggestionQuery) {
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const isJobDescriptionSuggestionEnabled = featureToggleList?.JobDescriptionTemplates?.IsEnabled;
    if (!isJobDescriptionSuggestionEnabled) return;
    try {
      dispatch(ActionCreators.setJobDescriptionSuggestionApiStatus('INPROGRESS'));
      const suggestionResponse = await suggestJobDescription(suggestionQuery);
      const suggestedJobDescription = suggestionResponse?.data;
      await dispatch(ActionCreators.setJobDescriptionSuggestionData(suggestedJobDescription));
      dispatch(ActionCreators.setJobDescriptionSuggestionApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(ActionCreators.setJobDescriptionSuggestionApiStatus('FAILED'));
    }
  };
}
