import React from 'react';

export default function DonutBackground(props) {
  const { width, height } = props;
  return (
    <defs>
      <filter id="inner-shadow" y="-20" x="-20" height={height} width={width}>
        <feGaussianBlur result="offset-blur" stdDeviation="5" />
        <feOffset dx="1" dy="1" />
        <feFlood floodColor="black" floodOpacity="1" result="color" />
        <feComposite operator="in" in2="offset-blur" />
        <feComponentTransfer>
          <feFuncA slope="0.5" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode></feMergeNode>
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );
}
