import React, { useCallback } from 'react';
import { Drawer, Empty, Table, Affix, Button } from 'antd';
import styles from './WorkflowReport.module.scss';

function ReportModal(props) {
  const { isVisible, setWorkflowReportModalVisibility } = props;
  const reportData = [];
  const hideReportModal = useCallback(() => setWorkflowReportModalVisibility(false));
  const onCancel = () => hideReportModal();

  const column = [
    {
      title: 'Recipient Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
  ];

  return (
    <Drawer
      destroyOnClose
      visible={isVisible}
      title="Workflow Report"
      placement="right"
      onClose={onCancel}
      closable
      bodyStyle={{ padding: '0' }}
      width="650"
    >
      {reportData.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Table dataSource={reportData} scroll={{ y: 451 }} columns={column} bordered pagination={false} />
          <Affix offsetBottom={1}>
            <Footer onCancel={onCancel} />
          </Affix>
        </>
      )}
    </Drawer>
  );
}

const Footer = ({ onCancel }) => (
  <div className={styles.drawerFooter}>
    <Button type="secondary" onClick={onCancel} shape="round">
      Cancel
    </Button>
    <Button type="primary" shape="round">
      Download
    </Button>
  </div>
);

export default ReportModal;
