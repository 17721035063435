import React from 'react';
import { getDatafromPath } from '../../../../../../Utils/LodashUtils';
import GenericDonut from '../../../../../D3Charts/GenericDonut';
import StatsDetails from '../../StatsDetails/StatsDetails';
import StatsView from '../../StatsDetails/StatsView/StatsView';
import styles from './CallStats.module.scss';

const colors = ['#13C26B', '#F5222D', '#DADBDD'];

export default function CallStats(props) {
  const { callStats, subtitle } = props;

  const totalCalls = getDatafromPath(callStats, ['total'], 0);
  const connectedCalls = getDatafromPath(callStats, ['data', 0, 'Value'], 0);
  const rejectedCalls = getDatafromPath(callStats, ['data', 1, 'Value'], 0);

  return (
    <StatsDetails title="Call" subtitle={subtitle}>
      <div className={styles.callStats}>
        <div className={styles.callStatsView}>
          <StatsView title="Total calls made" count={totalCalls} />
          <StatsView title="Calls connected" count={connectedCalls} type="success" />
          <StatsView title="Calls rejected" count={rejectedCalls} type="error" />
        </div>
        <div>
          <GenericDonut
            data={callStats?.data}
            width={208}
            height={208}
            title={totalCalls}
            subtitle="Total"
            colors={colors}
            showStatsText={false}
            chartTitle="Call"
            thickness={35}
          />
        </div>
      </div>
    </StatsDetails>
  );
}
