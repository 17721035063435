import React from 'react';
import { Button, Form } from 'antd';
import NotesContext from './NotesContext';

function NotesCancelButton() {
  const { onCancel } = React.useContext(NotesContext);

  const handleButtonClick = e => {
    e.preventDefault();
    onCancel();
  };

  return (
    <Form.Item>
      <Button onClick={handleButtonClick} shape="round">
        Cancel
      </Button>
    </Form.Item>
  );
}

export default NotesCancelButton;
