import React from 'react';
import { Row, Col, Tooltip, Badge, Empty, Skeleton } from 'antd';
import styles from './Insight.module.scss';
import { EmptyExperienceIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';

const experienceColors = ['#FDAD61', '#DAEE8B', '#A5D96A', '#65BD63', '#006837'];

export default function ExperienceInsightView(props) {
  const { listOfExperienceRangeStats, loading, readonlyStats, emptyInsightDescription } = props;
  const experienceInsightTitle = <div className={styles.headerTitle}>Experience</div>;
  const experienceInsightDescription = (
    <div className={styles.headerDescription}>Distribution of years of experience among comparable candidates</div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.experience.id}>
      <div className={styles.headerSupplyInfo} id="ExperienceHeader">
          <div className={styles.headerInfo}>{experienceInsightTitle}</div>
          {experienceInsightDescription}
        </div>
        <div>
          <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
            {listOfExperienceRangeStats.length > 0 ? (
              <>
                <Row>
                  <div className={styles.experienceColorMapView}>
                    <div style={{ display: 'flex' }}>
                      {listOfExperienceRangeStats.map((experience, index) => (
                        <Tooltip
                          key={experience.DisplayName}
                          title={`${experience.DisplayName} (${experience.Value} Profiles)`}
                        >
                          <div
                            style={{
                              width: `${experience.SupplyPercentage}%`,
                              'background-color': experienceColors[index],
                            }}
                          >
                            <span>{experience.Name}</span>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="experience-list">
                    {listOfExperienceRangeStats.slice(0, 5).map((item, i) => (
                      <Col span={11}>
                        <Badge
                          color={experienceColors[i]}
                          className={styles.skillsListView}
                          text={`${item.DisplayName} (${item.SupplyPercentage}%)`}
                        />
                      </Col>
                    ))}
                  </div>
                </Row>
              </>
            ) : (
              <div>
                <Empty
                  image={<EmptyExperienceIcon className={styles.emptyIcon} />}
                  description={
                    <div>
                      <h3>No experience data found</h3>
                      {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                    </div>
                  }
                />
              </div>
            )}
          </Skeleton>
        </div>
    </div>
  );
}
