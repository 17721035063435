import React from 'react';
import styles from './ReportViewHeader.module.scss';

export default function ReportViewHeader(props) {
  const { title, subtitle } = props;
  return (
    <div className={styles.reportViewHeader}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
}
