import React from 'react';
import { Switch } from 'antd';
import styles from './CandidateAutoRejectSwitch.module.scss';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';

export default function CandidateAutoRejectSwitch(props) {
  const { autoRejectValue, onChange, disabled } = props;
  const title = `Auto-reject candidates who apply from an external source `;
  const infoTitle = `How Auto Rejection works?`;
  const infoDescription = `When candidates apply to this job, Arya Intelligence layer looks at their data and access them based on Skills, Roles, Industries, Occupation, Company, etc. and automatically put them in the reject bucket if they don’t meet the criteria.`;

  return (
    <div className={styles.candidateAutoRejectSwitchWrapper}>
      <div className={styles.autoRejectSwitchWrapper}>
        <div className={styles.autoRejectSwitchTitle}>{title}</div>
        <div className={styles.autoRejectSwitch}>
          <Switch
            unCheckedChildren="Off"
            checkedChildren="On"
            checked={autoRejectValue}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.autoRejectSwitchInfoWrapper}>
        <div className={styles.autoRejectSwitchInfoIconWrapper}>
          <InfoCircleIcon />
        </div>
        <div className={styles.autoRejectSwitchInfo}>
          <div className={styles.autoRejectSwitchInfoTitle}>{infoTitle}</div>
          <div className={styles.autoRejectSwitchInfoDescription}>{infoDescription}</div>
        </div>
      </div>
    </div>
  );
}
