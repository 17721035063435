import React from 'react';
import { Button } from 'antd';
import style from './UserVerification.module.scss';

export default function UserVerificationAlreadyVerified({ history }) {
  return (
    <div className={style.alreadyVerified}>
      <h1>You are already here!</h1>
      <p>It looks like you&apos;ve already accepted the invitation we sent you.</p>
      <Button
        type="primary"
        shape="round"
        onClick={() => {
          localStorage.clear();
          history.push('/');
        }}
      >
        Login to Arya Pulse
      </Button>
    </div>
  );
}
