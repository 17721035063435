export const SET_WORKFLOW_REPORT_MODAL_VISIBILITY = 'SET_WORKFLOW_REPORT_MODAL_VISIBILITY';
export const SET_WORKFLOW_REPORT_DATA = 'SET_WORKFLOW_REPORT_DATA';
export const SET_WORKFLOW_REPORT_FILTER_DATA = 'SET_WORKFLOW_REPORT_FILTER_DATA';
export const SET_WORKFLOW_REPORT_END_NODES_DATA = 'SET_WORKFLOW_REPORT_END_NODES_DATA';
export const SET_WORKFLOW_REPORT_FETCH_API_STATUS = 'SET_WORKFLOW_REPORT_FETCH_API_STATUS';
export const SET_WORKFLOW_PUBLISHED_STATUS = 'SET_WORKFLOW_PUBLISHED_STATUS';

export function setWorkflowReportModalVisibilityAction(visible) {
  return {
    type: SET_WORKFLOW_REPORT_MODAL_VISIBILITY,
    payload: {
      visible,
    },
  };
}

export function setWorkflowReportDataAction(data) {
  return {
    type: SET_WORKFLOW_REPORT_DATA,
    payload: data,
  };
}

export function setWorkflowReportFilterData(data) {
  return {
    type: SET_WORKFLOW_REPORT_FILTER_DATA,
    payload: data,
  };
}

export function setWorkflowReportEndNodesData(data) {
  return {
    type: SET_WORKFLOW_REPORT_END_NODES_DATA,
    payload: data,
  };
}

export function setWorkflowPublishedStatus(status) {
  return {
    type: SET_WORKFLOW_PUBLISHED_STATUS,
    payload: status,
  };
}

export function setWorkflowReportApiStatus(status) {
  return {
    type: SET_WORKFLOW_REPORT_FETCH_API_STATUS,
    payload: {
      status,
    },
  };
}
