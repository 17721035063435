import * as announcementRepository from '../Repository/AnnouncementRepository';
import * as ActionCreators from './ActionCreators/AnnouncementActionCreator';

export function getAllAnnouncements(payload) {
  return dispatch => {
    announcementRepository
      .getAnnouncements({
        From: payload.from,
        Size: payload.size,
        Type: payload.type,
        IsViewed: payload.isViewed,
      })
      .then(response => {
        dispatch(ActionCreators.setReleaseAnnouncements(response.data));
      });
  };
}

export function getDowntimeAnnouncements(payload) {
  return dispatch => {
    announcementRepository
      .getAnnouncements({
        From: payload.from,
        Size: payload.size,
        Type: payload.type,
        IsViewed: payload.isViewed,
      })
      .then(response => {
        dispatch(ActionCreators.setDowntimeAnnouncements(response.data));
      });
  };
}

export function updateAnnouncementView(id) {
  return () => {
    announcementRepository.updateAnnouncementView(id);
  };
}

export function setDowntimeRenderStatus(status) {
  return ActionCreators.setDowntimeRenderFlag(status);
}
