import _ from 'lodash';
import { SET_ORDER_DETAILS } from '../Actions/ActionCreators/OrderActionCreator';

const emptyObject = {};

const defaultState = { orderStatusesByJobId: {} };

function mapOrders(orders) {
  return orders.map(order => {
    return {
      amount: order.Amount,
      billingAddress: order.BillingAddress,
      createdBy: order.CreatedBy,
      createdTime: order.CreatedTime,
      id: order.Id,
      invoiceNumber: order.InvoiceNumber,
      items: order.Items,
      payments: order.Payments,
      refund: order.Refund,
      shippingAddress: order.ShippingAddress,
      status: order.Status,
      type: order.Type,
      updatedBy: order.UpdatedBy,
      updatedTime: order.UpdatedTime,
    };
  });
}
export function OrderReducer(state = defaultState, action) {
  let newState;
  let jobId;
  let orderDetails;
  let newOrderDetails;
  switch (action.type) {
    case 'SET_FETCH_ORDERS_API_STATUS':
      newState = { ...state };
      newState.ordersApiStatus = action.payload;
      return newState;
    case 'SET_ORDERS':
      newState = _.cloneDeep(state);
      newState.orders = mapOrders(action.payload.Items);
      newState.ordersCount = action.payload.Total;
      return newState;

    case SET_ORDER_DETAILS:
      jobId = _.get(action, ['payload', 'jobId'], null);
      newOrderDetails = _.get(action, ['payload', 'orderDetails'], {});
      newState = _.cloneDeep(state);
      orderDetails = _.get(newState, ['orderDetailsByJobId', jobId], {});
      orderDetails = { ...orderDetails, ...newOrderDetails };
      _.setWith(newState, ['orderDetailsByJobId', jobId], orderDetails, Object);
      return newState;

    default:
      return state;
  }
}

export function getOrdersApiStatus(state) {
  return _.get(state, ['OrderReducer', 'ordersApiStatus']);
}

export function getOrders(state) {
  return _.get(state, ['OrderReducer', 'orders']);
}

export function getOrdersCount(state) {
  return _.get(state, ['OrderReducer', 'ordersCount']);
}

export function getBulkOrderStatuses(state) {
  return _.get(state, ['OrderReducer', 'orderStatusesByJobId'], emptyObject);
}

export function getOrdersByJobId(state) {
  return _.get(state, ['OrderReducer', 'orderDetailsByJobId'], emptyObject);
}
