import React from 'react';
import _ from 'lodash';
import { Input, Button, Tag } from 'antd';
import styles from './CustomFieldNodeContent.module.scss';

export default function CustomFieldNodeContent(props) {
  const { initialValue, form, drawerFooter } = props;
  const initialCustomFields = _.get(initialValue, 'CustomFields', []);
  const [nameInput, setNameInput] = React.useState('');
  const [valueInput, setValueInput] = React.useState('');

  form.getFieldDecorator('CustomFields', { initialValue: initialCustomFields });
  const { CustomFields: customFieldPairs } = form.getFieldsValue();

  const onAddFieldValue = () => {
    const fieldAlreadyExists = customFieldPairs.find(item => item.Name === nameInput && item.Value === valueInput);
    if (!fieldAlreadyExists) {
      form.setFieldsValue({
        CustomFields: [...customFieldPairs, { Name: nameInput, Value: valueInput }],
      });
    }
    setNameInput('');
    setValueInput('');
  };

  const onRemoveField = field => {
    form.setFieldsValue({
      CustomFields: customFieldPairs.filter(item => item !== field),
    });
  };

  const isAddFieldDisabled = !(nameInput && valueInput);

  return (
    <div className={styles.customFieldContainer}>
      <div className={styles.inputSection}>
        <Input onChange={e => setNameInput(e.target.value)} value={nameInput} placeholder="Enter a name" />
        <Input onChange={e => setValueInput(e.target.value)} value={valueInput} placeholder="Enter a value" />
      </div>
      <Button type="link" onClick={onAddFieldValue} disabled={isAddFieldDisabled}>
        <span>Add another field</span>
      </Button>
      <div className={styles.tagList}>
        {customFieldPairs.map(field => (
          <Tag closable key={field} value={field} onClose={() => onRemoveField(field)}>
            {`"${field.Name} = ${field.Value}"`}
          </Tag>
        ))}
      </div>
      <div className={styles.customTagDrawerFooter}>{drawerFooter}</div>
    </div>
  );
}
