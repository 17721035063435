import React, { useCallback } from 'react';
import _ from 'lodash';
import SkillTag from './SkillTag';
import { mapSkillSuggestions, mapSkillSuggestionsWithMaxSelectionLimit } from '../../../../../Utils/ManualSearchUtils';

const PreferredSkills = props => {
  const {
    value: skills,
    onChange,
    skillIntelApiStatus,
    handleTilesIconHover,
    addNewSkill,
    handleAliasSkillSelection,
    handleAliasSkillSectionCheckboxClick,
    isTitlesExists,
  } = props;

  const handleProminentSkillClick = useCallback(
    (id, isProminent) => {
      const clonedValue = _.cloneDeep(skills);
      const index = skills.findIndex(skill => skill.id === id);
      const currentSkill = clonedValue[index];
      currentSkill.isProminent = isProminent;
      if (isProminent && !currentSkill.isPreferred) {
        currentSkill.isPreferred = true;
        currentSkill.aliasSkills = mapSkillSuggestionsWithMaxSelectionLimit(
          currentSkill.aliasSkills,
          true,
          false,
          currentSkill.MaxRecommendedAliasSkillCount
        );
      }
      onChange(clonedValue);
    },
    [skills]
  );

  const handleSkillClick = useCallback(
    (id, isPreferred) => {
      const clonedValue = _.cloneDeep(skills);
      const index = skills.findIndex(skill => skill.id === id);
      const currentSkill = clonedValue[index];
      currentSkill.isPreferred = isPreferred;
      if (isPreferred) {
        currentSkill.aliasSkills = mapSkillSuggestionsWithMaxSelectionLimit(
          currentSkill.aliasSkills,
          true,
          false,
          currentSkill.MaxRecommendedAliasSkillCount
        );
      } else {
        currentSkill.aliasSkills = mapSkillSuggestions(currentSkill.aliasSkills, false);
        currentSkill.isProminent = false;
      }
      onChange(clonedValue);
    },
    [skills]
  );

  return skills.map(skill => (
    <SkillTag
      key={skill.id}
      skill={skill}
      handleProminentSkillClick={handleProminentSkillClick}
      handleSkillClick={handleSkillClick}
      handleTilesIconHover={handleTilesIconHover}
      addNewSkill={addNewSkill}
      handleAliasSkillSelection={handleAliasSkillSelection}
      handleAliasSkillSectionCheckboxClick={handleAliasSkillSectionCheckboxClick}
      skillIntelApiStatus={skillIntelApiStatus[skill.id]}
      isTitlesExists={isTitlesExists}
    />
  ));
};

export default PreferredSkills;
