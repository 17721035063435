import { connectV1Client } from './BaseRepository';

export function fetchConnectUsers(query = {}) {
  return connectV1Client.post('Users/_search', {
    UserIds: query.userIds,
    Size: query.size,
    From: query.from,
    SearchTerm: query.searchTerm,
  });
}

export function fetchConnectUser(userId) {
  return connectV1Client.get(`Users/${userId}`);
}
