export const RESET_GLOBAL_INSIGHT = 'RESET_GLOBAL_INSIGHT';
export const FETCH_GLOBAL_SUPPLY_API_STATUS = 'FETCH_GLOBAL_SUPPLY_API_STATUS';
export const GLOBAL_SUPPLY_LOCATION_API_STATUS = 'GLOBAL_SUPPLY_LOCATION_API_STATUS';
export const SET_GLOBAL_SUPPLY_LOCATION_DATA = 'SET_GLOBAL_SUPPLY_LOCATION_DATA';
export const SET_GLOBAL_SUPPLY_LIST = 'SET_GLOBAL_SUPPLY_LIST';

export const resetGlobalInsight = {
  type: RESET_GLOBAL_INSIGHT,
};

export const setGlobalSupplyLocationApiStatus = status => ({
  type: GLOBAL_SUPPLY_LOCATION_API_STATUS,
  payload: status,
});

export const setGlobalSupplyApiStatus = status => ({
  type: FETCH_GLOBAL_SUPPLY_API_STATUS,
  payload: status,
});

export const setGlobalSupplyLocationData = payload => ({
  type: SET_GLOBAL_SUPPLY_LOCATION_DATA,
  payload,
});

export const setGlobalSupplyList = payload => ({
  type: SET_GLOBAL_SUPPLY_LIST,
  payload,
});
