import { defineMessages } from 'react-intl';

const message = defineMessages({
  aryaSourcing: {
    id: 'AryaActivation.Modal.Title.aryaSourcing',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Arya Sourcing',
  },
  activateArya: {
    id: 'AryaActivation.Modal.Footer.activateArya',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Activate Arya',
  },
  deactivateArya: {
    id: 'AryaActivation.Modal.Footer.deactivateArya',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Deactivate Arya',
  },
  save: {
    id: 'AryaActivation.Modal.Footer.save',
    defaultMessage: 'Save',
  },
  keepAll: {
    id: 'AryaActivation.Modal.Body.keepAll',
    defaultMessage: 'Keep All',
  },
  keepShortlisted: {
    id: 'AryaActivation.Modal.Body.keepShortlisted',
    defaultMessage: 'Keep Shortlisted',
  },
  deleteAll: {
    id: 'AryaActivation.Modal.Body.deleteAll',
    defaultMessage: 'Delete All',
  },
  chooseSource: {
    id: 'AryaActivation.Modal.Body.chooseSource',
    defaultMessage: 'Choose Source',
  },
  aryaScoringRanking: {
    id: 'AryaScoringRanking.Modal.Body.checkboxTopMessage',
    defaultMessage: `What do you want to do with candidates already Scored & Ranked?`,
  },
  aryaScoringRankingApplied: {
    id: 'AryaScoringRanking.Modal.Body.checkboxBottomMessage',
    defaultMessage: `Score & Rank the candidates under "Applied" bucket with new intel`,
  },
});

export default message;
