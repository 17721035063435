import React from 'react';
import PropTypes from 'prop-types';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import './CandidateNotes.scss';

function CandidateNotes({ notes, time, user, activityType = null }) {
  return (
    <div>
      <div className="notes">
        <HtmlViewer htmlContent={notes} />
      </div>

      <div className="notes-info">
        {activityType ? <span className="activity-type">{activityType}</span> : null} {time}
        {user ? `, By ${user}` : ''}
      </div>
    </div>
  );
}

CandidateNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default CandidateNotes;
