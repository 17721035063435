import { message } from 'antd';
import _ from 'lodash';
import { apiV3Client, axiosInterceptor } from './BaseRepository';
import Config from '../Config/Config';

let controller;
async function generateContentWithCancellation(payload) {
  controller = new AbortController();
  const { headers } = await axiosInterceptor({ headers: {} });
  try {
    const response = await fetch(
      `${Config.apiV3Url}/GenerativeAI/email/_generate?jobId=${payload.jobId}&candidateId=${payload.candidate.Id}`,
      {
        method: 'POST',
        headers: { ...headers, 'Content-Type': 'application/json' },
        body: JSON.stringify(payload.context),
        signal: controller.signal,
      }
    );
    if (!response.ok) {
      throw new Error(response.status);
    }
    return response;
  } catch (error) {
    switch (_.get(error, ['message'], null)) {
      case 'Failed to fetch':
        message.error('Please check your internet connectivity and try again.');
        break;
      case '500':
        message.error('Request got cancelled. Please try again later.');
        break;
      case '429':
        message.error('ChatGPT is busy right now');
        break;
      default:
        break;
    }
    return null;
  }
}

function cancelGeneration() {
  if (controller) {
    controller.abort();
  }
}

function getGeneratedEmailsContentList(payload) {
  return apiV3Client.get(
    `GenerativeAI/api/v3/jobs/${payload.jobId}/candidates/${payload.candidate.Id}/generatedEmails`
  );
}

function fetchResolvedMergeTags(jobId, candidateId) {
  return apiV3Client.get(`MergeTags/jobs/${jobId}/candidates/${candidateId}/mergeTagValues`);
}

function fetchGenerativeAiConfiguration(currentUser, jobId) {
  return apiV3Client.get(`jobs/${jobId}/users/${currentUser?.Id}/_configuration`);
}

function saveGenerativeAiConfiguration(payload) {
  return apiV3Client.post(
    `jobs/${payload.jobId}/users/${payload.currentUserDetails?.Id}/_configuration`,
    payload.context
  );
}

const generateContent = generateContentWithCancellation;
export {
  generateContent,
  fetchGenerativeAiConfiguration,
  saveGenerativeAiConfiguration,
  getGeneratedEmailsContentList,
  cancelGeneration,
  fetchResolvedMergeTags,
};
