import React from 'react';
import { Tooltip } from 'antd';
import styles from './CareerportalCandidateListItemStyles.module.scss';
import { getRelativeTime } from '../../../Utils/RelativeTimeCalculator';

const CareerportalCandidateListItem = props => {
  const { title, onClick, isActive, isoDateTime } = props;

  return (
    <div
      className={`${styles.candidateListItemContainer} ${isActive ? styles.isCandidateCardActive : ''}`}
      onClick={onClick}
      role="presentation"
    >
      <div className={styles.candidateNameSection}>
        <div className={styles.candidateName}>
          <Tooltip placement="top" title={title}>
            {title.length > 20 ? `${title.slice(0, 24)}...` : title}
          </Tooltip>
        </div>
        <div className={styles.conversationTime}>{isoDateTime ? getRelativeTime(isoDateTime) : null}</div>
      </div>
      <div className={styles.candidateChatInfoSection}>
        <div className={styles.chatDescription}></div>
      </div>
    </div>
  );
};

export default CareerportalCandidateListItem;
