import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import TemplateRow from '../TemplateRow';
import './SharedTextTemplateRow.scss';
import eventTypes from '../../../../Analytics/EventTypes';

export default class SharedTextTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.viewTemplate = this.viewTemplate.bind(this);
    this.cloneTemplate = this.cloneTemplate.bind(this);
  }

  cloneTemplate() {
    const { id, cloneTemplate } = this.props;
    cloneTemplate(id);
  }

  viewTemplate() {
    const { id, viewTemplate } = this.props;
    viewTemplate(id);
  }

  render() {
    const { title, createdBy, updatedTime, isConsent, isCloneDisable } = this.props;

    return (
      <Row className="template">
        <Col span={8} onClick={this.viewTemplate} style={{ cursor: 'pointer' }}>
          <div className="user-shared-text-template-title-wrapper">
            {this.getTitleColumn(title)}
            {isConsent ? <div className="user-shared-text-template-consent-box"> Consent </div> : null}
          </div>
        </Col>
        <Col span={5}>{this.getUpdatedOnColumn(updatedTime)}</Col>
        <Col span={5}>{this.getCreatedByColumn(createdBy)}</Col>
        <Col span={5}>
          {this.getCloneColumn(
            'Duplicate',
            this.cloneTemplate,
            createdBy,
            eventTypes.connect.smsTemplates.duplicateShared,
            isCloneDisable
          )}
        </Col>
      </Row>
    );
  }
}

SharedTextTemplateRow.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  cloneTemplate: PropTypes.func.isRequired,
  viewTemplate: PropTypes.func.isRequired,
};
