const generateMergeTags = (mergeTags = []) => {
  return mergeTags.map(mergeTag => {
    const mergeTagKey = mergeTag.Key;
    return {
      name: mergeTag.DisplayName,
      value: mergeTag.IsUrl
        ? `<a href="{{${mergeTagKey}}}" target="_blank" rel="noopener" data-mce-href="{{${mergeTagKey}}}">click here</a>`
        : `{{${mergeTagKey}}}`,
    };
  });
};

export { generateMergeTags };
