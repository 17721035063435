import React from 'react';
import { AutoComplete, Input, Icon } from 'antd';
import './AutocompleteSearch.scss';

function AutocompleteSearch(props) {
  const {
    style,
    placeholder,
    onSearch,
    value,
    onChange,
    options,
    onSearchInput,
    notFoundContent,
    onSelect,
    maunalSearchAutoCompleteInputClassName,
  } = props;

  const handleOnSearch = (_value, event) => {
    if (_value?.trim() && (options?.length === 0 || event.type === 'click')) {
      onSearchInput(_value);
    }
  };

  const handleSearch = _value => {
    if (_value?.trim()) {
      onSearch(_value);
    }
  };

  const handleSelect = _value => {
    onSelect(_value);
  };

  const disabledClassName = maunalSearchAutoCompleteInputClassName ? 'disabledNew' : 'disabled';
  const enabledClassName = maunalSearchAutoCompleteInputClassName ? 'enabledNew' : null;

  return (
    <AutoComplete
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={handleSearch}
      dataSource={options}
      value={value}
      notFoundContent={notFoundContent}
      onSelect={handleSelect}
    >
      <Input.Search
        enterButton={<Icon type="plus" style={{ display: 'flex', border: 'none' }} />}
        className={`autocomplete-search-component ${maunalSearchAutoCompleteInputClassName} ${
          !value?.trim() ? disabledClassName : enabledClassName
        }`}
        onSearch={handleOnSearch}
      />
    </AutoComplete>
  );
}

export default AutocompleteSearch;
