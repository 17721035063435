import _ from 'lodash';
import { SET_SIGNUP_API_RESPONSE } from '../Utils/SignupUtils';

function PulseSignupReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'SET_BASIC_INFO':
      newState = _.cloneDeep(state);
      return { ...newState, signupBasicInfo: { ...action.payload } };
    case 'SET_COMPANY_INFO':
      newState = _.cloneDeep(state);
      return { ...newState, signupCompanyInfo: { ...action.payload } };
    case 'CREATE_GROUP_API_STATUS':
      newState = _.cloneDeep(state);
      return { ...newState, createGroupApiStatus: action.payload };
    case 'ADD_USER_TO_GROUP_API_STATUS':
      newState = _.cloneDeep(state);
      return { ...newState, addUserToGroupApiStatus: action.payload };
    case 'CREATE_UNREGISTERED_USER_API_STATUS':
      newState = _.cloneDeep(state);
      return { ...newState, createUnregisteredUserApiStatus: action.payload };
    case 'SET_SIGNUP_INFO':
      newState = _.cloneDeep(state);
      return { ...newState, signupInfo: { ...action.payload } };
    case SET_SIGNUP_API_RESPONSE:
      newState = _.cloneDeep(state);
      return { ...newState, signupApiResponse: action.payload };
    default:
      return state;
  }
}

function getSignupBasicInfo(state) {
  return _.get(state, ['PulseSignupReducer', 'signupBasicInfo'], {});
}

function getEmailAddress(state) {
  return _.get(state, ['PulseSignupReducer', 'signupBasicInfo', 'email'], '');
}

function getSignupCompanyInfo(state) {
  return _.get(state, ['PulseSignupReducer', 'signupCompanyInfo'], {});
}

function getCeateUnregisteredUserApiStatus(state) {
  return _.get(state, ['PulseSignupReducer', 'createUnregisteredUserApiStatus'], {});
}
function getCreateGroupApiStatus(state) {
  return _.get(state, ['PulseSignupReducer', 'createGroupApiStatus'], {});
}
function getAddUserToGroupApiStatus(state) {
  return _.get(state, ['PulseSignupReducer', 'addUserToGroupApiStatus'], {});
}

function getResendCodeStatus(state) {
  return _.get(state, ['PulseSignupReducer', 'resendCodeStatus'], {});
}

function getUserEmailAddress(state) {
  return _.get(state, ['PulseSignupReducer', 'signupInfo', 'Email'], '');
}

function getSignUpApiResponse(state) {
  return state?.PulseSignupReducer?.signupApiResponse;
}

export {
  getEmailAddress,
  PulseSignupReducer,
  getSignupBasicInfo,
  getSignupCompanyInfo,
  getCreateGroupApiStatus,
  getAddUserToGroupApiStatus,
  getCeateUnregisteredUserApiStatus,
  getResendCodeStatus,
  getUserEmailAddress,
  getSignUpApiResponse,
};
