import { connectTemplateNameByType } from '../../Utils/ConnectUtils';
import connectEventTypes from './ConnectEventTypes';

export function getEventNameByConnectType(connectType, actionType) {
  switch (connectType) {
    case connectTemplateNameByType.email:
      return connectEventTypes.emailTemplates[actionType];
    case connectTemplateNameByType.sms:
      return connectEventTypes.smsTemplates[actionType];
    case connectTemplateNameByType.drip:
      return connectEventTypes.dripTemplates[actionType];
    case connectTemplateNameByType.bot:
      return connectEventTypes.aryaBotTemplates[actionType];
    default:
      return null;
  }
}
