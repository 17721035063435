import _ from 'lodash';
import { SET_ACCOUNT_TYPES, SET_LICENCE_TYPES } from '../Actions/ActionCreators/OrganizationActionCreator';

const initialState = {
  SearchTerm: null,
};

function OrganizationsReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      newState = _.cloneDeep(state);
      if (action.payload.SearchTerm !== state.SearchTerm) {
        newState.Organizations = action.payload.orgData.Organizations;
      } else {
        newState.Organizations = [..._.get(newState, 'Organizations', []), ...action.payload.orgData.Organizations];
        newState.Organizations = _.uniqBy(newState.Organizations, 'Id');
      }
      newState.Total = action.payload.orgData.Total;
      return { ...newState, SearchTerm: action.payload.SearchTerm };
    case SET_ACCOUNT_TYPES:
      newState = _.cloneDeep(state);
      newState.AccountTypes = action.payload;
      return newState;
    case SET_LICENCE_TYPES:
      newState = _.cloneDeep(state);
      newState.LicenceTypes = action.payload;
      return newState;
    default:
      return state;
  }
}

function getOrganizations(state) {
  return state.OrganizationsReducer || {};
}

function getAccountTypes(state) {
  return state?.OrganizationsReducer?.AccountTypes;
}

function getLicenceTypes(state) {
  return state?.OrganizationsReducer?.LicenceTypes;
}
export { OrganizationsReducer, getOrganizations, getAccountTypes, getLicenceTypes };
