import { Component } from 'react';

/* eslint-disable react/no-unused-state */
export default class BaseTemplatesContainer extends Component {
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.setEditTemplateId = this.setEditTemplateId.bind(this);
    this.closeCreateDrawer = this.closeCreateDrawer.bind(this);
    this.showCreateDrawer = this.showCreateDrawer.bind(this);
    this.closeEditDrawer = this.closeEditDrawer.bind(this);
    this.showEditDrawer = this.showEditDrawer.bind(this);
  }

  onSearch(searchQuery) {
    this.setState(
      {
        searchQuery,
      },
      this.searchTemplates
    );
  }

  onTabChange(key) {
    switch (key) {
      case 'my-templates':
        this.setState(
          {
            shared: false,
            page: 1,
            pageSize: 10,
            systemEmailTemplate: false,
            includeSystemTemplate: false,
          },
          this.searchTemplates
        );
        break;
      case 'shared-templates':
        this.setState(
          {
            shared: true,
            page: 1,
            pageSize: 10,
            systemEmailTemplate: false,
            includeSystemTemplate: false,
          },
          this.searchTemplates
        );
        break;
      case 'system-templates':
        this.setState(
          {
            shared: false,
            page: 1,
            pageSize: 10,
            systemEmailTemplate: true,
          },
          this.searchSystemTemplates
        );
        break;
      default:
        break;
    }
  }

  onPageChange(page, pageSize) {
    this.setState(
      {
        page,
        pageSize,
      },
      this.searchTemplates
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        page: current,
        pageSize,
      },
      this.searchTemplates
    );
  }

  setEditTemplateId(templateId) {
    this.setState({
      editTemplateId: templateId,
    });
  }

  showCreateDrawer() {
    this.setState({
      createDrawerVisibility: true,
    });
  }

  closeCreateDrawer() {
    this.setState({
      createDrawerVisibility: false,
    });
  }

  showEditDrawer(templateId) {
    this.setEditTemplateId(templateId);
    this.setState({
      editDrawerVisibility: true,
    });
  }

  closeEditDrawer() {
    this.setState({
      editDrawerVisibility: false,
    });
  }
}
