import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import './ResearchCard.scss';
import messages from './messages';

function ResearchCard(props) {
  const { researchInfo } = props;
  return (
    <div className="research-card">
      <div>
        <div className="name-publication">
          <div className="publication-name">{researchInfo.Source}</div>
          <div className="publications-count">
            <FormattedMessage {...messages.publication} />: {researchInfo.Publications.length}
          </div>
        </div>
        <div className="publication-image ">
          <Icon style={{ fontSize: '40px', color: '#08c' }} type="picture" />
        </div>
      </div>
      <div style={{ marginTop: 12 }}>
        <div className="publication-summary-icon">
          <Icon type="file-pdf" />
        </div>
        <div className="publication-summary">
          {_.get(researchInfo, 'Publications[0].Title', 'Arya did not able to find Info')}
        </div>
      </div>
      <div style={{ marginTop: 8 }}>
        <div className="citations">
          <FormattedMessage {...messages.citations} />: {researchInfo.Citations}
        </div>
        <div className="h-index">
          <FormattedMessage {...messages.hIndex} />: {researchInfo.HIndex}
        </div>
        <div className="i-10-index">
          <FormattedMessage {...messages.i10Index} />: {researchInfo.I10Index}
        </div>
      </div>
      <div style={{ marginTop: 15, borderTop: '1px solid #DADBDD' }}>
        <div className="confidence">
          {' '}
          <FormattedMessage {...messages.confidence} />:{' '}
        </div>
        <div className="confidence-type"> {researchInfo.Confidence}</div>
        <div
          className="go-to-profile"
          role="button"
          tabIndex={0}
          onClick={() => {
            window.open(researchInfo.Link, '_blank');
          }}
          onKeyPress={() => {
            window.open(researchInfo.Link, '_blank');
          }}
        >
          {' '}
          <FormattedMessage {...messages.goToProfile} />
        </div>
      </div>
    </div>
  );
}

ResearchCard.propTypes = {
  researchInfo: PropTypes.shape({}).isRequired,
};

export default ResearchCard;
