import React from 'react';
import _ from 'lodash';
import { Menu, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import TemplateRow from '../TemplateRow';
import ShareTemplate from '../../ShareTemplate';
import './UserTextTemplateRow.scss';
import Collaborators from '../../../Collaborators/Collaborators';
import eventTypes from '../../../../Analytics/EventTypes';
import { connectTemplateNameByType } from '../../../../Utils/ConnectUtils';

export default class UserTextTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.state = {
      showUsersPopup: false,
    };
    this.editTemplate = this.editTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.sharePopUp = this.sharePopUp.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onShare = this.onShare.bind(this);
  }

  onShare(shareOptions) {
    const { shareTextTemplate, templateId } = this.props;
    shareTextTemplate(templateId, shareOptions);
    this.setState({
      showUsersPopup: false,
    });
  }

  render() {
    const {
      title,
      updatedTime,
      isPublic,
      isConsent,
      usersByGuId,
      maxCount,
      fetchUsersForShare,
      userListForShareTemplate,
      CurrentUser,
      sharedUsers,
      featureToggleList,
    } = this.props;
    const currentUserEmailId = CurrentUser.email;
    const { showUsersPopup } = this.state;
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreation },
    } = featureToggleList;
    const isDuplicateHidden = isConsent && !messageConsentTemplateCreation;
    const actionMenuItems = [];
    if (!isDuplicateHidden) {
      actionMenuItems.push(
        <Menu.Item onClick={this.duplicateTemplate} sk-event-name={eventTypes.connect.smsTemplates.duplicate}>
          <span sk-event-name={eventTypes.connect.smsTemplates.duplicate}>Duplicate</span>
        </Menu.Item>
      );
    }
    actionMenuItems.push(
      <Menu.Item onClick={this.sharePopUp} sk-event-name={eventTypes.connect.smsTemplates.share}>
        <span sk-event-name={eventTypes.connect.smsTemplates.share}>Share</span>
      </Menu.Item>,
      <Menu.Item onClick={this.deleteTemplate} sk-event-name={eventTypes.connect.smsTemplates.delete}>
        <span className="delete-option-text" sk-event-name={eventTypes.connect.smsTemplates.delete}>
          Delete
        </span>
      </Menu.Item>
    );
    const actionsMenu = <Menu>{actionMenuItems}</Menu>;
    const collaborators = isPublic ? userListForShareTemplate : sharedUsers;
    const collaboratorslist = collaborators
      .filter(id => usersByGuId[id])
      .map(id => {
        const FirstName = _.get(usersByGuId, [id, 'FirstName'], '');
        const LastName = _.get(usersByGuId, [id, 'LastName'], '');
        const Name = FirstName.concat(' ', LastName);
        const { Email } = usersByGuId[id];
        return {
          name: Name,
          Id: id,
          Email,
        };
      });

    return (
      <div>
        <Row className="template">
          <Col span={8} onClick={this.editTemplate} className="template-column">
            <div className="user-text-template-title-wrapper">
              {this.getTitleColumn(title)}
              {isConsent ? <div className="user-text-template-consent-box"> Consent </div> : null}
            </div>
          </Col>
          <Col span={6}>
            <Collaborators
              collaborators={collaboratorslist}
              onAddNew={this.sharePopUp}
              shareSize="Small"
              currentUserEmailId={currentUserEmailId}
              skEventName={eventTypes.connect.smsTemplates.share}
            />
          </Col>
          <Col span={6}>{this.getUpdatedOnColumn(updatedTime)}</Col>
          <Col span={3}>{this.getActionsColumn(actionsMenu)}</Col>
        </Row>

        <ShareTemplate
          isPublic={isPublic}
          userGuIds={userListForShareTemplate}
          isVisible={showUsersPopup}
          fetchUsersForShare={fetchUsersForShare}
          onClose={this.onClosePopup}
          sharedUsers={sharedUsers}
          onShare={this.onShare}
          maxCount={maxCount}
          usersByGuId={usersByGuId}
          currentUserEmailId={currentUserEmailId}
          type={connectTemplateNameByType.sms}
        />
      </div>
    );
  }
}

UserTextTemplateRow.propTypes = {
  templateId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sharedCount: PropTypes.number.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  duplicateTemplate: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
};
