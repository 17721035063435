import { fetchMergeTags } from '../Repository/MergeTagsRepository';
import { getConfig } from '../Reducers/ConfigReducer';
import { getAccountTypes } from '../Reducers/OrganizationsReducer';
import { getAccountType } from '../Utils/OrganizationUtils';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTagsAryaPulse } from '../Utils/MergeTagsUtils';

export default function getMergeTags() {
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const userConfig = getConfig(store);
    const accountTypes = getAccountTypes(store);
    const accountType = getAccountType(userConfig.AccountTypeId, accountTypes);
    const isBotEnabled = featureToggleList.BotWrite.IsEnabled;
    const response = await fetchMergeTags();
    let payload = response.data.MergeTags;
    if (!isBotEnabled) {
      payload = payload.filter(tag => tag.Key !== 'Bot.Url');
    }
    if (accountType === 'AryaPulse') {
      payload = getFilteredMergeTagsAryaPulse(payload);
    }
    dispatch({
      type: 'SET_MERGE_TAGS',
      payload,
    });
  };
}
