import React from 'react';
import ReportQueriesListItem from './ReportQueriesListItem/ReportQueriesListItem';

export default function ReportQueriesList(props) {
  const {
    onRunSavedQuery,
    onEditSavedQuery,
    reportQueriesList,
    onDeleteSavedQuery,
    onShareSavedQuery,
    deleteSavedQueryApiStatus,
  } = props;
  return (
    <div>
      {reportQueriesList?.map(item => (
        <ReportQueriesListItem
          key={item?.SavedReportId}
          queryDetails={item}
          onRunSavedQuery={onRunSavedQuery}
          onEditSavedQuery={onEditSavedQuery}
          onDeleteSavedQuery={onDeleteSavedQuery}
          onShareSavedQuery={onShareSavedQuery}
          deleteSavedQueryApiStatus={deleteSavedQueryApiStatus}
        />
      ))}
    </div>
  );
}
