import React from 'react';
import { Input, Form } from 'antd';
import { validateBooleanString } from '../../Utils/FormValidators';
import EducationContext from './EducationContext';

const { TextArea } = Input;

const { Item } = Form;
export default function Certification(props) {
  const { initialValue } = props;
  const { form } = React.useContext(EducationContext);
  return (
    <Item colon={false}>
      {form.getFieldDecorator('Certification', {
        initialValue,
        rules: [
          {
            validator: (rule, booleanString, callback) => validateBooleanString(rule, booleanString, callback, form),
          },
        ],
      })(
        <TextArea
          style={{ overflow: 'hidden' }}
          placeholder='Example: ("Financial Risk Management" OR "Six Sigma") AND APICS '
        />
      )}
    </Item>
  );
}
