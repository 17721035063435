import React from 'react';
import { Form, Input } from 'antd';

export default function PasswordInput({ form, credentialsValidator, isAuthenticated, jobBoard }) {
  const passwordFieldName = `${jobBoard}password`;
  const passwordError = form.isFieldTouched(passwordFieldName) && form.getFieldError(passwordFieldName);
  return (
    <div className="jobboard-input-container">
      <Form.Item validateStatus={passwordError ? 'error' : ''} help="">
        {form.getFieldDecorator(passwordFieldName, {
          initialValue: isAuthenticated ? '********' : undefined,
          rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
        })(
          <Input
            type="password"
            placeholder="Password"
            className="common-jobboard-password"
            autoComplete="new-password"
            readOnly={isAuthenticated}
          />
        )}
      </Form.Item>
    </div>
  );
}
