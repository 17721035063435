import React from 'react';

export default function useSeconds(props) {
  const [seconds, setSeconds] = React.useState(0);
  const { isActive } = props;

  React.useEffect(() => {
    let interval;
    const timeStart = new Date().getTime();
    if (isActive) {
      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const diff = currentTime - timeStart;
        const newSeconds = Math.floor(diff / 1000);
        setSeconds(newSeconds);
      }, 500);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  return { seconds };
}
