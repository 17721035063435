import PropTypes from 'prop-types';

function AppNotificationReducer(state = {}, action) {
  // const notification = {
  //   TimeStamp: _.get(action, 'payload.timeStamp', undefined),
  //   Code: _.get(action, 'payload.code', undefined),
  //   Description: _.get(action, 'payload.description', undefined),
  // };

  switch (action.type) {
    case 'SET_NOTIFICATION':
      return {
        ...state,
        notification: action.payload,
      };
    case 'NOTIFICATION_COMPLETED':
      return {
        ...state,
        notification: undefined,
      };
    case 'ALERT_NOTIFICATION_COMPLETED':
      return {
        ...state,
        alertNotification: undefined,
      };
    case 'SET_ALERT_ERROR':
    case 'SET_ALERT_NOTIFICATION':
      return {
        ...state,
        alertNotification: action.payload,
      };
    case 'SET_FEATURE_LOCKED_NOTIFICATION':
      return {
        ...state,
        featureLockedNotification: action.payload,
      };
    case 'SET_ERROR':
    case 'SET_INFO':
    default:
      return state;
  }
}

AppNotificationReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getNotification(state) {
  return state.AppNotificationReducer.notification;
}

function getAlertNotification(state) {
  return state.AppNotificationReducer.alertNotification;
}

function getFeatureLockedNotification(state) {
  return state.AppNotificationReducer.featureLockedNotification;
}

export { AppNotificationReducer, getNotification, getAlertNotification, getFeatureLockedNotification };
