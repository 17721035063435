import { connectV1Client, apiV3Client } from './BaseRepository';

export function getTextTemplates(from, size) {
  return connectV1Client.get('MessageTemplates', {
    params: {
      from,
      size,
    },
  });
}

export function searchTextTemplates(filter) {
  return connectV1Client.post('MessageTemplates/_search', filter);
}

export function postTextTemplate(template) {
  return connectV1Client.post('MessageTemplates', template);
}

export function getTextTemplate(templateId) {
  return connectV1Client.get(`MessageTemplates/${templateId}`);
}

export function deleteTextTemplate(templateId) {
  return connectV1Client.delete(`MessageTemplates/${templateId}`);
}

export function putTextTemplate(templateId, template) {
  return connectV1Client.put(`MessageTemplates/${templateId}`, template);
}

export function patchTextTemplate(templateId, template) {
  return connectV1Client.patch(`MessageTemplates/${templateId}`, {
    data: template,
  });
}

export function duplicateTextTemplate(templateId) {
  return connectV1Client.post(`MessageTemplates/${templateId}/_duplicate`);
}

export function shareTextTemplate(templateId, shareOptions) {
  return connectV1Client.post(`MessageTemplates/${templateId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
    IsShareWithAll: shareOptions.isShareWithAll,
    IsUnshareWithAll: shareOptions.isUnshareWithAll,
  });
}

export function testTextTemplate(Body, IsConsent) {
  return apiV3Client.post(`UserActivity/_testmessage`, {
    Body,
    IsConsentMessage: IsConsent,
  });
}
