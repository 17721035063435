export const ADVANCED_JOB_BASIC_INFO = 'ADVANCED_JOB_BASIC_INFO';
export const ADVANCED_JOB_INSIGHTS = 'ADVANCED_JOB_INSIGHTS';
export const ADVANCED_JOB_ADDITIONAL_INPUT = 'ADVANCED_JOB_ADDITIONAL_INPUT';
export const ADVANCED_JOB_EDIT_INSIGHTS = 'ADVANCED_JOB_EDIT_INSIGHTS';
export const VIEW_SAMPLE_CANDIDATES = 'VIEW_SAMPLE_CANDIDATES';
export const ADVANCED_JOB_EDIT_TITLE = 'ADVANCED_JOB_EDIT_TITLE';
export const SELECT_MODE = 'SELECT_MODE';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SAVE_JOB = 'SAVE_JOB';
export const SIGN_UP = 'SIGN_UP';
export const CREATE_JOB = 'CREATE_JOB';
export const CHECKOUT_PROGRESS = 'CHECKOUT_PROGRESS';
export const PURCHASE = 'PURCHASE';
export const ADVANCED_JOB_SAVE_DRAFT = 'ADVANCED_JOB_SAVE_DRAFT';
export const NORMAL_JOB_SAVE_DRAFT = 'NORMAL_JOB_SAVE_DRAFT';
export const VIEW_PLAN_SELECTION = 'VIEW_PLAN_SELECTION';
export const FREE_JOB_CHECKOUT = 'FREE_JOB_CHECKOUT';
export const FREE_JOB_SUBMITTED = 'FREE_JOB_SUBMITTED';
export const FREE_JOB_PURCHASED = 'FREE_JOB_PURCHASED';
export const PLAN_UPGRADE = 'PLAN_UPGRADE';
export const RESUME_DOWNLOAD = 'RESUME_DOWNLOAD';
export const CANDIDATE_VIEW = 'CANDIDATE_VIEW';
export const GO_TO_BUY_CREDIT = 'GO_TO_BUY_CREDIT';
export const GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE = 'GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE';
export const SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE = 'SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE';
export const SETUP_SOCKET_CONNECTIONS = 'SETUP_SOCKET_CONNECTIONS';
export const BROADCAST_CHANNEL_POST_MESSAGE = 'BROADCAST_CHANNEL_POST_MESSAGE';
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
