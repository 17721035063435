import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Affix, Button, Tabs, Skeleton } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './ViewSampleCandidates.module.scss';
import JobDetail from '../JobDetail/JobDetail';
import SampleJobdetail from '../../Containers/SampleJobDetail/SampleJobdetail';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import * as JobActions from '../../Actions/JobActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import { getUsersById } from '../../Reducers/UserReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getTryNowPayload, getTryNowCandidatesPayload, getJobDetailsForAnalytics } from '../../Utils/JobUtils';
import * as CandidateActions from '../../Actions/CandidateActions';
import { ProgressSteps } from '../../Containers/PlanSelection/PlanSelectionModules';
import SampleCandidateListContainer from '../../Containers/SampleCandidateListConatiner/SampleCandidateListConatiner';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCandidateCount } from '../../Reducers/CandidateReducer';
import { getJobsById, getTrynowFormData } from '../../Reducers/JobReducer';
import { getScoutingAgentConfig } from '../../Reducers/ScoutingAgentReducer';
import JobDetailHeader from '../JobDetailHeader/JobDetailHeader';
import { generateKeywordsFromBooleanString } from '../../Utils/BooleanStringUtility';
import { VIEW_SAMPLE_CANDIDATES } from '../../PubSub/EventTypes';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
  userConfig: getConfig(state),
  jobsById: getJobsById(state),
  usersById: getUsersById(state),
  tryNowCandidateApiStatus: getApiStatus(state, 'fetchTryNowCandidateApiStatus'),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  trynowFormData: getTrynowFormData(state),
  totalCandidates: getCandidateCount(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  fetchJobDetails: JobActions.fetchJobDetails,
  setTrynowFormData: JobActions.setTrynowFormData,
  fetchTryNowCandidates: CandidateActions.fetchTryNowCandidates,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
};

class ViewSampleCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'candidates',
    };
  }

  componentDidMount() {
    const { match, fetchJobDetails, setSampleCandidatesDisplayFlag, jobsById, setTrynowFormData } = this.props;
    const { jobId } = match.params;
    const jobDetails = jobsById[jobId] || {};
    if (!_.isEmpty(jobDetails)) {
      const tryNowPayload = getTryNowPayload(jobDetails, this.getSkills(jobDetails));
      setTrynowFormData(tryNowPayload, true);
    } else {
      fetchJobDetails(match.params.jobId);
    }
    setSampleCandidatesDisplayFlag();
  }

  getSkills = jobDetails => {
    const mustHaves = generateKeywordsFromBooleanString(jobDetails?.MustHaves || '');
    const niceToHaves = generateKeywordsFromBooleanString(jobDetails?.NiceToHaves || '');
    const skillsList = mustHaves.concat(niceToHaves);
    return jobDetails?.IsAdvancedJob ? skillsList : [];
  };

  componentDidUpdate(prevProps) {
    const {
      match,
      jobsById,
      setTrynowFormData,
      fetchTryNowCandidates,
      trynowFormData,
      history,
      tryNowCandidateApiStatus,
    } = this.props;
    const { jobId } = match.params;
    if (!_.isEqual(prevProps.jobsById[jobId], jobsById[jobId])) {
      // TODO handle jobdetails fetch error in API catch block & redirect accordingly
      if (_.get(this.props, ['jobsById', jobId, 'ErrorCode'], undefined) === 403) {
        history.push(`/jobs/${match.params.jobId}/forbidden`);
      }
      if (_.get(this.props, ['jobsById', jobId, 'ErrorCode'], undefined) === 404) {
        history.push(`/jobs/${match.params.jobId}/notfound`);
      }
      const jobDetails = jobsById[jobId];
      const tryNowPayload = getTryNowPayload(jobDetails, this.getSkills(jobDetails));
      setTrynowFormData(tryNowPayload, true);
    }
    if (!_.isEqual(prevProps.trynowFormData, trynowFormData)) {
      const jobDetails = jobsById[jobId];
      const tryNowCandidatesPayload = getTryNowCandidatesPayload(jobDetails, this.getSkills(jobDetails));
      fetchTryNowCandidates(tryNowCandidatesPayload);
    }
    if (prevProps.tryNowCandidateApiStatus === 'INPROGRESS' && tryNowCandidateApiStatus === 'COMPLETED') {
      const jobDetails = jobsById[jobId];
      const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
      PubSub.publish(VIEW_SAMPLE_CANDIDATES, {
        jobId: jobDetails?.JobId,
        jobTitle: jobDetails?.JobTitle,
        isAdvanced: jobDetails?.IsAdvancedJob,
        jobDetails: analyticsJobDetails,
      });
    }
  }

  onTabChange = key => {
    this.setState({ activeKey: key });
  };

  render() {
    const { activeKey } = this.state;
    const {
      featureToggleList,
      userConfig,
      jobsById,
      match,
      history,
      tryNowCandidateApiStatus,
      showSampleCandidates,
      usersById,
      scoutingAgentConfig,
    } = this.props;
    const jobDetails = jobsById[match.params.jobId] || {};
    const jobId = jobDetails?.JobId;
    const jobGuid = jobDetails?.JobGuid;
    const selectPlan = `/select-plan?jobGuid=${jobGuid}&jobId=${jobId}`;
    const tryNowData = {
      JobTitle: jobDetails?.JobTitle,
      Location: jobDetails?.Location,
    };
    const redirectPath = {
      pathname: jobDetails?.IsAdvancedJob ? `/jobs-v2/${jobId}/review` : `/jobs/${jobId}/edit`,
    };

    const {
      MarketIntelligence: { IsEnabled: isMarketIntelEnabled },
    } = featureToggleList;

    return (
      <div className={styles.viewSampleCandidatesContainer}>
        <div className={styles.stepsWrapper}>
          <Link className={styles.backToJob} to={redirectPath}>
            Back to Job
          </Link>
          <ProgressSteps current={1} showSampleCandidates={showSampleCandidates} />
        </div>
        <Skeleton active paragraph={{ rows: 12 }} loading={tryNowCandidateApiStatus === 'INPROGRESS'}>
          <div id="job-details">
            <JobDetailHeader
              isTryNow={false}
              scoutingAgentConfig={scoutingAgentConfig}
              job={{ ...tryNowData, JobId: jobId }}
              featureToggleList={featureToggleList}
              userConfig={userConfig}
              showBotIcon={false}
              selectPlan={selectPlan}
            />
            <Tabs defaultActiveKey="candidates" onTabClick={this.onTabChange} activeKey={activeKey}>
              <TabPane tab="Job Info" key="info" className="sample-job-info">
                <>
                  <div>
                    <JobDetail
                      job={jobDetails}
                      usersById={usersById}
                      featureToggleList={featureToggleList}
                      showSampleCandidates={showSampleCandidates}
                    />
                  </div>
                </>
              </TabPane>
              <TabPane tab="Candidate List" key="candidates">
                <SampleCandidateListContainer
                  showBanner
                  selectPlan={selectPlan}
                  jobDetails={jobDetails}
                  job={tryNowData}
                />
              </TabPane>
              <TabPane
                tab={isMarketIntelEnabled ? 'Market Intelligence' : 'Job Intel'}
                key="intel"
                className="sample-job-intel"
              >
                <SampleJobdetail selectPlan={selectPlan} />
              </TabPane>
            </Tabs>
          </div>
        </Skeleton>
        <Affix offsetBottom={0}>
          <div className={`${styles.footerWrapper} ${styles.footerAlignment}`}>
            <div style={{ width: '60%' }}>
              Select a plan to unlock the contact details of up to 100 qualified candidates sourced for each job.
              Connect with them directly or let us do it for you that leads to a faster time to hire. Get additional
              Market Intelligence relevant to your job when you select a plan.
            </div>
            <div className={styles.btnWrapper}>
              <Button
                type="primary"
                className={styles.saveBtn}
                shape="round"
                loading={tryNowCandidateApiStatus === 'INPROGRESS'}
                onClick={() => {
                  history.push(`/select-plan?jobGuid=${jobGuid}&jobId=${jobId}`);
                }}
              >
                Select a plan
              </Button>
            </div>
          </div>
        </Affix>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewSampleCandidates));
export { ViewSampleCandidates as ViewSampleCandidatesWithoutStore };
