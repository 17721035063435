const conversations = {};
const emailTemplates = {
  create: 'CREATE_EMAIL_TEMPLATE',
  duplicate: 'DUPLICATE_EMAIL_TEMPLATE',
  duplicateShared: 'DUPLICATE_SHARED_EMAIL_TEMPLATE',
  save: 'SAVE_EMAIL_TEMPLATE',
  cancel: 'CANCEL_CREATE_EMAIL_TEMPLATE',
  share: 'SHARE_EMAIL_TEMPLATE',
  confirmShare: 'CONFIRM_SHARE_EMAIL_TEMPLATE',
  cancelShare: 'CANCEL_SHARE_EMAIL_TEMPLATE',
  delete: 'DELETE_EMAIL_TEMPLATE',
};
const smsTemplates = {
  create: 'CREATE_SMS_TEMPLATE',
  duplicate: 'DUPLICATE_SMS_TEMPLATE',
  duplicateShared: 'DUPLICATE_SHARED_SMS_TEMPLATE',
  save: 'SAVE_SMS_TEMPLATE',
  cancel: 'CANCEL_CREATE_SMS_TEMPLATE',
  share: 'SHARE_SMS_TEMPLATE',
  confirmShare: 'CONFIRM_SHARE_SMS_TEMPLATE',
  cancelShare: 'CANCEL_SHARE_SMS_TEMPLATE',
  delete: 'DELETE_SMS_TEMPLATE',
};
const dripTemplates = {
  create: 'CREATE_DRIP_TEMPLATE',
  duplicate: 'DUPLICATE_DRIP_TEMPLATE',
  duplicateShared: 'DUPLICATE_SHARED_DRIP_TEMPLATE',
  save: 'SAVE_DRIP_TEMPLATE',
  cancel: 'CANCEL_CREATE_DRIP_TEMPLATE',
  share: 'SHARE_DRIP_TEMPLATE',
  confirmShare: 'CONFIRM_SHARE_DRIP_TEMPLATE',
  cancelShare: 'CANCEL_SHARE_DRIP_TEMPLATE',
  delete: 'DELETE_DRIP_TEMPLATE',
};
const aryaBotTemplates = {
  create: 'CREATE_ARYABOT_TEMPLATE',
  duplicate: 'DUPLICATE_ARYABOT_TEMPLATE',
  duplicateShared: 'DUPLICATE_SHARED_ARYABOT_TEMPLATE',
  save: 'SAVE_ARYABOT_TEMPLATE',
  cancel: 'CANCEL_CREATE_ARYABOT_TEMPLATE',
  share: 'SHARE_ARYABOT_TEMPLATE',
  confirmShare: 'CONFIRM_SHARE_ARYABOT_TEMPLATE',
  cancelShare: 'CANCEL_SHARE_ARYABOT_TEMPLATE',
  delete: 'DELETE_ARYABOT_TEMPLATE',
};

const connectEventTypes = {
  aryaBotTemplates,
  conversations,
  dripTemplates,
  emailTemplates,
  smsTemplates,
};

export default connectEventTypes;
