import React from 'react';
import DeleteNoteCardModal from './DeleteNoteCardModal/DeleteNoteCardModal';

function NoteActionItems(props) {
  const { onDelete, jobNoteDeleteApiStatus, noteDetails, isDeleteModalVisible, setDeleteModalVisibility } = props;

  return (
    <div>
      {isDeleteModalVisible ? (
        <DeleteNoteCardModal
          noteDetails={noteDetails}
          jobNoteDeleteApiStatus={jobNoteDeleteApiStatus}
          onDelete={onDelete}
          isModalVisible={isDeleteModalVisible}
          onCancel={() => {
            setDeleteModalVisibility(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default NoteActionItems;
