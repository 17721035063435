import { useDrag, useDrop } from 'react-dnd';

export function useDragAndDrop({ itemType, index, itemId, moveCard, ref, isDragAllowed }) {
  const [{ handlerId }, drop] = useDrop({
    item: { type: itemType },
    accept: itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: itemType,
    item: { id: itemId, index, type: itemType },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isDragAllowed,
  });

  return [handlerId, drop, drag];
}
