function getDummyJobIntel() {
  return {
    blsData: {
      areaData: {},
      countryData: {},
      stateData: {},
    },
    keywordSetData: {
      mustHaves: [],
      niceToHaves: [],
      relevant: [{ name: 'html', size: 5 }],
    },
    industries: {
      industryNames: ['computer software', 'software developers', 'information technology and services', 'Internet'],
      industryValues: [2, 3, 5, 6],
    },
    companies: {
      companyNames: ['Facebook', 'Leoforce', 'indeed', 'MicroSoft', 'Indeed', 'Apple', 'Tesla'],
      companyValues: [2, 3, 5, 6, 4, 8, 8],
    },
    jobFunctionsData: [],
  };
}

function getDummyJobDetails() {
  return {
    AutoExpand: false,
    Client: 'Leoforce',
    ClientId: 1234,
    Country: 'USA',
    CountryCode: 'US',
    CreatedBy: 1234,
    CreatedDate: '2021-02-23T16:36:23',
    Description: `<h3>About the job</h3><p><span style="color: rgba(0, 0, 0, 0.9);">
      Leoforce is the leader in A.I. technology for talent acquisition.
      We were the first firm to apply A.I. to recruiting and continue to be a leader in the market.
      Below is detail job description for your reference. Please review and share your thoughts to discuss further.
      </span></p><p><br></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
        Overview:</span></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
        We’re building something amazing! Our technology is shaking up the recruiting IT industry,
        and we need an additional UI/UX designer to help in our growing portfolio.
        We have a high high-energy startup culture and are really into having a team mentality,
        so we’re looking for an open-minded and collaborative individual.
        You are a passionate designer and a self-starter,
        comfortable with tackling all of the UX steps from user research,
        exploration and concepts, prototyping and user testing, to launch.
        If you are driven, enthusiastic, hard-working, and want to put your creativity to work,
        we want to hear from you!</span></p><p><span style="color: rgba(0, 0, 0, 0.9);
        background-color: transparent;">&nbsp;</span></p><p><span style="color: rgba(0, 0, 0, 0.9);
        background-color: transparent;">Responsibilities:</span></p><p><span style="color: rgba(0, 0, 0, 0.9);
         background-color: transparent;">• Own the user experience on our products.
         Be relied on to translate user needs and business objectives into designs that provide intuitive experiences for our users.
         </span></p><p><span style="color: rgba(0, 0, 0, 0.9);
         background-color: transparent;">
         • Champion and effectively communicate design ideas using sketches,
          user stories, wireframes, mockups and prototypes in a cross-functional team environment.
          </span></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          • Aid the UX team and product manager to drive product features from concept to final implementation,
          based on the UX goals and priorities decided by the team.</span></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          • Present and communicate design work in a clear, confident, and professional manner;
          explain the rationale for design decisions.</span></p><p><br></p><p>
          <span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          A successful candidate will have:</span></p><p><span style="color: rgba(0, 0, 0, 0.9);
          background-color: transparent;">• Strong leadership and management skills.</span></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          • A professional portfolio with interaction and visual design experience across brand, web,
          mobile and native apps.</span></p><p><span style="color: rgba(0, 0, 0, 0.9);
          background-color: transparent;">• 5+ years In UI/UX design</span></p><p>
          <span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          • Strong conceptual thinking and design execution abilities in both visual and interaction design.
          </span></p><p><span style="color: rgba(0, 0, 0, 0.9); background-color: transparent;">
          • Strong leadership and management skills.</span></p><p><span style="color: rgba(0, 0, 0, 0.9);`,
  };
}

export { getDummyJobIntel, getDummyJobDetails };
