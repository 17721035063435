import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emailDrips: {
    id: 'Templates.Drips.emailDrips',
    defaultMessage: 'Email Drips',
  },
  dripSequence: {
    id: 'Templates.Drips.Heading.dripSequence',
    defaultMessage: 'DRIP SEQUENCE',
  },
  updatedOn: {
    id: 'Templates.Drips.Heading.updatedOn',
    defaultMessage: 'UPDATED ON',
  },
  includesBot: {
    id: 'Templates.Drips.Heading.includesBot',
    defaultMessage: 'INCLUDES BOT',
  },
  shared: {
    id: 'Templates.Drips.Heading.shared',
    defaultMessage: 'SHARED',
  },
  createdBy: {
    id: 'Templates.Drips.Heading.createdBy',
    defaultMessage: 'CREATED BY',
  },
  stats: {
    id: 'Templates.Drips.Heading.stats',
    defaultMessage: 'STATS',
  },
  myDrips: {
    id: 'Templates.Drips.Tab.myDrips',
    defaultMessage: 'My Drips',
  },
  sharedDrips: {
    id: 'Templates.Drips.Tab.sharedDrips',
    defaultMessage: 'Shared Drips',
  },
  searchDrip: {
    id: 'Templates.Drips.searchDrips',
    defaultMessage: 'Search Drips',
  },
});

export default messages;
