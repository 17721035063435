import React from 'react';
import { Modal } from 'antd';
import ExtendedBotConfigContainer from './ExtendedBotConfigContainer';

function BotConfigPopup(props) {
  const {
    title,
    subTitle,
    botModalVisibility,
    onBotClose,
    onBotCancel,
    hideSelectTemplate,
    getJobQuestionApiStatus,
    jobId,
    cancelButtonText,
    saveButtonText,
    closable = false,
    readOnly,
    isPaidJobServiceEnabled,
  } = props;
  return (
    <Modal
      title={title}
      visible={botModalVisibility}
      footer={null}
      zIndex={2050}
      closable={closable}
      destroyOnClose
      onCancel={onBotCancel}
      maskClosable={false}
    >
      <ExtendedBotConfigContainer
        subTitle={subTitle}
        onClose={onBotClose}
        jobId={jobId}
        onCancel={onBotCancel}
        cancelButtonText={cancelButtonText}
        saveButtonText={saveButtonText}
        hideSelectTemplate={hideSelectTemplate}
        getJobQuestionApiStatus={getJobQuestionApiStatus}
        readOnly={readOnly}
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      />
    </Modal>
  );
}
export default BotConfigPopup;
