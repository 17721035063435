export const SET_CANDIDATE_ADVANCE_FILTER_FIELDS = 'SET_CANDIDATE_ADVANCE_FILTER_FIELDS';
export const SET_CAMPAIGN_CREATION_API_STATUS = 'SET_CAMPAIGN_CREATION_API_STATUS';
export const SET_CAMPAIGN_STATS = 'SET_CAMPAIGN_STATS';
export const SET_CANDIDATE_ADVANCE_FILTER_PORTALS = 'SET_CANDIDATE_ADVANCE_FILTER_PORTALS';

export const setCandidateAdvanceFields = ({ fields }) => ({
  type: SET_CANDIDATE_ADVANCE_FILTER_FIELDS,
  payload: {
    fields,
  },
});

export const setCandidateAdvanceFilterPortals = ({ portals }) => ({
  type: SET_CANDIDATE_ADVANCE_FILTER_PORTALS,
  payload: {
    portals,
  },
});

export const setCampaignCreationApiStatus = payload => ({
  type: SET_CAMPAIGN_CREATION_API_STATUS,
  payload,
});

export const setCampaignStats = payload => ({
  type: SET_CAMPAIGN_STATS,
  payload,
});
