// import _ from 'lodash';

const initialState = {
  isVisible: false,
  isCallInitiated: false,
};

function CallDialogReducer(state = initialState, action) {
  // let newState;
  switch (action.type) {
    case 'SET_CALL_DIALOG_VISIBILITY':
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };
    case 'INITIATE_CALL':
      return {
        ...state,
        isCallInitiated: action.payload.callInitiated,
        ...action.payload,
      };
    case 'SET_SIP_REGISTRATION_STATUS':
      return {
        ...state,
        isSipRegistered: action.payload.isSipRegistered,
      };
    case 'SET_CALLER_ID':
      return {
        ...state,
        callerId: action.payload.callerId,
      };
    case 'RESET_CALL_DIALOG_STATE':
      return { ...state, ...action.payload };
    case 'SET_NOTES_VIEW_STATE':
      return { ...state, notesOpened: action.payload };
    default:
      return state;
  }
}

function getCallDialogVisibility(state) {
  return state.CallDialogReducer.isVisible || false;
}

function getCallDialogState(state) {
  return state.CallDialogReducer || {};
}

function getCallDialogNotesViewState(state) {
  return state.CallDialogReducer.notesOpened || false;
}

export { CallDialogReducer, getCallDialogVisibility, getCallDialogState, getCallDialogNotesViewState };
