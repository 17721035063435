import React from 'react';
import InfoIconWithTooltip from '../InfoIconWithTooltip/InfoIconWithTooltip';
import styles from './TextWithInfoIcon.module.scss';

export default function TextWithInfoIcon(props) {
  const { text, tooltipTitle, textClassName } = props;
  return (
    <span className={styles.textWithInfoIcon}>
      <span className={textClassName || styles.text}>{text}</span>
      <InfoIconWithTooltip tooltipTitle={tooltipTitle} />
    </span>
  );
}
