import React from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import styles from './CandidateEducationSection.module.scss';
import { setElementMaxHeightByLines } from '../../../Utils/CandidateCardUtils';

function CandidateEducationSection(props) {
  const {
    candidateEducation = [],
    maxLinesToBeDisplayed,
    secondaryInfoStyle,
    shouldShowTooltip = true,
    educationRef,
  } = props;

  const textRef = React.useRef(null);

  const displayedCandidateEducation = candidateEducation.map(
    entry =>
      `${entry.University ? _.capitalize(entry.University) : ''} ${entry.Degree && entry.University ? ' - ' : ''} ${
        entry.Degree ? entry.Degree : ''
      }`
  );

  React.useEffect(() => {
    if (textRef) setElementMaxHeightByLines(textRef, maxLinesToBeDisplayed, educationRef);
  }, [displayedCandidateEducation]);

  const maxWidth = `${textRef.current?.clientWidth - 10}px`;

  return (
    <div className={styles.educationSection} style={secondaryInfoStyle} ref={textRef}>
      {shouldShowTooltip ? (
        <Tooltip
          title={displayedCandidateEducation.join(', ')}
          overlayClassName={styles.educationTooltip}
          mouseEnterDelay={0.5}
        >
          <div className={styles.candidateCardEducation}>
            <div className={styles.candidateEducation} style={{ maxWidth }}>
              {displayedCandidateEducation.map((education, index) => (
                <span style={{ minWidth: 'fit-content', marginRight: '6px' }}>
                  {education}
                  {index === displayedCandidateEducation.length - 1 ? '' : ','}
                </span>
              ))}
            </div>
          </div>
        </Tooltip>
      ) : (
        displayedCandidateEducation.join(', ')
      )}
    </div>
  );
}

export default CandidateEducationSection;
