import React from 'react';
import './UserAlerts.scss';
import styles from './UserAlerts.module.scss';

export function UserAlertWithWrapper(props) {
  const { header, content } = props;
  return (
    <div className={styles.userAlertWrapper}>
      <UserAlerts
        header={header}
        content={content}
        bodyClass={styles.alertBody}
        outerCircleClass={styles.iconWrapper}
        alertContentClass={styles.alertContentClass}
      />
    </div>
  );
}

function UserAlerts(props) {
  const { header, content, bodyClass, outerCircleClass, alertContentClass } = props;
  return (
    <div className="user-alert">
      <div className={`alert-message-body ${bodyClass}`}>
        <div className="alert-message-header">{header}</div>
        <div className={`alert-message-content ${alertContentClass}`}>{content}</div>
      </div>
      {/* TODO: need refactoring here  */}
      <div className={`lock-outer-circle ${outerCircleClass}`}>
        <div className="lock-middle-circle">
          <div className="lock-inner-circle">
            <img
              alt="premium feature"
              className="locked-img"
              src={`${process.env.PUBLIC_URL}/static/Images/locked_feature.svg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

UserAlerts.defaultProps = {
  outerCircleClass: '',
  alertContentClass: '',
  bodyClass: '',
};

export default UserAlerts;
