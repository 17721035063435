import React from 'react';
import BotStats from './BotStats/BotStats';
import CallStats from './CallStats/CallStats';
import EmailStats from './EmailStats/EmailStats';
import SmsStats from './SmsStats/SmsStats';
import styles from './ConnectStats.module.scss';

export default function ConnectStats(props) {
  const { emailStats, textStats, callStats, botSessionStats, subtitle } = props;

  return (
    <div className={styles.connectStats}>
      <div className={styles.connectGrid}>
        <div className={styles.emailResponse}>
          <EmailStats emailStats={emailStats} subtitle={subtitle} />
        </div>
        <div className={styles.textResponse}>
          <div className="svgcontainer"></div>
          <SmsStats textStats={textStats} subtitle={subtitle} />
        </div>
        <div className={styles.callResponse}>
          <CallStats callStats={callStats} subtitle={subtitle} />
        </div>
        <div className={styles.aryabotResponse}>
          <BotStats botSessionStats={botSessionStats} subtitle={subtitle} />
        </div>
      </div>
    </div>
  );
}
