import React from 'react';
import { Button, Icon } from 'antd';
import style from './UserVerification.module.scss';

export default function UserVerificationFailed({ username, resendCode, message }) {
  return (
    <>
      <div className={style.outerCircle}>
        <div className={style.innerCircle}>
          <Icon type="close" style={{ fontSize: '50px', color: 'white', margin: 'auto' }} />
        </div>
      </div>
      <h1 className={style.verificationFailedheading}>Email Verification Failed</h1>
      <p>{message}</p>
      <Button type="primary" shape="round" style={{ marginTop: '88px' }} onClick={() => resendCode(username)}>
        Resend Link
      </Button>
    </>
  );
}
