import _ from 'lodash';
import React from 'react';
import { Icon } from 'antd';
import { MonsterIcon } from '../../Icons/AryaIcons';
import { getPlainTextFromHTML } from '../TextUtils';
import { JOBBOARDFORPUBLISH } from '../JobBoardUtils';
import eventTypes from '../../Analytics/EventTypes';

export const SocialMediaJobPortals = ['facebook', 'linkedin', 'twitter'];
export const JobBoardPortals = ['monster'];
export const AdvertisementPortals = [JOBBOARDFORPUBLISH];
export const FreeAdvertisementPortals = ['adzuna'];
export const SuccessPublishedStatus = ['succeeded', 'initiated', 'success'];
export const DraftPublishedStatus = ['draft'];
export const AdCredits = 'AdCredits';
export const CreditCard = 'CreditCard';
export const Advertisement = 'Advertisement';
export const JobBoard = 'JobBoard';

export const filterEnabledJobBoardPublishPortals = jobDistributionPortals =>
  jobDistributionPortals
    ?.filter(
      portalConfig => portalConfig.IsEnabled && JobBoardPortals.includes(portalConfig.Portal?.Name.toLowerCase())
    )
    .map(portalConfig => portalConfig?.Portal?.Name) ?? [];

export const filterEnabledAdvertisementPortals = jobDistributionPortals =>
  jobDistributionPortals
    ?.filter(
      portalConfig => portalConfig.IsEnabled && AdvertisementPortals.includes(portalConfig.Portal?.Name.toLowerCase())
    )
    .map(portalConfig => portalConfig?.Portal?.Name) ?? [];

export const RecallHeading = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return 'Delete this job publish!';
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return 'Are you sure you want to end your Intelligent Job Advertising campaign?';
  }
  return null;
};

export const RecallDescription = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return `Are you sure that you wish to delete this job from ${portal}?`;
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return `This job is currently being promoted across the web using the Intelligent Job Advertising feature. Ending the campaign now will also remove promotional posts from related platforms.`;
  }
  return null;
};

export const getRecallText = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return {
      cancelText: 'No',
      confirmText: 'Yes',
      cancelSkEventName: eventTypes.job.publishJob.cancelRecallPublishJob,
      confirmSkEventName: eventTypes.job.publishJob.confirmRecallPublishJob,
    };
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return {
      cancelText: 'End Campaign',
      confirmText: 'Continue Campaign',
      cancelSkEventName: eventTypes.job.advertiseJob.confirmEndAdvertiseJob,
      confirmSkEventName: eventTypes.job.advertiseJob.continueAdvertiseJob,
    };
  }
  return {};
};

export const RecallCancelText = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return `No`;
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return `End Campaign`;
  }
  return null;
};

export const RecallConfirmText = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return `Yes`;
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return `Continue Campaign`;
  }
  return null;
};

export const GetJobBoardIcon = ({ portal, style }) => {
  switch (portal.toLowerCase()) {
    case 'monster':
      return <MonsterIcon style={style} />;
    case 'jobtarget':
    case 'adzuna':
      return <Icon type="rise" theme="outlined" style={style} />;
    default:
      return null;
  }
};

export const JobStatusChangeActivePortalText = portal => {
  if (JobBoardPortals.includes(portal.toLowerCase())) {
    return `Job Publish on ${portal}`;
  }
  if (AdvertisementPortals.includes(portal.toLowerCase())) {
    return `Ad campaign in-progress`;
  }
  if (FreeAdvertisementPortals.includes(portal.toLowerCase())) {
    return `Job Advertised on ${portal}`;
  }
  return null;
};

export const getDistributionJobDetails = (jobDetails, distributedInfo) => {
  const distributionQueryDetails = distributedInfo?.DistributionQuery;
  if (!_.isEmpty(distributionQueryDetails)) {
    return {
      JobTitle: distributionQueryDetails.JobTitle,
      JobDescription: distributionQueryDetails.JobDescription,
      CompanyName: distributionQueryDetails.CompanyContact?.CompanyName,
      Location: [distributionQueryDetails.PostingLocation?.City, distributionQueryDetails.PostingLocation?.State].join(
        ', '
      ),
      Zipcode: distributionQueryDetails.PostingLocation?.ZipCode,
    };
  }
  if (jobDetails) {
    return {
      JobTitle: jobDetails.JobTitle,
      JobDescription: jobDetails.Description,
      Location: jobDetails.Location,
      CompanyName: jobDetails.Client,
      Zipcode: jobDetails.Zipcode,
    };
  }
  return {};
};

export const hasJobEditAccess = (distributedPortalsInfo, portal, userId) => {
  return (
    userId === distributedPortalsInfo[portal.toLowerCase()]?.CreatedBy ||
    userId === distributedPortalsInfo[portal.toLowerCase()]?.UpdatedBy
  );
};

export const validateDescription = (rule, value, callback) => {
  const plainTextDescription = getPlainTextFromHTML(value.htmlContent);
  if (plainTextDescription.length < 200) {
    callback(`Minimum of 200 characters required`);
    return;
  }
  callback();
};
