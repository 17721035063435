import React from 'react';
import { Icon } from 'antd';

function MoreSvg(props) {
  const { fillColor } = props;
  return (
    <svg width={4} height={16} {...props}>
      <g fill={fillColor} stroke={fillColor} fillRule="evenodd">
        <circle cx={2} cy={2} r={1.5} />
        <circle cx={2} cy={8} r={1.5} />
        <circle cx={2} cy={14} r={1.5} />
      </g>
    </svg>
  );
}

const MoreIcon = props => <Icon component={() => MoreSvg(props)} {...props} />;

export default MoreIcon;
