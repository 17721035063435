import React from 'react';
import { getProductsData } from '../../Utils/PlanSelectionUtils';
import {
  PlanCardHeader,
  PlanCardPriceInfo,
  PlanCardTurnAroundTimeInfo,
  PlanCardDescription,
} from './PlanSelectionModules';
import styles from './PlanSelection.module.scss';

export function PlanCards(props) {
  const { productVariants, isPulsePromotionalOfferEnabled } = props;
  const productVariantsData = getProductsData(productVariants, null, null, null, isPulsePromotionalOfferEnabled);
  return (
    <div className={styles.plancards}>
      {productVariantsData.map(productVariant => (
        <PlanCard key={productVariant.title} planCardInfo={productVariant} productVariants={productVariants} />
      ))}
    </div>
  );
}
export function PlanCard(props) {
  const { planCardInfo } = props;
  return (
    <div className={styles.planCard}>
      <div className={styles.planCardHeaderWrapper}>
        <PlanCardHeader planCardInfo={planCardInfo} />
      </div>
      <div className={styles.planCardPriceInfoWrapper}>
        <PlanCardPriceInfo planCardInfo={planCardInfo} />
      </div>
      <div className={styles.planCardTurnAroundTimeInfoWrapper}>
        <PlanCardTurnAroundTimeInfo planCardInfo={planCardInfo} />
      </div>
      <div className={styles.plancardDescriptionWrapper}>
        <PlanCardDescription planCardInfo={planCardInfo} />
      </div>
    </div>
  );
}
