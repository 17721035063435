import deDE from 'antd/lib/locale-provider/de_DE';
import enUS from 'antd/lib/locale-provider/en_US';
import frFR from 'antd/lib/locale-provider/fr_FR';
import itIT from 'antd/lib/locale-provider/it_IT';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import zh from 'react-intl/locale-data/zh';
import deDEMessages from './Locales/de_DE.json';
import enUSMessages from './Locales/en_US.json';
import frFRMessages from './Locales/fr_FR.json';
import itITMessages from './Locales/it_IT.json';
import zhCNMessages from './Locales/zh_CN.json';

const langConfig = {
  de: {
    messages: {
      ...deDEMessages,
    },
    antd: deDE,
    locale: 'de-DE',
    data: de,
  },
  en: {
    messages: {
      ...enUSMessages,
    },
    antd: enUS,
    locale: 'en-US',
    data: en,
  },
  fr: {
    messages: {
      ...frFRMessages,
    },
    antd: frFR,
    locale: 'fr-FR',
    data: fr,
  },
  it: {
    messages: {
      ...itITMessages,
    },
    antd: itIT,
    locale: 'it-IT',
    data: it,
  },
  zh: {
    messages: {
      ...zhCNMessages,
    },
    antd: zhCN,
    locale: 'zh-CN',
    data: zh,
  },
};

export default langConfig;
