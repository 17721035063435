import React from 'react';
import _ from 'lodash';
import { JobCreditIcon } from '../../Icons/AryaIcons';
import './PlanCredits.scss';
import TextWithInfoIcon from '../Common/TextWithInfoIcon/TextWithInfoIcon';

export default function PlanCredits(props) {
  const { planDetailsByProductVariantId, productVariantsById, isCreateJobPage } = props;
  if (_.isEmpty(productVariantsById)) return null;
  const filterdPlanIds = Object.keys(productVariantsById).filter(
    productVariantId =>
      productVariantsById[productVariantId].IsHidden !== true &&
      productVariantsById[productVariantId].Name !== 'Premium Upgrade'
  );
  const plansOrder = ['Basic', 'Premium'];
  const sortedPlanIds = filterdPlanIds.sort((Id1, Id2) => {
    return plansOrder.indexOf(productVariantsById[Id1].Name) - plansOrder.indexOf(productVariantsById[Id2].Name);
  });
  return sortedPlanIds.map(productVariantId => {
    const planAvailableCredits = _.get(planDetailsByProductVariantId, [productVariantId, 'AvailableCredits'], 0);
    return (
      <div className="plan-credit-information" key={productVariantId}>
        <JobCreditIcon />{' '}
        {isCreateJobPage ? (
          `${planAvailableCredits} Full-Service Credits Available`
        ) : (
          <TextWithInfoIcon
            text={`${planAvailableCredits} FREE Credits left`}
            tooltipTitle={`Avail Arya Pulse Full-Service Plan with ${planAvailableCredits} Free credit points and source the right candidate for the job.`}
          />
        )}
      </div>
    );
  });
}
