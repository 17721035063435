import React from 'react';
import { debounce } from 'lodash';

import CandidateTag from './CandidateTag';

const candidateTagDiv = { height: '56px', overflow: 'auto' };

function CandidateTagsTab(props) {
  const {
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    fetchTagsForCandidate,
    createCandidateTag,
    jobId,
    candidate,
    candidateGlobalTags,
    fetchCandidateGlobalTags,
    fetchCandidateGlobalTagsApiStatus,
    deleteTagForCandidate,
    tagCloseApiStatus,
    suggestedTagsApiStatus,
    setNotification,
    candidateTagStyle,
  } = props;

  const [isInvalidTagErrorVisible, setInvalidTagErrorVisiblity] = React.useState(false);
  const candidateTagRef = React.useRef(null);

  React.useEffect(() => {
    const candidateTagElement = candidateTagRef.current;
    if (candidateTagElement) candidateTagElement.scrollTo(0, candidateTagElement.scrollHeight);
  }, [isInvalidTagErrorVisible, candidateGlobalTags.length]);

  const handleFetchCandidateTags = () => {
    fetchCandidateGlobalTags({ candidateIds: [candidate?.Id] });
  };

  const debounceFetchSuggestedTags = React.useCallback(
    debounce(nextValue => fetchTagsForCandidate(nextValue), 600),
    []
  );

  const onTagChange = value => {
    debounceFetchSuggestedTags({
      searchTerm: value,
      from: 0,
      size: 20,
      type: 'candidate',
    });
  };

  const onLoadMore = payload => {
    fetchTagsForCandidate(payload);
  };

  const onTagAdd = tag => {
    const tagsName = [];
    if (candidateGlobalTags.length > 0) {
      candidateGlobalTags.map(candidateGlobalTag => tagsName.push(candidateGlobalTag.Name.toUpperCase()));
      if (tagsName.includes(tag.toUpperCase())) {
        setNotification('ERROR', 'Tag already exists');
      } else {
        createCandidateTag({ tag, jobId, candidateId: candidate?.Id });
      }
    } else {
      createCandidateTag({ tag, jobId, candidateId: candidate?.Id });
    }
  };

  const onTagClose = tag => {
    deleteTagForCandidate({ tag, jobId, candidateId: candidate?.Id });
  };

  return (
    <div style={candidateTagStyle || candidateTagDiv} ref={candidateTagRef}>
      <CandidateTag
        tags={candidateGlobalTags}
        suggestedTags={candidateSuggestedTags}
        onTagChange={onTagChange}
        totalSuggestedTagsCount={candidateSuggestedTagsCount}
        onTagAdd={onTagAdd}
        onLoadMore={onLoadMore}
        handleFetchCandidateTags={handleFetchCandidateTags}
        fetchCandidateGlobalTagsApiStatus={fetchCandidateGlobalTagsApiStatus}
        onTagClose={onTagClose}
        tagCloseApiStatus={tagCloseApiStatus}
        suggestedTagsApiStatus={suggestedTagsApiStatus}
        setNotification={setNotification}
        isInvalidTagErrorVisible={isInvalidTagErrorVisible}
        setInvalidTagErrorVisiblity={setInvalidTagErrorVisiblity}
      />
    </div>
  );
}

export default CandidateTagsTab;
