import {
  SELECT_PLAN,
  SAVE_JOB,
  SIGN_UP,
  CREATE_JOB,
  CHECKOUT_PROGRESS,
  PURCHASE,
  SELECT_MODE,
  ADVANCED_JOB_BASIC_INFO,
  ADVANCED_JOB_INSIGHTS,
  ADVANCED_JOB_ADDITIONAL_INPUT,
  ADVANCED_JOB_EDIT_INSIGHTS,
  ADVANCED_JOB_EDIT_TITLE,
  ADVANCED_JOB_SAVE_DRAFT,
  NORMAL_JOB_SAVE_DRAFT,
  VIEW_SAMPLE_CANDIDATES,
  VIEW_PLAN_SELECTION,
  FREE_JOB_CHECKOUT,
  FREE_JOB_SUBMITTED,
  FREE_JOB_PURCHASED,
  PLAN_UPGRADE,
  RESUME_DOWNLOAD,
  CANDIDATE_VIEW,
  GO_TO_BUY_CREDIT,
  GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
} from './EventTypes';
import * as socketConnectionManager from '../Events/SocketConnectionManager';
import { broadcastChannel } from '../Events/BroadcastNotificationHandler';
import * as JobActions from '../Actions/JobActions';
import {
  getGtagPayloadForDefaultEvents,
  getGtagPayloadForPlanUpgradeEvent,
  getGtagPayloadForResumeDownloadEvent,
  getGtagPayloadForCandidateViewEvent,
  getGtagPayloadForBuyCreditEvent,
} from '../Utils/PubSubUtils';
import { getCurrentUserDetails } from '../Reducers/UserSessionReducer';
import store from '../store';

function aryaEventToPulseGtagEventMapper(event) {
  const eventsMapping = {
    [SELECT_MODE]: 'view_item_list',
    [CREATE_JOB]: 'view_item',
    [ADVANCED_JOB_BASIC_INFO]: 'advanced_job_basic_info',
    [ADVANCED_JOB_INSIGHTS]: 'advanced_job_insights',
    [ADVANCED_JOB_ADDITIONAL_INPUT]: 'advanced_job_additional_input',
    [ADVANCED_JOB_EDIT_INSIGHTS]: 'advanced_job_edit_insights',
    [ADVANCED_JOB_EDIT_TITLE]: 'advanced_job_edit_title',
    [ADVANCED_JOB_SAVE_DRAFT]: 'advanced_job_save_draft',
    [NORMAL_JOB_SAVE_DRAFT]: 'normal_job_save_draft',
    [VIEW_SAMPLE_CANDIDATES]: 'view_sample_candidates',
    [VIEW_PLAN_SELECTION]: 'view_plan_selection',
    [SAVE_JOB]: 'add_to_cart',
    [SELECT_PLAN]: 'begin_checkout',
    [CHECKOUT_PROGRESS]: 'checkout_progress',
    [PURCHASE]: 'purchase',
    [SIGN_UP]: 'sign_up',
    [FREE_JOB_CHECKOUT]: 'free_job_checkout',
    [FREE_JOB_SUBMITTED]: 'free_job_submitted',
    [FREE_JOB_PURCHASED]: 'free_job_purchased',
    [PLAN_UPGRADE]: 'plan_upgrade',
    [RESUME_DOWNLOAD]: 'resume_download',
    [CANDIDATE_VIEW]: 'candidate_view',
    [GO_TO_BUY_CREDIT]: 'go_to_buy_credit',
    [GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE]: 'go_to_job_description_recommendation_template',
    [SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE]: 'select_job_description_recommendation_template',
  };
  return eventsMapping[event];
}

function getGtagPayload(payload, eventName) {
  switch (eventName) {
    case 'plan_upgrade':
      return getGtagPayloadForPlanUpgradeEvent(payload);
    case 'resume_download':
      return getGtagPayloadForResumeDownloadEvent(payload);
    case 'candidate_view':
      return getGtagPayloadForCandidateViewEvent(payload);
    case 'go_to_buy_credit':
      return getGtagPayloadForBuyCreditEvent(payload);
    default:
      return getGtagPayloadForDefaultEvents(payload, eventName);
  }
}

function trackPulseActions(userAction, payload) {
  const state = store.getState();
  const currentUserDetails = getCurrentUserDetails(state);
  const { IsSandboxed: isSanboxed } = currentUserDetails ?? {};
  if (window.gtag) {
    if (!isSanboxed || userAction === SIGN_UP) {
      const eventName = aryaEventToPulseGtagEventMapper(userAction);
      const gtagPayload = getGtagPayload(payload, eventName);
      window.gtag('event', eventName, gtagPayload);
    }
  }
}

function setupSocketConnections(action, payload) {
  const { dispatch } = payload;
  dispatch(socketConnectionManager.setupSocketConnections());
}

function postMessage(action, payload) {
  const { broadcastNotification } = payload;
  try {
    broadcastChannel.postMessage(broadcastNotification);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

function fetchJobs(action, payload) {
  const { dispatch } = payload;
  dispatch(
    JobActions.fetchJobs({
      jobGuids: payload.filteredJobGuids,
    })
  );
}

export { trackPulseActions, setupSocketConnections, postMessage, fetchJobs, getGtagPayload };
