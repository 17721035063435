const initialElements = {
  Title: 'New Workflow',
  StartActivityId: '2',
  Activities: [
    {
      ActivityId: '2',
      Type: 'End',
      Name: 'End',
      DisplayName: 'End',
      Properties: [],
    },
  ],
  Connections: [],
};

export { initialElements };
