import React from 'react';
import { Form, InputNumber } from 'antd';
import { validateMinExperience, validateMaxExperience } from '../../Utils/FormValidators';

const FormItem = Form.Item;
export default function MinMaxExperience(props) {
  const {
    initialMinExperience,
    initialMaxExperience,
    form,
    showLabel = true,
    inputStyle,
    inputClassName,
    minExperiencePlaceholder,
    maxExperiencePlaceholder,
  } = props;

  const minExpPlaceholder = minExperiencePlaceholder || '0 years';
  const maxExpPlaceholder = maxExperiencePlaceholder || '0 years';

  return (
    <>
      <FormItem label={showLabel ? 'Min Overall Exp' : ''} colon={false}>
        {form.getFieldDecorator('MinExperience', {
          initialValue: initialMinExperience,
          rules: [{ validator: (rule, value, callback) => validateMinExperience(form, value, callback) }],
        })(
          <InputNumber
            placeholder={minExpPlaceholder}
            min={0}
            max={50}
            label="Min Overall Exp"
            style={inputStyle}
            className={inputClassName}
          />
        )}
      </FormItem>
      <FormItem label={showLabel ? 'Max Overall Exp' : ''} colon={false}>
        {form.getFieldDecorator('MaxExperience', {
          initialValue: initialMaxExperience,
          rules: [{ validator: (rule, value, callback) => validateMaxExperience(form, value, callback) }],
        })(
          <InputNumber
            placeholder={maxExpPlaceholder}
            min={0}
            max={50}
            label="Max Overall Exp"
            style={inputStyle}
            className={inputClassName}
          />
        )}
      </FormItem>
    </>
  );
}
