import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { getRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import './ConversationListItem.scss';

// @author: Prashanth

export default function ConversationListItem(props) {
  const { isActive, title, badgeCount, primaryInfo, secondaryInfo, isoDateTime, onClick, jobCode } = props;
  const isActiveCard = isActive ? 'isActive' : '';
  return (
    <div className={`card ${isActiveCard}`} onClick={onClick} role="presentation">
      <div className="titleBox">
        <div className="title ellipsis">
          <span>{title}</span>
        </div>
        {jobCode ? (
          <div className="job-external-source">
            <Tooltip title={jobCode}>
              <span className="job-external-source-text ">E</span>
            </Tooltip>
          </div>
        ) : null}
        <div className="extraInfo">{badgeCount > 0 ? <div className="countBadge">{badgeCount}</div> : null}</div>
      </div>
      <div className="primaryInfoBox ellipsis">
        <span>{primaryInfo}</span>
      </div>
      <div className="secondaryInfoBox">
        <div className="secondaryInfo ellipsis">
          <span>{secondaryInfo}</span>
        </div>
        <div className="timestampBox ellipsis">
          <span>{isoDateTime ? getRelativeTime(isoDateTime) : null}</span>
        </div>
      </div>
    </div>
  );
}

ConversationListItem.propTypes = {
  title: PropTypes.string.isRequired,
  primaryInfo: PropTypes.string,
  secondaryInfo: PropTypes.string,
  // badgeCount: PropTypes.number,
  // showDotIndicator: PropTypes.bool,
  // botStatus: PropTypes.OneOf(['active', 'inactive', 'completed']),
  isoDateTime: PropTypes.string,
  isActive: PropTypes.bool,
};

ConversationListItem.defaultProps = {
  primaryInfo: '',
  secondaryInfo: null,
  // badgeCount: null,
  isActive: false,
  isoDateTime: undefined,
  // botStatus: null,
  // showDotIndicator: false,
};
