import _ from 'lodash';

function CallConversationsReducer(state = {}, action) {
  let newState;
  let conversationCalls;
  let byAudioConversationId;
  let newCallHistory;
  const { payload } = action;
  switch (action.type) {
    case 'SET_CONVERSATION_CALLS':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      if (!newState.ById[payload.conversationId]) {
        newState.ById[payload.conversationId] = {};
      }
      newCallHistory = (payload.Calls || []).map(call => {
        // if (
        //   ['Ringing', 'Initiated'].includes(call.ConversationStatus) &&
        //   call.Id !== newState.ById[payload.conversationId].OngoingCallId
        // ) {
        //   return { ...call, ConversationStatus: 'Canceled' };
        // }
        return call;
      });
      if (newCallHistory) {
        newState.ById[payload.conversationId].Order = newCallHistory.map(call => call.Id);
        byAudioConversationId = _.keyBy(newCallHistory, 'Id');
        if (!newState.ById[payload.conversationId].ByCallId) {
          newState.ById[payload.conversationId].ByCallId = {};
        }
        newState.ById[payload.conversationId].ByCallId = {
          ...newState.ById[payload.conversationId].ByCallId,
          ...byAudioConversationId,
        };
      }
      return newState;
    case 'ADD_NEW_CALL':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      conversationCalls = _.get(newState, ['ById', payload.conversationId], null);
      if (!conversationCalls) {
        newState.ById[payload.conversationId] = {};
        newState.ById[payload.conversationId].Order = [];
        newState.ById[payload.conversationId].ByCallId = {};
      }
      if (
        !payload.Call.ConversationStatus ||
        payload.Call.ConversationStatus === 'Initiated' ||
        payload.Call.ConversationStatus === 'Ringing' ||
        payload.Call.ConversationStatus === 'InProgress'
      ) {
        newState.ById[payload.conversationId].OngoingCallId = payload.Call.Id;
      } else {
        newState.ById[payload.conversationId].OngoingCallId = null;
      }
      newState.ById[payload.conversationId].Order.unshift(payload.Call.Id);
      newState.ById[payload.conversationId].ByCallId[payload.Call.Id] = payload.Call;
      if (!payload.Call.ConversationStatus) {
        newState.ById[payload.conversationId].ByCallId[payload.Call.Id].ConversationStatus = 'Initiated';
      }
      return newState;
    case 'UPDATE_CALL_STATUS':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      conversationCalls = _.get(newState, ['ById', payload.conversationId], []);
      if (!conversationCalls) {
        throw new Error('Call conversations do not exist for the specified conversationId.');
      }
      if (
        newState.ById[payload.conversationId].ByCallId &&
        newState.ById[payload.conversationId].ByCallId[payload.audioConversationId]
      ) {
        newState.ById[payload.conversationId].ByCallId[payload.audioConversationId].ConversationStatus =
          payload.conversationStatus;
        if (action.payload.conversationStatus === 'Answered') {
          newState.ById[payload.conversationId].ByCallId[
            payload.audioConversationId
          ].AnsweredTime = new Date().toISOString();
        } else if (action.payload.conversationStatus === 'Completed') {
          newState.ById[payload.conversationId].ByCallId[
            payload.audioConversationId
          ].EndedTime = new Date().toISOString();
        }
      }
      newState.ById[payload.conversationId] = conversationCalls;
      return newState;
    case 'UNSET_ONGOING_CALL_ID':
      newState = _.cloneDeep(state);
      conversationCalls = _.get(newState, ['ById', payload.conversationId], null);
      if (!conversationCalls) {
        if (!newState.ById) {
          newState.ById = {};
        }
        newState.ById[payload.conversationId] = {};
        newState.ById[payload.conversationId].Order = [];
        newState.ById[payload.conversationId].ByCallId = {};
        newState.ById[payload.conversationId].OngoingCallId = null;
      }
      return newState;
    default:
      return state;
  }
}

function getCallConversations(state, conversationId) {
  return _.get(state.CallConversationsReducer, ['ById', conversationId]);
}

function getOngoingCallId(state, conversationId) {
  return _.get(state.CallConversationsReducer, ['ById', conversationId, 'OngoingCallId']);
}

function getCallStatus(state, conversationId, callId) {
  return _.get(
    state.CallConversationsReducer,
    ['ById', conversationId, 'ByCallId', callId, 'ConversationStatus'],
    null
  );
}

export { CallConversationsReducer, getCallConversations, getOngoingCallId, getCallStatus };
