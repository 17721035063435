import React from 'react';
import { Button } from 'antd';
import * as ReactRedux from 'react-redux';
import { getAccountTypesUnauthorizedScreenInfo } from '../../Utils/AccountTypesUtils';
import LogOutButton from '../Auth/LogOutButton';
import signOutAction from '../../Actions/AuthActions';
import { closeSocketConnection } from '../../Events/SocketConnectionManager';
import './AccountNotConfigured.scss';

function AccountNotConfigured() {
  const dispatch = ReactRedux.useDispatch();
  const { header, footer, subTitle } = getAccountTypesUnauthorizedScreenInfo();

  React.useEffect(() => {
    closeSocketConnection();
  }, []);

  const signout = () => {
    dispatch(signOutAction());
  };

  return (
    <div className="not-authorized-wrapper">
      <div className="not-authorized-logo">
        <div className="not-authorized">
          <div className="title">{header ?? 'Your account is not configured with Arya'}</div>
          {subTitle && <div className="subtitle">{subTitle}</div>}
          <LogOutButton
            signOut={signout}
            renderProp={handleClick => (
              <Button type="primary" shape="round" className="unauthorized-logout-button" onClick={handleClick}>
                Back to login
              </Button>
            )}
          />
          <div className="footer">
            {footer}
            Please contact your administrator or <a href="mailto:support@leoforce.com">support@leoforce.com</a> for more
            information.
          </div>
        </div>
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/static/Images/aryalogo-dark.svg`} alt="arya logo" />
        </div>
      </div>
    </div>
  );
}

export default AccountNotConfigured;
