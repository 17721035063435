/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import _ from 'lodash';
import { IntlProvider } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Input, Button, LocaleProvider } from 'antd';
import { connect } from 'react-redux';
import { SignIn } from 'aws-amplify-react';
import PubSub from 'pubsub-js';
import store from '../../store';
import config from '../../Config/Config';
import * as ConfigActions from '../../Actions/ConfigActions';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { setupSocketConnections, closeSocketConnection } from '../../Events/SocketConnectionManager';
import { SIGN_UP } from '../../PubSub/EventTypes';
import { trackPulseActions } from '../../PubSub/SubscriberActions';
import AppLogo from './AppLogo';
import PulseLoginPage from './PulseLogin/PulseLoginPage';
import './LoginForm.scss';
import './Auth.scss';

const mapDispatchToProps = {
  fetchWhiteLabelInfo: ConfigActions.fetchWhiteLabelInfo,
  removeWhiteLabelData: ConfigActions.removeWhiteLabelData,
};

const mapStateToProps = state => ({
  whiteLabelInfo: getWhiteLabelInformation(state),
});

class LoginForm extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loading: false,
      backgroundColor: 'white',
    };

    this._validAuthStates = ['signIn', 'signedOut'];

    this.onSubmit = this.onSubmit.bind(this);
    this.onLoginError = this.onLoginError.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
  }

  componentDidMount() {
    const { fetchWhiteLabelInfo, whiteLabelInfo, removeWhiteLabelData } = this.props;
    const client = _.find(config.whiteLabelClients, currentClient =>
      window.location.href.toLowerCase().includes(currentClient.toLowerCase())
    );
    if (client) {
      if (!localStorage.getItem('Client')) {
        localStorage.setItem('Client', client);
        fetchWhiteLabelInfo(client);
      }
    }
    Auth.currentAuthenticatedUser().catch(() => {
      if (!client) {
        localStorage.removeItem('Client');
        removeWhiteLabelData();
      } else {
        localStorage.setItem('Client', client);
        fetchWhiteLabelInfo(client);
      }
    });
    const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
    this.setState({
      backgroundColor: currentBgColor,
    });
    PubSub.subscribe(SIGN_UP, trackPulseActions);
  }

  componentDidUpdate(prevProps) {
    const { whiteLabelInfo } = this.props;
    if (prevProps.whiteLabelInfo !== whiteLabelInfo) {
      const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
      this.setState({
        backgroundColor: currentBgColor,
      });
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe(SIGN_UP, trackPulseActions);
  }

  onLoginError(err) {
    // eslint-disable-next-line no-console
    console.error(err);
    this.setState({
      loading: false,
    });
  }

  onSubmit(e) {
    const { username, password, loading } = this.state;
    sessionStorage.removeItem('aryaidp');
    if (loading || !username || !password) {
      return;
    }
    this.inputs.username = username.trim().toLowerCase();
    this.setState({
      loading: true,
      username: this.inputs.username,
    });
    super
      .signIn(e)
      .then(() => {
        closeSocketConnection();
        // ? Cannot connect this component due to AWS Amplify
        // ? Hence dispaching using store
        store.dispatch(setupSocketConnections());
      })
      .catch(err => this.onLoginError(err));
  }

  onHandleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    super.handleInputChange(e);
  }

  onForgotPassword() {
    super.changeState('forgotPassword');
  }

  onSignUpClick = () => {
    const { history } = this.props;
    history.push('/signup');
  };

  showComponent() {
    const pulseFormContent = {
      loginFormContent: {
        message: 'Log in to your account',
      },
      signupFormContent: {
        message: 'Sign up',
        footer: 'Already a Member?',
        footerLinkText: 'Log In Now',
      },
    };
    const { appLocale, location } = window;
    const { hostname } = location;
    const { username, password, loading, backgroundColor } = this.state;
    const { history } = this.props;

    const isDisabled = !username || !password;
    const userType = hostname === config.urls.pulseHostName ? 'pulse' : 'non-pulse';
    const idp = sessionStorage.getItem('aryaidp');
    if (idp) {
      return (
        <div className="window-wrapper">
          <div className="login-wait-text">Sit tight while we log you in!</div>
        </div>
      );
    }
    return (
      <LocaleProvider locale={appLocale.antd}>
        <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
          {userType === 'pulse' ? (
            <div className="auth-container-pulse">
              <PulseLoginPage
                formContent={pulseFormContent}
                onHandleChange={this.onHandleChange}
                onSubmit={this.onSubmit}
                username={username}
                password={password}
                loading={loading}
                onForgotPassword={this.onForgotPassword}
                onSignUpClick={this.onSignUpClick}
                history={history}
              />
            </div>
          ) : (
            <div className="auth-container" style={{ backgroundColor }}>
              <AppLogo />
              <div className="auth-form">
                <p className="auth-title">Welcome Back</p>
                <p className="auth-sub-title">Login to your account</p>
                <p className="auth-label">Email</p>
                <Input
                  id="username"
                  key="username"
                  name="username"
                  required
                  onChange={this.onHandleChange}
                  onPressEnter={this.onSubmit}
                  className="auth-input"
                  placeholder="Email address"
                  value={username}
                />
                <p className="auth-label">Password</p>
                <Input.Password
                  id="password"
                  key="password"
                  name="password"
                  required
                  onChange={this.onHandleChange}
                  onPressEnter={this.onSubmit}
                  type="password"
                  className="auth-input"
                  placeholder="Enter Password"
                  value={password}
                />
                <Button
                  className="auth-primary-button"
                  type="primary"
                  disabled={isDisabled}
                  loading={loading}
                  onClick={e => this.onSubmit(e)}
                >
                  Login
                </Button>
                <Button className="auth-secondary-button" onClick={() => super.changeState('forgotPassword')}>
                  <span>Forgot Password</span>
                </Button>
              </div>
              <div className="privacy-and-terms">
                <p>
                  By clicking login, you agree to our&nbsp;
                  <a href={config.termsOfUseLink} target="_blank" rel="noopener noreferrer">
                    Terms of Use
                  </a>
                  &nbsp; and that you have read our&nbsp;
                  <a href={config.privacyLink} target="_blank" rel="noopener noreferrer">
                    Data Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          )}
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export const LoginFormWithStore = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default withRouter(LoginFormWithStore);
