import { apiV3Client } from './BaseRepository';

export function fetchJobStatus() {
  return apiV3Client.get('jobstatuses');
}

export function fetchJobCountByStatus(filter = {}) {
  return apiV3Client.post('jobstatuses/_stats', {
    UserId: filter.userId,
    StatusId: filter.statusId,
    Status: filter.status,
    ClientId: filter.clientId,
    SearchKeyword: filter.searchTerm,
    ConnectStatus: filter.connectStatus,
    IsAssigned: filter.isAssigned,
    TagIds: filter.tagIds,
  });
}
