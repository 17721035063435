import React from 'react';
import CreditCardForm from '../Payment/CreditCardForm';
import '../Payment/PaymentInfo.scss';

export default function PaymentInformation(props) {
  const { form, hideHeader } = props;
  return (
    <div className="payment-information-container">
      {hideHeader ? null : <div className="payment-information-header-text">Payment Information</div>}
      <div className="payment-creditcard-form">
        <CreditCardForm form={form} isCardSaveDisabled />
      </div>
    </div>
  );
}
