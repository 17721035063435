import React from 'react';
import { Spin } from 'antd';
import styles from './SpinLoader.module.scss';

function SpinLoader(props) {
  const { text } = props;
  return (
    <div className={styles.spinLoaderContainer}>
      <Spin className={styles.spinLoader} size="small" />
      <span className={styles.spinLoaderText}>{text}</span>
    </div>
  );
}

export default SpinLoader;
