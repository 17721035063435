import React from 'react';
import ResumeLastUpdated from './ResumeLastUpdated/ResumeLastUpdated';
import styles from './ResumeLastUpdatedSection.module.scss';

export default function ResumeLastUpdatedSection(props) {
  const { form, defaultFormValues } = props;
  const { ResumeUpdateDurationType, ResumeUpdateDurationQuantity } = defaultFormValues;
  return (
    <div className={styles.resumeSection}>
      <ResumeLastUpdated
        form={form}
        defaultFormValues={defaultFormValues}
        resumeUpdateDurationType={ResumeUpdateDurationType}
        resumeUpdateDurationQuantity={ResumeUpdateDurationQuantity}
      />
    </div>
  );
}
