import React from 'react';
import { Button } from 'antd';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import styles from './FindMatchingJobWidgetWrapper.module.scss';

function FindMatchingJobWidgetWrapper(props) {
  const {
    candidate,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    fetchCandidateMatchingJobs,
    onClickFindMatchingJob,
    setCandidateJobMatchingInitialAggregation,
    isAlertMessage,
    setIsFindMathingJobClicked,
  } = props;

  const showModal = async () => {
    onClickFindMatchingJob('jobs');
    setCandidateMatchingJobModalVisiblity(true);
    setIsFindMathingJobClicked(true);
    const response = await fetchCandidateMatchingJobs({
      candidateId: candidate.Id,
      from: 0,
      size: 10,
      searchTerm: '',
      candidateTitle: candidate.Title,
      skills: candidate.Skills?.slice(0, 200),
      countryCodes: candidate.Country,
      location: [],
      candidateExperience: null,
      industries: [],
      exactTitleMatch: false,
      titles: [],
    });
    setCandidateJobMatchingInitialAggregation(response.Aggregations);
  };
  return (
    <div className={styles.findMatchingJobWidgetWrapper}>
      <Button
        shape="round"
        onClick={showModal}
        disabled={isCandidateMatchingJobModalVisible || isAlertMessage}
        className={styles.findMatchingJobBtn}
        sk-event-name={getEventNameByCandidateStatus(
          eventTypes.candidate.candidate360Profile.findMatchingJobs,
          candidate
        )}
      >
        Find Matching Jobs
      </Button>
    </div>
  );
}

export default FindMatchingJobWidgetWrapper;
