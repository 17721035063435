import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import './Tickbox.scss';

class Tickbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onChange, className, checked } = this.props;
    onChange(className, !checked);
  }

  render() {
    const { className, checked, children } = this.props;
    const cssButtonClass = checked ? 'tickbox-tick' : 'tickbox';
    const cssIconClass = checked ? 'check-circle-tick' : 'check-circle';
    return (
      <Button onClick={this.handleClick} className={`${className} ${cssButtonClass} `}>
        {children}
        <Icon type="check-circle" className={cssIconClass} theme="filled" twoToneColor="blue" />
      </Button>
    );
  }
}

Tickbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

Tickbox.defaultProps = {
  className: '',
  onChange: () => {},
};

export default Tickbox;
