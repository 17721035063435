import React from 'react';
import store from '../../store';
import { getJobLimitExceededAlertMessage } from '../../Utils/JobUtils';
import { getConfig } from '../../Reducers/ConfigReducer';

const FeatureLocked = 'Feature Locked!';
const AccessRestricted = 'Access Restricted';

const appFeatureNotificationContentMapper = ({ type }) => {
  let content;
  let header;
  const currentState = store.getState();
  if (type === 'JOB_LIMIT_EXCEEDED') {
    const userConfig = getConfig(currentState);
    header = FeatureLocked;
    content = getJobLimitExceededAlertMessage(userConfig.JobLimit);
  } else if (type === 'ACCESS_RESTRICTED') {
    header = AccessRestricted;
    content = (
      <span>
        This feature shall be disabled if you intend to source candidates whose personal data is not governed by the
        General Data Protection Regulation (GDPR). More information on data privacy and GDPR is available at{' '}
        <a href="https://leoforce.com/data-privacy-policy/" target="_blank" rel="noreferrer">
          https://leoforce.com/data-privacy-policy/
        </a>
        . Please send an email to <a href="mailto:support@leoforce.com">support@leoforce.com</a> for further help
      </span>
    );
  }
  return { content, header };
};

export { appFeatureNotificationContentMapper };
