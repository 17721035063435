import { setApiStatus } from '../ApiStatusActions';

const SET_DRIP_TEMPLATE_DETAILS = 'SET_DRIP_TEMPLATE_DETAILS';

function setDripTemplateFetchApiStatus(status) {
  const apiName = 'fetchDripTemplateApiStatus';
  return setApiStatus({ apiName, status });
}

function setDripTemplateDetails({ templateId, template }) {
  return dispatch => {
    dispatch({
      type: SET_DRIP_TEMPLATE_DETAILS,
      payload: {
        templateId,
        template,
      },
    });
  };
}

export { setDripTemplateDetails, setDripTemplateFetchApiStatus };
