import _ from 'lodash';

function PortalReducer(state = {}, action) {
  let newState;

  switch (action.type) {
    case 'SET_MONSTER_CLASSIC_COMPANY_CATAGORIES':
      newState = _.cloneDeep(state);
      _.set(newState, 'MonsterClassic.CompanyCatagories', _.get(action, ['payload', 'JobCatagories'], []));
      return newState;
    default:
      return state;
  }
}

function getMonsterClassicCompanyCatagories(state) {
  return _.get(state, ['PortalReducer', 'MonsterClassic', 'CompanyCatagories'], []);
}

export { PortalReducer, getMonsterClassicCompanyCatagories };
