/* eslint-disable no-unused-vars */
import React from 'react';
import { ALT_TEXT, LOGO_WIDTH, LOGO_HEIGHT, FOOTER_TEXT } from '../../Utils/PlanSelectionUtils';
import styles from './PlanSelectionFooter.module.scss';

function PlanSelectionFooter() {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.footerText}>{FOOTER_TEXT}</div>
      <div className={styles.logosWrapper}>
        <img
          src={`${process.env.PUBLIC_URL}/static/Images/Bashyam-Shah-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/Images/Droplette256-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />

        <img
          src={`${process.env.PUBLIC_URL}/static/Images/HRC-Logo.svg`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />

        <img
          src={`${process.env.PUBLIC_URL}/static/Images/Lone-Peak-Dental-Group-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />

        <img
          src={`${process.env.PUBLIC_URL}/static/Images/NextMed-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />

        <img
          src={`${process.env.PUBLIC_URL}/static/Images/Triumph-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />

        <img
          src={`${process.env.PUBLIC_URL}/static/Images/Vortex-systems-Logo.png`}
          alt={ALT_TEXT}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />
      </div>
    </div>
  );
}

export default PlanSelectionFooter;
