import { apiV1Client } from './BaseRepository';

export function fetchWorkflowReport(data) {
  const { id, aggregationIds } = data;
  if (Array.isArray(aggregationIds) && aggregationIds.length > 0) {
    return apiV1Client.get(`workflow-reports?id=${id}&aggregationids=${aggregationIds.join()}`);
  }
  return apiV1Client.get(`workflow-reports?id=${id}`);
}

export function fetchWorkflowReportFilter(workflowDefinitionId) {
  return apiV1Client.get(`workflow-reports/filters?definitionId=${workflowDefinitionId}`);
}
