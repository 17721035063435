import React, { useEffect } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import * as JobActions from '../../Actions/JobActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import AdvanceJobIcon from '../../Icons/AdvanceJobIcon';
import { getSampleCandidatesDisplayFlag, getNonDraftJobCount } from '../../Reducers/JobStatusReducer';
import { getProductVariantsById, getPlanDetailsByProductVariantId } from '../../Reducers/AryaPayOrderReducer';
import { CREATE_JOB } from '../../PubSub/EventTypes';
import { LINK_TO_ADVANCED_JOB_CREATION } from '../../Utils/LinkUtils/LinkUtils';
import { getIsFreeCandidateStepper } from '../../Utils/JobUtils';
import PlanCredits from '../Payment/PlanCredits';
import FirstJobFreeSelectJobCreation from './FirstJobFreeSelectJobCreation';
import JobCreationFlowStepper from '../Utils/JobCreationFlowStepper/JobCreationFlowStepper';
import { getTotalPaidCredits, getFreeJobCreditsCount, getTotalCredits } from '../../Utils/CreditInfoUtils';
import styles from './SelectJobCreation.module.scss';

const mapDispatchToProps = {
  setAdvancedJobFormData: JobActions.setAdvancedJobFormData,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
};

const mapStateToProps = state => ({
  productVariantsById: getProductVariantsById(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  nonDraftJobCount: getNonDraftJobCount(state),
});

function SelectJobCreation({
  setAdvancedJobFormData,
  planDetailsByProductVariantId,
  productVariantsById,
  fetchPlanDetailsByProductVariantId,
  isStepperRequired,
  showSampleCandidates,
  nonDraftJobCount,
}) {
  useEffect(() => {
    fetchPlanDetailsByProductVariantId();
  }, []);
  const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
  const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
  const availableCredits = getTotalCredits(planDetailsByProductVariantId, productVariantsById);
  const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
  return (
    <div className={`${isFirstJobFree ? styles.firstJobWrapper : styles.jobCreationWrapper}`}>
      {isFirstJobFree ? (
        <FirstJobFreeSelectJobCreation
          setAdvancedJobFormData={setAdvancedJobFormData}
          availableCredits={availableCredits}
          jobCount={nonDraftJobCount}
        />
      ) : (
        <>
          <PlanCredits
            planDetailsByProductVariantId={planDetailsByProductVariantId}
            productVariantsById={productVariantsById}
            isCreateJobPage
          />
          <span className={styles.title}>Lets talk about your job and know it better!</span>
          <div className={styles.icon}>
            <AdvanceJobIcon className={styles.iconSize} />
          </div>
          <Link to={LINK_TO_ADVANCED_JOB_CREATION}>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                PubSub.publish(CREATE_JOB, {
                  isAdvanced: true,
                });
                setAdvancedJobFormData({});
              }}
            >
              Create Job
            </Button>
          </Link>
          {isStepperRequired ? <JobCreationFlowStepper /> : null}
          <div className={styles.content}>
            <span>Tip: Higher job candidate engagement begins with smart job creation</span>
            <span>
              Need our help? Arya Pulse will assist you to create a job backed by data and market intelligence that
              would help to source the best candidates for your job.
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectJobCreation);

export { SelectJobCreation as SelectJobCreationWithoutStore };
