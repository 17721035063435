import React from 'react';
import styles from './EmptyReportList.module.scss';
import { ReportStatsIcon } from '../../../../../../../Icons/AryaIcons';

export default function EmptyReportList(props) {
  const { title, subtitle } = props;
  return (
    <div className={styles.emptyReportList}>
      <ReportStatsIcon className={styles.emptyReportListIcon} />
      <div className={styles.emptyReportListTitle}>{title}</div>
      <div className={styles.emptyReportListSubtitle}>{subtitle}</div>
    </div>
  );
}
