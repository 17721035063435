export const SET_CREATE_CLIENT_API_STATUS = 'SET_CREATE_CLIENT_API_STATUS';
export const SET_CURRENT_CLIENT_CREATION_INFO = 'SET_CURRENT_CLIENT_CREATION_INFO';
export const CLEAR_CURRENT_CLIENT_CREATION_INFO = 'CLEAR_CURRENT_CLIENT_CREATION_INFO';
export const setCreateClientApiStatus = status => ({
  type: SET_CREATE_CLIENT_API_STATUS,
  payload: status,
});

export const setCurrentClientCreationInfo = payload => ({
  type: SET_CURRENT_CLIENT_CREATION_INFO,
  payload,
});
export const clearCurrentClientCreationInfo = () => ({
  type: CLEAR_CURRENT_CLIENT_CREATION_INFO,
});
