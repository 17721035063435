import React from 'react';
import CompanyIcon from '../../Icons/CompanyIcon';
import SkillsIcon from '../../Icons/SkillsIcon';
import RolesIcon from '../../Icons/RolesIcon';
import LocationIcon from '../../Icons/LocationIcon';
import styles from './TryNowCandidateSupplyBanner.module.scss';

export function BannerItem({ children, SVGIcon }) {
  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <SVGIcon className={styles.iconSize} />
      </div>
      <div className={styles.text}>{children}</div>
    </div>
  );
}

export default function TryNowCandidateSupplyBanner({ bannerClass = '' }) {
  return (
    <div className={`${bannerClass} ${styles.candidateSupplyBanner}`}>
      <BannerItem SVGIcon={CompanyIcon}>
        Arya knows <span>who hires the candidates</span> that you are looking for
      </BannerItem>
      <BannerItem SVGIcon={SkillsIcon}>
        See <span>relevant, related positions</span> that you may have overlooked
      </BannerItem>
      <BannerItem SVGIcon={RolesIcon}>
        Instantly understand which <span>skills are most important</span> in an ideal candidate
      </BannerItem>
      <BannerItem SVGIcon={LocationIcon}>
        View where top candidates are <span>located around</span> the country
      </BannerItem>
    </div>
  );
}
