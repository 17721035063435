import uuid from 'uuid/v4';
import moment from 'moment';
import { getS3Object } from '../../Utils/AwsUtils';
import { getIdentityCredentials } from '../../Reducers/CognitoReducer';
import { getCurrentUserOrgGuid } from '../../Reducers/UserSessionReducer';
import { setIdentityCredentials } from '../../Actions/CognitoActions';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';
import config from '../../Config/Config';
import store from '../../store';

const handleUploadImage = async (file, done) => {
  try {
    const currentStore = store.getState();
    const identityCredentials = getIdentityCredentials(currentStore);
    const currentUserOrgGuid = getCurrentUserOrgGuid(currentStore);
    done({ progress: 10 });
    let credentials = identityCredentials;
    const diffSeconds = moment.utc(credentials.Expiration).diff(moment.utc(), 'seconds');
    if (diffSeconds < 300) {
      credentials = await store.dispatch(setIdentityCredentials());
    }
    const s3Object = getS3Object(credentials);
    const fileAttachment = file.attachments[0];
    const fileData = await s3Object
      .upload({
        Key: `${currentUserOrgGuid}/${uuid.v4()}-${fileAttachment.name}`,
        Bucket: config.ReactEmailEditor.Bucket,
        Body: fileAttachment,
        ContentType: fileAttachment.type,
      })
      .promise();

    done({ progress: 100, url: fileData.Location });
  } catch (error) {
    done({ progress: 0 });
    store.dispatch(setNotification('ERROR', 'Something went wrong while uploading image'));
  }
};

export { handleUploadImage };
