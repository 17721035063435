import React from 'react';
import { Checkbox, Icon, Tooltip } from 'antd';
import styles from './AllNotificationsActivator.module.scss';

export function getCheckboxTooltipTitle(isActivated) {
  if (isActivated) return 'Deactivate all settings';
  return 'Activate all settings';
}

export default function AllNotificationsActivator(props) {
  const { checked, loading, onChange, disabled } = props;
  const onActivateAll = e => {
    const value = e.target.checked;
    onChange(value);
  };
  const checkbox = <Checkbox checked={checked} onChange={onActivateAll} disabled={loading || disabled} />;
  const checkboxWithTooltip = (
    <Tooltip title={getCheckboxTooltipTitle(checked)}>
      <Checkbox checked={checked} onChange={onActivateAll} disabled={loading || disabled} />
    </Tooltip>
  );
  return (
    <div className={styles.content}>
      {disabled ? checkbox : checkboxWithTooltip}
      Activate All
      {loading ? <Icon type="loading" /> : null}
    </div>
  );
}
