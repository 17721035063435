import React from 'react';
import { withRouter } from 'react-router-dom';
import BullhornConnectRedirectUrl from './BullhornConnectRedirectUrl';

function ConnectAtsRedirectUrlMapper(props) {
  const { history, location, match } = props;
  const { ats } = match.params;

  switch (ats.toLowerCase()) {
    case 'bullhorn':
      return <BullhornConnectRedirectUrl history={history} location={location} />;
    default:
  }
}

export default withRouter(ConnectAtsRedirectUrlMapper);
export { ConnectAtsRedirectUrlMapper as ConnectAtsRedirectUrlMapperWithoutRouter };
