import React from 'react';
import { FormSelectComponent, FormRadioGroupComponent, FormInputComponent } from '../../../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../../../Utils/MapperUtils';
import ClearanceJobsOptions from '../../../../Data/AdvancedOptions/ClearanceJobs/ClearanceJobsOptions.json';
import ClearanceJobsInputs from '../../../../Data/AdvancedOptions/ClearanceJobs/ClearanceJobsInputs.json';
import '../AdvancedOptions.scss';

const {
  SortInfoOptions,
  WillingToRelocateOptions,
  IndustryOptions,
  LocationUsOptions,
  StateListOptions,
  InternationalLocationOptions,
  CareerLevelOptions,
  SalaryOptions,
  JobTypeOptions,
  ResumeFlagOptions,
  PolygraphOptions,
  ClearanceOptions,
} = ClearanceJobsOptions;

const {
  CandidateFirstNameParams,
  CandidateLastNameParams,
  SortInfoParams,
  IndustryParams,
  RelocationParams,
  StateLocationParams,
  LocationUsParams,
  InternationalLocationParams,
  CareerLevelParams,
  SalaryParams,
  JobTypeParams,
  ResumeFlagParams,
  PolygraphParams,
  SecurityClearanceParams,
} = ClearanceJobsInputs;

export default function ClearanceJobsAdvancedOptions(props) {
  const { form, defaultSettings } = props;
  const {
    Firstname,
    Lastname,
    SortInfo,
    Industries,
    StateList,
    LocationsUs,
    LocationsInternational,
    CareerLevel,
    WillingToRelocate,
    Salary,
    JobType,
    ResumeFlags,
    Polygraph,
    Clearance,
  } = defaultSettings;
  return (
    <div className="quicksearch-advanced-options clearancejobs-advanced-options">
      <FormInputComponent {...CandidateFirstNameParams} form={form} initialValue={Firstname} />
      <FormInputComponent {...CandidateLastNameParams} form={form} initialValue={Lastname} />
      <FormRadioGroupComponent
        {...SortInfoParams}
        form={form}
        initialValue={SortInfo || 'timestamp desc'}
        inputValues={formValuesMapper(SortInfoOptions)}
      />
      <FormSelectComponent
        {...IndustryParams}
        form={form}
        initialValue={Industries}
        inputValues={formValuesMapper(IndustryOptions)}
      />
      <FormRadioGroupComponent
        {...RelocationParams}
        form={form}
        initialValue={WillingToRelocate || 'not_selected'}
        inputValues={formValuesMapper(WillingToRelocateOptions)}
      />
      <FormSelectComponent
        {...StateLocationParams}
        form={form}
        initialValue={StateList}
        inputValues={formValuesMapper(StateListOptions)}
      />
      <FormSelectComponent
        {...LocationUsParams}
        form={form}
        initialValue={LocationsUs}
        inputValues={formValuesMapper(LocationUsOptions)}
      />
      <FormSelectComponent
        {...InternationalLocationParams}
        form={form}
        initialValue={LocationsInternational}
        inputValues={formValuesMapper(InternationalLocationOptions)}
      />
      <FormSelectComponent
        {...CareerLevelParams}
        form={form}
        initialValue={CareerLevel}
        inputValues={formValuesMapper(CareerLevelOptions)}
      />
      <FormSelectComponent
        {...SalaryParams}
        form={form}
        initialValue={Salary}
        inputValues={formValuesMapper(SalaryOptions)}
      />
      <FormSelectComponent
        {...JobTypeParams}
        form={form}
        initialValue={JobType}
        inputValues={formValuesMapper(JobTypeOptions)}
      />
      <FormSelectComponent
        {...ResumeFlagParams}
        form={form}
        initialValue={ResumeFlags}
        inputValues={formValuesMapper(ResumeFlagOptions)}
      />
      <FormSelectComponent
        {...PolygraphParams}
        form={form}
        initialValue={Polygraph}
        inputValues={formValuesMapper(PolygraphOptions)}
      />
      <FormSelectComponent
        {...SecurityClearanceParams}
        form={form}
        initialValue={Clearance}
        inputValues={formValuesMapper(ClearanceOptions)}
      />
    </div>
  );
}
