import React from 'react';
import { Icon } from 'antd';

export const CompanySvg = props => (
  <svg width={44} height={44} {...props}>
    <defs>
      <path id="a" d="M0 20h20V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#979797" strokeWidth={2} cx={22} cy={22} r={21} />
      <g transform="translate(12 12)">
        <path
          d="M18 18h-5v-3a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v3H2V2h16v16zm-9 0h2v-2H9v2zM19 0H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z"
          fill="#757575"
        />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#757575"
          mask="url(#b)"
          d="M7 7h2V5H7zm4 0h2V5h-2zm4 0h2V5h-2zm-8 4h2V9H7zM3 7h2V5H3zm0 4h2V9H3zm8 0h2V9h-2zm4 0h2V9h-2z"
        />
      </g>
    </g>
  </svg>
);

const CompanyIcon = props => <Icon component={CompanySvg} {...props} />;

export default CompanyIcon;
