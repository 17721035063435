import * as React from 'react';
import { Icon } from 'antd';

const AryaSendSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" {...props}>
    <path
      fill="#13C26B"
      d="M25.288 12.615.78.328a.468.468 0 0 0-.665.53L2.64 11.176a.463.463 0 0 0 .305.331l4.327 1.486-4.324 1.485a.459.459 0 0 0-.302.331L.116 25.142a.47.47 0 0 0 .665.53l24.507-12.216a.484.484 0 0 0 .21-.208.475.475 0 0 0-.21-.633ZM3.004 22.206l1.474-6.023 8.649-2.968a.235.235 0 0 0 0-.445L4.478 9.805l-1.468-6 18.399 9.226-18.405 9.175Z"
    />
  </svg>
);
const AryaSendIconV2 = props => <Icon component={AryaSendSvg} {...props} />;

export default AryaSendIconV2;
