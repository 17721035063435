import _ from 'lodash';
import * as ActionCreatorsType from '../Actions/ActionCreators/AryaPayOrderActionCreator';

const emptyList = [];
const emptyObject = {};

export function AryaPayOrderReducer(state = {}, action) {
  let productVariants = [];
  let productVariantsById = {};
  let orderItems = [];
  let orderItemById = {};
  switch (action.type) {
    case ActionCreatorsType.SET_PRODUCT_VARIANTS:
      if (action.payload.ProductVariants) {
        productVariants = action.payload.ProductVariants.map(product => ({ [product.Id]: product }));
        productVariantsById = Object.assign({}, ...productVariants);
        return { ...state, productVariants: action.payload.ProductVariants, productVariantsById };
      }
      return state;
    case ActionCreatorsType.SET_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID: {
      const newState = _.cloneDeep(state);
      const allPlanDetails = _.get(action, ['payload', 'Items'], []);
      const currentPlanDetailsByProductVariantId = newState.planDetailsByProductVariantId || {};
      let planDetailsByProductVariantId = allPlanDetails
        .filter(plan => !!plan.ProductVariants)
        .map(plan => ({ [plan.ProductVariants]: plan }));
      planDetailsByProductVariantId = Object.assign({}, ...planDetailsByProductVariantId);
      newState.planDetailsByProductVariantId = {
        ...currentPlanDetailsByProductVariantId,
        ...planDetailsByProductVariantId,
      };

      return newState;
    }
    case ActionCreatorsType.SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT:
      return { ...state, amountForProductVariant: action.payload };
    case ActionCreatorsType.SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_OUT_TAX:
      orderItems = action.payload?.Items.map(item => ({ [item.ProductVariantId]: item }));
      orderItemById = orderItems ? Object.assign({}, ...orderItems) : orderItems;
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          orderDetailsWithouttax: { orderItemById, TotalAmount: action.payload?.Amount },
        },
      };
    case ActionCreatorsType.SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_TAX:
      orderItems = action.payload?.Items?.map(item => ({ [item.ProductVariantId]: item }));
      orderItemById = orderItems ? Object.assign({}, ...orderItems) : orderItems;
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          orderDetailsWithtax: { orderItemById, TotalAmount: action.payload?.Amount },
        },
      };
    default:
      return state;
  }
}

function getProductVariants(state) {
  return _.get(state, ['AryaPayOrderReducer', 'productVariants'], emptyList);
}
function getPlanDetailsByProductVariantId(state) {
  return _.get(state, ['AryaPayOrderReducer', 'planDetailsByProductVariantId'], null);
}

function getTotalAmountForProductvariant(state) {
  return _.get(state, ['AryaPayOrderReducer', 'orderDetails', 'orderDetailsWithtax', 'TotalAmount'], emptyObject);
}

function getProductVariantsById(state) {
  return _.get(state, ['AryaPayOrderReducer', 'productVariantsById'], emptyObject);
}

function getOrderDetails(state) {
  return _.get(state, ['AryaPayOrderReducer', 'orderDetails', 'orderDetailsWithouttax'], emptyObject);
}
function getOrderDetailswithTax(state) {
  return _.get(state, ['AryaPayOrderReducer', 'orderDetails', 'orderDetailsWithtax'], emptyObject);
}
export {
  getOrderDetails,
  getProductVariants,
  getProductVariantsById,
  getPlanDetailsByProductVariantId,
  getTotalAmountForProductvariant,
  getOrderDetailswithTax,
};
