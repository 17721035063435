import React from 'react';
import { Form, Select, InputNumber, Radio, Switch } from 'antd';
import * as CareerbuilderUtils from '../../../../Utils/AdvancedOptionsUtils/CareerbuilderUtils';
import AdvanceSearchLocation from '../AdvanceSearchLocation/AdvanceSearchLocation';
import './CareerBuilderAdvancedOptions.scss';
import { getDefaultSwitchValue } from '../../QuickSearchFormUtils';

const { Item } = Form;
const { Option } = Select;

export default function CareerBuilderAdvancedOptions(props) {
  const {
    form,
    defaultSettings,
    countryCode,
    isCountryWideSearchEnabled,
    isAlternateLocationEnabled,
    isStateWideSearchEnabled,
  } = props;

  const getIsSortByValueDisabled = disabledValues => {
    const refineByFormValue = form.getFieldValue('careerbuilder-last-active') ? 'ActivityDate' : 'ModifiedDate';
    return disabledValues?.find(value => value === refineByFormValue);
  };

  const onRefineByChange = () => {
    form.setFieldsValue({ 'careerbuilder-order-by': 'Relevancy' });
  };

  const salaryTypeSelect = form.getFieldDecorator('careerbuilder-salary-type', {
    initialValue: defaultSettings.SalaryType ?? 'Yearly',
  })(
    <Select
      key="careerbuilder-salary-type"
      placeholder="Select type"
      dropdownClassName="popover-select"
      className="salary-type-select"
      getPopupContainer={trigger => trigger.parentNode}
    >
      <Option value="Hourly">Hourly</Option>
      <Option value="Yearly">Yearly</Option>
    </Select>
  );
  return (
    <div className="careerbuilder">
      <div className="careerbuilder-advanced-options">
        <Item label="Refine by" colon={false}>
          {form.getFieldDecorator('careerbuilder-last-active', {
            initialValue: defaultSettings.IsSearchWithLastActive
              ? defaultSettings.IsSearchWithLastActive !== 'false'
              : true,
          })(
            <Radio.Group key="careerbuilder-last-active" onChange={onRefineByChange}>
              {CareerbuilderUtils.SearchWithOptions.map(option => (
                <Radio value={option.value} key={option.value}>
                  {option.displayText}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Item>

        <Item label="Sort By" colon={false}>
          {form.getFieldDecorator('careerbuilder-order-by', {
            initialValue: defaultSettings?.OrderBy || 'Relevancy',
          })(
            <Select
              key="careerbuilder-order-by"
              placeholder="Select status"
              className="careerbuilder-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {CareerbuilderUtils.OrderByOptions.map(orderByOption => (
                <Option
                  key={orderByOption.value}
                  value={orderByOption.value}
                  title={orderByOption.displayText}
                  disabled={getIsSortByValueDisabled(orderByOption.disabledFor)}
                >
                  {orderByOption.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        <Item label="Categories/Industries" colon={false}>
          {form.getFieldDecorator('careerbuilder-category', {
            initialValue: defaultSettings.JobCategories ? defaultSettings.JobCategories.split(',') : undefined,
          })(
            <Select
              key="careerbuilder-category"
              mode="multiple"
              placeholder="Select categories"
              className="careerbuilder-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {CareerbuilderUtils.Categories.map((category, index) => (
                <Option key={index.toString()} value={category.value} title={category.displayText}>
                  {category.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Employment Types" colon={false}>
          {form.getFieldDecorator('careerbuilder-employment-type', {
            initialValue: defaultSettings.DesiredJobType?.split(','),
          })(
            <Select
              key="careerbuilder-employment-type"
              mode="multiple"
              placeholder="Select types"
              className="careerbuilder-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {CareerbuilderUtils.EmploymentTypes.map((type, index) => (
                <Option value={type.value} key={index.toString()} title={type.displayText}>
                  {type.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Minimum Level of Travel" colon={false}>
          {form.getFieldDecorator('careerbuilder-minimum-travel', {
            initialValue: defaultSettings.MinimumTravelRequirement,
          })(
            <Select
              key="careerbuilder-minimum-travel"
              placeholder="Select up to %"
              className="careerbuilder-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
              allowClear
            >
              {CareerbuilderUtils.TravelOptions.map((option, index) => (
                <Option key={index.toString()} value={option.value}>
                  {option.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Languages" colon={false}>
          {form.getFieldDecorator('careerbuilder-language', {
            initialValue: defaultSettings.LanguagesSpoken ? defaultSettings.LanguagesSpoken.split(',') : undefined,
          })(
            <Select
              key="careerbuilder-language"
              placeholder="Select Languages"
              className="careerbuilder-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              mode="multiple"
              optionFilterProp="title"
              showArrow
              allowClear
            >
              {CareerbuilderUtils.Languages.map((language, index) => (
                <Option key={index.toString()} value={language.value} title={language.displayText}>
                  {language.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Work Authorizations" colon={false}>
          {form.getFieldDecorator('careerbuilder-work-authorization', {
            initialValue: defaultSettings.WorkStatus ? defaultSettings.WorkStatus.split(',') : undefined,
          })(
            <Select
              key="careerbuilder-work-authorization"
              mode="multiple"
              placeholder="Select work authorizations"
              className="careerbuilder-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {CareerbuilderUtils.WorkAuthorizations.map((workAuthorization, index) => (
                <Option key={index.toString()} value={workAuthorization.value} title={workAuthorization.displayText}>
                  {workAuthorization.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Security Clearance" colon={false}>
          {form.getFieldDecorator('careerbuilder-security-clearance', {
            initialValue: defaultSettings.SecurityClearance,
          })(
            <Select
              key="careerbuilder-security-clearance"
              placeholder="Select status"
              className="careerbuilder-advance-options-select"
              dropdownClassName="popover-select"
              getPopupContainer={trigger => trigger.parentNode}
              showArrow
              allowClear
            >
              {CareerbuilderUtils.SecurityClearanceOptions.map((securityClearance, index) => (
                <Option key={index.toString()} value={securityClearance.value}>
                  {securityClearance.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Military Experience" colon={false}>
          {form.getFieldDecorator('careerbuilder-military-experience', {
            initialValue: defaultSettings.MilitaryExperience
              ? defaultSettings.MilitaryExperience.split(',')
              : undefined,
          })(
            <Select
              key="careerbuilder-military-experience"
              mode="multiple"
              placeholder="Select status"
              className="careerbuilder-advance-options-select"
              getPopupContainer={trigger => trigger.parentNode}
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {CareerbuilderUtils.MilitaryExperiences.map((experience, index) => (
                <Option key={index.toString()} value={experience.value} title={experience.displayText}>
                  {experience.displayText}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <div className="salary-form-item-label">
          Salary Range ($)
          <Item>{salaryTypeSelect}</Item>
        </div>
        <div className="salary-form-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Item>
            {form.getFieldDecorator('careerbuilder-min-salary', { initialValue: defaultSettings.MinSalary })(
              <InputNumber
                key="careerbuilder-min-salary"
                placeholder="Min Salary"
                className="careerbuilder-minimum-salary"
                min={0}
              />
            )}
          </Item>
          <Item>
            {form.getFieldDecorator('careerbuilder-max-salary', { initialValue: defaultSettings.MaxSalary })(
              <InputNumber
                key="careerbuilder-max-salary"
                placeholder="Max Salary"
                className="careerbuilder-maximum-salary"
                min={form.getFieldValue('careerbuilder-min-salary') || 0}
              />
            )}
          </Item>
        </div>
        {isAlternateLocationEnabled ? (
          <AdvanceSearchLocation
            defaultAlternateLocations={defaultSettings.AlternateLocations}
            form={form}
            jobBoardFormDecorator="careerbuilder"
            maxAlternateLocationsAllowed={2}
            countryCode={countryCode}
            isCountryWideSearchEnabled={isCountryWideSearchEnabled}
            isStateWideSearchEnabled={isStateWideSearchEnabled}
          />
        ) : null}
        <Item label="Search with most recent job title" colon={false} className="switch-form-item">
          {form.getFieldDecorator('careerbuilder-search-recent-job-title', {
            initialValue: getDefaultSwitchValue(defaultSettings.IsSearchWithMostRecentJobTitle, false),
            valuePropName: 'checked',
          })(<Switch key="careerbuilder-search-recent-job-title" />)}
        </Item>
        <Item label="Search with most recent company" colon={false} className="switch-form-item">
          {form.getFieldDecorator('careerbuilder-search-recent-company', {
            initialValue: getDefaultSwitchValue(defaultSettings.IsSearchWithMostRecentCompany, false),
            valuePropName: 'checked',
          })(<Switch key="careerbuilder-search-recent-company" />)}
        </Item>

        <Item label="Currently Employed" colon={false}>
          {form.getFieldDecorator('careerbuilder-currently-employed', {
            initialValue: defaultSettings.CurrentlyEmployed,
          })(
            <Radio.Group key="careerbuilder-currently-employed">
              {CareerbuilderUtils.CurrentlyEmployedOptions.map((option, index) => (
                <Radio value={option.value} key={index.toString()}>
                  {option.displayText}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Item>

        <Item label="Management Experience Required" colon={false}>
          {form.getFieldDecorator('careerbuilder-management-experience', {
            initialValue: defaultSettings.ManagementExperience || 'Either',
          })(
            <Radio.Group key="careerbuilder-management-experience">
              {CareerbuilderUtils.ManagementExperiences.map((experience, index) => (
                <Radio value={experience.value} key={index.toString()}>
                  {experience.displayText}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Item>

        <Item label="Minimum no. of employees managed" colon={false}>
          {form.getFieldDecorator('careerbuilder-minimum-employees', {
            initialValue: defaultSettings.MinimumEmployeesManaged,
          })(
            <InputNumber
              key="careerbuilder-minimum-employees"
              placeholder="Add No."
              className="careerbuilder-advance-options-inputnumber"
              min={0}
            />
          )}
        </Item>
      </div>
    </div>
  );
}
