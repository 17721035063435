import React from 'react';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { CandidatesFindingIcon } from '../../Icons/AryaIcons';
import { getMarketIntelLink } from '../../Utils/LinkUtils/LinkUtils';
import styles from './FindingCandidates.module.scss';

function FindingCandidates({ isFreeTrialPlan, jobId }) {
  const marketIntelLink = getMarketIntelLink(jobId);
  return (
    <>
      {isFreeTrialPlan ? (
        <div className={styles.freeTrialFindingCandidates}>
          <p className={styles.candidateListFreeTrialPlanNoCandidatesFound}>
            Stay with us while we get your top candidates in up to 30 minutes!
            <br />
            Keep a lookout for our email notification when your candidate list is available.
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/findingcandidate.gif`}
            alt="finding candidate gif"
            className={styles.candidateListFreeTrialFindingCandidateGif}
          />
          <div className={styles.candidateListFreeTrialPlanNoCandidatesFoundMessage}>
            As you hang around, be sure to make use of our reliable{' '}
            <Link to={marketIntelLink}>
              <span className={styles.candidateListFreeTrialText}>Market Intelligence</span>{' '}
            </Link>
            to empower your candidate search.
          </div>
        </div>
      ) : (
        <Empty
          imageStyle={{ marginTop: '80px', height: '266px' }}
          image={<CandidatesFindingIcon />}
          description={
            <>
              <p className={styles.candidateListNoCandidatesFound}>Arya is working on some great candidates for you!</p>
              <p className={styles.candidateListNoCandidatesFoundMessage}>Candidates to review will appear here.</p>
            </>
          }
        />
      )}
    </>
  );
}

export default FindingCandidates;
