import { defineMessages } from 'react-intl';

const messages = defineMessages({
  minimumOf: {
    id: 'Job.minimumof',
    defaultMessage: 'Minimum of {minExperience} years',
    values: {
      minExperience: -1,
    },
  },
  maximumOf: {
    id: 'Job.maximumof',
    defaultMessage: 'Maximum of {maxExperience} years',
    values: {
      maxExperience: -1,
    },
  },
  na: {
    id: 'Job.na',
    defaultMessage: 'NA',
  },
  minimumToMaximum: {
    id: 'Job.minimumtomaximum',
    defaultMessage: '{minExperience} to {maxExperience} years',
    values: {
      minExperience: -1,
      maxExperience: -1,
    },
  },
});

export default messages;
