import React from 'react';
import { connect } from 'react-redux';
import LocationContext from './LocationContext';
import * as LocationSearchActions from '../../Actions/LocationSearchActions';
import useLocation from '../../Hooks/useLocation';
import { getOptionValueFromValue, parseLocation } from '../../Utils/LocationUtils';

const mapDispatchToProps = {
  fetchLocations: LocationSearchActions._fetchLocations,
  fetchStateSuggestions: LocationSearchActions.fetchStateSuggestions,
  fetchZipCodes: LocationSearchActions.fetchZipCodes,
  searchZipCode: LocationSearchActions.searchZipCode,
  fetchCityAndStateSuggestions: LocationSearchActions._fetchCityAndStateSuggestions,
};

function LocationWrapper(props) {
  const {
    children,
    form,
    countryOptions,
    fetchLocations,
    fetchZipCodes,
    searchZipCode,
    callbacks = {},
    fetchCityAndStateSuggestions,
    layers,
  } = props;
  const { selectCountryCallback, countryChangeCallback } = callbacks;

  const [locationInput, setLocationInput] = React.useState('');

  const givenZipcode = form.getFieldValue('Zipcode');
  const givenCountryCode = form.getFieldValue('CountryCode');
  const isCountrySelected = !!givenCountryCode;
  const {
    clearZipCodeError,
    clearZipValidationError,
    isZipCodeInValid,
    locationApiStatus,
    locationSuggestions,
    manualLocation,
    searchLocation,
    searchLocationUsingZipcode,
    searchZipCodes,
    zipCodeSuggestions,
  } = useLocation({
    fetchLocations,
    fetchCityAndStateSuggestions,
    fetchZipCodes,
    givenCountryCode,
    searchZipCode,
    layers,
  });
  let locationDropDownStatus;
  if (locationInput.length) {
    locationDropDownStatus = locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
  } else {
    locationDropDownStatus = 'Type to search';
  }

  React.useEffect(() => {
    if (!isZipCodeInValid && manualLocation) {
      const zipCityState = [];
      if (manualLocation.City) {
        zipCityState.push(manualLocation.City);
      }
      if (manualLocation.State) {
        zipCityState.push(manualLocation.State);
      }
      if (zipCityState.length) {
        form.setFieldsValue({
          Location: zipCityState.join(', '),
        });
      }
    }
  }, [manualLocation?.City, manualLocation?.State, isZipCodeInValid]);

  const handleStateWideSearchCheckboxClick = () => {
    form.setFieldsValue({ Zipcode: undefined, Location: undefined });
  };

  const onSearchLocation = async searchTerm => {
    if (!searchTerm) return;
    searchLocation(givenCountryCode, searchTerm, layers);
  };
  const handleLocationSelect = value => {
    form.setFieldsValue({
      Zipcode: undefined,
    });
    const parsedValue = JSON.parse(value);
    if (parsedValue.state && !parsedValue.city) {
      form.setFieldsValue({ IsStateWideSearch: true });
    } else if (parsedValue.city) {
      form.setFieldsValue({ IsStateWideSearch: false });
    }
    const isStateWideSearch = form.getFieldValue('IsStateWideSearch');
    if (isStateWideSearch) return;
    const _value = getOptionValueFromValue(parsedValue);
    searchZipCodes(_value);
  };
  const handleOnFocus = () => {
    const locationFieldValue = form.getFieldValue('Location');
    if (!zipCodeSuggestions.length && locationFieldValue) searchZipCodes(parseLocation(locationFieldValue));
  };
  const isStateWideSearchChecked = form.getFieldValue('IsStateWideSearch');
  return (
    <LocationContext.Provider
      value={{
        handleLocationSelect,
        form,
        countryOptions,
        locationSuggestions,
        onSearchLocation,
        fetchZipCodes,
        isCountrySelected,
        locationDropDownStatus,
        clearZipCodeError,
        isZipCodeInValid,
        searchZipCode,
        zipCodeSuggestions,
        givenCountryCode,
        givenZipcode,
        selectCountryCallback,
        countryChangeCallback,
        isStateWideSearchChecked,
        // isStateWideSearchDisabled,
        handleStateWideSearchCheckboxClick,
        setLocationInput,
        searchLocationUsingZipcode,
        clearZipValidationError,
        handleOnFocus,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export default connect(null, mapDispatchToProps)(LocationWrapper);
export { LocationWrapper as LocationWrapperWithoutStore };
