import React from 'react';
import { Modal, Button, Icon } from 'antd';
import styles from './ChatGptRestartConfirmationModal.module.scss';

export default function ChatGptRestartConfirmationModal(props) {
  const { visible, onCancel, onConfirm } = props;
  return (
    <div>
      <Modal
        title="Restart Alert"
        visible={visible}
        footer={[
          <Button shape="round" key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button shape="round" key="submit" type="primary" onClick={onConfirm}>
            Yes
          </Button>,
        ]}
        onCancel={onCancel}
        zIndex={3200}
        centered
      >
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="question-circle" theme="filled" />
          </div>
          <div>
            <div className={styles.restartConfirmationContent}>
              Restart means all previous inputs will be lost. Would you like to do continue?
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
