import React, { useEffect } from 'react';
import { Checkbox } from 'antd';
import styles from './JobDescriptionRecommendationModal.module.scss';

export default function ElementsListWithCheckbox(props) {
  const { heading, elementsList, onSelectElements, isBorderBottomRequired, defaultValues } = props;

  useEffect(() => {
    onSelectElements(defaultValues);
  }, []);

  const onChange = checkedValues => {
    onSelectElements(checkedValues);
  };

  if (!elementsList?.length) return null;

  const options = [];
  elementsList.forEach(element => options.push({ value: element, label: element }));

  return (
    <div>
      <div className={styles.headingText}>{heading} :</div>
      <div className={styles.optionsCheckboxWrapper}>
        <Checkbox.Group options={options} onChange={onChange} defaultValue={defaultValues} />
      </div>
      {isBorderBottomRequired && <div className={styles.partition} />}
    </div>
  );
}
