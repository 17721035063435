import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { ShareModalItemUtil } from './ShareModalItemUtil';

const styleScroll = { maxHeight: '40vh', overflowY: 'scroll', overflowX: 'hidden' };

export function ShareModalItemsUtil(props) {
  const {
    searchValue,
    handleLoadMore,
    userIds,
    maxCount,
    topSharedUserIds,
    ownerId,
    usersById,
    onChangeUserSelection,
    currentSharedUserIds,
    viewerEmail,
    isShareWithAllChecked,
    currentUnsharedUserIds,
  } = props;

  const sortUserIdsByName = (id1, id2) => {
    const firstName1 = _.get(usersById, [id1, 'FirstName'], '');
    const lastName1 = _.get(usersById, [id1, 'LastName'], '');
    const fullName1 = `${firstName1} ${lastName1}`;
    const firstName2 = _.get(usersById, [id2, 'FirstName'], '');
    const lastName2 = _.get(usersById, [id2, 'LastName'], '');
    const fullName2 = `${firstName2} ${lastName2}`;
    if (fullName1 < fullName2) {
      return -1;
    }
    if (fullName1 > fullName2) {
      return 1;
    }
    return 0;
  };

  const renderModalItem = (id, isDisabled, defaultChecked) => {
    const { Email } = usersById?.[id] || {};
    if (!Email || Email === viewerEmail) return null;
    return (
      <ShareModalItemUtil
        usersById={usersById}
        userIds={userIds}
        id={id}
        isDisabled={isDisabled}
        defaultChecked={defaultChecked}
        onChangeUserSelection={onChangeUserSelection}
        currentSharedUserIds={currentSharedUserIds}
        currentUnsharedUserIds={currentUnsharedUserIds}
        isShareWithAllChecked={isShareWithAllChecked}
        email={Email}
        key={id}
      />
    );
  };

  return (
    <div style={styleScroll}>
      <InfiniteScroll
        pageStart={1}
        key={searchValue || '1'}
        initialLoad={false}
        loadMore={handleLoadMore}
        hasMore={maxCount === -1 || userIds?.length < maxCount}
        useWindow={false}
        threshold={220}
        loader={<div key={0}>Loading...</div>}
      >
        {renderModalItem(ownerId, true, true)}
        {topSharedUserIds
          .filter(id => id !== ownerId)
          .sort((id1, id2) => sortUserIdsByName(id1, id2))
          .map(id => renderModalItem(id))}
        {userIds?.filter(id => ![...topSharedUserIds, ownerId].includes(id)).map(id => renderModalItem(id))}
      </InfiniteScroll>
    </div>
  );
}
