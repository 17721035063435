import React, { Component } from 'react';
import { Button, Icon, Modal, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import qs from 'query-string';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getOAuthIcon, getOAuthProviderDisplayName, generateUrl } from '../../Utils/SmtpUtils';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import styles from './OAuth.module.scss';

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});
const mapDispatchToProps = {
  deleteEmailConfig: ConnectSettingsActions.deleteEmailConfig,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
};

const closeIcon = <Icon type="close-circle" theme="filled" />;
const checkedIcon = <Icon type="check-circle" theme="filled" style={{ color: '#13c26b' }} />;

class OAuth extends Component {
  modalOptions = {
    title: 'Are you sure you want to disconnect?',
    content: `Once done you won't be able to send any email to candidates`,
    okButtonProps: { shape: 'round' },
    cancelButtonProps: { shape: 'round' },
    okText: 'Disconnect',
    onOk: () => {
      this.disintegrateOAuth();
    },
    onCancel: () => {},
  };

  state = {
    hover: false,
  };

  componentDidMount() {
    window.addEventListener('message', this.callback);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.callback);
  }

  callback = e => {
    const { isAdmin, fetchEmailConfig, setCurrentEmailConfigId } = this.props;
    const { search } = e.data;
    if (!_.isEmpty(search)) {
      const parsed = qs.parseUrl(search);
      const { status, error } = parsed;
      if (status === 'false') {
        message.error(error);
      } else {
        fetchEmailConfig({ isAdmin }, setCurrentEmailConfigId);
      }
    }
  };

  handleOAuthClick = () => {
    const { providerConfig, tab, isAdmin } = this.props;
    if (!providerConfig) {
      this.integrateOAuth();
    } else if (!(tab === 'application' && isAdmin)) {
      this.showDisintegrateModal();
    }
  };

  integrateOAuth = () => {
    const url = generateUrl(this.props);
    window.open(url, '_blank', 'width=800,height=600');
  };

  disintegrateOAuth = () => {
    const { providerConfig, isAdmin, deleteEmailConfig, resetProviders, toggleEmailSmtpPopUp } = this.props;
    deleteEmailConfig(providerConfig.Id, { isAdmin }, resetProviders);
    if (toggleEmailSmtpPopUp) toggleEmailSmtpPopUp();
  };

  showDisintegrateModal = () => {
    Modal.confirm(this.modalOptions);
  };

  handleHover = () => {
    const { providerConfig } = this.props;
    const { hover } = this.state;
    if (!providerConfig) return;
    this.setState({
      hover: !hover,
    });
  };

  OAuthButtonContent = () => {
    const { hover } = this.state;
    const { providerConfig, isAdmin, tab, provider } = this.props;
    if (!providerConfig) return `Sign in with ${getOAuthProviderDisplayName[provider]}`;
    let currentIcon = checkedIcon;
    if (!(tab === 'application' && isAdmin)) {
      currentIcon = hover ? closeIcon : checkedIcon;
    }
    const { Email } = providerConfig.Configuration;
    return (
      <span>
        {Email} {currentIcon}
      </span>
    );
  };

  render() {
    const { provider } = this.props;
    return (
      <div>
        <Button
          className={styles.oauthIntegrateBtn}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}
          shape="round"
          onClick={this.handleOAuthClick}
        >
          {getOAuthIcon(provider)}
          {this.OAuthButtonContent()}
        </Button>
      </div>
    );
  }
}

export { OAuth as OAuthWithoutStore };

export default connect(mapStateToProps, mapDispatchToProps)(OAuth);
