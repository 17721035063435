import { Modal, Button, Icon } from 'antd';
import React from 'react';
import styles from './ArchiveListModal.module.scss';

function ArchiveListModal(props) {
  const { isModalVisible, setIsArchiveModalVisible, listName } = props;
  const handleCancel = () => {
    setIsArchiveModalVisible(false);
  };
  const handleOk = () => {
    setIsArchiveModalVisible(false);
  };
  return (
    <div className={styles.modalWrapper}>
      <Modal
        title="Archive The List"
        className={styles.modal}
        visible={isModalVisible}
        centered
        onCancel={handleCancel}
        footer={[
          <Button shape="round" key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button shape="round" key="submit" type="primary" onClick={handleOk}>
            Yes
          </Button>,
        ]}
      >
        <div className={styles.modalContent}>
          <div className={styles.iconDiv}>
            <Icon type="exclamation-circle" />
          </div>
          <div>Are you sure to archive this item - &#8216;{listName}&#8217;?</div>
        </div>
      </Modal>
    </div>
  );
}

export default ArchiveListModal;
