import { Button, Carousel, Icon, Tag, Tooltip } from 'antd';
import React from 'react';
import { AdvertisementPortals } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import RecallJobDistribution from '../RecallJobDistribution/RecallJobDistribution';
import styles from './AdvertiseJobComponent.module.scss';
import { isExpiredDateTime } from '../../../Utils/RelativeTimeCalculator';
import eventTypes from '../../../Analytics/EventTypes';

export default function AdvertiseJobComponent(props) {
  const {
    onPublishPortal,
    portals,
    currentUser,
    distributedJobInfo,
    distributedPortals,
    recalledPortals,
    recallPortalJobDistribution,
    isPublishDisabled,
    aryaJobStatus,
  } = props;

  const [recallVisible, setRecallVisible] = React.useState(false);

  const isPublished = distributedPortals?.find(p => p.toLowerCase() === AdvertisementPortals[0]);
  const isRecalled =
    recalledPortals?.find(p => p.toLowerCase() === AdvertisementPortals[0]) ||
    isExpiredDateTime(distributedJobInfo?.ExpiryDateTime);
  const isPublishClosed = !isPublished && aryaJobStatus === 'Closed';

  const hasEditAccess =
    currentUser.Id === distributedJobInfo?.CreatedBy || currentUser.Id === distributedJobInfo?.UpdatedBy;

  const onRecallConfirm = () => {
    setRecallVisible(false);
    recallPortalJobDistribution();
  };

  const getAdvertiseComponent = () => {
    const advertiseButton = (
      <Button
        type="primary"
        shape="round"
        onClick={() => onPublishPortal(portals[0])}
        disabled={isPublishDisabled || isPublishClosed || isRecalled}
        sk-event-name={eventTypes.job.advertiseJob.goToAdvertiseJob}
      >
        Advertise Now
      </Button>
    );
    if (isRecalled)
      return (
        <Tooltip title="You can't advertise this job because it is either expired or has been closed">
          {advertiseButton}
        </Tooltip>
      );
    if (isPublishClosed) return <Tooltip title="You can not advertise the closed job">{advertiseButton}</Tooltip>;
    if (!isPublishDisabled) return advertiseButton;
    return <Tooltip title="Ad campaign support is for US location only at the moment.">{advertiseButton}</Tooltip>;
  };

  const inactiveTag = () => {
    return isRecalled ? (
      <Tag className={styles.activeTag} color="red">
        Inactive
      </Tag>
    ) : null;
  };

  return (
    <div className={styles.advertiseJobWrapper}>
      <RecallJobDistribution
        visible={recallVisible}
        onCancel={onRecallConfirm}
        onConfirm={() => setRecallVisible(false)}
        onClose={() => setRecallVisible(false)}
        portal={portals[0]}
        isCloseMarkNotRequired
      />
      <div className={styles.advertiseJobTitleWrapper}>
        <div>
          <Icon type="rise" theme="outlined" style={{ color: '#13c26b', marginRight: '10px' }} />
          {isPublished ? (
            <>
              Ad campaign in-progress{' '}
              <Tag className={styles.activeTag} color="green">
                Active
              </Tag>
            </>
          ) : (
            <>Advertise This Job {inactiveTag()}</>
          )}
        </div>
      </div>
      <div className={styles.advertiseDescriptionWrapper}>
        {isPublished ? (
          <div>
            This job is currently being promoted with Intelligent Job Advertising for improved targeting and increased
            ROI.
          </div>
        ) : (
          <Carousel autoplay autoplaySpeed={6000} dots={false}>
            <div>Launching an Intelligent Job Advertising campaign can help increase your ROI by up to 90%.</div>
            <div>Using Intelligent Job Advertising can reduce your cost-per-applicant by up to 30%.</div>
            <div>Posting this job will make it 10X more likely to get high quality candidates</div>
          </Carousel>
        )}
      </div>
      <div className={styles.advertiseActionsWrapper}>
        {isPublished ? (
          <>
            <Button
              className={styles.editBtn}
              type="primary"
              shape="round"
              disabled={!hasEditAccess}
              onClick={() => onPublishPortal(portals[0])}
              sk-event-name={eventTypes.job.advertiseJob.editAdvertiseJob}
            >
              Edit
            </Button>
            <Button
              disabled={!hasEditAccess}
              type="secondary"
              shape="round"
              onClick={() => setRecallVisible(true)}
              sk-event-name={eventTypes.job.advertiseJob.endAdvertiseJob}
            >
              End Campaign
            </Button>
          </>
        ) : (
          getAdvertiseComponent()
        )}
      </div>
    </div>
  );
}
