import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import styles from './Candidate360AtsNotes.module.scss';

function Candidate360AtsNotes(props) {
  const {
    onNotesChange,
    currentNotes,
    aryaCandidateId,
    getInternalSourceWithCandidateId,
    candidate,
    notesContainer,
    setCandidateViewHeaderVisibility,
    isCandidateViewHeaderVisible,
  } = props;

  const ref = React.useRef(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  const notesElement = React.createElement(
    notesContainer,
    {
      candidateId: _.get(getInternalSourceWithCandidateId(candidate.Sources), ['CandidateId']),
      onNotesChange,
      currentNotes,
      aryaCandidateId,
    },
    null
  );

  return (
    <div
      ref={ref}
      className={classNames(styles.candidate360AtsNotesWrapper, {
        [styles.extendedCandidate360AtsNotesView]: !isCandidateViewHeaderVisible,
      })}
    >
      {notesElement}
    </div>
  );
}

export default Candidate360AtsNotes;
