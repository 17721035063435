import React from 'react';
import { Icon } from 'antd';
import { CandidateBookmarkIcon } from '../../Icons/AryaIcons';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import styles from './CandidateBookmark.module.scss';

function CandidateBookmark(props) {
  const { candidate, updateCandidateBookmarkStatus, jobId, candidateBookmarkApiStatus, width, height } = props;
  const isFavourite = candidate?.IsFavourite;
  const bookmarkFillColor = isFavourite ? '#13C26B' : '#B4B7BA';
  const onBookmarkClick = e => {
    e.stopPropagation();
    updateCandidateBookmarkStatus({ jobId, candidateId: candidate.Id, isFavourite: !isFavourite });
  };

  return (
    <div role="presentation" onClick={onBookmarkClick} className={styles.candidateBookmark}>
      {candidateBookmarkApiStatus === 'INPROGRESS' ? (
        <Icon type="loading" />
      ) : (
        <CandidateBookmarkIcon
          fillColor={bookmarkFillColor}
          width={width}
          height={height}
          sk-event-name={getEventNameByCandidateStatus(
            eventTypes.candidate.candidate360Profile.updateCandidateBookmark,
            candidate
          )}
        />
      )}
    </div>
  );
}

export default CandidateBookmark;
