import React from 'react';
import { OrderCancelledIcon } from '../../Icons/AryaIcons';
import './OrderCancelled.scss';

function OrderCancelled() {
  return (
    <div className="order-cancelled-wrapper">
      <div className="order-cancelled-icon-wrapper">
        <OrderCancelledIcon className="order-cancelled-icon" />
      </div>
      <div className="order-cancelled-header">Order Cancelled</div>
      <div className="order-cancelled-message">No candidates will appear as the order has been cancelled.</div>
    </div>
  );
}

export default OrderCancelled;
