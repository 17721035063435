import React from 'react';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { ProgressSteps } from '../PlanSelection/PlanSelectionModules';
import AdvancedJobForm from '../../Components/AdvancedJobCreation/AdvancedJobForm';
import { getJobUtilities, getJobTitles, getAdvancedJobFormData, getSkillSet } from '../../Reducers/JobReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getJobStatus, getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getProductVariantsById, getPlanDetailsByProductVariantId } from '../../Reducers/AryaPayOrderReducer';
import * as JobActions from '../../Actions/JobActions';
import { toBooleanQueryStringUsingOR } from '../../Utils/BooleanStringUtility';
import { getJobDetailsForAnalytics, getIsFreeCandidateStepper } from '../../Utils/JobUtils';
import { ADVANCED_JOB_BASIC_INFO } from '../../PubSub/EventTypes';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../Utils/CreditInfoUtils';
import config from '../../Config/Config';
import styles from './AdvancedJobFormContainer.module.scss';

const mapStateToProps = state => {
  return {
    jobTitles: getJobTitles(state),
    advancedJobFormData: getAdvancedJobFormData(state),
    locationApiStatus: getApiStatus(state, 'locationApiStatus'),
    createJobStatus: getApiStatus(state, 'createJobStatus'),
    utilities: getJobUtilities(state),
    userConfig: getConfig(state),
    usersById: getUsersById(state),
    jobStatus: getJobStatus(state),
    featureToggleList: getFeatureToggleList(state),
    currentUser: getCurrentUser(state),
    editJobStatus: getApiStatus(state, 'editJobStatus'),
    showSampleCandidates: getSampleCandidatesDisplayFlag(state),
    skills: getSkillSet(state),
    productVariantsById: getProductVariantsById(state),
    planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  };
};

const mapDispatchToProps = {
  createJob: JobActions.createJob,
  putEditJob: JobActions.putEditJob,
  fetchJobTitles: JobActions.fetchJobTitles,
  fetchLocations: JobActions.fetchLocations,
  fetchSkills: JobActions.fetchSkills,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetJobTitles: JobActions.resetJobTitles,
  searchZipCode: JobActions.searchZipCode,
  clearZipCodeError: JobActions.clearZipCodeError,
  clearZipValidationError: JobActions.clearZipValidationError,
  fetchCountries: JobActions.fetchCountries,
  fetchClients: JobActions.fetchClients,
  setAdvancedJobFormData: JobActions.setAdvancedJobFormData,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
};

class AdvancedJobFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { setSampleCandidatesDisplayFlag, fetchPlanDetailsByProductVariantId } = this.props;
    setSampleCandidatesDisplayFlag();
    fetchPlanDetailsByProductVariantId();
  }

  createNewJob = async (jobDetails, values, resumeUpdateDetails) => {
    const { createJob, history, featureToggleList, jobStatus, setAdvancedJobFormData, currentUser } = this.props;
    const newJob = { IsAdvancedJob: true, AssignedTo: [currentUser?.sub] };
    const { PaidJobService } = featureToggleList;
    Object.keys(values).forEach(key => {
      if (typeof values[key] !== 'undefined') {
        newJob[key] = values[key];
      }
    });
    newJob.Synonyms = jobDetails.Synonyms;

    if (PaidJobService.IsEnabled) {
      const status = jobStatus.find(elem => elem.Name === 'Draft');
      delete newJob?.jobTitleData;
      newJob.StatusId = status.Id;
      newJob.BotSumaryViewUrl = config.urls.botSummaryViewUrl;
      newJob.NiceToHaves = toBooleanQueryStringUsingOR(values?.skills);

      const currentJob = await createJob(newJob, null, resumeUpdateDetails?.uploadedResumeFiles, null);
      const jobId = currentJob?.data?.JobId;
      const analyticsJobDetails = getJobDetailsForAnalytics(currentJob?.data);
      PubSub.publish(ADVANCED_JOB_BASIC_INFO, {
        jobId: currentJob?.data?.JobId,
        jobTitle: currentJob?.data?.JobTitle,
        isAdvanced: currentJob?.data?.IsAdvancedJob,
        jobDetails: analyticsJobDetails,
      });
      setAdvancedJobFormData({
        ...newJob,
        JobId: jobId,
        selectedClient: values?.selectedClient,
        jobTitleData: values?.jobTitleData,
      });
      history.push(`/jobs-v2/${jobId}/edit`);
    }
  };

  editJob = async jobDetails => {
    const { putEditJob, history, setAdvancedJobFormData } = this.props;
    const res = await putEditJob(jobDetails?.JobId, jobDetails, null, null);
    const jobId = res.JobId;
    setAdvancedJobFormData({
      ...jobDetails,
      JobId: jobId,
      selectedClient: jobDetails?.Client,
      jobTitleData: {
        title: jobDetails?.JobTitle,
        titleSynonyms: [],
      },
    });
    const analyticsJobDetails = getJobDetailsForAnalytics(res);
    PubSub.publish(ADVANCED_JOB_BASIC_INFO, {
      jobId: res?.JobId,
      jobTitle: res?.JobTitle,
      isAdvanced: res?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    });
    history.push(`/jobs-v2/${jobId}/edit`);
  };

  render() {
    const { showSampleCandidates, productVariantsById, planDetailsByProductVariantId } = this.props;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
    const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
    return (
      <div className={styles.advancedJobContainer}>
        <div className={styles.stepsWrapper}>
          <ProgressSteps current={0} showSampleCandidates={isFirstJobFree} />
        </div>
        <AdvancedJobForm {...this.props} handleSubmit={this.createNewJob} handleEditJob={this.editJob} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedJobFormContainer);
export { AdvancedJobFormContainer as AdvancedJobFormContainerWithoutStore };
