import React from 'react';
import uuid from 'uuid';
import CandidateNotes from '../CandidateNotes/CandidateNotes';
import NotesForm from '../NotesForm/NotesForm';
import './CallNotes.scss';
import { getUserProperty } from '../../Utils/UserInfo';
import { getLocaleDateStringWithTimeStamp } from '../../Utils/RelativeTimeCalculator';

export default function CallNotes(props) {
  const { notes, isCallNotes, usersById, jobId, candidate } = props;

  const postNote = noteContent => {
    const { audioConversationId, candidateId, conversationId, postCallNotes } = props;
    const note = {
      Content: noteContent,
    };
    postCallNotes(audioConversationId, note, candidateId, conversationId, 0, -1, jobId);
  };

  return (
    <>
      <div id="call-dialog-notes">
        {notes.map(note =>
          note.Content ? (
            <CandidateNotes
              notes={note.Content}
              time={getLocaleDateStringWithTimeStamp(note.CreatedDate)}
              key={uuid.v4()}
              user={getUserProperty(usersById, note.CreatedBy, 'FullName')}
            />
          ) : null
        )}
      </div>
      <div style={{ width: '100%', paddingLeft: 10 }}>
        <NotesForm postNotes={postNote} isCallNotes={isCallNotes} candidate={candidate} />
      </div>
    </>
  );
}
