import React from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import GlobalInsightContainer from '../../Containers/Dashboard/GlobalInsight/GlobalInsightContainer';
import CareerProjectionContainer from '../../Containers/Dashboard/CareerProjection/CareerProjectionContainer';
import { clearLocations } from '../../Actions/LocationSearchActions';

const { TabPane } = Tabs;

export default function ResearchPortalComponent(props) {
  const { featureToggleList } = props;
  const dispatch = useDispatch();
  const isSalesDashboardReportsEnabled = featureToggleList.SalesDashboardReports.IsEnabled;
  const isCareerProjectionReportsEnabled = featureToggleList.CareerProjectionReport.IsEnabled;
  const clearStatesAndCities = () => {
    dispatch(clearLocations({ city: true, state: true }));
  };

  return (
    <div>
      <Tabs defaultActiveKey="globalInsights" onChange={clearStatesAndCities}>
        {isSalesDashboardReportsEnabled ? (
          <TabPane tab="Talent Landscape" key="globalInsights">
            <div>
              <GlobalInsightContainer />
            </div>
          </TabPane>
        ) : null}
        {isCareerProjectionReportsEnabled ? (
          <TabPane tab="Career Projection" key="careerprojection">
            <div>
              <CareerProjectionContainer />
            </div>
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
}
