import React from 'react';
import { connect } from 'react-redux';
import { Button, Skeleton } from 'antd';
import * as Action from '../../Actions/AoDActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getQuoteRequestedByUser } from '../../Reducers/AoDReducer';
import AoDRequestQuoteSuccessModal from '../../Components/AoD/AoDRequestQuoteSuccessModal';
import InfoIconWithTooltip from '../../Components/Common/InfoIconWithTooltip/InfoIconWithTooltip';
import styles from './AoDAdvertisement.module.scss';

const mapStateToProps = state => ({
  aodRequestQuoteApiStatus: getApiStatus(state, 'AoDRequestQuoteApiStatus'),
  quoteRequestedByUserApiStatus: getApiStatus(state, 'QuoteRequestedByUserApiStatus'),
  isQuoteRequestedByUser: getQuoteRequestedByUser(state),
});

const mapDispatchToProps = {
  requestQuote: Action.requestQuote,
  getIsQuoteRequestedByUser: Action.getIsQuoteRequestedByUser,
};

function AoDAdvertisement(props) {
  const {
    jobId,
    requestQuote,
    aodRequestQuoteApiStatus,
    isQuoteRequestedByUser,
    getIsQuoteRequestedByUser,
    quoteRequestedByUserApiStatus,
  } = props;
  const [isAoDModalVisible, setAodModalVisibility] = React.useState(false);

  React.useEffect(() => {
    getIsQuoteRequestedByUser();
  }, []);

  const onRequestQuote = async () => {
    const isSuccess = await requestQuote(jobId);
    if (isSuccess) setAodModalVisibility(true);
  };

  const onCloseModal = () => {
    setAodModalVisibility(false);
  };

  return (
    <Skeleton loading={quoteRequestedByUserApiStatus === 'INPROGRESS'}>
      <div className={styles.aodTitle}>Applicants on Demand</div>
      <div className={styles.aodContent}>
        Automate high-volume sourcing to&nbsp;
        <span className={styles.highlightContent}>capture qualified</span>,&nbsp;
        <span className={styles.highlightContent}>engaged applicants</span> directly in Arya using our
        pay-for-performance solution.
      </div>
      <Button
        type="primary"
        shape="round"
        style={{ marginTop: '5px' }}
        onClick={onRequestQuote}
        loading={aodRequestQuoteApiStatus === 'INPROGRESS'}
        disabled={isQuoteRequestedByUser}
      >
        Request a Quote
      </Button>
      {isQuoteRequestedByUser ? (
        <span className={styles.infoTooltip}>
          <InfoIconWithTooltip tooltipTitle="we received your request will be in touch shortly" />
        </span>
      ) : null}
      {isAoDModalVisible ? <AoDRequestQuoteSuccessModal onClose={onCloseModal} isVisible={isAoDModalVisible} /> : null}
    </Skeleton>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AoDAdvertisement);
export { AoDAdvertisement as AoDAdvertisementWithoutStore };
