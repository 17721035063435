import { recommendCandidate } from './CandidateActions';

export function setCallDialogVisibility({ isVisible, showDialpad }) {
  return dispatch => {
    dispatch({
      type: 'SET_CALL_DIALOG_VISIBILITY',
      payload: {
        isVisible,
        showDialpad,
      },
    });
  };
}

export function setCallDialogNotesViewState(notesOpened) {
  return async dispatch => {
    await dispatch({
      type: 'SET_NOTES_VIEW_STATE',
      payload: notesOpened,
    });
  };
}

export function initiateCall({
  conversationId,
  phoneNumber,
  personName,
  candidateId,
  jobId,
  candidate,
  callInitiated,
}) {
  return async dispatch => {
    const isRecommendedCandidate = Boolean(candidate.Status);
    if (!isRecommendedCandidate) {
      await dispatch(recommendCandidate(candidate));
    }
    dispatch({
      type: 'INITIATE_CALL',
      payload: {
        callInitiated,
        conversationId,
        phoneNumber,
        personName,
        candidateId,
        jobId,
        candidate,
      },
    });
  };
}

export function setCallerId({ callerId }) {
  return dispatch => {
    dispatch({
      type: 'SET_CALLER_ID',
      payload: {
        callerId,
      },
    });
  };
}

export function setCallStatus({ callStatus }) {
  return dispatch => {
    dispatch({
      type: 'SET_CALL_STATUS',
      payload: {
        callStatus,
      },
    });
  };
}

export function setSipRegistrationStatus({ isSipRegistered }) {
  return dispatch => {
    dispatch({
      type: 'SET_SIP_REGISTRATION_STATUS',
      payload: {
        isSipRegistered,
      },
    });
  };
}

export function resetCallDialogState(payload) {
  return dispatch => {
    dispatch({
      type: 'RESET_CALL_DIALOG_STATE',
      payload,
    });
  };
}
