import React from 'react';
import { getContactPullStatus } from '../ConnectMediaWidget/ConnectMediaWidget';
import EnhancedFetchContactWidget from '../EnhancedFetchContactWidget/EnhancedFetchContactWidget';

function FetchContactWidgetWrapper(props) {
  const { candidate = {}, currentJobDetails, availableProviders, contact, onCandidate360TabChange } = props;

  let contactRequestStatus = contact?.RequestStatus || null;
  if ((contact.Emails && contact.Emails.length > 0) || (contact.Phones && contact.Phones.length > 0)) {
    contactRequestStatus = 'Success';
  }
  const contactPullStatus = getContactPullStatus(contact, contactRequestStatus);
  let contactPullColor;
  if (contactPullStatus.toLowerCase() === 'notpulled') {
    contactPullColor = '#83878C';
  } else if (
    contactPullStatus.toLowerCase() === 'phone' ||
    contactPullStatus.toLowerCase() === 'email' ||
    contactPullStatus.toLowerCase() === 'all' ||
    contactPullStatus.toLowerCase() === 'invalidemail'
  ) {
    contactPullColor = '#13C26B';
  } else contactPullColor = '#f5222d';

  return (
    <EnhancedFetchContactWidget
      fetchContactStatus={candidate.fetchContactStatus}
      currentJobDetails={currentJobDetails}
      candidate={candidate}
      contactPullColor={contactPullColor}
      isDisable={availableProviders === 0}
      onCandidate360TabChange={onCandidate360TabChange}
    />
  );
}

export default FetchContactWidgetWrapper;
