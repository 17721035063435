import _ from 'lodash';
import cleanSet from 'clean-set';

const emptyObject = {};
const emptyList = [];

function EmailTemplates(state = {}, action) {
  let newState;
  let templateIds;
  switch (action.type) {
    case 'SET_EMAIL_TEMPLATES':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};

      newState.CurrentTemplateIds = action.payload.map(template => template.Id);
      newState.templateIds = action.payload.map(template => template.Id);
      action.payload.forEach(template => {
        newState.ById[template.Id] = template;
      });

      return newState;

    case 'SET_EMAIL_TEMPLATE_DETAILS':
      newState = cleanSet(state, ['ById', action.payload.template.Id], action.payload.template);
      return newState;

    case 'APPEND_EMAIL_TEMPLATES':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};
      templateIds = action.payload.templates.map(template => template.Id);
      templateIds = _.uniqBy([...newState.templateIds, ...templateIds]);
      newState.templateIds = templateIds;
      action.payload.templates.forEach(template => {
        newState.ById[template.Id] = template;
      });

      return newState;

    case 'SET_EMAIL_TEMPLATES_COUNT':
      return {
        ...state,
        Count: action.payload,
      };
    case 'SET_EMAIL_TEMPLATE_SHARED_WITH':
      newState = _.cloneDeep(state);
      if (action.payload.sharedWith || action.payload.unsharedWith) {
        _.set(newState, ['ById', action.templateId, 'SharedWith'], action.payload.sharedWith);
        _.set(newState, ['ById', action.templateId, 'UnsharedWith'], action.payload.unsharedWith);
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }

      if (action.payload.isShareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], true);
      }
      if (action.payload.isUnshareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }
      return newState;
    default:
      return state;
  }
}

function getCurrentEmailTemplateIds(state) {
  return state.EmailTemplates.CurrentTemplateIds || emptyList;
}

function getEmailTemplatesById(state) {
  return state.EmailTemplates.ById || emptyObject;
}

function getEmailTemplateIds(state) {
  return state.EmailTemplates.templateIds || emptyList;
}

function getEmailTemplatesCount(state) {
  return state.EmailTemplates.Count;
}

export {
  EmailTemplates,
  getCurrentEmailTemplateIds,
  getEmailTemplatesById,
  getEmailTemplateIds,
  getEmailTemplatesCount,
};
