import React from 'react';
import { GoodRatingIcon, BetterRatingIcon, BestRatingIcon } from '../../Icons/AryaIcons';

function RatingIcon(props) {
  switch (props.type) {
    case 'Good':
      return <GoodRatingIcon style={{ fontSize: 20 }} />;
    case 'Better':
      return <BetterRatingIcon style={{ fontSize: 20 }} />;
    case 'Best':
      return <BestRatingIcon style={{ fontSize: 20 }} />;
    default:
      return null;
  }
}

export default RatingIcon;
