import React from 'react';
import styles from './ClearAllButton.module.scss';

function ClearAllButton(props) {
  const { onClear, skEventName } = props;
  return (
    <div
      className={styles.clearAll}
      role="button"
      onClick={onClear}
      onKeyPress={onClear}
      tabIndex={0}
      sk-event-name={skEventName}
    >
      Clear All
    </div>
  );
}

export default ClearAllButton;
