import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterPopUp from '../../Components/FilterPopUp/FilterPopUp';
import * as JobActions from '../../Actions/JobActions';
import { getSelectedTags, getTagsForFilterPopUp } from '../../Reducers/JobReducer';

const mapStateToProps = state => ({
  selectedTags: getSelectedTags(state),
  tags: getTagsForFilterPopUp(state),
});

const mapDispatchToProps = {
  updateFilterTags: JobActions.updateFilterTags,
};

class FilterPopUpContainer extends Component {
  componentDidMount() {
    // const {getSelectedTags}= this.props;
    // getSelectedTags()
  }

  render() {
    const { updateFilterTags, selectedTags, fetchJobsOnAppliedFilter, tags } = this.props;

    return (
      <div>
        <FilterPopUp
          updateFilterTags={updateFilterTags}
          selectedTags={selectedTags}
          fetchJobsOnAppliedFilter={fetchJobsOnAppliedFilter}
          tags={tags}
        />
      </div>
    );
  }
}
export { FilterPopUpContainer as FilterPopUpContainerWithoutStore };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPopUpContainer);
