import { payV1Client } from './BaseRepository';

export function getProductVariants(productName) {
  return payV1Client.get(`/ProductVariants?productName=${productName}`);
}

export function getPlanDetails(query) {
  return payV1Client.post('/plans/_search', query);
}

export function getTotalAmountForProductvariant(items) {
  return payV1Client.post('orders/_amount', items);
}
