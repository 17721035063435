import * as React from 'react';
import { Icon } from 'antd';

export const PlusSvg = props => (
  <svg width={17} height={16} fill="none" {...props}>
    <path
      d="M15.54 7.297H9.1V1.232A.715.715 0 0 0 8.37.53a.716.716 0 0 0-.729.703v6.065h-6.3A.715.715 0 0 0 .614 8c0 .389.326.703.728.703h6.3v6.084c0 .389.326.703.729.703a.715.715 0 0 0 .728-.703V8.703h6.442A.715.715 0 0 0 16.269 8a.715.715 0 0 0-.728-.703Z"
      fill="#808489"
    />
  </svg>
);

const PlusIcon = props => <Icon component={PlusSvg} {...props} />;

export default PlusIcon;
