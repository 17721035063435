import React from 'react';
import { Select, Tooltip } from 'antd';
import { getRegionOptions } from '../../../Utils/RegionUtils/RegionUtils';
import MultiLocationContext from '../MultiLocationContext';

const RegionSelect = props => {
  const { isRemoteJobChecked } = props;
  const {
    regionTooltipContent,
    Region,
    onChangeRegion,
    isRegionDisabled,
    regions,
    isAdvanceSearch,
    isPrimaryLocation,
  } = React.useContext(MultiLocationContext);
  return (
    <Tooltip title={!(isAdvanceSearch && isPrimaryLocation) ? regionTooltipContent : ''}>
      <Select
        placeholder="Regions"
        style={{ width: '100%' }}
        value={Region?.RegionName}
        onChange={onChangeRegion}
        disabled={isRegionDisabled || isRemoteJobChecked}
        allowClear
        key="Region"
      >
        {getRegionOptions({ regions })}
      </Select>
    </Tooltip>
  );
};

export default RegionSelect;
