import React from 'react';
import { connect } from 'react-redux';
import { getJobNotesByJobId } from '../../Reducers/JobReducer';
import * as jobActions from '../../Actions/JobActions';
import * as candidateActions from '../../Actions/CandidateActions';
import JobNotes from '../../Components/JobNotes/JobNotes';
import * as ApiStatusReducer from '../../Reducers/ApiStatusReducer';
import { getCandidateSuggestedTags, getCandidateSuggestedTagsCount } from '../../Reducers/CandidateReducer';
import { setNotification as _setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  notesData: getJobNotesByJobId(state, props.jobId),
  jobNoteFetchApiStatus: ApiStatusReducer.getApiStatus(state, 'jobNoteFetchApiStatus'),
  jobNoteDeleteApiStatuses: ApiStatusReducer.getApiStatus(state, 'jobNoteDeleteApiStatus'),
  jobNoteAddButtonApiStatus: ApiStatusReducer.getApiStatus(state, 'jobNoteAddButtonApiStatus'),
  suggestedTagsApiStatus: ApiStatusReducer.getApiStatus(state, 'suggestedTagsApiStatus'),
  tagCloseApiStatus: ApiStatusReducer.getApiStatus(state, 'tagDeleteApiStatus'),
  candidateSuggestedTags: getCandidateSuggestedTags(state),
  candidateSuggestedTagsCount: getCandidateSuggestedTagsCount(state),
});

const mapDispatchToProps = {
  getAllJobNotes: jobActions.getAllJobNotes,
  createJobNote: jobActions.createJobNote,
  deleteJobNote: jobActions.deleteJobNote,
  updateJobNote: jobActions.updateJobNote,
  fetchTagsForCandidate: candidateActions.fetchTagsForCandidate,
  addTagForJobNote: jobActions.addTagForJobNote,
  deleteTagFromJobNote: jobActions.deleteTagFromJobNote,
  setNotification: _setNotification,
};

function JobNotesContainer(props) {
  const {
    notesData,
    getAllJobNotes,
    candidateContext,
    jobId,
    createJobNote,
    deleteJobNote,
    updateJobNote,
    jobNoteFetchApiStatus,
    jobNoteDeleteApiStatuses,
    jobNoteAddButtonApiStatus,
    fetchTagsForCandidate,
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    addTagForJobNote,
    tagCloseApiStatus,
    deleteTagFromJobNote,
    setNotification,
    suggestedTagsApiStatus,
  } = props;
  return (
    <div>
      <JobNotes
        notesData={notesData}
        getAllJobNotes={getAllJobNotes}
        candidateContext={candidateContext}
        jobId={jobId}
        createJobNote={createJobNote}
        deleteJobNote={deleteJobNote}
        updateJobNote={updateJobNote}
        jobNoteFetchApiStatus={jobNoteFetchApiStatus}
        jobNoteDeleteApiStatuses={jobNoteDeleteApiStatuses}
        jobNoteAddButtonApiStatus={jobNoteAddButtonApiStatus}
        fetchTagsForCandidate={fetchTagsForCandidate}
        candidateSuggestedTags={candidateSuggestedTags}
        candidateSuggestedTagsCount={candidateSuggestedTagsCount}
        addTagForJobNote={addTagForJobNote}
        tagCloseApiStatus={tagCloseApiStatus}
        deleteTagFromJobNote={deleteTagFromJobNote}
        setNotification={setNotification}
        suggestedTagsApiStatus={suggestedTagsApiStatus}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobNotesContainer);
