export const RESET_CAREER_PROJECTION = 'RESET_CAREER_PROJECTION';
export const CAREER_PROJECTION_API_STATUS = 'CAREER_PROJECTION_API_STATUS';
export const CAREER_ASSESSMENT_API_STATUS = 'CAREER_ASSESSMENT_API_STATUS';
export const SET_CAREER_PROJECTION_DATA = 'SET_CAREER_PROJECTION_DATA';
export const SET_CAREER_ASSESSMENT_DATA = 'SET_CAREER_ASSESSMENT_DATA';

export const resetCareerProjection = {
  type: RESET_CAREER_PROJECTION,
};
export const setCareerProjectionApiStatus = payload => ({
  type: CAREER_PROJECTION_API_STATUS,
  payload,
});

export const setCareerProjectionData = payload => ({
  type: SET_CAREER_PROJECTION_DATA,
  payload,
});

export const setCareerAssessmentApiStatus = payload => ({
  type: CAREER_ASSESSMENT_API_STATUS,
  payload,
});

export const setCareerAssessmentData = payload => ({
  type: SET_CAREER_ASSESSMENT_DATA,
  payload,
});
