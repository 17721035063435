import React from 'react';
import _ from 'lodash';
import { Form, Input, Button, Icon, Select } from 'antd';
import './NexxtLoginForm.scss';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

class NexxtLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit(isAuthenticated) {
    const { form, authenticate, jobBoard, disconnect, index, clearAccount, addSelectedCountry, removeSelectedCountry } =
      this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const countryCode = values[`${jobBoard}countryCode`];
        if (isAuthenticated) {
          disconnect(jobBoard, countryCode, index, removeSelectedCountry);
        } else {
          authenticate(
            jobBoard,
            {
              Username: values[`${jobBoard}userName`],
              Password: values[`${jobBoard}password`],
              EmployerEmail: values[`${jobBoard}employerEmail`],
              EmployerPassword: values[`${jobBoard}employerPassword`],
              EmployerCompanyId: values[`${jobBoard}employerId`],
              CountryCode: countryCode,
            },
            index,
            clearAccount,
            addSelectedCountry
          );
        }
      }
    });
  }

  render() {
    const {
      form,
      jobBoard,
      portalSettings,
      account,
      index,
      Countries,
      countriesMenu,
      credentialsValidator,
      isAuthenticated,
    } = this.props;
    const credentials = account;
    const username = `${jobBoard}userName`;
    const password = `${jobBoard}password`;
    const employerId = `${jobBoard}employerId`;
    const employerEmail = `${jobBoard}employerEmail`;
    const employerPassword = `${jobBoard}employerPassword`;
    const mandatoryFields = [username, password, employerId, employerEmail, employerPassword];
    const usernameError = form.isFieldTouched(username) && form.getFieldError(username);
    const passwordError = form.isFieldTouched(password) && form.getFieldError(password);
    const employerIdError = form.isFieldTouched(employerId) && form.getFieldError(employerId);
    const employerEmailError = form.isFieldTouched(employerEmail) && form.getFieldError(employerEmail);
    const employerPasswordError = form.isFieldTouched(employerPassword) && form.getFieldError(employerPassword);
    const errorDetail = getJobBoardErrorDetails(credentials, jobBoard);
    return (
      <div className="portal-login-form">
        <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
          {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
        </div>
        <div className="error-message-with-form">
          <Form>
            <div className="jobboard-credentials-container">
              <div className="jobboard-input-container">
                <Form.Item>
                  <div className="jobboard-country-code">
                    {form.getFieldDecorator(`${jobBoard}countryCode`, {
                      initialValue: credentials.CountryCode || (Countries && Countries[0]),
                    })(
                      <Select
                        className="jobboard-country-code-select"
                        disabled={isAuthenticated || countriesMenu.length < 2}
                      >
                        {countriesMenu}
                      </Select>
                    )}
                  </div>
                </Form.Item>
              </div>
              <div className="jobboard-input-container">
                <Form.Item validateStatus={usernameError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}userName`, {
                    initialValue: credentials.Username,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(<Input placeholder="Username" className="nexxt-username" readOnly={isAuthenticated} />)}
                </Form.Item>
                <Form.Item validateStatus={employerIdError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}employerId`, {
                    initialValue: credentials.EmployerCompanyId,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(
                    <Input
                      placeholder="Employer Id"
                      className="nexxt-employer-id"
                      autoComplete="false"
                      readOnly={isAuthenticated}
                    />
                  )}
                </Form.Item>
                <Form.Item validateStatus={employerEmailError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}employerEmail`, {
                    initialValue: credentials.EmployerEmail,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(
                    <Input
                      placeholder="Employer Email"
                      className="nexxt-employer-email"
                      autoComplete="false"
                      readOnly={isAuthenticated}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="jobboard-input-container">
                <Form.Item validateStatus={passwordError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}password`, {
                    initialValue: isAuthenticated ? '********' : undefined,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(
                    <Input
                      type="password"
                      placeholder="Password"
                      className="nexxt-password"
                      autoComplete="new-password"
                      readOnly={isAuthenticated}
                    />
                  )}
                </Form.Item>
                <Form.Item validateStatus={employerPasswordError ? 'error' : ''} help="">
                  {form.getFieldDecorator(`${jobBoard}employerPassword`, {
                    initialValue: isAuthenticated ? '********' : undefined,
                    rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
                  })(
                    <Input
                      type="password"
                      placeholder="Employer Password"
                      className="nexxt-employer-password"
                      autoComplete="new-password"
                      readOnly={isAuthenticated}
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item>
                <div className="btn-container">
                  <Button
                    // type="primary"
                    htmlType="submit"
                    loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
                    onClick={() => this.handleSubmit(isAuthenticated)}
                    className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
                    shape="round"
                    disabled={
                      credentialsValidator.hasError(form.getFieldsError()) ||
                      credentialsValidator.anyFieldEmpty(form, mandatoryFields)
                    }
                    block
                  >
                    {isAuthenticated ? 'Disconnect' : 'Authenticate'}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
          <div className="error-message">{errorDetail.Message}</div>
        </div>
      </div>
    );
  }
}

export default Form.create()(NexxtLoginForm);
export { NexxtLoginForm as NexxtLoginWithoutForm };
