import React from 'react';
import { Form, Select, Button, Icon, Input } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import styles from './DecisionNodeContent.module.scss';

const FormItem = Form.Item;
const { Option } = Select;
export default function DecisionNodeContent(props) {
  const { form, initialValue, drawerFooter, isDisabled } = props;

  const initialSetOfKeys = _.get(initialValue, 'SetOfKeys', { 0: [0] });

  function setSelectedConditions(initialConditions) {
    const initialKeys = Object.keys(initialSetOfKeys);
    initialConditions.map((item, index) => {
      return form.setFieldsValue({ [`andFilterList${initialKeys[index]}`]: _.get(item, [1], []) });
    });
  }
  React.useEffect(() => {
    const andConditions = _.get(initialValue, 'SelectedConditions', []);
    setSelectedConditions(andConditions);
  }, []);

  form.getFieldDecorator('SetOfKeys', { initialValue: initialSetOfKeys });
  const getKeyObject = form.getFieldValue('SetOfKeys');

  const addAndFilter = indexKey => {
    const newSetOfKeys = form.getFieldValue('SetOfKeys');
    const keys = _.get(getKeyObject, [indexKey]);
    const nextKeys = keys.concat(Math.max(...keys) + 1);
    newSetOfKeys[indexKey.toString()] = nextKeys;
    form.setFieldsValue({
      SetOfKeys: newSetOfKeys,
    });
  };

  const deleteFilterItem = (indexKey, itemIndex) => {
    const newSetOfKeys = form.getFieldValue('SetOfKeys');
    const currentAndFilterList = form.getFieldValue(`andFilterList${indexKey}`);
    const newAndFilterList = currentAndFilterList?.filter(
      (item, index) => currentAndFilterList[index] !== currentAndFilterList[itemIndex]
    );
    const KeyItems = _.get(getKeyObject, [indexKey]);
    const nextKeys = KeyItems.filter(keyItem => keyItem !== KeyItems[itemIndex]);
    newSetOfKeys[indexKey.toString()] = nextKeys;
    form.setFieldsValue({
      SetOfKeys: newSetOfKeys,
      [`andFilterList${indexKey}`]: newAndFilterList,
    });
  };

  const clearFilterItem = (indexKey, itemIndex) => {
    const updatedAndFilterList = form.getFieldValue(`andFilterList${indexKey}`);
    updatedAndFilterList[itemIndex] = { category: undefined, rule: undefined };
    form.setFieldsValue({
      [`andFilterList${indexKey}`]: updatedAndFilterList,
    });
  };

  const addOrFilter = () => {
    const newSetOfKeys = form.getFieldValue('SetOfKeys');
    const listOfFields = Object.keys(getKeyObject);
    newSetOfKeys[Math.max(...listOfFields) + 1] = [0];
    form.setFieldsValue({
      SetOfKeys: newSetOfKeys,
    });
  };

  const deleteAndNode = indexKey => {
    const newSetOfKeys = _.cloneDeep(form.getFieldValue('SetOfKeys'));
    delete newSetOfKeys[indexKey.toString()];
    form.setFieldsValue({
      SetOfKeys: newSetOfKeys,
      [`andFilterList${indexKey}`]: undefined,
    });
  };

  const getSelectOptionsByCategory = (indexKey, itemIndex) => {
    const category = form.getFieldValue(`andFilterList${indexKey}[${itemIndex}].category`);
    switch (category) {
      case 'Candidate':
        return (
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Choose a rule"
            style={{ width: 230 }}
            disabled={isDisabled}
          >
            <Option value="Applied">Candidate applied</Option>
            <Option value="Not Applied">Candidate not applied</Option>
          </Select>
        );
      case 'Email':
        return (
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Choose a rule"
            style={{ width: 230 }}
            disabled={isDisabled}
          >
            <Option value="Opened">Any email opened</Option>
            <Option value="Clicked">Any email link clicked</Option>
            <Option value="Not Opened">No email opened</Option>
            <Option value="Not Clicked">No email link clicked</Option>
          </Select>
        );
      default:
        return (
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Choose a rule"
            style={{ width: 230 }}
            disabled={isDisabled}
          />
        );
    }
  };

  const getStylesByFieldValue = (indexKey, itemIndex, isDelete) => {
    const { category, rule } = form.getFieldValue(`andFilterList${indexKey}[${itemIndex}]`);
    return classNames(
      {
        [styles.filledClearText]: category && rule,
      },
      {
        [styles.filledDeleteText]: isDelete && category && rule,
      },
      {
        [styles.emptyText]: !(category && rule),
      }
    );
  };

  const handleCategoryChange = (indexKey, itemIndex) => {
    form.setFieldsValue({ [`andFilterList${indexKey}[${itemIndex}].rule`]: undefined });
  };

  const displayDeleteAndConditionButton = ({ showButton, indexKey, itemIndex }) => {
    if (showButton) {
      return (
        <div className={getStylesByFieldValue(indexKey, itemIndex, true)}>
          <Button type="link" onClick={() => deleteFilterItem(indexKey, itemIndex)} disabled={isDisabled}>
            <span>Delete</span>
          </Button>
        </div>
      );
    }
    return null;
  };

  const displayClearAndConditionButton = ({ showButton, indexKey, itemIndex }) => {
    if (showButton) {
      return (
        <div className={getStylesByFieldValue(indexKey, itemIndex, false)}>
          <Button type="link" onClick={() => clearFilterItem(indexKey, itemIndex)} disabled={isDisabled}>
            <span>Clear</span>
          </Button>
        </div>
      );
    }
    return null;
  };

  const showAndBlockCancelIcon = ({ showCloseIcon, indexKey }) => {
    if (showCloseIcon) {
      return (
        <div className={styles.deleteNodeIcon}>
          <Icon type="close" onClick={() => deleteAndNode(indexKey)} style={{ fontSize: '12px', color: '#83878C' }} />
        </div>
      );
    }
    return null;
  };

  const displayFilterList = () => {
    const getCurrentKeys = Object.keys(getKeyObject);
    return getCurrentKeys ? (
      <div>
        {getCurrentKeys.map((indexKey, index) => {
          const currentKeyValues = _.get(getKeyObject, [indexKey], []);
          return (
            <div key={indexKey}>
              <div className={styles.ruleInputDropdownSection}>
                {showAndBlockCancelIcon({ showCloseIcon: getCurrentKeys.length > 1, indexKey })}
                {currentKeyValues?.map((_k, itemIndex) => (
                  <div key={_k} className={styles.andInputList}>
                    <FormItem>
                      {form.getFieldDecorator(`andFilterList${indexKey}[${itemIndex}].category`, {
                        rules: [{ required: true, message: 'Category cannot be empty' }],
                      })(
                        <Select
                          placeholder="Category"
                          style={{ width: 200 }}
                          getPopupContainer={trigger => trigger.parentNode}
                          onChange={() => handleCategoryChange(indexKey, itemIndex)}
                          disabled={isDisabled}
                        >
                          <Option value="Email">Email</Option>
                          <Option value="Candidate">Candidate</Option>
                        </Select>
                      )}
                    </FormItem>
                    <FormItem>
                      {form.getFieldDecorator(`andFilterList${indexKey}[${itemIndex}].rule`, {
                        rules: [{ required: true, message: 'Rule cannot be empty' }],
                      })(getSelectOptionsByCategory(indexKey, itemIndex))}
                    </FormItem>
                    {displayDeleteAndConditionButton({ showButton: itemIndex !== 0, indexKey, itemIndex })}
                    {displayClearAndConditionButton({ showButton: itemIndex === 0, indexKey, itemIndex })}
                  </div>
                ))}
                <Button type="link" onClick={() => addAndFilter(indexKey)} disabled={isDisabled}>
                  <span className={styles.andFilter}>Add &#39;AND&#39; filter</span>
                </Button>
              </div>
              {getCurrentKeys.length > 1 && index + 1 !== getCurrentKeys.length ? (
                <div className={styles.orTextDisplay}>
                  <b>OR</b>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    ) : null;
  };

  return (
    <div className={styles.decisionNodeContainer}>
      {/* <div className={styles.ruleDropdownSection}>
        <FormItem>
          {form.getFieldDecorator('RuleInput', { initialValue: _.get(initialValue, 'RuleInput') })(
            <Select style={{ width: 436 }} placeholder="Choose an existing rule">
              <Option value="rule-1">Rule-1</Option>
              <Option value="rule-2">Rule-2</Option>
              <Option value="rule-3">Rule-3</Option>
              <Option value="rule-4">Rule-4</Option>
            </Select>
          )}
        </FormItem>
        <Button type="link" onClick={() => {}}>
          <span>Clear</span>
        </Button>
      </div> */}
      <div className={styles.addNewRuleSection}>
        <FormItem colon={false} label={<b>Create a new rule</b>}>
          {form.getFieldDecorator('NewRuleName', {
            initialValue: _.get(initialValue, 'NewRuleName', undefined),
          })(<Input style={{ width: 510 }} placeholder="Name the rule" disabled={isDisabled} />)}
        </FormItem>
        {displayFilterList()}
        <Button type="link" onClick={addOrFilter} disabled={isDisabled}>
          <span className={styles.orFilter}>Add &#39;OR&#39; filter</span>
        </Button>
      </div>
      <div className={styles.footerPlacememt}>{drawerFooter}</div>
    </div>
  );
}
