import React from 'react';
import styles from './SegregateStyle.module.scss';

const SegregateComponent = props => {
  const { isActive, options = [], openChange, parentStyle, contentStyle, activeSegmentClassName } = props;
  const _handleChange = () => {
    if (openChange) openChange();
  };

  return (
    <div className={`${styles.segregate} ${parentStyle}`}>
      {options.map(({ component, name }) => {
        return (
          <div
            className={`segregateButton ${contentStyle} ${isActive === name && activeSegmentClassName} `}
            role="button"
            tabIndex={0}
            onKeyPress
            onClick={() => _handleChange()}
            value={component}
          >
            {component}
          </div>
        );
      })}
    </div>
  );
};

export default SegregateComponent;
