/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Collapse, Divider, Skeleton, Form } from 'antd';
import AdvancedJobDetailsSelection from '../AdvancedJobDetailsSelection/AdvancedJobDetailsSelection';
import AdvancedJobLocationPanel from '../AdvancedJobLocationInsight/AdvancedJobLocationPanel';
import GlobalInsightSupplyMenuList from '../../GlobalInsight/Filter/GlobalInsightSupplyMenuList';
import RichTextEditor from '../../Editor/RichTextEditor';
import {
  getUserGuids,
  getJobDetailsForAnalytics,
  getFormattedInput,
  validateInputField,
  SAVE,
} from '../../../Utils/JobUtils';
import { toBooleanQueryStringUsingOR, generateKeywordsFromBooleanString } from '../../../Utils/BooleanStringUtility';
import {
  getFormattedInsightsList,
  formatFormValues,
  getActiveInsightList,
  getSelectedSkills,
  filterNiceToHaves,
  filterMustHaves,
} from '../../../Utils/AdvancedJobUtil';
import { ADVANCED_JOB_ADDITIONAL_INPUT } from '../../../PubSub/EventTypes';
import JobEditIcon from '../../../Icons/JobEditIcon';
import SalarySelection from '../AdvancedJobDetailsSelection/SalarySelection';
import styles from './AdvancedJobDetailsEdit.module.scss';
import JobCreationFooter from '../JobCreationFooter/JobCreationFooter';
import WarningPopupModal from '../WarningPopupModal/WarningPopupModal';
import JobDescriptionContainer from '../../../Containers/JobDescriptionContainer/JobDescriptionContainer';

const { Panel } = Collapse;
const FormItem = Form.Item;

const menuItems = {
  skills: { name: 'Skills', id: 'AdvancedJob-Skills' },
  roles: { name: 'Roles', id: 'AdvancedJob-Roles' },
  industries: { name: 'Industries', id: 'AdvancedJob-Industries' },
  experience: { name: 'Experience', id: 'AdvancedJob-Experience' },
  salary: { name: 'Salary', id: 'AdvancedJob-Salary' },
  education: { name: 'Education', id: 'AdvancedJob-Education' },
  summary: { name: 'Job Summary', id: 'AdvancedJob-Summary' },
  description: { name: 'Job Description', id: 'AdvancedJob-Description' },
};

const advancedMenuItems = {
  employmentType: { name: 'Employment Type', id: 'AdvancedJob-EmploymentType' },
  workAuthorization: { name: 'Work Authorization', id: 'AdvancedJob-WorkAuthorization' },
  specialNotes: { name: 'Additional Notes', id: 'AdvancedJob-Notes' },
};

const advancedMenuList = Object.values(advancedMenuItems);

class AdvancedJobDetailsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanelKey: '1',
      warningMessage: '',
      isSubmitButtonClicked: false,
    };
    this.locationInsightRef = React.createRef();
  }

  handlePanelChange = key => {
    this.setState({ activePanelKey: key });
    const { jobDetails } = this.props;
    const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
    if (key === '2') {
      PubSub.publish(ADVANCED_JOB_ADDITIONAL_INPUT, {
        jobId: jobDetails?.JobId,
        jobTitle: jobDetails?.JobTitle,
        isAdvanced: jobDetails?.IsAdvancedJob,
        jobDetails: analyticsJobDetails,
      });
    }
    window.scrollTo(0, this.locationInsightRef?.current?.offsetTop);
  };

  getAdditonalNotes = () => {
    const { form, jobDetails } = this.props;
    return form.getFieldValue('additionalNotes')?.htmlContent || jobDetails?.JobAdvanceInfo?.SpecialNotes;
  };

  getJobSummary = () => {
    const { form, jobDetails } = this.props;
    const summary = form.getFieldValue('jobSummary')?.htmlContent || jobDetails?.Summary;
    return summary || '';
  };

  componentWillUnmount() {
    const { editJobStatus, form, globalSupplyApiStatus } = this.props;
    if (editJobStatus !== 'INPROGRESS' && globalSupplyApiStatus !== 'INPROGRESS') {
      form.validateFieldsAndScroll({ scroll: { offsetTop: 150 } }, (err, values) => {
        const newFormValues = _.cloneDeep(values);
        delete newFormValues.Description;
        delete newFormValues.additionalNotes;
        delete newFormValues.jobSummary;
        if (!_.isEmpty(newFormValues)) this.submitJob('handleSave', values);
      });
    }
  }

  validateOptionalInputFieldtext = (isJobSummaryInvalid, isAdditionalNotesError) => {
    return `${isJobSummaryInvalid ? 'Job Summary cannot be more than 1500 characters. ' : ''}${
      isAdditionalNotesError ? 'Additional notes cannot be more than 500 characters' : ''
    }`;
  };

  buildWarningMessage = errorFields => {
    const isSkillsRequired = errorFields.includes('SkillName');
    const isJobDescriptionRequired = errorFields.includes('Description');
    const isAdditionalNotesError = this.getAdditonalNotes()?.length > 500;
    const jobSummary = this.getJobSummary();
    const isJobSummaryInvalid = validateInputField(jobSummary)?.length > 1500;
    if (isSkillsRequired || isJobDescriptionRequired) {
      return `Please fill the ${isSkillsRequired ? 'Market Skills' : ''}${
        isSkillsRequired && isJobDescriptionRequired ? ' and ' : ''
      }${isJobDescriptionRequired ? 'Job Description' : ''} field${
        isSkillsRequired && isJobDescriptionRequired ? 's' : ''
      } under the insights section to proceed further.${this.validateOptionalInputFieldtext(
        isJobSummaryInvalid,
        isAdditionalNotesError
      )}`;
    }
    return this.validateOptionalInputFieldtext(isJobSummaryInvalid, isAdditionalNotesError);
  };

  submitWithFormValidation = submitAction => {
    const { form } = this.props;
    if (submitAction === SAVE) {
      this.setState({ isSubmitButtonClicked: true });
    }
    form.validateFieldsAndScroll({ scroll: { offsetTop: 150 } }, (err, values) => {
      if (submitAction === SAVE && !err) {
        this.setWarningPopupModalMessage(this.buildWarningMessage(''));
        this.setState({ isSubmitButtonClicked: true });
      }
      if (!err || submitAction !== SAVE) {
        this.setWarningPopupModalMessage(this.buildWarningMessage(''));
        this.submitJob(submitAction, values);
      } else {
        this.setState({ isSubmitButtonClicked: false });
        const errorFields = Object.keys(err).join(',');
        this.setWarningPopupModalMessage(this.buildWarningMessage(errorFields));
      }
    });
  };

  submitJob = (submitAction, values) => {
    const { handleSubmit, jobDetails, usersById, setAdvancedJobFormData } = this.props;
    const notes = this.getAdditonalNotes();
    const newJobDetails = _.cloneDeep(jobDetails);
    newJobDetails.AssignedTo = getUserGuids(newJobDetails?.AssignedTo, usersById);
    newJobDetails.MustHaves = toBooleanQueryStringUsingOR(filterMustHaves(values?.SkillName));
    newJobDetails.NiceToHaves = toBooleanQueryStringUsingOR(filterNiceToHaves(values?.SkillName));
    newJobDetails.Synonyms = formatFormValues(values?.SimilarTitle, 'SimilarTitle');
    newJobDetails.Industries = formatFormValues(values?.IndustryName, 'IndustryName');
    newJobDetails.JobAdvanceInfo.Degree = formatFormValues(values?.degreeName, 'degreeName');
    newJobDetails.JobAdvanceInfo.WorkAuthorization = formatFormValues(values?.workName, 'workName');
    newJobDetails.JobAdvanceInfo.EmploymentTypes = formatFormValues(values?.employmentName, 'employmentName');
    newJobDetails.MinExperience = values?.MinExperience || 0;
    newJobDetails.MaxExperience = values?.MaxExperience || null;
    newJobDetails.JobAdvanceInfo.SecurityClearance = values?.SecurityClearance;
    newJobDetails.JobAdvanceInfo.SpecialNotes = notes?.replace(/&nbsp;/g, '').trim();
    newJobDetails.Description = this.getJobDescription();
    newJobDetails.Summary = getFormattedInput(this.getJobSummary());
    const compensationType = values?.CompensationType;
    const minSalary = values?.MinSalary || null;
    const maxSalary = values?.MaxSalary || null;
    newJobDetails.JobAdvanceInfo.Salary = {
      CompensationType: compensationType || null,
      MinSalary: minSalary,
      MaxSalary: maxSalary,
      Currency: values?.Currency ?? 'USD',
    };
    const { Client, JobTitle } = newJobDetails;
    const selectedClient = { selectedClient: Client };
    setAdvancedJobFormData({
      jobTitleData: {
        title: JobTitle,
        titleSynonyms: [],
      },
      ...newJobDetails,
      ...selectedClient,
    });
    handleSubmit(newJobDetails, null, submitAction);
  };

  getInsights = (topInsights = [], insights = [], key) => {
    const selectedInsights = _.cloneDeep(topInsights);
    const formattedInsights = topInsights.map(item => item?.[key]?.toLowerCase());
    insights.forEach(ele => {
      if (!formattedInsights.includes(ele?.toLowerCase()) && ele) selectedInsights.unshift({ [key]: ele });
    });
    return selectedInsights;
  };

  handleJobFormEdit = () => {
    const { jobDetails, setAdvancedJobFormData, history } = this.props;
    if (!_.isEmpty(jobDetails)) {
      const { Client, JobTitle } = jobDetails;
      const selectedClient = { selectedClient: Client };
      setAdvancedJobFormData({
        jobTitleData: {
          title: JobTitle,
          titleSynonyms: [],
        },
        ...jobDetails,
        ...selectedClient,
      });
    }
    history.push('/jobs-v2/create');
  };

  validateDescription = (rule, value, callback) => {
    const errorMessage = 'Please enter job description';
    const description = this.getJobDescription();
    const content = description.replace(/<\/?[^>]+(>|$)/g, '');
    const isEnabled = content.replace(/&nbsp;/g, '').trim();
    if (isEnabled) {
      callback();
    } else {
      callback(errorMessage);
    }
  };

  getJobDescription = () => {
    const { form, jobDetails } = this.props;
    const description = form.getFieldValue('Description')?.htmlContent || jobDetails?.Description;
    return description || '';
  };

  getJDSuggestions = () => {
    const { form, jobDetails, getJobDescriptionSuggestions } = this.props;
    const rawSkills = form.getFieldValue('SkillName') || [];
    const skills = [];
    rawSkills.forEach(rawSkill => skills.unshift(rawSkill.SkillName));
    const title = jobDetails?.JobTitle;
    getJobDescriptionSuggestions(title, skills);
  };

  validateSpecialNotes = (rule, value, callback) => {
    const notes = this.getAdditonalNotes();
    const content = notes?.replace(/&nbsp;/g, '').trim();
    if (content?.length > 500) {
      callback('Additional notes cannot be more than 500 characters');
    } else {
      callback();
    }
  };

  validateJobSummary = (rule, value, callback) => {
    const summary = this.getJobSummary();
    const formattedString = validateInputField(summary);
    if (formattedString?.length > 1500) {
      callback('Job Summary cannot be more than 1500 characters');
    } else {
      callback();
    }
  };

  getSkills = (skillsTopInsights = [], niceToHaves = [], mustHaves = []) => {
    const selectedSkills = _.cloneDeep(skillsTopInsights);
    const formattedSkills = skillsTopInsights.map(skill => skill?.SkillName?.toLowerCase());
    niceToHaves.forEach(skill => {
      if (!formattedSkills.includes(skill?.toLowerCase())) selectedSkills.unshift({ SkillName: skill });
    });
    mustHaves.forEach(skill => {
      if (!formattedSkills.includes(skill?.toLowerCase())) selectedSkills.unshift({ SkillName: skill, checked: true });
    });
    return selectedSkills;
  };

  setWarningPopupModalMessage = warningMessage => {
    this.setState({ warningMessage });
  };

  render() {
    const { activePanelKey, warningMessage, isSubmitButtonClicked } = this.state;
    const {
      jobDetails,
      globalInsightSupplyList,
      globalSupplyLocationApiStatus,
      isLoading,
      form,
      editJobStatus,
      candidateSpecifications,
      fetchGlobalInsightSupplyList,
      featureToggleList,
    } = this.props;
    const { getFieldDecorator } = form;
    const { SkillsStats, SimilarTitlesStats, IndustriesStats, ExperienceRangeStats, Total } = globalInsightSupplyList;

    const [skillsTopInsights, moreSkills] = getFormattedInsightsList(SkillsStats, 'SkillName', Total);
    const [rolesTopInsights, moreRoles] = getFormattedInsightsList(SimilarTitlesStats, 'SimilarTitle', Total);
    const [industriesTopInsights, moreIndustries] = getFormattedInsightsList(IndustriesStats, 'IndustryName', Total);
    const [experienceTopInsights, moreExperiences] = getFormattedInsightsList(ExperienceRangeStats, 'Range', Total);
    const checkMenuItems = featureToggleList.JobSummary.IsEnabled ? menuItems : _.omit(menuItems, ['summary']);
    const menuList = Object.values(checkMenuItems);
    const [employmentTypeTopInsights, moreEmploymentTypes] = getFormattedInsightsList(
      candidateSpecifications?.EmploymentType,
      'Name',
      '',
      'employment'
    );
    const [workTypeTopInsights, moreWorkTypes] = getFormattedInsightsList(
      candidateSpecifications?.WorkAuthorization,
      'Name',
      '',
      'work'
    );

    const [educationTopInsights, moreEducationDegrees] = getFormattedInsightsList(
      candidateSpecifications?.EducationDegrees,
      'Name',
      '',
      'degree'
    );

    const showQuickSearch = activePanelKey === '1';
    const showAdvanceSearch = activePanelKey === '2';

    const mustHaves = generateKeywordsFromBooleanString(jobDetails?.MustHaves || '');
    const niceToHaves = generateKeywordsFromBooleanString(jobDetails?.NiceToHaves || '');

    const selectedSkills = getSelectedSkills(mustHaves, niceToHaves, 'SkillName');
    const selectedSynonyms = getActiveInsightList(jobDetails?.Synonyms, 'SimilarTitle');
    const selectedIndustries = getActiveInsightList(jobDetails?.Industries, 'IndustryName');
    const selectedDegrees = getActiveInsightList(jobDetails?.JobAdvanceInfo?.Degree, 'degreeName');
    const selectedWorkTypes = getActiveInsightList(jobDetails?.JobAdvanceInfo?.WorkAuthorization, 'workName');
    const selectedEmploymentTypes = getActiveInsightList(jobDetails?.JobAdvanceInfo?.EmploymentTypes, 'employmentName');

    const minExp = jobDetails?.MinExperience;
    const maxExp = jobDetails?.MaxExperience;
    const securityClearance = jobDetails?.JobAdvanceInfo?.SecurityClearance;

    const notes = this.getAdditonalNotes();
    const summary = this.getJobSummary();
    const description = this.getJobDescription();

    return (
      <Skeleton className={styles.loaderWrapper} active paragraph={{ rows: 12 }} loading={isLoading}>
        <div className={styles.jobDetails}>
          <WarningPopupModal
            warningMessage={warningMessage}
            setWarningPopupModalMessage={this.setWarningPopupModalMessage}
          />
          <span className={styles.title}>{jobDetails?.JobTitle || ''}</span>
          <span className={styles.editIcon} role="presentation" onClick={this.handleJobFormEdit}>
            <JobEditIcon />
          </span>
          <span className={styles.subTitle}>{jobDetails?.Location || ''}</span>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.collapseWrapper}>
            <Collapse
              activeKey={[activePanelKey]}
              accordion
              ghost
              defaultActiveKey={[activePanelKey]}
              onChange={this.handlePanelChange}
            >
              <Panel showArrow={false} header={<span className={styles.affixTitle}>Insights</span>} key="1">
                <GlobalInsightSupplyMenuList menuItems={menuList} />
              </Panel>
              <Panel showArrow={false} header={<span className={styles.affixTitle}>Additional Input</span>} key="2">
                <GlobalInsightSupplyMenuList menuItems={advancedMenuList} />
              </Panel>
            </Collapse>
          </div>
          <div className={styles.locationNotesWrapper} ref={this.locationInsightRef}>
            <AdvancedJobLocationPanel
              jobDetails={jobDetails}
              globalInsightSupplyList={globalInsightSupplyList}
              fetchGlobalInsightSupplyList={fetchGlobalInsightSupplyList}
              globalSupplyLocationApiStatus={globalSupplyLocationApiStatus}
            />
            <div className={showQuickSearch ? styles.show : styles.hide}>
              <AdvancedJobDetailsSelection
                title="Market Skills*"
                subTitle="Quickly compare top skills of similar candidates and make better decisions with fewer mis-hires "
                isMustHave
                showDivider
                allowNewEntry
                id={menuItems.skills.id}
                insightsList={this.getSkills(skillsTopInsights, niceToHaves, mustHaves)}
                addMoreList={_.differenceBy(moreSkills, selectedSkills, 'SkillName')}
                activeInsightsList={selectedSkills}
                insightName="SkillName"
                showAddMore
                form={form}
                getJDSuggestions={this.getJDSuggestions}
              />
              <AdvancedJobDetailsSelection
                title="Similar Roles"
                subTitle="Similar roles and job titles based on your given criteria "
                showDivider
                allowNewEntry
                id={menuItems.roles.id}
                insightsList={this.getInsights(rolesTopInsights, jobDetails?.Synonyms, 'SimilarTitle')}
                addMoreList={_.differenceBy(moreRoles, selectedSynonyms, 'SimilarTitle')}
                activeInsightsList={selectedSynonyms}
                insightName="SimilarTitle"
                showAddMore
                form={form}
              />
              <AdvancedJobDetailsSelection
                title="Industry"
                subTitle="Top industries that hire this role, and sometimes-related roles, based on your criteria "
                showDivider
                id={menuItems.industries.id}
                insightsList={this.getInsights(industriesTopInsights, jobDetails?.Industries, 'IndustryName')}
                addMoreList={_.differenceBy(moreIndustries, selectedIndustries, 'IndustryName')}
                activeInsightsList={selectedIndustries}
                allowNewEntry={industriesTopInsights?.length === 0}
                insightName="IndustryName"
                showAddMore={industriesTopInsights?.length === 0 || moreIndustries?.length > 0}
                form={form}
              />
              <AdvancedJobDetailsSelection
                title="Experience"
                subTitle="Distribution of years of experience among comparable candidates "
                showDivider
                showMinMaxExperience
                minExp={minExp}
                maxExp={maxExp}
                id={menuItems.experience.id}
                activeInsightsList={[{ Range: `${minExp}-${maxExp}` }]}
                insightsList={experienceTopInsights}
                addMoreList={moreExperiences}
                insightName="Range"
                form={form}
              />
              <SalarySelection
                form={form}
                candidateSpecifications={candidateSpecifications}
                jobDetails={jobDetails}
                salaryId={menuItems.salary.id}
              />
              <AdvancedJobDetailsSelection
                title="Educational Relevance"
                subTitle="Choose education level required for this job "
                showDivider
                id={menuItems.education.id}
                insightsList={_.orderBy(educationTopInsights, ['degreeName'], ['asc'])}
                activeInsightsList={selectedDegrees}
                addMoreList={moreEducationDegrees}
                insightName="degreeName"
                form={form}
              />
              {featureToggleList.JobSummary.IsEnabled ? (
                <div id={menuItems.summary.id} className={styles.jobSummary}>
                  <div className={styles.summaryTitle}>Job Summary</div>
                  <FormItem colon={false}>
                    {getFieldDecorator('jobSummary', {
                      rules: [{ validator: this.validateJobSummary }],
                    })(
                      <RichTextEditor
                        showToolbar
                        editorContent={{ htmlContent: summary }}
                        imageSupport={false}
                        placeholder="Please provide the requirements summary"
                      />
                    )}
                  </FormItem>
                  <Divider className={styles.jobSummaryDivider} />
                </div>
              ) : null}
              <div className={styles.jobDescription} id={menuItems.description.id}>
                <JobDescriptionContainer
                  jobForm={form}
                  jobDetails={jobDetails}
                  formProperties={{
                    fieldDecorator: 'Description',
                    fieldLabel: <span className={styles.descriptionTitle}>Job Description*</span>,
                    fieldColon: false,
                    validator: this.validateDescription,
                    initialValue: description || '',
                    isRequired: false,
                  }}
                  recommendationModalInitialValues={{
                    jobTitle: jobDetails?.JobTitle,
                    skills: mustHaves.concat(niceToHaves),
                  }}
                />
                {activePanelKey === '1' && (
                  <div className={styles.advanceSearch}>
                    You can also provide additional parameters to help us find relevant candidates in{' '}
                    <span
                      role="presentation"
                      onClick={() => {
                        this.handlePanelChange('2');
                      }}
                    >
                      Additional Input
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={showAdvanceSearch ? styles.show : styles.hide}>
              <AdvancedJobDetailsSelection
                id={advancedMenuItems.employmentType.id}
                title="Employment Type"
                insightsList={employmentTypeTopInsights}
                addMoreList={moreEmploymentTypes}
                activeInsightsList={selectedEmploymentTypes}
                insightName="employmentName"
                showDivider
                form={form}
              />
              <AdvancedJobDetailsSelection
                id={advancedMenuItems.workAuthorization.id}
                title="Work Authorization"
                insightsList={workTypeTopInsights}
                addMoreList={moreWorkTypes}
                activeInsightsList={selectedWorkTypes}
                insightName="workName"
                showSecurityClearance
                securityClearance={securityClearance}
                showDivider
                form={form}
              />
              <div id={advancedMenuItems.specialNotes.id} className={styles.specialNotes}>
                <div className={styles.notesTitle}>Additional notes</div>
                <FormItem
                  label={
                    <span className={styles.notesSubTitle}>
                      Please list any additional job requirements you may have that we need to take into consideration
                      while sourcing candidates for you
                    </span>
                  }
                  colon={false}
                >
                  {getFieldDecorator('additionalNotes', {
                    rules: [{ validator: this.validateSpecialNotes }],
                  })(
                    <RichTextEditor
                      showToolbar
                      editorContent={{ htmlContent: notes || '' }}
                      imageSupport={false}
                      placeholder="For example: I would prefer candidates who are Certified Scrum master, etc"
                    />
                  )}
                </FormItem>
              </div>
            </div>
          </div>
        </div>
        <JobCreationFooter
          isSubmitButtonClicked={isSubmitButtonClicked}
          content="Please note that the data and insights shown is based on national statistics and market intelligence. The actual sourced candidates are based on a combination of the job created, given parameters and candidate supply."
          handleSubmit={this.submitWithFormValidation}
          isLoading={editJobStatus === 'INPROGRESS'}
        />
      </Skeleton>
    );
  }
}

export { AdvancedJobDetailsEdit as AdvancedJobDetailsEditWithoutForm };
export default Form.create()(AdvancedJobDetailsEdit);
