import React from 'react';
import _ from 'lodash';
import { Form, Button, Icon, Select, message } from 'antd';
import qs from 'query-string';
import './CareerBuilderEdgeLoginForm.scss';
import config from '../../../Config/Config';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

const { baseUrl, redirectUri, clientId } = config.portal.oauth.careerBuilderEdge;

class CareerBuilderEdgeLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
    window.addEventListener('message', this.callback);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.callback);
  }

  callback = e => {
    const { fetchPortalCredentials } = this.props;
    const { search } = e.data;
    if (!_.isEmpty(search)) {
      const parsed = qs.parseUrl(search);
      const { status } = parsed;
      if (status === 'true') {
        message.success('Successfully connected');
        fetchPortalCredentials();
      }
    }
  };

  integrate = () => {
    const { currentUser } = this.props;
    const { email } = currentUser;
    const url = `${baseUrl}response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}/careerbuilder&scope=offline_access&state=${email}`;
    window.open(url, '_blank', 'width=800,height=800');
  };

  handleSubmit(isAuthenticated) {
    const { form, jobBoard, disconnect, index, removeSelectedCountry } = this.props;
    const countryCode = form.getFieldValue(`${jobBoard}countryCode`);
    if (isAuthenticated) {
      disconnect(jobBoard, countryCode, index, removeSelectedCountry);
    } else {
      this.integrate();
    }
  }

  render() {
    const { form, jobBoard, portalSettings, account, index, Countries, countriesMenu, isAuthenticated } = this.props;
    const credentials = account;
    const errorDetail = getJobBoardErrorDetails(credentials, jobBoard);
    return (
      <div className="portal-login-form cb-edge-form">
        <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
          {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
        </div>
        <div className="credentials-container">
          <Form layout="inline">
            <Form.Item>
              <div className="jobboard-country-code">
                {form.getFieldDecorator(`${jobBoard}countryCode`, {
                  initialValue: credentials.CountryCode || (Countries && Countries[0]),
                })(
                  <Select
                    className="jobboard-country-code-select"
                    disabled={isAuthenticated || countriesMenu.length < 2}
                  >
                    {countriesMenu}
                  </Select>
                )}
              </div>
            </Form.Item>

            <Form.Item>
              <div className="authenticate-career">Authenticate Career Builder with Arya</div>
              <div className="authentication">
                This authentication will let you see candidates from Career Builder in Arya
              </div>
            </Form.Item>

            <Form.Item>
              <div className="btn-container">
                <Button
                  // type="primary"
                  className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
                  loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
                  htmlType="submit"
                  onClick={() => this.handleSubmit(isAuthenticated)}
                  shape="round"
                  block
                >
                  {isAuthenticated ? 'Disconnect' : 'Authenticate'}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <div className="error-message">{errorDetail.Message}</div>
        </div>
      </div>
    );
  }
}

export default Form.create()(CareerBuilderEdgeLoginForm);
