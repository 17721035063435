import axios from 'axios';
import { apiV3Client } from './BaseRepository';

export function fetchJobPublishHistory(portal) {
  return apiV3Client.post('/jobs/_distributionhistory', {
    Portals: [portal],
  });
}

export function fetchJobPublishedPortals(jobId, includeDraft, includeRecalled) {
  return apiV3Client.get(
    `/jobs/${jobId}/_distributedPortals?includeDraft=${includeDraft}&includeRecalled=${includeRecalled}`
  );
}

export function fetchJobPortalPublishedInfoWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return (jobId, portal) => {
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV3Client
      .get(`/jobs/${jobId}/portals/${portal}/_distributionInfo`, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c;
        }),
      })
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

export function fetchJobPortalPublishedInfo(jobId, portal) {
  return apiV3Client.get(`/jobs/${jobId}/portals/${portal}/_distributionInfo`);
}

export function distributeJob(jobId, request) {
  return apiV3Client.post(`/jobs/${jobId}/_distribute`, request);
}

export function recallJob(jobId, request) {
  return apiV3Client.post(`/jobs/${jobId}/_recall`, request);
}

export function fetchAvailableMonsterJobInventories(jobId) {
  return apiV3Client.get(`/jobs/${jobId}/portals/monster/_inventories`);
}

export function fetchPortalTariff(portal) {
  return apiV3Client.get(`/jobs/portals/${portal}/_distributionTariff`);
}
