import React from 'react';
import { useSelector } from 'react-redux';
import PulseLoginForm from './PulseLoginForm';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';

import PulseInfoText from './PulseInfoText';
import './PulseLoginPage.scss';

function PulseLoginPage(props) {
  const {
    onHandleChange,
    onSubmit,
    onForgotPassword,
    onSignUpClick,
    username,
    password,
    formContent,
    loading,
    history,
  } = props;

  const generateMagicLinkApiStatus = useSelector(state => getApiStatus(state, 'generateMagicLinkApiStatus'));
  const { loginFormContent } = formContent;

  return (
    <div className="pulse-login-page">
      <PulseInfoText />
      <div className="pulse-login-form-wrapper">
        <div className="pulse-auth-form pulse-signup-form-padding">
          <div className="pulse-auth-logo">
            <img src={`${process.env.PUBLIC_URL}/static/Images/pulse_text_dark_with_arya.svg`} alt="arya pulse logo" />
          </div>
          {generateMagicLinkApiStatus === 'SUCCESS' ? null : (
            <div className="pulse-auth-message">{loginFormContent.message}</div>
          )}
          <PulseLoginForm
            loginFormContent={loginFormContent}
            onHandleChange={onHandleChange}
            onSubmit={onSubmit}
            username={username}
            password={password}
            loading={loading}
            onForgotPassword={onForgotPassword}
            onSignUpClick={onSignUpClick}
            generateMagicLinkApiStatus={generateMagicLinkApiStatus}
            history={history}
          />
        </div>
      </div>
    </div>
  );
}

export default PulseLoginPage;
