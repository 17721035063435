import _ from 'lodash';
import {
  SET_CURRENT_CLIENT_CREATION_INFO,
  CLEAR_CURRENT_CLIENT_CREATION_INFO,
} from '../Actions/ActionCreators/CreateClientActionCreator';

function ClientReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'ADD_CLIENT':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      newState.ById[action.payload.VaultClientId] = action.payload;
      return newState;
    case 'SET_CLIENT_LIST':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      action.payload.forEach(client => {
        newState.ById[client.Id] = client;
      });
      newState.ClientIds = action.payload.map(client => client.Id);
      return newState;
    case 'SET_CLIENT_DETAILS':
      newState = _.cloneDeep(state);
      newState.ById[action.payload.Id] = action.payload;
      return newState;
    case 'UPDATE_CLIENT_DETAILS':
      newState = _.cloneDeep(state);
      action.payload.clientUpdate.forEach(patchElem => {
        newState.ById[action.payload.id][patchElem.path] = patchElem.value;
      });
      return newState;
    case 'SET_CLIENT_COUNT':
      return {
        ...state,
        Total: action.payload,
      };
    case 'SET_ACTIVE_CLIENT_COUNT':
      return {
        ...state,
        TotalActive: action.payload,
      };
    case 'CHANGE_ACTIVE_CLIENT_COUNT':
      newState = _.cloneDeep(state);
      if (action.payload.isClientActivated) {
        newState.TotalActive += 1;
      } else {
        newState.TotalActive -= 1;
      }
      return newState;
    case 'SET_CLIENT_STATUS_API_STATUS':
      newState = _.cloneDeep(state);
      newState.ById[action.payload.id].changeClientStatusApiStatus = action.payload.changeClientStatusApiStatus;
      return newState;
    case 'TOGGLE_ADD_CLIENT_DRAWER':
      return {
        ...state,
        addClientDrawerVisibility: !state.addClientDrawerVisibility,
      };
    case SET_CURRENT_CLIENT_CREATION_INFO:
      return {
        ...state,
        currentClientId: action.payload.Id,
        currentClientName: action.payload.Name,
      };
    case CLEAR_CURRENT_CLIENT_CREATION_INFO:
      return {
        ...state,
        currentClientId: undefined,
        currentClientName: undefined,
      };
    default:
      return state;
  }
}

function getClientsById(state) {
  return state.ClientReducer.ById;
}

function getClientIds(state) {
  return state.ClientReducer.ClientIds || [];
}

function getClientTotal(state) {
  return state.ClientReducer.Total;
}

function getActiveClientTotal(state) {
  return state.ClientReducer.TotalActive;
}

function getAddClientDrawerVisibility(state) {
  return state.ClientReducer.addClientDrawerVisibility;
}
function getCurrentClientId(state) {
  return state.ClientReducer.currentClientId;
}
function getCurrentClientName(state) {
  return state.ClientReducer.currentClientName;
}
export {
  ClientReducer,
  getClientsById,
  getClientIds,
  getClientTotal,
  getActiveClientTotal,
  getAddClientDrawerVisibility,
  getCurrentClientId,
  getCurrentClientName,
};
