/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Input, Button, LocaleProvider, Icon, Tooltip, Form } from 'antd';
import { ForgotPassword } from 'aws-amplify-react';
import * as ConfigActions from '../../Actions/ConfigActions';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { PulseTextWithAryaLogoIcon } from '../../Icons/AryaIcons';
import config from '../../Config/Config';
import AppFooter from '../Footer/Footer';
import AppLogo from './AppLogo';
import './Auth.scss';
import { validateInputField } from '../../Utils/Validators';
import { mapCognitoErrorMessage } from '../../Utils/CognitoApiErrorResponseMapper';
import { getPasswordToolTipText } from '../../Utils/TextUtils';

const mapDispatchToProps = {
  fetchWhiteLabelInfo: ConfigActions.fetchWhiteLabelInfo,
};

const mapStateToProps = state => ({
  whiteLabelInfo: getWhiteLabelInformation(state),
});

class ForgotPasswordForm extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      isCodeSent: false,
      isLoading: false,
      username: '',
      password: '',
      code: '',
      error: null,
      backgroundColor: 'white',
      emailValidationStatus: false,
      passwordValidationStatus: false,
    };

    this._validAuthStates = ['forgotPassword'];
  }

  componentDidMount() {
    const { whiteLabelInfo } = this.props;
    const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
    this.setState({
      backgroundColor: currentBgColor,
    });
  }

  componentDidUpdate(prevProps) {
    const { whiteLabelInfo } = this.props;
    if (prevProps.whiteLabelInfo !== whiteLabelInfo) {
      const currentBgColor = _.get(whiteLabelInfo, ['LoginBackgroundColor'], 'white');
      this.setState({
        backgroundColor: currentBgColor,
      });
    }
  }

  isDisabled = () => {
    const { isCodeSent, username, password, code, emailValidationStatus, passwordValidationStatus, isLoading } =
      this.state;
    return (
      isLoading ||
      emailValidationStatus ||
      !username ||
      (isCodeSent && (!password || !code)) ||
      passwordValidationStatus
    );
  };

  resetState = () => {
    this.setState({
      isCodeSent: false,
      isLoading: false,
      username: '',
      password: '',
      code: '',
      error: null,
      emailValidationStatus: false,
      passwordValidationStatus: false,
    });
  };

  onCancel = () => {
    this.resetState();
    super.changeState('signIn');
  };

  onSubmit = () => {
    const { isCodeSent, username, password, code } = this.state;
    const userEmail = username?.toLowerCase();
    this.setState({ isLoading: true });
    if (isCodeSent) {
      Auth.forgotPasswordSubmit(userEmail, code, password)
        .then(() => {
          this.resetState();
          super.changeState('passwordResetConfirmation');
        })
        .catch(err => {
          this.onError(err);
        });
    } else {
      Auth.forgotPassword(userEmail)
        .then(() => {
          this.setState({ isLoading: false, isCodeSent: true, error: null });
        })
        .catch(err => {
          this.onError(err);
        });
    }
  };

  onError = err => {
    this.setState({
      error: err.code === 'UserNotFoundException' ? null : err,
      isLoading: false,
      isCodeSent: true,
    });
  };

  onHandleChange = (e, type) => {
    const val = e.target.value?.trim() ?? '';
    this.setState({ [e.target.name]: val });
    if (type === 'email') {
      const { error } = this.state;
      const emailValidationStatus = validateInputField(val, 128, 'Email Address');
      this.setState({ emailValidationStatus });
      if (error && error.code === 'UserNotFoundException') {
        this.setState({ error: null });
      }
    }
    if (type === 'password') {
      const passwordValidationStatus = validateInputField(val, null, 'Password');
      this.setState({ passwordValidationStatus });
    }
  };

  emailInput = () => {
    const { username, emailValidationStatus } = this.state;
    return (
      <>
        <p className="auth-label">Email</p>
        <Form>
          <Form.Item validateStatus={emailValidationStatus ? 'error' : 'success'} help={emailValidationStatus}>
            <Input
              id="username"
              key="username"
              name="username"
              required
              onChange={e => {
                this.onHandleChange(e, 'email');
              }}
              className="auth-input"
              placeholder="Email address"
              value={username}
            />
          </Form.Item>
        </Form>
      </>
    );
  };

  codeInput = () => {
    const { code } = this.state;
    return (
      <>
        <p className="auth-label">Code</p>
        <Input
          id="code"
          key="code"
          name="code"
          required
          onChange={this.onHandleChange}
          onPressEnter={this.onSubmit}
          placeholder="Code received on email"
          value={code}
        />
      </>
    );
  };

  resetPasswordInput = () => {
    const { password, passwordValidationStatus } = this.state;
    return (
      <>
        <p className="auth-label">
          New Password&nbsp;&nbsp;
          <Tooltip title={getPasswordToolTipText()}>
            <Icon type="info-circle" />
          </Tooltip>
        </p>
        <Form.Item validateStatus={passwordValidationStatus ? 'error' : 'success'} help={passwordValidationStatus}>
          <Input
            id="password"
            key="password"
            name="password"
            required
            onChange={e => {
              this.onHandleChange(e, 'password');
            }}
            onPressEnter={this.onSubmit}
            type="password"
            className="auth-input"
            placeholder="Enter Password"
            autoComplete="off"
            value={password}
          />
        </Form.Item>
      </>
    );
  };

  getCodePasswordInput = () => {
    const { isCodeSent, error } = this.state;
    const isUserNotFound = error && error.code === 'UserNotFoundException';
    const isCodeMismatch = error && error.code === 'CodeMismatchException';

    return isCodeSent && !isUserNotFound ? (
      <>
        {this.codeInput()}
        {isCodeMismatch ? (
          <p className="error-message">{error?.message}</p>
        ) : (
          <p className="user-message">
            If your email address exists in our database, you will receive a password recovery code at your email
            address in a few minutes.
          </p>
        )}
        {this.resetPasswordInput()}
        {!isCodeMismatch ? <p className="error-message">{error?.message}</p> : null}
      </>
    ) : null;
  };

  showComponent() {
    const { appLocale, location } = window;
    const { hostname } = location;
    const isAppNamePulse = hostname === config.urls.pulseHostName;
    const { isCodeSent, error, backgroundColor, isLoading } = this.state;
    const containerClassName = isAppNamePulse ? 'pulse-auth-container' : 'auth-container';

    return (
      <LocaleProvider locale={appLocale.antd}>
        <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
          <div className={containerClassName} style={{ backgroundColor }}>
            {!isAppNamePulse ? <AppLogo /> : null}
            <div className="auth-form">
              {isAppNamePulse ? (
                <div className="pulse-logo">
                  <PulseTextWithAryaLogoIcon />
                </div>
              ) : null}
              <p className="auth-title">Forgot your password?</p>
              <p className="auth-sub-title">Verify your email</p>
              {this.emailInput()}
              {error && !isCodeSent ? <p className="error-message">{mapCognitoErrorMessage(error)}</p> : null}
              {this.getCodePasswordInput()}
              <Button
                className="auth-primary-button"
                type="primary"
                loading={isLoading}
                disabled={this.isDisabled()}
                onClick={this.onSubmit}
              >
                Submit
              </Button>
              <Button className="auth-secondary-button" onClick={this.onCancel}>
                Cancel
              </Button>
            </div>
            {!isAppNamePulse ? <AppFooter className="app-footer" /> : null}
          </div>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

export { ForgotPasswordForm as ForgotPasswordFormWithoutStore };
