import React from 'react';
import { Row, Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import GlobalInsightList from '../../Components/GlobalInsight/InsightList/GlobalInsightList';
import UserAlerts from '../../Components/UserAlerts/UserAlerts';
import CustomAffixMenu from '../CustomAffixMenu';
import TryNowCandidateSupplyBanner from '../TryNowCandidateSupplyBanner';
import styles from './SampleCandidateSupplyInsight.module.scss';

export function getHeader() {
  return 'Unlock this feature for data driven hiring!';
}

export function getDescription(noStatsFound) {
  return noStatsFound
    ? `See Market Intelligence based on job information, when you select a paid plan.`
    : `Check out Market Intelligence relevant to your given job information. Select a paid plan to get additional insights!`;
}

function SampleCandidateSupplyInsight(props) {
  const {
    supplyPayload,
    globalInsightSupplyList,
    activeMenuList = [],
    inActiveMenuList = [],
    redirectPath = '/select-plan',
    globalSupplyApiStatus,
  } = props;

  const noStatsFound = activeMenuList.length === 0 && globalSupplyApiStatus === 'COMPLETED';
  const isPartialStatsFound = !noStatsFound && activeMenuList.length < 3;

  const header = getHeader();
  const description = globalSupplyApiStatus === 'COMPLETED' ? getDescription(noStatsFound) : '';

  const selectPlan = () => {
    const { history } = props;
    history.push(redirectPath);
  };

  return (
    <div className="user-alert-wrapper" style={{ top: '7%' }}>
      <UserAlerts
        header={header}
        bodyClass={`${styles.alertMessageBody} ${noStatsFound ? styles.alertMessageBodyV3 : ''} ${
          isPartialStatsFound ? styles.alertMessageBodyV2 : ''
        }`}
        outerCircleClass={`${styles.lockOuterCircle} ${noStatsFound ? styles.lockOuterCircleV3 : ''} ${
          isPartialStatsFound ? styles.lockOuterCircleV2 : ''
        }`}
        content={
          <div>
            <div className={styles.selectPlanLockedText}>{description}</div>
            <Button className={styles.selectPlanButton} type="primary" shape="round" onClick={selectPlan}>
              Select a Plan
            </Button>
            <div className={styles.candidateSupplyBanner}>
              <TryNowCandidateSupplyBanner />
              {!noStatsFound ? (
                <div className={styles.candidateSupplyInsights}>
                  <Row gutter={16}>
                    <Col span={4} className={styles.antCol4}>
                      <CustomAffixMenu
                        menuItemId={activeMenuList[0] ? `#${activeMenuList[0].id}` : ''}
                        activeMenuList={activeMenuList}
                      />
                    </Col>
                    <Col span={18} className={`${styles.antCol18} ${isPartialStatsFound ? styles.antCol18V1 : ''}`}>
                      <GlobalInsightList
                        hiddenMenuList={inActiveMenuList}
                        globalInsightSupplyList={globalInsightSupplyList}
                        supplyPayload={supplyPayload}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        }
      />
    </div>
  );
}

export default withRouter(SampleCandidateSupplyInsight);

export { SampleCandidateSupplyInsight as SampleCandidateSupplyInsightWithoutStore };
