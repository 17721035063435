import React from 'react';
import { Modal, Radio, Input, Icon } from 'antd';
import './PullJobsDialog.scss';

export default class PullJobsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobIds: '',
      pullJobSelectType: 'PullByIds',
    };
    this.handlePullJobTypeChange = this.handlePullJobTypeChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.pullJobs = this.pullJobs.bind(this);
  }

  handlePullJobTypeChange(e) {
    this.setState({
      pullJobSelectType: e.target.value,
    });
  }

  handleInputChange(e) {
    this.setState({
      jobIds: e.target.value,
    });
  }

  pullJobs() {
    const { jobIds } = this.state;
    const { onPullJobs } = this.props;
    onPullJobs(jobIds);
    this.setState({
      jobIds: '',
    });
  }

  render() {
    const { visible, handleCancel, AppName } = this.props;
    const { jobIds, pullJobSelectType } = this.state;
    const isEnabled = jobIds.trim().length;
    return (
      <Modal
        width="550px"
        visible={visible}
        title="Pull Job"
        okText="Confirm"
        onOk={this.pullJobs}
        onCancel={handleCancel}
        className="pull-jobs-modal"
        okButtonProps={{ className: 'confirm-button', disabled: !isEnabled }}
        cancelButtonProps={{ className: 'cancel-button' }}
      >
        <div className="pull-jobs-select">
          <Radio.Group defaultValue={pullJobSelectType} onChange={this.handlePullJobTypeChange}>
            {/* <Radio className="pull-all-radio-option" value="PullAll">
              Pull all the jobs
            </Radio> */}
            <Radio className="pull-jobs-radio-option" value="PullByIds">
              Pull job by IDs
            </Radio>
          </Radio.Group>
          <Input
            placeholder="1245, 12355, ..."
            value={jobIds}
            onChange={this.handleInputChange}
            disabled={pullJobSelectType !== 'PullByIds'}
          />
        </div>
        <span className="pull-jobs-user-info">
          <Icon type="info-circle" className="info-icon" />
          {`All the ATS jobs in the ${AppName} will be replaced by the new and modified jobs.`}
        </span>
      </Modal>
    );
  }
}
