import React from 'react';
import { connect } from 'react-redux';
import CandidateTagsTab from '../../Components/Tags/CandidateTagsTab';
import * as candidateActions from '../../Actions/CandidateActions';
import {
  getCandidateGlobalTags,
  getCandidateSuggestedTags,
  getCandidateSuggestedTagsCount,
} from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { setNotification as _setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  candidateSuggestedTags: getCandidateSuggestedTags(state),
  candidateSuggestedTagsCount: getCandidateSuggestedTagsCount(state),
  tagsFetchApiStatus: getApiStatus(state, 'tagsFetchStatusUpdate'),
  fetchCandidateGlobalTagsApiStatus: getApiStatus(state, 'fetchCandidateGlobalTagsApiStatus'),
  suggestedTagsApiStatus: getApiStatus(state, 'suggestedTagsApiStatus'),
  tagCloseApiStatus: getApiStatus(state, 'tagDeleteApiStatus'),
  candidateGlobalTags: getCandidateGlobalTags(state, props.candidate?.Id),
});

const mapDispatchToProps = {
  fetchTagsForCandidate: candidateActions.fetchTagsForCandidate,
  createCandidateTag: candidateActions.createCandidateTag,
  fetchCandidateGlobalTags: candidateActions.fetchCandidateGlobalTags,
  deleteTagForCandidate: candidateActions.deleteTagForCandidate,
  setNotification: _setNotification,
};

function CandidateTagsContainer(props) {
  const {
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    fetchTagsForCandidate,
    tagsFetchApiStatus,
    createCandidateTag,
    jobId,
    candidate,
    fetchCandidateGlobalTags,
    candidateGlobalTags,
    fetchCandidateGlobalTagsApiStatus,
    deleteTagForCandidate,
    tagCloseApiStatus,
    suggestedTagsApiStatus,
    setNotification,

    candidateTagStyle,
  } = props;
  return (
    <CandidateTagsTab
      candidateSuggestedTags={candidateSuggestedTags}
      candidateSuggestedTagsCount={candidateSuggestedTagsCount}
      fetchTagsForCandidate={fetchTagsForCandidate}
      tagsFetchApiStatus={tagsFetchApiStatus}
      createCandidateTag={createCandidateTag}
      jobId={jobId}
      candidate={candidate}
      fetchCandidateGlobalTags={fetchCandidateGlobalTags}
      candidateGlobalTags={candidateGlobalTags}
      fetchCandidateGlobalTagsApiStatus={fetchCandidateGlobalTagsApiStatus}
      deleteTagForCandidate={deleteTagForCandidate}
      tagCloseApiStatus={tagCloseApiStatus}
      suggestedTagsApiStatus={suggestedTagsApiStatus}
      setNotification={setNotification}
      candidateTagStyle={candidateTagStyle}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateTagsContainer);
