import React from 'react';
import { Carousel } from 'antd';
import { AnnouncementItem } from './AnnouncementItem';
import styles from './AnnouncementCarousel.module.scss';

export const AnnouncementCarousel = props => {
  const { onClose, announcementItems } = props;
  const carouselProps = {
    customPaging: () => {
      return <div className={styles.aryaPagination} />;
    },
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  return (
    <Carousel {...carouselProps} className={styles.aryaBannerCarousel}>
      {announcementItems.map(item => (
        <AnnouncementItem key={item.FeatureId} feature={item} onClose={onClose} />
      ))}
    </Carousel>
  );
};
