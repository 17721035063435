const INVALID_CVV = 'INVALID_CVV';
const CONFLICTING_CREATEPAYMENTCOMMAND = 'CONFLICTING_CREATEPAYMENTCOMMAND';
const DUPLICATE_TRANSACTION = 'DUPLICATE_TRANSACTION';
const AUTHORIZENET_GATEWAY_ERROR = 'AUTHORIZENET_GATEWAY_ERROR';
const FAILED = 'FAILED';

function mapAryaPayApiErrorCode(error) {
  const status = error?.response?.status;
  let errorData = {
    statusType: FAILED,
    errorMessage: '',
  };
  const errorResponse = error?.response?.data || {};
  const appCode = errorResponse?.ErrorCode;
  switch (status) {
    case 400:
      if (appCode === INVALID_CVV) {
        errorData = {
          statusType: INVALID_CVV,
          errorMessage: error?.response?.data?.Details?.Message,
        };
      } else {
        errorData = {
          statusType: appCode,
          errorMessage: '',
        };
      }
      break;
    case 409:
      if (appCode === CONFLICTING_CREATEPAYMENTCOMMAND) {
        const errorMessage = error?.response?.data?.ErrorMessage;
        if (errorMessage.includes('duplicate transaction has been submitted')) {
          errorData = {
            statusType: DUPLICATE_TRANSACTION,
            errorMessage: '',
          };
        } else {
          errorData = {
            statusType: CONFLICTING_CREATEPAYMENTCOMMAND,
            errorMessage: '',
          };
        }
      }
      break;
    case 500:
      if (appCode === AUTHORIZENET_GATEWAY_ERROR) {
        errorData = {
          statusType: AUTHORIZENET_GATEWAY_ERROR,
          errorMessage: error?.response?.data?.Details?.Message,
        };
      }
      break;
    default:
      break;
  }
  return errorData;
}
export { mapAryaPayApiErrorCode };
