import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import ContactProviders from '../../Components/ContactProviders/ContactProviders';
import * as connectSettingActions from '../../Actions/ConnectSettingsActions';

const mapStateToProps = state => ({
  contactProviderAuthenticationApiStatus: getApiStatus(state, 'contactProviderAuthenticationApiStatus'),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  authenticateContactProvider: connectSettingActions.authenticateContactProvider,
  disconnectContactProvider: connectSettingActions.disconnectContactProvider,
};

function ContactProvidersContainer(props) {
  const {
    contactProviderAuthenticationApiStatus,
    authenticateContactProvider,
    disconnectContactProvider,
    data,
    onChange,
    whiteLabelInfo,
  } = props;
  return (
    <ContactProviders
      data={data}
      onChange={onChange}
      contactProviderAuthenticationApiStatus={contactProviderAuthenticationApiStatus}
      authenticateContactProvider={authenticateContactProvider}
      disconnectContactProvider={disconnectContactProvider}
      whiteLabelInfo={whiteLabelInfo}
    />
  );
}

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(ContactProvidersContainer));
