export function capitalizeAndFormat(words) {
  if (!words) {
    return words;
  }
  let wordArray;
  if (words) {
    wordArray = words.split('/');
  }
  for (let i = 0; i < wordArray.length; i += 1) {
    const subword = wordArray[i].trim().split(' ');
    for (let j = 0; j < subword.length; j += 1) {
      subword[j] = subword[j][0].toUpperCase() + subword[j].slice(1);
    }
    wordArray[i] = subword.join(' ');
  }
  return wordArray.join(', ');
}
export function getFilteredText(text) {
  const regexHtml = /(<([^>]+)>)/gi;
  const removedHtmlTagText = text?.trim()?.replace(regexHtml, ' ');
  const escapeCharactersRegexList = [/&nbsp;/gi, /&lt;/gi, /&gt;/gi, /&amp;/gi];
  let filteredText = removedHtmlTagText;
  for (let i = 0; i < escapeCharactersRegexList?.length; i += 1) {
    filteredText = filteredText?.trim()?.replace(escapeCharactersRegexList[i], '');
  }
  return filteredText;
}
