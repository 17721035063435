const homePage = {
  viewHomePage: 'HOME_PAGE_VIEW',
};

const homeEventTypes = {
  homePage,
};

export const homeModules = {
  viewHomePage: 'Home Page Viewed',
};

export default homeEventTypes;
