import { useEffect } from 'react';

export default function useClickOutside(wrapperRef, callback) {
  useEffect(() => {
    function handleClick(e) {
      if (!wrapperRef?.current?.contains(e.target) && callback && document.getSelection()?.toString().length === 0) {
        callback(e);
      }
    }
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [callback, wrapperRef]);
}
