import React from 'react';
import { connect } from 'react-redux';
import { Form, Pagination } from 'antd';
import GroupList from './GroupList/GroupList';
import styles from './ListOfLists.module.scss';
import ListFormComponent from './ListFormComponent/ListFormComponent';
import * as JobActions from '../../Actions/JobActions';
import {
  getSegmentList,
  getJobListPageNumber,
  getJobListPageSize,
  getJobListSearchTerm,
  getJobAutomationConfiguration,
} from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as UserActions from '../../Actions/UserActions';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getRecruiterDropdownSelectedOrg } from '../../Reducers/UserReducer';

const mapStateToProps = state => ({
  segmentList: getSegmentList(state),
  pageSize: getJobListPageSize(state),
  page: getJobListPageNumber(state),
  searchTerm: getJobListSearchTerm(state, 'segment'),
  fetchSegmentListApiStatus: getApiStatus(state, 'fetchSegmentListApiStatus'),
  associatedTemplateName: getJobAutomationConfiguration(state),
  selectedOrgId: getRecruiterDropdownSelectedOrg(state),
  userConfig: getConfig(state) || {},
});
const mapDispatchToProps = {
  createSegment: JobActions.createSegment,
  fetchSegmentList: JobActions.fetchSegmentList,
  editSegment: JobActions.putEditSegment,
  setJobListPageSize: JobActions.setJobListPageSize,
  setJobListPageNumber: JobActions.setJobListPageNumber,
  setJobListSearchTerm: JobActions.setJobListSearchTerm,
  fetchJobAutomationConfiguration: JobActions.fetchJobAutomationConfiguration,
  fetchUsersForRecruiterSelect: UserActions.fetchUsersForDropdownLists,
  fetchJobList: JobActions.fetchJobList,
};

function ListOfLists(props) {
  const {
    form,
    createSegment,
    setJobListPageSize,
    pageSize,
    searchTerm,
    setJobListPageNumber,
    fetchSegmentList,
    segmentList,
    editSegment,
    page,
    setJobListSearchTerm,
    fetchSegmentListApiStatus,
    fetchJobAutomationConfiguration,
    associatedTemplateName,
    onSegmentCreationCallback,
    version,
    fetchUsersForRecruiterSelect,
    userConfig,
    selectedOrgId,
    fetchJobList,
    history,
  } = props;
  const segmentDetails = {};
  const getJobListFetchPayload = ({ isJobStatsRequired, _page, _size, _searchTerm }) => {
    return {
      count: _size,
      skip: _page >= 1 ? (_page - 1) * _size : 0,
      searchTerm: _searchTerm,
      isJobStatsRequired,
      EntityType: 'Segment',
      isAssigned: true,
    };
  };
  const _fetchJobList = (isFetchJobsCount, _page, _size, _searchTerm) => {
    const jobListFetchPayload = getJobListFetchPayload({
      isJobStatsRequired: isFetchJobsCount,
      _page,
      _size,
      _searchTerm,
    });
    fetchSegmentList(jobListFetchPayload);
  };
  const onSearchChange = async event => {
    const _searchTerm = event.target.value;
    await setJobListPageNumber(1);
    await setJobListSearchTerm(_searchTerm, 'segment');
    if (!_searchTerm) {
      _fetchJobList(true, page, pageSize, _searchTerm);
    }
  };
  const onSearch = value => {
    if (value.length) {
      _fetchJobList(true, page, pageSize, value);
    }
  };
  const onShowSizeChange = async size => {
    await setJobListPageNumber(1);
    await setJobListPageSize(size);
    _fetchJobList(true, 1, size, searchTerm);
  };
  const onPageChange = async _page => {
    await setJobListPageNumber(_page);
    _fetchJobList(true, _page, pageSize);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    _fetchJobList(true, page, pageSize, searchTerm);
    if (!userConfig.IsManagementUser) {
      fetchUsersForRecruiterSelect({ page: 1, searchTerm: null, listFor: 'RecruiterSelect' });
    } else {
      fetchUsersForRecruiterSelect({ page: 1, searchTerm: null, listFor: 'RecruiterSelect' }, selectedOrgId);
    }
  }, []);

  return (
    <div className={styles.jobList}>
      <ListFormComponent
        form={form}
        createSegment={createSegment}
        segmentDetails={segmentDetails}
        onChange={onSearchChange}
        onSearch={onSearch}
        searchTerm={searchTerm}
        onSegmentCreationCallback={onSegmentCreationCallback}
      />
      <GroupList
        form={form}
        segmentList={segmentList}
        editSegment={editSegment}
        segmentDetails={segmentDetails}
        createSegment={createSegment}
        fetchSegmentListApiStatus={fetchSegmentListApiStatus}
        fetchJobAutomationConfiguration={fetchJobAutomationConfiguration}
        associatedTemplateName={associatedTemplateName}
        onSegmentCreationCallback={onSegmentCreationCallback}
        version={version}
        history={history}
      />
      <div className={styles.paginationContainer}>
        <Pagination
          showSizeChanger
          size="small"
          pageSize={pageSize}
          total={segmentList?.totalCount}
          onChange={onPageChange}
          onShowSizeChange={(current, size) => onShowSizeChange(size)}
          hideOnSinglePage={segmentList?.totalCount <= 10}
          current={page}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ListOfLists));
export { ListOfLists };
