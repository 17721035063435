import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

export const selectComponent = (
  form,
  label,
  fieldDecorator,
  initialValue,
  placeHolder,
  optionsList,
  rules,
  disabled
) => {
  return (
    <Form.Item label={label} colon={false}>
      {form.getFieldDecorator(fieldDecorator, {
        rules,
        initialValue,
      })(
        <Select showSearch placeholder={placeHolder} disabled={disabled}>
          {optionsList.map(option => (
            <Option key={option} value={option} title={option}>
              {option}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};
