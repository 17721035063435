import React from 'react';
import { Button } from 'antd';
import Config from '../../../Config/Config';
import styles from './ConnectV2Redirect.module.scss';

function ConnectV2Redirect(props) {
  const { isVisible } = props;
  if (!isVisible) return null;
  return (
    <div className={styles.connectV2Redirect}>
      <div className={styles.connectDescription}>Check out latest Connect 2.0</div>
      <Button shape="round" type="primary" href={Config.urls.aryaConnectV2Client} target="_blank">
        Launch
      </Button>
    </div>
  );
}

export default ConnectV2Redirect;
