import { connectV1Client, apiV3Client } from './BaseRepository';

export function getEmailTemplates(from, size) {
  return connectV1Client.get('emailTemplates', {
    params: {
      from,
      size,
    },
  });
}

export function searchEmailTemplates(filter) {
  return connectV1Client.post('emailTemplates/_search', filter);
}

export function searchSystemEmailTemplates(filter) {
  return apiV3Client.post(`systememailtemplates/_search`, filter);
}
export function putSystemEmailTemplate(templateId, template) {
  return apiV3Client.put(`systememailtemplates/${templateId}`, template);
}
export function postEmailTemplate(template) {
  return connectV1Client.post('emailTemplates', template);
}

export function getEmailTemplate(templateId) {
  return connectV1Client.get(`emailTemplates/${templateId}`);
}

export function deleteEmailTemplate(templateId) {
  return connectV1Client.delete(`emailTemplates/${templateId}`);
}

export function putEmailTemplate(templateId, template) {
  return connectV1Client.put(`emailTemplates/${templateId}`, template);
}

export function patchEmailTemplate(templateId, template) {
  return connectV1Client.patch(`emailTemplates/${templateId}`, {
    data: template,
  });
}

export function duplicateEmailTemplate(templateId) {
  return connectV1Client.post(`emailTemplates/${templateId}/_duplicate`);
}

export function duplicateBulkEmailTemplates(bulkDuplicateEmailTemplateRequest) {
  return connectV1Client.post(`emailTemplates/_duplicate`, bulkDuplicateEmailTemplateRequest);
}

export function shareEmailTemplate(templateId, shareOptions) {
  return connectV1Client.post(`emailTemplates/${templateId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
    IsShareWithAll: shareOptions.isShareWithAll,
    IsUnshareWithAll: shareOptions.isUnshareWithAll,
  });
}

export function testEmailTemplate(Subject, Body) {
  return apiV3Client.post(`UserActivity/_testemail`, {
    Subject,
    Body,
  });
}
