import { isPulseUser } from './ConfigUtils';

function getExcludedMergeTagKeysAryaPulse() {
  return ['Email.Signature', 'Candidate.JobViewURL', 'Candidate.JobApplyURL', 'Job.Id'];
}

export function getFilteredMergeTagsAryaPulse(mergeTags = []) {
  const excludedMergeTagKeys = getExcludedMergeTagKeysAryaPulse();
  return mergeTags.filter(({ Key }) => !excludedMergeTagKeys.includes(Key));
}

export function getExcludedMergeTagKeys(featureToggleList = {}, version, jobDetails) {
  // Remove Job.Company from supported MergeTags
  const excludedMergeTagKeys = ['Job.Company'];
  const { CustomizeJobUrl, JobSummary, WhiteGloveServiceProgress, PaidJobService, CustomizeJobNotInterestedUrl } =
    featureToggleList;
  const isAdvancedJob = jobDetails?.IsAdvancedJob;
  const isJobSummaryEnabled = JobSummary?.IsEnabled && (!isPulseUser() || isAdvancedJob);
  if (CustomizeJobUrl?.IsEnabled) {
    excludedMergeTagKeys.push('Candidate.JobApplyURL');
  }
  if (!CustomizeJobNotInterestedUrl?.IsEnabled) {
    excludedMergeTagKeys.push('Candidate.JobNotInterestedURL');
  }
  if (!isJobSummaryEnabled || version === 'ats') {
    excludedMergeTagKeys.push('Job.Summary');
  }
  if (PaidJobService?.IsEnabled && !WhiteGloveServiceProgress?.IsEnabled) {
    excludedMergeTagKeys.push('Candidate.DownloadedSource');
  }
  return excludedMergeTagKeys;
}

export function getFilteredMergeTags(mergeTags = [], featureToggleList, version, jobDetails) {
  const excludedMergeTagKeys = getExcludedMergeTagKeys(featureToggleList, version, jobDetails);
  return mergeTags.filter(({ Key }) => !excludedMergeTagKeys.includes(Key));
}

export function getEmailMergeTags({ mergeTags }) {
  const emailSubjectMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
  const emailBodyMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'));
  const emailPreHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));
  return { emailSubjectMergeTags, emailBodyMergeTags, emailPreHeaderMergeTags };
}
