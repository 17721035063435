import { defineMessages } from 'react-intl';

const messages = defineMessages({
  textTemplates: {
    id: 'Templates.Text.textTemplates',
    defaultMessage: 'Text Templates',
  },
  searchTextTemplates: {
    id: 'Templates.Text.searchTextTemplates',
    defaultMessage: 'Search Text Templates',
  },
  template: {
    id: 'Templates.Text.Heading.template',
    defaultMessage: 'TEMPLATE',
  },
  updatedOn: {
    id: 'Templates.Text.Heading.updatedOn',
    defaultMessage: 'UPDATED ON',
  },
  includesBot: {
    id: 'Templates.Text.Heading.includesBot',
    defaultMessage: 'INCLUDES BOT',
  },
  shared: {
    id: 'Templates.Text.Heading.shared',
    defaultMessage: 'SHARED',
  },
  createdBy: {
    id: 'Templates.Text.Heading.createdBy',
    defaultMessage: 'CREATED BY',
  },
  myTemplates: {
    id: 'Templates.Text.Tab.myTemplates',
    defaultMessage: 'My Templates',
  },
  sharedTemplates: {
    id: 'Templates.Text.Tab.sharedTemplates',
    defaultMessage: 'Shared Templates',
  },
  textTemplate: {
    id: 'Templates.Text.textTemplate',
    defaultMessage: 'Text Template',
  },
  textTemplateName: {
    id: 'Templates.Text.textTemplateName',
    defaultMessage: 'Text Template Name',
  },
});

export default messages;
