import _ from 'lodash';

export const defaultTemplate = `<h3><strong>{item_heading}:</strong></h3><ul>{replace_items}</ul>`;

export const parseHtmlDescriptionForData = htmlDescription => {
  const requirements = [];
  const responsibilities = [];
  if (!htmlDescription) return { requirements, responsibilities };
  // eslint-disable-next-line no-undef
  const domParser = new DOMParser();
  const htmlDoc = domParser.parseFromString(htmlDescription, 'text/html');
  if (!htmlDoc) return { requirements, responsibilities };
  const requirementsHtmlElements = htmlDoc.getElementsByTagName('requirement');
  Array.from(requirementsHtmlElements).forEach(htmlElement =>
    requirements.push(htmlElement.innerText || htmlElement.textContent)
  );
  const responsibilityHtmlElements = htmlDoc.getElementsByTagName('responsibility');
  Array.from(responsibilityHtmlElements).forEach(htmlElement =>
    responsibilities.push(htmlElement.innerText || htmlElement.textContent)
  );
  return { requirements, responsibilities };
};

export const getItemListForDisplay = (suggestedItems, initialDisplayItems, selectedItems) => {
  const nonSelectedItemsfromSuggestions = _.differenceBy(suggestedItems, selectedItems, ele => ele.toLowerCase());
  const filteredIntialDisplayItems = _.differenceBy(initialDisplayItems, nonSelectedItemsfromSuggestions, ele =>
    ele.toLowerCase()
  );
  return _.unionBy(filteredIntialDisplayItems, selectedItems, ele => ele.toLowerCase());
};

export const getHtmlForItems = (items, itemName) => {
  if (!items?.length) return '';
  let itemsHtml = '';
  items.forEach(item => {
    itemsHtml = `${itemsHtml}<li><${itemName}>${item}</${itemName}></li>`;
  });
  return itemsHtml;
};

export const appendOrReplaceItemsForHtmlDescription = (
  htmlDescription,
  parsedItems,
  suggestedItems,
  selectedItems,
  itemName
) => {
  let modifiedHtmlDescription = htmlDescription;
  const itemHeading = itemName === 'requirement' ? 'Requirements' : 'Responsibilities';
  if (parsedItems.length === 0) {
    const itemsHtml = getHtmlForItems(selectedItems, itemName);
    if (itemsHtml)
      modifiedHtmlDescription = `${modifiedHtmlDescription}${defaultTemplate
        .replace('{item_heading}', itemHeading)
        .replace('{replace_items}', itemsHtml)}`;
  } else {
    const finalItemsForDisplay = getItemListForDisplay(suggestedItems, parsedItems, selectedItems);
    const itemsHtml = getHtmlForItems(finalItemsForDisplay, itemName);
    let itemTagsRegex = `<ul>((<li><br></li>)*?)<li><${itemName}>(.*?)</${itemName}></li>((<li><br></li>)*?)</ul>`;
    if (itemsHtml) {
      const re = new RegExp(itemTagsRegex);
      modifiedHtmlDescription = modifiedHtmlDescription.replace(re, `<ul>${itemsHtml}</ul>`);
    } else {
      itemTagsRegex = `<h3><strong>${itemHeading}:</strong></h3>${itemTagsRegex}`;
      const re = new RegExp(itemTagsRegex);
      modifiedHtmlDescription = modifiedHtmlDescription.replace(re, '');
    }
  }
  return modifiedHtmlDescription;
};

export const getHtmlDescriptionForDisplay = (htmlDescription, data) => {
  let finalizedHtmlDescriptionForDisplay = htmlDescription;
  const parsedReqsAndResFromDescription = parseHtmlDescriptionForData(htmlDescription);
  finalizedHtmlDescriptionForDisplay = appendOrReplaceItemsForHtmlDescription(
    finalizedHtmlDescriptionForDisplay,
    parsedReqsAndResFromDescription.requirements,
    data.suggestedValues.requirements,
    data.selectedValues.requirements,
    'requirement'
  );
  finalizedHtmlDescriptionForDisplay = appendOrReplaceItemsForHtmlDescription(
    finalizedHtmlDescriptionForDisplay,
    parsedReqsAndResFromDescription.responsibilities,
    data.suggestedValues.responsibilities,
    data.selectedValues.responsibilities,
    'responsibility'
  );
  return finalizedHtmlDescriptionForDisplay;
};

export const mergeLocalAndApiParserData = (localParsedData, apiParsedData) => {
  const parsingDataModel = {
    Requirements: localParsedData.requirements,
    Responsibilities: localParsedData.responsibilities,
    Benefits: apiParsedData.Benefits,
    Others: apiParsedData.Others,
  };
  if (localParsedData.requirements.length === 0) parsingDataModel.Requirements = apiParsedData.Requirements;
  if (localParsedData.responsibilities.length === 0) parsingDataModel.Responsibilities = apiParsedData.Responsibilities;
  return parsingDataModel;
};
