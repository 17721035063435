import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Button, Collapse } from 'antd';
import qs from 'query-string';
import PubSub from 'pubsub-js';
import { withRouter, Prompt } from 'react-router-dom';
import PaymentInformation from '../../Components/Payment/PaymentInfo';
import PaymentBillingInfo from '../../Components/Payment/PaymentBillingInfo';
import PaymentPlaceOrder from '../../Components/Payment/PaymentPlaceOrder';
import getDeleteCardModalContent from '../../Components/Payment/DeleteCardModalContent';
import { ProgressSteps } from '../PlanSelection/PlanSelectionModules';
import DeletePaymentCardModal from '../../Components/CustomModal/CustomModal';
import * as PaymentActions from '../../Actions/AryaPayActions';
import * as JobActions from '../../Actions/JobActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as UserActions from '../../Actions/UserActions';
import { getCurrentUser, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { getUsersByGuId, getUserLocationUtilities } from '../../Reducers/UserReducer';
import { getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { PURCHASE } from '../../PubSub/EventTypes';
import { getProductVariantsByName } from '../../Utils/PlanSelectionUtils';

import {
  getAddPaymentProfileApiStatus,
  getCreateOrderApiStatus,
  getPaymentProfile,
  getPaymentProfilesById,
  getPaymentApiStatus,
  getOrderInvoiceNumber,
  getUpdateBillingInfoApiStatus,
  getCreateOrderApiErrorMessage,
} from '../../Reducers/AryaPayReducer';
import { getJobUtilities, getJobsById, getJobGuidToIdMapping } from '../../Reducers/JobReducer';
import './Payment.scss';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import { getAvailableCreditsById, getTotalPaidCredits, getFreeJobCreditsCount } from '../../Utils/CreditInfoUtils';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getStringWithoutWhiteSpaces } from '../../Utils/TextUtils';
import { getJobDetailsForAnalytics, getIsFreeCandidateStepper } from '../../Utils/JobUtils';
import {
  getProductVariants,
  getPlanDetailsByProductVariantId,
  getTotalAmountForProductvariant,
  getProductVariantsById,
  getOrderDetails,
  getOrderDetailswithTax,
} from '../../Reducers/AryaPayOrderReducer';
import PaymentCompanyInfoPanel from '../../Components/Payment/PaymentCompanyInfoPanel';

const { Panel } = Collapse;

const mapStateToProps = state => ({
  currentUserDetails: getCurrentUserDetails(state),
  createOrderApiStatus: getCreateOrderApiStatus(state),
  createOrderApiErrorMessage: getCreateOrderApiErrorMessage(state),
  paymentApiStatus: getPaymentApiStatus(state),
  paymnetProfileApiStatus: getApiStatus(state, 'paymnetProfileApiStatus'),
  paymentProfile: getPaymentProfile(state),
  paymentProfilesById: getPaymentProfilesById(state),
  savePaymentProfileApiStatus: getAddPaymentProfileApiStatus(state),
  updateBillingInfoApiStatus: getUpdateBillingInfoApiStatus(state),
  invoiceNumber: getOrderInvoiceNumber(state),
  jobUtilities: getJobUtilities(state),
  locationApiStatus: getApiStatus(state, 'locationApiStatus'),
  deletePaymentCardApiStatus: getApiStatus(state, 'deletePaymentCardApiStatus'),
  currentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
  productVariants: getProductVariants(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  productVariantesById: getProductVariantsById(state),
  totalAmount: getTotalAmountForProductvariant(state),
  getAmountApiStatus: getApiStatus(state, 'fetchTotalAmountForProductvariantWithTaxApiStatus'),
  getAmountWithoutTaxApiStatus: getApiStatus(state, 'fetchTotalAmountForProductvariantWithoutTaxApiStatus'),
  planDetailsByProductVariantIdApiStatus: getApiStatus(state, 'fetchPlanDetailsByProductVariantIdApiStatus'),
  jobsById: getJobsById(state),
  jobGuidToIdMapping: getJobGuidToIdMapping(state),
  usersByGuId: getUsersByGuId(state),
  userUtilities: getUserLocationUtilities(state),
  orderDetails: getOrderDetails(state),
  orderDetailsWithTax: getOrderDetailswithTax(state),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
});

const mapDispatchToProps = {
  createOrder: PaymentActions.createOrder,
  fetchPaymentProfile: PaymentActions.fetchPaymentProfile,
  savePaymentDetails: PaymentActions.savePaymentDetails,
  fetchLocations: JobActions.fetchLocations,
  fetchZipCodes: JobActions.fetchZipCodes,
  resetZipcodes: JobActions.resetZipcodes,
  clearZipValidationError: JobActions.clearZipValidationError,
  searchZipCode: JobActions.searchZipCode,
  clearZipCodeError: JobActions.clearZipCodeError,
  resetJobUtilities: JobActions.setJobUtilities,
  setTrynowFormData: JobActions.setTrynowFormData,
  fetchJobs: JobActions.fetchJobs,
  fetchJobDetails: JobActions.fetchJobDetails,
  setCreateOrderApiStatus: PaymentActions.setCreateOrderApiStatus,
  updateBillingInfo: PaymentActions.updateBillingInfo,
  resetAryaPayApiStatuses: PaymentActions.resetAryaPayApiStatuses,
  deletePaymentCard: PaymentActions.deletePaymentProfile,
  clearDeletePaymentCardApiStatus: PaymentActions.clearDeletePaymentCardApiStatus,
  resetSavePaymentDetailApiStatus: PaymentActions.resetSavePaymentDetailApiStatus,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  resetGetOrderApiStatus: PayOrderActions.resetGetOrderAmountApiStatus,
  resetTotalAmountForProductvariant: PayOrderActions.resetTotalAmountForProductvariant,
  setCreateOrderApiStatusPending: PaymentActions.setCreateOrderApiStatusPending,
  fetchUsers: UserActions.fetchUsers,
  updateUserDetails: UserActions.updateUserDetails,
  fetchUserZipCodes: UserActions.fetchZipCodes,
  fetchUserLocations: UserActions.fetchLocations,
  fetchTotalAmountForProductvariants: PayOrderActions.fetchTotalAmountForProductvariants,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
};

class Payment extends React.Component {
  constructor(props) {
    super(props);
    const isAddressAvaliable = this.getIsUserFullAddressAvaliable(props?.currentUserDetails);
    this.state = {
      cardDetails: {},
      selectedPaymentProfileId: null,
      orderModalVsibility: false,
      deleteCardModalVisibility: false,
      paymentMode: 'AryaJobCredit',
      isPaymentAlreadyCompleted: false,
      companySettingVisibilty: false,
      bundlePlanModalVisibility: false,
      activePanelKey: isAddressAvaliable ? '2' : '1',
    };
    this.callback = this.callback.bind(this);
  }

  async componentDidMount() {
    const {
      fetchPaymentProfile,
      location,
      fetchPlanDetailsByProductVariantId,
      fetchJobs,
      fetchUsers,
      currentUser,
      fetchTotalAmountForProductvariants,
      setSampleCandidatesDisplayFlag,
      currentUserDetails,
    } = this.props;
    window.addEventListener('beforeunload', this.callback);
    const { variantId, jobGuid, premium, basic } = qs.parse(location.search);
    const isAddressAvailable = this.getIsUserFullAddressAvaliable(currentUserDetails);
    setSampleCandidatesDisplayFlag();
    fetchPaymentProfile();
    if (premium || basic) {
      if (premium) {
        const premiumCount = parseInt(premium, 10);
        this.setState({ premiumCount });
      }
      if (basic) {
        const basicCount = parseInt(basic, 10);
        this.setState({ basicCount });
      }
      this.setState({ orderType: 'future' });
      const items = this.getItemList();
      if (isAddressAvailable) {
        await fetchTotalAmountForProductvariants({
          items,
          IsTaxCalcuationRequired: true,
        });
      }
      fetchTotalAmountForProductvariants({
        items,
        IsTaxCalcuationRequired: false,
      });
    } else {
      this.setState({ orderType: 'immediate' });
      if (jobGuid) {
        fetchJobs({
          jobGuids: [jobGuid],
        });
      }
      fetchPlanDetailsByProductVariantId();
      fetchUsers({
        userGuids: [currentUser.sub],
      });
      if (isAddressAvailable) {
        await fetchTotalAmountForProductvariants({
          items: [{ ProductVariantId: variantId, Quantity: 1 }],
          IsTaxCalcuationRequired: true,
          isSingleJob: true,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.callback);
    const { resetAryaPayApiStatuses, resetTotalAmountForProductvariant } = this.props;
    resetAryaPayApiStatuses();
    resetTotalAmountForProductvariant();
  }

  callback = e => {
    e.preventDefault();
    e.returnValue = 'Are you sure you want to leave?';
  };

  async componentDidUpdate(prevProps) {
    const { location: prevlocation } = prevProps;
    const {
      deletePaymentCardApiStatus,
      location,
      fetchTotalAmountForProductvariants,
      planDetailsByProductVariantId,
      planDetailsByProductVariantIdApiStatus,
    } = this.props;
    const nextDeletePaymentCardApiStatus = _.get(deletePaymentCardApiStatus, ['status']);
    const prevDeletePaymentCardApiStatus = _.get(prevProps, ['deletePaymentCardApiStatus', 'status']);

    if (prevDeletePaymentCardApiStatus !== 'COMPLETED' && nextDeletePaymentCardApiStatus === 'COMPLETED') {
      this.onDeletionSuccess();
    }

    const { premium: prevpremium, basic: prevbasic } = qs.parse(prevlocation.search);
    const { premium, basic, variantId } = qs.parse(location.search);
    if ((premium || basic) && (prevpremium !== premium || prevbasic !== basic)) {
      const items = this.getItemList();

      await fetchTotalAmountForProductvariants({
        items,
        IsTaxCalcuationRequired: true,
      });
      fetchTotalAmountForProductvariants({
        items,
        IsTaxCalcuationRequired: false,
      });
    }

    if (
      !premium &&
      !basic &&
      planDetailsByProductVariantIdApiStatus === 'COMPLETED' &&
      planDetailsByProductVariantIdApiStatus !== prevProps.planDetailsByProductVariantIdApiStatus
    ) {
      const selectedPlanAvailableCredits = getAvailableCreditsById(planDetailsByProductVariantId, variantId);
      // eslint-disable-next-line react/no-did-update-set-state
      if (selectedPlanAvailableCredits) this.setState({ paymentMode: 'AryaJobCredit' });
    }
  }

  toggleBundlePlanModalVisibility = () => {
    const { bundlePlanModalVisibility } = this.state;
    this.setState({
      bundlePlanModalVisibility: !bundlePlanModalVisibility,
    });
  };

  getItemList = () => {
    const { location, productVariantesById } = this.props;
    const { premium, basic } = qs.parse(location.search);
    const product = getProductVariantsByName(productVariantesById);
    const items = [];
    let premiumCount = 0;
    let basicCount = 0;
    if (premium) {
      premiumCount = parseInt(premium, 10);
    }
    if (basic) {
      basicCount = parseInt(basic, 10);
    }
    if (basic && basicCount > 0) {
      items.push({
        productvariantId: product.Basic.Id,
        quantity: basicCount,
      });
    }
    if (premium && premiumCount > 0) {
      items.push({
        productvariantId: product.Premium.Id,
        quantity: premiumCount,
      });
    }
    return items;
  };

  updateAddress = async companyInfo => {
    const { location, updateUserDetails, fetchTotalAmountForProductvariants, fetchPlanDetailsByProductVariantId } =
      this.props;
    const { orderType } = this.state;
    const { variantId } = qs.parse(location.search);
    await updateUserDetails(companyInfo);
    this.handlePanelChange('2');
    if (orderType === 'future') {
      const items = this.getItemList();
      await fetchTotalAmountForProductvariants({
        items,
        IsTaxCalcuationRequired: true,
      });
    } else {
      fetchTotalAmountForProductvariants({
        Items: [{ productvariantId: variantId, quantity: 1 }],
        IsTaxCalcuationRequired: true,
      });
    }

    fetchPlanDetailsByProductVariantId();
  };

  onDeletionSuccess = () => {
    const { fetchPaymentProfile } = this.props;
    this.setState({
      selectedPaymentProfileId: null,
    });
    const secondsToGo = 3;
    fetchPaymentProfile();
    setTimeout(() => {
      this.hideDeleteCardModal();
    }, secondsToGo * 1000);
  };

  showDeleteCardModal = paymentProfileId => {
    this.setState({
      deleteCardModalVisibility: true,
      paymentProfileIdForDeletion: paymentProfileId,
    });
  };

  toggleDeleteCardModal = () => {
    const { deleteCardModalVisibility } = this.state;
    this.setState({
      deleteCardModalVisibility: !deleteCardModalVisibility,
    });
  };

  hideDeleteCardModal = () => {
    const { clearDeletePaymentCardApiStatus, deletePaymentCardApiStatus } = this.props;
    this.setState({
      deleteCardModalVisibility: false,
    });
    setTimeout(() => {
      if (['FAILED', 'COMPLETED'].includes(_.get(deletePaymentCardApiStatus, ['status']))) {
        clearDeletePaymentCardApiStatus();
      }
    }, 1 * 1000);
  };

  getJobPlan() {
    const { location, productVariants } = this.props;
    const { variantId } = qs.parse(location.search);
    const selectedProduct = _.find(productVariants, variant => variant.Id === variantId);
    if (selectedProduct) {
      return { planName: selectedProduct.Name, planDisplayName: selectedProduct.DisplayName };
    }
    return { planName: '', planDisplayName: '' };
  }

  selectCard = selectedPaymentProfileId => {
    const { form } = this.props;
    this.setState({ selectedPaymentProfileId });
    form.setFieldsValue({
      isBillingSameAsCompanyAddress: false,
    });
  };

  toggleOrderModalVisibility = () => {
    const { orderModalVsibility } = this.state;
    this.setState({ orderModalVsibility: !orderModalVsibility });
  };

  onClickDuplicatePaymentOk = () => {
    const { orderModalVsibility } = this.state;
    this.setState({ orderModalVsibility: !orderModalVsibility });
    const { history } = this.props;
    history.push({
      pathname: `/jobs`,
    });
  };

  placeOrder = async values => {
    const {
      createOrder,
      location,
      paymentProfile,
      updateBillingInfo,
      setCreateOrderApiStatusPending,
      jobsById,
      resetOrderDetails,
      resetOrderDetailsWithoutTax,
      setTrynowFormData,
      usersByGuId,
      currentUser,
    } = this.props;
    const currentUserDetails = _.get(usersByGuId, [currentUser.sub], {});
    const { selectedPaymentProfileId, paymentMode, orderType } = this.state;
    const { planName } = this.getJobPlan();
    const { variantId, jobGuid, jobId } = qs.parse(location.search);
    const jobDetails = _.get(jobsById, [jobId], {});
    const paymentData = {
      cardDetails: null,
      paymentProfileId: null,
      saveCardDetails: values.savePaymentDetails,
      address: {},
    };

    const address = {};
    if (values.Location) {
      const locationParts = values.Location.trim().split(',');
      const City = locationParts[0] ? locationParts[0].trim() : '';
      const State = locationParts[1] ? locationParts[1].trim() : '';
      paymentData.address = { ...paymentData.address, City, State };
      address.State = values.Location.trim();
    }
    if (values.Address) {
      address.State = values.Address.trim();
      paymentData.address = { ...paymentData.address, Street: values.Address.trim() };
    }
    if (values.Country) {
      address.Country = values.Country.trim();
      paymentData.address = { ...paymentData.address, Country: values.Country.trim() };
    }
    if (values.Zipcode) {
      address.Zip = values.Zipcode.trim();
      paymentData.address = { ...paymentData.address, Zip: values.Zipcode.trim() };
    }

    const selectedPaymentProfile = paymentProfile
      ? paymentProfile.find(profile => profile.ProfileId === selectedPaymentProfileId)
      : null;
    const selectedBillingAddress = _.get(selectedPaymentProfile, ['Address'], null);
    let billInfoUpdated = false;
    if (!_.isEqual(paymentData.address, selectedBillingAddress)) {
      billInfoUpdated = true;
    }
    if (paymentMode === 'CreditCard') {
      if (selectedPaymentProfileId) {
        paymentData.paymentProfileId = selectedPaymentProfileId;
      } else {
        let cardNumber = _.get(values, 'Number', '');
        cardNumber = getStringWithoutWhiteSpaces(cardNumber);
        paymentData.cardDetails = {
          Number: cardNumber,
          ExpiryMonth: Number(values.Date.format('M')),
          ExpiryYear: values.Date.year(),
          CVV: values.Cvv,
        };
      }
    } else {
      paymentData.creditsToUse = 1;
      paymentData.address = null;
    }
    this.toggleOrderModalVisibility();
    setCreateOrderApiStatusPending();
    if (billInfoUpdated && selectedPaymentProfile) {
      await updateBillingInfo(selectedPaymentProfile.ProfileId, paymentData.address);
    }
    let items = [];
    if (orderType === 'immediate') {
      items = [
        {
          ProductVariantId: variantId,
          Quantity: 1,
          CreditsToUse: paymentData.creditsToUse,
          RefIds: [jobGuid],
        },
      ];
    } else {
      items = this.getItemList();
    }
    const orderResponse = await createOrder(items, paymentData, orderType);
    const orderResponseData = orderResponse.data;
    const orderAmount = orderResponseData?.Amount?.Price;
    const orderTax = orderResponseData?.Amount?.Tax;
    const totalJobCount = orderResponse.data.Items.reduce(
      (prevValue, currentValue) => prevValue + currentValue.Quantity,
      0
    );
    const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
    PubSub.publish(PURCHASE, {
      jobId,
      jobTitle: jobDetails?.JobTitle,
      isAdvanced: jobDetails?.IsAdvancedJob,
      planName,
      quantity: totalJobCount,
      price: orderAmount,
      invoiceNumber: orderResponseData.InvoiceNumber,
      tax: orderTax,
      jobDetails: analyticsJobDetails,
    });
    resetOrderDetails();
    resetOrderDetailsWithoutTax();
    if (currentUserDetails.IsTryNow) {
      setTrynowFormData({}, true);
    }
  };

  placeOrderCollectInfo = () => {
    const { form } = this.props;
    const { paymentMode } = this.state;
    if (paymentMode === 'CreditCard') {
      form.validateFields((err, values) => {
        if (!err) {
          this.placeOrder(values);
        }
      });
    } else if (paymentMode === 'AryaJobCredit') {
      this.placeOrder({});
    }
  };

  handleBackToJobCreate = () => {
    const { history } = this.props;
    // const job = _.get(history, ['location', 'state'], {});
    // const { jobGuid, jobId } = qs.parse(location.search);
    history.goBack();
  };

  handleBackToEditProfile = () => {
    const { history, resetGetOrderApiStatus } = this.props;
    resetGetOrderApiStatus();
    history.push({
      pathname: `/settings/profile`,
    });
  };

  paymentModeSelected = paymentMode => {
    if (paymentMode === 'AryaJobCredit') {
      this.setState({ paymentMode, selectedPaymentProfileId: null });
    } else {
      this.setState({ paymentMode });
    }
  };

  getPlanDetails = () => {
    const { planDetailsByProductVariantId, location } = this.props;
    const { variantId } = qs.parse(location.search);
    return _.get(planDetailsByProductVariantId, variantId, {});
  };

  getPlaceOrderDisabilityStatus = () => {
    const { selectedPaymentProfileId, paymentMode } = this.state;
    const { paymentProfile, form, getAmountApiStatus = 'PENDING', location, jobsById } = this.props;
    const { jobId, premium, basic } = qs.parse(location.search);

    const planDetails = this.getPlanDetails();
    const { getFieldValue } = form;

    let disablePlaceOrder = true;
    if (paymentMode === 'AryaJobCredit') {
      if (planDetails?.AvailableCredits > 0) disablePlaceOrder = false;
    } else {
      const address = getFieldValue('Address');
      const country = getFieldValue('Country');
      const addressLocation = getFieldValue('Location');
      const zipCode = getFieldValue('Zipcode');
      const name = getFieldValue('Name');
      const number = getFieldValue('Number');
      const date = getFieldValue('Date');
      const cvv = getFieldValue('Cvv');

      const isPaymentInfoAvaliable =
        (paymentProfile.length && selectedPaymentProfileId) || (name && number && date && cvv);
      const isbillingAddressAvaliable = address && country && addressLocation && zipCode;
      const isAmountAvaliable = getAmountApiStatus === 'COMPLETED';

      if (isAmountAvaliable && isbillingAddressAvaliable && isPaymentInfoAvaliable) {
        disablePlaceOrder = false;
      }
    }

    if (premium || basic) {
      return disablePlaceOrder;
    }

    const jobDetails = _.get(jobsById, [jobId], {});
    if ((!_.isEmpty(jobDetails) && jobDetails?.ErrorCode) || _.isEmpty(jobDetails)) {
      disablePlaceOrder = true;
    }
    return disablePlaceOrder;
  };

  getIsBackPromptDisplayed = () => {
    const { bundlePlanModalVisibility } = this.state;
    const {
      location,
      jobGuidToIdMapping = {},
      jobsById = {},
      createOrderApiStatus,
      getAmountApiStatus = 'PENDING',
    } = this.props;

    const { jobGuid } = qs.parse(location.search);

    const jobId = jobGuidToIdMapping[jobGuid];
    const orderStatus = _.get(jobsById, [jobId, 'OrderInfo', 'Status'], '');
    return (
      !bundlePlanModalVisibility &&
      createOrderApiStatus !== 'SUCCESS' &&
      orderStatus.toLowerCase() !== 'success' &&
      getAmountApiStatus !== 'DESTINATIONADDRESS_NOT_FOUND'
    );
  };

  getIsUserFullAddressAvaliable = (userDetails = {}) => {
    const { Country, ZipCode, State, City, Street } = userDetails;
    return Country && ZipCode && State && City && Street;
  };

  handlePanelChange = key => {
    this.setState({ activePanelKey: key });
  };

  getStepper = (isUpgrade, isBuyCredit) => {
    const { showSampleCandidates, planDetailsByProductVariantId, productVariantesById } = this.props;
    const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantesById);
    const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantesById);
    const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
    const current = isFirstJobFree ? 3 : 2;
    const sampleCandidateStepper =
      isUpgrade || isBuyCredit ? null : (
        <div className="payment-component-progress-step">
          <ProgressSteps current={current} showSampleCandidates={isFirstJobFree} />
        </div>
      );
    return (
      <div className="back-link-stepper-wrapper">
        <div className="job-creation-back">
          <Button type="link" onClick={this.handleBackToJobCreate}>
            {'< Back'}
          </Button>
        </div>
        {sampleCandidateStepper}
      </div>
    );
  };

  render() {
    const countries = [{ Name: 'USA', Iso2Code: 'US', Iso3Code: 'USA' }];
    const {
      cardDetails,
      selectedPaymentProfileId,
      orderModalVsibility,
      deleteCardModalVisibility,
      paymentMode,
      isPaymentAlreadyCompleted,
      companySettingVisibilty,
      paymentProfileIdForDeletion,
      orderType,
      premiumCount,
      basicCount,
      bundlePlanModalVisibility,
      activePanelKey,
    } = this.state;

    const {
      form,
      createOrderApiStatus,
      createOrderApiErrorMessage,
      paymentApiStatus,
      history,
      paymentProfile,
      paymnetProfileApiStatus,
      location,
      savePaymentDetails,
      fetchPaymentProfile,
      savePaymentProfileApiStatus,
      invoiceNumber,
      jobUtilities,
      locationApiStatus,
      fetchLocations,
      fetchZipCodes,
      resetZipcodes,
      clearZipValidationError,
      searchZipCode,
      clearZipCodeError,
      updateBillingInfoApiStatus,
      getAmountApiStatus = 'PENDING',
      featureToggleList,
      resetSavePaymentDetailApiStatus,
      totalAmount,
      planDetailsByProductVariantIdApiStatus,
      currentUser,
      usersByGuId,
      productVariants,
      deletePaymentCardApiStatus,
      paymentProfilesById,
      deletePaymentCard,
      clearDeletePaymentCardApiStatus,
      userUtilities,
      fetchUserLocations,
      fetchUserZipCodes,
      fetchTotalAmountForProductvariants,
      orderDetails,
      productVariantesById,
      orderDetailsWithTax,
      getAmountWithoutTaxApiStatus,
      resetOrderDetailsWithoutTax,
      showSampleCandidates,
      currentUserDetails,
    } = this.props;

    const { isUpgrade, variantId, isBuyCredit } = qs.parse(location.search);
    const planDetails = this.getPlanDetails();
    const disablePlaceOrder = this.getPlaceOrderDisabilityStatus();
    const { planName, planDisplayName } = this.getJobPlan();
    const isBackPromptDisplayed = this.getIsBackPromptDisplayed();

    const selectedPaymentProfile = paymentProfile?.find(profile => profile.ProfileId === selectedPaymentProfileId);
    const selectedBillingAddress = _.get(selectedPaymentProfile, ['Address'], null);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const isPulsePromotionalOffer = featureToggleList.PulsePromotionalOffer.IsEnabled;

    const isAddressAvaliable = this.getIsUserFullAddressAvaliable(currentUserDetails);

    return (
      <Form onSubmit={this.placeOrderCollectInfo} layout="inline" className="billing-info-card">
        <Prompt when={isBackPromptDisplayed} message={() => `Are you sure you want to leave?`} />
        {isPaidJobServiceEnabled ? <div>{this.getStepper(isUpgrade, isBuyCredit)}</div> : null}
        <span className="place-order-title">Place Order</span>
        <div className="payment-plan-container">
          <div className="payment-billing-panel">
            <PaymentCompanyInfoPanel
              userDetails={currentUserDetails}
              isAddressAvaliable={isAddressAvaliable}
              getAmountApiStatus={getAmountApiStatus}
              paymentMode={paymentMode}
              updateAddress={this.updateAddress}
              activePanelKey={activePanelKey}
              handlePanelChange={this.handlePanelChange}
              userUtilities={userUtilities}
              fetchUserLocations={fetchUserLocations}
              fetchUserZipCodes={fetchUserZipCodes}
            />
            <Collapse
              expandIconPosition="right"
              accordion
              ghost
              activeKey={[activePanelKey]}
              defaultActiveKey={[activePanelKey]}
              onChange={this.handlePanelChange}
            >
              <Panel header={<div className="payment-method">Payment Information</div>} key="2">
                <div className="payment-billing-information-container">
                  <PaymentInformation
                    hideHeader
                    selectCard={this.selectCard}
                    paymentProfileId={selectedPaymentProfileId}
                    paymentProfile={paymentProfile}
                    paymnetProfileApiStatus={paymnetProfileApiStatus}
                    planDetailsByProductVariantIdApiStatus={planDetailsByProductVariantIdApiStatus}
                    savePaymentProfileApiStatus={savePaymentProfileApiStatus}
                    savePaymentDetails={savePaymentDetails}
                    fetchPaymentProfile={fetchPaymentProfile}
                    form={form}
                    showDeletePaymentCardModal={this.showDeleteCardModal}
                    resetSavePaymentDetailApiStatus={resetSavePaymentDetailApiStatus}
                    paymentModeSelected={this.paymentModeSelected}
                    paymentMode={paymentMode}
                    planName={planName}
                    planDisplayName={planDisplayName}
                    availableCredits={planDetails.AvailableCredits || 0}
                    orderType={orderType}
                    showSampleCandidates={showSampleCandidates}
                  />
                  <PaymentBillingInfo
                    userDetails={currentUserDetails}
                    isAddressAvaliable={isAddressAvaliable}
                    countries={countries}
                    form={form}
                    selectedAddress={selectedBillingAddress}
                    utilities={jobUtilities}
                    locationApiStatus={locationApiStatus}
                    fetchLocations={fetchLocations}
                    resetZipcodes={resetZipcodes}
                    fetchZipCodes={fetchZipCodes}
                    clearZipValidationError={clearZipValidationError}
                    searchZipCode={searchZipCode}
                    clearZipCodeError={clearZipCodeError}
                    paymentMode={paymentMode}
                    location={location}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="payment-agreement-container">
            <PaymentPlaceOrder
              createOrderApiStatus={createOrderApiStatus}
              createOrderApiErrorMessage={createOrderApiErrorMessage}
              paymentApiStatus={paymentApiStatus}
              history={history}
              placeOrderCollectInfo={this.placeOrderCollectInfo}
              updateBillingInfoApiStatus={updateBillingInfoApiStatus}
              cardDetails={cardDetails}
              planName={planName}
              planDisplayName={planDisplayName}
              modalVisbility={orderModalVsibility}
              invoiceNumber={invoiceNumber}
              toggleOrderModalVisibility={this.toggleOrderModalVisibility}
              onClickDuplicatePaymentOk={
                isPaymentAlreadyCompleted ? this.onClickDuplicatePaymentOk : this.toggleOrderModalVisibility
              }
              handleBackToEditProfile={this.handleBackToEditProfile}
              getAmountApiStatus={getAmountApiStatus}
              totalAmount={totalAmount}
              disablePlaceOrder={disablePlaceOrder}
              paymentMode={paymentMode}
              companySettingVisibilty={companySettingVisibilty}
              userDetails={_.get(usersByGuId, [currentUser.sub], {})}
              updateAddress={this.updateAddress}
              isAddressAvaliable={isAddressAvaliable}
              productVariants={productVariants}
              variantId={variantId}
              userUtilities={userUtilities}
              fetchUserLocations={fetchUserLocations}
              fetchUserZipCodes={fetchUserZipCodes}
              orderType={orderType}
              premiumCount={premiumCount}
              basicCount={basicCount}
              toggleBundlePlanModalVisibility={this.toggleBundlePlanModalVisibility}
              bundlePlanModalVisibility={bundlePlanModalVisibility}
              fetchTotalAmountForProductvariants={fetchTotalAmountForProductvariants}
              orderDetails={orderDetails}
              productVariantesById={productVariantesById}
              orderDetailsWithTax={orderDetailsWithTax}
              getAmountWithoutTaxApiStatus={getAmountWithoutTaxApiStatus}
              resetOrderDetailsWithoutTax={resetOrderDetailsWithoutTax}
              isPulsePromotionalOffer={isPulsePromotionalOffer}
            />
          </div>
        </div>
        <DeletePaymentCardModal
          visible={deleteCardModalVisibility}
          toggleOrderModalVisibility={this.hideDeleteCardModal}
          modalContent={getDeleteCardModalContent({
            deletePaymentCardApiStatus,
            paymentProfileIdForDeletion,
            paymentProfilesById,
            deletePaymentCard,
            clearDeletePaymentCardApiStatus,
            toggleDeleteCardModal: this.toggleDeleteCardModal,
          })}
        />
      </Form>
    );
  }
}

export { Payment as PaymentWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(Payment)));
