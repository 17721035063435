import { Collapse } from 'antd';
import React from 'react';
import styles from '../Payment/PaymentCompanyInfoPanel.module.scss';

const { Panel } = Collapse;

export default function PlaceOrderTermsOfUse({ placeOrderButtonText }) {
  return (
    <div className={styles.termsOfUseWrapper}>
      <Collapse expandIconPosition="right" accordion ghost>
        <Panel
          header={
            <div className={styles.termsOfUseHeader}>
              Please read the
              <a href="https://login.leoforce.com/leo/signup/usageagreement" target="_blank" rel="noopener noreferrer">
                &nbsp;Terms of Use
              </a>{' '}
              Carefully.
            </div>
          }
          key="1"
        >
          <div className={styles.paymentAgreement}>
            <p>
              Leoforce reserves the right to reject job postings that are not bona-fide recruitment ads, or for any
              other reason we consider not in the best interest of Leoforce.
            </p>
            <p>
              {`Please read the Leoforce’s terms of agreementcarefully. Please note that once you click ${placeOrderButtonText}, the
              cost is NON-REFUNDABLE and you agree that you have read and reviewed, in its entirety, the Leoforce Terms
              of Agreement available from the link in the prior sentence and agree to be bound by its terms and
              conditions. If you do not agree to be bound by the Leoforce Terms of Agreement, please DO NOT click the
              ${placeOrderButtonText} button. Please click ${placeOrderButtonText} only once to avoid being charged multiple times.`}
            </p>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
