import React from 'react';
import _ from 'lodash';
import { Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SampleCandidateSupplyInsight from '../SampleCandidateSupplyInsights';
import TryNowSplashScreen from '../TryNowSplashScreen';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getGlobalInsightSupplyList } from '../../Reducers/SalesDashBoardReducer';
import * as SalesDashBoardActions from '../../Actions/SalesDashBoardActions';
import { getTrynowFormData } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getDummyJobIntel } from '../../Utils/DummyDataUtils';
import {
  defaultExperienceRanges,
  menuItemsList,
  getActiveInactiveSupplyInsightsList,
  getLocationSupplyInsightsList,
} from '../../Utils/GlobalInsightSupplyUtil';
import { getCityStateFromLocation } from '../../Utils/LocationUtil';
import JobIntel from '../../Components/JobIntel/JobIntel';
import './SampleJobdetail.scss';

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
  userConfig: getConfig(state),
  trynowData: getTrynowFormData(state),
  tryNowCandidateApiStatus: getApiStatus(state, 'fetchTryNowCandidateApiStatus'),
  globalInsightSupplyList: getGlobalInsightSupplyList(state),
  globalSupplyApiStatus: getApiStatus(state, 'globalSupplyApiStatus'),
});

const mapDispatchToProps = {
  fetchGlobalInsightSupplyList: SalesDashBoardActions.fetchGlobalInsightSupplyList,
  clearGlobalInsightList: SalesDashBoardActions.clearGlobalInsightList,
};

const hiddenMenuList = [
  menuItemsList.industries.id,
  menuItemsList.experience.id,
  menuItemsList.education.id,
  menuItemsList.certifications.id,
];

class SampleJobdetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCandidateSupplyAnalyticsView: false,
      activeMenuList: [],
      inActiveMenuList: [],
    };
  }

  onClickSearchAgain = () => {
    const { history } = this.props;
    history.push('/_trynow');
  };

  hideCandidateSupplyAnalyticsView = () => {
    this.setState({ isCandidateSupplyAnalyticsView: false });
  };

  componentDidMount() {
    const { tryNowCandidateApiStatus, history, clearGlobalInsightList } = this.props;
    if (tryNowCandidateApiStatus !== 'COMPLETED') {
      history.push('/_trynow');
    } else {
      const { fetchGlobalInsightSupplyList, trynowData } = this.props;
      const { CountryCode = 'US', JobTitle, Skills = [], Location } = trynowData;
      const locationArray = getCityStateFromLocation(Location);
      const State = locationArray[1];
      const payload = {
        Title: JobTitle,
        CountryCode,
        State,
        Skills,
        Experiences: defaultExperienceRanges,
      };
      clearGlobalInsightList();
      fetchGlobalInsightSupplyList(payload);
    }
  }

  componentDidUpdate(prevProps) {
    const { globalInsightSupplyList, trynowData, globalSupplyApiStatus } = this.props;
    if (!_.isEmpty(globalInsightSupplyList) && !_.isEqual(globalInsightSupplyList, prevProps.globalInsightSupplyList)) {
      const initialActiveList = [];
      const initialInActiveList = [...hiddenMenuList];
      const { Location } = trynowData;
      const [City, State] = getCityStateFromLocation(Location);
      const { SimilarTitlesStats, CompaniesStats, SkillsStats } = globalInsightSupplyList;
      const [listOfMapStates, listOfMapCities] = getLocationSupplyInsightsList(
        { City, State },
        globalInsightSupplyList,
        true
      );
      const locationStats = listOfMapStates.length > 0 ? listOfMapStates : listOfMapCities;

      const supplyInsights = [
        {
          data: SimilarTitlesStats,
          item: menuItemsList.similarRoles,
        },
        {
          data: CompaniesStats,
          item: menuItemsList.companies,
        },
        {
          data: SkillsStats,
          item: menuItemsList.marketSkills,
        },
        {
          data: locationStats,
          item: menuItemsList.location,
        },
      ];
      const [activeSupplyInsightList, inActiveSupplyInsightList] = getActiveInactiveSupplyInsightsList(
        supplyInsights,
        initialActiveList,
        initialInActiveList
      );
      if (activeSupplyInsightList.length > 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          activeMenuList: activeSupplyInsightList,
          inActiveMenuList: inActiveSupplyInsightList,
        });
      } else {
        this.hideCandidateSupplyAnalyticsView();
      }
    }
    if (globalSupplyApiStatus !== prevProps.globalSupplyApiStatus && globalSupplyApiStatus === 'FAILED') {
      this.hideCandidateSupplyAnalyticsView();
    }
  }

  selectPlan = () => {
    const { history } = this.props;
    history.push('/select-plan');
  };

  render() {
    const { isCandidateSupplyAnalyticsView, activeMenuList, inActiveMenuList } = this.state;
    const { trynowData, globalInsightSupplyList, selectPlan, globalSupplyApiStatus } = this.props;
    const { Location } = trynowData;
    const locationArray = getCityStateFromLocation(Location);
    const State = locationArray[1];

    return (
      <div id="job-details">
        {!isCandidateSupplyAnalyticsView ? (
          <>
            <div className="sample-job-intel-wrapper-blur">
              <JobIntel intelData={getDummyJobIntel()} />
            </div>
            <SampleCandidateSupplyInsight
              supplyPayload={{ ...trynowData, State }}
              globalInsightSupplyList={globalInsightSupplyList}
              activeMenuList={activeMenuList}
              inActiveMenuList={inActiveMenuList}
              redirectPath={selectPlan}
              globalSupplyApiStatus={globalSupplyApiStatus}
            />
          </>
        ) : (
          <Skeleton active paragraph={{ rows: 10 }} loading={activeMenuList.length === 0}>
            <TryNowSplashScreen
              hideCandidateSupplyAnalyticsView={this.hideCandidateSupplyAnalyticsView}
              supplyPayload={{ ...trynowData, State }}
              globalInsightSupplyList={globalInsightSupplyList}
              activeMenuList={activeMenuList}
              inActiveMenuList={inActiveMenuList}
            />
          </Skeleton>
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SampleJobdetail));
export { SampleJobdetail as SampleJobdetailWithoutStore };
