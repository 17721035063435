import React from 'react';
import {
  getCircleColor,
  prepareDataForSkillProximityScatterPlot,
  getYAxisDomain,
  getXAxisDomain,
} from '../../../Utils/CandidateIntelChartUtils';
import renderScatterPlot from '../ScatterPlot';
import './SkillProximityScatterPlot.scss';

function getTooltipHeading(d, candidateName) {
  return `${d.Type === 'Candidate' ? candidateName : 'Job'}'s Skills`;
}

export function onMouseOver(tooltipContent, candidateName) {
  return (tooltip, offsetX, offsetY, data) => {
    const { Weight, Skill, Type } = data;
    tooltip
      .style('opacity', 1)
      .html(tooltipContent)
      .style('top', `${offsetY - 20}px`)
      .style('left', `${offsetX + 30}px`);

    tooltip.select('.tooltip-heading').html(getTooltipHeading(data, candidateName));
    tooltip.select('.skill-tooltip-skill-name').html(Skill);
    tooltip.select('.skill-tooltip-skill-weight').html(Weight.toFixed(2));
    tooltip.select('.skill-tooltip-label-color').style('background-color', getCircleColor(Type));
  };
}

class SkillProximityScatterPlot extends React.Component {
  constructor(props) {
    super(props);
    this.createScatterPlot = this.createScatterPlot.bind(this);
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  componentDidUpdate() {
    this.createScatterPlot();
  }

  createScatterPlot() {
    const { SkillsRelevance, candidateName } = this.props;
    const skillsData = prepareDataForSkillProximityScatterPlot(SkillsRelevance);

    const tooltipContent = `
    <div class="skill-tooltip-content">
      <p class="tooltip-heading"></p>
      <div class="skill-tooltip-details">
        <div class="skill-tooltip-label-color"></div>
        <div class="skill-tooltip-skill-name"></div>
        <div class="skill-tooltip-skill-weight"></div>
      </div>
    </div>
    `;

    const config = {
      parentId: '#skillsProximityScatterPlot',
      tooltipClass: 'skill-proximity-tooltip',
      onMouseOver: onMouseOver(tooltipContent, candidateName),
      xAxisDomain: getXAxisDomain(skillsData),
      yAxisDomain: getYAxisDomain(skillsData),
      idPrefix: 'skills',
    };

    renderScatterPlot(config, skillsData);
  }

  render() {
    return <div id="skillsProximityScatterPlot"></div>;
  }
}

export default SkillProximityScatterPlot;
