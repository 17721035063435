import React from 'react';
import { Icon } from 'antd';

const PersonalEmailSvg = props => (
  <svg width={17} height={16} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#757575" fillRule="evenodd">
      <path d="M5.667 5.667a1.418 1.418 0 000 2.833 1.418 1.418 0 000-2.833m0 4.25a2.836 2.836 0 01-2.834-2.834A2.836 2.836 0 015.667 4.25 2.836 2.836 0 018.5 7.083a2.836 2.836 0 01-2.833 2.834M1.53 14.167h8.275c-.424-1.281-2.008-2.125-4.139-2.125-2.13 0-3.713.844-4.137 2.125zm9.803 1.416H0v-.708c0-2.503 2.33-4.25 5.666-4.25 3.337 0 5.667 1.747 5.667 4.25v.708zM12.75 1.531L15.746.033C15.692.02 15.641 0 15.583 0H9.917c-.058 0-.11.02-.163.033l2.996 1.498z" />
      <path d="M12.988 2.6a.528.528 0 01-.476 0L9.21.948V4.25c0 .392.316.708.708.708h5.667a.708.708 0 00.708-.708V.948L12.988 2.6z" />
    </g>
  </svg>
);

const PersonalEmailIcon = props => <Icon component={PersonalEmailSvg} {...props} />;
export default PersonalEmailIcon;
