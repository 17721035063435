import React from 'react';
import { Button, Form } from 'antd';
import NotesContext from './NotesContext';

function NotesSaveButton(props) {
  const { form, onSubmit, addButtonApiStatus, candidateDetailsStatus, candidateListStatus } =
    React.useContext(NotesContext);
  const { buttonName, skEventName } = props;

  const handleButtonClick = e => {
    e.preventDefault();
    onSubmit(form.getFieldsValue());
  };

  return (
    <Form.Item>
      <Button
        onClick={handleButtonClick}
        type="primary"
        shape="round"
        loading={addButtonApiStatus === 'INPROGRESS'}
        disabled={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
        sk-event-name={skEventName}
      >
        {buttonName}
      </Button>
    </Form.Item>
  );
}

export default NotesSaveButton;
