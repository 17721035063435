import React from 'react';
import ClientFormContainer from '../../Containers/AddClient/ClientFormContainer';
import LinkButton from '../Common/LinkButton/LinkButton';
import styles from './ClientAddition.module.scss';

function ClientAddition() {
  const [clientFormVisibility, setclientFormVisibility] = React.useState(false);

  const toggleClientFormVisibility = () => {
    setclientFormVisibility(!clientFormVisibility);
  };

  return (
    <div>
      <div className={styles.addClientButton}>
        <LinkButton buttonName="Add Client" onClickButton={toggleClientFormVisibility} />
      </div>
      <ClientFormContainer
        visible={clientFormVisibility}
        onCloseClientForm={toggleClientFormVisibility}
        onCloseDrawer={toggleClientFormVisibility}
      />
    </div>
  );
}

export default ClientAddition;
