import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import CandidateIntel from '../../Containers/CandidateIntel/CandidateIntel';
import styles from './CandidateIntelWrapper.module.scss';

function CandidateIntelWrapper(props) {
  const {
    candidate,
    jobId,
    setCandidateViewHeaderVisibility,
    isCandidateViewHeaderVisible,
    candidateIntel,
    isCandidateIntelDownloadEnabled,
  } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    const container = ref.current;
    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  const [candidateFirstName, candidateLastName] = _.get(candidate, 'Name', '').split(' ');
  const score = _.get(candidate, 'Score', '');
  const jobTitle = _.get(candidate, 'Title', '');
  const candidateName = `${candidateFirstName} ${candidateLastName || ''}`;
  return (
    <div
      className={classNames(styles.candidateIntelWrapper, { [styles.extendView]: !isCandidateViewHeaderVisible })}
      ref={ref}
    >
      <div>
        <CandidateIntel
          candidateIntel={candidateIntel}
          candidateId={candidate?.Id}
          jobId={jobId}
          candidateName={candidateName}
          score={score}
          jobTitle={jobTitle}
          setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          isCandidateIntelDownloadEnabled={isCandidateIntelDownloadEnabled}
        />
      </div>
    </div>
  );
}

export default CandidateIntelWrapper;
