import React from 'react';
import styles from './StatsView.module.scss';

export default function StatsView(props) {
  const { title, count, type } = props;

  const getIndicatorClassName = () => {
    switch (type?.toLowerCase()) {
      case 'success':
        return styles.successIndicator;
      case 'error':
        return styles.errorIndicator;
      default:
        return styles.defaultIndicator;
    }
  };

  return (
    <div className={styles.statsView}>
      <div className={styles.statsViewTitle}>{title}</div>
      <div className={`${styles.statsViewCount} ${getIndicatorClassName()}`}>{count}</div>
    </div>
  );
}
