import React from 'react';
import { Skeleton, Tag, Empty, Progress } from 'antd';
import { convertToPascalCase } from '../../../Utils/DashBoardUtils';
import styles from './RoleComparisionView.module.scss';

function SkillTag({ skill, className }) {
  const trimPascalSkill = convertToPascalCase(skill.DisplayKey.trim());
  if (!trimPascalSkill) return null;
  return (
    <Tag className={className} key={trimPascalSkill}>
      {trimPascalSkill}
    </Tag>
  );
}

export default function RoleComparisionView(props) {
  const { assessmentList, assessmentApiStatus, assessmentPayload } = props;
  const { PrescribedTitle } = assessmentPayload;
  if (!PrescribedTitle) return null;
  const { Comparable, Missing, Percentage } = assessmentList.Skills;

  const loading = assessmentApiStatus === 'INPROGRESS';

  const comparableList = Comparable.map(skill => {
    return <SkillTag skill={skill} className={styles.matchSkillTag} />;
  });
  const missingList = Missing.map(skill => {
    return <SkillTag skill={skill} className={styles.missingSkillTag} />;
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.subHeader}>Role Comparison to</div>
      <div className={styles.header}>{PrescribedTitle}</div>
      <div className={styles.subSection}>
        <div className={styles.subHeader}>Skill Overlap</div>
        <Skeleton active paragraph={{ rows: 1 }} loading={loading}>
          <Progress type="circle" strokeColor="#13c26b" percent={parseFloat(Percentage).toFixed(2)} width={80} />
        </Skeleton>
      </div>
      <div className={styles.subSection}>
        <div className={styles.subHeader}>Skill Matches</div>
        <Skeleton active paragraph={{ rows: 1 }} loading={loading}>
          {comparableList.length > 0 ? (
            <div className={styles.skillSet}>{comparableList}</div>
          ) : (
            <Empty className={styles.empty} />
          )}
        </Skeleton>
      </div>
      <div className={styles.subSection}>
        <div className={styles.subHeader}>Skill Gaps</div>
        <Skeleton active paragraph={{ rows: 1 }} loading={loading}>
          {missingList.length > 0 ? (
            <div className={styles.skillSet}>{missingList}</div>
          ) : (
            <Empty className={styles.empty} />
          )}
        </Skeleton>
      </div>
    </div>
  );
}
