import React from 'react';
import { Collapse, Button, Alert } from 'antd';
// import WarningIcon from '../../Icons/WarningIcon';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
import styles from './PaymentCompanyInfoPanel.module.scss';

const { Panel } = Collapse;

export default function PaymentCompanyInfoPanel(props) {
  const {
    isAddressAvaliable,
    fetchUserZipCodes,
    fetchUserLocations,
    userUtilities,
    userDetails,
    updateAddress,
    activePanelKey,
    handlePanelChange,
    paymentMode,
    getAmountApiStatus,
  } = props;

  const companyAddressError = !isAddressAvaliable;
  const getAmountApiStatusList = ['DESTINATIONADDRESS_NOT_FOUND', 'TAXJAR_GATEWAY_ERROR', 'INVALID_STATE'];
  const isErrorInGetAmountApi = getAmountApiStatusList.includes(getAmountApiStatus);

  return (
    <div
      className={`${styles.paymentCompanyInfo} ${isErrorInGetAmountApi ? styles.paymentCompanyInfoCollapseIcon : ''}`}
    >
      <Collapse
        expandIconPosition="right"
        accordion
        ghost
        activeKey={[activePanelKey]}
        defaultActiveKey={[activePanelKey]}
        onChange={handlePanelChange}
      >
        <Panel
          extra={
            activePanelKey === '1' ? null : (
              <Button type="secondary" className={styles.editDetailsBtn}>
                {companyAddressError ? 'Add' : 'Edit'}
              </Button>
            )
          }
          showArrow={activePanelKey === '1'}
          header={
            <div>
              <div className={styles.paymentCompanyInfoHeader}>
                <div className={styles.paymentCompanyInfoTitle}>Company Information</div>
                {companyAddressError ? (
                  <div className={styles.paymentCompanyInfoSubtitle}>
                    Please fill in company informations for this account
                  </div>
                ) : null}
              </div>
              {isErrorInGetAmountApi && paymentMode !== 'AryaJobCredit' && isAddressAvaliable ? (
                <div style={{ paddingRight: '70px' }}>
                  <Alert
                    message={
                      <div>
                        Please check if the company address is valid and belongs to the location selected
                        <Button
                          type="text"
                          danger
                          className="order-result-update-address"
                          onClick={() => handlePanelChange('1')}
                        >
                          Update address
                        </Button>
                      </div>
                    }
                    type="error"
                    showIcon
                  />
                </div>
              ) : null}
            </div>
          }
          key="1"
        >
          <CompanyInfo
            fetchZipCodes={fetchUserZipCodes}
            fetchLocations={fetchUserLocations}
            utilities={userUtilities}
            userDetails={userDetails}
            updateAddress={updateAddress}
            hidePhoneNumber
          />
        </Panel>
      </Collapse>
    </div>
  );
}
