import axios from 'axios';
import { apiV1Client, smartJobV1Client } from './BaseRepository';

function fetchSkillAutocompleteWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return async ({
    searchTerm,
    from = 0,
    size = 10,
    abortIfDuplicated,
    includeSynonyms,
    includeUserSuggestions,
    userSuggestionsCount,
  }) => {
    if (abortIfDuplicated && cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV1Client
      .get('skills', {
        params: {
          searchTerm,
          from,
          size,
          includeSynonyms,
          includeUserSuggestions,
          userSuggestionsCount,
        },
        cancelToken: abortIfDuplicated
          ? new CancelToken(c => {
              cancelRequest = c;
            })
          : null,
      })
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}
function fetchJobTitleAutocompleteWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return async ({ from, size, title, abortIfDuplicated }) => {
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return smartJobV1Client
      .post(
        'Jobs/title/_search',
        { From: from, Size: size, Title: title },
        {
          cancelToken: abortIfDuplicated
            ? new CancelToken(c => {
                cancelRequest = c;
              })
            : null,
        }
      )
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

const fetchSkillAutocomplete = fetchSkillAutocompleteWithCancellation();
const fetchJobTitleAutocomplete = fetchJobTitleAutocompleteWithCancellation();
export { fetchSkillAutocomplete, fetchJobTitleAutocomplete };
