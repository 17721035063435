import { defineMessages } from 'react-intl';

const messages = defineMessages({
  callConfiguration: {
    id: 'AdminSettings.Navigator.CallConfiguration',
    defaultMessage: 'Call Configuration',
  },
  contactInfoProvider: {
    id: 'AdminSettings.Navigator.ContactInfoProvider',
    defaultMessage: 'Candidate Info Provider',
  },
  emailConfiguration: {
    id: 'AdminSettings.Navigator.EmailConfiguration',
    defaultMessage: 'SMTP Email Configuration',
  },
  sharing: {
    id: 'AdminSettings.Navigator.Sharing',
    defaultMessage: 'Sharing',
  },
  autoLogout: {
    id: 'AdminSettings.Navigator.AutoLogout',
    defaultMessage: 'Auto Logout',
  },
});

export default messages;
