export function mapCognitoErrorMessage(error) {
  const errorCode = error && error.code;
  let errorMessage = error.message || error.code;

  switch (errorCode) {
    case 'UserNotFoundException':
      errorMessage = `Couldn't find your Arya Account`;
      break;

    default:
      break;
  }
  return errorMessage;
}
