export function logSmartKarrotEvent(eventName, param) {
  if (window.UsageAnalytics) {
    window.UsageAnalytics.logEvent(eventName, param);
  }
}

export function getEnvironmentSkOrgId(env, orgId, isEnabled) {
  const skTestOrgId = '149b21ed-de53-4343-9733-750ad7ca7e8c';
  if (isEnabled) {
    if (env === 'prod') return orgId;
    return skTestOrgId;
  }
  return null;
}
