import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseJD, suggestJD, resetJDParsingInformation } from '../../Actions/AryaJobDescriptionActions';
import { getParsedJobData, getSuggestedJobDescriptions } from '../../Reducers/AryaJobDescriptionReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import JobDescription from '../../Components/JobDescription/JobDescription';

export default function JobDescriptionContainer(props) {
  const {
    jobForm,
    updateDescription,
    isRecommendationNotRequired,
    jobDetails,
    recommendationModalInitialValues,
    formProperties,
  } = props;
  const dispatch = useDispatch();

  const parsedJobData = useSelector(state => getParsedJobData(state));
  const parserApiStatus = useSelector(state => getApiStatus(state, 'JobParserApi'));
  const featureToggleList = useSelector(state => getFeatureToggleList(state));

  const suggestedJobDescriptions = useSelector(state => getSuggestedJobDescriptions(state));
  const descriptionSuggestionApiStatus = useSelector(state => getApiStatus(state, 'JobDescriptionSuggestionApi'));

  useEffect(() => {
    return () => {
      dispatch(resetJDParsingInformation);
    };
  }, []);

  const parseJobDescription = jobDescription => {
    dispatch(parseJD(jobDescription));
  };

  const suggestJobDescriptions = (title, skills) => {
    dispatch(suggestJD({ skills, title }));
  };

  const isRecommendationButtonRequired =
    featureToggleList?.JobDescriptionTemplates?.IsEnabled && !isRecommendationNotRequired;

  return (
    <JobDescription
      parseJobDescription={parseJobDescription}
      jobForm={jobForm}
      updateDescription={updateDescription}
      parsedJobData={parsedJobData}
      parserApiStatus={parserApiStatus}
      suggestedJobDescriptions={suggestedJobDescriptions}
      descriptionSuggestionApiStatus={descriptionSuggestionApiStatus}
      suggestJobDescriptions={suggestJobDescriptions}
      isRecommendationButtonRequired={isRecommendationButtonRequired}
      jobDetails={jobDetails}
      recommendationModalInitialValues={recommendationModalInitialValues}
      formProperties={formProperties}
    />
  );
}
