import { setApiStatus } from '../ApiStatusActions';

export const ADD_GENERATED_AI_CONFIGURATON = 'ADD_GENERATED_AI_CONFIGURATON';
export const ADD_RESOLVED_MERGETAG_VALUES = 'ADD_RESOLVED_MERGETAG_VALUES';

function setGenerativeAiFetchApiStatus(status) {
  const apiName = 'fetchChatGptConfigApiStatus';
  return setApiStatus({ apiName, status });
}

const setGenerativeAiConfiguration = ({ response }) => ({
  type: ADD_GENERATED_AI_CONFIGURATON,
  payload: {
    GeneratedAiConfiguration: response.data,
  },
});

const setResolvedMergeTagValues = response => ({
  type: ADD_RESOLVED_MERGETAG_VALUES,
  payload: {
    ResolvedMergeTagValues: response,
  },
});

export { setGenerativeAiConfiguration, setGenerativeAiFetchApiStatus, setResolvedMergeTagValues };
