import * as ActionCreators from '../Actions/ActionCreators/AryaJobDescriptionActionCreator';

const initialState = {
  ParsedJobData: {},
  SuggestedJobDescriptions: [],
};

export function AryaJobDescriptionReducer(state = initialState, action) {
  switch (action.type) {
    case ActionCreators.SET_PARSED_JOB_DATA:
      return {
        ...state,
        ParsedJobData: action.payload,
      };
    case ActionCreators.SET_JOB_DESCRIPTION_SUGGESTION_DATA:
      return {
        ...state,
        SuggestedJobDescriptions: action.payload,
      };
    case ActionCreators.RESET_PARSED_JOB_DATA:
      return {
        ...state,
        ParsedJobData: initialState.ParsedJobData,
      };
    default:
      return state;
  }
}

export function getParsedJobData(state) {
  return state?.AryaJobDescriptionReducer?.ParsedJobData;
}

export function getSuggestedJobDescriptions(state) {
  return state?.AryaJobDescriptionReducer?.SuggestedJobDescriptions;
}
