import React from 'react';
import DeleteIcon from '../../../Icons/DeleteIcon';
import MultiLocationContext from '../MultiLocationContext';

const DeleteLocationButton = () => {
  const { isPrimaryLocation, deleteLocation } = React.useContext(MultiLocationContext);
  const onDeleteLocation = () => {
    if (!isPrimaryLocation) deleteLocation();
  };
  return (
    <DeleteIcon
      style={{
        cursor: isPrimaryLocation ? 'not-allowed' : 'pointer',
        opacity: isPrimaryLocation ? 0.4 : 1,
      }}
      onClick={onDeleteLocation}
    />
  );
};

export default DeleteLocationButton;
