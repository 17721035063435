import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { fetchJobPublishedPortals, fetchJobPortalPublishedInfo, recallJob } from '../../Actions/JobDistributionActions';
import AdvertiseJobComponent from '../../Components/JobPublish/AryaAdvertisement/AdvertiseJobComponent';
import JobBoardPublishComponent from '../../Components/JobPublish/JobBoardPublish/JobBoardPublishComponent';
import {
  getJobDistributedInfo,
  getJobDistributedPortals,
  getJobRecalledPortals,
} from '../../Reducers/JobDistributionReducer';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { Advertisement, JobBoard } from '../../Utils/JobDistributionUtils/JobDistributionUtils';

export default function JobPublishDisplayCard(props) {
  const { onPublishPortal, portals, jobId, publishType, apiStatus, isPublishDisabled, aryaJobStatus } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchJobPublishedPortals({ jobId, includeRecalled: true }));
    dispatch(fetchJobPortalPublishedInfo(jobId, portals[0]));
  }, []);

  const currentUser = useSelector(state => getCurrentUserDetails(state));
  const distributedJobInfo = useSelector(state => getJobDistributedInfo(state, portals[0]));
  const distributedPortals = useSelector(state => getJobDistributedPortals(state));
  const recalledPortals = useSelector(state => getJobRecalledPortals(state));

  const recallPortalJobDistribution = () => {
    dispatch(recallJob(jobId, portals[0]));
  };

  const getJobPublishDisplayCard = () => {
    if (publishType === Advertisement) {
      return (
        <AdvertiseJobComponent
          onPublishPortal={onPublishPortal}
          portals={portals}
          currentUser={currentUser}
          distributedJobInfo={distributedJobInfo}
          distributedPortals={distributedPortals}
          recalledPortals={recalledPortals}
          recallPortalJobDistribution={recallPortalJobDistribution}
          isPublishDisabled={isPublishDisabled}
          aryaJobStatus={aryaJobStatus}
        />
      );
    }
    if (publishType === JobBoard) {
      return (
        <JobBoardPublishComponent
          onPublishPortal={onPublishPortal}
          portals={portals}
          currentUser={currentUser}
          distributedJobInfo={distributedJobInfo}
          distributedPortals={distributedPortals}
          recalledPortals={recalledPortals}
          recallPortalJobDistribution={recallPortalJobDistribution}
          aryaJobStatus={aryaJobStatus}
        />
      );
    }
    return null;
  };

  return (
    <Skeleton active loading={apiStatus === 'INPROGRESS'}>
      {getJobPublishDisplayCard()}
    </Skeleton>
  );
}
