import React from 'react';
import { Button, Icon, Alert } from 'antd';
import _ from 'lodash';

function hideDeleteCardModal(props) {
  const { clearDeletePaymentCardApiStatus, toggleDeleteCardModal, deletePaymentCardApiStatus } = props;
  toggleDeleteCardModal();
  setTimeout(() => {
    if (['FAILED', 'COMPLETED'].includes(_.get(deletePaymentCardApiStatus, ['status']))) {
      clearDeletePaymentCardApiStatus();
    }
  }, 1 * 1000);
}

function onDeletePaymentCard(props) {
  const { deletePaymentCard, paymentProfileIdForDeletion, showDeleteNotification = false } = props;
  deletePaymentCard(paymentProfileIdForDeletion, showDeleteNotification);
}

function getErrorMessage(props) {
  const { deletePaymentCardApiStatus } = props;
  return _.get(deletePaymentCardApiStatus, 'status', null) === 'FAILED'
      ? 'Oops, something just went wrong. Please try again.'
      : null;
}

function getDeletionSuccessContent() {
  return {
    header: 'Payment method removed successfully!',
    icon: <Icon type="check-circle" theme="outlined" className="custom-modal-success-icon" />,
    width: 386,
  };
}

function getDeletionPendingContent(props) {
  const { deletePaymentCardApiStatus, paymentProfilesById, paymentProfileIdForDeletion } = props;
  const ProviderName = _.get(paymentProfilesById, [paymentProfileIdForDeletion, 'CardDetail', 'ProviderName'], '');
  let CardNumber = _.get(paymentProfilesById, [paymentProfileIdForDeletion, 'CardDetail', 'Number'], 'xxxx');
  CardNumber = CardNumber.substring(CardNumber.length - 4);
  const errorMessage = getErrorMessage(props);
  return {
    width: 699,
    header: `Are you sure you want to delete ${ProviderName} card ending in ${CardNumber}?`,
    message: (
      <div>
        {errorMessage ? (
          <div style={{ marginBottom: '4px' }}>
            <Alert type="error" message={errorMessage} />
          </div>
        ) : null}
        {`If you do not want this payment method to be displayed in your list of payment options, click "Delete". (Disabling this payment method will not cancel any of your open orders that use this method.)`}
      </div>
    ),
    footer: (
      <div className="custom-modal-footer-content">
        <div className="offset"></div>
        <div className="custom-modal-buttons">
          <Button className="custom-modal-no-button" shape="round" onClick={() => hideDeleteCardModal(props)}>
            <div className="no-button-text">Cancel</div>
          </Button>
          <Button
            className="custom-modal-yes-button"
            shape="round"
            onClick={() => onDeletePaymentCard(props)}
            loading={_.get(deletePaymentCardApiStatus, 'status') === 'INPROGRESS'}
          >
            <div className="yes-button-text">Delete</div>
          </Button>
        </div>
      </div>
    ),
    icon: <Icon type="close-circle" theme="filled" className="custom-modal-pending-icon" />,
  };
}

export default function getDeleteCardModalContent(props) {
  const { deletePaymentCardApiStatus, showDeleteMessageModal = true } = props;
  if (_.get(deletePaymentCardApiStatus, 'status') === 'COMPLETED' && showDeleteMessageModal) {
    return getDeletionSuccessContent();
  }
  return getDeletionPendingContent(props);
}

export { onDeletePaymentCard };
