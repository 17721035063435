import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Form, Button, Skeleton } from 'antd';
import classNames from 'classnames';
import { BotConfig } from './BotConfig';
import LinkButton from '../../Common/LinkButton/LinkButton';
import './ExtendedBotConfig.scss';

class ExtendedBotConfig extends BotConfig {
  constructor(props) {
    super(props);
    this.getBotQuestionsTab = this.getBotQuestionsTab.bind(this);
  }

  getAddQuestionButton = () => {
    const { selectedRadioButton } = this.state;
    switch (selectedRadioButton) {
      case 'screening':
        return <LinkButton buttonName="Add Questions" onClickButton={this.addQuestion} />;
      case 'infoGathering':
        return <LinkButton buttonName="Add Questions" onClickButton={this.onClickAddMoreQuestionInfoGathering} />;
      default:
        return <div />;
    }
  };

  getFooterButtons = () => {
    const { hasErrors } = this.state;
    const { onCancel, cancelButtonText, saveButtonText, isPaidJobServiceEnabled } = this.props;
    return (
      <div
        className={classNames('extended-bot-button-wrapper', {
          'extended-bot-add-question-button': isPaidJobServiceEnabled,
        })}
      >
        {isPaidJobServiceEnabled ? this.getAddQuestionButton() : null}
        <div className={classNames('extended-bot-buttons')}>
          <Button className="cancel-button" onClick={onCancel}>
            {cancelButtonText || 'Cancel'}
          </Button>
          <Button type="primary" className="save-button" onClick={this.onSaveForm} disabled={hasErrors}>
            {saveButtonText || 'Save'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { getJobQuestionApiStatus, readOnly } = this.props;
    return (
      <Skeleton loading={getJobQuestionApiStatus !== 'COMPLETED'}>
        <div className="extended-bot-modal-wrapper">
          <div className="extended-bot-question-wrapper">{this.getBotQuestionsTab()}</div>
          {!readOnly ? this.getFooterButtons() : null}
        </div>
      </Skeleton>
    );
  }
}
export { ExtendedBotConfig as ExtendedBotConfigWithoutForm };
export default DragDropContext(HTML5Backend)(Form.create()(ExtendedBotConfig));
