function sortNotes(notes) {
  return notes.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
}

function getNotesIndicatorTextAndStatus(candidateNotesCount, callNotesCount, version) {
  const notesCount = candidateNotesCount + callNotesCount;
  let availabilityStatus;
  let text;
  if (candidateNotesCount === null || callNotesCount === null) {
    availabilityStatus = false;
    text = '-';
  } else if (notesCount === 0) {
    availabilityStatus = false;
    text = version !== 'ats' ? 'No Notes' : 'No Activities';
  } else if (notesCount > 0) {
    availabilityStatus = true;
    text = version !== 'ats' ? 'Notes' : 'Activities';
  } else {
    availabilityStatus = false;
    text = '-';
  }
  return { text, availabilityStatus };
}

export { sortNotes, getNotesIndicatorTextAndStatus };
