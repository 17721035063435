import React from 'react';
import classNames from 'classnames';
import styles from './CandidateAssignedStatus.module.scss';

export default function CandidateAssignedStatus({ assignedStatus, candidateOrigin = '' }) {
  let statusText = assignedStatus;
  if (statusText?.toLowerCase() === 'applied' && candidateOrigin) {
    statusText = statusText?.concat(' through ', candidateOrigin);
  }

  return (
    <div className={styles.candidateAssignedStatusWrapper}>
      <div className={classNames(styles.candidateAssignedStatusText, styles.candidate360AssignedStatusText)}>
        {statusText}
      </div>
    </div>
  );
}
