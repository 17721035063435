export const SET_RELEASE_ANNOUNCEMENTS = 'SET_RELEASE_ANNOUNCEMENTS';
export const RESET_RELEASE_ANNOUNCEMENT = 'RESET_RELEASE_ANNOUNCEMENT';
export const SET_DOWNTIME_ANNOUNCEMENTS = 'SET_DOWNTIME_ANNOUNCEMENTS';
export const RESET_DOWNTIME_ANNOUNCEMENT = 'RESET_DOWNTIME_ANNOUNCEMENT';
export const SET_DOWNTIME_RENDER_FLAG = 'SET_DOWNTIME_RENDER_FLAG';

export function setReleaseAnnouncements(data) {
  return {
    type: SET_RELEASE_ANNOUNCEMENTS,
    payload: data,
  };
}

export function setDowntimeAnnouncements(data) {
  return {
    type: SET_DOWNTIME_ANNOUNCEMENTS,
    payload: data,
  };
}

export const setDowntimeRenderFlag = payload => ({
  type: SET_DOWNTIME_RENDER_FLAG,
  payload,
});

export function resetReleaseAnnouncement() {
  return {
    type: RESET_RELEASE_ANNOUNCEMENT,
  };
}

export function resetDowntimeAnnouncement() {
  return {
    type: RESET_DOWNTIME_ANNOUNCEMENT,
  };
}
