import _ from 'lodash';
import { DraftPublishedStatus, SuccessPublishedStatus } from '../Utils/JobDistributionUtils/JobDistributionUtils';
import {
  SET_DISTRIBUTED_JOB_INFO,
  SET_DISTRIBUTED_JOB_PORTALS,
  SET_MONSTER_JOB_INVENTORIES,
  SET_PORTAL_TARIFF,
  RESET_DISTRIBUTED_JOB_INFO,
  RESET_JOB_DISTRIBUTION,
} from '../Actions/ActionCreators/JobDistributionActionCreator';

const initialState = {
  DistributedPortals: [],
  DraftedPortals: [],
  RecalledPortals: [],
  DistributedInfo: {
    monster: { DistributionQuery: {} },
    jobtarget: { DistributionQuery: {} },
    adzuna: { DistributionQuery: {} },
    linkedin: { DistributionQuery: {} },
    talroo: { DistributionQuery: {} },
  },
  MonsterInventories: [],
  PortalTariff: {},
};

function JobDistributionReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case SET_DISTRIBUTED_JOB_INFO:
      newState = _.cloneDeep(state);
      newState.DistributedInfo[action.payload.data.Portal.toLowerCase()] = action.payload.data;
      return newState;

    case SET_DISTRIBUTED_JOB_PORTALS:
      newState = _.cloneDeep(state);
      if (action.payload.setDraft) {
        newState.DraftedPortals = action.payload.portals
          .filter(p => DraftPublishedStatus.includes(p.DistributionStatus?.toLowerCase()))
          .map(p => p.Portal);
      }
      if (action.payload.setDistributed) {
        newState.DistributedPortals = action.payload.portals
          .filter(
            p =>
              SuccessPublishedStatus.includes(p.DistributionStatus?.toLowerCase()) &&
              !SuccessPublishedStatus.includes(p.RecallStatus?.toLowerCase())
          )
          .map(p => p.Portal);
      }
      if (action.payload.setRecalled) {
        newState.RecalledPortals = action.payload.portals
          .filter(p => SuccessPublishedStatus.includes(p.RecallStatus?.toLowerCase()))
          .map(p => p.Portal);
      }
      action.payload.portals.forEach(portal => {
        if (newState.DistributedInfo[portal.Portal.toLowerCase()]) {
          newState.DistributedInfo[portal.Portal.toLowerCase()].CreatedBy = portal?.CreatedBy;
          newState.DistributedInfo[portal.Portal.toLowerCase()].UpdatedBy = portal?.UpdatedBy;
        }
      });
      return newState;

    case SET_MONSTER_JOB_INVENTORIES:
      newState = _.cloneDeep(state);
      newState.MonsterInventories = action.payload;
      return newState;

    case SET_PORTAL_TARIFF:
      newState = _.cloneDeep(state);
      newState.PortalTariff[action.payload.portal] = action.payload;
      return newState;

    case RESET_DISTRIBUTED_JOB_INFO:
      newState = _.cloneDeep(state);
      newState.DistributedInfo[action.payload.toLowerCase()] =
        initialState.DistributedInfo[action.payload.toLowerCase()];
      return newState;

    case RESET_JOB_DISTRIBUTION:
      return initialState;

    default:
      return state;
  }
}

function getJobDistributedPortals(state) {
  return state.JobDistributionReducer.DistributedPortals;
}

function getJobDraftedPortals(state) {
  return state.JobDistributionReducer.DraftedPortals;
}

function getJobRecalledPortals(state) {
  return state.JobDistributionReducer.RecalledPortals;
}

function getJobDistributedInfo(state, portal) {
  return state.JobDistributionReducer.DistributedInfo[portal.toLowerCase()];
}

function getJobPortalsDistributedInfo(state) {
  return state.JobDistributionReducer.DistributedInfo;
}

function getMonsterJobInventories(state) {
  return state.JobDistributionReducer.MonsterInventories;
}

function getPortalTariffInfo(state, portal) {
  return state.JobDistributionReducer.PortalTariff[portal.toLowerCase()];
}

export {
  JobDistributionReducer,
  getJobDistributedPortals,
  getJobDraftedPortals,
  getJobRecalledPortals,
  getJobDistributedInfo,
  getJobPortalsDistributedInfo,
  getMonsterJobInventories,
  getPortalTariffInfo,
};
