import { setApiStatus } from '../ApiStatusActions';

export const SET_CANDIDATE_SUGGESTED_TAGS = 'SET_CANDIDATE_SUGGESTED_TAGS';
export const CLEAR_CANDIDATE_SUGGESTED_TAGS = 'CLEAR_CANDIDATE_SUGGESTED_TAGS';

export const setCandidateSuggestedTags = payload => ({
  type: SET_CANDIDATE_SUGGESTED_TAGS,
  payload,
});

export const fetchSuggestedTagsApiStatus = status => {
  return setApiStatus({
    apiName: 'suggestedTagsApiStatus',
    status,
  });
};

export const clearCandidateSuggestedTags = () => ({
  type: CLEAR_CANDIDATE_SUGGESTED_TAGS,
});

export const setTagDeleteApiStatus = status => {
  const apiName = 'tagDeleteApiStatus';
  return setApiStatus({ apiName, status });
};
