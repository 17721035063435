import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal, Button, Select, Input, Icon, Form } from 'antd';
import * as AryaPayActions from '../../Actions/AryaPayActions';
import { getConfig } from '../../Reducers/ConfigReducer';

const { Option } = Select;

function CreditInfoNotEnough(props) {
  const { visible, onCreditInfoNotEnoughModalOk, form } = props;
  const dispatch = useDispatch();
  const userConfig = useSelector(state => getConfig(state));
  const header = "You don't have enough credits to pull contacts.";
  return (
    <div>
      <Modal visible={visible} footer={null} closable={false} centered>
        <div className="modal-container">
          <div className="icon-div">
            <Icon type="exclamation-circle" theme="filled" />
          </div>
          <div>
            <h3>{header}</h3>
            {_.get(userConfig, 'Role', '') !== 'Recruiter' ? (
              <div>
                <div className="credits-message">
                  <div className="credits-request-title">Request more credits</div>
                  {form.getFieldDecorator('Credits')(
                    <Select className="credits-request-amount" placeholder="Select an Option">
                      <Option value={500}>500</Option>
                      <Option value={1000}>1000</Option>
                      <Option value={2000}>2000</Option>
                      <Option value="Customize">Customize..</Option>
                    </Select>
                  )}
                </div>
                {form.getFieldValue('Credits') === 'Customize' ? (
                  <div className="other-amount-request">
                    <div className="other-amount-title">Enter other amount</div>
                    {form.getFieldDecorator('OtherAmount')(
                      <Input className="other-amount-input" placeholder="Enter credit amount" />
                    )}
                  </div>
                ) : null}
                <div className="action-button">
                  <Button
                    type="default"
                    shape="round"
                    style={{ marginRight: '8px' }}
                    key="cancel-button"
                    onClick={() => onCreditInfoNotEnoughModalOk(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    key="ok-button"
                    onClick={() => {
                      dispatch(
                        AryaPayActions.requestForCredits(
                          form.getFieldValue(form.getFieldValue('Credits') === 'Customize' ? 'OtherAmount' : 'Credits')
                        )
                      );
                      onCreditInfoNotEnoughModalOk(false);
                    }}
                  >
                    Send Request
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p className="credits-message">Contact your administrator to add credits to your account.</p>
                <div className="action-button">
                  <Button
                    type="primary"
                    shape="round"
                    key="ok-button"
                    onClick={() => onCreditInfoNotEnoughModalOk(false)}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form.create()(CreditInfoNotEnough);
