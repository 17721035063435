import React from 'react';
import StatsDetails from '../StatsDetails/StatsDetails';
import InsightsCard from './InsightsCard/InsightsCard';
import PerformanceGrowth from './InsightsCard/PerfomanceGrowth/PerformanceGrowth';
import styles from './SummaryReportInsights.module.scss';

export default function SummaryReportInsights(props) {
  const { jobInsights, candidateSourcingInsights, candidateInsights, dateRangeKey, subtitle } = props;

  const getStats = (insights = {}, growthUnit, timeRange = 'range') => {
    const { stats = [] } = insights;
    return (
      <div className={styles.statsWrapper}>
        {stats?.map((performanceData, index) => (
          <div key={index} className={styles.performanceStats}>
            <div className={styles.performanceDetails}>
              <div className={styles.performanceLabel}>{performanceData?.label}</div>
              <div className={styles.performanceCount}>{performanceData?.count ?? 0}</div>
              <PerformanceGrowth growthQuantity={performanceData?.percent ?? 0} growthUnit={growthUnit} />
            </div>
            {index !== stats?.length - 1 ? (
              <div className={styles.performanceDetails}>
                <div className={styles.performanceLabelSeparator} />
                <div className={styles.performanceStatsSeparator} />
                <div className={styles.performanceGrowthSeparator} />
              </div>
            ) : null}
          </div>
        ))}
        <div className={styles.dateRange}>{`from last ${timeRange}`}</div>
      </div>
    );
  };

  return (
    <StatsDetails title="Summary Report" subtitle={subtitle}>
      <div className={styles.summaryReportInsights}>
        <div className={styles.jobCard}>
          <InsightsCard
            type="jobs"
            statsText={getStats(jobInsights, '%', dateRangeKey)}
            infoText={jobInsights?.infoText}
          />
        </div>
        <div className={styles.candidateCard}>
          <InsightsCard
            type="candidates"
            statsText={getStats(candidateSourcingInsights, '%', dateRangeKey)}
            infoText={candidateSourcingInsights?.infoText}
          />
        </div>
        <div className={styles.connectionCard}>
          <InsightsCard
            type="connections"
            statsText={getStats(candidateInsights, '%', dateRangeKey)}
            infoText={candidateInsights?.infoText}
          />
        </div>
      </div>
    </StatsDetails>
  );
}
