import * as jobRepository from '../Repository/JobRepository';

export default function fetchJobSourcingStats(jobIds) {
  return dispatch => {
    if (jobIds && jobIds.length > 0) {
      jobRepository
        .fetchJobSourcingStats(jobIds)
        .then(response => {
          const payload = response.data;
          dispatch({
            type: 'SET_JOB_SOURCING_STATS',
            payload,
          });
        })
        .catch(() => {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              code: 'SOURCING_STATS_FETCH_FAILED',
              timeStamp: new Date(),
            },
          });
        });
    }
  };
}
