import React from 'react';
import { Route } from 'react-router-dom';
import AdvancedJobDetailsReviewContainer from '../../Containers/AdvancedJobCreation/AdvancedJobDetailsReviewContainer/AdvancedJobDetailsReviewContainer';
import AdvancedJobEditContainer from '../../Containers/AdvancedJobCreation/AdvancedJobEditContainer/AdvancedJobEditContainer';
import AdvancedJobFormContainer from '../../Containers/AdvancedJobCreation/AdvancedJobFormContainer';
import SelectJobCreation from '../SelectJobCreation/SelectJobCreation';

export default function AdvancedJobRouter({ match: { url } }) {
  return (
    <>
      <Route path={`${url}/select-mode`} component={SelectJobCreation} />
      <Route path={`${url}/create`} component={AdvancedJobFormContainer} />
      <Route path={`${url}/:jobId/edit`} component={AdvancedJobEditContainer} />
      <Route path={`${url}/:jobId/review`} component={AdvancedJobDetailsReviewContainer} />
    </>
  );
}
