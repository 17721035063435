import { Icon } from 'antd';
import * as React from 'react';

const CallSvg = props => (
  <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.172 15H9.298C4.168 15 0 10.832 0 5.702V1.828C0 .976.697.278 1.55.278h3.099c.852 0 1.55.698 1.55 1.55v3.1c0 .828-.659 1.51-1.48 1.549.34 1.999 1.836 3.502 3.804 3.812a1.547 1.547 0 0 1 1.55-1.488h3.099c.852 0 1.55.698 1.55 1.55v3.1c0 .852-.698 1.549-1.55 1.549ZM1.55 1.828v3.874c0 4.27 3.479 7.748 7.748 7.748h3.874v-3.099h-3.1v.775a.777.777 0 0 1-.774.775c-3.48 0-6.144-2.658-6.199-6.183 0-.21.078-.411.225-.558a.774.774 0 0 1 .55-.233h.775V1.828h-3.1Z"
      fill="#83878C"
    />
  </svg>
);

const CallIcon = props => <Icon component={() => CallSvg(props)} {...props} />;
export default CallIcon;
