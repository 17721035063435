import _ from 'lodash';
import React from 'react';
import { Spin } from 'antd';
import LocationsInsightView from '../../GlobalInsight/InsightList/LocationsInsightView';
import { getLocationSupplyInsightsList, defaultExperienceRanges } from '../../../Utils/GlobalInsightSupplyUtil';
import { getCityStateFromLocation } from '../../../Utils/LocationUtil';

export default class AdvancedJobLocationInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { jobDetails, fetchGlobalInsightSupplyList } = this.props;
    if (!jobDetails?.AutoExpand) {
      const { JobTitle, CountryCode, Location, Range } = jobDetails;
      const [City, State] = getCityStateFromLocation(Location);
      fetchGlobalInsightSupplyList(
        {
          CountryCode,
          Experiences: defaultExperienceRanges,
          State,
          City,
          Title: JobTitle,
          Distance: Range,
        },
        false,
        true
      );
    }
  }

  render() {
    const { jobDetails, globalInsightSupplyList, globalSupplyLocationApiStatus } = this.props;
    const { Location } = jobDetails;
    const [City, State] = getCityStateFromLocation(Location);
    const [listOfMapStates, listOfMapCities] = getLocationSupplyInsightsList(
      { City, State, Distance: jobDetails?.Range },
      globalInsightSupplyList
    );
    const payload = jobDetails?.AutoExpand ? { ...jobDetails } : { ...jobDetails, State };
    return (
      <Spin spinning={globalSupplyLocationApiStatus === 'INPROGRESS'}>
        <LocationsInsightView
          customTitle="Relevant candidate data by location"
          customDescription="Refer to the candidate distribution statistics based on available data"
          listOfMapStates={listOfMapStates}
          listOfMapCities={_.uniqBy(listOfMapCities, 'CityName')}
          supplyPayload={payload}
        />
      </Spin>
    );
  }
}
