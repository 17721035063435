import { apiV3Client } from './BaseRepository';

export function addClient(clientDetails) {
  return apiV3Client.post('clients', clientDetails);
}

export function patchUpdateClient(id, clientDetails) {
  return apiV3Client.patch(`clients/${id}`, clientDetails);
}

export function changeClientStatus(clientId, status) {
  return apiV3Client.patch(`clients/${clientId}`, {
    isActive: status,
  });
}

export function fetchClientList(filter, orgId) {
  if (!orgId) {
    return apiV3Client.post('clients/_search', filter);
  }
  return apiV3Client.post(`clients/_adminsearch?orgId=${orgId}`, filter);
}

export function fetchClientDetails(id) {
  return apiV3Client.get(`clients/${id}`);
}
