import React from 'react';
import { Modal, Icon } from 'antd';
import { AnnouncementCarousel } from './AnnouncementCarousel';
import styles from './AnnouncementModal.module.scss';

export const AnnouncementModal = props => {
  const { modalVisiblity, onClose, announcementItems } = props;
  return (
    <Modal
      bodyStyle={{ height: '450px' }}
      className={styles.aryaBannerModal}
      width={790}
      style={{ top: '64px' }}
      icon={<Icon type="question-circle" />}
      visible={modalVisiblity}
      footer={null}
      closable={false}
      maskStyle={{ backgroundColor: 'rgba(7,45,35,0.47)' }}
      zIndex={2001}
      destroyOnClose
    >
      <AnnouncementCarousel announcementItems={announcementItems} onClose={onClose} />
    </Modal>
  );
};
