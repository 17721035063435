import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import TemplatesSidebar from '../../Components/Connect/TemplatesSidebar/TemplatesSidebar';
import DripTemplatesContainer from '../BaseTemplates/DripTemplates/DripTemplatesContainer';
import EmailTemplatesContainer from '../BaseTemplates/EmailTemplates/EmailTemplatesContainer';
import TextTemplatesContainer from '../BaseTemplates/TextTemplates/TextTemplatesContainer';
import AryabotTemplatesContainer from '../BaseTemplates/AryabotTemplates/AryabotTemplatesContainer';
import * as UserActions from '../../Actions/UserActions';
import './TemplatesContainer.scss';

const mapDispatchToProps = {
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
};

class TemplatesContainer extends Component {
  constructor(props) {
    super(props);

    const templateTypes = ['emailTemplates', 'textTemplates', 'drips'];

    this.state = {
      templateTypes,
      activeTemplateType: 0,
    };

    this.onTemplateTypeChange = this.onTemplateTypeChange.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
  }

  componentDidMount() {
    const { fetchUsersForShare } = this.props;
    fetchUsersForShare(1);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isBotEnabled } = nextProps;
    const { templateTypes } = prevState;
    const allTemplateTypes = [...templateTypes];
    if (isBotEnabled && !allTemplateTypes.includes('aryabotTemplates')) {
      allTemplateTypes.push('aryabotTemplates');
    }
    return { templateTypes: allTemplateTypes };
  }

  onTemplateTypeChange(templateType) {
    const { templateTypes, activeTemplateType } = this.state;
    const index = templateTypes.findIndex(template => template === templateType.key);
    if (index !== -1 && index !== activeTemplateType) {
      this.setState({
        activeTemplateType: index,
      });
    }
  }

  selectTemplate() {
    const { version, jobDetails } = this.props;
    const { templateTypes, activeTemplateType } = this.state;
    switch (templateTypes[activeTemplateType]) {
      case 'emailTemplates':
        return <EmailTemplatesContainer version={version} jobDetails={jobDetails} />;
      case 'textTemplates':
        return <TextTemplatesContainer version={version} jobDetails={jobDetails} />;
      case 'drips':
        return <DripTemplatesContainer version={version} jobDetails={jobDetails} />;
      case 'aryabotTemplates':
        return <AryabotTemplatesContainer />;
      default:
        return null;
    }
  }

  render() {
    const { AppName } = this.props;
    const { templateTypes, activeTemplateType } = this.state;
    return (
      <Row>
        <Col span={4} className="eamiltemplate-leftmenu ">
          <TemplatesSidebar
            templateTypes={templateTypes}
            activeTemplateType={activeTemplateType}
            onTemplateTypeChange={this.onTemplateTypeChange}
            AppName={AppName}
          />
        </Col>
        <Col offset={1} span={19}>
          {this.selectTemplate()}
        </Col>
      </Row>
    );
  }
}

export default connect(null, mapDispatchToProps)(TemplatesContainer);
export { TemplatesContainer as TemplatesContainerWithoutStore };
