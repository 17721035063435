import React from 'react';
import _ from 'lodash';
import { Form, Button, Input, Dropdown, Menu, Slider, Checkbox, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import JobTitleDropdown from '../../Containers/JobTitleDropdown/JobTitleDropdown';
import SkillsSelection from '../SkillsSelection/SkillsSelection';
import { getTitleCase } from '../../Utils/TextUtils';
import { toBooleanQueryStringUsingOR, generateKeywordsFromBooleanString } from '../../Utils/BooleanStringUtility';
import { getUserGuids } from '../../Utils/JobUtils';
import { getalpha2Codefromalpha3Code } from '../../Utils/CountryUtils';
import { FormSelectComponent, FormDropdownInputComponent } from '../../Utils/FormComponentUtils';
import { locationMenuList } from '../../Utils/LocationUtils';
import { validateZipCode } from '../../Utils/FormValidators';
import WarningIcon from '../../Icons/WarningIcon';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import styles from './AdvancedJobForm.module.scss';

const FormItem = Form.Item;

const skillsFormLabel = (
  <div>
    <span>What are the most relevant skills for this role?</span>
    <span>
      <Tooltip key="skills-section-tooltip" title="You can add additional skills for the job once you proceed">
        <InfoCircleIcon className={styles.infoIcon} />
      </Tooltip>
    </span>
  </div>
);

class AdvancedJobForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCountryWideSearchEnabled: true,
      selectedLocation: '',
    };
    this.onChangeJobTitle = debounce(this.onChangeJobTitle, 300);
    this.validateZipCode = debounce(validateZipCode, 300);
    this.filterLocation = debounce(this.filterLocation, 300);
    this.callForZipCodes = debounce(this.callForZipCodes, 300);
    this.fetchClientsDelayed = _.debounce(this.fetchClientsInstantly, 300);
    this.getSkills = debounce(this.getSkills, 300);
  }

  componentDidMount() {
    const { fetchCountries, fetchClients } = this.props;
    this.handleInitialFormData();
    fetchCountries();
    fetchClients({
      From: 0,
      Size: 10,
      IsActive: true,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const zipcodeValidStatus = nextProps.utilities.isZipCodeInValid;
    if (
      !zipcodeValidStatus &&
      nextProps.utilities &&
      nextProps.utilities.manualLocation &&
      !_.isEqual(prevState.manualLocation, nextProps.utilities.manualLocation)
    ) {
      const zipCityState = [];
      if (nextProps.utilities.manualLocation.City) {
        zipCityState.push(nextProps.utilities.manualLocation.City);
      }
      if (nextProps.utilities.manualLocation.State) {
        zipCityState.push(nextProps.utilities.manualLocation.State);
      }
      const zipLocation = zipCityState.join(', ');
      if (!_.isEmpty(nextProps.utilities.manualLocation)) {
        nextProps.form.setFieldsValue({
          Location: zipLocation,
        });
      }
      return {
        manualLocation: nextProps?.utilities?.manualLocation,
        selectedLocation: zipLocation,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { advancedJobFormData } = this.props;
    if (!_.isEqual(prevProps.advancedJobFormData, advancedJobFormData)) {
      this.handleInitialFormData();
    }
  }

  handleInitialFormData = () => {
    const { advancedJobFormData, form, fetchSkills } = this.props;
    if (!_.isEmpty(advancedJobFormData)) {
      form.setFieldsValue({
        AutoExpand: advancedJobFormData?.AutoExpand ?? false,
        Range: advancedJobFormData?.Range,
      });
      if (advancedJobFormData?.Location) {
        this.setState({ selectedLocation: advancedJobFormData?.Location });
        this.callForZipCodes(advancedJobFormData?.Location);
      }
      const initialJobTitle = advancedJobFormData?.jobTitleData?.title;
      if (initialJobTitle) {
        fetchSkills(initialJobTitle);
      }
      this.setState({ isCountryWideSearchEnabled: advancedJobFormData?.AutoExpand ?? false });
    }
  };

  onChangeJobTitle = value => {
    const { fetchJobTitles, resetJobTitles } = this.props;
    const minLength = value?.trim().length;
    if (minLength && minLength > 2) {
      fetchJobTitles({ title: value });
    } else {
      resetJobTitles();
    }
  };

  getSkills = value => {
    const { fetchSkills } = this.props;
    fetchSkills(value);
  };

  onChangeSkills = value => {
    const { form } = this.props;
    const currentValue = form.getFieldValue('jobTitleData')?.title;
    if (currentValue !== value) {
      form.setFieldsValue({ skills: [] });
    }
    if (value) this.getSkills(value);
  };

  getCountryCode = location => {
    const { utilities } = this.props;
    const selectedLocation = utilities?.locations?.filter(loc => `${loc.City}, ${loc.State}` === location);
    const selectedCountryCode = selectedLocation?.[0]?.countryCode ?? 'US';
    return selectedCountryCode.length > 2 ? getalpha2Codefromalpha3Code(selectedCountryCode) : selectedCountryCode;
  };

  getLocationInputLabel = () => {
    return (
      <div className={styles.locationInputLabelWrapper}>
        <div>What is the job location? *</div>
        <div className={styles.locationLabelSecondaryContent}>US Locations Only*</div>
      </div>
    );
  };

  filterLocation = value => {
    const { fetchLocations } = this.props;
    const country = this.getCountryCode(value);
    fetchLocations({
      country,
      search: value,
    });
  };

  locationChange = value => {
    const { clearZipCodeError, form } = this.props;
    if (!value?.length) {
      form.setFieldsValue({
        Location: undefined,
        Zipcode: undefined,
      });
      clearZipCodeError();
    }
  };

  callForZipCodes = value => {
    const { fetchZipCodes, form } = this.props;
    const { selectedLocation } = this.state;
    const country = this.getCountryCode(value);
    if (value !== selectedLocation) {
      form.setFieldsValue({
        Zipcode: undefined,
      });
      this.setState({ selectedLocation: value });
    }
    fetchZipCodes({
      country,
      location: value,
    });
  };

  getZipCodeMenuList = zipcodeValidStatus => {
    const { utilities, form, searchZipCode, clearZipValidationError } = this.props;
    const zipCodeOptions = utilities?.zipCodes || [];
    const { Zipcode: zipCode, Location: location } = form.getFieldsValue();
    return zipCodeOptions.length > 0 ? (
      <Menu>
        {(zipCode ? zipCodeOptions.filter(zipcodes => zipcodes.includes(zipCode)) : zipCodeOptions).map(zipcode => (
          <Menu.Item
            key={zipcode}
            onClick={() => {
              form.setFieldsValue({ Zipcode: zipcode });
              this.validateZipCode(
                searchZipCode,
                clearZipValidationError,
                zipCodeOptions,
                this.getCountryCode(location),
                zipcode,
                zipcodeValidStatus
              );
            }}
          >
            {zipcode}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <div />
    );
  };

  disableActivateArya = zipcodeValidStatus => {
    const { isCountryWideSearchEnabled } = this.state;
    const { form } = this.props;
    const { jobTitleData, Location: location, Zipcode: zipCode, selectedClient } = form.getFieldsValue();
    return (
      !location ||
      !jobTitleData?.title ||
      zipcodeValidStatus ||
      (!isCountryWideSearchEnabled && !zipCode) ||
      !selectedClient
    );
  };

  handleCheckboxChange = () => {
    const { isCountryWideSearchEnabled } = this.state;
    const { form } = this.props;

    this.setState({ isCountryWideSearchEnabled: !isCountryWideSearchEnabled }, () => {
      form.setFieldsValue({ Range: isCountryWideSearchEnabled ? 100 : 1000 });
    });
  };

  getLatestHiringCompany = () => {
    const { utilities } = this.props;
    const companyOptions = utilities?.clients?.clients || [];
    const companyOptionsDescOrder = _.orderBy(companyOptions, ['CreatedDate'], ['desc']);
    return companyOptionsDescOrder?.[0]?.Name;
  };

  fetchClientsInstantly = filter => {
    const { fetchClients } = this.props;
    fetchClients(filter);
  };

  getClientMenuList = () => {
    const { utilities, form } = this.props;
    const companyOptions = utilities?.clients?.clients;
    return companyOptions?.length ? (
      <Menu style={{ maxHeight: '264px', overflowY: 'auto' }} className="client-menu-list">
        {companyOptions.map(client => (
          <Menu.Item key={client.Id} onClick={() => form.setFieldsValue({ selectedClient: client.Name })}>
            {client.Name}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <div />
    );
  };

  validateJobFormAndSubmit = values => {
    const { handleSubmit, featureToggleList, utilities } = this.props;
    const { isCountryWideSearchEnabled } = this.state;
    const companyOptions = utilities?.clients?.clients || [];
    const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
    const formValues = _.cloneDeep(values);
    const newJobDetails = {};

    formValues.JobTitle = formValues?.jobTitleData?.title;
    formValues.Synonyms = formValues?.jobTitleData?.titleSynonyms;

    if (formValues.Synonyms) _.set(newJobDetails, ['Synonyms'], formValues.Synonyms);

    if (isCountryWideSearchEnabled === true) {
      formValues.Range = 1000;
    }
    formValues.CountryCode = this.getCountryCode(formValues?.Location);
    const newCountryOption = utilities?.countries?.find(option => option.Iso2Code === formValues.CountryCode);
    formValues.Country = newCountryOption?.Name;

    if (isPaidJobServiceEnabled) {
      const { selectedClient } = formValues;
      formValues.Openings = 1;
      const foundClient = _.find(companyOptions, client => client?.Name === selectedClient);
      if (foundClient) {
        formValues.ClientId = foundClient.Id;
      } else {
        formValues.ClientName = formValues.selectedClient;
        delete newJobDetails.ClientId;
      }
    } else {
      formValues.ClientId = formValues.selectedClientId;
    }
    handleSubmit(newJobDetails, formValues, null);
  };

  handleSubmit = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.validateJobFormAndSubmit(values);
      }
    });
  };

  handleEditJob = () => {
    const { advancedJobFormData, handleEditJob, form, usersById, utilities } = this.props;
    const companyOptions = utilities?.clients?.clients || [];
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const newJobDetails = _.cloneDeep(advancedJobFormData);
        newJobDetails.AssignedTo = getUserGuids(newJobDetails?.AssignedTo, usersById);
        newJobDetails.JobTitle = values?.jobTitleData?.title;
        newJobDetails.Location = values?.Location;
        newJobDetails.Client = values?.selectedClient;
        const { selectedClient } = values;
        const foundClient = _.find(companyOptions, client => client?.Name === selectedClient);
        if (foundClient) {
          newJobDetails.ClientId = foundClient.Id;
        } else {
          delete newJobDetails.ClientId;
          newJobDetails.ClientName = selectedClient;
        }

        delete newJobDetails?.jobTitleData;
        newJobDetails.Zipcode = values?.Zipcode;
        newJobDetails.Range = values?.Range;
        newJobDetails.AutoExpand = values?.AutoExpand;
        const selectedNiceToHaves = values?.skills || [];
        const niceToHaves = [];
        const modifiedMustHaves = [];
        const mustHaves = generateKeywordsFromBooleanString(advancedJobFormData?.MustHaves || '');
        const formattedSkills = mustHaves.map(skill => skill?.toLowerCase());
        selectedNiceToHaves.forEach(skill => {
          if (!formattedSkills.includes(skill?.toLowerCase())) {
            niceToHaves.push(skill);
          } else {
            modifiedMustHaves.push(skill);
          }
        });
        if (modifiedMustHaves.length !== mustHaves.length) {
          newJobDetails.MustHaves = toBooleanQueryStringUsingOR(modifiedMustHaves);
        }
        newJobDetails.NiceToHaves = toBooleanQueryStringUsingOR(niceToHaves);
        handleEditJob(newJobDetails);
      }
    });
  };

  zipCodeValidator = value => {
    const { searchZipCode, clearZipValidationError, utilities, form } = this.props;
    const { Zipcode: zipCode, Location: location } = form.getFieldsValue();
    const zipcodeValidStatus = utilities?.isZipCodeInValid && !utilities?.zipCodes?.includes(zipCode);

    this.validateZipCode(
      searchZipCode,
      clearZipValidationError,
      utilities?.zipCodes || [],
      this.getCountryCode(location),
      value,
      !zipcodeValidStatus
    );
  };

  setLocationDropDownStatus = () => {
    const { form, locationApiStatus } = this.props;
    const { Location: location } = form.getFieldsValue();
    if (location) return locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
    return 'Type to search';
  };

  getDefaultSkills = () => {
    const { advancedJobFormData } = this.props;
    const mustHaves = generateKeywordsFromBooleanString(advancedJobFormData?.MustHaves);
    const niceToHaves = generateKeywordsFromBooleanString(advancedJobFormData?.NiceToHaves);
    return niceToHaves.concat(mustHaves);
  };

  render() {
    const { form, jobTitles, advancedJobFormData, utilities, createJobStatus, editJobStatus, skills } = this.props;

    const { getFieldDecorator, getFieldValue } = form;
    const { isCountryWideSearchEnabled } = this.state;
    const isLoading = createJobStatus === 'INPROGRESS' || editJobStatus === 'INPROGRESS';
    const jobTitlesMenu = jobTitles?.map(jobTitle => getTitleCase(jobTitle)) ?? [];
    const initialJobTitle = advancedJobFormData?.jobTitleData?.title;
    const initialTitleSynoyms = advancedJobFormData?.jobTitleData?.titleSynonyms;
    const { Zipcode: zipCode } = form.getFieldsValue();
    const locationDropDownStatus = this.setLocationDropDownStatus();
    const zipcodeValidStatus = utilities?.isZipCodeInValid && !utilities?.zipCodes?.includes(zipCode);
    const disableActivateAryaButton = this.disableActivateArya(zipcodeValidStatus);

    return (
      <div className={styles.formWrapper}>
        <div className={styles.formHeader}>
          <span>Tell us about your job opening!</span>
        </div>
        <div className={styles.formAlignment}>
          <Form layout="vertical" hideRequiredMark>
            <div>
              {getFieldDecorator('jobTitleData', {
                initialValue: {
                  title: initialJobTitle ?? '',
                  titleSynonyms: initialTitleSynoyms ?? [],
                },
              })(
                <JobTitleDropdown
                  jobTitles={jobTitlesMenu}
                  showSynonymsDropdown={false}
                  onTitleChange={value => {
                    this.onChangeJobTitle(value);
                  }}
                  onTitleClick={value => {
                    this.onChangeJobTitle(value);
                    this.onChangeSkills(value);
                  }}
                  onBlur={value => {
                    this.onChangeJobTitle(value);
                    this.onChangeSkills(value);
                  }}
                />
              )}
            </div>
            {initialJobTitle && (
              <div className={styles.warningTextWrapper}>
                <WarningIcon />
                <span className={styles.titleEditWarningText}>
                  Editing the title will affect all insights and may cause loss of inputs
                </span>
              </div>
            )}

            <SkillsSelection
              skillsList={skills}
              initialValue={this.getDefaultSkills()}
              form={form}
              jobtitle={getFieldValue('jobTitleData')?.title}
              formLabel={skillsFormLabel}
            />

            <div className={styles.formFieldAlignment}>
              <FormItem label={<div>What is the name of the hiring company? *</div>} colon={false}>
                <Dropdown overlay={this.getClientMenuList()} trigger={['click']} forceRender>
                  {getFieldDecorator('selectedClient', {
                    initialValue: advancedJobFormData?.selectedClient || this.getLatestHiringCompany(),
                    rules: [{ required: true, message: 'Please select Hiring Company' }],
                  })(
                    <Input
                      placeholder="Select a company"
                      allowClear
                      onChange={e => {
                        this.fetchClientsDelayed({ SearchTerm: e.target.value, From: 0 });
                      }}
                    />
                  )}
                </Dropdown>
              </FormItem>
            </div>

            <div className={styles.formFieldAlignment}>
              <FormSelectComponent
                label={this.getLocationInputLabel()}
                placeholder="Ex: Raleigh, North Carolina"
                fieldDecoratorValue="Location"
                notFoundContent={locationDropDownStatus}
                className="location-select"
                showSearch
                showArrow={false}
                allowClear
                inputValues={locationMenuList(utilities?.locations || [])}
                initialValue={advancedJobFormData?.Location}
                onSearch={value => {
                  if (value) this.filterLocation(value);
                }}
                onSelect={this.callForZipCodes}
                onChange={this.locationChange}
                form={form}
              />
            </div>

            <span className={styles.remoteHiringText}>If hiring for remote jobs, please check the box below</span>

            <div className={styles.formFieldAlignment}>
              {getFieldDecorator('AutoExpand', {
                valuePropName: 'checked',
                initialValue: isCountryWideSearchEnabled,
              })(<Checkbox onChange={this.handleCheckboxChange}>Search the entire United States</Checkbox>)}
            </div>

            {!isCountryWideSearchEnabled && (
              <div className={styles.zipCodeMilesWrapper}>
                <div>
                  <FormDropdownInputComponent
                    label={<div>Postal Code *</div>}
                    placeholder="Postal Code"
                    fieldDecoratorValue="Zipcode"
                    errorMessage="Invalid Postal Code"
                    autoComplete="disabled"
                    dropdownOverlayClassName="zipcode-dropdown"
                    className="zipcode-input"
                    showArrow={false}
                    allowClear
                    inputValues={utilities?.zipCodes || []}
                    initialValue={advancedJobFormData?.Zipcode}
                    validityStatus={!zipcodeValidStatus}
                    onChange={this.zipCodeValidator}
                    onClick={value => {
                      form.setFieldsValue({ Zipcode: value });
                      this.zipCodeValidator(value);
                    }}
                    form={form}
                  />
                </div>

                <div className={styles.milesSlider}>
                  <FormItem label={<div>Maximum miles from job location</div>} colon={false}>
                    {getFieldDecorator('Range', {
                      initialValue: advancedJobFormData?.Range || 100,
                    })(<Slider className={styles.milesTooltip} disabled={isCountryWideSearchEnabled} min={5} />)}
                  </FormItem>
                </div>
              </div>
            )}

            <div className={styles.saveBtn}>
              <FormItem>
                <Button
                  type="primary"
                  shape="round"
                  style={{ width: '50%' }}
                  onClick={advancedJobFormData?.JobId ? this.handleEditJob : this.handleSubmit}
                  loading={isLoading}
                  disabled={disableActivateAryaButton}
                >
                  Save and Proceed
                </Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export { AdvancedJobForm as AdvancedJobFormWithoutForm };
export default Form.create()(AdvancedJobForm);
