const minSalaryValidator = (form, [, , callback]) => {
  const minSalary = form.getFieldValue('monster-min-salary');
  const maxSalary = form.getFieldValue('monster-max-salary');
  if (minSalary > maxSalary) {
    callback('Min salary should not be greater than Max salary');
  }
  callback();
  form.validateFields(['monster-max-salary']);
};

const maxSalaryValidator = (form, [, , callback]) => {
  const minSalary = form.getFieldValue('monster-min-salary');
  const maxSalary = form.getFieldValue('monster-max-salary');
  if (maxSalary === 0) {
    callback('Max salary cannot be zero');
  }
  if (maxSalary < minSalary) {
    callback('Max salary should not be less than Min salary');
  }
  callback();
  form.validateFields(['monster-min-salary']);
};

export { minSalaryValidator, maxSalaryValidator };
