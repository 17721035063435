import React from 'react';
import PubSub from 'pubsub-js';
import { Button, Icon } from 'antd';
import { CreateJobIcon } from '../../Icons/AryaIcons';
import { CREATE_JOB } from '../../PubSub/EventTypes';
import styles from './JobLanding.module.scss';
import PlanCredits from '../Payment/PlanCredits';
import JobCreationFlowStepper from '../Utils/JobCreationFlowStepper/JobCreationFlowStepper';

export default function JobLanding(props) {
  const { history, planDetailsByProductVariantId, productVariantsById, featureToggleList } = props;
  const isAdvancedJob = featureToggleList?.AdvancedJobCreation?.IsEnabled;
  const totalcredit = Object.values(planDetailsByProductVariantId || {})
    .map(plan => plan.AvailableCredits)
    .reduce((totalCredits, availableCredits) => totalCredits + availableCredits, 0);
  return (
    <div className={styles.jobLandingWrapper}>
      <div className={styles.joblandingContainer}>
        <div className={styles.credits}>
          {totalcredit > 0 ? (
            <PlanCredits
              planDetailsByProductVariantId={planDetailsByProductVariantId}
              productVariantsById={productVariantsById}
              isCreateJobPage
            />
          ) : null}
        </div>
        <CreateJobIcon />
        <h2>Let&apos;s create your first job!</h2>
        <div className={styles.jobCreationFlowStepperWrapper}>
          <JobCreationFlowStepper />
        </div>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            PubSub.publish(CREATE_JOB, {
              isAdvanced: isAdvancedJob,
            });
            history.push('/jobs/create');
          }}
        >
          <Icon type="plus" /> Create Job
        </Button>
      </div>
    </div>
  );
}
