import { v4 as uuid } from 'uuid';
import * as d3 from 'd3';
import _ from 'lodash';

const getCandidateScorelabel = scoreType => {
  switch (scoreType) {
    case 'SkillScore':
      return 'Skill';
    case 'RoleScore':
      return 'Role';
    case 'IndustryScore':
      return 'Industry';
    case 'EducationScore':
      return 'Education';
    case 'OccupationScore':
      return 'Occupation';
    case 'CompanyScore':
      return 'Company';
    case 'ExperienceScore':
      return 'Experience';
    default:
      return 'Score';
  }
};

const getCircleColor = type => {
  let color;
  switch (type) {
    case 'Candidate':
      color = '#5B8FF9';
      break;
    case 'Job':
      color = '#13C26B';
      break;
    default:
      color = 'rgba(7,16,26,0.3)';
  }

  return color;
};

const getColorForScoreType = scoreType => (scoreType === 'JobRelevancyScore' ? '#5B8FF9' : '#13C26B');

const prepareDataForIndustryRelevanceSankeyDiagram = rawData => {
  const { CandidateIndustry, RelevantIndustries } = rawData;
  const targetUUID = uuid();
  const targetIndustryName = CandidateIndustry;

  const nodes = [
    {
      id: targetUUID,
      name: targetIndustryName,
    },
  ];
  const links = [];

  const industryWithMaxRelevancy = _.maxBy(RelevantIndustries, 'Relevancy');

  RelevantIndustries.forEach(industry => {
    nodes.push({
      id: uuid(),
      name: industry.Industry,
      value: Math.max(industry.Relevancy, industryWithMaxRelevancy.Relevancy / 2),
      relevancy: industry.Relevancy,
    });
  });

  nodes.forEach(node => {
    if (node.id !== targetUUID) {
      links.push({
        id: uuid(),
        source: node.id,
        target: targetUUID,
        value: node.value,
        relevancy: node.relevancy,
      });
    }
  });
  return { nodes, links };
};

const prepareDataForOccupationalSimilarityScatterPlot = occupationData => {
  const formattedData = occupationData.Others.map(d => ({
    ...d,
    Type: 'Others',
  }));
  formattedData.push({ ...occupationData.CandidateOccupation, Type: 'Candidate' });
  formattedData.push({ ...occupationData.JobOccupation, Type: 'Job' });
  return formattedData;
};

const prepareDataForRoleSimilarityScatterPlot = roleData => {
  const formattedData = roleData.Others.map(d => ({
    ...d,
    Type: 'Others',
  }));
  formattedData.push({ ...roleData.CandidateRole, Type: 'Candidate' });
  formattedData.push({ ...roleData.JobRole, Type: 'Job' });
  return formattedData;
};

const prepareDataForSkillProximityScatterPlot = skillsRelevance => {
  const skills = [];
  skillsRelevance.CandidateSkills.forEach(skill => {
    skills.push({
      ...skill,
      Type: 'Candidate',
    });
  });
  skillsRelevance.JobSkills.forEach(skill => {
    skills.push({
      ...skill,
      Type: 'Job',
    });
  });
  return skills;
};

const getXAxisDomain = data => {
  return [Math.floor(d3.min(data, d => d.XCoordinate)), Math.ceil(d3.max(data, d => d.XCoordinate))];
};

const getYAxisDomain = data => {
  return [Math.floor(d3.min(data, d => d.YCoordinate)), Math.ceil(d3.max(data, d => d.YCoordinate))];
};

export {
  getCandidateScorelabel,
  getCircleColor,
  getColorForScoreType,
  prepareDataForIndustryRelevanceSankeyDiagram,
  prepareDataForOccupationalSimilarityScatterPlot,
  prepareDataForRoleSimilarityScatterPlot,
  prepareDataForSkillProximityScatterPlot,
  getXAxisDomain,
  getYAxisDomain,
};
