import _ from 'lodash';
// !TO BE REMOVED AFTER PROD API DEPLOYEMENT
// !CreatedDate: 27-08-2019

export function getResumeName(candidateDetails) {
  if (candidateDetails.LatestResumeName) {
    return candidateDetails.LatestResumeName;
  }

  const resumeName = _.get(candidateDetails, ['Resumes', 0, 'Name']);
  const originalResumeName = _.get(candidateDetails, ['Resumes', 0, 'OriginalName']);
  return originalResumeName || resumeName;
}

export function getCandidateGuid(candidateDetails) {
  const primarySources = _.get(candidateDetails, 'Sources', []).filter(source => source.Type === 'Primary');
  const [primarySource] = primarySources;
  if (primarySource && primarySource.Portal && primarySource.Portal.toLowerCase() === 'indeed') {
    return primarySource.CandidateId;
  }
  return candidateDetails.Guid;
}
