import React from 'react';
import './NetworkErrorComponent.scss';

export function NetworkErrorComponent() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="network-error">
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/static/Images/network-error.svg`}
          alt="Network error"
          className="network-error-img"
        />
      </div>
      <div className="network-error-title">
        <span>Oops, something just went wrong</span>
      </div>
      <div className="network-error-message">
        <span onClick={refreshPage} onKeyDown={refreshPage} tabIndex="-1" role="button">
          Try refreshing the page
        </span>
      </div>
    </div>
  );
}
