import React from 'react';
import { FormSelectComponent } from '../../../../../../Utils/FormComponentUtils';

const dateFormatOptionValues = [
  {
    value: 'DD/MM/YYYY',
    label: 'DD/MM/YYYY',
  },
  {
    value: 'MM-DD-YYYY',
    label: 'MM-DD-YYYY',
  },
  {
    value: 'YYYY-MM-DD',
    label: 'YYYY-MM-DD',
  },
  {
    value: 'YYYY/MM/DDTHH:MM:SS',
    label: 'YYYY/MM/DDTHH:MM:SS',
  },
];

export default function DateFormatSelect(props) {
  const { form, initialValue, fieldDecoratorValue, label, className, onChange, dropdownClassName } = props;

  return (
    <FormSelectComponent
      fieldDecoratorValue={fieldDecoratorValue}
      placeholder="Select date and time format"
      className={className}
      showArrow
      label={label}
      form={form}
      initialValue={initialValue}
      inputValues={dateFormatOptionValues}
      onChange={onChange}
      dropdownClassName={dropdownClassName}
    />
  );
}

DateFormatSelect.defaultProps = {
  fieldDecoratorValue: 'dateFormat',
};
