import React from 'react';
import PubSub from 'pubsub-js';
import { Modal, Button, Icon } from 'antd';
import jobPlaceConfirmationDialogueBoxContent from '../../../Utils/ConfirmationDialogueBoxContent';
import { getJobDetailsForAnalytics } from '../../../Utils/JobUtils';
import { FREE_JOB_CHECKOUT } from '../../../PubSub/EventTypes';
import styles from './ConfirmationDialogueBox.module.scss';

export default function ConfirmationDialogueBox(props) {
  const { confirmationDialogueBoxVisibility, onCancel, onPlaceJob, jobDetails, isLoading } = props;
  const { title, subTitle, submitButtonText, cancelButtonText, submitAction } = jobPlaceConfirmationDialogueBoxContent;
  const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);

  React.useEffect(() => {
    if (confirmationDialogueBoxVisibility) {
      PubSub.publish(FREE_JOB_CHECKOUT, {
        jobId: jobDetails?.JobId,
        jobTitle: jobDetails?.JobTitle,
        isAdvanced: jobDetails?.IsAdvancedJob,
        jobDetails: analyticsJobDetails,
      });
    }
  }, [confirmationDialogueBoxVisibility]);
  const handleOnSubmit = () => {
    onPlaceJob(jobDetails, null);
  };
  const handleOnCancel = () => {
    onCancel(jobDetails, null, submitAction);
  };
  return (
    <Modal
      destroyOnClose
      visible={confirmationDialogueBoxVisibility}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      width={500}
      closable={false}
      zIndex={2050}
    >
      <div className={styles.confirmationWrapper}>
        <span className={styles.icon}>
          <Icon className={styles.warningIcon} type="exclamation-circle" theme="filled" />
        </span>
        <div className={styles.textWrapper}>
          <div className={styles.confirmationTitle}>{title}</div>
          <div className={styles.confirmationSubTitle}>{subTitle}</div>
          <div className={styles.buttons}>
            <Button
              type="default"
              shape="round"
              disabled={isLoading}
              className={styles.cancelButton}
              onClick={handleOnCancel}
              key="save-job-button"
            >
              {cancelButtonText}
            </Button>
            <Button type="primary" shape="round" loading={isLoading} onClick={handleOnSubmit} key="place-job-button">
              {submitButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
