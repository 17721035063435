import cleanSet from 'clean-set';
import _ from 'lodash';
import {
  SET_WORKFLOW_REPORT_MODAL_VISIBILITY,
  SET_WORKFLOW_REPORT_DATA,
  SET_WORKFLOW_REPORT_FILTER_DATA,
  SET_WORKFLOW_REPORT_END_NODES_DATA,
  SET_WORKFLOW_REPORT_FETCH_API_STATUS,
  SET_WORKFLOW_PUBLISHED_STATUS,
} from '../Actions/ActionCreators/WorkflowReportActionCreator';

const initialState = {
  ModalVisible: false,
  ReportData: {},
  FilterData: {},
  EndNodesData: {},
  WorkflowReportFetchApiStatus: false,
};

function endNodePayload(oldState, newNodes) {
  const newState = _.cloneDeep(oldState);
  if (newNodes.reinitiate) {
    return {};
  }
  Object.entries(newNodes).forEach(node => {
    const [key, value] = node;
    if (key in newState && key !== 'isRendered') {
      newState[key] += value;
    } else newState[key] = value;
  });
  return newState;
}

function WorkflowReportReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_WORKFLOW_REPORT_MODAL_VISIBILITY:
      newState = cleanSet(state, 'ModalVisible', action.payload.visible);
      return newState;
    case SET_WORKFLOW_REPORT_DATA:
      newState = cleanSet(state, 'ReportData', action.payload);
      return newState;
    case SET_WORKFLOW_REPORT_FILTER_DATA:
      newState = cleanSet(state, 'FilterData', action.payload);
      return newState;
    case SET_WORKFLOW_REPORT_END_NODES_DATA: {
      const newObj = endNodePayload(state.EndNodesData, action.payload);
      newState = cleanSet(state, 'EndNodesData', newObj);
      return newState;
    }
    case SET_WORKFLOW_REPORT_FETCH_API_STATUS:
      newState = cleanSet(state, 'WorkflowReportFetchApiStatus', action.payload.status);
      return newState;
    case SET_WORKFLOW_PUBLISHED_STATUS:
      newState = cleanSet(state, 'IsWorkflowPublished', action.payload);
      return newState;
    default:
      return state;
  }
}

function getWorkflowReportModalVisibility(state) {
  return state.WorkflowReportReducer.ModalVisible;
}

function getWorkflowReportData(state) {
  return state.WorkflowReportReducer.ReportData;
}

function getWorkflowReportFilterData(state) {
  return state.WorkflowReportReducer.FilterData;
}

function getWorkflowReportEndNodesData(state) {
  return state.WorkflowReportReducer.EndNodesData;
}

function getWorkflowReportApiStatus(state) {
  return state.WorkflowReportReducer.WorkflowReportFetchApiStatus;
}

function getWorkflowPublishedStatus(state) {
  return state.WorkflowReportReducer.IsWorkflowPublished;
}

export {
  WorkflowReportReducer,
  getWorkflowReportModalVisibility,
  getWorkflowReportFilterData,
  getWorkflowReportData,
  getWorkflowReportEndNodesData,
  getWorkflowReportApiStatus,
  getWorkflowPublishedStatus,
};
