import React from 'react';
import * as d3 from 'd3';
import './DonutChart.scss';
import DonutInnerRadiusText from './DonutInnerRadiusText';
import DonutBackground from './DonutBackGround';

const groupPlacementShift = { left: 10, top: 10 };

function createArc(height, innerRadius) {
  const outerRadius = height / 2;
  return d3
    .arc()
    .outerRadius(outerRadius)
    .innerRadius(outerRadius - innerRadius);
}

function createPie() {
  return d3
    .pie()
    .sort(null)
    .value(d => d.relevancy);
}

class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.state = {
      arc: null,
      pie: null,
      toolTipInfo: {},
    };
  }

  componentDidMount() {
    this.createDonutChart();
  }

  createDonutChart() {
    const { ChartConfig } = this.props;
    const { height, innerRadius } = ChartConfig;
    this.setState({
      arc: createArc(height, innerRadius),
      pie: createPie(),
    });
  }

  getTextPosition({ arc, d }) {
    const { ChartConfig } = this.props;
    const { width, height } = ChartConfig;
    const radius = Math.min(width, height) / 2 - 25;
    const c = arc.centroid(d);
    const x = c[0];
    const y = c[1];
    const h = Math.sqrt(x * x + y * y);
    return `translate(${(x / h) * radius}, ${(y / h) * radius})`;
  }

  mosueOverEventHandler = (event, d) => {
    const tooltip = d3.select('.tooltip-donut');
    const { degree, fillColor, displayText } = d.data;
    const { offsetX, offsetY } = event.nativeEvent;
    const toolTipInfo = { degree, fillColor, displayText, offsetX, offsetY };
    this.setState({ toolTipInfo });
    tooltip
      .transition()
      .duration(200)
      .style('opacity', 1);
    d3.select(event.target).attr('filter', "url('#inner-shadow')");
  };

  mosueOutEventHandler = event => {
    const tooltip = d3.select('.tooltip-donut');
    tooltip
      .transition()
      .duration(300)
      .style('opacity', 0);
    d3.select(event.target).attr('filter', null);
  };

  render() {
    const { ChartConfig, DonutChartList } = this.props;
    const { width, height, innerRadiusInfo } = ChartConfig;
    const { arc, pie, toolTipInfo } = this.state;

    if (!arc || !pie) {
      return <div>Loading...</div>;
    }

    return (
      <div id="donutChart">
        <svg ref={this.svgRef} width={width + 20} height={height + 20} className="pie-chart">
          <DonutBackground width={width} height={height} />
          <g
            transform={`translate(${width / 2 + groupPlacementShift.left}, ${height / 2 + groupPlacementShift.top})`}
            id="education-group"
          >
            {pie(DonutChartList).map(d => {
              const { displayText, fillColor } = d.data;
              return (
                <React.Fragment key={d.data.Degree}>
                  <path
                    className="donut-arc"
                    fill={fillColor}
                    d={arc(d)}
                    onFocus={event => this.mosueOverEventHandler(event, d)}
                    onMouseOver={event => this.mosueOverEventHandler(event, d)}
                    onBlur={this.mosueOutEventHandler}
                    onMouseOut={this.mosueOutEventHandler}
                  />

                  <text transform={this.getTextPosition({ arc, d })} textAnchor="middle" fontSize="12px">
                    {displayText}
                  </text>
                </React.Fragment>
              );
            })}

            <DonutInnerRadiusText InnerRadiusInfo={innerRadiusInfo} />
          </g>
        </svg>
        <div className="tooltip-donut" style={{ left: toolTipInfo.offsetX, top: toolTipInfo.offsetY, opacity: 0 }}>
          <div className="donut-tooltip-content">
            <p className="donut-tooltip-heading">
              {innerRadiusInfo?.topText}
              {innerRadiusInfo?.bottomText}
            </p>
            <div className="tooltip-details-info">
              <div className="label-color" style={{ backgroundColor: toolTipInfo?.fillColor }}></div>
              <div className="label-heading">{toolTipInfo?.degree}</div>
              <div>{toolTipInfo?.displayText}%</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonutChart;
