import { fetchOrganizations, fetchAccountTypes, fetchLicenceTypes } from '../Repository/OrganizationRepository';
import { setAccountTypes, setLicenceTypes } from './ActionCreators/OrganizationActionCreator';

function getOrganizations(page, SearchTerm) {
  return dispatch => {
    fetchOrganizations({
      From: (page - 1) * 10,
      Size: 10,
      SearchKeyword: SearchTerm,
    }).then(res => {
      const orgData = res.data;
      dispatch({
        type: 'SET_ORGANIZATIONS',
        payload: { orgData, SearchTerm: SearchTerm || null },
      });
    });
  };
}

function getAccountTypes() {
  return async dispatch => {
    const accountTypes = await fetchAccountTypes();
    dispatch(setAccountTypes(accountTypes.data));
  };
}

function getLicenceTypes() {
  return async dispatch => {
    const licenceTypes = await fetchLicenceTypes();
    dispatch(setLicenceTypes(licenceTypes.data));
  };
}

export { getOrganizations, getAccountTypes, getLicenceTypes };
