import axios from 'axios';
import Config from '../Config/Config';
import { apiV1Client, apiV3Client, connectV1Client } from './BaseRepository';

export function fetchConfig() {
  return apiV3Client.get('/_config');
}

export function fetchConnectConfig() {
  return connectV1Client.get('/_config');
}

export function updateConsentConfig(isContactViewAllowed) {
  return connectV1Client.put('/_config/consent', {
    IsContactViewAllowed: isContactViewAllowed,
  });
}

export function getConnectConsentConfig() {
  return connectV1Client.get('/_config/consent');
}

export function patchUpdateConfig(configUpdate) {
  return apiV3Client.patch('/_config', configUpdate);
}

export function putUpdateConfig(configUpdate) {
  return apiV3Client.put('/_config', configUpdate);
}

export function fetchAllPortalCredentials() {
  return apiV3Client.get(`/_config/portals`);
}

export function integratePortal(portal, userDetails) {
  const newUserDetails = userDetails;
  if (newUserDetails.CountryCode === 'All') delete newUserDetails.CountryCode;
  return apiV3Client.put(`/_config/portals/${portal}`, newUserDetails);
}

export function integrateJobPortal(portal, userDetails) {
  const newUserDetails = userDetails;
  if (newUserDetails.CountryCode === 'All') delete newUserDetails.CountryCode;
  return apiV3Client.put(`/_config/jobdistributeportals/${portal}`, newUserDetails);
}

export function integrateSource(sourceDetails) {
  return apiV3Client.post(`/users/_config/sources`, [sourceDetails]);
}

export function disintegratePortal(payload) {
  const { portal, countryCode, searchType } = payload;
  const baseURL = `/_config/portals/${portal}`;
  let apiURL = '';
  if (countryCode !== 'All' && searchType) apiURL = `${baseURL}?countryCode=${countryCode}&searchType=${searchType}`;
  else if (countryCode !== 'All' && !searchType) apiURL = `${baseURL}?countryCode=${countryCode}`;
  else if (searchType) apiURL = `${baseURL}?searchType=${searchType}`;
  else apiURL = baseURL;

  return apiV3Client.delete(apiURL);
}

export function disconnectJobPortal(portal, countryCode) {
  if (countryCode !== 'All')
    return apiV3Client.delete(`/_config/jobdistributeportals/${portal}?countryCode=${countryCode}`);
  return apiV3Client.delete(`/_config/jobdistributeportals/${portal}`);
}

export function fetchAllPortalCountries() {
  return apiV3Client.get(`/portals/countries`);
}

export function fetchWhiteLabelInfo(client) {
  return axios.get(`${Config.urls.getWhiteLabelResourceUrl(client)}/data.json`);
}

export function fetchAppConfiguration(appType) {
  return axios.get(`${Config.urls.getAppConfiguration(appType)}`);
}

export function fetchJobDistributionConfiguration() {
  return apiV3Client.get('jobdistribution/_config');
}

export function fetchCandidateDownloadFilterConfig() {
  return apiV3Client.get('candidatefilter/_config/download');
}

export function fetchCandidateCommunicateFilterConfig() {
  return apiV3Client.get('candidatefilter/_config/communicate');
}

export function fetchJobDistributionPortalCredentials() {
  return apiV3Client.get('_config/jobdistributeportals');
}

export function fetchNotificationConfig(filter) {
  return apiV1Client.post(`Notifications/subscriptions`, {
    NotificationTypes: filter?.types,
  });
}

export function updateNotificationConfigByType({ type, config }) {
  return apiV1Client.put(`Notifications/subscriptions`, config, {
    params: { type },
  });
}

export function updateNotificationConfigById({ id, config }) {
  return apiV1Client.put(`Notifications/subscriptions/${id}`, config);
}

export function getFeaturesAllowedCountries(query) {
  return apiV3Client.post('features/countries/_search', query);
}

export function fetchMaskingConfig() {
  return apiV3Client.get('users/_config/masking');
}

export function fetchOrgMaskingConfig() {
  return apiV3Client.get('organizations/_config/masking');
}

export function updateOrgMaskingConfig(config) {
  return apiV3Client.put('organizations/_config/masking', config);
}
