import React from 'react';
import { Row, Col } from 'antd';
import CountrySelect from '../../../CompoundComponents/MultiLocationWrapper/CountrySelect/CountrySelect';
import RegionSelect from '../../../CompoundComponents/MultiLocationWrapper/RegionSelect/RegionSelect';
import LocationSelect from '../../../CompoundComponents/MultiLocationWrapper/LocationSelect/LocationSelect';
import PrimaryLocationRadio from '../../../CompoundComponents/MultiLocationWrapper/PrimaryLocationRadio/PrimaryLocationRadio';
import DeleteLocationButton from '../../../CompoundComponents/MultiLocationWrapper/DeleteLocationButton/DeleteLocationButton';
import AddLocationButton from '../../../CompoundComponents/MultiLocationWrapper/AddLocationButton/AddLocationButton';
import MultiLocationWrapper from '../../../CompoundComponents/MultiLocationWrapper/MultiLocationWrapper';
import styles from './JobMultiLocationItem.module.scss';

function JobMultilocationItem(props) {
  const { isLastElement, isFirstElement } = props;
  return (
    <Row className={styles.multiLocationInputGroup} gutter="16">
      <MultiLocationWrapper {...props}>
        <Col span={4}>
          <CountrySelect isDisabled={!isFirstElement} />
        </Col>
        <Col span={5}>
          <RegionSelect />
        </Col>
        <Col span={9}>
          <LocationSelect placeholder="City, State" />
        </Col>
        <Col span={4} className={styles.primaryLocationRadio}>
          <PrimaryLocationRadio />
        </Col>
        <Col span={2} className={styles.modifyLocationActionContainer}>
          <DeleteLocationButton />
          {isLastElement ? <AddLocationButton /> : null}
        </Col>
      </MultiLocationWrapper>
    </Row>
  );
}

export default JobMultilocationItem;
