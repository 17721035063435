import React from 'react';
import _ from 'lodash';
import { Modal } from 'antd';
import './CustomModal.scss';

export default function CustomModal(props) {
  const { visible, closeModal, modalContent } = props;
  const icon = _.get(modalContent, 'icon', null);
  const header = _.get(modalContent, 'header', null);
  const message = _.get(modalContent, 'message', null);
  const footer = _.get(modalContent, 'footer', null);
  const modalWidth = _.get(modalContent, 'width', null);
  const customModalWrapperClassName = _.get(modalContent, 'customModalWrapperClassName', null);
  return (
    <div className="custom-modal-wrapper">
      <Modal
        className="custom-modal"
        visible={visible}
        footer={null}
        style={modalContent.style}
        maskClosable={false}
        closable={!!modalContent.closable}
        onCancel={closeModal}
        zIndex={2001}
        centered={modalContent.centered}
        width={modalWidth || 488}
      >
        <div className={`custom-modal-content-wrapper ${customModalWrapperClassName}`}>
          {icon ? <div className="custom-modal-icon">{icon}</div> : null}
          <div className="custom-modal-content">
            {header ? (
              <div className={`custom-modal-header custom-modal-header-${header && !message}`}>{header}</div>
            ) : null}
            {message ? <div className="custom-modal-message">{message}</div> : null}
            {footer ? <div className="custom-modal-footer">{footer}</div> : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
