import _ from 'lodash';

import { parseHtmlStringFromEditor, parseTextFromHtml } from '../../Utils/DomParserUtils';

export default function createRequestBody(values, alldrops = {}, initialOrderValue = 0, initialValues) {
  let drops = _.cloneDeep(alldrops);
  const decoratorNameToApiName = {
    'template-id': 'EmailTemplateId',
    'template-condition': 'TriggerOn',
    'template-days': 'TimeAfterPrevious',
    'template-subject': 'Subject',
    'template-preHeader': 'PreHeader',
    'template-chatbot': 'IsBotEnabled',
    'template-body': 'Body',
  };

  Object.keys(values).forEach(key => {
    const [decoratorName, index] = key.split('_');
    const field = decoratorNameToApiName[decoratorName];
    if (index) {
      if (field === 'Body') {
        drops[index][field] = parseHtmlStringFromEditor(values[key].htmlContent);
      } else if (field === 'Subject') {
        drops[index][field] = parseTextFromHtml(values[key].htmlContent);
      } else if (field === 'PreHeader') {
        drops[index][field] = parseTextFromHtml(values[key].htmlContent);
      } else {
        drops[index][field] = values[key];
      }
    }
  });

  drops = drops.map((drop, index) => {
    return { ...drop, Order: index + initialOrderValue };
  });

  return {
    Name: values['template-name'],
    Drops: drops,
    IsPublic: initialValues?.IsPublic ?? false,
  };
}
