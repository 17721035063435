import React from 'react';
import { withRouter } from 'react-router-dom';
import AdvanceJobHeaderIcon from '../../../Icons/AdvanceJobHeaderIcon';
import { ProgressSteps } from '../../../Containers/PlanSelection/PlanSelectionModules';
import styles from './AdvancedJobEditHeaderSection.module.scss';

function AdvancedJobEditHeaderSection(props) {
  const { activeStep, distribtionStatsDataCount, isFirstJobFree } = props;
  let { title, subTitle } = props;
  if (distribtionStatsDataCount === 0) {
    title = 'Not Insights found';
    subTitle =
      'Please review and make changes to Job Title, Location, Miles or Skills to get Insights based on Market Intelligence.';
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.stepsWrapper}>
        <ProgressSteps current={activeStep} showSampleCandidates={isFirstJobFree} />
      </div>
      <div className={styles.container}>
        <span className={styles.icon}>
          <AdvanceJobHeaderIcon className={styles.iconSize} />
        </span>
        <div>
          <div className={styles.title}>{title}</div>
          <span className={`${distribtionStatsDataCount === 0 ? styles.noInsightSubTitle : styles.subTitle}`}>
            {subTitle}
          </span>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdvancedJobEditHeaderSection);

export { AdvancedJobEditHeaderSection as AdvancedJobEditHeaderSectionWithoutRouter };
