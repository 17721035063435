import React, { useRef } from 'react';
import { Checkbox, Icon, Tooltip } from 'antd';
import { ContactProviderAuthenticationForm } from './ContactProviderAuthenticationForm';
import * as DragAndDropHook from '../../Hooks/useDragAndDrop';
import './ContactProviderItem.scss';

const style = {
  border: '1px solid #e8e8e8',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: '#f5f5f5',
  cursor: 'move',
  width: '653px',
  borderRadius: '5px',
};

const itemType = 'contactProvider';

export const ContactProviderItem = ({
  providerId,
  providerName,
  index,
  isSubscribed,
  isSystemCredentialAllowed,
  configuration,
  moveCard,
  handleCheckBoxClick,
  handleAuthenticate,
  handleDisconnect,
  contactProviderAuthenticationApiStatus,
  credentialType,
  onSelectCredentialType,
  form,
  whiteLabelInfo,
}) => {
  const [configurationFormVisibility, setConfigurationFormVisibility] = React.useState(false);
  const ref = useRef(null);

  const [handlerId, drop, drag] = DragAndDropHook.useDragAndDrop({
    itemType,
    index,
    itemId: providerId,
    moveCard,
    ref,
    isDragAllowed: true,
  });
  drag(drop(ref));

  const onArrowClick = e => {
    e.preventDefault();
    setConfigurationFormVisibility(!configurationFormVisibility);
  };

  const handleAuthenticationUser = (userLoginName, password) => {
    handleAuthenticate({ providerId, userLoginName, password });
  };

  const handleAuthenticationApi = apiKey => {
    handleAuthenticate({ providerId, apiKey });
  };

  const handleDisconection = () => {
    handleDisconnect({ providerId });
  };

  const handleCredentialType = e => {
    onSelectCredentialType(providerId, e.target.value);
  };

  const userName = configuration?.Others?.user_id;
  const isApiKey = !!configuration?.ApiKey;
  const isCheckboxEnabled = isSystemCredentialAllowed || userName || isApiKey;
  const isChecked = isCheckboxEnabled && isSubscribed;
  return (
    <div ref={ref} style={style} data-handler-id={handlerId} className="card-item">
      <div className="header-data">
        <div className="data">
          {isCheckboxEnabled ? (
            <Checkbox
              onChange={e => handleCheckBoxClick(e.target.checked, providerId)}
              checked={isChecked}
              disabled={!isCheckboxEnabled}
            >
              {providerName}
            </Checkbox>
          ) : (
            <Tooltip title="Please authenticate using credential to enable">
              <Checkbox
                onChange={e => handleCheckBoxClick(e.target.checked, providerId)}
                checked={isChecked}
                disabled={!isCheckboxEnabled}
              >
                {providerName}
              </Checkbox>
            </Tooltip>
          )}
        </div>
        <div className="information">
          {!configurationFormVisibility && credentialType === 'Arya' && isSystemCredentialAllowed ? (
            <div className="information-text">
              <Icon type="check" theme="outlined" style={{ color: '#13c26b', fontSize: '11px' }} />
              <div>
                <Tooltip title="more information">Available Credit</Tooltip>
              </div>
            </div>
          ) : null}
          {!configurationFormVisibility && credentialType === 'Client' && (isApiKey || userName) ? (
            <div className="information-text">
              <Icon type="check" theme="outlined" style={{ color: '#13c26b', fontSize: '11px' }} />
              <div>
                <Tooltip title="more information">{userName || 'Custom Credit'}</Tooltip>
              </div>
            </div>
          ) : null}
          <div className="expand-arrow-icon" role="presentation" onClick={onArrowClick}>
            {configurationFormVisibility ? (
              <Icon type="up" theme="outlined" style={{ fontSize: '9px' }} />
            ) : (
              <Icon type="down" theme="outlined" style={{ fontSize: '9px' }} />
            )}
          </div>
        </div>
      </div>
      {configurationFormVisibility ? (
        <ContactProviderAuthenticationForm
          handleAuthenticationUser={handleAuthenticationUser}
          handleAuthenticationApi={handleAuthenticationApi}
          handleDisconection={handleDisconection}
          userName={userName}
          isApiKey={isApiKey}
          providerId={providerId}
          isLoading={contactProviderAuthenticationApiStatus?.[providerId] === 'INPROGRESS'}
          credentialType={credentialType}
          handleCredentialType={handleCredentialType}
          providerName={providerName}
          isSystemCredentialAllowed={isSystemCredentialAllowed}
          form={form}
          whiteLabelInfo={whiteLabelInfo}
        />
      ) : null}
    </div>
  );
};
