import { setApiStatus } from '../ApiStatusActions';

export const SET_PARSED_JOB_DATA = 'SET_PARSED_JOB_DATA';
export const RESET_PARSED_JOB_DATA = 'RESET_PARSED_JOB_DATA';
export const SET_JOB_DESCRIPTION_SUGGESTION_DATA = 'SET_JOB_DESCRIPTION_SUGGESTION_DATA';

export function setParsedJobData(data) {
  return { type: SET_PARSED_JOB_DATA, payload: data };
}

export function setJobDescriptionSuggestionData(data) {
  return { type: SET_JOB_DESCRIPTION_SUGGESTION_DATA, payload: data };
}

export function setJobParserApiStatus(status) {
  const apiName = 'JobParserApi';
  return setApiStatus({ apiName, status });
}

export function setJobDescriptionSuggestionApiStatus(status) {
  const apiName = 'JobDescriptionSuggestionApi';
  return setApiStatus({ apiName, status });
}
