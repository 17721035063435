import React from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import { ContactProviderItem } from './ContactProviderItem';

const ContactProviders = props => {
  const {
    contactProviderAuthenticationApiStatus,
    authenticateContactProvider,
    disconnectContactProvider,
    data,
    onChange,
    form,
    whiteLabelInfo,
  } = props;
  const [contactProviders, setContactProviders] = React.useState([]);

  React.useEffect(() => {
    if (data.length) {
      const newData = data.map(provider => {
        const clonedProvider = _.cloneDeep(provider);
        if (!clonedProvider.IsSystemCredentialAllowed) {
          clonedProvider.CredentialType = 'Client';
        }
        return clonedProvider;
      });
      const subscribedProviders = newData.filter(provider => provider.IsSubscribed);
      const sortedCheckedSubscribedProvidres = subscribedProviders
        .filter(provider => provider.IsSystemCredentialAllowed || provider.Configuration?.Others?.user_id)
        .sort((a, b) => a.Order - b.Order);
      const sortedUncheckedSubscribedProvidres = subscribedProviders
        .filter(provider => !(provider.IsSystemCredentialAllowed || provider.Configuration?.Others?.user_id))
        .sort((a, b) => a.Order - b.Order);
      const sortedData = [
        ...sortedCheckedSubscribedProvidres,
        ...sortedUncheckedSubscribedProvidres,
        ...newData.filter(provider => !provider.IsSubscribed),
      ];
      setContactProviders(sortedData);
    }
  }, [data]);

  const updateContactProviders = updatedContactProviders => {
    const subscribedProviderIds = updatedContactProviders
      .filter(provider => provider.IsSubscribed)
      .map(provider => ({
        ProviderId: provider.Id,
        CredentialType: provider.CredentialType || 'Arya',
        IsAuthenticate: provider?.Configuration?.Others?.user_id || provider?.Configuration?.ApiKey,
      }));
    setContactProviders(updatedContactProviders);
    onChange(subscribedProviderIds);
  };

  const handleAuthenticate = async ({ providerId, userLoginName, password, apiKey }) => {
    let payload;
    if (userLoginName) {
      payload = {
        Others: {
          user_id: userLoginName,
          password,
        },
      };
    } else {
      payload = {
        ApiKey: apiKey,
      };
    }
    const response = await authenticateContactProvider({ providerId, payload });
    if (response.isSuccess) {
      const clonedProviders = _.cloneDeep(contactProviders);
      const index = clonedProviders.findIndex(x => x.Id === providerId);
      if (userLoginName) _.setWith(clonedProviders[index], ['Configuration', 'Others', 'user_id'], userLoginName);
      else _.setWith(clonedProviders[index], ['Configuration', 'ApiKey'], apiKey);
      _.setWith(clonedProviders[index], ['CredentialType'], 'Client');
      updateContactProviders(clonedProviders);
    }
  };

  const handleDisconnect = async ({ providerId }) => {
    const response = await disconnectContactProvider({ providerId });
    if (response.isSuccess) {
      const clonedProviders = _.cloneDeep(contactProviders);
      const index = clonedProviders.findIndex(x => x.Id === providerId);
      if (!clonedProviders[index].IsSystemCredentialAllowed) clonedProviders[index].IsSubscribed = false;
      else {
        _.setWith(clonedProviders[index], ['CredentialType'], 'Arya');
        form.setFieldsValue({ [`${providerId}-configurationType`]: 'Arya' });
      }
      delete clonedProviders[index].Configuration;
      updateContactProviders(clonedProviders);
    }
  };

  const reOrderContactProvider = React.useCallback(
    (dragIndex, hoverIndex) => {
      const currentContactProvider = contactProviders[dragIndex];
      const clonedContactProviders = _.cloneDeep(contactProviders);
      clonedContactProviders.splice(dragIndex, 1);
      clonedContactProviders.splice(hoverIndex, 0, currentContactProvider);
      updateContactProviders(clonedContactProviders);
    },
    [contactProviders]
  );

  const onSelectCredentialType = (providerId, credentialType) => {
    const clonedProviders = _.cloneDeep(contactProviders);
    const index = clonedProviders.findIndex(x => x.Id === providerId);
    clonedProviders[index].CredentialType = credentialType;
    updateContactProviders(clonedProviders);
  };

  const handleCheckBoxClick = (isChecked, providerId) => {
    const updatedContactProviders = _.cloneDeep(contactProviders);
    const currentContactProviderIndex = updatedContactProviders.findIndex(x => x.Id === providerId);
    if (isChecked && updatedContactProviders[currentContactProviderIndex]?.Configuration?.Others?.user_id) {
      updatedContactProviders[currentContactProviderIndex].CredentialType = 'Client';
    }
    updatedContactProviders[currentContactProviderIndex].IsSubscribed = isChecked;
    updateContactProviders(updatedContactProviders);
  };

  const renderContactProvider = (contactProvider, index) => {
    const {
      Id: id,
      Name: name,
      IsSubscribed: isSubscribed,
      IsSystemCredentialAllowed: isSystemCredentialAllowed,
      CredentialType: credentialType,
      Configuration: configuration,
    } = contactProvider;
    return (
      <ContactProviderItem
        key={id}
        index={index}
        providerId={id}
        providerName={name}
        isSubscribed={isSubscribed}
        isSystemCredentialAllowed={isSystemCredentialAllowed}
        credentialType={credentialType}
        configuration={configuration}
        moveCard={reOrderContactProvider}
        handleCheckBoxClick={handleCheckBoxClick}
        handleAuthenticate={handleAuthenticate}
        handleDisconnect={handleDisconnect}
        contactProviderAuthenticationApiStatus={contactProviderAuthenticationApiStatus}
        onSelectCredentialType={onSelectCredentialType}
        form={form}
        whiteLabelInfo={whiteLabelInfo}
      />
    );
  };

  return <>{contactProviders.map((contactProvider, index) => renderContactProvider(contactProvider, index))}</>;
};

export default Form.create()(ContactProviders);
export { ContactProviders as ContactProvidersWithoutForm };
