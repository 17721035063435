import React from 'react';
import { Radio, Input, Form } from 'antd';
import styles from './CandidateOptOutContent.module.scss';

const { TextArea } = Input;
const { Item } = Form;

export default function CandidateOptOutContent({ value, onChange }) {
  const triggerOnChange = changedValue => {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  const handleOptOutTypeChange = e => {
    triggerOnChange({
      CandidateOptOutType: e.target.value,
    });
  };

  const handleOptOutReasonChange = e => {
    triggerOnChange({
      CandidateOptOutReason: e.target.value,
    });
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  return (
    <div className={styles.candidateOptOutReasonContent}>
      <div>Selected Opt-out Reason</div>
      <Item>
        <Radio.Group value={value?.CandidateOptOutType} onChange={handleOptOutTypeChange}>
          <Radio style={radioStyle} value="Job">
            Job
          </Radio>
        </Radio.Group>
      </Item>
      <div>Additional details for the opt-out (Optional) </div>
      <Item>
        <TextArea
          onChange={handleOptOutReasonChange}
          value={value?.CandidateOptOutReason}
          rows={4}
          placeholder="Why Candidate chose to opt out of this job"
        />
      </Item>
    </div>
  );
}
