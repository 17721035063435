import React from 'react';
import styles from './EndNodeContent.module.scss';

export default function EndNodeContent(props) {
  const { drawerFooter } = props;
  return (
    <div style={{ width: 650 }}>
      <div className={styles.endNodeContent}>Do you want to end the workflow? </div>
      <div className={styles.endDrawerFooter}>{drawerFooter}</div>
    </div>
  );
}
