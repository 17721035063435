import React from 'react';
import { actionTypes } from '../../Utils/WorkflowUtils';
import EmailNodeContent from './EmailNodeContent/EmailNodeContent';
import SmsNodeContent from './SmsNodeContent/SmsNodeContent';
import AddTagNodeContent from './AddTagNodeContent/AddTagNodeContent';
import CustomFieldNodeContent from './CustomFieldNodeContent/CustomFieldNodeContent';
import DecisionNodeContent from './DecisionNodeContent/DecisionNodeContent';
import DelayNodeContent from './DelayNodeContent/DelayNodeContent';
import EndNodeContent from './EndNodeContent/EndNodeContent';

export default function WorkflowNodeModalContentMapper(props) {
  const {
    type,
    form,
    initialValue,
    drawerFooter,
    onCancel,
    onSave,
    mergeTags,
    isDisabled,
    isFirstEdge,
    isFirstNode,
  } = props;
  switch (type) {
    case actionTypes.SendEmail:
      return (
        <EmailNodeContent
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
          drawerFooter={drawerFooter}
          onCancel={onCancel}
          onSave={onSave}
          mergeTags={mergeTags}
        />
      );
    case actionTypes.SendSMS:
      return (
        <SmsNodeContent initialValue={initialValue} isDisabled={isDisabled} form={form} drawerFooter={drawerFooter} />
      );
    case actionTypes.AddTag:
      return (
        <AddTagNodeContent
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
          drawerFooter={drawerFooter}
        />
      );
    case actionTypes.AddCustomField:
      return (
        <CustomFieldNodeContent
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
          drawerFooter={drawerFooter}
        />
      );
    case actionTypes.Decision:
      return (
        <DecisionNodeContent
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
          drawerFooter={drawerFooter}
        />
      );
    case actionTypes.Delay:
      return (
        <DelayNodeContent
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
          drawerFooter={drawerFooter}
          isFirstEdge={isFirstEdge}
          isFirstNode={isFirstNode}
        />
      );
    case actionTypes.End:
      return <EndNodeContent drawerFooter={drawerFooter} isDisabled={isDisabled} />;
    default:
      return null;
  }
}
