import _ from 'lodash';
import Platform from 'platform';

const getUserPropertyByUserGuid = (usersById, userGuid, property) => {
  const filteredUserId = Object.keys(usersById).filter(user => usersById[user].UserGuid === userGuid);
  return _.get(usersById, [filteredUserId, property], null);
};

const getUserProperty = (usersById, userId, property) => {
  return _.get(usersById, [userId, property], getUserPropertyByUserGuid(usersById, userId, property));
};

const getUserByUserGuid = (usersById, userGuid) => {
  const filteredUserId = Object.keys(usersById).filter(user => usersById[user].UserGuid === userGuid);
  return _.get(usersById, [filteredUserId]);
};

const getVerifiedUserEmails = userEmails => {
  const emailsList = [];
  (userEmails || []).forEach(email => {
    const verificationStatus = _.get(email, ['VerificationStatus'], 'Pending');
    if (verificationStatus.toLowerCase() === 'verified') {
      emailsList.push(email.EmailId);
    }
  });
  return _.uniq(emailsList);
};

export const getCurrentUserEmailFromList = ({ currentUserEmail, emailList, orgDefaultEmail }) => {
  const currentUserEmailFromEmailList = _.find(
    emailList,
    email => email.toLowerCase() === currentUserEmail?.toLowerCase()
  );
  if (currentUserEmailFromEmailList?.trim()?.length > 0) {
    return currentUserEmailFromEmailList;
  }
  if (emailList?.length > 0) return emailList[0];
  return orgDefaultEmail;
};

const getCurrentSelectedFromEmail = ({
  userEmails,
  featureToggleList,
  currentUserEmail,
  selectedFromEmail,
  isAdminSMTPConfigurationExists,
  isEmailProviderSMTPConfigurationExists,
  orgDefaultEmail,
  successfulTestedEmailList,
}) => {
  const emailsList = getVerifiedUserEmails(userEmails);
  const defaultPrimaryFromEmail = featureToggleList?.PrimaryFromEmail.IsEnabled;
  if (!selectedFromEmail && defaultPrimaryFromEmail) {
    if (!isAdminSMTPConfigurationExists && !isEmailProviderSMTPConfigurationExists) {
      return orgDefaultEmail;
    }
    if (!isAdminSMTPConfigurationExists && isEmailProviderSMTPConfigurationExists) {
      return getCurrentUserEmailFromList({ currentUserEmail, emailList: successfulTestedEmailList, orgDefaultEmail });
    }
    const currentUserEmailFromEmailList = _.find(
      emailsList,
      email => email.toLowerCase() === currentUserEmail?.toLowerCase()
    );
    if (currentUserEmailFromEmailList?.trim()?.length > 0) return currentUserEmailFromEmailList;

    const filteredEmailsList = emailsList.filter(email => email?.toLowerCase() !== orgDefaultEmail);
    if (filteredEmailsList?.length > 0) return filteredEmailsList[0];

    return orgDefaultEmail;
  }
  if (!selectedFromEmail && !defaultPrimaryFromEmail) {
    return emailsList[0];
  }
  return selectedFromEmail;
};
const getDeviceInfo = () => {
  const mobileRegex =
    /.*(Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune).*/;
  const deviceType = mobileRegex.test(Platform.ua) ? 'Mobile' : 'Desktop';
  return { OperatingSystem: Platform.os.family, Browser: Platform.name, Type: deviceType, Model: Platform.product };
};

const getUserIdByUserGuid = (usersById, userGuid) => {
  const filteredUserId = Object.keys(usersById).filter(user => usersById[user].UserGuid === userGuid);
  return _.get(usersById, [filteredUserId], null);
};

const getUserId = (usersById, userId) => {
  return _.get(usersById, [userId], getUserIdByUserGuid(usersById, userId));
};

export {
  getUserPropertyByUserGuid,
  getUserProperty,
  getUserByUserGuid,
  getVerifiedUserEmails,
  getCurrentSelectedFromEmail,
  getDeviceInfo,
  getUserIdByUserGuid,
  getUserId,
};
