import React from 'react';
import { getUserType } from './ConfigUtils';
import { getConfig } from '../Reducers/ConfigReducer';
import { getAccountType } from './OrganizationUtils';
import { getAccountTypes } from '../Reducers/OrganizationsReducer';
import Config from '../Config/Config';
import store from '../store';

const MapAccountTypes = {
  AryaQuantum: 'Arya Quantum',
  AryaFusion: 'Arya Fusion',
  AryaFusionLite: 'Arya Fusion Lite',
  AryaPulse: 'Arya Pulse',
  WhiteGloveService: 'White Glove Service',
};

const MapDomains = {
  Pulse: `http${Config.urls.pulseHostName ? 's' : ''}://${Config.urls.pulseHostName ?? 'localhost:3000'}`,
  NonPulse: Config.urls.appUrl,
};

function getHeaderContent(accountType) {
  return `You do not have a ${accountType} account.`;
}

function getFooterContent(accountType, domain) {
  return (
    <div>
      If you are an existing user of {accountType}, please click <a href={domain}>Login to {accountType}</a>.
    </div>
  );
}

function getSubTitleContent() {
  return 'Please click on the button below to login to your existing account';
}

const AccountTypesInfo = {
  pulse: {
    AryaQuantum: {
      header: getHeaderContent(MapAccountTypes.AryaPulse),
      subTitle: getSubTitleContent(),
      footer: getFooterContent(MapAccountTypes.AryaQuantum, MapDomains.NonPulse),
    },
    AryaFusion: {
      header: getHeaderContent(MapAccountTypes.AryaPulse),
      subTitle: getSubTitleContent(),
      footer: getFooterContent(MapAccountTypes.AryaFusion, MapDomains.NonPulse),
    },
    AryaFusionLite: {
      header: getHeaderContent(MapAccountTypes.AryaPulse),
      subTitle: getSubTitleContent(),
      footer: getFooterContent(MapAccountTypes.AryaFusionLite, MapDomains.NonPulse),
    },
  },
  'non-pulse': {
    AryaPulse: {
      header: getHeaderContent(MapAccountTypes.AryaQuantum),
      subTitle: getSubTitleContent(),
      footer: getFooterContent(MapAccountTypes.AryaPulse, MapDomains.Pulse),
    },
    WhiteGloveService: {
      header: getHeaderContent(MapAccountTypes.AryaQuantum),
      subTitle: getSubTitleContent(),
      footer: getFooterContent(MapAccountTypes.WhiteGloveService, MapDomains.Pulse),
    },
  },
};

function getAccountTypesUnauthorizedScreenInfo() {
  const state = store.getState();
  const userType = getUserType();
  const userConfig = getConfig(state);
  const accountTypeId = userConfig.AccountTypeId;
  const accountTypes = getAccountTypes(state);
  const accountType = getAccountType(accountTypeId, accountTypes);
  const userApplicationType = AccountTypesInfo[userType] ?? {};
  return userApplicationType[accountType] ?? {};
}

export { getAccountTypesUnauthorizedScreenInfo };
