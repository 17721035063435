import React from 'react';
import { Modal, Button, Icon } from 'antd';
import styles from './DeleteNoteCardModal.module.scss';

export default function DeleteNoteCardModal(props) {
  const { isModalVisible, onCancel, onDelete, jobNoteDeleteApiStatus } = props;

  const onDeleteClick = () => {
    onDelete();
  };
  const isLoading = jobNoteDeleteApiStatus === 'INPROGRESS';
  return (
    <Modal
      title="Delete Note"
      visible={isModalVisible}
      onCancel={onCancel}
      centered
      footer={[
        <Button shape="round" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button shape="round" key="submit" onClick={onDeleteClick} loading={isLoading} type="primary">
          Yes
        </Button>,
      ]}
      zIndex={2001}
    >
      <div className={styles.modalContent}>
        <div className={styles.iconDiv}>
          <Icon type="exclamation-circle" />
        </div>
        <div className={styles.message}>Deleting this note will permanently remove it. Continue to delete?</div>
      </div>
    </Modal>
  );
}
