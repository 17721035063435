import store from '../store';
import EventHandlers from './SocketEventHandler';

export const broadcastChannel = new BroadcastChannel('arya_channel');

export function createBroadcastChannel() {
  broadcastChannel.onmessage = event => {
    const { eventType } = event.data;
    const { eventData } = event.data;
    const notificationPayload = { Type: eventType, Payload: { ...eventData }, Source: 'Arya' };
    EventHandlers.handleNotificationEvent(null, store.dispatch, 'notification', notificationPayload);
  };
}

export function closeBroadcastChannel() {
  broadcastChannel.close();
}
