import _ from 'lodash';

function isUnsupportedOperation(error) {
  if (!error) return false;

  const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
  const statusCode = _.get(error, ['response', 'status']);

  return statusCode === 406 && appCode.includes('UNSUPPORTED_OPERATION');
}

function mapErrorCodes(error) {
  let isVisible = true;
  const errorMessage = 'Failed to add client';

  if (isUnsupportedOperation(error)) {
    isVisible = false;
  }
  return { isVisible, errorMessage };
}

export { mapErrorCodes, isUnsupportedOperation };
