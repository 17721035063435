const getSourceResultCount = source => {
  const hasSourceTrueCount = source?.find(sourceObject => sourceObject.Value === 'true')?.Count || 0;
  const hasSourceFalseCount = source?.find(sourceObject => sourceObject.Value === 'false')?.Count || 0;
  return { hasSourceTrueCount, hasSourceFalseCount };
};

const aggregationKeysToDisplayNameMapper = {
  careerbuilderedge: {
    Source: 'Source',
    Skill: 'Skills',
    AcademicDiscipline: 'Academic Discipline',
    DegreeLevel: 'Academic Degree',
    City: 'City',
    Country: 'Country',
    State: 'State',
    Industry: 'Industry',
    CaroteneV3Title: 'Job Title',
    CurrentEmployer: 'Current Employer',
    PreviousEmployer: 'Previous Employer',
  },
};

const getCurrentSearchResult = defaultCandidateAggregations => {
  const { HasResume, HasSocialSource } = defaultCandidateAggregations;
  if (HasResume) return 'Resume Results';
  if (HasSocialSource) return 'Social Results';
  return 'All Results';
};

export { getSourceResultCount, aggregationKeysToDisplayNameMapper, getCurrentSearchResult };
