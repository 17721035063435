import React from 'react';
import {
  prepareDataForOccupationalSimilarityScatterPlot,
  getXAxisDomain,
  getYAxisDomain,
} from '../../../Utils/CandidateIntelChartUtils';
import renderScatterPlot from '../ScatterPlot';
import './OccupationalRelevanceScatterPlot.scss';

function onMouseOver(tooltipContent) {
  return (tooltip, offsetX, offsetY) => {
    tooltip
      .style('opacity', 0.9)
      .html(tooltipContent)
      .style('top', `${offsetY - 20}px`)
      .style('left', `${offsetX + 30}px`);
  };
}

class OccupationalRelevanceScatterPlot extends React.Component {
  constructor(props) {
    super(props);
    this.createScatterPlot = this.createScatterPlot.bind(this);
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  componentDidUpdate() {
    this.createScatterPlot();
  }

  createScatterPlot() {
    const { candidateName, OccupationRelevance } = this.props;
    const { CandidateOccupation, JobOccupation } = OccupationRelevance;

    const tooltipContent = `
    <div class="tooltip-content">
      <p class="tooltip-heading">
        Occupation
      </p>
      <div class="occupation-tooltip-details">
        <div class="tooltip-candidate-occupation-color"></div>
        <div class="tooltip-candidate-occupation">${CandidateOccupation.Occupation} (${candidateName}'s)</div>
      </div>
      <div class="occupation-tooltip-details">
        <div class="tooltip-job-occupation-color"></div>
        <div class="tooltip-job-occupation">${JobOccupation.Occupation} (Job Role)</div>
      </div>
    </div>
    `;

    const data = prepareDataForOccupationalSimilarityScatterPlot(OccupationRelevance);

    const config = {
      parentId: '#occupationalRelevanceScatterPlot',
      tooltipClass: 'occupation-relevance-tooltip',
      onMouseOver: onMouseOver(tooltipContent),
      xAxisDomain: getXAxisDomain(data),
      yAxisDomain: getYAxisDomain(data),
      idPrefix: 'occupation',
    };
    renderScatterPlot(config, data);
  }

  render() {
    return <div id="occupationalRelevanceScatterPlot"></div>;
  }
}

export default OccupationalRelevanceScatterPlot;
