import React from 'react';
import { connect } from 'react-redux';
import * as ConfigActions from '../../../Actions/ConfigActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getNotificationConfig } from '../../../Reducers/ConfigReducer';
import NotificationSettings from '../../../Components/Settings/NotificationSettings/NotificationSettings';

const mapStateToProps = state => {
  return {
    notificationConfig: getNotificationConfig(state),
    fetchNotificationConfigApiStatus: getApiStatus(state, 'fetchNotificationConfigApiStatus'),
  };
};

const mapDispatchToProps = {
  fetchNotificationConfig: ConfigActions.fetchNotificationConfig,
  updateNotificationConfigByType: ConfigActions.updateNotificationConfigByType,
  updateNotificationConfigById: ConfigActions.updateNotificationConfigById,
  activateAllNotificationByType: ConfigActions.activateAllNotificationByType,
};

export function NotificationSettingsContainer(props) {
  const {
    notificationConfig,
    fetchNotificationConfigApiStatus,
    fetchNotificationConfig,
    updateNotificationConfigByType,
    updateNotificationConfigById,
    activateAllNotificationByType,
  } = props;
  return (
    <div>
      <NotificationSettings
        notificationConfig={notificationConfig}
        fetchNotificationConfigApiStatus={fetchNotificationConfigApiStatus}
        fetchNotificationConfig={fetchNotificationConfig}
        updateNotificationConfigByType={updateNotificationConfigByType}
        updateNotificationConfigById={updateNotificationConfigById}
        activateAllNotificationByType={activateAllNotificationByType}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsContainer);
