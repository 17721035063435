import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Pagination, Tabs, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseTemplateContainer from '../BaseTemplatesContainer';
import CreateEmailTemplate from '../../../Components/Connect/BaseTemplateForm/CreateEmailTemplate/CreateEmailTemplate';
import UserEmailTemplateRow from '../../../Components/Connect/TemplateRow/UserEmailTemplateRow/UserEmailTemplateRow';
import SharedEmailTemplateRow from '../../../Components/Connect/TemplateRow/SharedEmailTemplateRow/SharedEmailTemplateRow';
import SystemEmailTemplateRow from '../../../Components/Connect/TemplateRow/SystemEmailTemplateRow/SystemEmailTemplateRow';
import TemplateHeader from '../../../Components/Connect/TemplateHeader/TemplateHeader';
import getMergeTags from '../../../Actions/MergeTagsActions';
import * as MergeTagsReducer from '../../../Reducers/MergeTagsReducer';
import * as EmailTemplatesActions from '../../../Actions/EmailTemplates';
import * as ConnectActions from '../../../Actions/ConnectActions';
import * as UserActions from '../../../Actions/UserActions';
import {
  getConnectUsersById,
  getUsersByGuId,
  getUserListForShareTemplate,
  getConnectMaxCount,
} from '../../../Reducers/UserReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getIsFeaturesEnabled } from '../../../Utils/FeatureMapper.ts';
import { getFilteredMergeTags } from '../../../Utils/MergeTagsUtils';

import {
  getCurrentEmailTemplateIds,
  getEmailTemplatesById,
  getEmailTemplatesCount,
} from '../../../Reducers/EmailTemplates';
import messages from './messages';
import './EmailTemplatesContainer.css';
import { connectTemplateNameByType } from '../../../Utils/ConnectUtils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  currentEmailTemplateIds: getCurrentEmailTemplateIds(state),
  emailTemplatesById: getEmailTemplatesById(state),
  emailTemplatesCount: getEmailTemplatesCount(state),
  mergeTags: MergeTagsReducer.getMergeTags(state),
  testTemplateStatus: getApiStatus(state, 'testTemplateStatus'),
  getEmailTemplateStatus: getApiStatus(state, 'getEmailTemplateStatus'),
  connectUsersById: getConnectUsersById(state),
  usersByGuId: getUsersByGuId(state),
  userListForShareTemplate: getUserListForShareTemplate(state),
  maxCount: getConnectMaxCount(state),
  CurrentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  searchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  searchSystemEmailTemplates: EmailTemplatesActions.searchSystemEmailTemplates,
  postEmailTemplate: EmailTemplatesActions.postEmailTemplate,
  editEmailTemplate: EmailTemplatesActions.editEmailTemplate,
  editSystemEmailTemplate: EmailTemplatesActions.editSystemEmailTemplate,
  deleteEmailTemplate: EmailTemplatesActions.deleteEmailTemplate,
  duplicateEmailTemplate: EmailTemplatesActions.duplicateEmailTemplate,
  testEmailTemplate: EmailTemplatesActions.testEmailTemplate,
  clearTestEmailTemplateState: EmailTemplatesActions.clearTestEmailTemplateState,
  fetchMergeTags: getMergeTags,
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
  shareEmailTemplate: EmailTemplatesActions.shareEmailTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
};

class EmailTemplatesContainer extends BaseTemplateContainer {
  constructor(props) {
    super(props);
    this.state = {
      createDrawerVisibility: false,
      editDrawerVisibility: false,
      page: 1,
      pageSize: 10,
      searchQuery: '',
      shared: false,
      systemEmailTemplate: false,
      includeSystemTemplate: false,
    };

    this.searchTemplates = this.searchTemplates.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.closeEditDrawer = this.closeEditDrawer.bind(this);
    this.closeCreateDrawer = this.closeCreateDrawer.bind(this);
    this.editSystemTemplate = this.editSystemTemplate.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    this.searchTemplates();
  }

  onSearch(searchQuery) {
    const { systemEmailTemplate } = this.state;
    this.setState(
      {
        searchQuery,
      },
      systemEmailTemplate ? this.searchSystemTemplates : this.searchTemplates
    );
  }

  onPageChange(page, pageSize) {
    const { systemEmailTemplate } = this.state;
    this.setState(
      {
        page,
        pageSize,
      },
      systemEmailTemplate ? this.searchSystemTemplates : this.searchTemplates
    );
  }

  onShowSizeChange(current, pageSize) {
    const { systemEmailTemplate } = this.state;
    this.setState(
      {
        page: current,
        pageSize,
      },
      systemEmailTemplate ? this.searchSystemTemplates : this.searchTemplates
    );
  }

  closeEditDrawer() {
    const { clearTestEmailTemplateState } = this.props;
    clearTestEmailTemplateState();
    this.setState({
      editDrawerVisibility: false,
    });
  }

  closeCreateDrawer() {
    const { clearTestEmailTemplateState } = this.props;
    clearTestEmailTemplateState();
    this.setState({
      createDrawerVisibility: false,
    });
  }

  searchTemplates() {
    const { page, pageSize, searchQuery, shared, includeSystemTemplate } = this.state;
    const { searchEmailTemplates } = this.props;
    searchEmailTemplates({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
      IncludeSystemTemplate: includeSystemTemplate,
    });
  }

  searchSystemTemplates() {
    const { page, pageSize, searchQuery } = this.state;
    const { searchSystemEmailTemplates } = this.props;
    searchSystemEmailTemplates({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
    });
  }

  createTemplate(template) {
    const { postEmailTemplate } = this.props;
    const { page, pageSize, searchQuery, shared, systemEmailTemplate } = this.state;
    let refreshList = true;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    if (systemEmailTemplate) {
      refreshList = false;
    }
    postEmailTemplate(template, filter, refreshList);
    this.closeCreateDrawer();
  }

  editTemplate(template) {
    const { editEmailTemplate } = this.props;
    const { page, pageSize, searchQuery, shared, editTemplateId } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    editEmailTemplate(editTemplateId, template, filter);
    this.closeEditDrawer();
  }

  editSystemTemplate(template) {
    const { editSystemEmailTemplate } = this.props;
    const { page, pageSize, searchQuery, editTemplateId } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
    };
    const systemTemplate = {
      Subject: template.Subject,
      Body: template.Body,
    };
    editSystemEmailTemplate(editTemplateId, systemTemplate, filter);
    this.closeEditDrawer();
  }

  deleteTemplate(templateId) {
    const { deleteEmailTemplate, currentEmailTemplateIds } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const shouldGoBack = currentEmailTemplateIds.length === 1 && page !== 1;
    const updatedPage = shouldGoBack ? page - 1 : page;
    const filter = {
      From: (updatedPage - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    deleteEmailTemplate(templateId, filter);
    this.setState({ page: updatedPage });
  }

  duplicateTemplate(templateId) {
    const { duplicateEmailTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    duplicateEmailTemplate(templateId, filter);
  }

  render() {
    const {
      intl,
      emailTemplatesById,
      currentEmailTemplateIds,
      emailTemplatesCount,
      mergeTags,
      testTemplateStatus,
      testEmailTemplate,
      connectUsersById,
      maxCount,
      CurrentUser,
      usersByGuId,
      userListForShareTemplate,
      shareEmailTemplate,
      fetchUsersForShare,
      featureToggleList,
      getEmailTemplateStatus,
      setInvalidTemplateNotification,
      version,
      jobDetails,
    } = this.props;
    const {
      createDrawerVisibility,
      editDrawerVisibility,
      editTemplateId,
      page,
      pageSize,
      shared,
      systemEmailTemplate,
    } = this.state;

    const [isSystemEmailWriteEnabled, isSystemEmailReadEnabled] = getIsFeaturesEnabled(featureToggleList, [
      'SystemEmailTemplateWrite',
      'SystemEmailTemplateRead',
    ]);
    const isReactEmailEditorEnabled = featureToggleList.ReactEmailEditor.IsEnabled;
    const filteredMergeTags = getFilteredMergeTags(
      mergeTags,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      jobDetails
    );
    return (
      <div className="email-templates">
        <TemplateHeader
          onClick={this.showCreateDrawer}
          // onSearch={this.onSearch}
          onChange={event => this.onSearch(event.target.value)}
          heading={<FormattedMessage {...messages.emailTemplates} />}
          placeholder={intl.formatMessage({ ...messages.searchEmailTemplates })}
          systemEmailTemplate={systemEmailTemplate}
          type={connectTemplateNameByType.email}
        />
        {createDrawerVisibility ? (
          <CreateEmailTemplate
            visible={createDrawerVisibility}
            onClose={this.closeCreateDrawer}
            title={intl.formatMessage({ ...messages.emailTemplate })}
            emailTemplateName={intl.formatMessage({ ...messages.emailTemplateName })}
            subjectLine={intl.formatMessage({ ...messages.subjectLine })}
            onSubmit={this.createTemplate}
            initialValues={{}}
            mergeTags={filteredMergeTags}
            testTemplateStatus={testTemplateStatus}
            testEmailTemplate={testEmailTemplate}
            isSystemEmailWriteEnabled={isSystemEmailWriteEnabled}
            isReactEmailEditorEnabled={isReactEmailEditorEnabled}
            setInvalidTemplateNotification={setInvalidTemplateNotification}
          />
        ) : null}

        {editDrawerVisibility ? (
          <CreateEmailTemplate
            visible={editDrawerVisibility}
            onClose={this.closeEditDrawer}
            title={intl.formatMessage({ ...messages.emailTemplate })}
            emailTemplateName={intl.formatMessage({ ...messages.emailTemplateName })}
            subjectLine={intl.formatMessage({ ...messages.subjectLine })}
            onSubmit={!systemEmailTemplate ? this.editTemplate : this.editSystemTemplate}
            initialValues={emailTemplatesById[editTemplateId]}
            mergeTags={filteredMergeTags}
            testTemplateStatus={testTemplateStatus}
            testEmailTemplate={testEmailTemplate}
            viewOnly={systemEmailTemplate ? !isSystemEmailWriteEnabled : shared}
            systemEmailTemplate={systemEmailTemplate}
            isSystemEmailWriteEnabled={isSystemEmailWriteEnabled}
            isEditTemplateDrawer
            isReactEmailEditorEnabled={isReactEmailEditorEnabled}
            setInvalidTemplateNotification={setInvalidTemplateNotification}
          />
        ) : null}

        <Tabs defaultActiveKey="my-templates" onChange={this.onTabChange}>
          <TabPane tab={<FormattedMessage {...messages.myTemplates} />} key="my-templates">
            <Row className="email template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.template} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.shared} />
              </Col>
              <Col span={6}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              {/* <Col span={3}>
            <FormattedMessage {...messages.includesBot} />
          </Col> */}
              {/* <Col span={4}>
                <FormattedMessage {...messages.shared} />
              </Col> */}
            </Row>
            <Skeleton loading={getEmailTemplateStatus === 'INPROGRESS'}>
              {currentEmailTemplateIds.map(templateId => {
                const emailTemplate = emailTemplatesById[templateId];
                return (
                  <UserEmailTemplateRow
                    key={emailTemplate.Id}
                    title={emailTemplate.Name}
                    updatedTime={emailTemplate.UpdatedTime}
                    subject={emailTemplate.Subject}
                    includesBot={emailTemplate.IsBotEnabled}
                    isPublic={emailTemplate.IsPublic}
                    deleteTemplate={this.deleteTemplate}
                    duplicateTemplate={this.duplicateTemplate}
                    editTemplate={this.showEditDrawer}
                    shareEmailTemplate={shareEmailTemplate}
                    templateId={templateId}
                    usersByGuId={usersByGuId}
                    userListForShareTemplate={userListForShareTemplate}
                    sharedUsers={_.uniq(emailTemplatesById[templateId].SharedWith || [])}
                    maxCount={maxCount}
                    CurrentUser={CurrentUser}
                    fetchUsersForShare={fetchUsersForShare}
                  />
                );
              })}
              <Pagination
                showSizeChanger
                size="small"
                total={emailTemplatesCount}
                onChange={this.onPageChange}
                onShowSizeChange={this.onShowSizeChange}
                hideOnSinglePage={emailTemplatesCount <= 10}
                current={page}
                pageSize={pageSize}
              />
            </Skeleton>
          </TabPane>
          <TabPane tab={<FormattedMessage {...messages.sharedTemplates} />} key="shared-templates">
            <Row className="email template-table-header">
              <Col span={8}>
                <FormattedMessage {...messages.template} />
              </Col>
              <Col span={5}>
                <FormattedMessage {...messages.updatedOn} />
              </Col>
              {/* <Col span={4}>
                <FormattedMessage {...messages.includesBot} />
              </Col> */}
              <Col span={5}>
                <FormattedMessage {...messages.createdBy} />
              </Col>
            </Row>
            <Skeleton loading={getEmailTemplateStatus === 'INPROGRESS'}>
              {currentEmailTemplateIds.map(templateId => {
                const emailTemplate = emailTemplatesById[templateId];
                const firstName = _.get(connectUsersById, [emailTemplate.CreatedBy, 'FirstName'], '');
                const lastName = _.get(connectUsersById, [emailTemplate.CreatedBy, 'LastName'], '');
                return (
                  <SharedEmailTemplateRow
                    key={emailTemplate.Id}
                    id={emailTemplate.Id}
                    title={emailTemplate.Name}
                    updatedTime={emailTemplate.UpdatedTime}
                    subject={emailTemplate.Subject}
                    includesBot={emailTemplate.IsBotEnabled}
                    createdBy={`${firstName} ${lastName}`}
                    cloneTemplate={this.duplicateTemplate}
                    viewTemplate={this.showEditDrawer}
                  />
                );
              })}
              <Pagination
                onChange={this.onPageChange}
                onShowSizeChange={this.onShowSizeChange}
                showSizeChanger
                size="small"
                total={emailTemplatesCount}
                hideOnSinglePage={emailTemplatesCount <= 10}
                current={page}
                pageSize={pageSize}
              />
            </Skeleton>
          </TabPane>
          {isSystemEmailReadEnabled ? (
            <TabPane tab={<FormattedMessage {...messages.systemEmailTemplate} />} key="system-templates">
              <Row className="email template-table-header">
                <Col span={8}>
                  <FormattedMessage {...messages.template} />
                </Col>
                <Col span={5}>
                  <FormattedMessage {...messages.updatedOn} />
                </Col>
                <Col span={5}>
                  <FormattedMessage {...messages.updatedBy} />
                </Col>
              </Row>
              <Skeleton loading={getEmailTemplateStatus === 'INPROGRESS'}>
                {currentEmailTemplateIds.map(templateId => {
                  const emailTemplate = emailTemplatesById[templateId];
                  const firstName = _.get(connectUsersById, [emailTemplate.UpdatedBy, 'FirstName'], '');
                  const lastName = _.get(connectUsersById, [emailTemplate.UpdatedBy, 'LastName'], '');
                  return (
                    <SystemEmailTemplateRow
                      key={emailTemplate.Id}
                      id={emailTemplate.Id}
                      templateId={templateId}
                      title={emailTemplate.Key} // Currently we don't have name of these template
                      updatedTime={emailTemplate.UpdatedTime}
                      subject={emailTemplate.Subject}
                      updatedBy={`${firstName} ${lastName}`}
                      viewTemplate={this.showEditDrawer}
                      editTemplate={this.showEditDrawer}
                      isSystemEmailWriteEnabled={isSystemEmailWriteEnabled}
                    />
                  );
                })}
                <Pagination
                  onChange={this.onPageChange}
                  onShowSizeChange={this.onShowSizeChange}
                  showSizeChanger
                  size="small"
                  total={emailTemplatesCount}
                  hideOnSinglePage={emailTemplatesCount <= 10}
                  current={page}
                  pageSize={pageSize}
                />
              </Skeleton>
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

EmailTemplatesContainer.defaultProps = {
  emailTemplatesCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmailTemplatesContainer));
export { EmailTemplatesContainer as EmailTemplatesContainerWithoutStore };
