import { apiV3Client, connectV1Client } from './BaseRepository';

export function fetchNotifications(count, skip, status) {
  return apiV3Client.get('notifications', {
    params: {
      skip,
      count,
      status,
    },
  });
}

export function fetchNotificationsCount(status) {
  return apiV3Client.get('notifications/_count', {
    params: {
      status,
    },
  });
}

export function putConnectNotificationStatus(notificationId, status) {
  return connectV1Client.put(`Notifications/${notificationId}`, {
    Status: status,
  });
}

export function putConnectViewedTime() {
  return connectV1Client.put('Notifications/_view');
}
