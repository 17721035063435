import React from 'react';
import { Icon } from 'antd';

const ProfessionalEmailSvg = props => (
  <svg
    width={18}
    height={13}
    viewBox="0 0 18 13"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="prefix__a" d="M0 0h17.928v12.82H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <path
        d="M4.89 2.793h4.503V1.6H4.89v1.194zm9.387 1.05v7.92c-.006.013-.015.025-.017.038-.08.572-.57 1.02-1.266 1.02-3.904-.005-7.807-.002-11.71-.005-.13 0-.263-.01-.386-.044-.57-.158-.897-.6-.897-1.208-.002-2.504-.002-5.01.001-7.514 0-.12.012-.243.045-.358.156-.562.592-.883 1.199-.886.628-.004 1.255-.001 1.882-.001h.167V2.64c0-.585-.002-1.17 0-1.756 0-.555.33-.884.886-.884h5.91c.566 0 .892.328.893.892v1.757c0 .048.005.097.008.156h.194c.669.003 1.338-.003 2.007.012.321.007.582.165.796.405.16.18.228.398.288.622z"
        fill="#7E7E7E"
        mask="url(#prefix__b)"
      />
      <path
        d="M11.684 7.634L8.613 4.801c-.04.132-.032 5.536.01 5.628 1.023-.933 2.043-1.864 3.061-2.795zm5.452-2.843l-3.082 2.843 3.082 2.815V4.791zm-3.371 2.58l3.079-2.838-.017-.025H8.911l-.014.027 3.076 2.836.326-.295c.363-.325.774-.325 1.137-.002.104.092.207.187.329.297zm3.075 3.34l-.045-.047-3.63-3.31c-.203-.185-.397-.179-.606.012l-3.583 3.267c-.022.02-.049.034-.073.051l.017.028h7.92zm1.088-.058c-.043.21-.125.399-.293.543a.9.9 0 01-.612.208H8.697c-.36-.001-.64-.152-.797-.481a.95.95 0 01-.086-.39c-.006-1.95-.005-3.9-.003-5.848 0-.516.37-.87.895-.87h7.31c.336 0 .672.005 1.007-.003.473-.01.831.306.892.717.001.012.009.024.013.036v6.088z"
        fill="#7E7E7E"
        mask="url(#prefix__b)"
      />
      <path
        d="M16.84 10.711H8.92a.422.422 0 01-.017-.027c.025-.017.052-.03.073-.051l3.583-3.267c.21-.19.403-.197.606-.012l3.63 3.31.045.047"
        fill="#FFF"
        mask="url(#prefix__b)"
      />
      <path
        d="M13.765 7.37l-.33-.296c-.362-.323-.773-.323-1.135.002l-.327.295-3.076-2.836.013-.027h7.917l.016.025-3.078 2.838M17.137 4.79v5.66l-3.083-2.815 3.083-2.845M11.684 7.634l-3.06 2.795c-.043-.092-.051-5.496-.011-5.628l3.071 2.833"
        fill="#FFF"
        mask="url(#prefix__b)"
      />
    </g>
  </svg>
);

const ProfessionalEmailIcon = props => <Icon component={ProfessionalEmailSvg} {...props} />;
export default ProfessionalEmailIcon;
