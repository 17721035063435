export const SET_CANCEL_ORDER_API_STATUS = 'SET_CANCEL_ORDER_API_STATUS';
export const SET_UPDATE_ORDER_API_STATUS = 'SET_UPDATE_ORDER_API_STATUS';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';

export const setCancelOrderApiStatus = (jobId, status) => ({
  type: SET_CANCEL_ORDER_API_STATUS,
  payload: { jobId, status },
});

export const setUpdateOrderApiStatus = (jobId, status) => ({
  type: SET_UPDATE_ORDER_API_STATUS,
  payload: { jobId, status },
});

export const setOrderDetails = (jobId, orderDetails) => ({
  type: SET_ORDER_DETAILS,
  payload: { jobId, orderDetails },
});
