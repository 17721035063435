import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { undoJobCandidateAutoReject, fetchJobSourcingStats } from '../../Actions/JobActions';
import { getSourcingStats } from '../../Reducers/JobReducer';
import AutoRejectAlertMessage from '../../Components/CandidateList/AutoRejectAlertMessage/AutoRejectAlertMessage';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';

function AutoRejectAlert(props) {
  const { status, jobId, onStatusChange, fetchCandidates, defaultFilter } = props;
  const sourcingStats = useSelector(state => getSourcingStats(state, jobId));
  const undoJobAutoRejectApiStatus = useSelector(state => getApiStatus(state, 'undoJobAutoRejectApiStatus'));
  const dispatch = useDispatch();
  useEffect(() => {
    if (undoJobAutoRejectApiStatus?.toUpperCase() === 'COMPLETED') {
      fetchCandidates({ filter: defaultFilter });
      dispatch(fetchJobSourcingStats([jobId]));
    }
  }, [undoJobAutoRejectApiStatus]);
  const autoRejectedCandidateCount = sourcingStats?.AutoRejected ?? 0;
  const appliedCandidateCount = sourcingStats?.Applied ?? 0;
  if (!(status?.toLowerCase() === 'applied' && autoRejectedCandidateCount > 0)) return null;
  const onUndoClick = () => {
    dispatch(undoJobCandidateAutoReject(jobId));
  };
  return (
    <AutoRejectAlertMessage
      appliedCandidateCount={autoRejectedCandidateCount + appliedCandidateCount}
      autoRejectedCandidateCount={autoRejectedCandidateCount}
      undoButtonLoadingStatus={undoJobAutoRejectApiStatus?.toUpperCase() === 'INPROGRESS'}
      onUndoClick={onUndoClick}
      onReviewClick={onStatusChange}
    />
  );
}

export default AutoRejectAlert;
