import React from 'react';
import { Icon } from 'antd';
import styles from './JobDescriptionAlert.module.scss';

export function JobDescriptionAlert(props) {
  const { icon, text, isClosable } = props;
  const [isAlertClose, setIsAlertClose] = React.useState(false);

  if (isAlertClose) return null;

  return (
    <div className={styles.alertWrapper}>
      <div className={styles.alertTextWrapper}>
        {icon}
        {text}
      </div>
      {isClosable && <Icon type="close" onClick={() => setIsAlertClose(true)} />}
    </div>
  );
}

export function WarningAlert({ text }) {
  return (
    <JobDescriptionAlert
      icon={<Icon type="exclamation-circle" theme="filled" className={styles.warningIcon} />}
      text={text}
      isClosable
    />
  );
}

export function SuccessAlert({ text }) {
  return (
    <JobDescriptionAlert
      icon={<Icon type="check-circle" theme="filled" className={styles.successIcon} />}
      text={text}
      isClosable
    />
  );
}

export function LoadingAlert({ text }) {
  return <JobDescriptionAlert icon={<Icon type="loading" className={styles.loadingIcon} />} text={text} isClosable />;
}
