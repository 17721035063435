import React from 'react';
import MinMaxExperience from '../../../../Components/MinMaxExperience/MinMaxExperience';
import styles from './ExperienceSection.module.scss';

export default function ExperienceSection(props) {
  const { form, defaultFormValues } = props;
  return (
    <div className={styles.experienceSection}>
      <div className={styles.experienceLabel}>Experience</div>
      <div className={styles.experienceRangeWrapper}>
        <div className={styles.experienceRangeLabel}>Experience Range</div>
        <MinMaxExperience
          form={form}
          initialMinExperience={defaultFormValues?.Experience?.MinExperience || undefined}
          initialMaxExperience={defaultFormValues?.Experience?.MaxExperience || undefined}
          showLabel={false}
          inputClassName={styles.minMaxInput}
        />
      </div>
    </div>
  );
}
