import React from 'react';
import { debounce } from 'lodash';
import { Form, Input, Dropdown, Menu, Tooltip } from 'antd';
import LocationContext from '../LocationContext';
import { validateZipCode } from '../../../Utils/FormValidators';
import styles from './Zipcode.module.scss';

const FormItem = Form.Item;

const debouncedValidateZipCode = debounce(validateZipCode, 600);

const Zipcode = props => {
  const { label, disabled, required, initialValue, zipcodeTooltipContent, formItemStyle, isManualSearchFormMinimized } =
    props;
  const {
    form,
    isZipCodeInValid,
    isCountrySelected,
    isStateWideSearchChecked,
    zipCodeSuggestions,
    givenCountryCode,
    searchLocationUsingZipcode,
    clearZipValidationError,
    handleOnFocus,
  } = React.useContext(LocationContext);

  const zipcodeId = isManualSearchFormMinimized ? 'zipcode-minimized' : 'zipcode-expanded';

  const zipCodes = zipCodeSuggestions;
  const handleClick = zipcode => {
    form.setFieldsValue({ Zipcode: zipcode });
    debouncedValidateZipCode(
      searchLocationUsingZipcode,
      clearZipValidationError,
      zipCodeSuggestions,
      givenCountryCode,
      zipcode,
      isZipCodeInValid
    );
  };
  const handleChange = event => {
    debouncedValidateZipCode(
      searchLocationUsingZipcode,
      clearZipValidationError,
      zipCodeSuggestions,
      givenCountryCode,
      event.target.value,
      isZipCodeInValid
    );
  };
  let zipCodeMenuList = <div />;
  if (zipCodeSuggestions?.length)
    zipCodeMenuList = (
      <Menu>
        {zipCodes.map(zipcode => (
          <Menu.Item
            key={zipcode}
            onClick={() => {
              handleClick(zipcode);
            }}
          >
            {zipcode}
          </Menu.Item>
        ))}
      </Menu>
    );

  return (
    <Tooltip title={zipcodeTooltipContent}>
      <FormItem
        label={label}
        style={formItemStyle}
        colon={false}
        validateStatus={isZipCodeInValid ? 'error' : ''}
        help={isZipCodeInValid ? 'Invalid Postal code' : ''}
        id={zipcodeId}
      >
        <Dropdown
          overlay={zipCodeMenuList}
          disabled={disabled || !isCountrySelected || isStateWideSearchChecked}
          trigger={['click']}
          overlayClassName={styles.zipcodeDropdownOverlay}
        >
          {form.getFieldDecorator('Zipcode', {
            rules: [{ required, message: 'Please input zip code' }],
            initialValue,
          })(
            <Input
              placeholder="Postal Code"
              allowClear
              autoComplete="off"
              onChange={handleChange}
              onFocus={handleOnFocus}
            />
          )}
        </Dropdown>
      </FormItem>
    </Tooltip>
  );
};

export default Zipcode;
