import jobEventTypes from './JobEventTypes';

export function getEventNameByFeatureType(featureType, actionType) {
  switch (featureType?.toLowerCase()) {
    case 'refinesearch':
      return jobEventTypes.refineSearch[actionType];
    case 'manualsearch':
      return jobEventTypes.manualSearch[actionType];
    case 'job':
      return jobEventTypes.aryaSourcing[actionType];
    default:
      return null;
  }
}
