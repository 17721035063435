import React from 'react';
import {
  RedStarIcon,
  OrangeStarIcon,
  GreenStarIcon,
  GiftIcon,
  SmallRedStarIcon,
  SmallGreenStarIcon,
  MediumRedStarIcon,
  CircleIcon,
  TreeIcon,
  LineIcon,
  GreenLineIcon,
  RedLineIcon,
} from '../../Icons/OfferIcons';
import styles from './OfferBanner.module.scss';

function OfferBanner(props) {
  const { isDisplayIcon, content } = props;
  return (
    <div className={styles.offerBannerWrapper}>
      <div className={styles.offerBannerContents}>
        {isDisplayIcon ? (
          <div className={styles.offerBannerLeftIcons}>
            <SmallRedStarIcon className={styles.offerBannerRedStarOne} />
            <TreeIcon className={styles.offerBannerRedTreeOne} />
            <LineIcon className={styles.offerBannerRedLineOne} />
            <SmallGreenStarIcon className={styles.offerBannerGreenStarOne} />
            <CircleIcon className={styles.offerBannerOrangeCircleOne} />
            <GiftIcon className={styles.offerBannerGreenGiftOne} />
            <GreenLineIcon className={styles.offerBannerGreenLineOne} />
            <CircleIcon className={styles.offerBannerGreenCircleOne} />
            <OrangeStarIcon className={styles.offerBannerOrangeStarOne} />
            <RedStarIcon className={styles.offerBannerRedStarSecond} />
            <RedLineIcon className={styles.offerBannerRedLineTwo} />
          </div>
        ) : null}
        <span className={styles.offerBannerText}>{content}</span>
        {isDisplayIcon ? (
          <div className={styles.offerBannerRightIcons}>
            <TreeIcon className={styles.offerBannerGreenTreeOne} />
            <GreenLineIcon className={styles.offerBannerGreenLineTwo} />
            <CircleIcon className={styles.offerBannerGreenCircleTwo} />
            <OrangeStarIcon className={styles.offerBannerOrangeStarTwo} />
            <GiftIcon className={styles.offerBannerRedGiftTwo} />
            <LineIcon className={styles.offerBannerRedLineThree} />
            <SmallGreenStarIcon className={styles.offerBannerGreenStarTwo} />
            <MediumRedStarIcon className={styles.offerBannerRedStarThree} />
            <GreenStarIcon className={styles.offerBannerGreenStarThree} />
            <GreenLineIcon className={styles.offerBannerGreenLineThree} />
            <CircleIcon className={styles.offerBannerOrangeCircleTwo} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default OfferBanner;
