import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import style from './UserVerification.module.scss';
import { userVerificationAction } from '../../Actions/UserSessionActions';
import { resendVerificationCode } from '../../Actions/PulseSignupActions';
import { getUserVerificationStatus } from '../../Reducers/UserSessionReducer';
import UserVerificationSucess from './UserVerificationSuccess';
import UserVerificationAlreadyVerified from './UserVerificationAlreadyVerified';
import UserVerificationLinkExpired from './UserVerificationLinkExpired';
import UserVerificationFailed from './UserVerificationFailed';

const mapStateToProps = state => {
  return {
    userVerificationApiStatus: getUserVerificationStatus(state),
  };
};
const mapDispatchToProps = {
  verifyUser: userVerificationAction,
  resendCode: resendVerificationCode,
};
export function UserVerificationContainer({ history, verifyUser, userVerificationApiStatus, resendCode }) {
  const params = queryString.parse(window.location.search);
  useEffect(() => {
    if (Object.keys(params).length === 0) return;
    verifyUser(params);
  }, []);

  let message = null;
  if (userVerificationApiStatus && userVerificationApiStatus.status === 'SUCCESS') {
    message = <UserVerificationSucess history={history} />;
  }
  if (!userVerificationApiStatus || (userVerificationApiStatus && userVerificationApiStatus.status === 'PENDING')) {
    message = 'Verifying your account....';
  }
  if (userVerificationApiStatus && userVerificationApiStatus.status === 'FAILED') {
    switch (userVerificationApiStatus.statusCode) {
      case 'USER_ALREADY_CONFIRMED':
        message = <UserVerificationAlreadyVerified history={history} />;
        break;
      case 'CODE_EXPIRED':
        message = <UserVerificationLinkExpired resendCode={resendCode} username={params.user_name} />;
        break;
      case 'CODE_MISMATCH':
        message = (
          <UserVerificationFailed
            resendCode={resendCode}
            username={params.user_name}
            message="The verification link is invalid"
          />
        );
        break;
      case 'LIMIT_EXCEEDED':
        message = (
          <UserVerificationFailed
            resendCode={resendCode}
            username={params.user_name}
            message="Limit exceeded for the no. of times the email can be verified. Please retry after sometime."
          />
        );
        break;
      case 'TOO_MANY_FAILED_ATTEMPTS':
        message = (
          <UserVerificationFailed
            resendCode={resendCode}
            username={params.user_name}
            message="You've tried too many times with an invalid link. Please retry after sometime."
          />
        );
        break;
      case 'USER_NOT_FOUND':
        message = (
          <UserVerificationFailed
            resendCode={resendCode}
            username={params.user_name}
            message="The verification link is invalid"
          />
        );
        break;
      default:
        message = (
          <UserVerificationFailed
            resendCode={resendCode}
            username={params.user_name}
            message="Oops! Something went wrong while verifying your email. Please try after sometime or contact support if the problem persists."
          />
        );
        break;
    }
  }

  return (
    <div className={style.verificationContainer}>
      <div className={style.userVerificationCard}>{message}</div>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(UserVerificationContainer);
