import axios from 'axios';
import { apiV3Client } from './BaseRepository';

export function parseJobDescription(jobDescription) {
  return apiV3Client.post('jobs/_parse', {
    JobDescription: jobDescription,
    IsRankingRequired: false,
  });
}

export function suggestJobDescriptionWithCancellationToken() {
  let locationCancelRequest;
  const { CancelToken } = axios;
  return suggestionQuery => {
    if (locationCancelRequest) {
      locationCancelRequest('Operation canceled due to new request.');
    }
    return apiV3Client.post(
      'jobs/_suggest_jd',
      {
        Skills: suggestionQuery.skills,
        Title: suggestionQuery.jobTitle,
      },
      {
        cancelToken: new CancelToken(c => {
          locationCancelRequest = c;
        }),
      }
    );
  };
}

export const suggestJobDescription = suggestJobDescriptionWithCancellationToken();
