import React from 'react';
import _ from 'lodash';
import { AutoComplete, Tag } from 'antd';
import styles from './AddTagNodeContent.module.scss';

export default function AddTagNodeContent(props) {
  const { form, initialValue, drawerFooter, isDisabled } = props;
  const [tagInput, setTagInput] = React.useState();
  const initialTagList = _.get(initialValue, 'AddTagList', []);
  const [tagAutocompleteOptions, setTagAutocompleteOptions] = React.useState(initialTagList);

  form.getFieldDecorator('AddTagList', { initialValue: initialTagList });

  const { AddTagList: listOfTags } = form.getFieldsValue();

  const handleSearch = React.useCallback(async () => {
    const response = {
      Tags: [
        {
          Tag: 'Unavailable',
        },
        {
          Tag: 'Available',
        },
        {
          Tag: 'Work from home',
        },
        {
          Tag: 'Work from office',
        },
      ],
    };
    setTagAutocompleteOptions(
      _.uniqBy(
        (response?.Tags ?? []).map(tagData => ({ value: tagData.Tag, text: tagData.Tag })),
        'value'
      )
    );
  }, []);

  const handleOnSelect = value => {
    const isDuplicateTag = listOfTags.find(tag => tag === value);
    if (!isDuplicateTag) {
      form.setFieldsValue({ AddTagList: [...listOfTags, value] });
    }
    setTagInput(undefined);
  };

  const onTagClose = value => {
    form.setFieldsValue({ AddTagList: listOfTags.filter(tags => tags !== value) });
  };
  const notFoundContent = 'Type on search';

  return (
    <div className={styles.addTagContainer}>
      <AutoComplete
        dataSource={tagAutocompleteOptions}
        placeholder="Enter a tag"
        style={{ width: 470 }}
        onSearch={value => handleSearch(value)}
        onChange={setTagInput}
        onSelect={tag => handleOnSelect(tag)}
        value={tagInput}
        notFoundContent={notFoundContent}
        disabled={isDisabled}
      />
      <div className={styles.tagList}>
        {listOfTags.map(tag => (
          <Tag closable key={tag} value={tag} onClose={() => onTagClose(tag)}>
            {tag}
          </Tag>
        ))}
      </div>
      <div className={styles.addTagDrawerFooter}>{drawerFooter}</div>
    </div>
  );
}
