import React from 'react';
import styles from './EmailSettings.module.scss';

export default function DefaultSmtpComponent(props) {
  const { currentUser, orgDefaultEmail } = props;

  return (
    <div className={styles.defaultEmailSmtpComponent}>
      <div>
        <span className={styles.label}>Default &ldquo;from&rdquo; Name:</span> {currentUser.given_name}{' '}
        {currentUser.family_name}{' '}
      </div>
      <div>
        <span className={styles.label}>Default &ldquo;from&rdquo; Email:</span> {orgDefaultEmail.EmailId}
      </div>
    </div>
  );
}
