import React from 'react';
import { Button } from 'antd';
import { getEventNameByReportType } from '../../../../../../Analytics/Report/ReportEventUtils';
// import styles from './ExportReportButton.module.scss';

export default function ExportReportButton(props) {
  const { onClick, disabled, loading, type } = props;
  // const menuTitle = <div className={styles.exportReportMenuTitle}>Export Report as</div>;
  // const moreMenu = () => {
  //   return (
  //     <Menu>
  //       <Menu.ItemGroup title={menuTitle} className={styles.menuGroup}>
  //         <Menu.Item key="pdf" onClick={onClick}>
  //           <div className={styles.exportReportMenuOption} role="presentation">
  //             PDF
  //           </div>
  //         </Menu.Item>
  //         <Menu.Item key="xls" onClick={onClick}>
  //           <div className={styles.exportReportMenuOption} role="presentation">
  //             Excel (.xls)
  //           </div>
  //         </Menu.Item>
  //       </Menu.ItemGroup>
  //     </Menu>
  //   );
  // };

  return (
    // <Dropdown overlay={moreMenu} placement="bottomRight">
    <Button
      shape="round"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      sk-event-name={getEventNameByReportType(type, 'export')}
    >
      Export Report
    </Button>
    // </Dropdown>
  );
}
