import React from 'react';
import SMSNotification from './SMSNotification/SMSNotification';
import ChatNotification from './ChatNotification/ChatNotification';
import CandidateOptNotification from './CandidateOptNotification/CandidateOptNotification';
import EmailNotification from './EmailNotification/EmailNotification';

function getNotificationComponent(props) {
  switch (props.notification.Type) {
    case 'SMS':
      return <SMSNotification {...props} />;
    case 'Chat':
      return <ChatNotification {...props} />;
    case 'ConsentStatus':
      return <CandidateOptNotification {...props} />;
    case 'Email':
      return <EmailNotification {...props} />;
    default:
      return null;
  }
}

function ConnectNotification(props) {
  return <div>{getNotificationComponent(props)}</div>;
}

export default ConnectNotification;
