import * as React from 'react';
import { Icon } from 'antd';

const EmailUnsubscribedSvgV2 = props => (
  <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.1"
      d="M11.1911 12.5688L3.18481 6.34379L2.54028 5.84222L3.18716 6.34613V18.5618H20.8122V6.34613L12.8012 12.5688C12.571 12.7477 12.2877 12.8448 11.9961 12.8448C11.7046 12.8448 11.4213 12.7477 11.1911 12.5688V12.5688Z"
      fill="#83878C"
    />
    <path
      opacity="0.1"
      d="M20.5376 4.65863L21.4587 5.84221L20.8118 6.34612L21.461 5.84221L20.5399 4.65863H20.5376Z"
      fill="#83878C"
    />
    <path
      d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM19.5352 5.43984L12 11.2969L4.46484 5.43984H19.5352ZM20.8125 6.34687V18.5625H3.1875V6.34687L2.54063 5.84297L3.18516 6.34453L11.1914 12.5695C11.4217 12.7484 11.7049 12.8455 11.9965 12.8455C12.2881 12.8455 12.5713 12.7484 12.8016 12.5695L20.8125 6.34687L21.4594 5.84297L20.5383 4.65938H20.5406L21.4617 5.84297L20.8125 6.34687Z"
      fill="#83878C"
    />
    <circle cx="23.68" cy="19.68" r="7.68" fill="#FFE0E2" />
    <path
      d="M24.055 19.5002L26.5934 16.9621C26.7468 16.8086 26.7468 16.5603 26.5934 16.4078C26.4399 16.2543 26.1919 16.2543 26.0395 16.4078L23.5001 18.945L20.9607 16.4068C20.8073 16.2533 20.5592 16.2533 20.4068 16.4068C20.2534 16.5603 20.2534 16.8086 20.4068 16.9611L22.9452 19.4992L20.4068 22.0384C20.2534 22.1919 20.2534 22.4402 20.4068 22.5927C20.4835 22.6694 20.5841 22.7073 20.6838 22.7073C20.7844 22.7073 20.884 22.6694 20.9607 22.5927L23.5001 20.0545L26.0395 22.5937C26.1162 22.6704 26.2158 22.7083 26.3164 22.7083C26.417 22.7083 26.5166 22.6704 26.5934 22.5937C26.7468 22.4402 26.7468 22.1919 26.5934 22.0394L24.055 19.5002Z"
      stroke="#F5222D"
      strokeWidth="0.5"
    />
    <mask
      id="mask0_764_11494"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="20"
      y="16"
      width="7"
      height="7"
    >
      <path
        d="M24.055 19.5002L26.5934 16.9621C26.7468 16.8086 26.7468 16.5603 26.5934 16.4078C26.4399 16.2543 26.1919 16.2543 26.0395 16.4078L23.5001 18.945L20.9607 16.4068C20.8073 16.2533 20.5592 16.2533 20.4068 16.4068C20.2534 16.5603 20.2534 16.8086 20.4068 16.9611L22.9452 19.4992L20.4068 22.0384C20.2534 22.1919 20.2534 22.4402 20.4068 22.5927C20.4835 22.6694 20.5841 22.7073 20.6838 22.7073C20.7844 22.7073 20.884 22.6694 20.9607 22.5927L23.5001 20.0545L26.0395 22.5937C26.1162 22.6704 26.2158 22.7083 26.3164 22.7083C26.417 22.7083 26.5166 22.6704 26.5934 22.5937C26.7468 22.4402 26.7468 22.1919 26.5934 22.0394L24.055 19.5002Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </mask>
    <g mask="url(#mask0_764_11494)">
      <circle cx="22.5833" cy="19.9583" r="27.5" fill="#F5222D" />
      <rect
        x="-4.91675"
        y="-7.54166"
        width="55"
        height="55"
        stroke="#F5222D"
        strokeWidth="0.5"
        strokeMiterlimit="0"
        strokeLinejoin="bevel"
      />
    </g>
  </svg>
);
const EmailUnsubscribedIconV2 = props => (
  <Icon component={customProps => <EmailUnsubscribedSvgV2 {...props} {...customProps} />} {...props} />
);
export default EmailUnsubscribedIconV2;
