import React from 'react';
import ShareModalUtil from '../../../Utils/ShareModalUtils/ShareModalUtil';

function ShareSavedReportContainer(props) {
  const { isVisible, onClose, onShare, sharedUserIds, unSharedUserIds, isPublic, ownerId } = props;
  return (
    <div>
      <ShareModalUtil
        isVisible={isVisible}
        isPublic={isPublic}
        onShare={onShare}
        onClose={onClose}
        sharedUserIds={sharedUserIds}
        unSharedUserIds={unSharedUserIds}
        titleText="Share report"
        ownerId={ownerId}
        maxCount={5}
        viewerEmail=""
      />
    </div>
  );
}

export default ShareSavedReportContainer;
