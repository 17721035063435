import React from 'react';
import PubSub from 'pubsub-js';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import AIIcon from '../../Icons/AIIcon';
import { CREATE_JOB, GO_TO_BUY_CREDIT } from '../../PubSub/EventTypes';
import { LINK_TO_ADVANCED_JOB_CREATION, LINK_TO_BUY_CREDITS } from '../../Utils/LinkUtils/LinkUtils';
import styles from './FirstJobFreeSelectJobCreation.module.scss';

function FirstJobFreeSelectJobCreation(props) {
  const { setAdvancedJobFormData, availableCredits, jobCount } = props;
  return (
    <div className={styles.wrapper}>
      <AIIcon className={styles.image} />
      <div className={styles.textWrapper}>
        <div className={styles.headingText}>
          Get started for <span className={styles.freeText}>FREE!</span>
        </div>
        <div className={styles.subHeadingText}>
          Need to hire immediately? Post a job and find limited{' '}
          <span className={styles.candidateText}> free candidates</span> right away! Upgrade anytime!
        </div>
        <div className={styles.bodyText}>
          Allow us to assist you creating your first job backed by data and market intelligence, so that we may source
          the best candidates for you!
        </div>
        <Link to={LINK_TO_ADVANCED_JOB_CREATION}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              PubSub.publish(CREATE_JOB, {
                isAdvanced: true,
              });
              setAdvancedJobFormData({});
            }}
            className={styles.advancedJobButton}
          >
            Create Job
          </Button>
        </Link>
        <div className={styles.footerText}>
          Skip and select a plan to{' '}
          <Link
            to={LINK_TO_BUY_CREDITS}
            className={styles.buyCreditLink}
            onClick={() =>
              PubSub.publish(GO_TO_BUY_CREDIT, {
                sourceName: 'Banner',
                availableCredits,
                jobCount,
              })
            }
          >
            buy jobs
          </Link>
          <Icon type="right" />
        </div>
      </div>
    </div>
  );
}
export default FirstJobFreeSelectJobCreation;
