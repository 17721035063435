import { Button, Icon, Modal } from 'antd';
import React from 'react';
import {
  RecallDescription,
  RecallHeading,
  getRecallText,
} from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import styles from './RecallJobDistribution.module.scss';

export default function RecallJobDistribution(props) {
  const { visible, onCancel, onConfirm, portal, onClose, isCloseMarkNotRequired } = props;

  const heading = RecallHeading(portal);
  const description = RecallDescription(portal);
  const { cancelText, confirmText, cancelSkEventName, confirmSkEventName } = getRecallText(portal);

  return (
    <div>
      <Modal visible={visible} footer={null} centered onCancel={onClose || onCancel} closable={!isCloseMarkNotRequired}>
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="question-circle" theme="filled" />
          </div>
          <div>
            <h3>{heading}</h3>
            <p className={styles.message}>{description}</p>
            <div className={styles.actionButton}>
              <Button
                type="primary"
                style={{ marginRight: '8px' }}
                shape="round"
                key="ok-button"
                onClick={onConfirm}
                sk-event-name={confirmSkEventName}
              >
                {confirmText}
              </Button>
              <Button
                type="default"
                shape="round"
                key="cancel-button"
                onClick={onCancel}
                sk-event-name={cancelSkEventName}
              >
                {cancelText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
