import axios from 'axios';
import { apiV1Client } from './BaseRepository';

function fetchWorkflowTemplatesWithCancellation() {
  const { CancelToken } = axios;
  let cancelRequest;
  return async filter => {
    const { page, pageSize, searchTerm, isSystemTemplate, isDraft } = filter;
    let fetchWorkflowTemplatesURL = `workflow?page=${page}&pageSize=${pageSize}`;
    if (searchTerm) {
      fetchWorkflowTemplatesURL = `${fetchWorkflowTemplatesURL}&searchTerm=${searchTerm}`;
    }
    if (isSystemTemplate) {
      fetchWorkflowTemplatesURL = `${fetchWorkflowTemplatesURL}&isSystemTemplate=${isSystemTemplate}`;
    }
    if (isDraft === true || isDraft === false) {
      fetchWorkflowTemplatesURL = `${fetchWorkflowTemplatesURL}&isDraft=${isDraft}`;
    }
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV1Client
      .get(fetchWorkflowTemplatesURL, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c;
        }),
      })
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

export function saveWorkflowTemplate(workflow) {
  return apiV1Client.post('workflow', workflow);
}

export function putWorkflowDripTemplate(workflowId, workflow) {
  return apiV1Client.put(`workflow/${workflowId}`, workflow);
}

export function fetchWorkflowTemplateById(workflowId) {
  return apiV1Client.get(`workflow/${workflowId}`);
}

export function deleteWorkflowDripTemplate(workflowId) {
  return apiV1Client.delete(`workflow/${workflowId}`);
}

export function duplicateWorkflowTemplate(workflowId) {
  return apiV1Client.post(`workflow/${workflowId}/_duplicate`);
}

export function shareWorkflowDripTemplate(workflowId, shareOptions) {
  return apiV1Client.post(`workflow/${workflowId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
    IsShareWithAll: shareOptions.isShareWithAll,
    IsUnshareWithAll: shareOptions.isUnshareWithAll,
  });
}

const fetchWorkflowTemplates = fetchWorkflowTemplatesWithCancellation();

export { fetchWorkflowTemplates };
