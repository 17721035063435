import React from 'react';

const TooltipText = ({ completedInstances, percentage }) => (
  <div>
    {completedInstances} {completedInstances > 1 ? 'Candidates' : 'Candidate'}{' '}
    <span style={{ color: '#13C26B' }}>|</span> {Math.round(percentage)}%
  </div>
);

export default TooltipText;
