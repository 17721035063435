import React from 'react';
import { Form } from 'antd';
import RichTextEditor from '../../Components/Editor/RichTextEditor';
import NotesContext from './NotesContext';

function NotesEditorField() {
  const { form, onEditorContentChange, editorBody, invalidDescriptionErrorMessage } = React.useContext(NotesContext);
  return (
    <Form.Item
      validateStatus={invalidDescriptionErrorMessage ? 'error' : 'success'}
      help={invalidDescriptionErrorMessage}
    >
      {form.getFieldDecorator(
        'NotesEditor',
        {}
      )(
        <RichTextEditor
          showToolbar
          editorContent={{ htmlContent: editorBody }}
          onChange={onEditorContentChange}
          imageSupport={false}
        />
      )}
    </Form.Item>
  );
}

export default NotesEditorField;
