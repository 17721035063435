import React from 'react';
import { Link } from 'react-router-dom';
import { Steps, Icon, Button } from 'antd';
import PubSub from 'pubsub-js';
import { getPlanSelectionHeaderContent, getRedirectOptions, getProductsData } from '../../Utils/PlanSelectionUtils';
import BasicPlanOfferIcons from './BasicPlanOfferIcons';
import { RibbonIcon } from '../../Icons/OfferIcons';
import { RefineCriteriaIcon } from '../../Icons/AryaIcons';
import styles from './PlanSelection.module.scss';
import { SELECT_PLAN } from '../../PubSub/EventTypes';
import { getJobDetailsForAnalytics } from '../../Utils/JobUtils';

const { Step } = Steps;

export function ProgressSteps(props) {
  const { current, showSampleCandidates } = props;
  return (
    <Steps size="small" current={current} className={showSampleCandidates ? styles.freeJobSteps : styles.paidJobSteps}>
      <Step title="Create Job" />
      {showSampleCandidates ? <Step title="Free candidates" /> : null}
      {!showSampleCandidates ? <Step title="Select a Plan" /> : null}
      {!showSampleCandidates ? <Step title="Order" /> : null}
    </Steps>
  );
}

export function DescriptionBox({ description, cardType }) {
  if (cardType === 'Description') {
    return (
      <div
        key={description.id}
        className={`${styles.planCardDescription} ${styles.centerContent} ${styles.planCardDescriptionText}`}
      >
        {description.benefit}
      </div>
    );
  }
  return (
    <div key={description.id} className={`${styles.planCardDescription} ${styles.centerContent}`}>
      {description[cardType] ? <Icon className={styles.checkIcon} type="check" /> : ''}
    </div>
  );
}

export function PlanCardHeader(props) {
  const { planCardInfo, isOffer } = props;
  return (
    <div className={`${styles.title} ${isOffer ? styles.basicTitle : ''}`}>
      {planCardInfo.title}
      <p className={`${styles.caption} ${isOffer ? styles.basicCaption : ''}`}>{planCardInfo.caption}</p>
    </div>
  );
}

export function PlanCardPriceInfo(props) {
  const { planCardInfo } = props;
  return (
    <div className={`${styles.planPriceInfo} ${styles.centerContent}`}>
      {planCardInfo.princeInfo}
      {planCardInfo.cardType === 'TryNow' ? (
        <span className={styles.planPriceInfoDescription}>{planCardInfo.princeInfoDescription}</span>
      ) : null}
    </div>
  );
}

export function PlanCardDescription(props) {
  const { planCardInfo } = props;
  switch (planCardInfo.cardType) {
    case 'MultipleJobs':
      return (
        <div className={styles.multipleJobsPlanCard}>
          <div className={styles.multipleJobsPlanCardDescription}>
            {planCardInfo.description?.map(description => (
              <div className={styles.multipleJobsPlanCardContent}>
                <div className={styles.circle}></div>
                <div className={styles.information}>{description}</div>
              </div>
            ))}
          </div>
        </div>
      );
    case 'TryNow':
    case 'Basic':
    case 'Premium':
    case 'PremiumUpgrade':
    case 'Description':
      return planCardInfo.description?.map(description => (
        <DescriptionBox description={description} cardType={planCardInfo.cardType} />
      ));
    default:
      return null;
  }
}

export function PlanCardTurnAroundTimeInfo(props) {
  const { planCardInfo } = props;
  return (
    <div className={`${styles.turnAroundTimeInfo} ${styles.centerContent}`}>{planCardInfo.turnAroundTimeInfo}</div>
  );
}

export function PlanSelectionHeader({ isUpgrade, isFromFree }) {
  const { header, headerDescription } = getPlanSelectionHeaderContent();
  return (
    <div className={styles.header}>
      {(isFromFree || isUpgrade) && <RefineCriteriaIcon className={styles.refineCriteriaIcon} />}
      <div className={styles.headerContent}>
        <div className={styles.headerText}>{header}</div>
        <div className={styles.headerDescription}>{headerDescription}</div>
      </div>
    </div>
  );
}

function publishPlanSelectionEvent({ quantity, planName, amount, job }) {
  const analyticsJobDetails = getJobDetailsForAnalytics(job);
  PubSub.publish(SELECT_PLAN, {
    jobId: job?.JobId,
    jobTitle: job?.JobTitle,
    isAdvanced: job?.IsAdvancedJob,
    quantity,
    planName,
    price: amount,
    jobDetails: analyticsJobDetails,
  });
}

function PlanSelectButton(props) {
  const {
    isOpenBundleModal,
    selectPlanButtonText,
    amount,
    planName,
    toggleBundlePlanModalVisibility,
    isLinkButton,
    job,
  } = props;
  return (
    <Button
      type="primary"
      shape="round"
      className={`${isLinkButton ? styles.linkButton : styles.getStartedButton}`}
      block
      onClick={() => {
        if (isOpenBundleModal) toggleBundlePlanModalVisibility();
        if (planName === 'Basic') publishPlanSelectionEvent({ quantity: 1, planName, amount, job });
      }}
    >
      {selectPlanButtonText}
    </Button>
  );
}

function GetPlanButton(props) {
  const { planCardInfo, toggleBundlePlanModalVisibility, showModalVisibilty, upgradeInfo = {}, job } = props;
  const { planType, buttonText, planName, isUpgrade, id, amount } = planCardInfo;
  const { jobGuid, jobId, isFromFree } = upgradeInfo;
  const redirectPathOptions = getRedirectOptions(isUpgrade, id, jobGuid, planName, jobId, isFromFree);

  if (planType === 'MultipleJobs') {
    return (
      <PlanSelectButton
        selectPlanButtonText={buttonText}
        isOpenBundleModal
        toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
        planName={planName}
        amount={amount}
        job={job}
      />
    );
  }
  if (isUpgrade === true || planName === 'Premium') {
    return (
      <Button
        type="primary"
        shape="round"
        className={styles.getStartedButton}
        onClick={() => {
          publishPlanSelectionEvent({ quantity: 1, planName, amount, job });
          showModalVisibilty();
        }}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <Link className={styles.getStartedButton} to={redirectPathOptions}>
      <PlanSelectButton selectPlanButtonText={buttonText} planName={planName} amount={amount} job={job} isLinkButton />
    </Link>
  );
}

export function PlanCards(props) {
  const {
    upgradeInfo,
    productVariants,
    showModalVisibilty,
    toggleBundlePlanModalVisibility,
    job,
    showSampleCandidates,
  } = props;
  const { isUpgrade, jobGuid, isFromFree } = upgradeInfo;
  const productVariantsData = getProductsData(productVariants, isUpgrade, jobGuid, isFromFree);

  return (
    <div className={styles.plancards}>
      {productVariantsData.map(productVariant => (
        <PlanCard
          upgradeInfo={upgradeInfo}
          key={productVariant.title}
          showModalVisibilty={showModalVisibilty}
          toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
          planCardInfo={productVariant}
          job={job}
          showSampleCandidates={showSampleCandidates}
        />
      ))}
    </div>
  );
}
export function BasicPlanCardOfferPriceInfo(props) {
  const { planCardInfo } = props;
  return (
    <div className={`${styles.planPriceInfo} ${styles.centerContent}`}>
      <p className={styles.discountedAmount}>{planCardInfo.princeInfo}</p>
      <p className={styles.actualAmount}>$0</p>
    </div>
  );
}
function isDecemberOffer(planCardInfo) {
  const decemberOffer = localStorage.getItem('DecemberOffer');
  const showOffer = decemberOffer === 'true';
  return showOffer && planCardInfo.planName === 'Basic';
}

export function BasicPlanOfferInfo(props) {
  const { planCardInfo, isOffer } = props;
  const planButton = GetPlanButton(props);
  return (
    <>
      <div className={styles.planCardHeaderWrapper}>
        <PlanCardHeader planCardInfo={planCardInfo} isOffer={isOffer} />
        <BasicPlanOfferIcons />
      </div>
      <div className={styles.planCardPriceInfoWrapper}>
        <BasicPlanCardOfferPriceInfo planCardInfo={planCardInfo} />
      </div>
      <div className={styles.planCardTurnAroundTimeInfoWrapper}>
        <PlanCardTurnAroundTimeInfo planCardInfo={planCardInfo} />
      </div>
      <PlanCardDescription planCardInfo={planCardInfo} />
      <div className={`${styles.planCardButton} ${styles.centerContent}`}>
        {planCardInfo.buttonText ? planButton : null}
        <RibbonIcon className={styles.ribbonIcon} />
      </div>
    </>
  );
}

export function PlanCard(props) {
  const {
    planCardInfo,
    showSampleCandidates,
    toggleBundlePlanModalVisibility,
    showModalVisibilty,
    job,
    upgradeInfo,
  } = props;
  const isOffer = isDecemberOffer(planCardInfo);
  const planButton = GetPlanButton(props);
  return (
    <>
      {isOffer ? (
        <div className={styles.basicPlanCard}>
          <BasicPlanOfferInfo
            planCardInfo={planCardInfo}
            toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
            showModalVisibilty={showModalVisibilty}
            job={job}
            showSampleCandidates={showSampleCandidates}
            upgradeInfo={upgradeInfo}
            isOffer={isOffer}
          />
        </div>
      ) : (
        <div className={styles.planCard}>
          <div className={styles.planCardHeaderWrapper}>
            <PlanCardHeader planCardInfo={planCardInfo} />
          </div>
          <div className={styles.planCardPriceInfoWrapper}>
            <PlanCardPriceInfo planCardInfo={planCardInfo} />
          </div>
          <div className={styles.planCardTurnAroundTimeInfoWrapper}>
            <PlanCardTurnAroundTimeInfo planCardInfo={planCardInfo} />
          </div>
          <PlanCardDescription planCardInfo={planCardInfo} />
          <div className={`${styles.planCardButton} ${styles.centerContent}`}>
            {planCardInfo.buttonText ? planButton : null}
          </div>
        </div>
      )}
    </>
  );
}

export function OrderUpgradePlanCard(props) {
  const { planCardInfo, upgradeInfo, productVariants, history } = props;
  const { isUpgrade, jobGuid } = upgradeInfo;
  const planButton = GetPlanButton(props);
  const productVariantsData = getProductsData(productVariants, isUpgrade, jobGuid);

  return (
    <div className={styles.orderUpgradePlanCardWrapper}>
      <div className={styles.orderUpgradePlanCard}>
        <div className={styles.orderUpgradePlanCardHeader}>
          <PlanCardHeader planCardInfo={planCardInfo} />
        </div>
        <div className={styles.orderUpgradePlanCardDescriptionContainer}>
          {productVariantsData.map(cardInfo => (
            <React.Fragment key={cardInfo.cardType}>
              <div className={styles.orderUpgradePlanCardDescription}>
                <div className={styles.planCardPriceInfoWrapper}>
                  <PlanCardPriceInfo planCardInfo={cardInfo} />
                </div>
                <div className={styles.planCardTurnAroundTimeInfoWrapper}>
                  <PlanCardTurnAroundTimeInfo planCardInfo={cardInfo} />
                </div>
                <PlanCardDescription planCardInfo={cardInfo} />
              </div>
              {cardInfo.cardType === 'Description' ? (
                <div className={styles.orderUpgradePlanCardDescriptionBorder}></div>
              ) : null}
            </React.Fragment>
          ))}
        </div>
        <div className={`${styles.orderUpgradePlanCardButton}`}>
          {planCardInfo.buttonText ? planButton : null}
          <div
            className={styles.noThanksButton}
            role="button"
            tabIndex={0}
            onClick={() => history.push('/')}
            onKeyPress={() => history.push('/')}
          >
            No Thanks
          </div>
        </div>
      </div>
    </div>
  );
}
