import { defineMessages } from 'react-intl';

const messages = defineMessages({
  search: {
    id: 'JobFilter.search',
    defaultMessages: 'Search',
  },
  searchExtended: {
    id: 'JobFilter.searchExtended',
    defaultMessage: 'Search with Job title, Job Code, Location',
  },
  job: {
    id: 'JobFilter.job',
    defaultMessages: 'Job',
  },
  all: {
    id: 'JobFilter.all',
    defaultMessage: 'All',
  },
});
export default messages;
