export const SET_ACCOUNT_TYPES = 'SET_ACCOUNT_TYPES';
export const SET_LICENCE_TYPES = 'SET_LICENCE_TYPES';

export const setAccountTypes = payload => ({
  type: SET_ACCOUNT_TYPES,
  payload,
});

export const setLicenceTypes = payload => ({
  type: SET_LICENCE_TYPES,
  payload,
});
