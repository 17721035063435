import { setApiStatus } from '../ApiStatusActions';

export const FETCH_PRODUCT_VARIANTS_API_STATUS = 'FETCH_PRODUCT_VARIANTS_API_STATUS';
export const SET_PRODUCT_VARIANTS = 'SET_PRODUCT_VARIANTS';
export const SET_FETCH_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID_API_STATUS =
  'SET_FETCH_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID_API_STATUS';
export const SET_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID = 'SET_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID';
export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_API_STATUS = 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_API_STATUS';
export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT = 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT';
export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_OUT_TAX = 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_OUT_TAX';
export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_TAX = 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_TAX';
export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHTAX_API_STATUS =
  'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHTAX_API_STATUS';

export const SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHOUT_TAX_API_STATUS =
  'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHOUT_TAX_API_STATUS';

export const SET_CREATE_ORDER_API_ERROR_MESSAGE = 'SET_CREATE_ORDER_API_ERROR_MESSAGE';
export function setFetchProductVariantsApiStatus(status) {
  return { type: FETCH_PRODUCT_VARIANTS_API_STATUS, payload: status };
}

export function setProductVariants(data) {
  return { type: SET_PRODUCT_VARIANTS, payload: data };
}

export function setFetchPlanDetailsByProductVariantIdApiStatus(status) {
  return { type: SET_FETCH_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID_API_STATUS, payload: status };
}

export function setPlanDetailsByProductVariantId(data) {
  return { type: SET_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID, payload: data };
}

export function setTotalAmountForProductvariantApiStatus(status) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_API_STATUS, payload: status };
}

export function setTotalAmountForProductvariantWithTaxApiStatus(status) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHTAX_API_STATUS, payload: status };
}

export function setTotalAmountForProductvariantWithoutTaxApiStatus(status) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHOUT_TAX_API_STATUS, payload: status };
}
export function setTotalAmountForProductvariant(data) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT, payload: data };
}

export function setTotalAmountForProductvariantsWithoutTax(data) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_OUT_TAX, payload: data };
}
export function setTotalAmountForProductvariantsWithTax(data) {
  return { type: SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANTS_WITH_TAX, payload: data };
}
export function setCreateOrderApiErrorMessage(data) {
  return { type: SET_CREATE_ORDER_API_ERROR_MESSAGE, payload: data };
}

export function setFetchAvailableCreditsApiStatus(status) {
  const apiName = 'fetchAvailableCreditsApiStatus';
  return setApiStatus({ apiName, status });
}
