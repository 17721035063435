import React from 'react';
import { fetchReportStatsPayload, getRecruiterId } from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import ReportSummaryFilterContainer from '../../../../Containers/ReportPortalContainer/ReportSummaryFilterContainer/ReportSummaryFilterContainer';
import styles from './SummaryHeader.module.scss';
import ViewReportButton from '../../ReportStatsTab/ReportView/ReportViewFilterButtons/ViewReportButton/ViewReportButton';

export default function SummaryHeader(props) {
  const { form, getReportSummary, currentUserId, fetchReportSummaryApiStatus } = props;

  const onViewButtonClick = () => {
    form.validateFields().then(value => {
      const filter = getRecruiterId(value, currentUserId);
      const payload = fetchReportStatsPayload(filter);
      getReportSummary(payload);
    });
  };

  return (
    <div className={styles.summaryHeader}>
      <ReportSummaryFilterContainer form={form} currentUserId={currentUserId} />
      <div className={styles.summaryFilterButton}>
        <ViewReportButton
          onClick={onViewButtonClick}
          loading={fetchReportSummaryApiStatus?.status?.toUpperCase() === 'INPROGRESS'}
        />
      </div>
    </div>
  );
}
