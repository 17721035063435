import PubSub from 'pubsub-js';
import { trackPulseActions, setupSocketConnections, postMessage, fetchJobs } from './SubscriberActions';
import { gtmEventList } from '../Utils/PubSubUtils';
import {
  SELECT_PLAN,
  SAVE_JOB,
  CREATE_JOB,
  ADVANCED_JOB_ADDITIONAL_INPUT,
  ADVANCED_JOB_BASIC_INFO,
  ADVANCED_JOB_INSIGHTS,
  ADVANCED_JOB_EDIT_TITLE,
  ADVANCED_JOB_EDIT_INSIGHTS,
  VIEW_SAMPLE_CANDIDATES,
  ADVANCED_JOB_SAVE_DRAFT,
  NORMAL_JOB_SAVE_DRAFT,
  VIEW_PLAN_SELECTION,
  // CHECKOUT_PROGRESS,
  SELECT_MODE,
  PURCHASE,
  FREE_JOB_CHECKOUT,
  FREE_JOB_SUBMITTED,
  FREE_JOB_PURCHASED,
  PLAN_UPGRADE,
  RESUME_DOWNLOAD,
  CANDIDATE_VIEW,
  GO_TO_BUY_CREDIT,
  GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SETUP_SOCKET_CONNECTIONS,
  BROADCAST_CHANNEL_POST_MESSAGE,
  NOTIFICATION_RECEIVED,
} from './EventTypes';

function loadSubscribers() {
  PubSub.subscribe(SELECT_PLAN, trackPulseActions);
  PubSub.subscribe(SAVE_JOB, trackPulseActions);
  PubSub.subscribe(CREATE_JOB, trackPulseActions);
  PubSub.subscribe(PURCHASE, trackPulseActions);
  // PubSub.subscribe(CHECKOUT_PROGRESS, trackPulseActions);
  PubSub.subscribe(SELECT_MODE, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_BASIC_INFO, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_INSIGHTS, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_ADDITIONAL_INPUT, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_EDIT_INSIGHTS, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_EDIT_TITLE, trackPulseActions);
  PubSub.subscribe(ADVANCED_JOB_SAVE_DRAFT, trackPulseActions);
  PubSub.subscribe(VIEW_SAMPLE_CANDIDATES, trackPulseActions);
  PubSub.subscribe(VIEW_PLAN_SELECTION, trackPulseActions);
  PubSub.subscribe(NORMAL_JOB_SAVE_DRAFT, trackPulseActions);
  PubSub.subscribe(FREE_JOB_CHECKOUT, trackPulseActions);
  PubSub.subscribe(FREE_JOB_SUBMITTED, trackPulseActions);
  PubSub.subscribe(FREE_JOB_PURCHASED, trackPulseActions);
  PubSub.subscribe(PLAN_UPGRADE, trackPulseActions);
  PubSub.subscribe(RESUME_DOWNLOAD, trackPulseActions);
  PubSub.subscribe(CANDIDATE_VIEW, trackPulseActions);
  PubSub.subscribe(GO_TO_BUY_CREDIT, trackPulseActions);
  PubSub.subscribe(GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE, trackPulseActions);
  PubSub.subscribe(SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE, trackPulseActions);
  PubSub.subscribe(SETUP_SOCKET_CONNECTIONS, setupSocketConnections);
  PubSub.subscribe(BROADCAST_CHANNEL_POST_MESSAGE, postMessage);
  PubSub.subscribe(NOTIFICATION_RECEIVED, fetchJobs);
}

function unloadSubscribers() {
  for (let i = 0; i < gtmEventList.length; i += 1) {
    PubSub.unsubscribe(gtmEventList[i], trackPulseActions);
  }
}

export { loadSubscribers, unloadSubscribers };
