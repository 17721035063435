/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-02-14 02:42:26
 * @modify date 2019-02-14 02:42:26
 * @desc Dialpad
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './Dialpad.scss';

function Dialpad({ onDtmfTone }) {
  return (
    <div className="dialpad">
      <div className="dialpad-row">
        <span className="dial-note" onClick={() => onDtmfTone('1')} role="presentation">
          1
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('2')} role="presentation">
          2
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('3')} role="presentation">
          3
        </span>
      </div>
      <div className="dialpad-row">
        <span className="dial-note" onClick={() => onDtmfTone('4')} role="presentation">
          4
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('5')} role="presentation">
          5
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('6')} role="presentation">
          6
        </span>
      </div>
      <div className="dialpad-row">
        <span className="dial-note" onClick={() => onDtmfTone('7')} role="presentation">
          7
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('8')} role="presentation">
          8
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('9')} role="presentation">
          9
        </span>
      </div>
      <div className="dialpad-row">
        <span className="dial-note" onClick={() => onDtmfTone('*')} role="presentation">
          *
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('0')} role="presentation">
          0
        </span>
        <span className="dial-note" onClick={() => onDtmfTone('#')} role="presentation">
          #
        </span>
      </div>
    </div>
  );
}

// Dialpad.propTypes = {
//   onDtmfTone: PropTypes.func.isRequired,
// };

export default Dialpad;
