import * as React from 'react';
import { Icon } from 'antd';

const AddSvgV2 = props => {
  const { fillColor } = props;
  const fill = fillColor ?? '#13C26B';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_62_2024"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="20"
        height="18"
      >
        <path
          d="M20.2383 11.1562H12.7734V3.87891C12.7734 3.4125 12.3961 3.03516 11.9297 3.03516C11.4633 3.03516 11.0859 3.4125 11.0859 3.87891V11.1562H3.78516C3.31875 11.1562 2.94141 11.5336 2.94141 12C2.94141 12.4664 3.31875 12.8438 3.78516 12.8438H11.0859V20.1445C11.0859 20.6109 11.4633 20.9883 11.9297 20.9883C12.3961 20.9883 12.7734 20.6109 12.7734 20.1445V12.8438H20.2383C20.7047 12.8438 21.082 12.4664 21.082 12C21.082 11.5336 20.7047 11.1562 20.2383 11.1562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_62_2024)">
        <circle cx="4" cy="10" r="60" fill={fill} />
      </g>
    </svg>
  );
};
const AddIconV2 = props => <Icon component={customProps => <AddSvgV2 {...props} {...customProps} />} {...props} />;
export default AddIconV2;
