import React from 'react';
import { Icon } from 'antd';

function DiversityFilterSVG(props) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.074510 0 0 0 0 0.760784 0 0 0 0 0.419608 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
        <polygon id="path-2" points="0 0 20 0 20 19.9892113 0 19.9892113"></polygon>
      </defs>
      <g id="Main-flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Usecase2--Job-Level-2" transform="translate(-1012.000000, -440.000000)">
          <g id="Group-8" transform="translate(1012.000000, 440.000000)">
            <g filter="url(#filter-1)" id="Group-3">
              <g>
                <mask id="mask-3" fill="white">
                  <use xlinkHref="#path-2"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M18.6604956,9.84695727 C18.5535497,4.93316831 14.4947899,1.24414682 9.86134876,1.33324393 C4.93650823,1.42796463 1.24244996,5.50056338 1.33668165,10.1392858 C1.43653692,15.0554709 5.50312089,18.7419984 10.1342637,18.6529991 C15.0576372,18.5583762 18.7470499,14.4873423 18.6604956,9.84695727 M10.5835635,19.9892113 L9.49009448,19.9892113 C9.41997085,19.9789422 9.34979831,19.9690642 9.27972358,19.958355 C8.73452944,19.875077 8.18014196,19.8299417 7.64590159,19.7012839 C5.8020999,19.2571186 4.2089144,18.3523086 2.88042851,16.9964872 C1.4352655,15.5216904 0.519354998,13.7681105 0.157734166,11.7321779 C0.0899577665,11.3504099 0.0518152041,10.9634096 -1.95602884e-05,10.5788544 L-1.95602884e-05,9.48548314 C0.00966278249,9.42880721 0.0243818995,9.37247358 0.0282939572,9.31540643 C0.127317917,7.86730938 0.516274253,6.50209905 1.21672818,5.2305825 C2.35968474,3.155676 4.02954656,1.6567711 6.21394177,0.746190773 C7.78722467,0.0903343021 9.42823507,-0.124877771 11.1252857,0.0678399704 C12.5154354,0.225740399 13.8159501,0.649709651 15.0189078,1.35749869 C16.867893,2.44534413 18.2440082,3.96860159 19.1234387,5.92668426 C20.0508898,7.99166391 20.2337785,10.1363028 19.712888,12.3321897 C19.2748354,14.1790722 18.3643528,15.7726978 17.0081891,17.1033842 C15.5329522,18.5509433 13.7779542,19.4683208 11.7400167,19.8311642 C11.3573196,19.8992829 10.9692435,19.9373277 10.5835635,19.9892113"
                  id="Fill-1"
                  fill="#B4B7BA"
                  mask="url(#mask-3)"
                ></path>
              </g>
            </g>
            <path
              d="M9.97823126,8.47083719 C8.26651042,8.47078829 6.55478958,8.47181521 4.84311764,8.47010368 C4.40570069,8.46971247 4.1216853,8.19298329 4.13493739,7.79057926 C4.14545105,7.47169766 4.38834093,7.20973649 4.70683133,7.17819553 C4.79074496,7.16988241 4.87578332,7.17027361 4.96028376,7.17022471 C8.31863859,7.16988241 11.6769445,7.1696379 15.0352993,7.17110493 C15.1581379,7.17115383 15.2857688,7.17007801 15.4028372,7.20108107 C15.7025986,7.28039804 15.8845582,7.56690736 15.8583474,7.8881362 C15.8341904,8.18476797 15.5944791,8.42980949 15.2888496,8.4648713 C15.2116353,8.47377123 15.1328563,8.47069049 15.0547618,8.47069049 C13.3626013,8.47093499 11.6703918,8.47088609 9.97823126,8.47083719"
              id="Fill-4"
              fill="#13C26B"
            ></path>
            <path
              d="M9.99773776,11.0828496 C11.7160113,11.0828985 13.4342848,11.0819694 15.1525584,11.0835343 C15.5903176,11.0838766 15.8737462,11.3588942 15.8606408,11.7611026 C15.8500783,12.0870749 15.5963813,12.3522635 15.2696267,12.3762248 C15.1983783,12.3815061 15.1265432,12.3803325 15.0549525,12.3803325 C11.6835412,12.3805281 8.312081,12.3806748 4.94066969,12.3796479 C4.83069196,12.379599 4.71660658,12.3828264 4.61147003,12.3565178 C4.29288183,12.2767119 4.10172891,11.9797378 4.13845336,11.644181 C4.17302617,11.3280379 4.44217573,11.086615 4.78438298,11.0850013 C5.73462179,11.0805024 6.6848606,11.0829963 7.63514832,11.0828496 C8.42269443,11.0827029 9.21024054,11.0828007 9.99773776,11.0828496"
              id="Fill-6"
              fill="#13C26B"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

const DiversityFilterIcon = props => {
  return <Icon component={DiversityFilterSVG} {...props} />;
};

export default DiversityFilterIcon;
