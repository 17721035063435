import React from 'react';
import { Icon } from 'antd';
import styles from './PerformanceGrowth.module.scss';

export default function PerformanceGrowth(props) {
  const { growthQuantity, growthUnit } = props;
  const getIndicatorClassName = () => {
    if (growthQuantity > 0) return styles.incrementIndicator;
    return styles.decrementIndicator;
  };
  const getGrowthSymbol = () => {
    if (growthQuantity >= 0) return '+';
    return '';
  };
  const getIndicatorIcon = () => {
    if (growthQuantity > 0) return <Icon type="arrow-up" />;
    if (growthQuantity === 0) return <Icon type="arrows-alt" rotate={45} />;
    return <Icon type="arrow-down" />;
  };
  return (
    <div className={`${styles.performanceGrowth} ${getIndicatorClassName()}`}>
      {`${getGrowthSymbol()}${growthQuantity}${growthUnit}`} {getIndicatorIcon()}
    </div>
  );
}

PerformanceGrowth.defaultProps = {
  growthQuantity: 0,
  growthUnit: '',
};
