import React from 'react';
import { Button, Row, Badge, Empty, Skeleton, Tooltip } from 'antd';
import { listColors } from '../../../Utils/DashBoardUtils';
import ColourChart from '../../D3Charts/ColourChart';
import InsightList from './InsightList';
import styles from './Insight.module.scss';
import { EmptyCertificationsIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';
import eventTypes from '../../../Analytics/EventTypes';

export default function CertificationsInsightView(props) {
  const { listOfCertifications, totalCount, loading, readonlyStats, emptyInsightDescription } = props;
  const [certificationsListView, setCertificationsListView] = React.useState(false);
  React.useEffect(() => {
    if (certificationsListView) {
      setCertificationsListView(false);
    }
  }, [loading]);
  const certificationInsightTitle = <div className={styles.headerTitle}>Certifications</div>;
  const certificationInsightDescription = (
    <div className={styles.headerDescription}>List of certifications successfully completed by similar candidates</div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.certifications.id}>
      {certificationsListView ? (
        <>
          <div className={styles.headerSupplyInfo}>
            <div className={styles.headerInfo}>
              {certificationInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  onClick={() => setCertificationsListView(false)}
                  sk-event-name={eventTypes.job.marketIntel.hideCertificationList}
                >
                  X
                </Button>
              </div>
            </div>
            {certificationInsightDescription}
          </div>
          <div id="SkillsList">
            <InsightList insightList={listOfCertifications} loading={loading} itemSpan={11} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerSupplyInfo}>
            <div className={styles.headerInfo}>
              {certificationInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  disabled={listOfCertifications.length < 1}
                  onClick={() => setCertificationsListView(true)}
                  sk-event-name={eventTypes.job.marketIntel.displayCertificationList}
                >
                  {' '}
                  See list{' '}
                </Button>
              </div>
            </div>
            {certificationInsightDescription}
          </div>
          <div>
            <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
              <Row>
                <div className={styles.colorMapView}>
                  <ColourChart list={listOfCertifications} totalCount={totalCount} />
                </div>
              </Row>
              <div className={styles.insightListView}>
                {listOfCertifications.length > 0 ? (
                  <>
                    {listOfCertifications.slice(0, 15).map((item, i) => (
                      <Badge
                        color={listColors[i]}
                        className={styles.skillsListView}
                        text={
                          <Tooltip key={item.DisplayName} title={item.DisplayName}>
                            {item.DisplayName} ({item.SupplyPercentage}%)
                          </Tooltip>
                        }
                      />
                    ))}
                  </>
                ) : (
                  <div>
                    <Empty
                      image={<EmptyCertificationsIcon className={styles.emptyIcon} />}
                      description={
                        <div>
                          <h3>No certification data found</h3>
                          {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
