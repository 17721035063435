import _ from 'lodash';

function MailConversationsReducer(state = {}, action) {
  let newState;
  let mails;
  let pendingMails;
  let mailIds;
  switch (action.type) {
    case 'SET_CONVERSATION_MAILS':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }

      mails = _.get(state, ['ById', action.payload.conversationId], []);
      mailIds = mails.map(mail => mail.Id);
      pendingMails = _.get(state, ['Pending', 'ById', action.payload.conversationId], []);
      pendingMails = pendingMails.filter(mail => !mailIds.includes(mail.Id));
      _.set(newState, ['Pending', 'ById', action.payload.conversationId], pendingMails);
      newState.ById[action.payload.conversationId] = action.payload.Mails;
      return newState;
    case 'SET_CONVERSATION_PENDING_MAILS':
      newState = _.cloneDeep(state);
      mails = _.get(newState, ['Pending', 'ById', action.payload.conversationId], []);
      mails.unshift(action.payload.email);
      _.set(newState, ['Pending', 'ById', action.payload.conversationId], mails);
      return newState;
    case 'ADD_NEW_MAIL': {
      if (action.payload.Mail.RequestStatus === 'Pending') {
        return state;
      }
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      const receivedMail = action.payload.Mail;
      const { conversationId } = action.payload;
      pendingMails = _.get(state, ['Pending', 'ById', conversationId], []);
      pendingMails = pendingMails.filter(mail => mail.Id !== receivedMail.Id);
      _.set(newState, ['Pending', 'ById', conversationId], pendingMails);
      mails = _.get(state, ['ById', conversationId], []);
      const receivedMailGuid = receivedMail.Id;
      const index = mails.findIndex(mail => mail.Id === receivedMailGuid);
      if (index !== -1) mails[index] = receivedMail;
      else mails.unshift(receivedMail);
      newState.ById[conversationId] = mails;
      return newState;
    }
    default:
      return state;
  }
}

function getMailConversations(state, conversationId) {
  const mails = _.get(state.MailConversationsReducer, ['ById', conversationId], []);
  let pendingMails = _.get(state.MailConversationsReducer, ['Pending', 'ById', conversationId], []).map(email => ({
    ...email,
    Status: 'Pending',
  }));
  pendingMails = _.differenceBy(pendingMails, mails, 'Id');
  return [...pendingMails, ...mails];
}

export { MailConversationsReducer, getMailConversations };
