import React from 'react';
import { Badge } from 'antd';
import { ContactPullIcon } from '../../../Icons/AryaIcons';
import { getRelativeTimeSpanFromNow } from '../../../Utils/RelativeTimeCalculator';
import './ContactFetchedNotification.scss';

function getIcon() {
  return (
    <div className="contactfetched-image-wrapper">
      <div className="contactfetched-image">
        <ContactPullIcon className="contactpull-icon" />
      </div>
    </div>
  );
}

function getNotificationInfo(successCount, totalCount) {
  return (
    <div className="message">
      {successCount} of {totalCount} new candidates contacts pulled successfully.
    </div>
  );
}

export default function ContactFetchedNotification(props) {
  const { notification, onClick } = props;
  if (typeof notification.Payload === 'string') {
    notification.Payload = JSON.parse(notification.Payload);
  }
  const { Payload, UpdatedTime, UnreadCount } = notification;
  const { JobTitle, JobId, SuccessCount, TotalCount } = Payload;
  return (
    <div className="notification" role="presentation" onClick={() => onClick(notification)}>
      <div className="icon">{getIcon()}</div>
      <div className="content">
        <div className="notification-title">
          <span className="bold">{JobTitle}</span>, {JobId}
        </div>
        {getNotificationInfo(SuccessCount, TotalCount)}
        <div className="actions" />
        <div className="since">{getRelativeTimeSpanFromNow(UpdatedTime)}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}
