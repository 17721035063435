import React from 'react';
import { Button, Icon, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import GlobalInsightList from '../../Components/GlobalInsight/InsightList/GlobalInsightList';
import CustomAffixMenu from '../CustomAffixMenu';
import TryNowCandidateSupplyBanner from '../TryNowCandidateSupplyBanner';
import styles from './TryNowSplashScreen.module.scss';

function TryNowSplashScreen(props) {
  const {
    hideCandidateSupplyAnalyticsView,
    history,
    supplyPayload,
    globalInsightSupplyList,
    activeMenuList = [],
    inActiveMenuList = [],
  } = props;

  const selectPlan = () => {
    history.push('/select-plan');
  };

  return (
    <div className={styles.splashScreenWrapper}>
      <div className={styles.splashScreenHeader}>
        <Icon type="close" className={styles.splashScreenCloseIcon} onClick={hideCandidateSupplyAnalyticsView} />
        <div className={styles.buttonWrapper}>
          <Button type="primary" shape="round" onClick={hideCandidateSupplyAnalyticsView}>
            View Candidates
          </Button>
          <Button type="secondary" shape="round" style={{ marginLeft: 15 }} onClick={selectPlan}>
            Select a plan
          </Button>
        </div>
        <div className={styles.splashScreenBannerTitle}>
          Here&apos;s some <span>Candidate Supply</span> insights based on your job info.
        </div>
        <div className={styles.bannerWrapper}>
          <TryNowCandidateSupplyBanner bannerClass={styles.bannerBody} />
        </div>
      </div>
      <div className={styles.splashScreenBody}>
        <Row gutter={16}>
          <Col span={4} className={styles.antCol4}>
            <CustomAffixMenu
              menuItemId={activeMenuList[0] ? `#${activeMenuList[0].id}` : ''}
              activeMenuList={activeMenuList}
            />
          </Col>
          <Col span={18} className={styles.antCol18}>
            <GlobalInsightList
              globalInsightSupplyList={globalInsightSupplyList}
              supplyPayload={supplyPayload}
              hiddenMenuList={inActiveMenuList}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(TryNowSplashScreen);

export { TryNowSplashScreen as TryNowSplashScreenWithoutStore };
