import uuid from 'uuid';
import PubSub from 'pubsub-js';
import {
  SELECT_PLAN,
  SAVE_JOB,
  CREATE_JOB,
  ADVANCED_JOB_ADDITIONAL_INPUT,
  ADVANCED_JOB_BASIC_INFO,
  ADVANCED_JOB_INSIGHTS,
  ADVANCED_JOB_EDIT_TITLE,
  ADVANCED_JOB_EDIT_INSIGHTS,
  VIEW_SAMPLE_CANDIDATES,
  ADVANCED_JOB_SAVE_DRAFT,
  NORMAL_JOB_SAVE_DRAFT,
  VIEW_PLAN_SELECTION,
  SELECT_MODE,
  PURCHASE,
  FREE_JOB_CHECKOUT,
  FREE_JOB_SUBMITTED,
  FREE_JOB_PURCHASED,
  PLAN_UPGRADE,
  RESUME_DOWNLOAD,
  CANDIDATE_VIEW,
  GO_TO_BUY_CREDIT,
  GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
} from '../PubSub/EventTypes';
import { FREE_TRIAL } from './PlanSelectionUtils';
import { getJobDetailsForAnalytics } from './JobUtils';
import { getCurrentUserDetails } from '../Reducers/UserSessionReducer';
import store from '../store';

export const gtmEventList = [
  SELECT_PLAN,
  SAVE_JOB,
  CREATE_JOB,
  ADVANCED_JOB_ADDITIONAL_INPUT,
  ADVANCED_JOB_BASIC_INFO,
  ADVANCED_JOB_INSIGHTS,
  ADVANCED_JOB_EDIT_TITLE,
  ADVANCED_JOB_EDIT_INSIGHTS,
  VIEW_SAMPLE_CANDIDATES,
  ADVANCED_JOB_SAVE_DRAFT,
  NORMAL_JOB_SAVE_DRAFT,
  VIEW_PLAN_SELECTION,
  SELECT_MODE,
  PURCHASE,
  FREE_JOB_CHECKOUT,
  FREE_JOB_SUBMITTED,
  FREE_JOB_PURCHASED,
  PLAN_UPGRADE,
  RESUME_DOWNLOAD,
  CANDIDATE_VIEW,
  GO_TO_BUY_CREDIT,
  GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
];
function getListPosition(planName) {
  const planToListPosition = {
    Basic: 1,
    Premium: 2,
    'Multiple Jobs': 3,
    'Premium Upgrade': 4,
    FreeTrial: 5,
  };
  return planToListPosition[planName];
}

const getGtagPayloadForDefaultEvents = (payload, eventName) => {
  const listPosition = getListPosition(payload.planName);
  const { jobTitle: name, planName: variant, quantity, price, tax, invoiceNumber, userId, jobDetails } = payload;
  const randomNumber = uuid.v4();
  const eventsWithNoId = ['view_item'];
  const id = eventsWithNoId.includes(eventName) ? randomNumber.toString() : payload?.jobId?.toString(); // for create job and select mode event we dont have job id initially, so thats why a random number
  const listName = payload.isAdvanced ? 'Advanced Job Creation' : 'Normal Job Creation';
  const state = store.getState();
  const currentUserDetails = getCurrentUserDetails(state);
  const currentUserId = currentUserDetails?.Id ?? userId;
  let gtagPayload = {
    content_type: 'product',
    userId: currentUserId,
    items: [
      {
        id,
        name,
        list_name: listName,
        brand: 'Arya Pulse',
        category: 'Jobs',
        variant,
        list_position: listPosition,
        quantity,
        price,
      },
    ],
    jobDetails,
  };
  if (eventName === 'view_item_list') {
    gtagPayload = {
      content_type: 'product',
      userId: currentUserId,
      items: [
        {
          name: 'Advanced Job Creation',
          brand: 'Arya Pulse',
          category: 'Jobs',
        },
        {
          name: 'Normal Job Creation',
          brand: 'Arya Pulse',
          category: 'Jobs',
        },
      ],
    };
  }
  if (eventName === 'purchase') {
    gtagPayload = {
      ...gtagPayload,
      transaction_id: invoiceNumber,
      tax,
      shipping: 0,
      value: payload.price,
      affiliation: 'Arya Pulse',
      currency: 'USD',
    };
  }
  return gtagPayload;
};

const getGtagPayloadForPlanUpgradeEvent = payload => {
  const { currentPlan, jobGuid, sourceName } = payload || {};
  return { currentPlan, jobGuid, sourceName };
};

const getGtagPayloadForResumeDownloadEvent = payload => {
  const { candidateId, jobGuid, planName, candidateType } = payload || {};
  return { candidateId, jobGuid, planName, candidateType };
};

const getGtagPayloadForCandidateViewEvent = payload => {
  const { candidateId, candidateType, jobGuid, planName, viewCount } = payload || {};
  return { candidateId, candidateType, jobGuid, planName, viewCount };
};

const getGtagPayloadForBuyCreditEvent = payload => {
  const { sourceName, availableCredits, jobCount } = payload || {};
  return { sourceName, availableCredits, jobCount };
};

const getCandidatesViewedStats = userGuid => {
  const candidateViewedStats = JSON.parse(localStorage.getItem('candidateViewStats') || '{}');
  return candidateViewedStats[userGuid] || [];
};

const setCandidatesViewedStats = (viewedCandidateIdList, userGuid) => {
  const candidateViewedStats = JSON.parse(localStorage.getItem('candidateViewStats') || '{}');
  candidateViewedStats[userGuid] = viewedCandidateIdList;
  localStorage.setItem('candidateViewStats', JSON.stringify(candidateViewedStats));
};

const getGtagPayload = jobDetails => {
  const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
  return {
    jobId: jobDetails?.JobId,
    jobTitle: jobDetails?.JobTitle,
    isAdvanced: jobDetails?.IsAdvancedJob,
    jobDetails: analyticsJobDetails,
  };
};

const raiseGtmEvents = (jobDetails, orderResponse) => {
  const gtagPayload = getGtagPayload(jobDetails);
  const gtagPayloadForCheckout = { ...gtagPayload, quantity: 1, planName: FREE_TRIAL, price: 0 };
  const gtagPayloadForPurchase = {
    ...gtagPayloadForCheckout,
    invoiceNumber: orderResponse?.InvoiceNumber,
    tax: orderResponse?.Amount?.Tax,
  };
  PubSub.publish(SAVE_JOB, gtagPayload);
  PubSub.publish(FREE_JOB_PURCHASED, gtagPayloadForPurchase);
};
export {
  getGtagPayloadForDefaultEvents,
  getGtagPayloadForPlanUpgradeEvent,
  getGtagPayloadForResumeDownloadEvent,
  getGtagPayloadForCandidateViewEvent,
  getGtagPayloadForBuyCreditEvent,
  setCandidatesViewedStats,
  getCandidatesViewedStats,
  raiseGtmEvents,
};
