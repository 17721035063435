import React from 'react';
import { Icon, Button } from 'antd';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import styles from './AnnouncementItem.module.scss';

export const AnnouncementItem = props => {
  const { onClose, feature } = props;
  const imageURL = feature.Images.length > 0 ? feature.Images[0].URL : '';
  const pdfURL = feature.Links.length > 0 ? feature.Links[0].URL : '';
  return (
    <div className={styles.aryaBannerItem}>
      <div className={styles.aryaTop}>
        <div className={styles.aryaCloseButton}>
          <Icon type="close" onClick={onClose} style={{ fontSize: '16px', color: '#83878C' }} />
        </div>
        <div className={styles.aryaVisual}>
          <div className={styles.aryaDisplay}>
            <div className={styles.announcementImage} style={{ backgroundImage: `url("${imageURL}")` }}></div>
          </div>
        </div>
      </div>
      <div className={styles.aryaBottom}>
        <h2 className={styles.aryaFeatureName}>{feature.Name}</h2>
        <div className={styles.aryaFeatureDescription}>
          <HtmlViewer htmlContent={feature.Description} includeAttributes={['target']} />
        </div>
        <div className={styles.aryaButtons}>
          <Button type="primary" shape="round">
            <a href={pdfURL} target="_blank" rel="noreferrer">
              Learn More
            </a>
          </Button>
          <div role="presentation" onClick={onClose} className={styles.aryaSkipTour}>
            Skip Tour
          </div>
        </div>
      </div>
    </div>
  );
};
