const initialState = {
  AtsConfig: {},
};

function AtsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ATS_CONFIG':
      return {
        ...state,
        AtsConfig: action.payload,
      };
    default:
      return state;
  }
}

function getAtsConfig(state) {
  return state.AtsReducer.AtsConfig;
}

export { AtsReducer, getAtsConfig };
