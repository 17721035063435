import { apiV2Client } from './BaseRepository';

export function fetchCandidateDetails(candidateFilter) {
  if (candidateFilter.jobId) {
    return apiV2Client.get(`jobs/${candidateFilter.jobId}/candidates/${candidateFilter.candidateId}`, {
      params: {
        portal: candidateFilter.portal,
      },
    });
  }
  return apiV2Client.get(`candidates/${candidateFilter.candidateId}`, {
    params: {
      portal: candidateFilter.portal,
    },
  });
}

export function downloadResume(candidateId, resumeName, portal) {
  return apiV2Client.get(`/Candidates/${candidateId}/Resumes/${resumeName}`, {
    responseType: 'blob',
    params: {
      portal,
    },
  });
}
