export const SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS = 'SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS';
export const SET_ADVANCE_SEARCH_ACTIVITY_API_STATUS = 'SET_ADVANCE_SEARCH_ACTIVITY_API_STATUS';
export const setMonitorUserEmailActivityApiStatus = status => ({
  type: SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS,
  payload: status,
});

export const setAdvanceSearchActivityApiStatus = status => ({
  type: SET_ADVANCE_SEARCH_ACTIVITY_API_STATUS,
  payload: status,
});
