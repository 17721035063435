import React from 'react';
import { Tag, Icon, Tooltip, Skeleton } from 'antd';
import AddSuggestedTag from '../TagsDisplayComponent/AddSuggestedTag';
import styles from './CandidateTag.module.scss';

function CandidateTag(props) {
  const {
    totalSuggestedTagsCount,
    suggestedTags,
    onTagChange,
    onTagAdd,
    onLoadMore,
    tags,
    tagCloseApiStatus,
    onTagClose,
    handleFetchCandidateTags,
    fetchCandidateGlobalTagsApiStatus,
    suggestedTagsApiStatus,
    setNotification,
    isInvalidTagErrorVisible,
    setInvalidTagErrorVisiblity,
  } = props;
  React.useEffect(() => {
    handleFetchCandidateTags();
  }, []);

  const tagsResult = Object.values(
    tags.reduce((acc, { AssociationId, Name, Id }) => {
      acc[AssociationId] = acc[AssociationId] || { AssociationId, values: [] };
      acc[AssociationId].values.push(Name, Id);
      return acc;
    }, {})
  );

  return (
    <div className={styles.candidateTags}>
      <Skeleton loading={fetchCandidateGlobalTagsApiStatus === 'INPROGRESS'} paragraph={{ rows: 0 }}>
        {tagsResult?.length
          ? tagsResult.map(tag => {
              const isLongTag = tag?.values[0]?.length > 20;
              const tagElem = (
                <Tag key={tag?.values[1]} className={styles.candidateTag}>
                  {isLongTag ? `${tag?.values[0]?.slice(0, 20)}...` : tag?.values[0]}
                  <Icon
                    type={tagCloseApiStatus?.[tag?.AssociationId] === 'INPROGRESS' ? 'loading' : 'close'}
                    onClick={() => onTagClose(tag)}
                  />
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag?.values[0]} key={tag?.values[1]}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })
          : null}
        {tagsResult?.length < 10 ? (
          <div className={styles.addTag}>
            <AddSuggestedTag
              suggestedTags={suggestedTags}
              onTagChange={onTagChange}
              totalSuggestedTagsCount={totalSuggestedTagsCount}
              zIndex={2002}
              onTagAdd={onTagAdd}
              onLoadMore={onLoadMore}
              suggestedTagsApiStatus={suggestedTagsApiStatus}
              setNotification={setNotification}
              tagsResult={tagsResult}
              isInvalidTagErrorVisible={isInvalidTagErrorVisible}
              setInvalidTagErrorVisiblity={setInvalidTagErrorVisiblity}
            />
          </div>
        ) : null}
      </Skeleton>
    </div>
  );
}

export default CandidateTag;
