import React from 'react';
import { Button } from 'antd';
import PubSub from 'pubsub-js';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import styles from './PlanSelection.module.scss';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as SalesDashBoardActions from '../../Actions/SalesDashBoardActions';
import {
  getProductVariants,
  getOrderDetails,
  getProductVariantsById,
  getPlanDetailsByProductVariantId,
} from '../../Reducers/AryaPayOrderReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getJobsById } from '../../Reducers/JobReducer';
import { getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getTotalPaidCredits, getFreeJobCreditsCount } from '../../Utils/CreditInfoUtils';
import BotConfigPopup from '../BotConfig/BotConfigPopup';
import BotPrequalPopup from '../../Components/Connect/BotConfig/BotPrequalPopup';
import BundlePlanModal from '../../Components/BundlePlanModal/BundlePlanModal';
import { getProductsData, getRedirectOptions, PREMIUM_UPGRADE, PREMIUM } from '../../Utils/PlanSelectionUtils';
import { ProgressSteps, PlanSelectionHeader, OrderUpgradePlanCard, PlanCards } from './PlanSelectionModules';
import PlanSelectionFooter from '../../Components/Payment/PlanSelectionFooter';
import { VIEW_PLAN_SELECTION } from '../../PubSub/EventTypes';
import { getJobByJobGuid, getJobDetailsForAnalytics, getIsFreeCandidateStepper } from '../../Utils/JobUtils';

const mapStateToProps = state => ({
  productVariants: getProductVariants(state),
  getJobQuestionApiStatus: getApiStatus(state, 'getJobQuestionApiStatus'),
  jobsById: getJobsById(state),
  orderDetails: getOrderDetails(state),
  productVariantsById: getProductVariantsById(state),
  getAmountWithoutTaxApiStatus: getApiStatus(state, 'fetchTotalAmountForProductvariantWithoutTaxApiStatus'),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  fetchTotalAmountForProductvariants: PayOrderActions.fetchTotalAmountForProductvariants,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
  clearGlobalInsightList: SalesDashBoardActions.clearGlobalInsightList,
  setSampleCandidatesDisplayFlag: JobStatusActions.setSampleCandidatesDisplayFlag,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
};

function PlanSelection(props) {
  const [botModalVisibility, setBotModalVisibility] = React.useState(false);
  const [botPrequalPopupVisibility, setBotPrequalPopupVisibility] = React.useState(false);
  const [bundlePlanModalVisibility, setBundlePlanModalVisibility] = React.useState(false);

  const {
    getJobQuestionApiStatus,
    resetOrderDetails,
    resetOrderDetailsWithoutTax,
    jobsById,
    location,
    productVariants,
    history,
    orderDetails,
    productVariantsById,
    fetchTotalAmountForProductvariants,
    getAmountWithoutTaxApiStatus,
    clearGlobalInsightList,
    showSampleCandidates,
    setSampleCandidatesDisplayFlag,
    fetchPlanDetailsByProductVariantId,
    planDetailsByProductVariantId,
    featureToggleList,
  } = props;
  const isPulsePromotionalOffer = featureToggleList.PulsePromotionalOffer.IsEnabled;
  const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
  const { isUpgrade, jobGuid, jobId, isFromFree } = qs.parse(location.search);
  let orderUpgradePlan;
  const job = getJobByJobGuid(jobsById, jobGuid);
  const { IsAdvancedJob: isAdvancedJob } = job ?? {};
  if (isUpgrade) {
    const productVariantsData = getProductsData(productVariants, isUpgrade, jobGuid);
    orderUpgradePlan = productVariantsData.filter(productData => productData.isUpgrade);
  }
  const filteredProductVariants = productVariants.filter(productVariant => !productVariant.IsHidden);
  const analyticsJobDetails = getJobDetailsForAnalytics(job);
  const availablePaidCredits = getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById);
  const freeJobCreditsCount = getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById);
  // ComponentDidMount
  React.useEffect(() => {
    resetOrderDetails();
    resetOrderDetailsWithoutTax();
    clearGlobalInsightList();
    setSampleCandidatesDisplayFlag();
    fetchPlanDetailsByProductVariantId();
    PubSub.publish(VIEW_PLAN_SELECTION, {
      jobId: job?.JobId,
      jobTitle: job?.JobTitle,
      isAdvanced: job?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    });
  }, []);

  const pushToPaymentPage = () => {
    const productVariantsData = getProductsData(productVariants, isUpgrade, jobGuid);
    const planCardType = isUpgrade ? PREMIUM_UPGRADE : PREMIUM;
    const productVariant = productVariantsData.filter(productData => productData.cardType === planCardType);
    const redirectPathOptions = getRedirectOptions(isUpgrade, productVariant[0].id, jobGuid, null, jobId, isFromFree);
    const { pathname, search } = redirectPathOptions;
    history.push(`${pathname}${search}`);
  };
  const togglebBotModalVisibility = () => {
    setBotModalVisibility(!botModalVisibility);
    pushToPaymentPage();
  };

  const onClickSkip = () => {
    setBotPrequalPopupVisibility(!botPrequalPopupVisibility);
    pushToPaymentPage();
  };

  const showBotModalVisibilty = () => {
    setBotPrequalPopupVisibility(false);
    setBotModalVisibility(true);
  };

  const toggleBundlePlanModalVisibility = () => {
    setBundlePlanModalVisibility(!bundlePlanModalVisibility);
  };

  const showBotPrequalPopup = () => {
    setBotPrequalPopupVisibility(!botPrequalPopupVisibility);
  };

  const handleBackToJobCreate = () => {
    if (isAdvancedJob || isFromFree) {
      history.goBack();
    } else {
      history.push(`/jobs/${jobId}/edit`);
    }
  };
  const isFirstJobFree = getIsFreeCandidateStepper(availablePaidCredits, showSampleCandidates, freeJobCreditsCount);
  const currentStepperProgress = isFirstJobFree ? 2 : 1;

  const stepper = (
    <>
      <Button className={styles.backBtn} type="link" onClick={handleBackToJobCreate}>
        {'< Back'}
      </Button>
      {!isFromFree ? (
        <ProgressSteps
          current={currentStepperProgress}
          // showSampleCandidates={showSampleCandidates}
          showSampleCandidates={isFirstJobFree}
        />
      ) : null}
    </>
  );
  return (
    <>
      <BotConfigPopup
        title="Arya Pulse PreQualified Questions"
        botModalVisibility={botModalVisibility}
        onBotClose={togglebBotModalVisibility}
        jobId={job?.JobId}
        onBotCancel={togglebBotModalVisibility}
        cancelButtonText="Skip"
        saveButtonText="Save and Continue"
        hideSelectTemplate
        getJobQuestionApiStatus={getJobQuestionApiStatus}
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      />
      <BotPrequalPopup
        botPrequalPopupVisibility={botPrequalPopupVisibility}
        onClickSkip={onClickSkip}
        onClickSave={showBotModalVisibilty}
      />
      <BundlePlanModal
        modalVisibility={bundlePlanModalVisibility}
        fetchTotalAmountForProductvariants={fetchTotalAmountForProductvariants}
        orderDetails={orderDetails}
        productVariantsById={productVariantsById}
        history={history}
        toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
        fetchTotalAmountForProductvariantApiStatus={getAmountWithoutTaxApiStatus}
        resetOrderDetailsWithoutTax={resetOrderDetailsWithoutTax}
        jobDetails={job}
        isPulsePromotionalOffer={isPulsePromotionalOffer}
      />
      <div className={styles.selectPlanContainer}>
        <div className={styles.selectPlanHeaderContainer}>
          <div className={styles.progressSteps}>{!isUpgrade ? stepper : null}</div>
          <PlanSelectionHeader isFromFree={isFromFree} isUpgrade={isUpgrade} />
        </div>
        <div className={styles.selectPlanCardsContainer}>
          {isUpgrade ? (
            <OrderUpgradePlanCard
              upgradeInfo={{ isUpgrade, jobGuid, jobId }}
              planCardInfo={orderUpgradePlan[0]}
              productVariants={filteredProductVariants}
              showModalVisibilty={showBotPrequalPopup}
              history={history}
            />
          ) : (
            <PlanCards
              {...props}
              upgradeInfo={{ isUpgrade, jobGuid, jobId, isFromFree }}
              productVariants={filteredProductVariants}
              showModalVisibilty={showBotPrequalPopup}
              toggleBundlePlanModalVisibility={toggleBundlePlanModalVisibility}
              job={job}
              showSampleCandidates={showSampleCandidates}
            />
          )}
        </div>
        <div className={styles.moreDiscount}>
          Get 24 X 7 support via chat, email and phone.
          <br />
          To get your queries resolved regarding discounts and services, reach to us at <span>sales@leoforce.com</span>
        </div>
        <div className={styles.selectPlanFooterContainer}>
          <PlanSelectionFooter />
        </div>
      </div>
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanSelection));

export { PlanSelection as PlanSelectionWithoutStore };
