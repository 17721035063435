import _ from 'lodash';
import { getMapingStatsList } from './DashBoardUtils';

export const defaultExperienceRanges = [
  {
    To: 2,
  },
  {
    From: 2,
    To: 5,
  },
  {
    From: 5,
    To: 11,
  },
  {
    From: 11,
    To: 15,
  },
  {
    From: 15,
  },
];

export const menuItemsList = {
  industries: { name: 'Industries', id: 'Supply-Industries', sectionName: 'industry' },
  similarRoles: { name: 'Similar Roles', id: 'Supply-Roles', sectionName: 'role' },
  companies: { name: 'Companies', id: 'Supply-Companies', sectionName: 'company' },
  marketSkills: { name: 'Market Skills', id: 'Supply-Skills', sectionName: 'skill' },
  experience: { name: 'Experience', id: 'Supply-Experience', sectionName: 'experience' },
  location: { name: 'Location', id: 'Supply-Location', sectionName: 'location' },
  education: { name: 'Education', id: 'Supply-Education', sectionName: 'education' },
  certifications: { name: 'Certifications', id: 'Supply-Certifications', sectionName: 'certification' },
};

export const menuList = Object.values(menuItemsList);

export const getActiveInactiveSupplyInsightsList = (supplyInsights, initialActiveList, initialInActiveList) => {
  const activeList = _.cloneDeep(initialActiveList);
  const inActiveList = [...initialInActiveList];
  supplyInsights.forEach(stats => {
    if (_.isEmpty(stats.data)) {
      inActiveList.push(stats?.item?.id);
    } else {
      activeList.push(stats?.item);
    }
  });
  return [activeList, inActiveList];
};

export const getLocationSupplyInsightsList = (
  supplyPayload,
  globalInsightSupplyList,
  isCandidateSupplyAnalyticsView
) => {
  const { States = [], Cities = [], Total, LocationSpecificProfileCount } = globalInsightSupplyList;
  if (isCandidateSupplyAnalyticsView && !States?.length && !Cities?.length) {
    return [[], []];
  }
  const listOfMapStates = [];
  const listOfMapCities = [];
  if (supplyPayload.State) {
    const citiesList = getMapingStatsList(Cities, 'CityName', LocationSpecificProfileCount || Total);
    listOfMapCities.push(...citiesList);
    const statesList = getMapingStatsList(States, 'StateName', Total);
    listOfMapStates.push(...statesList);
  }
  if (!_.isEmpty(supplyPayload.City)) {
    const locationSpecificCount = LocationSpecificProfileCount ?? Total;
    const cityName = _.trim(supplyPayload.City);
    const citiesList = [
      {
        CityName: cityName,
        Count: locationSpecificCount,
      },
    ];
    listOfMapCities.push(getMapingStatsList(citiesList, 'CityName', locationSpecificCount)[0]);
  }
  return [listOfMapStates, listOfMapCities];
};
