import _ from 'lodash';

function SubscriptionPlansReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'SET_SUBSCRIPTION_PLAN_FOR_JOB':
      newState = _.cloneDeep(state);
      return { ...newState, SubscriptionPlans: { ...action.payload } };
    default:
      return state;
  }
}

function getSubscriptionPlans(state) {
  return _.get(state, ['SubscriptionPlansReducer', 'SubscriptionPlans'], {});
}

export { SubscriptionPlansReducer, getSubscriptionPlans };
