import React from 'react';
import { Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import { getSourceImage } from '../../Utils/SourceUtils';
import './JobPortal.scss';

function JobPortal(props) {
  const { selected, name, disabled, source, showVaultName, change, index, scoutingAgent } = props;
  const cssButtonClass = selected ? 'portal-tick' : 'portal';
  const cssIconClass = selected ? 'check-circle-tick' : 'check-circle';
  const buttonCssStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: scoutingAgent ? 'fit-content' : '74px',
    minHeight: '74px',
  };
  return (
    <Button
      onClick={() => {
        change(index);
      }}
      className={cssButtonClass}
      style={buttonCssStyle}
      disabled={disabled}
    >
      <div className="portal-image">
        {getSourceImage(
          source?.Portal === 'Internal' ? { Portal: 'InternalForDownloadedCandidates' } : source,
          { fontSize: 30, display: 'block' },
          showVaultName
        )}
      </div>
      <div className="portal-name" style={scoutingAgent ? { whiteSpace: 'break-spaces' } : {}}>
        {name}
      </div>
      <Icon type="check-circle" theme="filled" className={cssIconClass} twoToneColor="blue" />
    </Button>
  );
}

JobPortal.propTypes = {
  change: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  name: PropTypes.string,
};

JobPortal.defaultProps = {
  selected: false,
  name: '',
};
export default JobPortal;
