import React from 'react';
import CareerBuilderEdgeFilters from './CareerBuilderEdgeFilters/CareerBuilderEdgeFilters';
import './QuickSearchFilters.scss';

const getCurrentFilter = props => {
  const { sourceName } = props;
  if (sourceName?.toLowerCase() === 'careerbuilderedge') return <CareerBuilderEdgeFilters {...props} />;
  return null;
};
export default function QuickSearchFilters(props) {
  return <div className="quick-search-filters">{getCurrentFilter(props)}</div>;
}
