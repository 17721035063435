import * as jobStatusRepository from '../Repository/JobStatusRepository';

function fetchJobStatus(callbackFunction) {
  return dispatch => {
    return jobStatusRepository
      .fetchJobStatus()
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_JOB_STATUS',
          payload,
        });
        if (callbackFunction) {
          callbackFunction(response.data.DefaultStatusId);
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DATA_FETCH_FAIL',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function setCurrentJobStatusId(jobStatusId) {
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_JOB_STATUS_ID',
      payload: jobStatusId,
    });
  };
}

function fetchJobCountByStatus(filter, allowPageLoading = true) {
  return dispatch => {
    if (allowPageLoading)
      dispatch({
        type: 'SET_JOB_STATUS_COUNT_API_STATUS',
        payload: 'INPROGRESS',
      });
    jobStatusRepository
      .fetchJobCountByStatus(filter)
      .then(response => {
        if (allowPageLoading)
          dispatch({
            type: 'SET_JOB_STATUS_COUNT_API_STATUS',
            payload: 'COMPLETED',
          });
        dispatch({
          type: 'SET_JOB_COUNT_BY_STATUS',
          payload: response.data,
        });
        const nonDraftJobsCount =
          response.data.JobStatus?.filter(word => word.Name !== 'Draft')
            .map(item => item.Count)
            .reduce((acc, val) => acc + val, 0) || 0;
        const showSampleCandidates = nonDraftJobsCount <= 0;
        localStorage.setItem('SampleCandidatesDisplayFlag', showSampleCandidates);
        dispatch({ type: 'SET_NON_DRAFT_JOB_COUNT', payload: nonDraftJobsCount });
        dispatch({
          type: 'SET_SAMPLE_CANDIDATES_DISPLAY_FLAG',
          payload: showSampleCandidates,
        });
      })
      .catch(error => {
        if (allowPageLoading)
          dispatch({
            type: 'SET_JOB_STATUS_COUNT_API_STATUS',
            payload: 'FAILED',
          });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function setSampleCandidatesDisplayFlag() {
  const sampleCandidatesDisplayFlag = localStorage.getItem('SampleCandidatesDisplayFlag');
  const showSampleCandidates = sampleCandidatesDisplayFlag === 'true';
  if (!sampleCandidatesDisplayFlag) {
    const defaultFilter = {
      IsAssigned: true,
      SearchKeyword: '',
      StatusId: -1,
      TagIds: [],
    };
    return fetchJobCountByStatus(defaultFilter);
  }
  return dispatch => {
    dispatch({
      type: 'SET_SAMPLE_CANDIDATES_DISPLAY_FLAG',
      payload: showSampleCandidates,
    });
  };
}

export { setSampleCandidatesDisplayFlag, fetchJobStatus, fetchJobCountByStatus, setCurrentJobStatusId };
