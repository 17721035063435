import React from 'react';
import { Skeleton } from 'antd';
import styles from './NotesCard.module.scss';
import NotesCardItem from './NotesCardItem';

function NotesCard(props) {
  const {
    allNotes,
    onDelete,
    noteFetchApiStatus,
    onEdit,
    noteDeleteApiStatuses,
    onTagChange,
    onLoadMore,
    onTagAdd,
    suggestedTags,
    totalSuggestedTagsCount,
    tagCloseApiStatus,
    onTagClose,
    setNotification,
    suggestedTagsApiStatus,
  } = props;
  const [isSeeMore, setSeeMore] = React.useState(false);
  const handleButtonClick = () => setSeeMore(!isSeeMore);
  const buttonName = isSeeMore ? 'See More' : 'See Less';
  return (
    <div>
      {allNotes?.length ? (
        <div role="presentation" className={styles.seeButton} onClick={handleButtonClick}>
          {buttonName}
        </div>
      ) : null}
      {!isSeeMore ? (
        <Skeleton loading={noteFetchApiStatus === 'INPROGRESS'} avatar paragraph={{ rows: 4 }}>
          {(allNotes || []).map(note => (
            <NotesCardItem
              key={note.Id}
              note={note}
              onDelete={onDelete}
              onUpdateNote={onEdit}
              noteDeleteApiStatus={noteDeleteApiStatuses?.[note?.Id]}
              onTagChange={onTagChange}
              onTagLoadMore={onLoadMore}
              onTagAdd={onTagAdd}
              onTagClose={onTagClose}
              suggestedTags={suggestedTags}
              totalSuggestedTagsCount={totalSuggestedTagsCount}
              tagCloseApiStatus={tagCloseApiStatus}
              setNotification={setNotification}
              suggestedTagsApiStatus={suggestedTagsApiStatus}
            />
          ))}
        </Skeleton>
      ) : null}
    </div>
  );
}

export default NotesCard;
