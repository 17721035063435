import _ from 'lodash';

function CognitoReducer(state = {}, action = {}) {
  let newState;
  if (action.type === 'SET_IDENTITY_CREDENTIALS') {
    newState = _.cloneDeep(state);
    const credentials = action.payload;
    newState.IdentityCredentials = credentials;
    return newState;
  }
  return state;
}

function getIdentityCredentials(state) {
  return state.CognitoReducer?.IdentityCredentials || {};
}

export { CognitoReducer, getIdentityCredentials };
