const calendar = {};
const meeting = {};
const settings = {};

const calendarEventTypes = {
  calendar,
  meeting,
  settings,
};

export default calendarEventTypes;
