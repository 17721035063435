import React from 'react';
import _ from 'lodash';
import JobPortal from '../../../Components/JobPortal/JobPortal';
import styles from './JobPortals.module.scss';
import { getSourceName, getSourceDisplayName } from '../../../Utils/SourceUtils';

const JobPortals = props => {
  const {
    sources,
    sourceTitle,
    showVaultName,
    whiteLabelInfo,
    authorizedPortals,
    currentJobActivationStatus,
    handlePortalSelect,
    isJobActivationAllowed,
    userConfig,
  } = props;
  return (
    <div className={styles.portals}>
      <div className={styles.sourceTitle}>{sourceTitle}</div>
      <div className={styles.portalActivationButtons}>
        {sources.map(portalSource => {
          const sourceName = getSourceName(portalSource);
          const sourceDisplayName = getSourceDisplayName(
            portalSource,
            userConfig,
            showVaultName,
            {
              Name: 'activationDialougeIcon',
            },
            whiteLabelInfo
          );
          const index = sourceName.toLowerCase();
          const isDisabled =
            !authorizedPortals.includes(portalSource.Group || portalSource.Portal) ||
            !_.get(currentJobActivationStatus, [index], false);
          return (
            <JobPortal
              name={sourceDisplayName}
              showVaultName={showVaultName}
              key={sourceName}
              index={index}
              source={portalSource}
              scoutingAgent
              selected={
                !isDisabled &&
                currentJobActivationStatus &&
                currentJobActivationStatus[index] &&
                currentJobActivationStatus[index].isClicked
              }
              change={handlePortalSelect}
              disabled={!isJobActivationAllowed || isDisabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JobPortals;
