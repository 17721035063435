import React from 'react';
import { Icon, Tooltip } from 'antd';
import styles from './PushCandidateComponent.module.scss';
import PushIconV2 from '../../Icons/PushIconV2';

function PushCandidateComponent(props) {
  const {
    atsSource,
    onPushCandToAts,
    loading = false,
    isPushAllowed = false,
    showContactInfoButton,
    showPushCandidateButton,
    isRecreateCandidateAllowed,
  } = props;

  if (loading) {
    return (
      <div className={styles.loadinIcon}>
        <Icon type="loading" />
      </div>
    );
  }

  if (atsSource) {
    return (
      <Tooltip title={<div>Client Candidate ID: {atsSource.CandidateId}</div>} overlayClassName="drawer-tooltip">
        <div className={styles.candidateDetailPushedCandidate}>
          <PushIconV2
            className={styles.pushStatusIcon}
            onClick={
              isPushAllowed && isRecreateCandidateAllowed
                ? onPushCandToAts
                : e => {
                    e.stopPropagation();
                  }
            }
            style={{ fontSize: '18px' }}
            fillColor="#07101a"
          />
        </div>
      </Tooltip>
    );
  }

  const shouldShowInfoButton = showContactInfoButton && isPushAllowed && showPushCandidateButton;

  if (shouldShowInfoButton) {
    return (
      <div className={styles.candidateDetailPushCandidate} role="presentation" onClick={onPushCandToAts}>
        <PushIconV2 style={{ fontSize: '18px' }} fillColor="#07101a" />
      </div>
    );
  }
  return null;
}

export default PushCandidateComponent;
