import React from 'react';
import { Tooltip, Skeleton } from 'antd';
import styles from './Insight.module.scss';

export default function InsightList(props) {
  const { insightList, loading } = props;
  return (
    <Skeleton paragraph={{ rows: 5 }} loading={loading}>
      <div className={styles.insightListView}>
        {insightList.map(item => (
          <div className={styles.supplyListItem}>
            <span className={styles.percent}>{item.SupplyPercentage}% </span>
            <Tooltip title={`${item.DisplayName} (${item.Value} Profiles)`}>
              <span className={styles.displayName}>{item.DisplayName}</span>
            </Tooltip>
          </div>
        ))}
      </div>
    </Skeleton>
  );
}
