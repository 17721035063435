import _ from 'lodash';
import {
  SET_GLOBAL_COUNTRIES,
  SET_STATE_SUGGESTIONS,
  SET_GLOBAL_LOCATIONS,
  SET_CITY_SUGGESTIONS,
  SET_CITY_AND_STATE_SUGGESTIONS,
  SET_LOCATION_VALIDATION_ERROR,
} from '../Actions/ActionCreators/LocationSearchActions';

const initialState = {
  countries: [],
  locations: [],
  stateSuggestions: [],
  citySuggestions: [],
  error: '',
  errorMessage: '',
};

function LocationSearchReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case SET_GLOBAL_COUNTRIES:
      newState = _.cloneDeep(state);
      newState.countries = action.payload;
      return newState;
    case SET_GLOBAL_LOCATIONS:
      newState = _.cloneDeep(state);
      newState.locations = action.payload;
      return newState;
    case SET_STATE_SUGGESTIONS:
      newState = _.cloneDeep(state);
      newState.stateSuggestions = action.payload;
      return newState;
    case SET_CITY_SUGGESTIONS:
      newState = _.cloneDeep(state);
      newState.citySuggestions = action.payload;
      return newState;
    case SET_CITY_AND_STATE_SUGGESTIONS:
      newState = _.cloneDeep(state);
      newState.locationSuggestions = action.payload;
      return newState;
    case SET_LOCATION_VALIDATION_ERROR:
      newState = _.cloneDeep(state);
      newState.error = action.payload.error;
      newState.errorMessage = action.payload.errorMessage;
      return newState;
    default:
      return state;
  }
}

function getLocationSearchReducerUtilities(state) {
  return state.LocationSearchReducer;
}

function getStateSuggestions(state) {
  return state.LocationSearchReducer?.stateSuggestions?.map(x => x.state.name) ?? [];
}
function getLocationSuggestions(state) {
  return state.LocationSearchReducer?.locationSuggestions ?? [];
}
function getLocationValidationStatus(state) {
  return state.LocationSearchReducer?.error ?? '';
}
function getLocationValidationErrorMessage(state) {
  return state.LocationSearchReducer?.errorMessage ?? '';
}
export {
  LocationSearchReducer,
  getLocationSearchReducerUtilities,
  getStateSuggestions,
  getLocationSuggestions,
  getLocationValidationStatus,
  getLocationValidationErrorMessage,
};
