import React, { useEffect } from 'react';
import { Form, Icon } from 'antd';
import './CommonJobBoardForm.scss';
import CountrySelect from '../CommonLoginFormComponents/CountrySelect';
import PasswordInput from '../CommonLoginFormComponents/PasswordInput';
import AuthenticationButton from '../CommonLoginFormComponents/AuthenticationButton';
import UsernameInput from '../CommonLoginFormComponents/UsernameInput';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

export function submitJobBoardForm(
  {
    form,
    jobBoard,
    index,
    removeSelectedCountry,
    clearAccount,
    addSelectedCountry,
    authenticate,
    disconnect,
    isAuthenticated,
  },
  values,
  error
) {
  if (!error) {
    const userName = values[`${jobBoard}userName`].trim();
    const password = values[`${jobBoard}password`].trim();
    form.setFieldsValue({
      [`${jobBoard}userName`]: userName,
      [`${jobBoard}password`]: password,
    });
    const countryCode = values[`${jobBoard}countryCode`];
    if (isAuthenticated) {
      disconnect(jobBoard, countryCode, index, removeSelectedCountry);
    } else {
      authenticate(
        jobBoard,
        {
          Username: userName,
          Password: password,
          CountryCode: countryCode,
        },
        index,
        clearAccount,
        addSelectedCountry
      );
    }
  }
}

function CommonJobBoardForm(props) {
  const { form, jobBoard, isAuthenticated, account } = props;

  useEffect(() => {
    form.validateFields();
  }, []);

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      submitJobBoardForm(props, values, err);
    });
  };

  const mandatoryFields = [`${jobBoard}userName`, `${jobBoard}password`];
  const errorDetail = getJobBoardErrorDetails(account, jobBoard);

  return (
    <div className="portal-login-form">
      <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
        {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
      </div>
      <div>
        <Form>
          <div className="jobboard-credentials-container">
            <CountrySelect {...props} />
            <UsernameInput {...props} />
            <PasswordInput {...props} />
            <AuthenticationButton
              {...props}
              onClickAuthenticationButton={handleSubmit}
              mandatoryFields={mandatoryFields}
            />
          </div>
        </Form>
        <div className="error-message">{errorDetail.Message}</div>
      </div>
    </div>
  );
}

export default Form.create()(CommonJobBoardForm);
export { CommonJobBoardForm as CommonJobBoardFormWithoutForm };
