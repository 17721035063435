import * as React from 'react';
import { Icon } from 'antd';

const CallSuccessSvgV2 = props => (
  <svg width="34" height="28" viewBox="0 0 34 28" fill="none" {...props}>
    <path
      opacity="0.1"
      d="M19.6667 16.9144L17.0675 14.3176L14.2386 17.1488L13.7324 16.9613C12.1972 16.3975 10.803 15.5066 9.64632 14.3504C8.48969 13.1941 7.5983 11.8002 7.03394 10.2652L6.84644 9.75897L6.86753 9.73788L9.68003 6.93007L7.08081 4.33319L4.63159 6.78241C4.38081 7.03553 4.26362 7.39178 4.32222 7.73866C4.77925 10.5019 6.26518 13.2558 8.50347 15.4941C10.7417 17.7324 13.498 19.2183 16.2613 19.6754C16.6082 19.734 16.9644 19.6168 17.2175 19.3637L19.6667 16.9144Z"
      fill="#83878C"
    />
    <path
      d="M18.4055 20.557L20.8992 18.0609C21.2039 17.7562 21.375 17.3484 21.375 16.9172C21.375 16.4859 21.2062 16.0781 20.8992 15.7734L18.2156 13.0852C17.9109 12.7805 17.5008 12.6094 17.0695 12.6094C16.6359 12.6094 16.2305 12.7781 15.9234 13.0852L13.8211 15.1852C12.7064 14.7068 11.6937 14.0194 10.8375 13.1602C9.97266 12.3 9.29063 11.2945 8.80781 10.1789L10.9102 8.07891C11.2148 7.77422 11.3859 7.36641 11.3859 6.93516C11.3867 6.72244 11.345 6.51172 11.2634 6.31532C11.1817 6.11892 11.0616 5.9408 10.9102 5.79141L8.22656 3.10078C7.92188 2.79609 7.51172 2.625 7.08047 2.625C6.64688 2.625 6.24141 2.79375 5.93438 3.10078L3.44063 5.59453C2.92031 6.11484 2.62266 6.83203 2.62266 7.56797C2.62266 7.72031 2.63438 7.86797 2.66016 8.01797C3.17109 11.1211 4.82344 14.2008 7.30781 16.6875C9.79687 19.1719 12.8766 20.8219 15.982 21.3375C16.8633 21.4852 17.7703 21.1898 18.4055 20.557V20.557ZM16.2609 19.6758C13.4977 19.2187 10.7414 17.7328 8.50313 15.4945C6.26484 13.2562 4.77891 10.5023 4.32188 7.73906C4.26328 7.39219 4.38047 7.03594 4.63125 6.78281L7.08047 4.33359L9.67969 6.93047L6.86719 9.73828L6.84609 9.75937L7.03359 10.2656C7.59796 11.8006 8.48934 13.1945 9.64598 14.3508C10.8026 15.507 12.1968 16.3979 13.732 16.9617L14.2383 17.1492L17.0672 14.318L19.6664 16.9148L17.2172 19.3641C16.9641 19.6172 16.6078 19.7344 16.2609 19.6758Z"
      fill="#83878C"
    />
    <circle cx="25.68" cy="19.68" r="7.68" fill="#DAF0E5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4597 24.48L21.8401 20.8613L23.4226 19.2788L25.1742 21.0304L28.8815 15.84L30.7021 17.1396L25.4597 24.48Z"
      fill="#13C26B"
    />
  </svg>
);
const CallSuccessIconV2 = props => (
  <Icon component={customProps => <CallSuccessSvgV2 {...props} {...customProps} />} {...props} />
);
export default CallSuccessIconV2;
