import React from 'react';
import { Affix } from 'antd';
import { Scroll } from '../Scroll/Scroll';
import styles from './SettingsLayout.module.scss';

export default function SettingsLayout(props) {
  const { sider, content } = props;
  return (
    <div className={styles.settingsLayout}>
      <Scroll>
        <div className={styles.affixWrapper}>
          <Affix offsetTop={80} className={styles.affix}>
            <div className={styles.siderWrapper}>
              <div className={styles.sider}>{sider}</div>
              <div className={styles.verticalLine} />
            </div>
          </Affix>
        </div>
        <div className={styles.content}>{content}</div>
      </Scroll>
    </div>
  );
}
