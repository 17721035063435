import React from 'react';
import MilesInput from '../../../../../Components/Common/MilesInput/MilesInput';
import styles from './ManualSearchMiles.module.scss';

export default function ManualSearchMiles(props) {
  const { form, locationType, isCountryWideSearchEnabled, defaultRange, candidateContext } = props;
  const isRemoteJob = form.getFieldValue('IsRemoteJob');
  const isStateWideSearchChecked = form.getFieldValue('IsStateWideSearch');
  const isMilesDisabled =
    isCountryWideSearchEnabled ||
    (isRemoteJob && locationType === 'Location') ||
    (isStateWideSearchChecked && locationType !== 'MultiLocation');
  return (
    <div className={styles.manualSearchMiles}>
      <MilesInput
        label="Specify the maximum miles for sourcing"
        form={form}
        initialRange={defaultRange}
        isDistanceDisabled={isMilesDisabled}
        maxAllowedRange={999}
        distanceFieldName="Range"
        candidateContext={candidateContext}
      />
    </div>
  );
}
