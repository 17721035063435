import { scale } from './companyTableScale';

export function getMinAndMaxScoreRevenueCompanySize(bucket, key) {
  let minScore = 2;
  let maxScore = 98;
  const sizeBuckets = scale[key];

  const keys = Object.keys(sizeBuckets).sort((a, b) => sizeBuckets[a] - sizeBuckets[b]);
  const currentBucketIndex = keys.indexOf(bucket);

  if (currentBucketIndex >= 0) {
    minScore = sizeBuckets[keys[currentBucketIndex]];
  }

  if (currentBucketIndex < keys.length - 1) {
    maxScore = sizeBuckets[keys[currentBucketIndex + 1]];
  }

  return { minScore, maxScore };
}

export function getMinAndMaxScoreLocationPopularity(locationCount, key) {
  let minScore = 2;
  let maxScore = 98;
  const locationBuckets = scale[key];

  for (const bucket of locationBuckets) {
    if (locationCount >= bucket.FromValue && locationCount < bucket.ToValue) {
      minScore = bucket.Score;
      break;
    }
  }

  for (let i = locationBuckets.length - 1; i >= 0; i -= 1) {
    const bucket = locationBuckets[i];
    if (locationCount < bucket.ToValue) {
      maxScore = bucket.Score;
    } else {
      break;
    }
  }

  if (minScore === maxScore && locationBuckets.length > 1) {
    const nextIndex = locationBuckets.findIndex(b => b.Score === maxScore);
    if (nextIndex < locationBuckets.length) {
      maxScore = locationBuckets[nextIndex].Score;
    }
  }

  return { minScore, maxScore };
}

export function updateScoreRevenueAndCompanySize(bucket, key) {
  const { minScore, maxScore } = getMinAndMaxScoreRevenueCompanySize(bucket, key);
  return Math.floor(Math.random() * (maxScore - minScore) + minScore);
}

export function updateScoreLocationPopularity(locationCount, key) {
  const { minScore, maxScore } = getMinAndMaxScoreLocationPopularity(locationCount, key);
  return Math.floor(Math.random() * (maxScore - minScore) + minScore);
}
