import React from 'react';
import { connect } from 'react-redux';
import * as regionActions from '../../Actions/RegionActions';
import { getRegions } from '../../Reducers/RegionReducer';
import * as jobActions from '../../Actions/JobActions';
import MultilocationSettings from '../Settings/AdminSettings/MultilocationSettings/MultilocationSettings';
import { getJobUtilities } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';

const mapStateToProps = state => {
  return {
    regions: getRegions(state),
    jobUtilities: getJobUtilities(state),
    locationApiStatus: getApiStatus(state, 'locationApiStatus'),
  };
};

const mapDispatchToProps = {
  fetchRegions: regionActions.fetchRegions,
  addRegion: regionActions.addRegion,
  deleteRegion: regionActions.deleteRegion,
  fetchLocations: jobActions.fetchLocations,
  clearLocations: jobActions.clearLocations,
};

function MultiLocationSettingsContainer(props) {
  const {
    regions,
    fetchRegions,
    clearLocations,
    addRegion,
    deleteRegion,
    fetchLocations,
    jobUtilities,
    locationApiStatus,
  } = props;
  const locations = jobUtilities?.locations ?? [];
  const countryOptions = jobUtilities?.countries ?? [];
  return (
    <MultilocationSettings
      regions={regions}
      fetchRegions={fetchRegions}
      addRegion={addRegion}
      deleteRegion={deleteRegion}
      fetchLocations={fetchLocations}
      locations={locations}
      locationApiStatus={locationApiStatus}
      countryOptions={countryOptions}
      clearLocations={clearLocations}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiLocationSettingsContainer);
export { MultiLocationSettingsContainer as MultiLocationSettingsContainerWithoutStore };
