import { Button, Icon, Dropdown, Tooltip } from 'antd';
import React, { Component, Fragment } from 'react';
import { getRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import './TemplateRow.scss';

/* eslint-disable */
export default class TemplateRow extends Component {
  editTemplate() {
    const { editTemplate, templateId } = this.props;
    editTemplate(templateId);
  }

  sharePopUp() {
    const { fetchUsersForShare } = this.props;
    const { showUsersPopup } = this.state;
    this.setState({ showUsersPopup: !showUsersPopup });
    fetchUsersForShare(1);
  }

  onClosePopup() {
    const { fetchUsersForShare } = this.props;
    this.setState({ showUsersPopup: false });
    fetchUsersForShare(1);
  }

  duplicateTemplate() {
    const { duplicateTemplate, templateId } = this.props;
    duplicateTemplate(templateId);
  }

  deleteTemplate() {
    const { deleteTemplate, templateId } = this.props;
    deleteTemplate(templateId);
  }

  getTitleColumn(title, isConsent) {
    return (
      <Tooltip title={title} placement="topLeft">
        <div className="template-title user-text-template-title-wrapper">
          {title}
          {isConsent ? <div className="user-text-template-consent-box"> Consent </div> : null}
        </div>
      </Tooltip>
    );
  }

  getUpdatedOnColumn(updatedDate) {
    return <span className="template-updated-on">{getRelativeTime(updatedDate)}</span>;
  }

  getIsBotIncludedColumn(isBotIncluded) {
    let botStatus = 'No';
    if (isBotIncluded === true) {
      botStatus = 'Yes';
    }
    return <span className="template-bot-status">{botStatus}</span>;
  }

  getSharedCountColumn(sharedCount) {
    return (
      <span className="template-shared-count">
        <Icon type="user" />
        {sharedCount}
      </span>
    );
  }

  getCreatedByColumn(creatorName) {
    return <span className="template-by">{creatorName}</span>;
  }

  getStatsColumn(stats) {
    return (
      <span className="template-stats">
        {stats.responsePercentage}% / {stats.open} / {stats.clicks}
      </span>
    );
  }

  getCloneColumn(cloneText, cloneCallback, createdBy, skEventName, isDisabled) {
    let isBtnDisabled = isDisabled;
    if ((typeof createdBy === 'string' && !createdBy.trim()) || createdBy === null) {
      isBtnDisabled = true;
    }
    return (
      <Button className="clone-button" onClick={cloneCallback} disabled={isBtnDisabled} sk-event-name={skEventName}>
        {cloneText}
      </Button>
    );
  }

  getActionsColumn(actionsMenu) {
    return (
      <Dropdown overlay={actionsMenu}>
        <Icon type="ellipsis" style={{ transform: 'rotate(90deg)' }} />
      </Dropdown>
    );
  }

  getSubject(subjectText, subject) {
    return (
      <Tooltip title={subject} placement="bottomLeft">
        <div className="template-subject">{`${subjectText} : ${subject}`}</div>
      </Tooltip>
    );
  }

  addSubject(element, subjectElement) {
    return (
      <Fragment>
        {element}
        {subjectElement}
      </Fragment>
    );
  }
}
