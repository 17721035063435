import React from 'react';
import { Button } from 'antd';
import { getSourceImage } from '../../Utils/SourceUtils';
import SourcesContext from './SourceContext';
import styles from './SourceItem.module.scss';

const SourceItem = props => {
  const { candidateContext = 'job', isNonInternalPortalForSegmentEnabled } = props;

  const { sourceNamesSelected, sourceName, isSourceAuthorized, source, showVaultName, onSourceSelect } =
    React.useContext(SourcesContext);

  const isInternalSelectedOnly = sourceNamesSelected?.length === 1 && sourceNamesSelected[0] === 'Leoforce';
  const isSourceAButtonDisabledForSgment =
    isInternalSelectedOnly && candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled;
  const isSourceButtonDisabled = !isSourceAuthorized || isSourceAButtonDisabledForSgment;


  return (
    <Button
      className={`${styles.sourceFilter} ${
        sourceNamesSelected.includes(sourceName) && isSourceAuthorized ? styles.selected : styles.unselected
      }`}
      key={sourceName}
      onClick={() => onSourceSelect(sourceName)}
      disabled={isSourceButtonDisabled}
    >
      {getSourceImage(source, { fontSize: 24, height: 24, verticalAlign: 'middle' }, showVaultName)}
    </Button>
  );
};

export default SourceItem;
