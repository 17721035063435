import React from 'react';
import './PulseInfoText.scss';

function PulseInfoText() {
  return (
    <div className="pulse-info-text-wrapper">
      <div className="pulse-info-text">
        <div className="pulse-info-image">
          <img src={`${process.env.PUBLIC_URL}/static/Images/pulse_login_ai.svg`} alt="arya pulse logo" />
        </div>
        <div className="pulse-info-message-text">
          <div className="pulse-info-message-header">Flexible</div>
          <div className="pulse-info-message-description first-level-text">On-demand recruiting service</div>
          <div className="pulse-info-message-description second-level-text">Perfect solution for low-volume hiring</div>
        </div>
      </div>
      <div className="pulse-info-text">
        <div className="pulse-info-image">
          <img src={`${process.env.PUBLIC_URL}/static/Images/pulse_login_relevant.svg`} alt="arya pulse logo" />
        </div>
        <div className="pulse-info-message-text">
          <div className="pulse-info-message-header">Relevant</div>
          <div className="pulse-info-message-description first-level-text">
            Qualified interview ready candidate list
          </div>
          <div className="pulse-info-message-description second-level-text">Intelligent job advertising support</div>
        </div>
      </div>
      <div className="pulse-info-text">
        <div className="pulse-info-image">
          <img src={`${process.env.PUBLIC_URL}/static/Images/pulse_login_easy.svg`} alt="arya pulse logo" />
        </div>
        <div className="pulse-info-message-text">
          <div className="pulse-info-message-header">Easy</div>
          <div className="pulse-info-message-description first-level-text">
            Fixed price per job with no long term contract
          </div>
          <div className="pulse-info-message-description second-level-text">Scale-up anytime</div>
        </div>
      </div>
    </div>
  );
}

export default PulseInfoText;
