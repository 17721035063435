import React from 'react';
import { Form, Input, Select, Button } from 'antd';
import _ from 'lodash';
import { validatePhone, validateStreetAddress } from '../../Utils/FormValidators';
import { locationMenuListWithStateCode } from '../../Utils/LocationUtils';
import './CompanyInfo.scss';

const FormItem = Form.Item;
const { Option } = Select;
class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'US',
      selectedLocation: undefined,
      locationInput: '',
    };
    this.isSaveAddressChangesButtonDisabled = this.isSaveAddressChangesButtonDisabled.bind(this);
    this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
  }

  componentDidMount() {
    const { userDetails, form } = this.props;
    form.setFieldsValue({
      selectCountry: 'US',
    });
    if (!_.isEmpty(userDetails)) {
      this.fetchZipCodeList();
    }
  }

  componentDidUpdate(prevProps) {
    const { userDetails } = this.props;

    if (!_.isEqual(userDetails, prevProps.userDetails) && !!userDetails) {
      this.fetchZipCodeList();
    }
  }

  updateCompanyInfo() {
    const { updateAddress, form } = this.props;
    const { getFieldValue } = form;
    const officePhonenumber = getFieldValue('officePhonenumber');
    const company = getFieldValue('company');
    const zipcode = getFieldValue('selectZipCode');
    const country = getFieldValue('selectCountry');
    const locationArray = getFieldValue('selectLocation').split('@');
    const location = locationArray[locationArray.length - 1].split(',');
    const street = getFieldValue('street').trim();
    const city = (location[0] ?? '').trim();
    const state = (location[1] ?? '').trim();
    const companyInfo = {
      OrgName: company,
      Phone: officePhonenumber,
      Street: street,
      City: city,
      State: state,
      ZipCode: zipcode,
      Country: country,
    };
    updateAddress(companyInfo);
  }

  isSaveAddressChangesButtonDisabled() {
    const { form, userDetails } = this.props;
    const { getFieldValue } = form;
    const { OrgName, Street, City, State, ZipCode, Country } = userDetails;
    const currentLocation = [City, State].join(', ');
    const company = getFieldValue('company');
    const zipcode = getFieldValue('selectZipCode');
    const country = getFieldValue('selectCountry');
    const locationArray = (getFieldValue('selectLocation') ?? '').split('@');
    const updatedLocation = locationArray[locationArray.length - 1];
    const street = getFieldValue('street');

    return (
      !company ||
      !zipcode ||
      !country ||
      !updatedLocation ||
      !street ||
      !street.trim().length ||
      street.trim().length > 60 ||
      (OrgName === company &&
        Street === street &&
        updatedLocation === currentLocation &&
        ZipCode === zipcode &&
        Country === country)
    );
  }

  fetchZipCodeList() {
    const { userDetails, fetchZipCodes } = this.props;
    const { City, State, Country } = userDetails;
    fetchZipCodes({
      country: Country,
      location: [City, State].join(', '),
    });
  }

  handleCountryChange = value => {
    if (!value) {
      const { form } = this.props;
      const { setFieldsValue } = form;
      setFieldsValue({
        selectLocation: undefined,
        selectZipCode: undefined,
      });
    }
  };

  onCountrySelect(value) {
    const { form } = this.props;
    const { setFieldsValue } = form;
    const { selectedCountry } = this.state;
    if (selectedCountry !== value) {
      setFieldsValue({
        selectLocation: undefined,
        selectZipCode: undefined,
      });
    }
    this.setState({
      selectedCountry: value,
    });
  }

  getCountrySelect = () => {
    const countries = [{ name: 'USA', iso2Code: 'US', iso3Code: 'USA' }];
    const countryMenuList = countries.map(country => (
      <Option key={country.iso2Code} value={country.iso2Code} title={country.name}>
        {country.name}
      </Option>
    ));
    return <Select
        key="country"
        style={{ width: '100%' }}
        className="select-dropdown country"
        size="large"
        placeholder="Select Country"
        optionFilterProp="title"
        onChange={value => this.handleCountryChange(value)}
        onSelect={value => this.onCountrySelect(value)}
        showSearch
        allowClear
      >
        {countryMenuList}
      </Select>;
  };

  getLocationDropDownStatus = () => {
    let locationDropDownStatus = 'Type to search';
    const { locationInput } = this.state;
    const { locationApiStatus } = this.props;
    if (locationInput && locationInput.length) {
      locationDropDownStatus = locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
    }
    return locationDropDownStatus;
  };

  getLocationSelect = () => {
    const { utilities, form } = this.props;
    const { getFieldValue } = form;
    const isCountrySelected = getFieldValue('selectCountry');
    const locations = _.get(utilities, 'locations', []);
    const locationDropDownStatus = this.getLocationDropDownStatus();
    let _locationMenuList = null;
    if (locations) {
      _locationMenuList = locationMenuListWithStateCode(locations);
    }
    return (
      <Select
        className="company-info-location"
        size="large"
        key="location"
        showSearch
        placeholder="Enter Location"
        onSearch={value => this.filterLocation(value)}
        onChange={value => this.handleLocationChange(value)}
        onSelect={value => this.getZipcodes(value)}
        disabled={!isCountrySelected}
        showArrow={false}
        allowClear
        notFoundContent={locationDropDownStatus}
      >
        {_locationMenuList?.map(locationOption => (
          <Option key={locationOption} value={locationOption}>
            {locationOption}
          </Option>
        ))}
      </Select>
    );
  };

  handleLocationChange = value => {
    const { form } = this.props;
    const { setFieldsValue } = form;
    if (!value || !value.length) {
      setFieldsValue({
        selectLocation: undefined,
        selectZipCode: undefined,
      });
    }
  };

  filterLocation = value => {
    const { form, fetchLocations } = this.props;
    const { getFieldValue } = form;
    this.setState({ locationInput: value });
    const country = getFieldValue('selectCountry');
    fetchLocations({
      country,
      search: value,
    });
  };

  getZipCodeSelect = () => {
    const { utilities, form } = this.props;
    const { getFieldValue } = form;

    const isCountrySelected = getFieldValue('selectCountry');
    const isLocationSelected = getFieldValue('selectLocation');

    const zipCodes = _.get(utilities, 'zipCodes', []);
    const zipCodeMenuList = zipCodes.map(zipCode => (
      <Option value={zipCode} title={zipCode} key={zipCode}>
        {zipCode}
      </Option>
    ));
    return (
      <Select
        key="zipCode"
        size="large"
        style={{ width: '100%' }}
        placeholder="Select Postal Code"
        optionFilterProp="title"
        className="select-dropdown country"
        disabled={!(isCountrySelected && isLocationSelected)}
        allowClear
        showSearch
      >
        {zipCodeMenuList}
      </Select>
    );
  };

  getZipcodes(value) {
    const { selectedLocation } = this.state;
    const { form, fetchZipCodes } = this.props;
    const { getFieldValue } = form;
    if (!_.isEqual(value, selectedLocation)) {
      form.setFieldsValue({
        selectZipCode: undefined,
      });
    }
    this.setState({
      selectedLocation: value,
    });
    const country = getFieldValue('selectCountry');
    fetchZipCodes({
      country,
      location: value,
    });
  }

  render() {
    const countrySelect = this.getCountrySelect();
    const locationSelect = this.getLocationSelect();
    const zipCodeSelect = this.getZipCodeSelect();
    const { form, userDetails, hideSaveButton, hidePhoneNumber } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <FormItem label="Company" colon={false}>
          {getFieldDecorator('company', {
            initialValue: userDetails.OrgName,
            rules: [
              {
                required: true,
                message: 'Enter Company Name.',
              },
            ],
          })(<Input className="company-info-settings-input" placeholder="Enter Company Name" disabled></Input>)}
        </FormItem>
        {!hidePhoneNumber ? (
          <FormItem label="Phone Number" colon={false}>
            {getFieldDecorator('officePhonenumber', {
              initialValue: userDetails.OfficePhone,
              rules: [
                {
                  required: true,
                  message: 'We need your phone number.',
                },
                {
                  validator: validatePhone,
                },
              ],
            })(<Input className="company-info-settings-input" placeholder="Enter Phone Number" />)}
          </FormItem>
        ) : null}
        <FormItem label="Address" colon={false}>
          {getFieldDecorator('street', {
            initialValue: userDetails.Street,
            rules: [
              {
                required: true,
                validator: validateStreetAddress,
              },
            ],
          })(<Input className="company-info-settings-input" placeholder="( eg. 500 W Peace St )"></Input>)}
        </FormItem>
        <FormItem label="City, State" colon={false}>
          {getFieldDecorator('selectLocation', {
            initialValue:
              userDetails.City && userDetails.State ? [userDetails.City, userDetails.State].join(', ') : undefined,
            rules: [
              {
                required: true,
                message: 'Enter your current location.',
              },
            ],
          })(locationSelect)}
        </FormItem>
        <div className="company-info-zipcode-country">
          <FormItem label="Postal Code" colon={false}>
            {getFieldDecorator('selectZipCode', {
              initialValue: userDetails.ZipCode,
              rules: [
                {
                  required: true,
                  message: 'Enter a valid Postal Code.',
                },
              ],
            })(zipCodeSelect)}
          </FormItem>
          <FormItem label="Country" colon={false}>
            {form.getFieldDecorator('selectCountry', {
              initialValue: userDetails.Country,
              rules: [
                {
                  required: true,
                  message: 'Please select a country.',
                },
              ],
            })(countrySelect)}
          </FormItem>
        </div>
        {!hideSaveButton ? (
          <Button
            type="primary"
            shape="round"
            onClick={this.updateCompanyInfo}
            disabled={this.isSaveAddressChangesButtonDisabled()}
          >
            Save
          </Button>
        ) : null}
      </div>
    );
  }
}

export default Form.create()(CompanyInfo);

export { CompanyInfo as CompanyInfoWithoutForm };
