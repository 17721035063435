import { message } from 'antd';
import * as atsRepository from '../Repository/AtsRepository';

export function setPushCandidateAtsApiStatus(status) {
  return {
    type: 'SET_PUSH_CANDIDATE_ATS_API_STATUS',
    payload: status,
  };
}

export function pullJobs(jobIds, atsName) {
  return dispatch => {
    atsRepository
      .pullJobs(jobIds, atsName)
      .then(() => {
        message.success('Job pull request is being processed');
      })
      .catch(err => {
        message.error('Unable to pull jobs');
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}

export function pushCandidate(jobId, candidateId, portal, atsName) {
  return dispatch => {
    dispatch(setPushCandidateAtsApiStatus('INPROGRESS'));
    atsRepository
      .pushCandidate(jobId, candidateId, portal, atsName)
      .then(() => {
        message.success('Your request is being processed');
        dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
      })
      .catch(err => {
        message.error('Unable to push candidate');
        dispatch(setPushCandidateAtsApiStatus('FAILED'));
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}

export function fetchAtsConfig(atsName) {
  return dispatch => {
    atsRepository
      .fetchAtsConfig(atsName)
      .then(response => {
        dispatch({
          type: 'SET_ATS_CONFIG',
          payload: response.data,
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}
