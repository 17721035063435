import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCareerProjectionList, getCareerAssessmentList } from '../../../Reducers/CareerProjectionReducer';
import { getLocationSearchReducerUtilities } from '../../../Reducers/LocationSearchReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  fetchCountries,
  fetchStateSuggestions,
  fetchCitySuggestions,
  fetchLocations,
  clearLocations,
} from '../../../Actions/LocationSearchActions';
import {
  fetchCareerProjectionList,
  fetchCareerAssessmentList,
  resetCareerProjection,
} from '../../../Actions/CareerProjectionActions';
import GlobalInsightSupplyComponent from '../../../Components/GlobalInsight/GlobalInsightSupplyComponent';

function CareerProjectionContainer() {
  const dispatch = useDispatch();

  const utilities = useSelector(state => getLocationSearchReducerUtilities(state));
  const careerProjectionList = useSelector(state => getCareerProjectionList(state));
  const careerAssessmentList = useSelector(state => getCareerAssessmentList(state));
  const locationApiStatus = useSelector(state => getApiStatus(state, 'locationApiStatus'));
  const statesApiStatus = useSelector(state => getApiStatus(state, 'stateSuggestionsApiStatus'));
  const citiesApiStatus = useSelector(state => getApiStatus(state, 'citySuggestionsApiStatus'));
  const careerProjectionApiStatus = useSelector(state => getApiStatus(state, 'careerProjectionApiStatus'));
  const careerAssessmentApiStatus = useSelector(state => getApiStatus(state, 'careerAssessmentApiStatus'));
  const { countries, stateSuggestions, citySuggestions } = utilities;

  const [isCareerProjectionLandingPage, setIsCareerProjectionLandingPage] = React.useState(true);
  const [careerProjectionFetchPayload, setCareerProjectionFetchPayload] = React.useState({ CountryCode: 'US' });
  const [careerAssessmentFetchPayload, setCareerAssessmentFetchPayload] = React.useState({});

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const fetchCareerProjection = fetchPayload => {
    setIsCareerProjectionLandingPage(false);
    setCareerProjectionFetchPayload(fetchPayload);
    setCareerAssessmentFetchPayload({});
    dispatch(fetchCareerProjectionList(fetchPayload));
  };

  const fetchCareerAssessment = fetchPayload => {
    setCareerAssessmentFetchPayload(fetchPayload);
    dispatch(fetchCareerAssessmentList(fetchPayload));
  };

  const resetPage = () => {
    setIsCareerProjectionLandingPage(true);
    setCareerProjectionFetchPayload({ CountryCode: 'US' });
    setCareerAssessmentFetchPayload({});
    dispatch(resetCareerProjection);
  };

  return (
    <div>
      <GlobalInsightSupplyComponent
        fetchProjection={fetchCareerProjection}
        fetchAssessment={fetchCareerAssessment}
        listCountries={countries}
        listStates={stateSuggestions}
        locations={citySuggestions}
        projectionPayload={careerProjectionFetchPayload}
        assessmentPayload={careerAssessmentFetchPayload}
        fetchLocations={filter => dispatch(fetchLocations(filter))}
        fetchStateSuggestions={filter => dispatch(fetchStateSuggestions(filter))}
        fetchCitySuggestions={filter => dispatch(fetchCitySuggestions(filter))}
        projectionList={careerProjectionList}
        assessmentList={careerAssessmentList}
        locationApiStatus={locationApiStatus}
        projectionApiStatus={careerProjectionApiStatus}
        assessmentApiStatus={careerAssessmentApiStatus}
        citiesApiStatus={citiesApiStatus}
        statesApiStatus={statesApiStatus}
        clearLocations={typeOfLocations => dispatch(clearLocations(typeOfLocations))}
        resetPage={resetPage}
        isGlobalInsightLandingPage={isCareerProjectionLandingPage}
        isCareerProjection
      />
    </div>
  );
}

export default withRouter(CareerProjectionContainer);
export { CareerProjectionContainer as CareerProjectionContainerWithoutRouter };
