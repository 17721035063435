import React from 'react';
import styles from './StatsDetails.module.scss';

export default function StatsDetails(props) {
  const { title, subtitle, children } = props;
  return (
    <div className={styles.statsDetailsWrapper}>
      <div className={styles.statsDetailsHeader}>
        <div className={styles.statsDetailsTitle}>{title}</div>
        <div className={styles.statsDetailsSubtitle}>{subtitle}</div>
      </div>
      {children}
    </div>
  );
}
