import * as d3 from 'd3';
import { getCircleColor } from '../../Utils/CandidateIntelChartUtils';

const defaultConfig = {
  width: 600,
  height: 250,
  margin: { top: 10, right: 10, bottom: 20, left: 30 },
  xAxisTicks: 1,
  yAxisTicks: 1,
  xAxisGridTicks: 7,
  yAxisGridTicks: 5,
  circleFillOpacity: 0.2,
};

export default function renderScatterPlot(config, data) {
  const {
    width,
    height,
    margin,
    xAxisTicks,
    yAxisTicks,
    xAxisGridTicks,
    yAxisGridTicks,
    circleFillOpacity,
  } = defaultConfig;
  const { parentId, tooltipClass, onMouseOver, xAxisDomain, yAxisDomain, idPrefix } = config;

  const INNER_WIDTH = width - margin.left - margin.right;
  const INNER_HEIGHT = height - margin.top - margin.bottom;

  const xScale = d3
    .scaleLinear()
    .domain(xAxisDomain)
    .range([0, INNER_WIDTH]);
  const yScale = d3
    .scaleLinear()
    .domain(yAxisDomain)
    .range([INNER_HEIGHT, 0]);

  const xAxis = d3.axisBottom(xScale).ticks(xAxisTicks);
  const yAxis = d3.axisLeft(yScale).ticks(yAxisTicks);

  const xAxisGrid = d3
    .axisBottom(xScale)
    .tickSize(-INNER_HEIGHT)
    .tickFormat('')
    .ticks(xAxisGridTicks);
  const yAxisGrid = d3
    .axisLeft(yScale)
    .tickSize(-INNER_WIDTH)
    .tickFormat('')
    .ticks(yAxisGridTicks);

  d3.selectAll(`${parentId} svg`).remove();

  const svg = d3
    .select(parentId)
    .append('svg')
    .attr('width', width)
    .attr('height', height);

  const svgGroup = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

  svg.insert('defs').html(
    `<filter id="circle-shadow" x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
    <feGaussianBlur in="SourceAlpha" stdDeviation="1"/>
    <feOffset dx="4" dy="4" result="offsetblur"/>
    <feOffset dx="-4" dy="-4" result="offsetblur"/>
    <feMerge>
      <feMergeNode/>
      <feMergeNode in="SourceGraphic"/>
      <feMergeNode in="SourceGraphic"/>
    </feMerge>
  </filter>`
  );

  svgGroup
    .append('g')
    .attr('class', 'x axis-grid')
    .attr('transform', `translate(0, ${INNER_HEIGHT})`)
    .call(xAxisGrid);

  svgGroup
    .append('g')
    .attr('class', 'y axis-grid')
    .call(yAxisGrid);

  svgGroup
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${INNER_HEIGHT})`)
    .call(xAxis);
  svgGroup
    .append('g')
    .attr('class', 'y axis')
    .call(yAxis);

  const tooltip = d3
    .select(parentId)
    .append('div')
    .attr('class', tooltipClass)
    .style('opacity', 0);

  const circleGroups = svgGroup
    .selectAll('circle')
    .data(data)
    .enter()
    .append('g');

  circleGroups
    .append('circle')
    .attr('id', (_, i) => `${idPrefix}-uc${i}`)
    .attr('cx', d => xScale(d.XCoordinate))
    .attr('cy', d => yScale(d.YCoordinate))
    .attr('r', d => d.Weight * 30 + 5)
    .attr('fill', 'white')
    .attr('visibility', 'hidden')
    .attr('filter', "url('#circle-shadow')");

  circleGroups
    .append('circle')
    .attr('id', (_, i) => `${idPrefix}-c${i}`)
    .attr('cx', d => xScale(d.XCoordinate))
    .attr('cy', d => yScale(d.YCoordinate))
    .attr('r', d => d.Weight * 30)
    .attr('fill', d => getCircleColor(d.Type))
    .attr('fill-opacity', circleFillOpacity)
    .attr('stroke', d => getCircleColor(d.Type))
    .attr('stroke-width', 1)
    .on('mouseover', (d, i) => {
      d3.select(`#${idPrefix}-uc${i}`).attr('visibility', 'visible');
      const offsetX = xScale(d.XCoordinate);
      const offsetY = yScale(d.YCoordinate);
      onMouseOver(tooltip, offsetX, offsetY, d);
    })
    .on('mouseout', (_, i) => {
      d3.select(`#${idPrefix}-uc${i}`).attr('visibility', 'hidden');
      tooltip.style('opacity', 0);
    });
}
