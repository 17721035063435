import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import styles from './WorkflowContainer.module.scss';
import WorkflowListContainer from '../BaseTemplates/WorkflowList/WorkflowListContainer';
import * as UserActions from '../../Actions/UserActions';

const mapDispatchToProps = {
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
};

function WorkflowContainer(props) {
  const { fetchUsersForShare, setUrl, location } = props;

  React.useEffect(() => {
    fetchUsersForShare(1);
  }, []);

  return (
    <div className={styles.workflowContainer}>
      <Row>
        <Col>
          <WorkflowListContainer setUrl={setUrl} location={location} />
        </Col>
      </Row>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(WorkflowContainer);
export { WorkflowContainer as WorkflowContainerWithoutStore };
