import React from 'react';
import { Icon } from 'antd';

const JobDetailSvg = props => {
  return (
    <svg viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path id="prefix__a" d="M0 .012h19.306v22.932H0z" />
        <path id="prefix__c" d="M.01.038h9.756v14.597H.009z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={18.5} cy={18.5} r={18} stroke="#979797" />
        <g transform="translate(9 7)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M18.211.012c.096.04.194.074.287.12.507.244.797.644.8 1.202.01 1.612.006 3.224.004 4.836 0 .3-.197.511-.458.507-.262-.003-.449-.213-.45-.516V1.55c0-.49-.154-.642-.652-.642H1.56c-.497 0-.65.152-.65.643v19.86c0 .489.154.64.654.64h16.182c.495 0 .648-.153.648-.647 0-1.015-.002-2.03.001-3.045.001-.368.294-.592.611-.471.188.071.294.213.294.411.002 1.142.014 2.284-.007 3.426-.012.63-.599 1.175-1.244 1.215-.098.006-.197.005-.296.005H1.571c-.856 0-1.235-.258-1.54-1.045-.005-.012-.02-.02-.032-.03V1.086C.123.776.257.466.557.28.725.174.913.099 1.092.01h17.12z"
            fill="#39B66A"
            mask="url(#prefix__b)"
          />
          <g transform="translate(13 6)">
            <mask id="prefix__d" fill="#fff">
              <use xlinkHref="#prefix__c" />
            </mask>
            <path
              d="M1.242 11.454l-.207 1.799c.53-.376 1.011-.72 1.51-1.075l-1.303-.724zM8.274 3.11c.173-.298.347-.563.485-.844.218-.444.033-.964-.405-1.21-.44-.245-1.003-.143-1.293.258-.189.26-.343.544-.521.831l1.734.965zm-5.056 8.42l4.593-7.657-1.728-.959L1.49 10.57l1.728.96zM9.766 2.16c-.252.494-.476 1.003-.76 1.48-1.699 2.852-3.41 5.698-5.124 8.541-.099.165-.246.317-.404.432-.872.632-1.752 1.254-2.637 1.87a.848.848 0 01-.43.153c-.257.007-.428-.225-.399-.508.066-.631.139-1.262.21-1.892.054-.464.104-.928.17-1.39.021-.148.061-.305.138-.432C2.4 7.285 4.27 4.159 6.15 1.037 6.786-.017 8.048-.273 9.031.44c.371.269.597.633.69 1.072.009.042.03.08.045.121v.526z"
              fill="#39B66A"
              mask="url(#prefix__d)"
            />
          </g>
          <path
            d="M8.303 6.688c0 .417.163.687.477.791a.643.643 0 00.715-.178c.272-.293.25-.654.145-1.001-.076-.25-.276-.404-.545-.434-.483-.054-.792.27-.792.822M7.122 9.484c1.189.898 2.737.803 3.753-.007-.426-.696-1.05-1.073-1.86-1.077-.817-.005-1.454.365-1.893 1.084m3.127-1.699c.52.237.93.596 1.267 1.05.98-1.385.73-3.245-.545-4.26-1.32-1.052-3.155-.91-4.292.337C5.58 6.114 5.7 7.868 6.5 8.825c.32-.462.747-.798 1.257-1.05-.333-.426-.354-.901-.314-1.384.062-.768.657-1.326 1.486-1.41.654-.067 1.358.411 1.55 1.083.17.592.14 1.172-.231 1.72m2.75-.791a4 4 0 11-7.998.015A4 4 0 0113 6.993M8.533 12.002c.989 0 1.978-.007 2.966.007.138.002.317.063.4.173a.607.607 0 01.083.467c-.047.141-.211.238-.336.332-.046.035-.127.011-.192.011h-5.89c-.043 0-.086.002-.13 0-.25-.018-.429-.217-.434-.483-.005-.278.176-.498.437-.504.396-.009.793-.003 1.19-.003h1.906M8.505 15.999c-.993 0-1.985.004-2.978-.005a.64.64 0 01-.361-.117c-.151-.112-.197-.306-.146-.51.05-.2.172-.322.35-.358.057-.012.115-.008.173-.008h5.999c.276.012.462.219.458.508-.004.279-.187.483-.453.488-.345.007-.69.002-1.035.002H8.505M8.511 17.002c.996 0 1.992-.006 2.988.007.138.002.316.063.4.173a.607.607 0 01.084.467c-.048.141-.212.237-.337.332-.046.035-.127.011-.192.011h-5.89c-.043 0-.086.002-.13 0-.25-.018-.429-.216-.434-.483-.005-.278.176-.498.436-.504.404-.009.809-.003 1.213-.003H8.51M10.483 20.999c-.344 0-.688.003-1.032-.001-.276-.003-.455-.209-.451-.506.004-.287.18-.488.444-.489a205.3 205.3 0 012.106 0c.275.001.454.209.45.506-.004.287-.18.485-.444.489-.358.004-.715.001-1.073.001M4 12.504a.496.496 0 01-.493.496.52.52 0 01-.507-.492.52.52 0 01.516-.508c.271.006.486.23.484.504M4 15.492a.496.496 0 01-.48.508.519.519 0 01-.52-.504.52.52 0 01.503-.496.496.496 0 01.497.492M4 17.511a.496.496 0 01-.5.489.52.52 0 01-.5-.5c0-.27.249-.51.523-.5.272.01.482.235.477.511"
            fill="#39B66A"
          />
        </g>
      </g>
    </svg>
  );
};

const JobDetailIcon = props => <Icon component={JobDetailSvg} {...props} />;

export default JobDetailIcon;
