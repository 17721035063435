import React from 'react';
import StatsDetails from '../StatsDetails/StatsDetails';
import LineChart from '../../../../D3Charts/LineChart';

const lineColors = ['red', 'blue', 'green', 'orange', 'grey', 'yellow', 'black'];

export default function LineChartStatsWrapper(props) {
  const {
    setFilterCriteria,
    data,
    activeLines,
    subtitle,
    xScale,
    yScale,
    width,
    height,
    margin,
    yMinMax,
    title,
    id,
    xTickFormat,
  } = props;

  return (
    <StatsDetails title={title} subtitle={subtitle}>
      <div>
        <LineChart
          title={title}
          id={id}
          selectedLinesCount={activeLines}
          width={width}
          height={height}
          xScale={xScale}
          yScale={yScale}
          lineColors={lineColors}
          margin={margin}
          data={data}
          filterLines={setFilterCriteria}
          yMinMax={yMinMax}
          xTickFormat={xTickFormat}
        />
      </div>
    </StatsDetails>
  );
}
