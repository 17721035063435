/* eslint-disable no-undef */

import { attachQuillStyles } from './QuillUtils';

export function parseTextFromHtml(htmlString) {
  const domparser = new DOMParser();
  const document = domparser.parseFromString(htmlString, 'text/html');
  return document.body.textContent;
}

export function checkHtmlForImages(htmlString) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const imgTags = tempDiv.querySelectorAll('img');
  return imgTags?.length > 0;
}

export function parseHtmlStringFromEditor(htmlString, parseFont = true) {
  if (!htmlString) return htmlString;
  let replacedString;
  const regex1 = /<p([^<]*?)?class="ql-indent"(.*?)?<\/p>/g; // for indent
  const regex2 = /<span([^<]*?style=['"][^<]*?color: .*?;)(.*?)(['"][^>]*?)>(.*?)?<\/span>/g; // for font
  const subst1 = `<blockquote$1class="ql-indent"$2</blockquote>`;
  const subst2 = `<div$1$2display:inline $3>$4</div>`;
  replacedString = htmlString.replace(regex1, subst1);
  // if (parseFont) {
  //   replacedString = replacedString.replace(regex2, subst2);
  // }
  return attachQuillStyles(replacedString);
}

export function parseHtmlStringToEditor(htmlString) {
  if (!htmlString) return htmlString;
  const regex1 = /<blockquote([^<]*?)?class="ql-indent"(.*?)?<\/blockquote>/g; // for indent  const subst1 = `<p$1class="ql-indent"$2</p>`;
  const subst1 = `<p$1class="ql-indent"$2</p>`;
  return htmlString.replace(regex1, subst1);
}
