import React from 'react';
import { Group } from '@visx/group';
import { LinkHorizontal } from '@visx/shape';
import { hierarchy, Tree } from '@visx/hierarchy';
import { Tooltip } from 'antd';

export default function TreeGraph(props) {
  const { data, initialNode, onNodeClick } = props;

  const [activeNode, setActiveNode] = React.useState(initialNode);

  React.useEffect(() => {
    onNodeClick(activeNode?.name);
  }, [activeNode]);

  React.useEffect(() => {
    setActiveNode(initialNode);
  }, [initialNode]);

  const getStrokeColor = node => {
    if (node.data.name === activeNode?.name || node.depth === 0) {
      return '#13c26b';
    }
    return '#DFE1E1';
  };
  const getFontColor = node => {
    if (node.depth === 0) {
      return 'white';
    }
    if (node.data.name === activeNode?.name) {
      return '#13c26b';
    }
    return '#07101a';
  };

  const treeSvg = (
    <svg width={500} height={500}>
      <Group top={0} left={0}>
        <Tree root={hierarchy(data, d => d.children)} size={[280, 150]}>
          {tree => (
            <Group top={20} left={180} right={-180}>
              {tree.links().map(link => (
                <LinkHorizontal
                  data={link}
                  percent={0.1}
                  stroke={getStrokeColor(link.target)}
                  strokeWidth="1"
                  fill="none"
                />
              ))}

              {tree.descendants().map(node => {
                const width = 160;
                const height = 20;
                const top = node.x;
                const left = node.depth === 0 ? node.y - 75 : node.y;

                return (
                  <Tooltip key={node.data.name} title={node.data.name}>
                    <Group top={top} left={left} key={node.data.name}>
                      {node.depth === 0 ? (
                        <rect
                          height={height}
                          width={width}
                          y={-height / 2}
                          x={-width / 2}
                          fill={getStrokeColor(node)}
                          rx={12}
                        />
                      ) : (
                        <circle
                          r={5}
                          fill={getStrokeColor(node)}
                          onClick={() => {
                            setActiveNode(node.data);
                          }}
                        />
                      )}
                      <text
                        dy=".33em"
                        fontSize={12}
                        fontFamily="Arial"
                        textAnchor={node.depth === 0 ? 'middle' : 'start'}
                        cursor="pointer"
                        fill={getFontColor(node)}
                        transform={node.depth === 0 ? '' : 'translate(10, 0)'}
                        onClick={() => {
                          if (node.depth !== 0) {
                            setActiveNode(node.data);
                          }
                        }}
                      >
                        {node.data.name.length > 24 ? `${node.data.name.slice(0, 23)}...` : node.data.name}
                      </text>
                    </Group>
                  </Tooltip>
                );
              })}
            </Group>
          )}
        </Tree>
      </Group>
    </svg>
  );

  return <div>{treeSvg}</div>;
}
