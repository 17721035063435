import React from 'react';
import { Button, Row, Col, Progress, Tooltip, Empty, Skeleton } from 'antd';
import HeatMap from '../../D3Charts/HeatMap';
import InsightList from './InsightList';
import styles from './Insight.module.scss';
import { EmptyLocationIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';
import eventTypes from '../../../Analytics/EventTypes';

const defaultTitle = 'Relevant candidate profiles by location';
const defaultDescription =
  'Refer to the candidate distribution statistics, given by country, state and city based on available data';

export default function LocationsInsightView(props) {
  const {
    listOfMapStates,
    listOfMapCities,
    supplyPayload,
    loading,
    readonlyStats,
    customTitle,
    customDescription,
    emptyInsightDescription,
  } = props;
  const [heatMapListView, setHeatMapListView] = React.useState(false);
  React.useEffect(() => {
    if (heatMapListView) {
      setHeatMapListView(false);
    }
  }, [loading]);
  const locationInsightTitle = <div className={styles.headerTitle}>{customTitle || defaultTitle}</div>;
  const locationInsightDescription = (
    <div className={styles.headerDescription}>{customDescription || defaultDescription}</div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.location.id}>
      {heatMapListView ? (
        <>
          <div className={styles.headerSupplyInfo} id="LocationListHeader">
            <div className={styles.headerInfo}>
              {locationInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  onClick={() => setHeatMapListView(false)}
                  sk-event-name={eventTypes.job.marketIntel.hideLocationList}
                >
                  X
                </Button>
              </div>
            </div>
            {locationInsightDescription}
          </div>
          <div id="LocationList">
            <div className={styles.listTitle}> {supplyPayload.State ? 'Cities' : 'States'}</div>
            <InsightList
              insightList={supplyPayload.State ? listOfMapCities : listOfMapStates}
              loading={loading}
              itemSpan={11}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerSupplyInfo} id="LocationListheatMap">
            <div className={styles.headerInfo}>
              {locationInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  disabled={(supplyPayload.State ? listOfMapCities : listOfMapStates).length < 1}
                  onClick={() => setHeatMapListView(true)}
                  sk-event-name={eventTypes.job.marketIntel.displayLocationList}
                >
                  See list{' '}
                </Button>
              </div>
            </div>
            {locationInsightDescription}
          </div>
          <div>
            <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
              <Row>
                {(supplyPayload.State ? listOfMapCities : listOfMapStates).length > 0 || supplyPayload.City ? (
                  <>
                    {!supplyPayload.City ? (
                      <div className={styles.listTitle}>Top 5 {supplyPayload.State ? 'Cities' : 'States'}</div>
                    ) : null}
                    <Col span={10}>
                      <div>
                        {(supplyPayload.State ? listOfMapCities.slice(0, 5) : listOfMapStates.slice(0, 5)).map(item => (
                          <div key={item.DisplayName}>
                            {!supplyPayload.City ? (
                              <div className={styles.supplyTopListView}>
                                <div className={styles.supplyName}>
                                  <Tooltip title={item.DisplayName}>{item.DisplayName}</Tooltip>
                                </div>
                                <Progress percent={item.SupplyPercentage} size="small" />
                              </div>
                            ) : (
                              <div className={styles.supplyTopListView}>
                                <Tooltip title={item.DisplayName}>
                                  <div className={styles.supplyName}>{`${item.DisplayName} (${item.Count})`}</div>
                                </Tooltip>
                                <Progress percent={item.SupplyPercentage} size="small" />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col span={14}>
                      {supplyPayload.CountryCode === 'US' && !supplyPayload.City ? (
                        <div id="HeatMapView">
                          <HeatMap list={listOfMapStates} width={500} height={400} fillColor="#13C26B" />
                        </div>
                      ) : (
                        <Empty
                          image={<EmptyLocationIcon className={styles.emptyIcon} />}
                          description={<div>No Maps</div>}
                        />
                      )}
                    </Col>
                  </>
                ) : (
                  <div>
                    <Empty
                      image={<EmptyLocationIcon className={styles.emptyIcon} />}
                      description={
                        <div>
                          <h3>No location data found</h3>
                          {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                        </div>
                      }
                    />
                  </div>
                )}
              </Row>
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
