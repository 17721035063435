/* eslint-disable react/no-danger */
import { Modal } from 'antd';
import React from 'react';
import styles from './JobExternalPreviewModal.module.scss';

export default function JobExternalPreviewModal({ jobPreviewModalVisibility, previewUrl, onClosePreviewModal }) {
  const iFrame = `<iframe src=${previewUrl} width="100%" height="100%"></iframe>`;

  return (
    <Modal
      onCancel={onClosePreviewModal}
      visible={jobPreviewModalVisibility}
      footer={null}
      width="1280px"
      bodyStyle={{ padding: '56px' }}
    >
      <div className={styles.previewDiv} dangerouslySetInnerHTML={{ __html: iFrame }} />
    </Modal>
  );
}
