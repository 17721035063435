import React, { Component } from 'react';
import { Button } from 'antd';
import CreditCardDetails from './CreditCardDetails';
import CreditCardForm from './CreditCardForm';
import ModalCardForm from './ModalCardForm';
import './PaymentSettings.scss';

export default class PaymentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardFormDisplay: false,
    };
  }

  toggleCreditCardForm = () => {
    const { creditCardFormDisplay } = this.state;
    this.setState({ creditCardFormDisplay: !creditCardFormDisplay });
  };

  handleCancel = () => {
    const { resetSavePaymentDetailApiStatus } = this.props;
    this.setState({ creditCardFormDisplay: false });
    resetSavePaymentDetailApiStatus();
  };

  render() {
    const { creditCardFormDisplay } = this.state;
    const {
      form,
      selectCard,
      paymentProfileId,
      savePaymentProfileApiStatus,
      paymentProfile = [],
      savePaymentDetails,
      fetchPaymentProfile,
      showDeletePaymentCardModal,
    } = this.props;
    return (
      <div className="payment-setting-wrapper">
        {paymentProfile.length ? (
          <div>
            <div className="credit-card-collection">
              {paymentProfile.map(profile => (
                <div className="payment-info-credit-card" key={profile.ProfileId}>
                  <CreditCardDetails
                    CardDetail={profile.CardDetail}
                    selectCard={() => selectCard(profile.ProfileId)}
                    select={paymentProfileId === profile.ProfileId}
                    ProfileId={profile.ProfileId}
                    showDeletePaymentCardModal={showDeletePaymentCardModal}
                  />
                </div>
              ))}
            </div>
            <Button type="primary" shape="round" onClick={this.toggleCreditCardForm}>
              Add Payment Method
            </Button>
          </div>
        ) : (
          <div className="payment-creditcard-form">
            <CreditCardForm form={form} creditCardFormDisplay />
          </div>
        )}
        <ModalCardForm
          creditCardFormDisplay={creditCardFormDisplay}
          savePaymentProfileApiStatus={savePaymentProfileApiStatus}
          handleCancel={this.handleCancel}
          savePaymentDetails={savePaymentDetails}
          fetchPaymentProfile={fetchPaymentProfile}
          showNotification
        />
      </div>
    );
  }
}
