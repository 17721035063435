import React from 'react';
import ChatBubbleBlockIcon from '../../Icons/ChatBubbleBlockIcon';
import styles from './CandidateOptOutWidget.module.scss';

export default function CandidateOptOutWidget({ candidateOptOutType }) {
  return (
    <div className={styles.optOutWidgetWrapper}>
      <div className={styles.optOutIconWrapper}>
        <ChatBubbleBlockIcon className={styles.optOutIcon} /> <p>Opted-out</p>
      </div>
      <div className={styles.optOutReasonContent}>Candidate Opted-Out: {candidateOptOutType}</div>
    </div>
  );
}
