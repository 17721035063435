import React from 'react';
import { Form, InputNumber, Switch, Select, Checkbox, Tooltip, Icon } from 'antd';
import NaukriOptions from '../../../../Data/AdvancedOptions/Naukri/NaukriOptions.json';
import '../AdvancedOptions.scss';
import './NaukriAdvancedOptions.scss';
import { formValuesMapper } from '../../../../Utils/MapperUtils';
import { getDefaultSwitchValue } from '../../QuickSearchFormUtils';

const { Item } = Form;
const { Option } = Select;

function NaukriAdvancedOptions(props) {
  const { form, defaultSettings } = props;
  return (
    <div className="quicksearch-advanced-options naukri-advanced-options">
      <Item>
        <div className="salary-form-item-label">Salary Range (In Lakhs)</div>
        <div className="salary-form-item">
          <Item>
            {form.getFieldDecorator('Naukri_MinSalary', { initialValue: defaultSettings.MinSalary })(
              <InputNumber placeholder="0 Lacs" className="naukri-minimum-salary" min={0} />
            )}
          </Item>
          <Item>
            {form.getFieldDecorator('Naukri_MaxSalary', { initialValue: defaultSettings.MaxSalary })(
              <InputNumber
                placeholder="100 Lacs"
                className="naukri-maximum-salary"
                min={form.getFieldValue('Naukri_MinSalary') || 0}
              />
            )}
          </Item>
        </div>
        <div>
          <Item label="Include resumes with no salary info" colon={false} className="advanced-options-switch-component">
            {form.getFieldDecorator('Naukri_IncludeResumesWithNoSalaryInfo', {
              initialValue: getDefaultSwitchValue(defaultSettings.IncludeResumesWithNoSalaryInfo, false),
              valuePropName: 'checked',
            })(<Switch />)}
          </Item>
        </div>

        <Item label="Notice Period" colon={false}>
          {form.getFieldDecorator('Naukri_NoticePeriod', {
            initialValue: defaultSettings.NoticePeriod?.split(','),
          })(
            <Select
              mode="multiple"
              placeholder="Select Notice Period"
              className="advanced-options-select-component"
              dropdownClassName="popover-select"
              optionFilterProp="title"
              showArrow
            >
              {formValuesMapper(NaukriOptions.NoticePeriod).map(option => (
                <Option key={option.value} value={option.value} title={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item label="Last Active In" colon={false}>
          {form.getFieldDecorator('Naukri_LastActiveInDays', {
            initialValue: defaultSettings.LastActiveInDays
              ? defaultSettings.LastActiveInDays
              : NaukriOptions.LastActiveInDays['6 months'],
          })(
            <Select
              placeholder="Select Last Active"
              className="advanced-options-select-component"
              dropdownClassName="popover-select"
              showArrow
            >
              {formValuesMapper(NaukriOptions.LastActiveInDays).map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item>
          {form.getFieldDecorator('Naukri_ExcludePreferredLocation', {
            initialValue:
              defaultSettings.ExcludePreferredLocation === 'false' || !defaultSettings.ExcludePreferredLocation,
            valuePropName: 'checked',
          })(
            <Checkbox className="naukri-checkbox">
              Include candidates who prefer to relocate to above locations.
            </Checkbox>
          )}
        </Item>
        {form.getFieldValue('Naukri_ExcludePreferredLocation') ? (
          <Item>
            {form.getFieldDecorator('Naukri_ExcludeAnywhereCandidates', {
              initialValue: defaultSettings.ExcludeAnywhereCandidates === 'true',
              valuePropName: 'checked',
            })(
              <Checkbox className="naukri-checkbox">
                Exclude candidates who have mentioned Anywhere in location.
                <Tooltip
                  title='Only show candidates who are exactly willing to relocate to selected locations.Exclude those candidates who have preferred to relocate "Anywhere".'
                  overlayStyle={{ maxWidth: '320px' }}
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </Checkbox>
            )}
          </Item>
        ) : null}
      </Item>
    </div>
  );
}

export default NaukriAdvancedOptions;
