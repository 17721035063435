import { apiV3Client } from './BaseRepository';

function fetchRegionsByCountryCode({ countryCode }) {
  return apiV3Client.get(`/countries/${countryCode}/regions`);
}

function fetchRegions() {
  return apiV3Client.get(`Regions`);
}

function fetchRegion({ id }) {
  return apiV3Client.get(`Regions/${id}`);
}

function addRegion({ regionCreationPayload }) {
  return apiV3Client.post('Regions', regionCreationPayload);
}

function deleteRegion({ id }) {
  return apiV3Client.delete(`Regions/${id}`);
}

export { fetchRegionsByCountryCode, fetchRegions, fetchRegion, addRegion, deleteRegion };
