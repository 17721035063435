import React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import CandidateSegmentsAndCampaignsWrapper from '../../Components/CandidateSegmentsAndCampaigns/CandidateSegmentsAndCampaignsWrapper';
import * as CandidateActions from '../../Actions/CandidateActions';
import {
  getCandidateListsAndCampaigns,
  getListsAndCampaignsAggregations,
  getCandidateListsAndCampaignsTotalCount,
} from '../../Reducers/CandidateReducer';
import { getJobAutomationConfiguration } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as JobActions from '../../Actions/JobActions';

const mapsDispatchToProps = {
  fetchCandidateSegmentsAndCampaigns: CandidateActions.fetchCandidateSegmentsAndCampaigns,
  appendCandidateSegmentsAndCampaigns: CandidateActions.appendCandidateSegmentsAndCampaigns,
  fetchJobAutomationConfiguration: JobActions.fetchJobAutomationConfiguration,
};
const mapStateToProps = state => ({
  candidateListsAndCampaigns: getCandidateListsAndCampaigns(state),
  fetchCandidateListsAndCampaignsApistatus: getApiStatus(state, 'fetchCandidateListsAndCampaignsApistatus'),
  listsAndCampaignsAggregation: getListsAndCampaignsAggregations(state),
  candidateListsAndCampaignsTotalCount: getCandidateListsAndCampaignsTotalCount(state),
  associatedTemplateName: getJobAutomationConfiguration(state),
});

function CandidateSegmentsAndCampaignsContainer(props) {
  const {
    fetchCandidateSegmentsAndCampaigns,
    candidateListsAndCampaigns,
    candidate,
    fetchCandidateListsAndCampaignsApistatus,
    candidateListsAndCampaignsTotalCount,
    listsAndCampaignsAggregation,
    appendCandidateSegmentsAndCampaigns,
    fetchJobAutomationConfiguration,
    associatedTemplateName,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
    openSegmentAtsView,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedCreatedByFilterOptions, setSelectedCreatedByFilterOptions] = React.useState([]);
  const segments = candidateListsAndCampaigns;

  const onSearchChange = event => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!value) {
      fetchCandidateSegmentsAndCampaigns({ candidateId: candidate.Id, searchTerm: value });
    }
  };

  const onSearch = value => {
    if (searchTerm.trim().length && value.length)
      fetchCandidateSegmentsAndCampaigns({ candidateId: candidate.Id, searchTerm: value });
  };

  React.useEffect(() => {
    fetchCandidateSegmentsAndCampaigns({ candidateId: candidate.Id });
  }, []);

  if (fetchCandidateListsAndCampaignsApistatus === 'INPROGRESS')
    return <Skeleton paragraph={{ rows: 10 }} loading active />;
  return (
    <div>
      <CandidateSegmentsAndCampaignsWrapper
        segments={segments}
        candidateId={candidate.Id}
        fetchCandidateSegmentsAndCampaigns={fetchCandidateSegmentsAndCampaigns}
        onSearchChange={onSearchChange}
        onSearch={onSearch}
        searchTerm={searchTerm}
        segmentAggregation={listsAndCampaignsAggregation}
        selectedCreatedByFilterOptions={selectedCreatedByFilterOptions}
        setSelectedCreatedByFilterOptions={setSelectedCreatedByFilterOptions}
        candidate={candidate}
        appendCandidateSegmentsAndCampaigns={appendCandidateSegmentsAndCampaigns}
        segmentsTotalCount={candidateListsAndCampaignsTotalCount}
        fetchJobAutomationConfiguration={fetchJobAutomationConfiguration}
        associatedTemplateName={associatedTemplateName}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
        version={version}
        openSegmentAtsView={openSegmentAtsView}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapsDispatchToProps)(CandidateSegmentsAndCampaignsContainer);
export { CandidateSegmentsAndCampaignsContainer as CandidateSegmentsAndCampaignsContainerWithoutStore };
