import React from 'react';
import { Badge } from 'antd';
import styles from './BasicSystemNotification.module.scss';

export default function BasicSystemNotification(props) {
  const { title, subTitle, isViewed, publishedTime, content, onClick } = props;
  return (
    <div
      className={styles.basicSystemNotificationWrapper}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <Badge dot={isViewed} offset={[-15, 12]}>
        <h4>{title}</h4>
        <p className={styles.notificationSubTitle}>{subTitle}</p>
        <p>{content}</p>
        <p className={styles.since}>{publishedTime}</p>
      </Badge>
    </div>
  );
}
