import React from 'react';
import _ from 'lodash';
import FormPanelItem from '../FormPanelItem/FormPanelItem';
import './FormPanelComponent.scss';

export default function FormPanelComponent(props) {
  const { form, groupedFilterKeys, values, fieldDecoratorKey, labelDisplayNames, defaultSettings } = props;
  return (
    <>
      {Object.keys(groupedFilterKeys)
        .filter(filterKey => !_.isEmpty(groupedFilterKeys[filterKey]))
        .map(filterKey => (
          <React.Fragment key={`${fieldDecoratorKey}_${filterKey}`}>
            <div className="form-panel-header">{filterKey}</div>
            {Object.keys(groupedFilterKeys[filterKey]).map(subFilter => (
              <FormPanelItem
                key={`${fieldDecoratorKey}_${subFilter}`}
                label={labelDisplayNames[subFilter]}
                inputValues={values?.[subFilter]}
                form={form}
                fieldDecoratorValue={`${fieldDecoratorKey}_${subFilter}`}
                initialValue={defaultSettings?.[subFilter]}
              />
            ))}
          </React.Fragment>
        ))}
    </>
  );
}
