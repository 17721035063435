import React from 'react';
import { Button, Row, Col, Progress, Tooltip, Empty, Skeleton } from 'antd';
import BarChart from '../../D3Charts/BarChart';
import InsightList from './InsightList';
import styles from './Insight.module.scss';
import { EmptyCompaniesIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';
import eventTypes from '../../../Analytics/EventTypes';

export function getTooltip(totalCount) {
  return count => {
    const percentage = ((count / totalCount) * 100).toFixed(2);
    return `${percentage}% (${count} candidates)`;
  };
}

export default function CompanyInsightView(props) {
  const { listOfCompaniesStats, loading, readonlyStats, totalCount, emptyInsightDescription } = props;
  const [companyListView, setCompanyListView] = React.useState(false);
  const tooltipCallback = getTooltip(totalCount);

  React.useEffect(() => {
    if (companyListView) {
      setCompanyListView(false);
    }
  }, [loading]);
  const companyInsightTitle = <div className={styles.headerTitle}>Company</div>;
  const companyInsightDescription = (
    <div className={styles.headerDescription}>Major companies that hire similar candidates</div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.companies.id}>
      {companyListView ? (
        <>
          <div className={styles.headerSupplyInfo} id="IndustryListHeader">
            <div className={styles.headerInfo}>
              {companyInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  onClick={() => setCompanyListView(false)}
                  sk-event-name={eventTypes.job.marketIntel.hideCompanyList}
                >
                  {' '}
                  X{' '}
                </Button>
              </div>
            </div>
            {companyInsightDescription}
          </div>
          <div id="CompaniesList">
            <InsightList insightList={listOfCompaniesStats} loading={loading} itemSpan={11} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerSupplyInfo} id="CompaniesChat">
            <div className={styles.headerInfo}>
              {companyInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  disabled={listOfCompaniesStats.length < 1}
                  onClick={() => setCompanyListView(true)}
                  sk-event-name={eventTypes.job.marketIntel.displayCompanyList}
                >
                  See list
                </Button>
              </div>
            </div>
            {companyInsightDescription}
          </div>
          <div>
            <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
              <Row>
                {listOfCompaniesStats.length > 0 ? (
                  <>
                    <Col span={10}>
                      <div className={styles.listTitle}>Top 5 Companies</div>
                      {listOfCompaniesStats.slice(0, 5).map(item => (
                        <div className={styles.supplyTopListView} key={item.DisplayName}>
                          <div className={styles.supplyName}>
                            <Tooltip title={item.DisplayName}>{item.DisplayName}</Tooltip>
                          </div>
                          <Progress percent={item.SupplyPercentage} size="small" />
                        </div>
                      ))}
                    </Col>
                    <Col className={styles.BarChartContainer} span={12}>
                      <div className={styles.BarChartView} id="BarChartView">
                        <BarChart
                          list={listOfCompaniesStats.slice(0, 5)}
                          width={400}
                          height={320}
                          tooltipCallback={tooltipCallback}
                          xLabelPadding={25}
                          labelTextAnchor="end"
                          margin={80}
                        />
                      </div>
                    </Col>
                  </>
                ) : (
                  <div>
                    <Empty
                      image={<EmptyCompaniesIcon className={styles.emptyIcon} />}
                      description={
                        <div>
                          <h3>No companies data found</h3>
                          {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                        </div>
                      }
                    />
                  </div>
                )}
              </Row>
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
