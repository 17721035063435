import { defineMessages } from 'react-intl';

const messages = defineMessages({
  publication: {
    id: 'ResearchCard.publication',
    defaultMessage: 'Publication',
  },
  citations: {
    id: 'ResearchCard.citations',
    defaultMessage: 'Citations',
  },
  hIndex: {
    id: 'ResearchCard.hindex',
    defaultMessage: 'H-index',
  },
  i10Index: {
    id: 'ResearchCard.i10index',
    defaultMessage: 'i-10index',
  },
  confidence: {
    id: 'ResearchCard.confidence',
    defaultMessage: 'Confidence',
  },
  goToProfile: {
    id: 'ResearchCard.gotoprofile',
    defaultMessage: 'Go To Profile',
  },
});

export default messages;
