import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { getGlobalInsightSupplyList } from '../../../Reducers/SalesDashBoardReducer';
import { getLocationSearchReducerUtilities } from '../../../Reducers/LocationSearchReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import GlobalInsightSupplyComponent from '../../../Components/GlobalInsight/GlobalInsightSupplyComponent';
import { fetchGlobalInsightSupplyList, resetGlobalInsight } from '../../../Actions/SalesDashBoardActions';
import {
  fetchCountries,
  fetchStateSuggestions,
  fetchCitySuggestions,
  fetchLocations,
  clearLocations,
} from '../../../Actions/LocationSearchActions';

function GlobalInsightContainer(props) {
  const { prepopulatedJobDetails } = props;
  const dispatch = useDispatch();

  const utilities = useSelector(state => getLocationSearchReducerUtilities(state));
  const globalInsightSupplyList = useSelector(state => getGlobalInsightSupplyList(state));
  const locationApiStatus = useSelector(state => getApiStatus(state, 'locationApiStatus'));
  const statesApiStatus = useSelector(state => getApiStatus(state, 'stateSuggestionsApiStatus'));
  const citiesApiStatus = useSelector(state => getApiStatus(state, 'citySuggestionsApiStatus'));
  const globalSupplyApiStatus = useSelector(state => getApiStatus(state, 'globalSupplyApiStatus'));
  const { countries, stateSuggestions, citySuggestions } = utilities;
  const defaultExperinceRanges = [
    {
      To: 2,
    },
    {
      From: 2,
      To: 5,
    },
    {
      From: 5,
      To: 11,
    },
    {
      From: 11,
      To: 15,
    },
    {
      From: 15,
    },
  ];

  const [isGlobalInsightLandingPage, setIsGlobalInsightLandingPage] = React.useState(true);
  const [globalInsightFetchPayload, setGlobalInsightFetchPayload] = React.useState({ CountryCode: 'US', Distance: 50 });

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const fetchGlobalInsightSupply = fetchPayload => {
    const newFetchPayload = {
      ...fetchPayload,
      Experiences: fetchPayload.Experiences?.length > 0 ? fetchPayload.Experiences : defaultExperinceRanges,
      Skills: _.union(fetchPayload.Skills, fetchPayload.searchSkills),
      Industries: _.union(fetchPayload.Industries, fetchPayload.searchIndustries),
      Companies: _.union(fetchPayload.Companies, fetchPayload.searchCompanies),
    };
    setIsGlobalInsightLandingPage(false);
    setGlobalInsightFetchPayload(newFetchPayload);
    dispatch(fetchGlobalInsightSupplyList(newFetchPayload));
  };

  const resetPage = () => {
    setIsGlobalInsightLandingPage(true);
    setGlobalInsightFetchPayload({ CountryCode: 'US', Distance: 50 });
    dispatch(resetGlobalInsight);
  };

  return (
    <div>
      <GlobalInsightSupplyComponent
        fetchGlobalInsightSupply={fetchGlobalInsightSupply}
        listCountries={countries}
        listStates={stateSuggestions}
        supplyPayload={globalInsightFetchPayload}
        locations={citySuggestions}
        fetchLocations={filter => dispatch(fetchLocations(filter))}
        fetchStateSuggestions={filter => dispatch(fetchStateSuggestions(filter))}
        fetchCitySuggestions={filter => dispatch(fetchCitySuggestions(filter))}
        globalInsightSupplyList={globalInsightSupplyList}
        locationApiStatus={locationApiStatus}
        globalSupplyApiStatus={globalSupplyApiStatus}
        clearLocations={typeOfLocations => dispatch(clearLocations(typeOfLocations))}
        citiesApiStatus={citiesApiStatus}
        statesApiStatus={statesApiStatus}
        resetPage={resetPage}
        isGlobalInsightLandingPage={isGlobalInsightLandingPage}
        prepopulatedJobDetails={prepopulatedJobDetails}
        isCandidateSupply
      />
    </div>
  );
}

export default withRouter(GlobalInsightContainer);
export { GlobalInsightContainer as GlobalInsightContainerWithoutRouter };
