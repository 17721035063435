import React from 'react';
import { Icon } from 'antd';
import styles from './AddEmailConfiguration.module.scss';

export default function AddEmailConfiguration(props) {
  const { onAddNewEmailConfiguration, emailSmtpConfiguration } = props;
  const { IsMultipleSenderAddressConfigurationAllowed } = emailSmtpConfiguration;
  return (
    <div>
      {IsMultipleSenderAddressConfigurationAllowed ? (
        <div
          role="button"
          tabIndex={0}
          className={styles.addEmailAddressWrapper}
          onClick={onAddNewEmailConfiguration}
          onKeyPress={onAddNewEmailConfiguration}
        >
          <Icon type="plus" className={styles.addEmailAddressIcon} />
          Add New Email Address
        </div>
      ) : null}
    </div>
  );
}

export { AddEmailConfiguration as AddEmailConfigurationWithoutStore };
