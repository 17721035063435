import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Tooltip } from 'antd';
import SearchCriteriaSelect from '../../ManualSearchV2/HeaderOptions/SearchCriteriaSelect/SearchCriteriaSelect';
import SearchCriteriaSelectWithAgent from '../../ManualSearchV2/HeaderOptions/SearchCriteriaSelect/SearchCriteriaSelectWithAgent';
import { getManualSearchCriteria, getManualSearchCriteriaIdentifiers } from '../../../Reducers/ManualSearchReducer';
import { getDefaultFormValuesFromAryaGeneratedCriteria } from '../../../Utils/ManualSearchUtils';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import { getJobDetails } from '../../../Reducers/JobReducer';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import styles from './ManualSearchFormHeaderOptions.module.scss';
import eventTypes from '../../../Analytics/EventTypes';

const mapStateToProps = (state, props) => ({
  criteriaIdentifiers: getManualSearchCriteriaIdentifiers(state, { jobId: props.jobId, criteriaType: 'Saved' }),
  aryaGeneratedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'AryaGenerated' }),
  currentJobDetails: getJobDetails(state, props.jobId),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  fetchManualSearchCriteriaIdentifiersApiStatus: getApiStatus(state, 'fetchManualSearchCriteriaIdentifiersApiStatus'),
});

const mapDispatchToProps = {
  fetchManualSearchCriteriaIdentifiers: manualSearchActions.fetchManualSearchCriteriaIdentifiers,
  clearManualSearchCriteriaIdentifiers: manualSearchActions.clearManualSearchCriteriaIdentifiers,
  fetchManualSearchCriteriaById: manualSearchActions.fetchManualSearchCriteriaById,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  setManualSearchFormErrorNotification: manualSearchActions.setManualSearchFormErrorNotification,
  setSelectedCriteriaName: manualSearchActions.setSelectedCriteriaName,
  setSelectedCriteriaType: manualSearchActions.setSelectedCriteriaType,
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
};

function ManualSearchFormHeaderOptions(props) {
  const {
    criteriaIdentifiers,
    form,
    setDefaultFormValues,
    aryaGeneratedCriteria,
    currentJobDetails,
    fetchManualSearchCriteriaIdentifiersApiStatus,
    jobId,
    fetchManualSearchCriteriaIdentifiers,
    clearManualSearchCriteriaIdentifiers,
    setSelectedCriteriaName,
    currentUserDetails,
    setAdvancedSettings,
    setIsReactivationAllowedFlag,
    AppName,
    candidateContext,
    setSelectedCriteriaType,
    onSelect,
    featureToggleList,
    scoutingAgentConfig,
  } = props;

  const handleResetButtonClick = async () => {
    const defaultFormValues = await getDefaultFormValuesFromAryaGeneratedCriteria({
      aryaGeneratedCriteria,
      currentJobDetails,
      currentUserDetails,
      candidateContext,
    });
    setDefaultFormValues(defaultFormValues);
    setAdvancedSettings({});
    form.resetFields();
    setSelectedCriteriaName({ jobId, criteriaName: undefined });
    setSelectedCriteriaType({ jobId, selectedCriteriaType: undefined });
    setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
  };
  const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false) && scoutingAgentConfig;
  return (
    <div className={styles.manualSearchManualSearchFormHeaderOptions}>
      {isScoutingAgentEnabled ? (
        <>
          <SearchCriteriaSelectWithAgent
            jobId={jobId}
            onSelect={onSelect}
            criteriaIdentifiers={criteriaIdentifiers}
            fetchManualSearchCriteriaIdentifiersApiStatus={fetchManualSearchCriteriaIdentifiersApiStatus}
            fetchManualSearchCriteriaIdentifiers={fetchManualSearchCriteriaIdentifiers}
            clearManualSearchCriteriaIdentifiers={clearManualSearchCriteriaIdentifiers}
          />
          <Tooltip title={`This will take you back to the initial ${AppName} criteria`}>
            <div
              className={styles.resetButton}
              role="button"
              tabIndex={0}
              onKeyPress={handleResetButtonClick}
              onClick={handleResetButtonClick}
              sk-event-name={eventTypes.job.manualSearch.resetManualSearchCriteria}
            >
              Reset
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <SearchCriteriaSelect
            jobId={jobId}
            onSelect={onSelect}
            criteriaIdentifiers={criteriaIdentifiers}
            fetchManualSearchCriteriaIdentifiersApiStatus={fetchManualSearchCriteriaIdentifiersApiStatus}
            fetchManualSearchCriteriaIdentifiers={fetchManualSearchCriteriaIdentifiers}
            clearManualSearchCriteriaIdentifiers={clearManualSearchCriteriaIdentifiers}
          />
          <Tooltip title={`This will take you back to the initial ${AppName} criteria`}>
            <div
              className={styles.resetButton}
              role="button"
              tabIndex={0}
              onKeyPress={handleResetButtonClick}
              onClick={handleResetButtonClick}
              sk-event-name={eventTypes.job.manualSearch.resetManualSearchCriteria}
            >
              Reset
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSearchFormHeaderOptions);
export { ManualSearchFormHeaderOptions as ManualSearchFormHeaderOptionsWithoutStore };
