import React, { useCallback } from 'react';
import { Popover, Button, Tooltip } from 'antd';
import { FILTER_BADGE } from '../../Utils/JobUtils';
import { FilterIcon as Filter } from '../../Icons/AryaIcons';
import FilterIconWithTooltip from '../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import './FilterPopoverComponent.scss';

const FilterPopoverComponent = props => {
  const {
    content,
    onApply,
    popoverTitle,
    filterButtonTitle,
    onClearPayload,
    onClear,
    isFilterApplied,
    isApplyFilterButtonDisabled,
    buttonShape,
    buttonStyle,
    filterType,
  } = props;
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  const onFilterApplied = useCallback(() => {
    setPopoverVisibility(false);
    onApply();
  }, [onApply]);
  const onFilterCleared = useCallback(() => {
    setPopoverVisibility(false);
    onClear(onClearPayload);
  }, [onClear]);

  const getFilter = type => {
    if (type === FILTER_BADGE) {
      return (
        <FilterIconWithTooltip
          title="Filters"
          isFilterApplied={isFilterApplied}
          style={{ fontSize: 20, marginTop: 5 }}
        />
      );
    }

    return (
      <Button
        shape={buttonShape}
        style={buttonStyle}
        className={`${isFilterApplied ? 'filter-popover-applied' : null}`}
      >
        <Filter alt="Filter" className={`${isFilterApplied ? 'filter-applied-icon' : null}`} />
        {filterButtonTitle}
      </Button>
    );
  };
  return (
    <Popover
      overlayClassName="filter-popover-component-overlay"
      overlayStyle={{ zIndex: 2223 }}
      content={content}
      placement="bottomRight"
      trigger="click"
      autoAdjustOverflow={false}
      visible={popoverVisibility}
      onVisibleChange={setPopoverVisibility}
      title={
        <div className="filter-popover-component-title-wrapper">
          <div className="filter-popover-component-title">{popoverTitle}</div>
          <div className="filter-popover-component-title-buttons-wrapper">
            <Button shape="round" onClick={onFilterCleared}>
              Clear
            </Button>
            <Button type="primary" shape="round" onClick={onFilterApplied} disabled={isApplyFilterButtonDisabled}>
              Apply
            </Button>
          </div>
        </div>
      }
    >
      <Tooltip title={filterButtonTitle}>{getFilter(filterType)}</Tooltip>
    </Popover>
  );
};

export default FilterPopoverComponent;
