import React from 'react';
import styles from './EducationSection.module.scss';
import EducationWrapper from '../../../../CompoundComponents/EducationWrapper/EducationWrapper';
import Degree from '../../../../CompoundComponents/EducationWrapper/Degree';
import School from '../../../../CompoundComponents/EducationWrapper/School';
import Specialization from '../../../../CompoundComponents/EducationWrapper/Specialization';
import Certification from '../../../../CompoundComponents/EducationWrapper/Certification';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import eventTypes from '../../../../Analytics/EventTypes';

export default function EducationSection(props) {
  const { form, defaultFormValues } = props;
  const onClear = () => {
    form.setFieldsValue({
      Degree: undefined,
      School: undefined,
      Specialization: undefined,
      Certification: undefined,
    });
  };
  return (
    <div className={styles.educationSection}>
      <div className={styles.educationSectionClearAll}>
        <div className={styles.educationSectionLabelWrapper}>Education</div>
        <div className={styles.clearAll}>
          <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearEducationFilter} />
        </div>
      </div>
      <div className={styles.educationWrapper}>
        <EducationWrapper form={form}>
          <div className={styles.educationSectionRow}>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={<div className={styles.quickSearchFormItemLabelText}>Education / Degree</div>}
                  tooltipTitle="Career Builder will take the first value from the selection as it does not support multi-select"
                />
              </div>
              <Degree initialValue={defaultFormValues?.Education?.Degree} />
            </div>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabelText}>School / University</div>
              <School initialValue={defaultFormValues?.Education?.School} />
            </div>
          </div>
          <div className={styles.educationSectionRow}>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={<div className={styles.quickSearchFormItemLabelText}>Specializations</div>}
                  tooltipTitle="Specialization is not available in Monster Power"
                />
              </div>
              <Specialization initialValue={defaultFormValues?.Education?.Specialization} />
            </div>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={<div className={styles.quickSearchFormItemLabelText}>Certifications</div>}
                  tooltipTitle="Certification is not available in Monster Power"
                />
              </div>
              <Certification initialValue={defaultFormValues?.Education?.Certification} />
            </div>
          </div>
        </EducationWrapper>
      </div>
    </div>
  );
}
