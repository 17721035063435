export const isDisabledReactivationOptions = (jobDetails, version) => {
  let disableFlag = true;
  const sourcedCandidateCount = jobDetails?.sourcingStats?.Shortlisted ?? 0;
  const shortlistedCandidateCount = jobDetails?.sourcingStats?.Sourced ?? 0;
  const connectedCandidateCount = jobDetails?.ConnectedCount ?? 0;
  if (sourcedCandidateCount || shortlistedCandidateCount || connectedCandidateCount) {
    disableFlag = !disableFlag;
  }
  return disableFlag && version !== 'ats';
};
