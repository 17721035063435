import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { Auth } from 'aws-amplify';
import RoutesContainer from '../../routes';
import config from '../../Config/Config';

class SsoLoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.redirect = this.redirect.bind(this);
    this.setUserLoggedinStatus = this.setUserLoggedinStatus.bind(this);
  }

  componentDidMount() {
    const { identityProvider } = this.props;
    sessionStorage.setItem('aryaidp', identityProvider.identifier);
    this.setUserLoggedinStatus();
    const { authState } = this.state;
    const isSignedIn = authState === 'signedIn';
    if (!isSignedIn) {
      this.redirect();
    }
  }

  setUserLoggedinStatus() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        // eslint-disable-next-line no-console
        console.log('user loggedin from external IDP', user);
        this.setState({ authState: 'signedIn' });
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        this.setState({ authState: 'signIn' });
      });
  }

  redirect() {
    const { identityProvider } = this.props;
    const state = uuid();
    window.location.assign(
      `https://${config.auth.cognito.domain}/oauth2/authorize?identity_provider=${identityProvider.identifier}&redirect_uri=${config.auth.cognito.redirectUri}&state=${state}&response_type=CODE&client_id=${config.auth.cognito.clientId}&scope=email openid phone profile aws.cognito.signin.user.admin`
    );
  }

  render() {
    const { authState } = this.state;
    const isSignedIn = authState === 'signedIn';
    if (isSignedIn) {
      return <RoutesContainer />;
    }
    return <>Redirecting...</>;
  }
}

export default SsoLoginContainer;
