import _ from 'lodash';
import { getRelativeDay } from './RelativeTimeCalculator';

function getGroupMessages(givenMessages) {
  const messages = _.cloneDeep(givenMessages).reverse();
  const _messages = [];
  let createdTime = messages[0].CreatedTime;
  _messages.push({ type: 'title', Body: getRelativeDay(createdTime) });
  for (let index = 0; index < messages.length; index += 1) {
    if (_.get(messages, [index, 'CreatedTime'], '').substring(0, 11) === createdTime.substring(0, 11)) {
      _messages.push(messages[index]);
    } else {
      createdTime = messages[index].CreatedTime;
      _messages.push({ type: 'title', Body: getRelativeDay(createdTime) });
      _messages.push(messages[index]);
    }
  }
  return _messages.reverse();
}

export default getGroupMessages;
