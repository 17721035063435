import React from 'react';
import MinMaxExperience from '../../../../Components/MinMaxExperience/MinMaxExperience';
import styles from './ExperienceSection.module.scss';

export default function ExperienceSection(props) {
  const { form, defaultFormValues } = props;
  return (
    <div className={styles.experienceSection}>
      <div className={styles.experienceLabel}>Overall Experience Range</div>
      <div className={styles.experienceRangeWrapper}>
        <MinMaxExperience
          form={form}
          initialMinExperience={defaultFormValues?.Experience?.MinExperience || undefined}
          initialMaxExperience={defaultFormValues?.Experience?.MaxExperience || undefined}
          showLabel={false}
          inputClassName={styles.minMaxInput}
          minExperiencePlaceholder="From"
          maxExperiencePlaceholder="To"
        />
      </div>
    </div>
  );
}
