/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { getOutgoers } from 'react-flow-renderer';

const getIncomingEdges = ({ edges, targetNodeId }) => edges.filter(x => x.target === targetNodeId);

const getOutGoingEdges = ({ edges, targetNodeId }) => {
  return edges.filter(x => x.source === targetNodeId);
};

const updateEdge = ({ edges, targetEdgeId, updatedTargetEdgeId }) => {
  const targetEdgeIndex = edges.findIndex(x => x.id === targetEdgeId);
  const targetEdge = edges[targetEdgeIndex];
  const updatedTargetEdge = { ...targetEdge, target: updatedTargetEdgeId };
  edges[targetEdgeIndex] = updatedTargetEdge;
};

const deleteNode = ({ nodes, targetNodeId }) => {
  _.remove(nodes, x => x.id === targetNodeId);
};

const deleteOutGoingEdges = ({ edges, targetNodeId }) => {
  _.remove(edges, x => x.source === targetNodeId);
};

const deleteNodeAndOutgoingEdges = ({ nodes, edges, targetNodeId }) => {
  deleteNode({ nodes, targetNodeId });
  deleteOutGoingEdges({
    edges,
    targetNodeId,
  });
};

const getIsMergeNode = ({ targetNodeId, edges }) => {
  const incomingEdges = getIncomingEdges({ targetNodeId, edges });
  return incomingEdges.length > 1;
};

const getMergeNodeIdOfRuleNode = ({ targetNodeId, nodes, edges }) => {
  let mergeNodeCounter = 0;
  const targetNode = nodes.find(x => x.id === targetNodeId);
  const nodesToBeTraversed = [getOutgoers(targetNode, nodes, edges)[0]];
  while (nodesToBeTraversed.length > 0) {
    const currentNode = nodesToBeTraversed.pop();
    if (getIsMergeNode({ targetNodeId: currentNode.id, edges })) {
      if (mergeNodeCounter) {
        mergeNodeCounter -= 1;
      } else {
        return currentNode.id;
      }
    } else if (currentNode.type === 'Decision') {
      mergeNodeCounter += 1;
    }
    const outGoingNodes = getOutgoers(currentNode, nodes, edges);
    if (outGoingNodes[0]) {
      nodesToBeTraversed.push(outGoingNodes[0]);
    }
  }
  return null;
};

const getLeafEdgeFromNode = ({ node, edges, ruleNode, targetNodeId }) => {
  if (node?.id === targetNodeId) {
    return edges?.find(edge => edge.target === node?.id && edge.source === ruleNode?.id);
  }
  return edges?.find(edge => edge.source === node?.id);
};

const getConnectingLeafEdgeToTargetNode = (currentNode, targetNodeId, cloneNodes, cloneEdges, ruleNode) => {
  let previousNode = currentNode;
  let traversedNode = currentNode;

  while (true) {
    if (traversedNode?.id === targetNodeId) {
      return getLeafEdgeFromNode({ node: previousNode, edges: cloneEdges, ruleNode, targetNodeId });
    }
    previousNode = traversedNode;
    traversedNode = _.get(getOutgoers(traversedNode, cloneNodes, cloneEdges), [0]);
  }
};

const getIncomingLeafEdgesToTargetFromRuleNode = (ruleNode, targetNodeId, cloneNodes, cloneEdges) => {
  const outGoers = getOutgoers(ruleNode, cloneNodes, cloneEdges);

  if (outGoers.length === 1) {
    return getOutGoingEdges({
      edges: cloneEdges,
      targetNodeId: ruleNode?.id,
    });
  }

  const leafEdge1 = getConnectingLeafEdgeToTargetNode(outGoers[0], targetNodeId, cloneNodes, cloneEdges, ruleNode);
  const leafEdge2 = getConnectingLeafEdgeToTargetNode(outGoers[1], targetNodeId, cloneNodes, cloneEdges, ruleNode);

  return [leafEdge1, leafEdge2];
};

export {
  getIncomingEdges,
  getOutGoingEdges,
  updateEdge,
  deleteNode,
  getIsMergeNode,
  deleteOutGoingEdges,
  deleteNodeAndOutgoingEdges,
  getMergeNodeIdOfRuleNode,
  getIncomingLeafEdgesToTargetFromRuleNode,
};
