import React from 'react';
import * as d3 from 'd3';
import './GenericDonut.scss';

const groupPlacementShift = { left: 10, top: 10 };

function createArc(height, thickness) {
  const outerRadius = height / 2;
  const innerRadius = outerRadius - thickness;
  return d3
    .arc()
    .outerRadius(outerRadius)
    .innerRadius(innerRadius);
}

function createPie() {
  return d3
    .pie()
    .sort(null)
    .value(d => d.Value);
}

function getLabels(data, colors) {
  return data?.map((d, idx) => (
    <div className="legendLabels">
      <div className="legendLabelColor" style={{ backgroundColor: colors[idx] }}></div>
      <div className="legendLabelName">{d.Name}</div>
    </div>
  ));
}

class GenericDonut extends React.Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.state = {
      arc: null,
      pie: null,
    };
  }

  componentDidMount() {
    this.createDonutChart();
  }

  createDonutChart() {
    const { height, thickness } = this.props;
    this.setState({
      arc: createArc(height, thickness),
      pie: createPie(),
    });
  }

  getTextPosition({ arc, d }) {
    const { width, height } = this.props;
    const radius = Math.min(width, height) / 2 - 25;
    const c = arc.centroid(d);
    const x = c[0];
    const y = c[1];
    const h = Math.sqrt(x * x + y * y);
    return `translate(${(x / h) * radius}, ${(y / h) * radius})`;
  }

  mosueOverEventHandler = (event, d, colors, idx) => {
    const { chartTitle } = this.props;

    const tooltip = d3.select(`#tooltip${chartTitle}`);
    const html = `
      <div class="tooltipContent">
        <p class="tooltipHeading">
          ${chartTitle}
        </p >
      <div class="tooltipDetails">
        <div class="labelColor"></div>
        <div class="labelName"></div>
        <div class="labelValue"><div>
        </div>
        </div>
    `;

    tooltip
      .html(html)
      .style('position', 'absolute')
      .style('left', `${event.nativeEvent.offsetX}px`)
      .style('top', `${event.nativeEvent.offsetY}px`)
      .transition()
      .duration(200)
      .style('opacity', 1);

    tooltip.select('.labelName').html(d.data.Name);
    tooltip.select('.labelValue').html(d.data.Value);
    tooltip.select('.labelColor').style('background-color', colors[idx]);

    d3.select(event.target).attr('filter', "url('#innerShadow')");
  };

  mosueOutEventHandler = event => {
    const { chartTitle } = this.props;

    const tooltip = d3.select(`#tooltip${chartTitle} `);
    tooltip
      .transition()
      .duration(300)
      .style('opacity', 0);

    d3.select(event.target).attr('filter', null);
  };

  render() {
    const { width, height, data, title, subtitle, colors, showStatsText, chartTitle } = this.props;

    const { arc, pie } = this.state;

    if (!arc || !pie) {
      return <div>Loading...</div>;
    }

    return (
      <div id="donutChartView">
        <div id="donutChart">
          <svg ref={this.svgRef} width={width + 20} height={height + 20} className="pieChart">
            <defs>
              <filter id="innerShadow" y="-20" x="-20" height="400" width="500">
                <feGaussianBlur result="offset-blur" stdDeviation="5"></feGaussianBlur>
                <feOffset dx="1" dy="1"></feOffset>
                <feFlood floodColor="black" floodOpacity="1" result="color"></feFlood>
                <feComposite operator="in" in2="offset-blur"></feComposite>
                <feComponentTransfer>
                  <feFuncA slope="0.5"></feFuncA>
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
            </defs>
            <g
              transform={`translate(${width / 2 + groupPlacementShift.left}, ${height / 2 + groupPlacementShift.top})`}
              id="donutGroup"
            >
              {pie(data)?.map(d => {
                const { Value, Name } = d.data;

                return (
                  <React.Fragment key={Name}>
                    <path
                      className="donutArc"
                      fill={colors[d.index]}
                      d={arc(d)}
                      onFocus={() => {}}
                      onMouseOver={event => this.mosueOverEventHandler(event, d, colors, d.index)}
                      onBlur={() => {}}
                      onMouseOut={this.mosueOutEventHandler}
                    />

                    {showStatsText ? (
                      <text transform={this.getTextPosition({ arc, d })} textAnchor="middle" fontSize="12px">
                        {`${Value} `}
                      </text>
                    ) : null}
                  </React.Fragment>
                );
              })}

              <g>
                <text textAnchor="middle" fontSize="16px" x="0" y="0">
                  <tspan x="0" y="-12">
                    {`${title} `}
                  </tspan>
                  <tspan x="0" y="12">
                    {`${subtitle} `}
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
          <div className="tooltip" id={`tooltip${chartTitle}`}></div>
        </div>
        <div className="donutLabels">{getLabels(data, colors)}</div>
      </div>
    );
  }
}

GenericDonut.defaultProps = {
  width: 300,
  height: 300,
  title: 'Donut Chart',
  colors: [
    '#FF0000',
    '#FF3300',
    '#ff6600',
    '#ff9900',
    '#FFCC00',
    '#FFFF00',
    '#ccff00',
    '#99ff00',
    '#66ff00',
    '#33ff00',
    '#00FF00',
  ],
  data: [],
  showStatsText: true,
  chartTitle: 'Donut',
  thickness: 50,
};
export default GenericDonut;
