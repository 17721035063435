import React from 'react';
import { connect } from 'react-redux';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getReportStats, getReportColumns as _getReportColumns } from '../../../Reducers/ReportPortalReducer';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import JobReports from '../../../Components/ReportPortal/ReportStatsTab/JobReports/JobReports';

const mapStateToProps = state => {
  return {
    fetchReportStatsApiStatus: getApiStatus(state, 'fetchReportStatsApiStatus'),
    exportReportApiStatus: getApiStatus(state, 'exportReportApiStatus'),
    reportStats: getReportStats(state, 'jobs'),
    reportColumns: _getReportColumns(state, 'jobs'),
  };
};

const mapDispatchToProps = {
  fetchReportStats: ReportPortalActions.getReportStats,
  generateReport: ReportPortalActions.generateReport,
  clearReportStats: ReportPortalActions.clearReportStats,
  getReportColumns: ReportPortalActions.getReportColumns,
  clearFetchReportStatsApiStatus: ReportPortalActions.clearFetchReportStatsApiStatus,
  clearRecruitersForDropdownLists: ReportPortalActions.clearRecruitersForDropdownLists,
  clearClientsForDropdownLists: ReportPortalActions.clearClientsForDropdownLists,
};

export function JobReportsContainer(props) {
  const {
    reportStats,
    fetchReportStats,
    fetchReportStatsApiStatus,
    clearReportStats,
    generateReport,
    exportReportApiStatus,
    clearFetchReportStatsApiStatus,
    savedQueryConfig,
    onSaveQuerySuccess,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
  } = props;

  return (
    <div>
      <JobReports
        savedQueryConfig={savedQueryConfig}
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        reportStats={reportStats}
        getReportStats={fetchReportStats}
        clearReportStats={clearReportStats}
        exportReportApiStatus={exportReportApiStatus}
        generateReport={generateReport}
        clearFetchReportStatsApiStatus={clearFetchReportStatsApiStatus}
        onSaveQuerySuccess={onSaveQuerySuccess}
        clearRecruitersForDropdownLists={clearRecruitersForDropdownLists}
        clearClientsForDropdownLists={clearClientsForDropdownLists}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobReportsContainer);
