import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AnnouncementModal } from '../../Components/AnnouncementBanner/AnnouncementModal';
import { getAllAnnouncements, updateAnnouncementView } from '../../Actions/AnnouncementActions';
import { resetReleaseAnnouncement } from '../../Actions/ActionCreators/AnnouncementActionCreator';
import { getReleaseAnnouncements } from '../../Reducers/AnnouncementReducer';

function AnnouncementBanner() {
  const [modalVisiblity, setModalVisiblity] = React.useState(false);
  const announcementItems = useSelector(state => getReleaseAnnouncements(state));
  const dispatch = useDispatch();

  const displayAnnouncement = _.get(announcementItems, [0], null);
  const announcementItemsPayload = displayAnnouncement?.Payload;

  React.useEffect(() => {
    if (announcementItemsPayload) setModalVisiblity(true);
  }, [announcementItemsPayload]);

  React.useEffect(() => {
    dispatch(
      getAllAnnouncements({
        from: 0,
        size: 1,
        type: 'ReleaseAnnouncement',
        isViewed: false,
      })
    );
  }, []);

  const onModalClose = () => {
    setModalVisiblity(false);
    dispatch(updateAnnouncementView(displayAnnouncement?._id));
    dispatch(resetReleaseAnnouncement());
  };

  return (
    <div>
      {announcementItemsPayload ? (
        <AnnouncementModal
          announcementItems={announcementItemsPayload}
          modalVisiblity={modalVisiblity}
          onClose={onModalClose}
        />
      ) : null}
    </div>
  );
}

export default AnnouncementBanner;
