import _ from 'lodash';
import {
  SET_RELEASE_ANNOUNCEMENTS,
  RESET_RELEASE_ANNOUNCEMENT,
  SET_DOWNTIME_ANNOUNCEMENTS,
  RESET_DOWNTIME_ANNOUNCEMENT,
  SET_DOWNTIME_RENDER_FLAG,
} from '../Actions/ActionCreators/AnnouncementActionCreator';

const initialState = {
  ReleaseAnnouncements: [],
  DowntimeAnnouncements: [],
  downtimeRenderFlag: false,
};

const AnnouncementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RELEASE_ANNOUNCEMENTS:
      return { ...state, ReleaseAnnouncements: action.payload };

    case SET_DOWNTIME_ANNOUNCEMENTS:
      return { ...state, DowntimeAnnouncements: action.payload };

    case RESET_RELEASE_ANNOUNCEMENT:
      return {
        ...state,
        ReleaseAnnouncements: initialState.ReleaseAnnouncements,
      };

    case RESET_DOWNTIME_ANNOUNCEMENT:
      return {
        ...state,
        DowntimeAnnouncements: initialState.DowntimeAnnouncements,
      };

    case SET_DOWNTIME_RENDER_FLAG: {
      return {
        ...state,
        downtimeRenderFlag: action.payload,
      };
    }

    default:
      return state;
  }
};

const getReleaseAnnouncements = state => {
  return state.AnnouncementReducer?.ReleaseAnnouncements;
};

const getDowntimeAnnouncements = state => {
  return state.AnnouncementReducer?.DowntimeAnnouncements;
};

function getDowntimeRenderFlag(state) {
  return _.get(state, ['AnnouncementReducer', 'downtimeRenderFlag'], false);
}

export { AnnouncementReducer, getReleaseAnnouncements, getDowntimeAnnouncements, getDowntimeRenderFlag };
