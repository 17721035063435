import React from 'react';
import TooltipNodeReportData from './TooltipNodeReportData';
import TooltipText from './TooltipText';

function getElementReportData(activityStats, elementId, outComeLabel) {
  const activityStat = activityStats.find(activity => activity.ActivityId === elementId);
  if (activityStat) {
    const { TotalInstances, OutcomeStats } = activityStat;
    const edgeReportData = OutcomeStats.find(
      outcome => outcome.ActivityOutcome.toLowerCase() === outComeLabel.toLowerCase()
    );
    return { totalInstances: TotalInstances, completedInstances: edgeReportData?.Count };
  }
  return {};
}

function getPercentage(value, total) {
  if (total > 0) return (value / total) * 100;
  return 0;
}

const edgeTargetNode = ['Decision', 'End'];

const TooltipReportData = props => {
  const {
    activityStats,
    elementId,
    outComeLabel,
    elementType,
    targetType,
    target,
    sourceNode,
    setWorkflowReportEndNodesData,
    targetNodeLabel,
    isRendered,
  } = props;
  const { totalInstances, completedInstances } = getElementReportData(activityStats, elementId, outComeLabel);
  const percentage = getPercentage(completedInstances, totalInstances);
  if (elementType === 'edge') {
    if (targetType === 'End' && completedInstances > -1 && !isRendered)
      setWorkflowReportEndNodesData({ [target]: completedInstances, isRendered: true });
    if (edgeTargetNode.includes(targetType) && sourceNode !== 'SendEmail') {
      if (activityStats.length > 0) {
        return TooltipText({ completedInstances, percentage });
      }
      return TooltipText({ completedInstances: 0, percentage: 0 });
    }
    return null;
  }
  if (elementType === 'node')
    return (
      <TooltipNodeReportData
        sourceNodes={sourceNode}
        activityStats={activityStats}
        completedInstances={completedInstances}
        targetNodeLabel={targetNodeLabel}
      />
    );

  return false;
};

export default TooltipReportData;
