import React from 'react';
import { Affix, Button } from 'antd';
import styles from './JobCreationFooter.module.scss';

export default function JobCreationFooter({
  handleSubmit,
  isLoading,
  actionsDisabled,
  content,
  primaryButtonText = 'Proceed',
  isSubmitButtonClicked,
}) {
  return (
    <Affix offsetBottom={0} style={{ width: '100%' }}>
      <div className={styles.footerWrapper}>
        <div>{content}</div>

        <Button
          type="default"
          disabled={actionsDisabled || isSubmitButtonClicked}
          className={styles.draftBtn}
          shape="round"
          onClick={() => handleSubmit('draft')}
          loading={isLoading && !isSubmitButtonClicked}
        >
          Save Draft
        </Button>
        <Button
          type="primary"
          disabled={actionsDisabled || (!isSubmitButtonClicked && isLoading)}
          className={styles.saveBtn}
          shape="round"
          onClick={() => handleSubmit('save')}
          loading={isLoading && isSubmitButtonClicked}
        >
          {primaryButtonText}
        </Button>
      </div>
    </Affix>
  );
}
