import React from 'react';
import { Anchor } from 'antd';
import styles from './GlobalInsightSupplyMenuList.module.scss';
import { menuList } from '../../../Utils/GlobalInsightSupplyUtil';

const { Link } = Anchor;
export default function GlobalInsightSupplyMenuList(props) {
  const { hiddenMenuList = [], menuItems = menuList } = props;
  const [targetOffset, setTargetOffset] = React.useState(undefined);
  React.useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);
  return (
    <Anchor targetOffset={targetOffset} className={styles.supplyItemsUl} offsetTop={80}>
      {menuItems.map(
        menuInfo =>
          !hiddenMenuList.includes(menuInfo.id) && (
            <Link href={`#${menuInfo.id}`} title={menuInfo.name} key={menuInfo.id} className={styles.itemName} />
          )
      )}
    </Anchor>
  );
}
