import React from 'react';
import { Icon } from 'antd';

export const LocationSvg = props => (
  <svg width={44} height={44} {...props}>
    <defs>
      <path id="a" d="M0 26.667h16V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={21} stroke="#979797" strokeWidth={2} />
      <g transform="translate(14 9)">
        <path
          d="M8 2.667A5.339 5.339 0 0 1 13.333 8c0 3.343-3.25 8.392-5.333 11.176C5.916 16.395 2.667 11.348 2.667 8A5.339 5.339 0 0 1 8 2.667m0 20.749C11.204 19.572 16 12.983 16 8c0-4.412-3.588-8-8-8S0 3.588 0 8c0 4.984 4.799 11.573 8 15.416"
          fill="#757575"
        />
        <path
          d="M10.667 8A2.67 2.67 0 0 0 8 5.333 2.67 2.67 0 0 0 5.333 8 2.67 2.67 0 0 0 8 10.667 2.67 2.67 0 0 0 10.667 8"
          fill="#757575"
        />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path fill="#757575" mask="url(#b)" d="M2.667 26.667h10.666V24H2.667z" />
      </g>
    </g>
  </svg>
);

const LocationIcon = props => <Icon component={LocationSvg} {...props} />;

export default LocationIcon;
