export default function convertBase64ToFile(image) {
  const byteString = window.atob(image.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const index = image.indexOf(';');
  return new Blob([ab], {
    type: image.substr(5, index),
  });
}
