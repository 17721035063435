import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import TemplateRow from '../TemplateRow';
import eventTypes from '../../../../Analytics/EventTypes';

export default class SharedAryabotTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.cloneTemplate = this.cloneTemplate.bind(this);
    this.viewTemplate = this.viewTemplate.bind(this);
  }

  cloneTemplate() {
    const { cloneTemplate, id } = this.props;
    cloneTemplate(id);
  }

  viewTemplate() {
    const { viewTemplate, id } = this.props;
    viewTemplate(id);
  }

  render() {
    const { title, createdBy, updatedTime } = this.props;

    return (
      <Row className="template">
        <Col span={8} onClick={this.viewTemplate} className="template-column">
          {this.getTitleColumn(title)}
        </Col>
        <Col span={5}>{this.getCreatedByColumn(createdBy)}</Col>
        <Col span={5}>{this.getUpdatedOnColumn(updatedTime)}</Col>

        <Col span={5}>
          {this.getCloneColumn(
            'Duplicate',
            this.cloneTemplate,
            createdBy,
            eventTypes.connect.aryaBotTemplates.duplicateShared
          )}
        </Col>
      </Row>
    );
  }
}

SharedAryabotTemplateRow.propTypes = {
  title: PropTypes.string.isRequired,
  cloneTemplate: PropTypes.func.isRequired,
  viewTemplate: PropTypes.func.isRequired,
  createdBy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
