import React from 'react';
import { Modal } from 'antd';
import WarningIcon from '../../../Icons/WarningIcon';
import styles from './WarningPopupModal.module.scss';

export default function WarningPopupModal(props) {
  const { setWarningPopupModalMessage, warningMessage } = props;
  return (
    <Modal
      destroyOnClose
      visible={warningMessage}
      onCancel={() => setWarningPopupModalMessage('')}
      maskClosable={false}
      footer={null}
    >
      <div className={styles.warningWrapper}>
        <span className={styles.icon}>
          <WarningIcon className={styles.iconSize} />
        </span>
        <div className={styles.textWrapper}>
          <span className={styles.warningTitle}>Oops!</span>
          <span className={styles.warningSubTitle}>{warningMessage}</span>
        </div>
      </div>
    </Modal>
  );
}
