import React from 'react';
import { Dropdown, Button, Tree, Menu, Icon, Tooltip } from 'antd';
import styles from './ColumnFilter.module.scss';

const { TreeNode } = Tree;

export function getFormattedColumnOptions(columns = []) {
  return columns?.map(column => {
    return {
      key: column?.AryaName,
      title: (
        <Tooltip title={column?.DisplayName}>
          <div className={styles.columnOption}>{column?.DisplayName}</div>
        </Tooltip>
      ),
    };
  });
}

export function ColumnFilterContent(props) {
  const { value, onChange, optionValues } = props;

  const renderTreeNodes = data => {
    return data?.map(item => {
      if (item?.children) {
        return (
          <TreeNode title={item?.title} key={item?.key} dataRef={item}>
            {renderTreeNodes(item?.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item?.key} {...item} />;
    });
  };

  return (
    <Menu className={styles.columnFilterMenu}>
      {!optionValues?.length ? (
        <Menu.ItemGroup title="No data found!" />
      ) : (
        <Tree
          checkable
          autoExpandParent
          onCheck={onChange}
          checkedKeys={value}
          selectable={false}
          defaultExpandedKeys={['ALL']}
        >
          {renderTreeNodes(optionValues)}
        </Tree>
      )}
    </Menu>
  );
}

export default function ColumnFilter(props) {
  const { columns, value, onChange, fetchReportColumnsApiStatus, isSingleColumnList } = props;

  const [selectedColumnsCount, setSelectedColumnsCount] = React.useState(
    value?.filter(selectedValue => !['ALL', 'STATIC', 'DYNAMIC'].includes(selectedValue?.toUpperCase()))?.length
  );
  const [menuVisibility, setMenuVisibility] = React.useState(false);

  const loadingStatus = fetchReportColumnsApiStatus?.status?.toUpperCase() === 'INPROGRESS';

  React.useEffect(() => {
    if (value?.length) {
      setSelectedColumnsCount(
        value?.filter(selectedValue => !['ALL', 'STATIC', 'DYNAMIC'].includes(selectedValue?.toUpperCase()))?.length
      );
    }
  }, [JSON.stringify(value)]);

  const getColumnOptionValues = () => {
    if (!columns?.length) return [];
    const staticColumns = [];
    const dynamicColumns = [];
    (columns ?? []).forEach(column => {
      if (!column?.PortalId) staticColumns.push(column);
      else dynamicColumns.push(column);
    });

    let parentOptionValue = {
      title: 'All Columns',
      key: 'ALL',
    };

    if (isSingleColumnList) {
      parentOptionValue = {
        ...parentOptionValue,
        children: getFormattedColumnOptions(columns),
      };
    } else {
      const staticOptionValues = {
        title: 'Static Columns',
        key: 'STATIC',
        children: getFormattedColumnOptions(staticColumns),
      };
      const dynamicOptionValues = {
        title: 'Dynamic Columns',
        key: 'DYNAMIC',
        children: getFormattedColumnOptions(dynamicColumns),
      };
      const childrenOptionValues = [];
      if (staticColumns?.length) {
        childrenOptionValues.push(staticOptionValues);
      }
      if (dynamicColumns?.length) {
        childrenOptionValues.push(dynamicOptionValues);
      }
      parentOptionValue = {
        ...parentOptionValue,
        children: childrenOptionValues,
      };
    }
    return [parentOptionValue];
  };

  const optionValues = getColumnOptionValues();

  const onCheck = selectedColumns => {
    setSelectedColumnsCount(selectedColumns?.length);
    onChange(selectedColumns);
  };

  return (
    <div className={styles.reportFilter}>
      <Dropdown
        getPopupContainer={node => node.parentNode}
        overlay={<ColumnFilterContent value={value} onChange={onCheck} optionValues={optionValues} />}
        trigger={['click']}
        onVisibleChange={setMenuVisibility}
        disabled={loadingStatus}
      >
        <Button className={styles.dropdownButton}>
          <div className={styles.dropdownButtonContent}>
            <div>{!loadingStatus ? `${selectedColumnsCount} columns selected` : null}</div>
            {loadingStatus ? <Icon type="loading" className={styles.downIcon} /> : null}
            {!loadingStatus && menuVisibility ? <Icon type="up" className={styles.downIcon} /> : null}
            {!loadingStatus && !menuVisibility ? <Icon type="down" className={styles.downIcon} /> : null}
          </div>
        </Button>
      </Dropdown>
    </div>
  );
}
