import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function JobExperience({ minExperience, maxExperience }) {
  if (minExperience !== -1 && maxExperience !== -1 && minExperience && maxExperience) {
    return (
      <FormattedMessage
        {...messages.minimumToMaximum}
        values={{
          maxExperience,
          minExperience,
        }}
      />
    );
  }
  if (minExperience && minExperience !== -1 && (!maxExperience || maxExperience === -1)) {
    return <FormattedMessage {...messages.minimumOf} values={{ minExperience }} />;
  }
  if (maxExperience && maxExperience !== -1 && (!minExperience || minExperience === -1)) {
    return <FormattedMessage {...messages.maximumOf} values={{ maxExperience }} />;
  }
  return <FormattedMessage {...messages.na} />;
}
