import React from 'react';

function useLocation(props) {
  const [locationApiStatus, setLocationApiStatus] = React.useState('');
  const [locationSuggestions, setLocationSuggestions] = React.useState();
  const [zipCodeSuggestions, setZipCodeSuggestions] = React.useState([]);
  const [isZipCodeInValid, setIsZipCodeInValid] = React.useState(false);
  const [manualLocation, setManualLocation] = React.useState();
  const { fetchCityAndStateSuggestions, fetchZipCodes, givenCountryCode, searchZipCode } = props;
  const searchLocation = async (country, searchTerm, layers) => {
    setLocationApiStatus('INPROGRESS');
    const _locationSuggestions = await fetchCityAndStateSuggestions({ country, searchTerm, layers });
    setLocationSuggestions(_locationSuggestions);
    setManualLocation({});
    setIsZipCodeInValid(false);
    setLocationApiStatus('COMPLETED');
  };
  const searchZipCodes = async value => {
    const _zipCodeSuggestions = await fetchZipCodes({
      filter: {
        country: givenCountryCode,
        location: value,
      },
    });
    setZipCodeSuggestions(_zipCodeSuggestions);
  };
  const searchLocationUsingZipcode = async ({ postalCode, countryCode }) => {
    try {
      setIsZipCodeInValid(false);
      const response = await searchZipCode({
        postalCode,
        countryCode,
      });
      const location = response[0];
      const _location = { city: location?.City, state: { name: location?.State } };
      setManualLocation(location);
      setLocationSuggestions([_location]);
      setIsZipCodeInValid(false);
      setZipCodeSuggestions([postalCode]);
    } catch (error) {
      const isRequestCancelled = error.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        setIsZipCodeInValid(true);
        setManualLocation({});
        setLocationSuggestions([]);
      }
    }
  };
  const clearZipCodeError = () => {
    setIsZipCodeInValid(false);
    setManualLocation({});
    setLocationSuggestions([]);
    setZipCodeSuggestions([]);
  };
  const clearZipValidationError = () => {
    setIsZipCodeInValid(false);
  };
  return {
    clearZipCodeError,
    clearZipValidationError,
    isZipCodeInValid,
    locationApiStatus,
    locationSuggestions,
    manualLocation,
    searchLocation,
    searchLocationUsingZipcode,
    searchZipCodes,
    zipCodeSuggestions,
  };
}

export default useLocation;
