import React from 'react';
import _, { debounce } from 'lodash';
import { Form, Skeleton, Select, Row, Col, Button, Icon } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import RecallJobDistribution from './RecallJobDistribution/RecallJobDistribution';
import MonsterJobPublishForm from './Monster/MonsterJobPublishForm';
import AryaAdvertisementForm from './AryaAdvertisement/AryaAdvertisementForm';
import { JobBoardPortals, AdvertisementPortals } from '../../Utils/JobDistributionUtils/JobDistributionUtils';
import { MonsterIcon } from '../../Icons/AryaIcons';
import JobExternalPreviewModal from '../JobExternalPreviewModal/JobExternalPreviewModal';
import { getJobExternalPreviewURL } from '../../Utils/JobUtils';
import { JOBBOARDFORPUBLISH } from '../../Utils/JobBoardUtils';
import styles from './JobPublishComponent.module.scss';

const { Option } = Select;

const defaultProps = {
  distributionPortals: [],
  distributedPortals: [],
  portal: '',
};

function JobPublishComponent(props = defaultProps) {
  const {
    form,
    portal,
    locationOptions,
    utilities,
    jobDetails,
    distributionPortals,
    distributedJobInfo,
    distributedPortals,
    draftedPortals,
    monsterInventories,
    fetchLocations,
    fetchZipCodes,
    searchZipCode,
    clearZipCodeError,
    clearZipValidationError,
    fetchMonsterJobInventories,
    fetchDistributedPortals,
    fetchDistributedJobPortalInfo,
    recallPortalJobDistribution,
    locationApiStatus,
    distributeJobApiStatus,
    distributeJobPortalApiStatus,
    distributeJobPortalInfoApiStatus,
    monsterJobInventoriesApiStatus,
    onSubmit,
    onCancel,
    availableAdCredits,
    portalTariff,
    productVariant,
    productVariantId,
    fetchAvailableJobAdCreditsStatus,
    AppName,
  } = props;
  const { zipCodes, isZipCodeInValid } = utilities;

  const currentUser = useSelector(state => getCurrentUserDetails(state));
  const [locationInput, setLocationInput] = React.useState('');
  const [publishPortal, setPublishPortal] = React.useState(portal);
  const [recallVisible, setRecallVisible] = React.useState(false);
  const [paymentScreen, setPaymentScreen] = React.useState(false);
  const [jobPreviewModalVisibility, setJobPreviewModalVisibility] = React.useState(false);

  React.useEffect(() => {
    fetchDistributedPortals();
    fetchDistributedJobPortalInfo(publishPortal);
    if (publishPortal.toLowerCase() === 'monster') {
      fetchMonsterJobInventories();
    }
  }, []);

  const filterLocation = value => {
    setLocationInput(value);
    const country = jobDetails.CountryCode;
    fetchLocations({
      country,
      search: value,
    });
  };

  const debouncedFilterLocations = React.useCallback(
    debounce(nextValue => filterLocation(nextValue), 600),
    []
  );

  const getPortalIcon = portalName => {
    if (portalName.toLowerCase() === 'monster') {
      return <MonsterIcon />;
    }
    return null;
  };

  let locationDropDownStatus;
  let locationMenuList;
  if (locationOptions) {
    locationMenuList = locationOptions.map((locationOption, index) => {
      let optionValue = locationOption.City ? `${locationOption.City}, ` : '';
      optionValue = locationOption.State ? optionValue + locationOption.State : optionValue;
      optionValue = _.trim(optionValue, ', ');
      return (
        <Option value={optionValue} key={`${optionValue}${index.toString()}`}>
          {optionValue}
        </Option>
      );
    });
  }
  if (locationInput.length) {
    locationDropDownStatus = locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
  } else {
    locationDropDownStatus = 'Type to search';
  }

  const distributeLoading = distributeJobApiStatus === 'INPROGRESS';
  const formLoading =
    distributeJobPortalApiStatus === 'INPROGRESS' || distributeJobPortalInfoApiStatus === 'INPROGRESS';
  const monsterInventoriesLoading = monsterJobInventoriesApiStatus === 'INPROGRESS';

  const advertisementHeading = () =>
    AdvertisementPortals.includes(portal.toLowerCase()) ? (
      <div className={styles.advertiseHeadingCols}>
        <Col className={styles.advertisePortalHeading}>{AppName} Intelligent Job Advertising</Col>
        <Col className={styles.advertisePortalHeading}>
          <Button
            type="primary"
            className={styles.advertiseHeadingBtn}
            onClick={() => setJobPreviewModalVisibility(true)}
          >
            <Icon type="eye" theme="filled" className={styles.advertisePreviewIcon} />
            Preview Job
          </Button>
        </Col>
      </div>
    ) : null;

  const jobBoardHeading = () =>
    JobBoardPortals.includes(portal.toLowerCase()) ? (
      <>
        <Col span={5} className={styles.selectPortalHeading}>
          Publish this Job on
        </Col>
        <Col span={4}>
          <Form.Item colon={false}>
            {form.getFieldDecorator('Portal', {
              initialValue: portal,
              rules: [{ required: true, message: 'Please select portal' }],
            })(
              <Select onChange={setPublishPortal} className={styles.selectPortal}>
                {distributionPortals.map(portalOption => (
                  <Option value={portalOption} key={portalOption} title={portalOption}>
                    {getPortalIcon(portalOption)} {portalOption}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </>
    ) : null;

  const availableCreditsDisplay = () =>
    AdvertisementPortals.includes(portal.toLowerCase()) ? (
      <div className={styles.jobCredits}>
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1 }}
          loading={fetchAvailableJobAdCreditsStatus !== 'COMPLETED'}
        >
          Available: {availableAdCredits} credits
        </Skeleton>
      </div>
    ) : null;

  return (
    <div className={styles.jobPublishContainer}>
      {paymentScreen ? null : (
        <>
          <div className={styles.jobPublishComponent}>
            <Row gutter={16}>
              {advertisementHeading()}
              {jobBoardHeading()}
            </Row>
          </div>
          <div className={styles.jobPublishComponentOptions}>
            <div className={styles.backToJobWrapper}>
              <div onClick={onCancel} role="presentation" className={styles.backToJob}>
                {'< Back'}
              </div>
              {availableCreditsDisplay()}
            </div>
          </div>
        </>
      )}
      <JobExternalPreviewModal
        jobPreviewModalVisibility={jobPreviewModalVisibility}
        onClosePreviewModal={() => setJobPreviewModalVisibility(false)}
        previewUrl={getJobExternalPreviewURL(jobDetails, form)}
      />
      <RecallJobDistribution
        visible={recallVisible}
        onCancel={() => setRecallVisible(false)}
        onConfirm={() => {
          setRecallVisible(false);
          recallPortalJobDistribution(publishPortal);
        }}
        portal={publishPortal}
      />
      <div className={styles.portalForm}>
        <Skeleton active paragraph={{ rows: 16 }} loading={formLoading}>
          {publishPortal.toLowerCase() === 'monster' ? (
            <MonsterJobPublishForm
              form={form}
              onSubmit={onSubmit}
              onCancel={onCancel}
              currentUser={currentUser}
              jobDetails={jobDetails}
              isPublished={distributedPortals?.find(p => p.toLowerCase() === 'monster')}
              distributedJobInfo={distributedJobInfo}
              monsterInventories={monsterInventories}
              filterLocations={debouncedFilterLocations}
              locationOptions={locationMenuList}
              locationDropDownStatus={locationDropDownStatus}
              distributeLoading={distributeLoading}
              monsterInventoriesLoading={monsterInventoriesLoading}
              recallPortalJobDistribution={() => setRecallVisible(true)}
            />
          ) : null}
          {publishPortal.toLowerCase() === JOBBOARDFORPUBLISH ? (
            <AryaAdvertisementForm
              form={form}
              onSubmit={onSubmit}
              onCancel={onCancel}
              currentUser={currentUser}
              jobDetails={jobDetails}
              isPublished={distributedPortals?.find(p => p.toLowerCase() === JOBBOARDFORPUBLISH)}
              isDrafted={draftedPortals?.find(p => p.toLowerCase() === JOBBOARDFORPUBLISH)}
              distributedJobInfo={distributedJobInfo}
              filterLocations={debouncedFilterLocations}
              locationOptions={locationMenuList}
              locationDropDownStatus={locationDropDownStatus}
              distributeLoading={distributeLoading}
              recallPortalJobDistribution={() => setRecallVisible(true)}
              portalTariff={portalTariff}
              availableAdCredits={availableAdCredits}
              zipCodeProps={{
                zipCodes,
                clearZipValidationError,
                searchZipCode,
                isZipCodeInValid,
                fetchZipCodes,
                clearZipCodeError,
              }}
              productVariant={productVariant}
              productVariantId={productVariantId}
              setPaymentScreen={setPaymentScreen}
              AppName={AppName}
            />
          ) : null}
        </Skeleton>
      </div>
    </div>
  );
}

export default Form.create()(JobPublishComponent);
export { JobPublishComponent as JobPublishComponentWithoutForm };
