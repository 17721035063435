import { connect } from 'react-redux';
import { fetchAllPortalCredentials } from '../../../Actions/ConfigActions';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import CareerBuilderEdgeLoginForm from '../../../Components/JobBoardIntegrationLoginForms/CareerBuilderEdgeLoginForm/CareerBuilderEdgeLoginForm';

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});
const mapDispatchToProps = { fetchPortalCredentials: fetchAllPortalCredentials };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareerBuilderEdgeLoginForm);
