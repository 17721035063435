import * as React from 'react';
import { Icon } from 'antd';

const SegmentShareNotificationSvg = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle width={40} fill="#13C26B" fillOpacity={0.15} />
    <circle cx={11.945} cy={7.687} r={2.187} stroke="#13C26B" />
    <circle cx={17.669} cy={8.994} r={1.58} stroke="#13C26B" />
    <circle cx={6.294} cy={8.994} r={1.58} stroke="#13C26B" />
    <path
      d="M7.66 15.325v-.02l-.002-.018c-.015-.187.039-.51.207-.916.164-.396.423-.834.774-1.243.7-.819 1.742-1.498 3.104-1.55 1.72-.066 2.809.726 3.493 1.622.705.923.959 1.93.977 2.165.027.336.011 1.258-.001 1.887l-.001.045.007.043-.001.01v.001a.3.3 0 0 1-.104.02c-.51.028-5.647.011-8.178-.001h-.014a.532.532 0 0 1-.228-.05.147.147 0 0 1-.028-.021v-.001h-.001l-.001-.003a.12.12 0 0 1-.003-.033v-1.937Z"
      stroke="#13C26B"
    />
    <path
      d="M16.5 16.547c1.741.009 3.727.02 4.094 0 .367-.02.416-.288.395-.42.008-.424.02-1.085 0-1.34-.037-.461-.803-3.04-3.453-2.937C17.06 11.892 16 12 15 13M7.5 16.547c-1.741.009-3.727.02-4.094 0-.367-.02-.416-.288-.395-.42-.008-.424-.02-1.085 0-1.34.037-.461.803-3.04 3.453-2.937C6.94 11.892 8 12 9 13"
      stroke="#13C26B"
    />
  </svg>
);
const SegmentShareNotificationIcon = props => <Icon component={() => SegmentShareNotificationSvg(props)} {...props} />;
export default SegmentShareNotificationIcon;
