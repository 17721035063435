import _ from 'lodash';

function AryabotTemplates(state = {}, action) {
  let newState;
  switch (action.type) {
    case 'SET_ARYABOT_TEMPLATES':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};

      newState.CurrentTemplateIds = action.payload.map(template => template.Id);
      action.payload.forEach(template => {
        newState.ById[template.Id] = template;
      });

      return newState;

    case 'SET_ARYABOT_TEMPLATES_COUNT':
      return {
        ...state,
        Count: action.payload,
      };

    case 'SET_ARYABOT_TEMPLATE_DETAILS':
      newState = _.cloneDeep(state);
      if (!newState.ById) newState.ById = {};

      newState.ById[action.payload.templateId] = action.payload.template;
      return newState;

    case 'SET_QUESTIONS':
      newState = _.cloneDeep(state);
      newState.ById[action.payload.templateId].Questions = action.payload.questions;
      return newState;

    case 'SET_ANSWERS':
      return {
        ...state,
        Answers: action.payload,
      };

    case 'SET_ARYABOT_TEMPLATE_SHARED_WITH':
      newState = _.cloneDeep(state);
      if (action.payload.sharedWith || action.payload.unsharedWith) {
        _.set(newState, ['ById', action.templateId, 'SharedWith'], action.payload.sharedWith);
        _.set(newState, ['ById', action.templateId, 'UnsharedWith'], action.payload.unsharedWith);
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }

      if (action.payload.isShareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], true);
      }
      if (action.payload.isUnshareWithAll) {
        _.set(newState, ['ById', action.templateId, 'IsPublic'], false);
      }
      return newState;

    default:
      return state;
  }
}

function getCurrentAryabotTemplateIds(state) {
  return state.AryabotTemplates.CurrentTemplateIds || [];
}

function getAryabotTemplatesById(state) {
  return state.AryabotTemplates.ById || {};
}

function getAryabotTemplatesCount(state) {
  return state.AryabotTemplates.Count;
}

export { AryabotTemplates, getCurrentAryabotTemplateIds, getAryabotTemplatesById, getAryabotTemplatesCount };
