import { apiV3Client } from './BaseRepository';

export function requestQuote(jobId) {
  return apiV3Client.post('aod/_requestQuote', {
    JobId: jobId,
  });
}

export function getIsQuoteRequestedByUser() {
  return apiV3Client.get('aod/_requestStatus');
}
