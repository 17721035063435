import React from 'react';
import { Tooltip } from 'antd';

function RegionTagWrapper(props) {
  const { locationList, children } = props;

  return (
    <Tooltip
      title={
        <div>
          {locationList?.map(location => (
            <div>{location.Location}</div>
          ))}
        </div>
      }
      trigger="hover"
      placement="topLeft"
    >
      {children}
    </Tooltip>
  );
}

export default RegionTagWrapper;
