import React from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import styles from './ScoreDetails.module.scss';
import AnchorScroll from '../Common/AnchorScroll/AnchorScroll';
import ScoreDetailSection from './ScoreDetailSection';
import { getAnchorTargetOffset } from '../../Utils/CandidateWorkHistoryUtils';

function ScoreDetailWrapper(props) {
  const {
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    candidateIntel,
    candidateIntelV1,
    isCandidateIntelDownloadEnabled,
  } = props;

  const ref = React.useRef(null);
  const [scrollingContainer, setScrollingContainer] = React.useState(null);
  const candidateIntelV2Overview = candidateIntel?.ScoreOveriewV1 ? candidateIntel?.ScoreOveriewV1 : null;
  const overview = candidateIntelV1?.Overview ? candidateIntelV1?.Overview : candidateIntelV2Overview;
  const sectionOrder = ['Summary', 'Skill', 'Company', 'Experience', 'Industry', 'Role', 'Education'];
  const sideMenu = sectionOrder
    .filter(item => candidateIntel[item]?.Facts?.length > 0)
    .map(item => ({ id: `${item}-id-ref`, name: item }));

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    if (ref.current && sideMenu.length > 0) {
      setScrollingContainer(ref.current);
      ref.current.addEventListener('scroll', onScroll);
    }
    return () => {
      if (ref.current) ref.current.removeEventListener('scroll', onScroll);
    };
  }, [sideMenu]);

  const anchorTargetOffset = getAnchorTargetOffset({ isCandidateViewHeaderVisible });

  return (
    <div className={styles.completeHistory}>
      <Skeleton active paragraph={{ rows: 8 }} loading={sideMenu.length === 0}>
        <div style={{ marginTop: '12px' }}>
          <AnchorScroll
            scrollingContainer={scrollingContainer}
            anchorMenuList={sideMenu}
            anchorTargetOffset={anchorTargetOffset}
            preventUrlChange
            isAnchorMenuListLoading={!ref.current}
            defaultActiveLink={`#${sideMenu[0].name}-id-ref`}
          />
        </div>
        <div
          className={classNames(styles.scoreDetailsContainer, { [styles.extendView]: !isCandidateViewHeaderVisible })}
          ref={ref}
        >
          <div>
            <article className={styles.contentContainer}>
              {sideMenu.map(item => (
                <section key={item.id} className={styles.content}>
                  <ScoreDetailSection
                    candidateDetails={props}
                    type={item.name}
                    data={candidateIntel[item.name]}
                    styles={styles}
                    id={item.id}
                    overview={overview}
                    isCandidateIntelDownloadEnabled={isCandidateIntelDownloadEnabled}
                  />
                </section>
              ))}
            </article>
          </div>
        </div>
      </Skeleton>
    </div>
  );
}

export default ScoreDetailWrapper;
