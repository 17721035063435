import React from 'react';
import { Anchor } from 'antd';
import styles from './AnchorScroll.module.scss';

function AnchorScroll(props) {
  const { scrollingContainer, anchorMenuList, preventUrlChange, anchorTargetOffset, defaultActiveLink } = props;

  const onClickAnchorItem = event => {
    if (preventUrlChange) event.preventDefault();
  };

  const getAnchorContainer = () => scrollingContainer || window;

  return (
    <div className={styles.anchorScrollWrapper}>
      <Anchor
        className={styles.anchorList}
        getContainer={getAnchorContainer}
        targetOffset={anchorTargetOffset}
        showInkInFixed
        onClick={onClickAnchorItem}
        activeLink={defaultActiveLink}
      >
        {anchorMenuList.map(({ id, name }) => (
          <Anchor.Link href={`#${id}`} title={name} className={styles.anchorItemItem} />
        ))}
      </Anchor>
    </div>
  );
}

export default AnchorScroll;
