import PropTypes from 'prop-types';
import _ from 'lodash';
import { getFeatureToggleList } from './FeatureToggleReducer.ts';

function JobStatusReducer(state = {}, action) {
  let newStatus;
  switch (action.type) {
    case 'SET_JOB_STATUS':
      return {
        ...state,
        DefaultStatusId: action.payload.DefaultStatusId,
        JobStatuses: action.payload.JobStatuses,
      };

    case 'SET_JOB_COUNT_BY_STATUS': {
      const jobStatuses = _.cloneDeep(state.JobStatuses);
      jobStatuses.forEach(status => {
        newStatus = status;
        newStatus.Count = action.payload.JobStatus.find(responseStatus => responseStatus.Id === newStatus.Id).Count;
      });
      return {
        ...state,
        JobStatuses: jobStatuses,
      };
    }
    case 'SET_CURRENT_JOB_STATUS_ID':
      return {
        ...state,
        CurrentStatusId: action.payload,
      };
    case 'SET_SAMPLE_CANDIDATES_DISPLAY_FLAG':
      return {
        ...state,
        showSampleCandidates: action.payload,
      };
    case 'SET_NON_DRAFT_JOB_COUNT':
      return {
        ...state,
        nonDraftJobCount: action.payload,
      };
    default:
      return state;
  }
}

JobStatusReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getDefaultStatusId(state) {
  return state.JobStatusReducer.DefaultStatusId;
}

function getCurrentJobStatusId(state) {
  return (
    state.JobStatusReducer.CurrentStatusId ||
    (getFeatureToggleList(state).PaidJobService.IsEnabled ? -1 : state.JobStatusReducer.DefaultStatusId)
  );
}

function getJobStatus(state) {
  return state.JobStatusReducer.JobStatuses;
}

function getTotalJobCount(state) {
  const jobStatuses = getJobStatus(state);
  return jobStatuses ? jobStatuses.map(jobStatus => jobStatus.Count).reduce((acc, value) => acc + value) : 0;
}

function getSampleCandidatesDisplayFlag(state) {
  return state.JobStatusReducer.showSampleCandidates;
}

function getNonDraftJobCount(state) {
  return state.JobStatusReducer.nonDraftJobCount;
}

export {
  JobStatusReducer,
  getCurrentJobStatusId,
  getJobStatus,
  getTotalJobCount,
  getDefaultStatusId,
  getSampleCandidatesDisplayFlag,
  getNonDraftJobCount,
};
