import { setApiStatus } from '../ApiStatusActions';

export const SET_JOB_PUBLISH_HISTORY = 'SET_JOB_PUBLISH_HISTORY';
export const SET_DISTRIBUTED_JOB_INFO = 'SET_DISTRIBUTED_JOB_INFO';
export const SET_DISTRIBUTED_JOB_PORTALS = 'SET_DISTRIBUTED_JOB_PORTALS';
export const SET_MONSTER_JOB_INVENTORIES = 'SET_MONSTER_JOB_INVENTORIES';
export const SET_PORTAL_TARIFF = 'SET_PORTAL_TARIFF';
export const RESET_DISTRIBUTED_JOB_INFO = 'RESET_DISTRIBUTED_JOB_INFO';
export const RESET_JOB_DISTRIBUTION = 'RESET_JOB_DISTRIBUTION';

export const setJobPublishHistory = payload => ({
  type: SET_JOB_PUBLISH_HISTORY,
  payload,
});

export function setFetchJobPublishHistoryStatus(status) {
  const apiName = 'fetchJobPublishHistoryApiStatus';
  return setApiStatus({ apiName, status });
}

export const setDistributedJobInfo = (jobId, portal, data) => ({
  type: SET_DISTRIBUTED_JOB_INFO,
  payload: {
    jobId,
    portal,
    data,
  },
});

export function setDistributeJobStatus(status) {
  const apiName = 'distributeJobStatus';
  return setApiStatus({ apiName, status });
}

export function setFetchAvailableJobAdCreditsStatus(status) {
  const apiName = 'fetchAvailableJobAdCreditsStatus';
  return setApiStatus({ apiName, status });
}

export const setDistributedJobPortals = payload => ({
  type: SET_DISTRIBUTED_JOB_PORTALS,
  payload,
});

export function setDistributedJobPortalStatus(status) {
  const apiName = 'distributeJobPortalStatus';
  return setApiStatus({ apiName, status });
}

export function setDistributedJobPortalInfoStatus(status) {
  const apiName = 'distributeJobPortalInfoStatus';
  return setApiStatus({ apiName, status });
}

export const setMonsterJobInventories = payload => ({
  type: SET_MONSTER_JOB_INVENTORIES,
  payload,
});

export const setPortalTariff = payload => ({
  type: SET_PORTAL_TARIFF,
  payload,
});

export function setMonsterJobInventoriesStatus(status) {
  const apiName = 'monsterJobInventoriesStatus';
  return setApiStatus({ apiName, status });
}

export const resetJobDistributedInfo = portal => ({
  type: RESET_DISTRIBUTED_JOB_INFO,
  payload: portal,
});

export const resetJobDistribution = () => ({
  type: RESET_JOB_DISTRIBUTION,
});
