import React from 'react';
import { Icon } from 'antd';

const DeleteSvg = () => {
  return (
    <svg width={18} height={23}>
      <defs>
        <filter colorInterpolationFilters="auto" id="prefix__a__deletesvg">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.756863 0 0 0 0 0.764706 0 0 0 0 0.776471 0 0 0 1.000000 0"
          />
        </filter>
        <path id="prefix__b__deletesvg" d="M0 0h18v22.06H0z" />
      </defs>
      <g filter="url(#prefix__a__deletesvg)" fill="none" fillRule="evenodd">
        <mask id="prefix__c__deletesvg" fill="#fff">
          <use xlinkHref="#prefix__b__deletesvg" />
        </mask>
        <path
          d="M7.225 2.526h3.557v-1.22H7.225v1.22zm9.412 2.547c-.115-.348-.232-.664-.32-.987-.055-.202-.156-.257-.362-.257-4.639.006-9.278.005-13.916.005-.05 0-.103-.008-.15.002-.046.01-.114.028-.125.06-.137.386-.266.776-.4 1.177h15.273zM2.906 6.386c-.004.033-.01.054-.008.074.169 2.103.336 4.206.507 6.308.194 2.396.392 4.79.587 7.187.048.588.285.81.88.81l8.485.001c.057 0 .115.001.172-.001.368-.016.637-.252.688-.617.027-.192.037-.386.053-.579l.374-4.612c.106-1.309.21-2.618.317-3.926l.366-4.484c.004-.054-.005-.108-.008-.161H2.906zM11.109 0c.153.074.318.13.456.225.374.257.52.635.521 1.077.002.402 0 .803 0 1.24h3.964c.755 0 1.21.325 1.452 1.038.147.434.3.867.435 1.305.243.784-.241 1.457-1.062 1.487-.071.002-.143 0-.234 0-.03.345-.062.678-.09 1.012l-.36 4.506-.414 5.064c-.088 1.08-.163 2.16-.267 3.24-.11 1.123-.937 1.862-2.066 1.863-2.887.004-5.773.004-8.66 0-1.166-.001-1.998-.81-2.083-1.971-.125-1.696-.275-3.39-.412-5.085l-.36-4.507-.316-3.905c-.005-.064-.018-.128-.03-.217-.157 0-.313.005-.468-.001-.8-.032-1.287-.694-1.059-1.456.146-.488.31-.97.485-1.448.22-.602.69-.923 1.336-.925 1.257-.003 2.513 0 3.77 0h.261c.005-.085.012-.16.012-.235.002-.367-.008-.733.006-1.099.023-.597.333-.987.904-1.167.034-.01.066-.027.099-.041h4.18z"
          fill="#000"
          mask="url(#prefix__c__deletesvg)"
        />
        <path
          d="M13.152 13.566l-.277 5.288c-.01.193-.018.387-.034.58-.03.391-.308.655-.668.64-.365-.013-.634-.318-.62-.713.01-.294.03-.587.045-.881.08-1.519.162-3.037.241-4.556.106-2.028.207-4.055.314-6.083.025-.467.308-.734.72-.697.351.03.587.312.57.705a172.52 172.52 0 01-.1 2.107c-.063 1.203-.128 2.407-.193 3.61h.002zM6.163 13.608l.307 5.695c.03.548-.404.913-.872.725a.61.61 0 01-.406-.559c-.041-.652-.078-1.303-.114-1.955-.111-2.028-.223-4.055-.332-6.082-.065-1.197-.133-2.393-.188-3.59-.02-.444.288-.735.706-.698.325.028.56.278.581.64.062 1.067.118 2.134.176 3.202l.14 2.622h.002M9.65 13.592c0 1.916-.001 3.833.002 5.749.001.299-.093.537-.372.67a.634.634 0 01-.888-.41 1.091 1.091 0 01-.033-.277c-.001-3.811-.002-7.622 0-11.433 0-.489.283-.782.71-.748a.627.627 0 01.574.55c.01.092.007.186.007.28v5.619"
          fill="#000"
          mask="url(#prefix__c__deletesvg)"
        />
      </g>
    </svg>
  );
};

const DeleteIcon = props => <Icon component={DeleteSvg} {...props} />;

export default DeleteIcon;
