import React from 'react';
import { Radio } from 'antd';
import HorizontalLoginForm from './HorizontalLoginForm';
import styles from './ContactProviderAuthenticationForm.module.scss';

function ContactProviderAuthenticationForm(props) {
  const {
    form,
    handleAuthenticationApi,
    handleAuthenticationUser,
    handleDisconection,
    userName,
    isLoading,
    providerId,
    credentialType,
    providerName,
    isApiKey,
    handleCredentialType,
    isSystemCredentialAllowed,
    whiteLabelInfo,
  } = props;
  const configurationTypeValues =
    providerName.toLowerCase() !== 'other'
      ? [
          {
            value: 'Client',
            label: 'Custom',
          },
        ]
      : [];
  if (isSystemCredentialAllowed) {
    configurationTypeValues.splice(0, 0, {
      value: 'Arya',
      label: `${whiteLabelInfo?.AppName ?? 'Arya'} Credit`,
    });
  }
  return (
    <div>
      <div className={styles.contactConfigureOptions}>
        <div className={styles.contactConfigureHeading}>Please select contact credit provider:</div>
        {form.getFieldDecorator(`${providerId}-configurationType`, { initialValue: credentialType })(
          <Radio.Group onChange={handleCredentialType}>
            {configurationTypeValues.map(configurationType => (
              <Radio value={configurationType.value} key={configurationType.value}>
                {configurationType.label}
              </Radio>
            ))}
          </Radio.Group>
        )}
      </div>
      {form.getFieldValue(`${providerId}-configurationType`) === 'Client' &&
      providerName.toLowerCase() !== 'zoominfo' ? (
        <HorizontalLoginForm
          handleAuthentication={handleAuthenticationApi}
          handleDisconection={handleDisconection}
          isAuthenticate={isApiKey}
          isLoading={isLoading}
          type="apikey"
        />
      ) : null}
      {form.getFieldValue(`${providerId}-configurationType`) === 'Client' &&
      providerName.toLowerCase() === 'zoominfo' ? (
        <HorizontalLoginForm
          handleAuthentication={handleAuthenticationUser}
          handleDisconection={handleDisconection}
          isAuthenticate={!!userName}
          userName={userName}
          isLoading={isLoading}
          type="username"
        />
      ) : null}
    </div>
  );
}

export { ContactProviderAuthenticationForm };
