export const SET_REGIONS = 'SET_REGIONS';
export const DELETE_REGION = 'DELETE_REGION';
export const SET_REGION = 'SET_REGION';

export const setRegions = ({ regions }) => ({
  type: SET_REGIONS,
  payload: {
    regions,
  },
});

export const setRegion = ({ id, regionDetails }) => ({
  type: SET_REGION,
  payload: {
    id,
    regionDetails,
  },
});

export const removeRegion = ({ id }) => ({
  type: DELETE_REGION,
  payload: {
    id,
  },
});
