import React from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Input, Checkbox, Avatar, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { getConfiguration } from '../Collaborators/Collaborators';
import '../ShareJob/ShareJob.scss';
import messages from '../ShareJob/messages';
import { getEventNameByConnectType } from '../../Analytics/Connect/ConnectEventUtils';

const { Search } = Input;
const messages2 = defineMessages({
  title: {
    id: 'Templates.User.ShareTemplateTitle',
    defaultMessage: 'Share template with others',
  },
});

class ShareTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSharedUsers: props.sharedUsers || [],
      topSharedUsers: props.sharedUsers || [],
      currentUnsharedUsers: [],
      isShareWithAllChecked: props.isPublic,
      fetched: false,
    };

    this.sortUserGuIdsByName = this.sortUserGuIdsByName.bind(this);
    this.onChangeUserSelection = this.onChangeUserSelection.bind(this);
    this.onShareTemplate = this.onShareTemplate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getOkText = this.getOkText.bind(this);
    this.onCheckChangeAll = this.onCheckChangeAll.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.isPublic && !prevState.fetched && props.userGuIds.length > 0) {
      return {
        currentSharedUsers: props.userGuIds,
        topSharedUsers: props.userGuIds,
        currentUnsharedUsers: [],
        isShareWithAllChecked: props.isPublic,
        fetched: true,
      };
    }
    return prevState;
  }

  onChangeUserSelection(e) {
    const { currentSharedUsers, currentUnsharedUsers } = this.state;
    const { userGuIds, maxCount } = this.props;
    const checkedUserId = e.target.value;
    const isChecked = e.target.checked;
    const _currentUnsharedUsers = [...currentUnsharedUsers];
    let _currentSharedUsers = [...currentSharedUsers];
    if (isChecked) {
      _.remove(_currentUnsharedUsers, userId => userId === checkedUserId);
      _currentSharedUsers = [...currentSharedUsers, checkedUserId];
      this.setState({
        currentSharedUsers: _currentSharedUsers,
        currentUnsharedUsers: _currentUnsharedUsers,
      });
    } else {
      _.remove(_currentSharedUsers, userId => userId === checkedUserId);
      if (currentSharedUsers.includes(checkedUserId)) {
        currentUnsharedUsers.push(checkedUserId);
      }
      this.setState({
        currentSharedUsers: _currentSharedUsers,
        currentUnsharedUsers,
      });
    }

    if (_.isEqual([..._currentSharedUsers].sort(), [...userGuIds].sort()) && userGuIds.length === maxCount) {
      this.setState({
        isShareWithAllChecked: true,
      });
    } else {
      this.setState({
        isShareWithAllChecked: false,
      });
    }
  }

  onShareTemplate() {
    const { currentSharedUsers, currentUnsharedUsers, isShareWithAllChecked } = this.state;
    const { onShare, userGuIds, fetchUsersForShare } = this.props;
    if (isShareWithAllChecked) {
      onShare({ isShareWithAll: true });
    } else if (!isShareWithAllChecked) {
      if (_.isEqual([...currentUnsharedUsers].sort(), [...userGuIds].sort()) && !currentSharedUsers.length) {
        onShare({ isUnshareWithAll: true, sharedWith: [] });
      } else {
        onShare({ sharedWith: currentSharedUsers, unsharedWith: currentUnsharedUsers });
      }
    }
    this.setState({
      searchValue: '',
      topSharedUsers: currentSharedUsers,
    });
    fetchUsersForShare(1);
  }

  getOkText() {
    const { currentSharedUsers, currentUnsharedUsers } = this.state;
    const { sharedUsers, intl } = this.props;
    const checkForNewSharedUser = currentSharedUsers.findIndex(user => !sharedUsers.includes(user));
    if (checkForNewSharedUser === -1 && currentUnsharedUsers.length) {
      return intl.formatMessage({ ...messages.unshare });
    }
    return intl.formatMessage({ ...messages.share });
  }

  handleSearch(value) {
    const { fetchUsersForShare, usersByGuId } = this.props;
    const { currentSharedUsers } = this.state;
    const cleanValue = value.trim();
    const topSharedUsers = currentSharedUsers.filter(id => {
      if (usersByGuId[id]) {
        const Name = _.get(usersByGuId, [id, 'FirstName'], '') + _.get(usersByGuId, [id, 'LastName'], '');
        const { Email } = usersByGuId[id];
        return Name && Email
            ? Name.toLowerCase().search(cleanValue) >= 0 || Email.toLowerCase().search(cleanValue) >= 0
            : false;
      }
      return false;
    });
    this.setState({
      searchValue: cleanValue.toLowerCase(),
      topSharedUsers,
    });
    if (cleanValue) {
      fetchUsersForShare(1, cleanValue.toLowerCase());
    } else {
      fetchUsersForShare(1);
    }
  }

  handleSearchClear(event) {
    const { fetchUsersForShare } = this.props;
    const { currentSharedUsers } = this.state;
    if (!event.target.value) {
      const cleanValue = event.target.value;
      this.setState({
        searchValue: cleanValue.toLowerCase(),
        topSharedUsers: currentSharedUsers,
      });
      if (cleanValue) {
        fetchUsersForShare(1, cleanValue.toLowerCase());
      } else {
        fetchUsersForShare(1, '');
      }
    }
    this.setState({
      searchValue: event.target.value,
    });
  }

  onCheckChangeAll() {
    const { userGuIds } = this.props;
    const { isShareWithAllChecked } = this.state;

    if (!isShareWithAllChecked) {
      this.setState({
        currentSharedUsers: [...userGuIds],
        currentUnsharedUsers: [],
      });
    } else {
      this.setState({
        currentSharedUsers: [],
        currentUnsharedUsers: [...userGuIds],
      });
    }
    this.setState({
      isShareWithAllChecked: !isShareWithAllChecked,
    });
  }

  handleClose() {
    const { onClose, sharedUsers, isPublic } = this.props;
    this.setState({
      searchValue: '',
      topSharedUsers: sharedUsers || [],
      currentSharedUsers: sharedUsers || [],
      currentUnsharedUsers: [],
      isShareWithAllChecked: isPublic,
      fetched: false,
    });
    onClose();
  }

  sortUserGuIdsByName(id1, id2) {
    const { usersByGuId } = this.props;
    const firstUserName = usersByGuId[id1].FirstName
      ? usersByGuId[id1].FirstName.toLowerCase()
      : usersByGuId[id1].Email.toLowerCase();
    const secondUserName = usersByGuId[id2].FirstName
      ? usersByGuId[id2].FirstName.toLowerCase()
      : usersByGuId[id2].Email.toLowerCase();
    if (firstUserName < secondUserName) {
      return -1;
    }
    if (firstUserName > secondUserName) {
      return 1;
    }
    return 0;
  }

  render() {
    const {
      isVisible,
      maxCount,
      usersByGuId,
      userGuIds,
      intl,
      sharedUsers,
      currentUserEmailId,
      jobOwner,
      type,
    } = this.props;
    const { currentSharedUsers, searchValue, topSharedUsers, currentUnsharedUsers, isShareWithAllChecked } = this.state;
    userGuIds.sort((id1, id2) => this.sortUserGuIdsByName(id1, id2));
    const shareDisableFlag =
      !currentUnsharedUsers.length &&
      _.isEqual([...sharedUsers].sort(), [...currentSharedUsers].sort()) &&
      !isShareWithAllChecked;
    const isIndeterminateState = isShareWithAllChecked
      ? false
      : currentSharedUsers.length && currentSharedUsers.length < maxCount;
    return (
      <div>
        <Modal
          visible={isVisible}
          okText={this.getOkText()}
          cancelText={intl.formatMessage({ ...messages.cancel })}
          cancelButtonProps={{ 'sk-event-name': getEventNameByConnectType(type, 'cancelShare') }}
          onOk={this.onShareTemplate}
          onCancel={this.handleClose}
          title={<FormattedMessage {...messages2.title} />}
          okButtonProps={{
            disabled: shareDisableFlag,
            'sk-event-name': getEventNameByConnectType(type, 'confirmShare'),
          }}
        >
          <div>
            <div className="share-search-box">
              <Search
                placeholder={intl.formatMessage({ ...messages.searchRecruiters })}
                shape="round"
                onSearch={this.handleSearch}
                onChange={this.handleSearchClear}
                value={searchValue}
              />
            </div>

            <div className="share-with-user">
              <Checkbox
                onChange={this.onCheckChangeAll}
                indeterminate={isIndeterminateState}
                checked={isShareWithAllChecked}
              >
                Share with all
              </Checkbox>
            </div>
            <div style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
              <InfiniteScroll pageStart={1} key={searchValue || '1'} useWindow={false} threshold={220}>
                {userGuIds
                  .filter(id => topSharedUsers.includes(id))
                  .filter(id => id !== jobOwner)
                  .sort((id1, id2) => this.sortUserGuIdsByName(id1, id2))
                  .map(id => {
                    const FirstName = _.get(usersByGuId, [id, 'FirstName'], '');
                    const LastName = _.get(usersByGuId, [id, 'LastName'], '');
                    const Name = FirstName.concat(' ', LastName);
                    const { Email } = usersByGuId[id];
                    const config = getConfiguration(Name || Email);
                    return Email !== currentUserEmailId ? (
                      <div className="share-with-user" key={id}>
                        <Checkbox
                          onChange={this.onChangeUserSelection}
                          checked={currentSharedUsers.includes(id) || isShareWithAllChecked}
                          value={id}
                        />
                        <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
                        <Tooltip title={Email}>
                          <span>{Name || Email}</span>
                        </Tooltip>
                      </div>
                    ) : null;
                  })}
                {userGuIds
                  .filter(id => !topSharedUsers.includes(id))
                  .map(id => {
                    const FirstName = _.get(usersByGuId, [id, 'FirstName'], '');
                    const LastName = _.get(usersByGuId, [id, 'LastName'], '');
                    const Name = FirstName.concat(' ', LastName);
                    const { Email } = usersByGuId[id];
                    const config = getConfiguration(Name || Email);
                    return usersByGuId[id].Email !== currentUserEmailId ? (
                      <div className="share-with-user" key={id}>
                        <Checkbox
                          onChange={this.onChangeUserSelection}
                          checked={currentSharedUsers.includes(id) || isShareWithAllChecked}
                          value={id}
                        />
                        <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
                        <Tooltip title={usersByGuId[id].Email}>
                          <span>{Name || Email}</span>
                        </Tooltip>
                      </div>
                    ) : null;
                  })}
              </InfiniteScroll>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ShareTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  maxCount: PropTypes.number.isRequired,
  fetchUsersForShare: PropTypes.func.isRequired,
  userGuIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sharedUsers: PropTypes.arrayOf(PropTypes.number),
  usersByGuId: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

ShareTemplate.defaultProps = {
  sharedUsers: [],
};
export default injectIntl(ShareTemplate);

export { ShareTemplate as ShareTemplateWithoutInject };
