import React from 'react';
import { Alert } from 'antd';
import {
  CandidateIntelOverviewIcon,
  CandidateIntelSkillProximityIcon,
  CandidateIntelRoleSimilarityIcon,
  CandidateIntelIndustryRelevanceIcon,
  CandidateIntelEducationRelevanceIcon,
  CandidateIntelOccupationalRelevanceIcon,
  LightBulbIcon,
} from '../../Icons/AryaIcons';
import CandidateIntelScore from './CandidateIntelScore/CandidateIntelScore';
import EducationRelevanceChart from './EducationRelevanceChart/EducationRelevanceChart';
import OccupationalRelevanceScatterPlot from './OccupationalRelevanceScatterPlot/OccupationalRelevanceScatterPlot';
import './CandidateIntel.scss';
import RoleSimilarityScatterPlot from './RoleSimilarityScatterPlot/RoleSimilarityScatterPlot';
import CandidateIntelOverviewChart from './CandidateIntelOverviewChart/CandidateIntelOverviewChart';
import SkillProximityScatterPlot from './SkillProximityScatterPlot/SkillProximityScatterPlot';
import IndustryRelevancySankeyChart from './IndustryRelevancySankeyChart/IndustryRelevancySankeyChart';

const intelAlertMessages = {
  skills: {
    message: 'How to read Skills Proximity?',
    description:
      'See how likely it is candidate has the required skills, or how easily it will be to acquire them. The closer candidate’s skills to the required skills (even some not mentioned in the job description) the more relevant they are, or easier to acquire.',
  },
  role: {
    message: 'How to read Role Similarity?',
    description:
      "The closer the candidate's role to the job role, the more likely this candidate will be relevant to the job.",
  },
  industry: {
    message: 'How to read Industry Relevance?',
    description:
      'The higher the percentage, the more likely the candidate will be a fit for the industries closely related to the job requirement.',
  },
  education: {
    message: 'How to read Educational Relevance and Impact?',
    description:
      'Candidates with educational qualifications matching the job requirements or better than the average candidate will have more relevancy for this job.',
  },
  occupation: {
    message: 'How to read Occupational Similarity?',
    description:
      "The closer the candidate's occupation to the job occupation, the more likely this candidate will be relevant to the job.",
  },
};

export function showAlertForCandidateIntel(intelType) {
  const { message, description } = intelAlertMessages[`${intelType}`];
  return (
    <Alert
      className="candidate-intel-alert"
      message={message}
      description={description}
      type="info"
      icon={<LightBulbIcon />}
      showIcon
    />
  );
}

export function CandidateIntelOverViewSection({ Overview, AppName }) {
  const { JobRelevancyScore } = Overview;
  const candidateIntelScoreGroup = Object.keys(JobRelevancyScore).map(key => (
    <CandidateIntelScore score={JobRelevancyScore[`${key}`]} scoreType={key} />
  ));
  return (
    <div className="overview">
      <div className="intel-header">
        <div className="overview-icon">
          <CandidateIntelOverviewIcon />
        </div>
        <div>
          <p className="intel-heading">Overview</p>
          <p className="intel-subheading">
            At a glance, see how this candidate compares to the job requirements, as well as to the other candidates{' '}
            {AppName} thinks are relevant for this position.
          </p>
          <div className="intel-labels">
            <div className="intel-label-group">
              <div className="intel-label-color candidate-label"></div>
              <div className="intel-label-text">Compared to Job Requirements</div>
            </div>
            <div className="intel-label-group">
              <div className="intel-label-color job-label"></div>
              <div className="intel-label-text">Compared to other relevant candidates</div>
            </div>
          </div>
          <div className="candidate-intel-overview-details">
            <CandidateIntelOverviewChart Overview={Overview} />
            <div>
              <p className="overall-score-heading">Overall Score</p>
              <div className="candidate-score-card-group">{candidateIntelScoreGroup}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CandidateIntelSkillProximitySection({ SkillScore, SkillsRelevance, candidateName }) {
  return (
    <div className="skill-proximity">
      <div className="skill-proximity-icon">
        <CandidateIntelSkillProximityIcon className="proximity-icon" />
      </div>
      <div>
        <div className="intel-header">
          <div>
            <p className="intel-heading">Skills Proximity</p>
            <p className="intel-subheading">How relevant the candidate&apos;s skill are to the job requirements.</p>
          </div>
          <div className="intel-score">
            <CandidateIntelScore score={SkillScore} />
          </div>
        </div>
        <div className="intel-description">
          At a glance, see how relevant this candidate&apos;s skills are, or how easy it might be for them to acquire
          relevant skills.
        </div>
        <div className="intel-labels">
          <div className="intel-label-group">
            <div className="intel-label-color candidate-label"></div>
            <div className="intel-label-text">{candidateName}&apos;s Skills</div>
          </div>
          <div className="intel-label-group">
            <div className="intel-label-color job-label"></div>
            <div className="intel-label-text">Required Job Skills</div>
          </div>
        </div>
        <SkillProximityScatterPlot SkillsRelevance={SkillsRelevance} candidateName={candidateName} />
        {showAlertForCandidateIntel('skills')}
      </div>
    </div>
  );
}

export function CandidateIntelRoleSimilaritySection({ RoleScore, RoleRelevance, candidateName, AppName }) {
  return (
    <div className="role-similarity">
      <div className="role-similarity-icon">
        <CandidateIntelRoleSimilarityIcon />
      </div>
      <div>
        <div className="intel-header">
          <div>
            <p className="intel-heading">Role Similarity</p>
            <p className="intel-subheading">
              How closely this candidate&apos;s role is related to the role required by the job.
            </p>
          </div>
          <div className="intel-score">
            <CandidateIntelScore score={RoleScore} />
          </div>
        </div>
        <div className="intel-description">
          {AppName} understands the candidate&apos;s relevance to the role in terms of the types of functions, skills,
          and tools used by all candidates in similar roles.
        </div>
        <div className="intel-labels">
          <div className="intel-label-group">
            <div className="intel-label-color candidate-label"></div>
            <div className="intel-label-text">{candidateName}&apos;s Role</div>
          </div>
          <div className="intel-label-group">
            <div className="intel-label-color job-label"></div>
            <div className="intel-label-text">Job Role</div>
          </div>
          <div className="intel-label-group">
            <div className="intel-label-color others-label"></div>
            <div className="intel-label-text">Others</div>
          </div>
        </div>
        <RoleSimilarityScatterPlot RoleRelevance={RoleRelevance} candidateName={candidateName} />
        {showAlertForCandidateIntel('role')}
      </div>
    </div>
  );
}

export function CandidateIntelIndustryRelevanceSection({ IndustryScore, IndustryRelevance }) {
  return (
    <div className="industry-relevance">
      <div className="industry-relevance-icon">
        <CandidateIntelIndustryRelevanceIcon />
      </div>
      <div>
        <div className="intel-header">
          <div>
            <p className="intel-heading">Industry Relevance</p>
            <p className="intel-subheading">
              See at a glance how relevant this candidate&apos;s industry experience is to the job.
            </p>
          </div>
          <div className="intel-score">
            <CandidateIntelScore score={IndustryScore} />
          </div>
        </div>
        <div className="intel-description">
          See how statistically significant the candidate&apos;s industry experience is to the job based on how many
          other similar candidates moved between related industries.
        </div>
        <IndustryRelevancySankeyChart IndustryRelevance={IndustryRelevance} />
        {showAlertForCandidateIntel('industry')}
      </div>
    </div>
  );
}

export function CandidateIntelEdcuationRelevanceSection({
  EducationScore,
  EducationalRelevance,
  candidateName,
  AppName,
}) {
  return (
    <div className="education-relevance">
      <div className="education-relevance-icon">
        <CandidateIntelEducationRelevanceIcon />
      </div>
      <div>
        <div className="intel-header">
          <div>
            <p className="intel-heading">Educational Relevance and Impact</p>
            <p className="intel-subheading">
              Identify if this candidate&apos;s education will have impact on their performance in the job.
            </p>
          </div>
          <div className="intel-score">
            <CandidateIntelScore score={EducationScore} />
          </div>
        </div>
        <div className="intel-description">
          {AppName} analyzes the candidate&apos;s education and compares it to that of other relevant candidates for
          this job.
        </div>

        <EducationRelevanceChart
          width={250}
          height={250}
          EducationalRelevance={EducationalRelevance}
          candidateName={candidateName}
        />

        {showAlertForCandidateIntel('education')}
      </div>
    </div>
  );
}

export function CandidateIntelOccupationalRelevanceSection({
  OccupationScore,
  OccupationRelevance,
  candidateName,
  AppName,
}) {
  return (
    <div className="occupational-similarity">
      <div className="occupational-similarity-icon">
        <CandidateIntelOccupationalRelevanceIcon />
      </div>
      <div>
        <div className="intel-header">
          <div>
            <p className="intel-heading">Occupational Similarity</p>
            <p className="intel-subheading">
              How closely this candidate&apos;s occupation is relevant to the occupation of the job.
            </p>
          </div>
          <div className="intel-score">
            <CandidateIntelScore score={OccupationScore} />
          </div>
        </div>
        <div className="intel-description">
          {AppName} compares the relevance of the candidate&apos;s occupation to the job, as well as comparing to the
          occupations of similarly related candidates.
        </div>
        <div className="intel-labels">
          <div className="intel-label-group">
            <div className="intel-label-color candidate-label"></div>
            <div className="intel-label-text">{candidateName}&apos;s Occupation</div>
          </div>
          <div className="intel-label-group">
            <div className="intel-label-color job-label"></div>
            <div className="intel-label-text">Job Occupation</div>
          </div>
          <div className="intel-label-group">
            <div className="intel-label-color others-label"></div>
            <div className="intel-label-text">Others</div>
          </div>
        </div>
        <OccupationalRelevanceScatterPlot candidateName={candidateName} OccupationRelevance={OccupationRelevance} />
        {showAlertForCandidateIntel('occupation')}
      </div>
    </div>
  );
}

export default function CandidateIntel({ candidateIntel, candidateName, AppName }) {
  const {
    Overview,
    SkillsRelevance,
    RoleRelevance,
    IndustryRelevance,
    EducationalRelevance,
    OccupationRelevance,
  } = candidateIntel;
  const { JobRelevancyScore } = candidateIntel.Overview || {};
  const { SkillScore, RoleScore, IndustryScore, EducationScore, OccupationScore } = JobRelevancyScore || {};
  return (
    <>
      {Overview ? <CandidateIntelOverViewSection Overview={Overview} AppName={AppName} /> : null}
      {SkillsRelevance ? (
        <>
          <div className="line-break"></div>
          <CandidateIntelSkillProximitySection
            SkillScore={SkillScore}
            SkillsRelevance={SkillsRelevance}
            candidateName={candidateName}
          />
        </>
      ) : null}
      {RoleRelevance ? (
        <>
          <div className="line-break"></div>
          <CandidateIntelRoleSimilaritySection
            RoleScore={RoleScore}
            RoleRelevance={RoleRelevance}
            candidateName={candidateName}
            AppName={AppName}
          />
        </>
      ) : null}

      {IndustryRelevance ? (
        <>
          <div className="line-break"></div>
          <CandidateIntelIndustryRelevanceSection IndustryScore={IndustryScore} IndustryRelevance={IndustryRelevance} />
        </>
      ) : null}

      {EducationalRelevance ? (
        <>
          <div className="line-break"></div>
          <CandidateIntelEdcuationRelevanceSection
            EducationScore={EducationScore}
            EducationalRelevance={EducationalRelevance}
            candidateName={candidateName}
            AppName={AppName}
          />
        </>
      ) : null}

      {OccupationRelevance ? (
        <>
          <div className="line-break"></div>
          <CandidateIntelOccupationalRelevanceSection
            OccupationScore={OccupationScore}
            OccupationRelevance={OccupationRelevance}
            candidateName={candidateName}
            AppName={AppName}
          />
        </>
      ) : null}
    </>
  );
}
