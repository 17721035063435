import React from 'react';
import { Button, Row, Col, Progress, Empty, Tooltip, Skeleton } from 'antd';
import InsightList from './InsightList';
import styles from './Insight.module.scss';
import { EmptySimilarRolesIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';
import eventTypes from '../../../Analytics/EventTypes';

export default function RoleInsightView(props) {
  const { listOfSimilarTitlesStats, loading, readonlyStats, emptyInsightDescription, displayCount } = props;
  const [titleSynonymsListView, setTitleSynonymsListView] = React.useState(false);
  React.useEffect(() => {
    if (titleSynonymsListView) {
      setTitleSynonymsListView(false);
    }
  }, [loading]);
  const roleInsightTitle = <div className={styles.headerTitle}>Similar roles</div>;
  const roleInsightDescription = (
    <div className={styles.headerDescription}>Similar roles and job titles based on your given criteria</div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.similarRoles.id}>
      {titleSynonymsListView ? (
        <>
          <div className={styles.headerSupplyInfo}>
            <div className={styles.headerInfo}>
              {roleInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  onClick={() => setTitleSynonymsListView(false)}
                  sk-event-name={eventTypes.job.marketIntel.hideJobRoleList}
                >
                  X
                </Button>
              </div>
            </div>
            {roleInsightDescription}
          </div>
          <div id="RoleList">
            <InsightList insightList={listOfSimilarTitlesStats} loading={loading} itemSpan={11} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerSupplyInfo}>
            <div className={styles.headerInfo}>
              {roleInsightTitle}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  disabled={listOfSimilarTitlesStats.length < 1}
                  onClick={() => setTitleSynonymsListView(true)}
                  sk-event-name={eventTypes.job.marketIntel.displayJobRoleList}
                >
                  See list
                </Button>
              </div>
            </div>
            {roleInsightDescription}
          </div>
          <div>
            <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
              <Row>
                {listOfSimilarTitlesStats.length > 0 ? (
                  <>
                    {listOfSimilarTitlesStats.slice(0, displayCount).map(item => (
                      <Col span={120 / (displayCount ?? 15)} key={item.DisplayName}>
                        <div className={styles.skillsMapList}>
                          <Tooltip title={`${item.DisplayName} (${item.Value} Profiles)`}>
                            <Progress percent={item.SupplyPercentage} data-label={item.DisplayName} />
                          </Tooltip>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : (
                  <div>
                    <Empty
                      image={<EmptySimilarRolesIcon className={styles.emptyIcon} />}
                      description={
                        <div>
                          <h3>No role data found</h3>
                          {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                        </div>
                      }
                    />
                  </div>
                )}
              </Row>
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
