import React from 'react';
import { Menu, Dropdown } from 'antd';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './WorkflowNodePopover.module.scss';
import { actionSubTypesKeyValuePairs, nodeTypesKeyValuePairs } from '../../Utils/WorkflowUtils';
import WorkflowNodeModal from '../WorkflowNodeModal/WorkflowNodeModal';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import {
  WorkflowActionsSvgIcon,
  WorkflowDelayActionSvgIcon,
  WorkflowRuleActionSvgIcon,
  WorkflowEndActionSvgIcon,
} from '../../Icons/AryaIcons';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';

const { SubMenu } = Menu;

const mapStateToProps = state => ({
  mergeTags: MergeTagsReducer.getMergeTags(state),
  featureToggleList: getFeatureToggleList(state),
});

export function WorkflowNodePopover(props) {
  const { onSaveCallback, isFirstEdge, location } = props;
  const [nodeType, setNodeType] = React.useState();
  const [workflowModalVisibility, setWorkflowModalVisibility] = React.useState(false);
  const { children, mergeTags, featureToggleList } = props;
  const filteredMergeTags = getFilteredMergeTags(mergeTags, {
    CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
    JobSummary: featureToggleList.JobSummary,
    CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
  });
  const queryParams = queryString.parse(location.search);
  const { type } = queryParams;
  const isDisabled = type === 'preview';

  const handleClick = e => {
    setNodeType(e.key);
    setWorkflowModalVisibility(true);
  };

  const onSave = payload => {
    onSaveCallback(payload);
  };

  const onCancel = () => {
    setNodeType(null);
    setWorkflowModalVisibility(false);
  };

  const getNodeBasedOnType = type => {
    switch (type) {
      case 'Action' || 'Send Email' || 'Send SMS' || 'Add Tag' || 'Add Custom Field':
        return (
          <div className={styles.popoverItemWrapper} style={{ color: '#722ed1' }}>
            <WorkflowActionsSvgIcon className={styles.workflowActionSvg} />
            <div className={styles.actionSelctionPopover}>
              <span className={styles.workflowActionName}>{type}</span> <br />
              <span className={styles.workflowActionLabel}>Do something </span>
            </div>
          </div>
        );
      case 'Delay':
        return (
          <div className={styles.popoverItemWrapper} style={{ color: '#FAAD14' }}>
            <WorkflowDelayActionSvgIcon className={styles.workflowActionSvg} />
            <div className={styles.actionSelctionPopover}>
              <span className={styles.workflowActionName}>{type}</span> <br />
              <span className={styles.workflowActionLabel}>Wait time</span>
            </div>
          </div>
        );
      case 'Rule':
        return (
          <div className={styles.popoverItemWrapper} style={{ color: '#007bff' }}>
            <WorkflowRuleActionSvgIcon className={styles.workflowActionSvg} />
            <div className={styles.actionSelctionPopover}>
              <span className={styles.workflowActionName}>{type}</span> <br />
              <span className={styles.workflowActionLabel}>If-Then criteria</span>
            </div>
          </div>
        );
      case 'End':
        return (
          <div className={styles.popoverItemWrapper} style={{ color: '#f5222d' }}>
            <WorkflowEndActionSvgIcon className={styles.workflowActionSvg} />
            <div className={styles.actionSelctionPopover}>
              <span className={styles.workflowActionName}>{type}</span> <br />
              <span className={styles.workflowActionLabel}>End flow</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const menu = (
    <Menu onClick={handleClick} className={styles.workflowNodeDropdown}>
      <SubMenu
        key="Action"
        className={styles.workflowNodeMenuItem}
        popupClassName={styles.workflowNodePopoverSubmenu}
        popupOffset={[-4, 0]}
        title={getNodeBasedOnType('Action')}
      >
        {actionSubTypesKeyValuePairs.map(actionSubType => (
          <Menu.Item key={actionSubType.key} style={{ cursor: 'pointer' }}>
            <span className={styles.workflowActionSubtype}>{actionSubType.value}</span>
          </Menu.Item>
        ))}
      </SubMenu>
      {nodeTypesKeyValuePairs
        .filter(x => x.key !== 'Action')
        .map(x => {
          const node = getNodeBasedOnType(x.value);
          return (
            <Menu.Item
              key={x.key}
              style={{
                cursor: 'pointer',
              }}
              className={styles.workflowNodeMenuItem}
            >
              {node}
            </Menu.Item>
          );
        })}
    </Menu>
  );
  return (
    <>
      {isDisabled ? null : (
        <Dropdown overlay={menu} placement="bottomLeft">
          <div className={styles.dropdownMenu}>{children}</div>
        </Dropdown>
      )}
      <WorkflowNodeModal
        isFirstEdge={isFirstEdge}
        type={nodeType}
        visible={workflowModalVisibility}
        onSaveCallback={onSave}
        onCancelCallback={onCancel}
        mergeTags={filteredMergeTags}
      />
    </>
  );
}

export default connect(mapStateToProps, null)(withRouter(WorkflowNodePopover));
export { WorkflowNodePopover as WorkflowNodePopoverWithoutStore };
