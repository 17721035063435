import React from 'react';
import { InputNumber, Form } from 'antd';
import { validateMinExperience, validateMaxExperience } from '../../../Utils/FormValidators';
import styles from './AdvancedJobDetailsSelection.module.scss';

const FormItem = Form.Item;

export default function ExperienceSelection(props) {
  const isMaxExpReq = true;
  const isMinExpReq = true;
  const { form, minExp, maxExp, handleExpChange } = props;
  return (
    <div className={styles.experienceWrapper}>
      <FormItem label="Min Overall Exp" colon={false} className={styles.minExp}>
        {form.getFieldDecorator('MinExperience', {
          initialValue: maxExp ? minExp : minExp || undefined,
          rules: [{ validator: (rule, value, callback) => validateMinExperience(form, value, callback, isMinExpReq) }],
        })(
          <InputNumber
            onChange={e => handleExpChange(e, true)}
            placeholder="0 years"
            min={0}
            max={50}
            label="Min Overall Exp"
          />
        )}
      </FormItem>
      <FormItem label="Max Overall Exp" colon={false}>
        {form.getFieldDecorator('MaxExperience', {
          initialValue: maxExp,
          rules: [{ validator: (rule, value, callback) => validateMaxExperience(form, value, callback, isMaxExpReq) }],
        })(
          <InputNumber
            onChange={e => handleExpChange(e, false)}
            placeholder="0 years"
            min={0}
            max={50}
            label="Max Overall Exp"
          />
        )}
      </FormItem>
    </div>
  );
}
