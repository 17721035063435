import React, { Component } from 'react';
import { Skeleton, Radio, Icon } from 'antd';
import ModalCardForm from './ModalCardForm';
import './PaymentInfo.scss';

export function RadioTabItem({ isActive, children, value, disabled }) {
  return (
    <div className={`payment-info-tab-item ${disabled ? 'payment-info-tab-disabled' : ''}`}>
      {isActive ? (
        <>
          <Icon type="check-circle" style={{ color: '#13C26B', fontSize: '16px', margin: '0px' }} />
          <span style={{ padding: '0px 8px' }}>{children}</span>
        </>
      ) : (
        <Radio value={value} disabled={disabled}>
          {children}
        </Radio>
      )}
    </div>
  );
}

export default class PaymentInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardFormDisplay: false,
      // paymentProfileId: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { paymnetProfileApiStatus, paymentProfile = [], selectCard } = this.props;
    if (
      prevProps.paymnetProfileApiStatus === 'INPROGRESS' &&
      paymnetProfileApiStatus === 'COMPLETED' &&
      paymentProfile.length
    ) {
      selectCard(paymentProfile[0].ProfileId);
    }
  }

  handleCancel() {
    const { resetSavePaymentDetailApiStatus } = this.props;
    this.setState({ creditCardFormDisplay: false });
    resetSavePaymentDetailApiStatus();
  }

  render() {
    const { creditCardFormDisplay } = this.state;
    const {
      savePaymentProfileApiStatus,
      savePaymentDetails,
      fetchPaymentProfile,
      planDisplayName,
      availableCredits,
      planDetailsByProductVariantIdApiStatus,
      hideHeader,
    } = this.props;
    return (
      <div className="payment-information-container">
        {hideHeader ? null : <div className="payment-information-header-text">Payment Information</div>}
        <Skeleton loading={planDetailsByProductVariantIdApiStatus === 'INPROGRESS'}>
          {availableCredits ? (
            <>
              <div style={{ paddingTop: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="payment-mode-arya-job-credit-header">Total number of credits available</div>
                <div className="credits-remaining-content">
                  <div style={{ fontWeight: 'bold' }}>{availableCredits}</div>
                  <div>{planDisplayName} Credits remaining</div>
                </div>
              </div>
              {/* <div className="expiry-info">
                  You have {availableCredits} {planDisplayName} plan job credits. Credits expire by MM/DD/YY
                </div> */}
            </>
          ) : (
            <div className="no-credits-available">No credits available for {planDisplayName} plan</div>
          )}
          <div className="multiple-jobs-discount-content">
            If you want to purchase multiple jobs and avail a volume discount, choose our Multiple Jobs plan today!
            <a href="https://goarya.com/arya-pulse-bundling/" target="_blank" rel="noopener noreferrer">
              &nbsp;Request Now
            </a>{' '}
            and we will get back to you.
          </div>
        </Skeleton>
        <ModalCardForm
          creditCardFormDisplay={creditCardFormDisplay}
          savePaymentProfileApiStatus={savePaymentProfileApiStatus}
          handleCancel={this.handleCancel}
          savePaymentDetails={savePaymentDetails}
          fetchPaymentProfile={fetchPaymentProfile}
        />
      </div>
    );
  }
}
