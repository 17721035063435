export default function markNotificationCompletion(type = 'TOAST') {
  return dispatch => {
    if (type === 'TOAST') {
      dispatch({
        type: 'NOTIFICATION_COMPLETED',
      });
    } else if (type === 'ALERT') {
      dispatch({
        type: 'ALERT_NOTIFICATION_COMPLETED',
      });
    }
  };
}

function setFeatureLockedNotificationAlert(payload) {
  return {
    type: 'SET_FEATURE_LOCKED_NOTIFICATION',
    payload,
  };
}

export { setFeatureLockedNotificationAlert };
