import React from 'react';
import { Form, Input } from 'antd';

export function getPlaceholderText(jobBoard) {
  return jobBoard.toLowerCase() === 'efinancial' ? 'API Key' : 'Username';
}

export default function UsernameInput({ form, account, credentialsValidator, isAuthenticated, jobBoard }) {
  const usernameFieldName = `${jobBoard}userName`;
  const usernameError = form.isFieldTouched(usernameFieldName) && form.getFieldError(usernameFieldName);
  return (
    <div className="jobboard-input-container">
      <Form.Item validateStatus={usernameError ? 'error' : ''} help="">
        {form.getFieldDecorator(usernameFieldName, {
          initialValue: account.Username,
          rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
        })(
          <Input
            placeholder={getPlaceholderText(jobBoard)}
            className="common-jobboard-username"
            readOnly={isAuthenticated}
          />
        )}
      </Form.Item>
    </div>
  );
}
