import { getSourceName } from './SourceUtils';
import { getCurrentJobDetails } from '../Reducers/JobReducer';
import store from '../store';

function mapCandidateListApiErrorCodes(error) {
  const code = error?.response?.status;
  let errorData = {
    Code: code,
    Message: 'No candidates found',
    ErrorDescription: 'Please try changing options and search again',
  };
  const errorResponse = error?.response?.data?.Error || {};
  const { Code: appCode, Info: source = {}, Message: message } = errorResponse;
  const sourceName = getSourceName(source ?? {});
  const state = store.getState();
  const currentJobDetails = getCurrentJobDetails(state);
  const jobLocation = currentJobDetails?.Country;
  switch (error?.response?.status) {
    case 404:
      if (appCode === 'CREDENTIALS_NOT_FOUND')
        errorData = {
          code: 404,
          Message: `Credentials do not exist for ${sourceName}`,
          ErrorDescription: 'Please authorize to see all the candidates',
        };
      break;
    case 401:
      errorData = {
        code: 401,
        Message: `Credentials are Invalid/Expired for ${sourceName}`,
        ErrorDescription: 'Please reauthorize to see all the candidates',
      };
      break;
    case 403:
      if (appCode === 'CREDENTIALS_NOT_SUPPORTED')
        errorData = {
          code: 403,
          Message: `${sourceName} credentials not supported for region: ${jobLocation}`,
          ErrorDescription: message,
          appCode: 'CREDENTIALS_NOT_SUPPORTED',
        };
      else if (appCode === 'SOURCE_FORBIDDEN')
        errorData = {
          code: 403,
          Message: `You don't have access to ${sourceName}`,
          ErrorDescription: `Please contact your admin or ${sourceName} if this issue persists`,
          appCode: 'SOURCE_FORBIDDEN',
        };
      break;
    case 406:
      errorData = {
        code: 406,
        Message: `${sourceName} is not available in ${jobLocation}`,
        ErrorDescription: 'Please contact your adminstrator for more information',
      };
      break;
    default:
      break;
  }
  return errorData;
}
function mapBulkPublishCandidatesApiErrorCode(error) {
  let errorMessage = 'Oops, something just went wrong. Please try again.';
  if (error?.response?.data?.Error?.Code === 'UNSUPPORTED_OPERATION') {
    errorMessage = error?.response?.data?.Error?.Message;
  }
  return errorMessage;
}
export { mapCandidateListApiErrorCodes, mapBulkPublishCandidatesApiErrorCode };
